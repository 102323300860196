import React, { useEffect, useState } from "react";
import SidebarWithHeader from "./sidebar";
import useUserToken from "../../hooks/useUserToken";
import { useCookies } from "react-cookie";
export default function Layout({ children }: any) {
  const [allOrganization, setAllOrganizations] = useState("");
  const [, setCookie] = useCookies(["organization", "access_token"]);
  const token = useUserToken() || useUserToken("affiliate");
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + "organisation/user/all", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setAllOrganizations(data.data.organisations);

          let expires = new Date();
          expires.setTime(expires.getTime() + 720 * 60 * 60 * 1000);
          setCookie("organization", data.data.organisations, {
            path: "/",
            expires,
          });
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
        }
      })
      .catch((err) => {
        console.log("here");
      });
  };
  return (
    <React.Fragment>
      <SidebarWithHeader organization={allOrganization}>
        {children}
      </SidebarWithHeader>
    </React.Fragment>
  );
}
