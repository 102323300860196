import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";
import ReferMetricksBannerSection from "./Banner";
import ReferMetricksGuide from "./Guide";
import ReferSignificance from "./Significance";

const ReferMetricks = () => {
  return (
    <Box px="24px" py="20px">
      <Box bg="white" w="full" pb="80px">
        <ReferMetricksBannerSection />
        <ReferMetricksGuide />

        <Flex
          mx="auto"
          mt="20px"
          maxW="4xl"
          flexDirection="column"
          alignItems="center"
          p={{ base: "30px", md: "30px 80px" }}
          bgColor="#F6F6FB"
        >
          <Text
            fontSize="14px"
            lineHeight="24px"
            fontWeight="500"
            color="#32325D"
            textAlign="center"
          >
            We take care of everything, including hosting the products and
            providing customer service, and you earn money by referring people
            to our websites. This is how it functions:
          </Text>

          <Text
            fontSize="14px"
            lineHeight="24px"
            fontWeight="500"
            color="#32325D"
            mt="12px"
            textAlign="center"
          >
            You can monitor your sales data, conversion reports, account
            balances, traffic, etc from your dashboard.
          </Text>
        </Flex>

        <ReferSignificance />
      </Box>
    </Box>
  );
};

export default ReferMetricks;
