import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Image,
  Link,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import EarningsTable from "./EarningsTable";

const Earnings = () => {
  const [filterData, setfilterData] = useState({
    date: "",
    status: "",
  });

  const earnings = [
    {
      plans: "Standard",

      amount: "USD $10.00",
      date: "Oct 4, 2023",
      status: "Paid",
      action: "Receipt",
    },

    {
      plans: "Standard",

      amount: "USD $10.00",
      date: "Dec 20, 2022",
      status: "Paid",
      action: "Receipt",
    },
    {
      plans: "Standard",

      amount: "USD $10.00",
      date: "Dec 4, 2022",
      status: "Paid",
      action: "Receipt",
    },
  ];

  // const [csvFileDownloadLink] = useJsonToCSV(
  //   ["DATE CREATED", "PRODUCT ID", "SALES", "STATUS", "EARNINGS"],
  //   earnings
  // );

  return (
    <Stack pb="100px" w={"100%"}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt="36px"
        mb="28px"
      >
        <HStack spacing="24px">
          <Heading size="md">History</Heading>
        </HStack>
      </Box>

      {/* <EarningsTable earnings={earnings} /> */}
      <Flex
        w={"100%"}
        justify={"center"}
        align={"center"}
        direction={"column"}
        gap={5}
        borderTop={"1px"}
        borderTopColor={"#c4c4c4"}
        pt={20}
      >
        <Image
          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
          width="150px"
          height="150px"
        />
        <Text fontWeight={"medium"}>No data yet</Text>
      </Flex>
    </Stack>
  );
};

export default Earnings;
