import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ReferMetricksBannerSection = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleRedirect = () => {
    // if (location.state.userType === "affiliate")
    //   return navigate("/affiliate-explore/view/40");
    // return navigate("/affiliate-login?id=");
  };

  return (
    <Flex
      alignItems="center"
      flexDirection={{ base: "column-reverse", md: "row" }}
      p="14px 40px"
      bgColor="#F6F6FB"
      position="relative"
    >
      <Box maxWidth="xl" mt={{ base: "60px", md: "0px" }}>
        <Text
          fontSize="22px"
          fontWeight="500"
          lineHeight="33px"
          color="#32325D"
        >
          Earn 20% Commission When You Refer Us.
        </Text>
        <Text
          fontSize="14px"
          fontWeight="400"
          lineHeight="24px"
          color="#32325D"
          mt="15px"
          mb="28px"
        >
          We take care of everything, including hosting the products and
          providing customer service.
        </Text>

        <Button
          bgColor=" #7211D4"
          onClick={handleRedirect}
          borderRadius="8px"
          width="100%"
          w="max-content"
        >
          <Text
            fontWeight="500"
            fontSize="16px"
            lineHeight="32px"
            color=" #F0F3F9"
          >
            Join Now
          </Text>
          <Image
            ml="7px"
            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/f986e464-5df1-47da-a06a-cea4503eb0e9.svg"
          />
        </Button>
      </Box>

      <Image
        h="225px"
        w="350px"
        ml={{ md: "80px" }}
        src="/assets/png/20_percent.png"
      />

      <Box position="absolute" right="0px" top="0px">
        <Image
          h={{ base: "80px", md: "150px" }}
          w={{ base: "100px", md: "170px" }}
          src="https://metricksassets.s3.us-east-2.amazonaws.com/development/default/d94e033e-7572-4ce0-8d33-102f0e0726cf.png"
        />
      </Box>
    </Flex>
  );
};

export default ReferMetricksBannerSection;
