import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Image,
  Text,
  Stack,
  Select,
  useColorModeValue,
  Link,
  Button,
  Avatar,
  Flex,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
// import Swiper styles
import "swiper/css";
import { endpoints } from "../../../utils/endpoints";
import { apiReqHandler } from "../../../helper/apiReqHandler";
import { getCookie } from "cookies-next";
const TopAffiliate = ({ top10Affiliates }) => {
  SwiperCore.use([Autoplay]);
  // const [top10Affiliates, settop10Affiliates] = useState([]);
  const swiperRef = useRef();

  // useEffect(() => {
  //   getTopAffiliate();
  // }, []);
  // const getTopAffiliate = async () => {
  //   // console.log(endpoints.campaign.VERIFY(campaignId));
  //   const { res } = await apiReqHandler({
  //     endPoint: endpoints.dashboard.TOPAFFILIATES,
  //     method: "GET",
  //     //   @ts-ignore
  //     bearerAuth: getCookie("access_token"),
  //   });

  //   //   @ts-ignore
  //   settop10Affiliates(res.data.data.top10);
  //   // console.log("fetvh", res);
  //   console.log("status", res.data.data.top10);
  // };
  return (
    <>
      {/* {top10Affiliates.length > 1 && ( */}
      <Stack
        my={["10px", "18px"]}
        mx={["0px", "18px"]}
        background="#F9FAFD"
        width="100%"
        height="152px"
        padding={["16px 20px", "16px 27px"]}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb="20px"
        >
          <Box display="flex" alignItems="center">
            <Text
              fontWeight="500"
              fontSize="12px"
              color=" #32325D"
              lineHeight="18px"
              opacity="0.8"
              letterSpacing="0.02em"
            >
              FIND NEW AFFILIATES
            </Text>
            <Image
              ml="10px"
              src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/39116ff5-c69d-4845-b009-28991cd8efa9.svg`}
            />
          </Box>
          <Box display={["none", "block"]}>
            <Link
              href="/explore"
              color="#5C14CE"
              display="flex"
              alignItems="center"
            >
              <Text
                fontWeight="500"
                fontSize="12px"
                color=" #7211D4"
                lineHeight="18px"
                opacity="0.8"
                letterSpacing="0.02em"
                textDecoration="underline"
                textTransform="capitalize"
              >
                See more
              </Text>
              <Image
                ml="10px"
                src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/f0d0d4f4-e220-48e2-932c-bb33f3c94888.svg`}
              />
            </Link>
          </Box>
        </Box>

        <Box position="relative">
          <Swiper
            spaceBetween={20}
            breakpoints={{
              // when window width is >= 370px
              370: {
                slidesPerView: 2,
              },
              // when window width is >= 400px
              460: {
                slidesPerView: 3,
              },
              // when window width is >= 640px
              640: {
                slidesPerView: 3.4,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 3,
              },
              // when window width is >= 900px
              900: {
                slidesPerView: 3.4,
              },
              // when window width is >= 1024px
              1024: {
                slidesPerView: 4.3,
              },
              // when window width is >= 1200px
              1200: {
                slidesPerView: 5.3,
              },
              // when window width is >= 1500px
              1500: {
                slidesPerView: 6.3,
              },
            }}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            autoplay={{ delay: 3000 }}
            // onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {top10Affiliates.map((item, i) => {
              return (
                <SwiperSlide position="relative" key={item.user.id}>
                  <Box display="flex" alignItems="center">
                    <Box>
                      <Text
                        fontWeight="500"
                        fontSize="14px"
                        color=" #32325D"
                        lineHeight="22px"
                        opacity="0.8"
                      >
                        {i + 1}
                      </Text>
                    </Box>
                    <Box
                      ml={["7px", "14px"]}
                      display="flex"
                      alignItems="center"
                      background="#FFFFFF"
                      border=" 0.5px solid rgba(50, 50, 93, 0.5)"
                      borderRadius=" 80px"
                      w={"150px"}
                    >
                      <Box ml="14px" mt="9px" mb="9px">
                        <Avatar
                          name={item.user.firstname}
                          width="30px"
                          height="30px"
                          src={item.bannerImage}
                        />
                      </Box>
                      <Box pl="14px" pr="20px">
                        <Text
                          fontWeight="500"
                          fontSize="14px"
                          color=" #32325D"
                          lineHeight="21px"
                        >
                          {item.user.firstname}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
        <Button
          background="#FFFFFF"
          boxShadow="0px 0px 7.42829px rgba(114, 17, 212, 0.25)"
          borderRadius=" 4.64268px"
          width="32px"
          height="28px"
          position="absolute"
          marginTop="60px !important"
          zIndex="10"
          right="15px"
          onClick={() => swiperRef.current?.slideNext()}
        >
          <Image
            src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/67853fcc-a95e-4476-be48-14434b51b27d.svg`}
          />
        </Button>
      </Stack>
      {/* )} */}
    </>
  );
};

export default TopAffiliate;
