import {
  Box,
  Button,
  HStack,
  Image,
  Link,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { MdArrowDropDown } from "react-icons/md";
import useJsonToCSV from "../../../../../../hooks/useJsonToCSV";
import EarningsTable from "./EarningsTable";
import useUserToken from "../../../../../../hooks/useUserToken";

const Earnings = ({
  campaignName,
  id,
  currentAff,
}: {
  campaignName: string;
  id: string | undefined;
  currentAff: any;
}) => {
  const [filterData, setfilterData] = useState({
    date: "",
    status: "",
  });

  const token = useUserToken("affiliate");

  const [isLoading, setisLoading] = useState(true);

  const [earnings, setearnings] = useState<any[]>([]);
  const [filteredEarnings, setfilteredEarnings] = useState<any[]>([]);

  // const [highlighted, sethighlighted] = useState<any[]>([]);
  const [dataToDownLoad, setdataToDownLoad] = useState<any[]>([]);

  const getEarnings = (
    endpoint: string,
    setPlaceholder: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    if (!id) return setisLoading(false);

    setisLoading(true);
    let bearerAuth = token;

    fetch(process.env.REACT_APP_API_ENDPOINT + endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setPlaceholder(
            data.data.commissions.map((earning: any) => ({
              id: earning.id,
              createdAt: earning.createdAt,
              sales: earning.sales,
              earnings: earning.amount,
              status: earning.status,
              aff_orgId: earning.aff_org.id,
              adv_orgId: earning.adv_orgId,
              aff_orgName: earning.aff_org.companyname,
              aff_fullname:
                earning.aff_user.firstname + " " + earning.aff_user.lastname,
              campaignName,
              aff_email: earning.aff_user.email,
              productId: earning.conversion.metadata.product_id,
              affCountry: earning.aff_org.country,
              affState: earning.aff_org.state,
              affAddress: earning.aff_org.address,
              advertiserCountry: earning.adv_org.country,
              advertiserState: earning.adv_org.state,
              advertiserAddress: earning.adv_org.address,
            }))
          );
        }
        return setisLoading(false);
      })
      .catch((err) => {
        console.log("get earnings => " + err);
        return setisLoading(false);
      });
  };

  const noResult = () => {
    if (!isLoading && !earnings.length)
      return {
        result: true,
        reason:
          "No data available yet, when you have earnings you will see them here.",
      };

    if (!isLoading && !filteredEarnings.length && filterData.status)
      return { result: true, reason: "No Results Found" };

    if (!isLoading && !filteredEarnings.length && filterData.date)
      return { result: true, reason: "No Results Found" };

    return { result: false, reason: "" };
  };

  const ifFilterExists = () => {
    if (isLoading) return false;
    if (filterData.date || filterData.status) return true;
    return false;
  };

  useEffect(() => {
    if (filterData.date && !filterData.status) {
      getEarnings(
        `commissions/affiliate/get-commissions/${id}?date=${filterData.date}`,
        setfilteredEarnings
      );
    } else if (!filterData.date && filterData.status) {
      getEarnings(
        `commissions/affiliate/get-commissions/${id}?status=${filterData.status}`,
        setfilteredEarnings
      );
    } else if (filterData.date && filterData.status) {
      getEarnings(
        `commissions/affiliate/get-commissions/${id}?date=${filterData.date}&status=${filterData.status}`,
        setfilteredEarnings
      );
    } else {
      // do nothing
    }
  }, [filterData]);

  useEffect(() => {
    setfilterData({
      date: "",
      status: "",
    });
    getEarnings(`commissions/affiliate/get-commissions/${id}`, setearnings);
  }, [id]);

  useEffect(() => {
    let clonedHighlighted: any[] = [];

    earnings.forEach((item: any) => {
      clonedHighlighted.push({
        dateCreated: item?.createdAt || "",
        productId: item?.productId || "",
        sales: item?.sales || 0,
        status: item?.status || "",
        earnings: item?.earnings || 0,
      });
    });

    setdataToDownLoad(clonedHighlighted);
  }, [earnings]);

  const [csvFileDownloadLink] = useJsonToCSV(
    ["DATE CREATED", "PRODUCT ID", "SALES", "STATUS", "EARNINGS"],
    dataToDownLoad
  );

  return (
    <Stack pb="100px">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={{ md: "36px" }}
        mb="28px"
      >
        <HStack spacing="24px">
          <Select
            w={{ base: "85px", md: "120px" }}
            bg="white"
            placeholder="Date"
            onChange={(e) =>
              setfilterData({ ...filterData, date: e.target.value })
            }
            fontSize={{ base: "14px" }}
            icon={<MdArrowDropDown />}
          >
            <option value="last_day">Last Day</option>
            <option value="last_30_days">Last 30 Days</option>
            <option value="last_quater">Last Quarter</option>
            <option value="last_year">Last Year</option>
          </Select>

          <Select
            w={{ base: "85px", md: "120px" }}
            fontSize={{ base: "14px" }}
            bg="white"
            placeholder="Status"
            onChange={(e) =>
              setfilterData({ ...filterData, status: e.target.value })
            }
            icon={<MdArrowDropDown />}
          >
            <option value="paid">Paid</option>
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="overdue">Overdue</option>
            <option value="declined">Declined</option>
          </Select>
        </HStack>

        <Link
          href={csvFileDownloadLink}
          download={`Earnings.csv`}
          fontSize={{ base: "12px", md: "14px" }}
          lineHeight={{ base: "18px", md: "21px" }}
          fontWeight="500"
          width={{ base: "90px", md: "110px" }}
          height="38px"
          borderRadius="8px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="rgba(50, 50, 93, 0.8)"
          border="0.5px solid rgba(50, 50, 93, 0.4)"
          _hover={{ textDecoration: "none" }}
        >
          Export CSV
        </Link>
      </Box>

      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          h="40vh"
          alignItems="center"
          pt="20px"
        >
          <Image className="loading" src="/assets/logo.png" height="80px" />
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="70px"
            color="#000000"
            mt="-15px"
          >
            Loading Sales Table...
          </Text>
        </Box>
      ) : (
        <EarningsTable
          earnings={!ifFilterExists() ? earnings : filteredEarnings}
          // highlighted={highlighted}
          // sethighlighted={sethighlighted}
        />
      )}

      {noResult().result && (
        <Box
          w="100%"
          height="50vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={{ base: "0px 16px", md: "0px" }}
          mb="21px"
        >
          <Image
            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
            height="250px"
            objectFit="contain"
            mb="16px"
          />

          <Text
            fontWeight="600"
            fontSize="14px"
            lineHeight="21px"
            color="#32325D"
            textAlign="center"
          >
            {noResult().reason}
          </Text>
        </Box>
      )}
    </Stack>
  );
};

export default Earnings;
