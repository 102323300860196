import { Box, Image, Text } from "@chakra-ui/react";
import { BsCheckLg } from "react-icons/bs";
const SideBar = () => {
  return (
    <Box
      display={{ base: "none", md: "flex" }}
      width="50%"
      background="#F0F5FF"
      alignItems="flex-start"
      flexDirection="column"
      justifyContent="space-between"
      padding="71px 72px 55px 72px"
      h={"100vh"}
    >
      <Box>
        <Image src="/assets/logo.png" mb="1.5em" height="40px" />
      </Box>
      <Box py="60px">
        <Image
          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/0a753425-fb87-4fd3-9456-d2b05e8faff7.svg"
          mb="1.5em"
        />
      </Box>
      <Box>
        <Text
          color="#737373"
          fontSize="14px"
          fontWeight="500"
          lineHeight="24px"
        >
          Copyright © 2024 Peddle Technologies
        </Text>
      </Box>
    </Box>
  );
};

export default SideBar;
