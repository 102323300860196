import {
  Box,
  Button,
  Circle,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { TbHeadset } from "react-icons/tb";
import { ArrowLeftIcon, CheckIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { HiOutlineEmojiSad } from "react-icons/hi";
import { useState } from "react";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { useCookies } from "react-cookie";
import { getCookie } from "cookies-next";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
const Suspended = () => {
  const [cookies] = useCookies(["user_id", "access_token"]);
  const elements = useElements();
  const stripe = useStripe();
  const toastt = useToast();
  const navigate = useNavigate();
  const [ispaying, setPaying] = useState(false);
  const CARD_ELEMENT_OPTIONS = {
    showIcon: true,
    style: {
      base: {
        // iconColor: "#000",
        color: "#282838",
        border: "1px solid #000",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",

        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const unsuspend = async () => {
    // event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // setLoading(true);
    // @ts-ignore
    const card = elements.getElement(CardNumberElement);
    // @ts-ignore
    const result = await stripe.createToken(card);
    // @ts-ignore
    // console.log(result.token.id);
    if (result.error) {
      toast.error(result.error.message);
      // setLoading(false);
    } else {
      // Send the token to your server.
      console.log(result.token);

      const payload = {
        source: result.token.id,
        // @ts-ignore
        card_id: result.token.card.id,
        plan: "basic",
      };

      fetch(process.env.REACT_APP_API_ENDPOINT + "/billing/unsuspend", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.access_token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((data) => {
          // setLoading(false);
          console.log(data);

          if (!data.success) {
            toastt({
              title: data.message,
              status: "warning",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });

            return;
          }

          // toast.success("Card added successfully");

          toastt({
            title: "Congratulations, Kindly Login again",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top-right",
          });
          navigate("/login");
        })
        .catch((err) => {
          // setLoading(false);
          console.log(err);
        });
    }
  };
  return (
    <Flex
      direction={"column"}
      align={"center"}
      backgroundColor={"#f0f5ff"}
      justify={"center"}
      position={"relative"}
      h={"100vh"}
    >
      <Flex
        backgroundColor={"#fff"}
        shadow={"lg"}
        w={!ispaying ? "50%" : "60%"}
        h={"50vh"}
        justify={"center"}
        align={"center"}
      >
        {!ispaying ? (
          <Stack direction={"column"} gap={5} align={"center"}>
            <Flex
              borderRadius={20}
              w={"100px"}
              h={"100px"}
              shadow={"lg"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <HiOutlineEmojiSad size={50} color="#FCB713" />
            </Flex>
            <Heading textAlign={"center"} size={"md"}>
              Your account has been suspended!
            </Heading>
            <Text textAlign={"center"} w={"500px"} color={"#333333"}>
              Please clear any unpaid invoices and contact Support to restore
              your account
            </Text>
            <Flex direction={"column"} gap={3} w={"60%"}>
              <Button
                color="#fff"
                backgroundColor="#7211D4"
                borderRadius={10}
                py="7"
                px="6"
                lineHeight="1"
                size="lg"
                onClick={() => {
                  setPaying(true);
                }}
                //   rightIcon={<ChevronRightIcon />}
              >
                Pay invoices
              </Button>
              {/* <Button
                color="#000"
                backgroundColor="#fff"
                borderRadius={10}
                py="7"
                px="6"
                lineHeight="1"
                size="md"
                leftIcon={<TbHeadset />}
                // onClick={() => navigate("/login")}
              >
                Help Center
              </Button> */}
              <Button
                color="#000"
                backgroundColor="#fff"
                borderRadius={10}
                py="7"
                px="6"
                lineHeight="1"
                size="md"
                leftIcon={<ArrowLeftIcon />}
                onClick={() => navigate("/login")}
              >
                Back to Login
              </Button>
            </Flex>
          </Stack>
        ) : (
          <Stack direction={"row"} gap={5} align={"center"} width={"90%"}>
            <Stack direction={"column"} gap={5} align={"center"} width={"100%"}>
              <Heading size={"md"} alignSelf={"flex-start"}>
                Pay Invoice
              </Heading>
              <Box w="100%">
                <Box width="100%" borderColor="#000" borderRadius="10px">
                  <form onSubmit={(e) => console.log(e)}>
                    <label style={{ fontWeight: "500" }}>
                      Card number
                      <Box
                        w="full"
                        borderColor="#c4c4c4"
                        borderRadius="5px"
                        borderWidth="1px"
                        p={4}
                      >
                        <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                      </Box>
                    </label>
                    <Box my="1em">
                      <label style={{ fontWeight: "500" }}>
                        Expiration month and year
                        <Box
                          w="full"
                          borderColor="#c4c4c4"
                          borderRadius="5px"
                          borderWidth="1px"
                          p={4}
                        >
                          <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                        </Box>
                      </label>
                    </Box>

                    <label style={{ fontWeight: "500" }}>
                      CVC
                      <Box
                        w="full"
                        borderColor="#c4c4c4"
                        borderRadius="5px"
                        borderWidth="1px"
                        p={4}
                      >
                        <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                      </Box>
                    </label>
                  </form>
                </Box>
              </Box>
              <Button
                color="#fff"
                backgroundColor="#7211D4"
                borderRadius={10}
                py="7"
                px="6"
                lineHeight="1"
                size="lg"
                w={"100%"}
                //   rightIcon={<ChevronRightIcon />}
                onClick={() => {
                  //   setPaying(false);
                  unsuspend();
                }}
              >
                Pay
              </Button>
            </Stack>
            <Flex
              w={"50%"}
              borderLeftColor={"#32325D40"}
              borderLeftWidth={0.5}
              h={"40vh"}
              direction={"column"}
              align={"center"}
            >
              <Flex
                w={"90%"}
                h={"100%"}
                borderColor={"#32325D40"}
                borderWidth={0.5}
                direction={"column"}
                gap={6}
                borderRadius={10}
                p={5}
              >
                <Heading size={"lg"} color={"#32325D"}>
                  Starter
                </Heading>
                <Flex align={"baseline"}>
                  <Heading size={"lg"} color={"#32325D"}>
                    $56/
                  </Heading>
                  <Text color={"#32325D"}>monthly</Text>
                </Flex>
                <Flex gap={3} align={"center"}>
                  <Circle bgColor={"#00BA6C"} p={1.5}>
                    <CheckIcon color={"#fff"} fontSize={10} />
                  </Circle>
                  <Heading fontSize={12}>Unlimited Affiliates</Heading>
                </Flex>
                <Flex gap={3} align={"center"}>
                  <Circle bgColor={"#00BA6C"} p={1.5}>
                    <CheckIcon color={"#fff"} fontSize={10} />
                  </Circle>
                  <Heading fontSize={12}>Unlimited Campaigns</Heading>
                </Flex>
                <Flex gap={3} align={"center"}>
                  <Circle bgColor={"#00BA6C"} p={1.5}>
                    <CheckIcon color={"#fff"} fontSize={10} />
                  </Circle>
                  <Heading fontSize={12}>Unlimited Organizations</Heading>
                </Flex>
                <Flex gap={3} align={"center"}>
                  <Circle bgColor={"#00BA6C"} p={1.5}>
                    <CheckIcon color={"#fff"} fontSize={10} />
                  </Circle>
                  <Heading fontSize={12}>Unlimited Control</Heading>
                </Flex>
                <Flex gap={3} align={"center"}>
                  <Circle bgColor={"#00BA6C"} p={1.5}>
                    <CheckIcon color={"#fff"} fontSize={10} />
                  </Circle>
                  <Heading fontSize={12}>Custom affiliate onboarding</Heading>
                </Flex>
                <Flex gap={3} align={"center"}>
                  <Circle bgColor={"#00BA6C"} p={1.5}>
                    <CheckIcon color={"#fff"} fontSize={10} />
                  </Circle>
                  <Heading fontSize={12}>Find new affiliates</Heading>
                </Flex>
              </Flex>
            </Flex>
          </Stack>
        )}
      </Flex>
      <Image
        src="/assets/png/Ellipse 207.png"
        height="70px"
        w={"70px"}
        // objectFit="contain"
        position={"absolute"}
        top={210}
        left={210}
      />
      <Image
        src="/assets/png/Ellipse 206.png"
        height="40px"
        w={"40px"}
        // objectFit="contain"
        position={"absolute"}
        top={77}
        left={177}
      />
      <Image
        src="/assets/png/Ellipse 206.png"
        height="40px"
        w={"40px"}
        // objectFit="contain"
        position={"absolute"}
        top={196}
        right={400}
      />
      <Image
        src="/assets/png/Ellipse 203.png"
        height="56px"
        w={"56px"}
        // objectFit="contain"
        position={"absolute"}
        top={73}
        right={164}
      />
    </Flex>
  );
};

export default Suspended;
