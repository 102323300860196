import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { BsGlobe2, BsHash } from "react-icons/bs";
import { MdMail } from "react-icons/md";
import { AiFillPhone } from "react-icons/ai";

const RenderIcon = ({ type }: { type: string }) => {
  if (type === "emailQuestion") return <MdMail />;
  if (type === "websiteQuestion") return <BsGlobe2 />;
  if (type === "phoneQuestion") return <AiFillPhone />;
  if (type === "numberQuestion") return <BsHash />;

  return <Text></Text>;
};

type QuestionFormProps = {
  questions: any[];
  answers: any;
  setanswers: React.Dispatch<any>;
  defaultAns: string;
  setdefaultAns: React.Dispatch<React.SetStateAction<string>>;
};

const QuestionsForm = ({
  questions,
  answers,
  setanswers,
  defaultAns,
  setdefaultAns,
}: QuestionFormProps) => {
  const [checkBoxTraker, setcheckBoxTraker] = useState<any>({});

  const inputHasIcon = (id: string) => {
    if (
      id === "emailQuestion" ||
      id === "websiteQuestion" ||
      id === "phoneQuestion" ||
      id === "numberQuestion"
    )
      return true;

    return false;
  };

  const checkSingleChoice = (type: string, multipleChoice: boolean) => {
    if (type === "multipleChoiceQuestion" && !multipleChoice) return true;
    return false;
  };

  const checkMultiChoice = (type: string, multipleChoice: boolean) => {
    if (type === "multipleChoiceQuestion" && multipleChoice) return true;
    return false;
  };

  const handleFormChange = (e: any, id: number, type: string) => {
    let key = `q${id}`;

    setanswers({
      ...answers,
      [key]: { questionId: id, answer: e.target.value, type },
    });
  };

  const handleCheckChange = (option: any, id: number, type: string) => {
    let key = `q${id}`;

    if (!answers[key]) {
      setcheckBoxTraker({
        [option.id]: true,
      });

      return setanswers({
        ...answers,
        [key]: { questionId: id, answer: [option.id], type },
      });
    }

    if (answers[key].answer.includes(option.id)) {
      setanswers({
        ...answers,
        [key]: {
          questionId: id,
          answer: answers[key].answer.filter((item: any) => item !== option.id),
          type,
        },
      });
      setcheckBoxTraker({
        [option.id]: false,
      });
    } else {
      setanswers({
        ...answers,
        [key]: {
          questionId: id,
          answer: [...answers[key].answer, option.id],
          type,
        },
      });
      setcheckBoxTraker({
        [option.id]: true,
      });
    }
  };

  return (
    <Box maxHeight="40vh" overflowY="scroll">
      <FormControl marginBottom="28px">
        <FormLabel
          color="#32325D"
          fontSize="14px"
          lineHeight="17px"
          fontWeight="500"
          display="flex"
          alignItems="center"
        >
          <Text>How do you plan on promoting our campaign?</Text>{" "}
          <Text color="red.500">*</Text>
        </FormLabel>

        <Input
          type="text"
          value={defaultAns}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setdefaultAns(e.target.value)
          }
        />
      </FormControl>

      {questions.map((item: any) => (
        <FormControl key={item.id} marginBottom="28px">
          <FormLabel
            color="#32325D"
            fontSize="14px"
            lineHeight="17px"
            fontWeight="500"
            display="flex"
            alignItems="center"
          >
            <Text>{item?.label}</Text>{" "}
            {item.required && <Text color="red.500">*</Text>}
          </FormLabel>

          {inputHasIcon(item.type) && (
            <InputGroup>
              <InputLeftAddon children={<RenderIcon type={item.type} />} />
              <Input
                type={item.type === "numberQuestion" ? "number" : "text"}
                placeholder={item.placeholder}
                value={answers[`q${item.id}`]?.answer}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleFormChange(e, item.id, item.type)
                }
              />
            </InputGroup>
          )}

          {item.type === "textQuestion" && (
            <Input
              type="text"
              placeholder={item.placeholder}
              value={answers[`q${item.id}`]?.answer}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFormChange(e, item.id, item.type)
              }
            />
          )}

          {checkSingleChoice(item.type, item.multipleChoice) && (
            <Select
              placeholder={item.placeholder || " "}
              value={answers[`q${item.id}`]?.answer}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                handleFormChange(e, item.id, item.type)
              }
            >
              {item?.options.map((opt: any) => (
                <option value={opt.id}>{opt.label}</option>
              ))}
            </Select>
          )}

          {checkMultiChoice(item.type, item.multipleChoice) && (
            <Stack spacing={2}>
              {item?.options.map((opt: any) => (
                <Checkbox
                  colorScheme="purple"
                  isChecked={checkBoxTraker[opt.id]}
                  onChange={() => handleCheckChange(opt, item.id, item.type)}
                >
                  {opt?.label}
                </Checkbox>
              ))}
            </Stack>
          )}

          <FormHelperText
            fontSize="12px"
            lineHeight="17px"
            marginTop="8px"
            color="rgba(50, 50, 93, 0.6)"
          >
            {item?.help}
          </FormHelperText>
        </FormControl>
      ))}
    </Box>
  );
};

export default QuestionsForm;
