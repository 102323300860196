import {
  Avatar,
  Box,
  Image,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  useDisclosure,
  useMediaQuery,
  Button,
  Checkbox,
  Circle,
  useToast,
  Tooltip,
  Slide,
  IconButton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsChatDots, BsShare } from "react-icons/bs";
import { MdChevronRight, MdKeyboardBackspace } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import useUserToken from "../../../hooks/useUserToken";
import TabForm from "./tabForm";
import { motion } from "framer-motion";
import ChatComponent from "../../chat";
import ChatComponentCompose from "../../chat/compose";
import { getCurrentUserInstance } from "../../../state/localstorage";
import { useRecoilState } from "recoil";
import { chatState } from "../../../state/chat";
import { SendIcon } from "../../../assets";
import {
  ArrowDownIcon,
  ArrowRightIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import ChatElement from "../../newChat";

function AffiliateDetails() {
  const navigate = useNavigate();
  const toast = useToast();
  const token = useUserToken();
  const user = getCurrentUserInstance();

  const [loading, setLoading] = useState(false);
  const [affiliateData, setaffiliateData] = useState<any>({});
  let [searchParams, _] = useSearchParams();
  const [_Data, setChatList] = useRecoilState(chatState);
  const [campaign, setCampaigns] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [sCamp, setsCamp] = useState<any>([]);
  const [isChatOpen, setisChatOpen] = useState(false);
  const sendOfflineMessage = () => {
    console.log("it happened here");
  };

  const openChats = () => {
    setisChatOpen(true);
  };

  const closeChat = () => {
    setisChatOpen(false);
  };
  const findAffiliateData = (id: string | null) => {
    setLoading(true);

    let bearerAuth = token;
    fetch(
      process.env.REACT_APP_API_ENDPOINT + `/explore/find-affiliates/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("affData", data.data.affiliate);
        if (data.success) {
          setaffiliateData(data.data.affiliate);

          // console.log(
          //   "camptest",
          //   data.data.affiliate.aff_org_sharedCampaigns.map(
          //     (item: any, i: any) => {
          //       return item.campaign.id;
          //     }
          //   )
          // );
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("find affiliates: =>" + err);
      });

    // setLoading(false);
  };

  useEffect(() => {
    getCampaigns();
    if (searchParams.get("id") !== null) {
      findAffiliateData(searchParams.get("id"));
    }
    setChatList([]);
  }, []);

  const {
    isOpen: isOpenChat,
    onOpen: onOpenChat,
    onClose: onCloseChat,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [size, setSize] = React.useState("md");
  const [showCompose, setShowCompose] = useState(true);
  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  const [selectedItems, setSelectedItems]: any = useState([]);
  const [selected, setSelected]: any = useState({});
  const closeCompose = () => {
    setShowCompose(false);
  };

  const handleSizeClick = (newSize: string) => {
    setSize(newSize);
    onOpenChat();
  };
  const getCampaigns = () => {
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + "campaign/fetch-campaigns", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("campsshare", data.data.campaigns);
        if (data.success) {
          setCampaigns(data.data.campaigns);
        } else {
          // @ts-ignore
        }
      })
      .catch((err) => {
        setCampaigns([]);
      });
  };

  const inviteAffiliate = () => {
    let bearerAuth = token;

    // let affiliatePayload = getAffiliatePayload();

    const campaignIds = selectedItems.map((item: any) => {
      return item.campaignId;
    });

    const List1 = campaignIds.filter(
      (item: any) => !affiliateData.aff_org_sharedCampaigns.includes(item)
    );
    // const List2 = affiliateData.aff_org_sharedCampaigns.filter(
    //   (item: any) => !campaignIds.includes(item)
    // );
    // const campRemove = affiliateData.aff_org_sharedCampaigns.filter(
    //   (itemS: any) => campaignIds.some((item: any) => item === itemS)
    // );

    console.log("campRemove", List1);

    const payload = {
      campaignIds: List1,
      affiliateData: {
        email: affiliateData?.user?.email,
        firstname: affiliateData?.user?.firstname,
        status: "approved",
        affiliate_userId: affiliateData?.user?.id,
        affiliate_orgId: Number(searchParams.get("id")),
      },
    };
    console.log("payload", payload);

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `promote-campaign/invite-affiliate-to-campaigns`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          onClose();
          onCloseChat();
          findAffiliateData(searchParams.get("id"));
          return toast({
            title: "Invite has been sent to the affiliate(s) successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          return toast({
            title: "Failed to send invite(s)",
            description: data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      });
  };

  const handleSelect = (item: any) => {
    console.log("selectedItems", selectedItems, item);
    const isSelected = selectedItems.includes(item);
    setSelectAll(false);
    if (isSelected) {
      // If item is already selected, remove it from the selectedItems array
      setSelectedItems((prevSelectedItems: any) =>
        prevSelectedItems.filter((selectedItem: any) => selectedItem !== item)
      );
    } else {
      // If item is not selected, add it to the selectedItems array
      setSelectedItems((prevSelectedItems: any) => [
        ...prevSelectedItems,
        item,
      ]);
    }
  };
  const handleSelectAll = () => {
    setSelectAll(!selectAll);

    // If selectAll is true, select all items, else deselect all items
    if (selectAll) {
      setSelectedItems([]);
    } else {
      const allItemIds = campaign
        .filter((item: any) => item?.campaignStatus === "active")
        .map((item) => item);
      setSelectedItems(allItemIds);
    }
  };

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        h="70vh"
        alignItems="center"
        pt="20px"
      >
        <Image className="loading" src="/assets/logo.png" height="60px" />

        <Text
          fontWeight="400"
          fontSize="16px"
          lineHeight="70px"
          color="#000000"
          mt="-15px"
        >
          Fetching Affiliate details...
        </Text>
      </Box>
    );

  return (
    <>
      <Box bgColor="#F1F4FA" pb="20px">
        <Box
          display="flex"
          // mb="20px"
          padding="10px 20px"
          alignItems="center"
          // bgColor={{ base: "white", md: "#F3F3F9" }}
          // css={{
          //   boxShadow: "0px 12px 24px rgba(190, 190, 190, 0.25)",
          // }}
        >
          <Text
            fontSize="12px"
            fontWeight="500"
            lineHeight="18px"
            color="#32325D"
            mr="5px"
            cursor="pointer"
            _hover={{ textDecoration: "underline" }}
            onClick={() => navigate(`/explore`)}
          >
            Explore
          </Text>

          <MdChevronRight size={20} color="#32325D" />

          <Text
            fontSize="12px"
            lineHeight="18px"
            color="#32325D"
            fontWeight="400"
            ml="5px"
          >
            {affiliateData.user
              ? affiliateData?.user?.firstname +
                " " +
                affiliateData?.user?.lastname
              : ""}
          </Text>
        </Box>

        <Box
          margin={{ base: "0px", md: "0px 20px" }}
          pl={{ base: "20px", md: "0px" }}
          pr={{ base: "20px", md: "0px" }}
          pb="110px"
          bgColor="white"
          borderRadius={8}
          position="relative"
        >
          <Image
            src={
              "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/1bc4be2d-94c3-41b1-8425-ad4d6efc2805.png"
            }
            h={{ base: "95px", md: "210px" }}
            w="100%"
            borderRadius="8px"
            objectFit="cover"
          />

          <Box
            position="absolute"
            top={{ base: "55px", md: 135 }}
            left={{ base: "19px", md: 15 }}
            display="flex"
            flexDirection="column"
            pl="19px"
            alignItems={{ base: "start", md: "center" }}
          >
            {affiliateData?.user?.profile_image ? (
              <Image
                src={affiliateData?.user?.profile_image || ""}
                h={{ base: "82px", md: "135px" }}
                w={{ base: "82px", md: "135px" }}
                mb="9px"
                objectFit="cover"
              />
            ) : (
              <Avatar
                bg="purple.300"
                mb="9px"
                size={isDesktop ? "2xl" : "lg"}
              />
            )}

            <Text
              fontSize={{ base: "16px", md: "24px" }}
              lineHeight={{ base: "24px", md: "36px" }}
              fontWeight={{ base: "500", md: "600" }}
              color="#32325D"
            >
              {affiliateData.user
                ? affiliateData?.user?.firstname +
                  " " +
                  affiliateData?.user?.lastname
                : "-  -"}
            </Text>
          </Box>

          <Box
            display={{ base: "none", md: "flex" }}
            mt="47px"
            pr="28px"
            flexDirection="column"
            alignItems="end"
          >
            <Box
              display="flex"
              alignItems="center"
              cursor="pointer"
              mb={"13px"}
              onClick={() => navigate(-1)}
            >
              <MdKeyboardBackspace color="#32325D" />
              <Text
                color="#32325D"
                lineHeight="40px"
                fontSize="14px"
                fontWeight="400"
                pl="8px"
              >
                Back to the previous page
              </Text>
            </Box>

            <Flex align={"center"} gap={5}>
              {/* <Box height="45px" width="45px" ml="14px" position="relative">
                <IconButton
                  height="100%"
                  width="100%"
                  variant="ghost"
                  padding="4px"
                  onClick={openChats}
                  aria-label="open chat"
                  icon={
                    <Image src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/7a074a02-6aaa-46d4-8fe3-2a3c262defb4.png" />
                  }
                />
              </Box> */}
              <Box
                color="#7211D4"
                cursor={"pointer"}
                bgColor="#F1F3FA"
                display={{ base: "none", md: "flex" }}
                borderRadius="8px"
                alignItems="center"
                padding="7px 14px"
                onClick={() => {
                  onOpenChat();
                }}
              >
                <BsShare height={17} width={17} />
                <Text
                  fontSize="14px"
                  lineHeight="21px"
                  fontWeight="500"
                  ml="8px"
                >
                  Share Campaign
                </Text>
              </Box>
            </Flex>

            <Modal
              onClose={onCloseChat}
              size={"xl"}
              isOpen={isOpenChat}
              scrollBehavior="inside"
              isCentered
            >
              <ModalOverlay />
              <ModalContent borderRadius="10px 10px 10px 10px;" pb={10}>
                <ModalHeader
                  // background="#32325D"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius="14px 14px 0px 0px;"
                >
                  <Text fontSize={"15px"}>Campaigns</Text>
                  <Button
                    background="#7311D4"
                    borderRadius="6px"
                    size={"sm"}
                    onClick={() => onOpen()}
                  >
                    <Text
                      mr="5px"
                      fontSize="12px"
                      // lineHeight="21px"
                      color="#ffffff"
                    >
                      Send
                    </Text>
                    {/* <Image src={SendIcon} /> */}
                    <ChevronRightIcon color={"#fff"} fontSize={"20px"} />
                  </Button>
                </ModalHeader>

                <ModalBody position="relative">
                  {/* <ChatComponent /> */}
                  <Flex
                    w={"100%"}
                    justify={"space-between"}
                    align={"center"}
                    borderBottom="1px solid rgba(50, 50, 93, 0.25)"
                    pb={3}
                  >
                    <Flex
                      justify={"space-arround"}
                      align={"center"}
                      gap={3}
                      w={"80%"}
                    >
                      <Checkbox
                        colorScheme="purple"
                        spacing="14px"
                        isChecked={selectAll}
                        onChange={handleSelectAll}
                      />
                      <Text fontSize={"12px"}>Campaign name</Text>
                      <ArrowDownIcon />
                    </Flex>
                    <Flex w={"20%"}>
                      <Text fontSize={"12px"}>Status</Text>
                    </Flex>
                  </Flex>

                  <Box
                    bgColor="#ffffff"
                    // boxShadow="2xl"
                    borderRadius="9px"
                    width="100%"
                    // h="350px"
                    // overflowY="scroll"
                  >
                    {campaign
                      .filter((item: any) => item?.campaignStatus == "active")
                      .map((item: any) => {
                        return (
                          <Flex
                            key={item.id}
                            justify={"space-between"}
                            align={"center"}
                            // my={3}
                            borderBottom="1.5px solid rgba(0, 0, 0, 0.06)"
                          >
                            <Flex
                              height="60px"
                              w={"80%"}
                              _hover={{ bgColor: "gray.50" }}
                              alignItems={"center"}
                              gap={3}
                              // onClick={() => {
                              //   handleSelect(item);
                              // }}
                            >
                              {affiliateData.aff_org_sharedCampaigns.includes(
                                item?.campaignId
                              ) ? (
                                <Tooltip
                                  label={
                                    "This campaign has been sent to this Affiliate already"
                                  }
                                  placement="right"
                                  fontSize="md"
                                >
                                  <Box
                                    h={4}
                                    w={4}
                                    borderRadius={4}
                                    borderWidth={0.5}
                                    bgColor={"#c4c4c4"}
                                  ></Box>
                                </Tooltip>
                              ) : (
                                <Checkbox
                                  colorScheme="purple"
                                  spacing="14px"
                                  isChecked={selectedItems.includes(item)}
                                  onChange={() => handleSelect(item)}
                                />
                              )}

                              <Avatar
                                name={item.campaignName}
                                src={item.bannerImage}
                                size={"sm"}
                              />

                              <Text
                                fontWeight="400"
                                fontSize="12px"
                                textTransform="capitalize"
                                color="#32325D"
                              >
                                {item.campaignName}
                              </Text>
                            </Flex>
                            <Flex w={"20%"}>
                              <Flex
                                alignItems="center"
                                bgColor={"#ECFEF3"}
                                h={"18px"}
                                p={"10px"}
                                borderRadius={"10px"}
                              >
                                <Box
                                  backgroundColor={"#239667"}
                                  height="7px"
                                  width="7px"
                                  borderRadius="50%"
                                ></Box>

                                <Text
                                  color="#047A48"
                                  lineHeight="21px"
                                  fontSize="12px"
                                  fontWeight="500"
                                  ml="6px"
                                  textTransform="capitalize"
                                >
                                  {item?.campaignStatus}
                                </Text>
                              </Flex>
                            </Flex>
                          </Flex>
                        );
                      })}
                  </Box>
                </ModalBody>
                {/* {showCompose && user.organisation.userType !== "affiliate" && (
                  <ModalFooter background="transparent">
                    <ChatComponentCompose closeCompose={closeCompose} />
                  </ModalFooter>
                )} */}
                {/* {!showCompose && user.organisation.userType !== "affiliate" && (
                  <Flex
                    alignItems="center"
                    position="absolute"
                    justifyContent="flex-end"
                    bottom="0px"
                    right="0px"
                  >
                    <Image
                      cursor="pointer"
                      src={`/assets/svg/combined-shape.svg`}
                      onClick={() => setShowCompose(true)}
                    />
                  </Flex>
                )} */}
              </ModalContent>
            </Modal>
            <Modal onClose={onClose} size={"sm"} isOpen={isOpen} isCentered>
              <ModalOverlay />
              <ModalContent
                // mx={{ base: ".6em", md: "auto" }}
                // marginTop={{ base: "15em", md: "18em" }}
                borderRadius={10}
                paddingBottom={5}
              >
                {/* <ModalCloseButton /> */}
                <ModalBody>
                  <Flex py="1em" direction={"column"} gap={3}>
                    <Circle size="50px" bg="#F4EFFA">
                      <Circle size="30px" bg="#E5D5F2" color="#7211D4">
                        <BsShare size={15} />
                      </Circle>
                    </Circle>
                    <Flex direction={"column"} gap={3}>
                      <Text fontWeight={600} fontSize={"1.5em"}>
                        Share campaigns
                      </Text>
                      <Text color={"#667085"} fontSize={"12px"}>
                        This action will share the selected campaigns with this
                        affiliate. Continue?
                      </Text>
                    </Flex>
                  </Flex>
                </ModalBody>

                <ModalFooter
                  w={"100%"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Button
                    borderRadius="8px"
                    bgColor="#ffff"
                    color="#000"
                    p={5}
                    borderWidth={0.5}
                    borderColor={"#c4c4c4"}
                    w={"48%"}
                    onClick={() => onClose()}
                  >
                    {/* <IoPersonOutline fontSize={14} /> */}
                    <Text
                      fontWeight="600"
                      fontSize={{ base: "12px", md: "14px" }}
                      lineHeight={{ base: "18px", md: "21px" }}
                      onClick={onClose}
                    >
                      No, Cancel
                    </Text>
                  </Button>

                  <Button
                    w={"48%"}
                    p={5}
                    bgColor="#7F56DA"
                    color="white"
                    borderRadius="8px"
                    fontWeight="600"
                    fontSize={{ base: "12px", md: "14px" }}
                    lineHeight={{ base: "18px", md: "21px" }}
                    ml="13px"
                    onClick={() => {
                      // onClose();
                      inviteAffiliate();
                    }}
                  >
                    Yes, Continue
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Box>

          <Box
            padding={{ base: "0px", md: "0px 28px" }}
            mt={{ base: "120px", md: "53px" }}
            mb={{ base: "56px", md: "0px" }}
          >
            <TabForm affiliateDetails={affiliateData} />
            {isChatOpen ? (
              <Slide direction="bottom" in={isChatOpen}>
                <ChatElement
                  onClose={closeChat}
                  data={{
                    unreadMessageCount: 3,
                    lastMessage: "",
                    username: affiliateData.companyname,
                    lastMessageDate: Date.now(),
                    userImage: affiliateData.user.profile_image,
                    chatId: affiliateData.id,
                    isOnline: true,
                  }}
                />
              </Slide>
            ) : (
              ""
            )}
          </Box>

          <Box
            color="white"
            bgColor="#7211D4"
            display={{ base: "flex", md: "none" }}
            borderRadius="8px"
            alignItems="center"
            justifyContent="center"
            width="100%"
            padding="12px 14px"
            onClick={() => {
              setChatList((oldChatList: any) => [
                ...oldChatList,
                {
                  value: affiliateData.user,
                  checked: true,
                },
              ]);
              handleSizeClick("lg");
            }}
          >
            <BsChatDots height={17} width={17} />
            <Text fontSize="16px" lineHeight="24px" fontWeight="500" ml="8px">
              Chat
            </Text>
          </Box>

          <Box
            display={{ base: "flex", md: "none" }}
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            mt="32px"
            onClick={() => navigate(-1)}
          >
            <MdKeyboardBackspace color="#32325D" />
            <Text
              color="#32325D"
              lineHeight="40px"
              fontSize="14px"
              fontWeight="400"
              pl="8px"
            >
              Back to the previous page
            </Text>
          </Box>
          {/* ------ */}
        </Box>
        {/* end line */}
      </Box>
    </>
  );
}

export default AffiliateDetails;
