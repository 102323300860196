import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { BsCheckSquareFill, BsFillCaretUpFill, BsSearch } from "react-icons/bs";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { MdCancel, MdKeyboardBackspace, MdOutlineAddBox } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useOutsideAlerter } from "../../../hooks/useOutsideAlerter";
import ManagersTable from "./components/ManagersTable";
import ManagersForm from "./components/ManagersForm";
import useUserToken from "../../../hooks/useUserToken";
import { gsap, Power2 } from "gsap";

type ManagerType = {
  id: number;
  campaignName: string;
  firstName: string;
  lastName: string;
  email: string;
  signupDate: string;
  lastActiveDate: string;
  status: string;
  campaignIds: any[];
};

const Managers = () => {
  const navigate = useNavigate();
  const [showIndustries, setShowIndustries] = useState(false);

  const toggleHandler = () => {
    return setShowIndustries(!showIndustries);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [refreshField, setrefreshField] = useState(0);
  const [campaigns, setcampaigns] = useState<any[]>([]);
  const [managers, setmanagers] = useState<any[]>([]);
  const [filteredManagers, setfilteredManagers] = useState<any[]>([]);
  const [loading, setloading] = useState(false);

  const {
    isOpen: isOpenManagerModal,
    onOpen: onOpenManagerModal,
    onClose: onCloseManagerModal,
  } = useDisclosure();

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setShowIndustries(false));
  const token = useUserToken("affiliate");

  const getCampaigns = () => {
    let bearerAuth = token;
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `/promote-campaign/affiliate/campaigns?status=approved`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setcampaigns(
            data.data.campaigns.map((item: any) => ({
              name: item.campaign.campaign_name || "",
              id: item.campaignId,
              selected: false,
            }))
          );
        }
      })
      .catch((err) => {
        console.log("get campaign => " + err);
      });
  };

  const getManagers = () => {
    let bearerAuth = token;
    setloading(true);

    fetch(process.env.REACT_APP_API_ENDPOINT + `/all/users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setmanagers(
            data.data.users.map((user: any) => {
              return {
                id: user.id,
                campaignName:
                  user.campaigns && user.campaigns.length
                    ? user.campaigns.join(", ")
                    : "",
                firstName: user.firstname,
                lastName: user.lastname,
                email: user.email,
                signupDate: user.createdAt,
                lastActiveDate: user.last_login,
                status: user.role
                  ? user.role.role_name.split("_").join(" ")
                  : "",
                permissionId: user.permissionId || 0,
                invitedBy: user.invitedBy || 0,
                campaignIds: user.campaignIds || [],
              };
            })
          );
        }
        setloading(false);
      })
      .catch((err) => {
        console.log("get campaign => " + err);
        setloading(false);
      });
  };

  const filterManagers = () => {
    let selectedCampaigns = campaigns
      .filter((item) => item.selected)
      .map((item) => item.id);

    let filtered: any[] = [];
    let exists: { [key: string]: boolean } = {};

    selectedCampaigns.forEach((id) => {
      managers.forEach((manager: ManagerType) => {
        if (manager && manager.status === "admin" && !exists[`${manager.id}`]) {
          filtered.push(manager);
          exists[`${manager.id}`] = true;
        }
        if (
          manager &&
          manager.campaignIds.includes(id) &&
          !exists[`${manager.id}`]
        ) {
          filtered.push(manager);
          exists[`${manager.id}`] = true;
        }
      });
    });

    return filtered;
  };

  const filterExist = () => {
    let selectedCampaigns = campaigns.filter((item) => item.selected);

    if (selectedCampaigns.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const toast = useToast();

  const openAddNewModal = () => {
    if (loading) return;
    if (!loading && campaigns.length === 0)
      return toast({
        title:
          "You have to be approved to at least one campaigns to add managers",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });

    onOpenManagerModal();
  };

  useEffect(() => {
    getCampaigns();
    gsap.fromTo(
      ".loading",
      {
        opacity: 0,
        ease: Power2.easeInOut,
        scale: 0,
        y: 80,
      },
      {
        opacity: 1,
        duration: 1.5,
        scale: 1,
        ease: Power2.easeInOut,
        repeat: -1,
        y: 0,
        yoyo: true,
      }
    );
  }, []);

  useEffect(() => {
    setfilteredManagers(filterManagers());
  }, [campaigns]);

  useEffect(() => {
    getManagers();
  }, [refreshField]);

  return (
    <Box mt="30px" bgColor="white" p="25px 30px" minH="80vh">
      <Box
        display="flex"
        alignItems="center"
        cursor="pointer"
        onClick={() => navigate(`/settings`)}
        pb="16px"
        borderBottom="1px solid rgba(50, 50, 93, 0.30)"
      >
        <MdKeyboardBackspace color="#32325D" />
        <Text
          color="#32325D"
          lineHeight="40px"
          fontSize="16px"
          fontWeight="500"
          ml="8px"
        >
          Back to the previous page
        </Text>
      </Box>

      <Box
        display="flex"
        flexDir={{ base: "column", md: "row" }}
        alignItems={{ md: "center" }}
        py="25px"
      >
        <Button
          bgColor="rgba(114, 17, 212, 0.06)"
          border="0.5px solid rgba(50, 50, 93, 0.20)"
          color="rgba(50, 50, 93, 0.80)"
          borderRadius="8px"
          mr={{ md: "17px" }}
          maxW={{ base: "60%", md: "auto" }}
          mb={{ base: "17px", md: "0px" }}
          onClick={openAddNewModal}
        >
          <MdOutlineAddBox fontSize={15} />
          <Text
            fontSize={{ base: "12px", md: "14px" }}
            fontWeight="500"
            lineHeight={{ base: "18px", md: "21px" }}
            ml="7px"
          >
            Add New Manager
          </Text>
        </Button>

        <Box position="relative" ref={wrapperRef}>
          <Flex w="100%" columnGap=".5em" alignItems="center">
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              border="0.6px solid"
              borderColor="rgba(50, 50, 93, 0.30)"
              borderRadius="8px"
              p=".6em"
              cursor={"pointer"}
              onClick={toggleHandler}
            >
              <Flex columnGap=".5em" mr="10px" rowGap=".5em" flexWrap="wrap">
                {campaigns.filter((campaign: any) => campaign.selected)
                  .length >= 1 ? (
                  campaigns
                    .filter((campaign: any) => campaign.selected)
                    .map((campaign: any, i: number) => {
                      return (
                        <Box
                          key={i}
                          bgColor="#F0F5FF"
                          py={["3px", "3px", "6px"]}
                          px=".4em"
                          alignItems="flex-start"
                          display="flex"
                          borderRadius="4px"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Text
                            mr=".8em"
                            fontSize="14px"
                            fontWeight="400"
                            color="gray.800"
                          >
                            {campaign.name}
                          </Text>
                          <MdCancel
                            color="#000"
                            cursor="pointer"
                            onClick={() => {
                              const newState = campaigns.map(
                                (data: any, x: any) => {
                                  if (campaign.name === data.name) {
                                    return { ...data, selected: false };
                                  } else {
                                    return data;
                                  }
                                }
                              );
                              setcampaigns(newState);
                            }}
                          />
                        </Box>
                      );
                    })
                ) : (
                  <Text>Campaigns</Text>
                )}
              </Flex>

              <Box>
                {!showIndustries ? (
                  <IoChevronDown cursor={"pointer"} onClick={toggleHandler} />
                ) : (
                  <IoChevronUp cursor={"pointer"} onClick={toggleHandler} />
                )}
              </Box>
            </Box>
          </Flex>

          {showIndustries && (
            <Box
              p={["1em", "1em", "1.5em"]}
              my="1em"
              boxShadow="xl"
              rounded="xl"
              zIndex="3"
              background="white"
              position="absolute"
              maxHeight="60vh"
              w="max-content"
              overflowY="scroll"
            >
              <Flex
                alignItems="flex-start"
                justifyContent="space-between"
                my=".2em"
              >
                <Text fontSize="14px" color="gray.400" fontWeight="400">
                  Search Campaign
                </Text>

                <BsFillCaretUpFill
                  color="#CACBD4"
                  cursor="pointer"
                  onClick={toggleHandler}
                />
              </Flex>

              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<BsSearch color="gray.300" />}
                />
                <Input
                  type="text"
                  _focus={{ ring: "0" }}
                  border="0"
                  bgColor="#F4F6F8"
                  value={searchQuery}
                  onChange={(e: React.ChangeEvent<any>) =>
                    setSearchQuery(e.target.value)
                  }
                />
              </InputGroup>

              {campaigns.map((campaign: any, i: any) => {
                return (
                  <Box
                    key={i}
                    display={
                      searchQuery !== "" &&
                      !campaign.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                        ? "none"
                        : "flex"
                    }
                    justifyContent="space-between"
                    alignItems="flex-start"
                    my="1em"
                    cursor="pointer"
                    _hover={{ bgColor: "gray.50" }}
                    onClick={() => {
                      const newState = campaigns.map((data: any, x: any) => {
                        if (campaigns[i] == data) {
                          return { ...data, selected: !data.selected };
                        } else {
                          return data;
                        }
                      });
                      setcampaigns(newState);
                    }}
                    p=".5em"
                    borderRadius="3px"
                  >
                    <Text marginRight="0.5rem">{campaign.name}</Text>

                    {campaign.selected ? (
                      <BsCheckSquareFill color="#5C15CE" />
                    ) : (
                      <Box
                        width="1em"
                        height="1em"
                        border="1.5px solid gray"
                        borderRadius="4px"
                      ></Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </Box>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          h="50vh"
          alignItems="center"
          pt="20px"
        >
          <Image className="loading" src="/assets/logo.png" height="60px" />
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="70px"
            color="#000000"
            mt="-15px"
          >
            Loading Managers Table...
          </Text>
        </Box>
      ) : (
        <ManagersTable
          managers={filterExist() ? filteredManagers : managers}
          campaigns={campaigns}
          setRefreshField={setrefreshField}
        />
      )}

      {campaigns && campaigns.length ? (
        <ManagersForm
          isOpen={isOpenManagerModal}
          onClose={onCloseManagerModal}
          setRefresh={setrefreshField}
          type="New"
          campaigns={campaigns && campaigns.length ? campaigns : []}
        />
      ) : (
        ""
      )}
    </Box>
  );
};

export default Managers;
