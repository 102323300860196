// @ts-ignore
import { getList } from "country-list-with-dial-code-and-flag";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";

import { BsCheckSquareFill, BsFillCaretUpFill, BsSearch } from "react-icons/bs";
import React, { useEffect, useState, useRef } from "react";

import {
  IoArrowBackSharp,
  IoArrowForwardSharp,
  IoChevronDown,
  IoChevronUp,
} from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdCancel } from "react-icons/md";
import { useCookies } from "react-cookie";
import { toast, ToastContainer } from "react-toastify";
import { useOutsideAlerter } from "../../../hooks/useOutsideAlerter";
import {
  getCurrentUserInstance,
  setLocalUserInstance,
} from "../../../state/localstorage";
import OrganizationSettingsFooter from "../components/OrganizationSettingsFooter";

const ProductDetails = ({
  orgrawShipToCountries,
  currentStep,
  setCurrentStep,
  getOrgDetails,
  setrefreshData,
}: {
  orgrawShipToCountries: [];
  currentStep: string;
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>;
  getOrgDetails: any;
  setrefreshData: React.Dispatch<React.SetStateAction<number>>;
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [orgType, setOrgType] = useState(false);
  interface DataItem {
    defaultIcon: string;
    coloredIcon: string;
    title: string;
    name: string;
    selected: boolean;
  }
  const details: DataItem[] = [
    {
      defaultIcon:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/dee212f9-5a5d-4fd6-869d-b4e0fa080ed3.svg",
      coloredIcon: "/assets/svg/colored-ecommerce.svg",
      title: "E-commerce",
      name: "ecommerce",
      selected: false,
    },
    {
      defaultIcon:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/a7631722-e9dd-4f15-94de-f9c97efe7cba.svg",
      coloredIcon:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/dfee292c-71af-43d6-87c9-5307de051e34.svg",
      title: "SaaS",
      name: "saas",
      selected: false,
    },
  ];
  useEffect(() => {
    if (searchParams.get("type") !== null) {
      setOrgType(searchParams.get("type") ? true : false);
    }
    const countryList = getList().map((country: any) => {
      return { country, selected: false };
    });
    countryList.forEach((item: any) => {
      // console.log(item.country.name);
      if (localUser.organisation.countriesToShip.includes(item.country.name)) {
        item.selected = true;
      }
    });
    console.log(countryList);
    setAllCountries(countryList);

    // console.log(localUser.organisation.productType);
    details.forEach((item: any) => {
      if (localUser.organisation.productType.includes(item.name)) {
        item.selected = true;
      }
    });
    console.log("card details", localUser.organisation);
    // @ts-ignore
    setCardData(details);
  }, [searchParams, orgType]);
  const [cookies] = useCookies(["user_id", "access_token"]);

  const localUser = getCurrentUserInstance();

  const [allCountries, setAllCountries] = useState(
    getList().map((country: any) => {
      return { country, selected: false };
    })
  );
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const [newUpdate, setnewUpdate] = useState(false);
  const [count, setcount] = useState(1);

  const toggleHandler = () => {
    return setShowFilter(!showFilter);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setShowFilter(false));

  const [cardData, setCardData] = useState([]);

  const [saveAndExit, setSaveAndExit] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (count > 1) {
      setnewUpdate(true);
      sessionStorage.setItem("advertizerSettingsChange", "new update");
    }
    setcount(count + 1);
  }, [cardData]);
  const submitHandler = () => {
    if (
      cardData.filter((dt: any) => dt.selected).length < 1 ||
      allCountries.filter((countryData: any) => countryData.selected).length < 1
    ) {
      toast.warning("Please complete all required fields or save and exit");
      return;
    }

    setLoading(true);
    const payload = {
      productType: cardData
        .filter((dt: any) => dt.selected)
        .map((data: any) => data.title.split("-").join("").toLowerCase()),
      countriesToShip: allCountries
        .filter((countryData: any) => countryData.selected)
        .map((data: any) => data.country.name),
    };

    fetch(process.env.REACT_APP_API_ENDPOINT + "auth/signup/step4", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.access_token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);

        if (data.success) {
          setLocalUserInstance({
            ...localUser,
            rawShipToCountries: allCountries,
            rawProductTypes: cardData,
          });
          const user = getCurrentUserInstance();
          user.organisation.signup_step = 4;
          setLocalUserInstance(user);
          if (orgType) {
            saveAndExit
              ? window.open("https://www.Metricks.io", "_self")
              : navigate("/signup/niche?type=newOrganization");
          } else {
            saveAndExit
              ? window.open("https://www.Metricks.io", "_self")
              : navigate("/signup/niche");
          }
        }
        console.log(data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const updateData = {
    productType: cardData
      .filter((dt: any) => dt.selected)
      .map((data: any) => data.title.split("-").join("").toLowerCase()),
    countriesToShip: allCountries
      .filter((countryData: any) => countryData.selected)
      .map((data: any) => data.country.name),
  };
  const validator = () => {
    if (
      cardData.filter((dt: any) => dt.selected).length < 1 ||
      allCountries.filter((countryData: any) => countryData.selected).length < 1
    ) {
      return { isValid: false, error: "An asset has to be selected" };
    }

    return { isValid: true, error: "" };
  };

  return (
    <>
      <Box ref={wrapperRef}>
        <ToastContainer />
        {/* <Heading fontSize="1rem" color="#32325D" fontWeight={"600"} pb="34px">
          {currentStep}
        </Heading> */}
        <Heading fontSize="1em" mb="1em">
          What countries can you ship your products to?
          <span style={{ color: "red", marginLeft: 5 }}>*</span>
        </Heading>
        <Text fontSize={16}>Country</Text>

        <Flex
          borderBottom="1px solid #B6BDC3"
          pb=".3em"
          justifyContent="space-between"
          alignItems="flex-start"
          ref={wrapperRef}
          cursor="pointer"
          onClick={() => {
            if (!showFilter) {
              setShowFilter(true);
            }
          }}
        >
          <Flex rowGap=".5em" columnGap=".5em" flexWrap="wrap">
            {allCountries.filter((countryData: any) => countryData.selected)
              .length >= 1 ? (
              allCountries
                .filter((countryData: any) => countryData.selected)
                .map((data: any, i: any) => {
                  return (
                    <Box
                      key={i}
                      bgColor="#F0F5FF"
                      py={["3px", "3px", "6px"]}
                      px=".4em"
                      alignItems="center"
                      display="flex"
                      borderRadius="4px"
                    >
                      <Box>{data.country.flag}</Box>
                      <Text
                        mr=".8em"
                        ml=".5em"
                        fontSize="14px"
                        fontWeight="400"
                        color="gray.800"
                      >
                        {data.country.name}
                      </Text>

                      <MdCancel
                        color="#000"
                        cursor="pointer"
                        onClick={(e: React.ChangeEvent<any>) => {
                          const newState = allCountries.map(
                            (data: any, x: any) => {
                              if (allCountries[i] == data) {
                                return { ...data, selected: !data.selected };
                              } else {
                                return data;
                              }
                            }
                          );
                          setAllCountries(newState);
                        }}
                      />
                    </Box>
                  );
                })
            ) : (
              <Text cursor="pointer" onClick={toggleHandler}>
                Select countries
              </Text>
            )}
          </Flex>
          <Box>
            {!showFilter ? (
              <IoChevronDown cursor="pointer" onClick={toggleHandler} />
            ) : (
              <IoChevronUp cursor="pointer" onClick={toggleHandler} />
            )}
          </Box>
        </Flex>

        {showFilter && (
          <Box
            p={["1em", "1em", "1.5em"]}
            my="1em"
            boxShadow="xl"
            rounded="xl"
            height="60vh"
            overflowY="scroll"
            ref={wrapperRef}
          >
            <Flex alignItems="center" justifyContent="space-between" my=".5em">
              <Text fontSize="14px" color="gray.400" fontWeight="400">
                Search Country
              </Text>

              <BsFillCaretUpFill
                color="#CACBD4"
                cursor="pointer"
                onClick={toggleHandler}
              />
            </Flex>

            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<BsSearch color="gray.300" />}
              />
              <Input
                type="text"
                _focus={{ ring: "0" }}
                border="0"
                bgColor="#F4F6F8"
                value={searchQuery}
                onChange={(e: React.ChangeEvent<any>) =>
                  setSearchQuery(e.target.value)
                }
              />
            </InputGroup>

            {allCountries.map((countryData: any, i: any) => {
              return (
                <Box
                  display={
                    searchQuery !== "" &&
                    !countryData.country.name
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                      ? "none"
                      : "flex"
                  }
                  justifyContent="space-between"
                  alignItems="center"
                  key={i}
                  cursor="pointer"
                  _hover={{ bgColor: "gray.50" }}
                  p=".5em"
                  my="2px"
                  borderRadius="3px"
                  onClick={() => {
                    const newState = allCountries.map((data: any, x: any) => {
                      if (allCountries[i] == data) {
                        return { ...data, selected: !data.selected };
                      } else {
                        return data;
                      }
                    });
                    setAllCountries(newState);
                  }}
                >
                  <Flex alignItems="center" mt="1em">
                    <div> {countryData.country.flag}</div>
                    <Text ml="1em">{countryData.country.name}</Text>
                  </Flex>

                  {countryData.selected ? (
                    <BsCheckSquareFill color="#5C15CE" />
                  ) : (
                    <Box
                      width="1em"
                      height="1em"
                      border="1.5px solid gray"
                      borderRadius="4px"
                    ></Box>
                  )}
                  {/* <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        const newState = allCountries.map(
                          (data: any, x: any) => {
                            if (allCountries[i] == data) {
                              return { ...data, selected: true };
                            } else {
                              return data;
                            }
                          }
                        );

                        setAllCountries(newState);
                      } else {
                        const newState = allCountries.map(
                          (data: any, x: any) => {
                            if (allCountries[i] == data) {
                              return { ...data, selected: false };
                            } else {
                              return data;
                            }
                          }
                        );

                        setAllCountries(newState);
                      }
                    }}
                    mr=".2em"
                    defaultChecked={countryData.selected ? true : false}
                    colorScheme="purple"
                  >
                    {""}
                  </Checkbox> */}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>

      <Box mt="40px">
        <Heading fontSize="1em" mb="1em" mt="2em">
          What type of products do you have?
          <span style={{ color: "red", marginLeft: 5 }}>*</span>
        </Heading>

        <Flex flexWrap="wrap" rowGap="1em" columnGap="1em">
          {cardData.map((dt: any, i: any) => (
            <Box
              key={i}
              _hover={{ bgColor: "#F5F8FF" }}
              border="1.5px solid"
              borderColor={dt.selected ? "#7211D4" : "#D1D1D1"}
              bgColor={dt.selected ? "#F5F8FF" : "#ffffff"}
              padding="16px 16px 10px 16px"
              borderRadius="0.4em"
              width={["100%", "45%", "48%", "260px"]}
              minHeight={["100px", "148px", "160px"]}
              display="flex"
              alignItems="flex-start"
              flexDirection={["row-reverse", "column"]}
              justifyContent="space-between"
              cursor="pointer"
              onClick={() => {
                const newState = cardData.map((dt: any) => {
                  if (cardData[i] == dt) {
                    return {
                      ...dt,
                      selected: !dt.selected,
                    };
                  } else {
                    return dt;
                  }
                });
                // @ts-ignore
                setCardData(newState);
              }}
            >
              {!dt.selected ? (
                <Box
                  width="1em"
                  height="1em"
                  border="1.5px solid #A4B3C3"
                  borderRadius="4px"
                  cursor="pointer"
                  onClick={() => {
                    const newState = cardData.map((dt: any) => {
                      if (cardData[i] == dt) {
                        return { ...dt, selected: !dt.selected };
                      } else {
                        return dt;
                      }
                    });
                    // @ts-ignore
                    setCardData(newState);
                  }}
                ></Box>
              ) : (
                <BsCheckSquareFill
                  color="#5C15CE"
                  cursor="pointer"
                  onClick={() => {
                    const newState = cardData.map((dt: any) => {
                      if (cardData[i] == dt) {
                        return { ...dt, selected: !dt.selected };
                      } else {
                        return dt;
                      }
                    });
                    // @ts-ignore
                    setCardData(newState);
                  }}
                />
              )}
              {/* <Checkbox
                colorScheme="purple"
                color="#A5A5A5"
                alignItems="center"
                isChecked={dt.selected}
                onChange={(e) => {
                  if (e.target.checked) {
                    const newState = cardData.map((dt: any) => {
                      if (cardData[i] == dt) {
                        return { ...dt, selected: true };
                      } else {
                        return dt;
                      }
                    });
                    setCardData(newState);
                  } else {
                    const newState = cardData.map((dt: any) => {
                      if (cardData[i] == dt) {
                        return { ...dt, selected: false };
                      } else {
                        return dt;
                      }
                    });
                    setCardData(newState);
                  }
                }}
              ></Checkbox> */}

              <Box my=".3em">
                <Image src={dt.selected ? dt.coloredIcon : dt.defaultIcon} />
                <Text
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="26px"
                  color={dt.selected ? "#7211D4" : "#000000"}
                  mt=".2em"
                >
                  {dt.title}
                </Text>
              </Box>
            </Box>
          ))}
        </Flex>
      </Box>

      <OrganizationSettingsFooter
        hasUpdate={newUpdate}
        sethasUpdate={setnewUpdate}
        dataToUpdate={updateData}
        setRefreshData={setrefreshData}
        validator={validator}
        getOrgDetails={getOrgDetails}
      />
    </>
  );
};
export default ProductDetails;
