import {
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalHeader,
  Box,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const PromptModal = ({
  isOpen,
  title,
  message,
  onClose,
  handler,
}: {
  isOpen: boolean;
  title: string;
  message: string;
  onClose: () => any;
  handler: () => any;
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent
          height="235px"
          borderRadius="1em"
          p=".8em"
          mx={{ base: ".6em", md: "auto" }}
          marginTop={{ base: "15em", md: "18em" }}
        >
          <Box
            width=".8em"
            height="235px"
            position="absolute"
            left="0"
            bgGradient="linear(360deg, #7211D4 -1.38%, #FA9445 98.36%)"
            top="0"
            borderLeftRadius="1em"
          ></Box>
          <ModalHeader mt="1em">{title}</ModalHeader>
          <ModalCloseButton
            bgColor="gray.100"
            borderRadius="1.5em"
            p="1.5em"
            top="1.5em"
            right="1.8em"
            fontSize="1em"
          />
          <ModalBody mr="1em">
            <Text
              fontSize={{ base: "md", md: "1.3em" }}
              fontWeight="medium"
              mb="1em"
            >
              {message}
            </Text>
            <Box
              width="90%"
              height="1px"
              mx="auto"
              bgColor="gray.200"
              mb=".6em"
            ></Box>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} px="2em" onClick={handler}>
              Leave
            </Button>
            <Button
              bgColor="#F0F5FF"
              color="#7211D4"
              px="2em"
              onClick={onClose}
            >
              Stay
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
