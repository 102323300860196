import {
  Box,
  Button,
  Grid,
  GridItem,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import React, { useRef } from "react";

const TopSectionDetail = ({
  label,
  fieldData,
  value,
}: {
  label: string;
  fieldData?: any;
  value: string;
}) => {
  return (
    <Stack>
      <Text fontSize="9px" fontWeight="500" lineHeight="14px" color="#74788D">
        {label}
      </Text>
      {fieldData ? (
        <>
          <Text
            fontSize="11px"
            fontWeight="700"
            lineHeight="16px"
            color="#19073B"
          >
            {fieldData.global}
          </Text>
          <Text
            fontSize="11px"
            mt="5px"
            fontWeight="500"
            lineHeight="16px"
            color="#19073B"
          >
            {fieldData.local}
          </Text>
        </>
      ) : (
        <Text
          fontSize="11px"
          fontWeight="700"
          lineHeight="16px"
          color="#19073B"
        >
          {value}
        </Text>
      )}
    </Stack>
  );
};

const InvoiceTable = ({
  title,
  tableData,
}: {
  title: string;
  tableData: any[];
}) => {
  return (
    <Box>
      <Text
        fontSize="12px"
        mb="8px"
        lineHeight="17px"
        fontWeight="500"
        color="#7211D4"
      >
        {title}
      </Text>

      <Table
        variant="simple"
        border="0.4px solid #74788D"
        style={{ borderCollapse: "collapse" }}
      >
        <Tbody>
          {tableData.map((info: any) => (
            <Tr>
              <Td
                fontSize="9px"
                fontWeight="500"
                lineHeight="14px"
                color="#74788D"
                border="0.4px solid #74788D"
              >
                {info.label}
              </Td>
              <Td
                fontSize="9px"
                fontWeight="600"
                lineHeight="14px"
                color="#19073B"
                border="0.4px solid #74788D"
              >
                {info.value === "paid"
                  ? "Paid"
                  : info.value === "approved"
                  ? "Approved"
                  : info.value === "declined"
                  ? "Declined"
                  : info.value}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

type CommissionInvoiceProps = {
  isOpen: boolean;
  onClose: () => void;
  affiliateState: string;
  affiliateCountry: string;
  affiliateAddress: string;
  advertiserState: string;
  advertiserCountry: string;
  advertiserAddress: string;
  commissionTotal: number;
  issuedDate: string;
  invoiceNumber: string;
  paymentInfo: { label: string; value: string | number }[];
  accountInfo: { label: string; value: string | number }[];
};

const CommissionInvoice = ({
  isOpen,
  onClose,
  affiliateState,
  affiliateCountry,
  affiliateAddress,
  advertiserState,
  advertiserCountry,
  advertiserAddress,
  commissionTotal,
  paymentInfo,
  issuedDate,
  invoiceNumber,
  accountInfo,
}: CommissionInvoiceProps) => {
  const invoiceRef = useRef<HTMLDivElement>(null);

  const createPDF = async () => {
    const pdf = new jsPDF("portrait", "px", "a4");
    if (invoiceRef.current) {
      const data = await html2canvas(invoiceRef.current);
      const img = data.toDataURL("image/png");
      const imgProperties = pdf.getImageProperties(img);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(img, "JPEG", 30, 50, 380, 250);
      pdf.save("invoice.pdf");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box id="commissionInvoice" ref={invoiceRef}>
            {/* Top Section */}
            <Grid templateColumns="1.5fr 1fr 1fr 1fr" gap="10px">
              <GridItem>
                <Image src="/assets/logo.png" h="53px" w="72px" />
              </GridItem>

              <GridItem>
                <Stack spacing="10px">
                  <TopSectionDetail label="Invoice No." value={invoiceNumber} />
                  <TopSectionDetail label="Date Issued" value={issuedDate} />
                </Stack>
              </GridItem>

              <GridItem>
                <TopSectionDetail
                  label="Affiliate Information"
                  fieldData={{
                    global: `${affiliateState}, ${affiliateCountry}`,
                    local: affiliateAddress,
                  }}
                  value=""
                />
              </GridItem>

              <GridItem>
                <TopSectionDetail
                  label="Advertiser Information"
                  fieldData={{
                    global: `${advertiserState}, ${advertiserCountry}`,
                    local: advertiserAddress,
                  }}
                  value=""
                />
              </GridItem>
            </Grid>

            <Text
              color="#7211D4"
              lineHeight="30px"
              fontSize="20px"
              fontWeight="500"
              mt="25px"
            >
              INVOICE
            </Text>

            {/* Acccount and Payment Info */}
            <Grid templateColumns="1fr 1fr" gap="12px" mb="40px" mt="21px">
              <GridItem>
                <InvoiceTable
                  title="Account Information"
                  tableData={accountInfo}
                />
              </GridItem>

              <GridItem>
                <InvoiceTable
                  title="Payment Information"
                  tableData={paymentInfo}
                />
              </GridItem>
            </Grid>

            <Box>
              <Text
                fontSize="9px"
                fontWeight="500"
                lineHeight="14px"
                color="#74788D"
                mb="4px"
              >
                Total Amount
              </Text>

              <Box
                // border="0.4px solid #74788D"
                w="220px"
                display="flex"
                // justifyContent="center"
                // alignItems="center"
                height="40px"
                m="0px"
                overflowX="visible"
              >
                <Text color="#068B3C" fontSize="23px" fontWeight="500">
                  ${commissionTotal}
                </Text>
              </Box>
            </Box>
          </Box>
        </ModalBody>

        <ModalFooter display="flex" alignItems="center" justifyContent="end">
          <Button
            bgColor="#F0F3F9"
            borderRadius="8px"
            color="#7211D4"
            fontWeight="500"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight={{ base: "18px", md: "21px" }}
            onClick={createPDF}
          >
            Download
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CommissionInvoice;
