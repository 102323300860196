import React, { useEffect, useState } from "react";
import useJsonToCSV from "../../../../../hooks/useJsonToCSV";
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  StackDivider,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdArrowDropDown, MdFilterList, MdOutlineAddBox } from "react-icons/md";
import SalesTable from "./SalesTable";
import { RiSearchLine } from "react-icons/ri";
import useUserToken from "../../../../../hooks/useUserToken";
import Fuse from "fuse.js";
import { FaFileExport } from "react-icons/fa";
import moment from "moment";

type NewSaleDataProps = {
  [key: string]: string | number;
};

type NewSaleFormProps = {
  title: string;
  value: string;
  placeholder: string;
  type: string;
};

const newSaleForm: NewSaleFormProps[] = [
  {
    title: "Affiliate",
    value: "affiliate_id",
    placeholder: "Affiliate Organization Name",
    type: "select",
  },
  {
    title: "Amount",
    value: "amount",
    placeholder: "0",
    type: "number",
  },
  {
    title: "Product ID",
    value: "product_id",
    placeholder: "Optional",
    type: "select",
  },
];

const Sales = ({
  campaignName,
  id,
  campaignPromotion,
  productLinks,
  setExternalAffInfo,
  setTabIndex,
}: {
  campaignName: string;
  id: string | undefined;
  campaignPromotion: any[];
  productLinks: any[];
  setExternalAffInfo: React.Dispatch<any>;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [filterData, setfilterData] = useState({
    date: "",
    status: "",
  });

  const [highlighted, sethighlighted] = useState<any[]>([]);
  const [dataToDownLoad, setdataToDownLoad] = useState<any[]>([]);
  const [sales, setsales] = useState<any[]>([]);
  const [filteredSales, setfilteredSales] = useState<any[]>([]);
  const [isCreatingSale, setisCreatingSale] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [updateSalesTable, setupdateSalesTable] = useState(false);

  const [searchVal, setsearchVal] = useState<string>("");
  const [newSaleData, setnewSaleData] = useState<NewSaleDataProps>({
    affiliate_id: "",
    amount: "",
    product_id: "",
  });
  const [newSaleAdded, setnewSaleAdded] = useState<boolean>(false);
  const [filterIsOpen, setfilterIsOpen] = useState(false);
  const [existingAffiliates, setexistingAffiliates] = useState<any[]>([]);

  const token = useUserToken();
  const {
    isOpen: isOpenNewSaleModal,
    onOpen: onOpenNewSaleModal,
    onClose: onCloseNewSaleModal,
  } = useDisclosure();

  const toast = useToast();

  const options = {
    keys: ["aff_org.companyname", "metadata.product_id"],
  };

  const fuse = new Fuse(sales, options);

  const getSales = (
    endpoint: string,
    setPlaceholder: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    if (!id) return;

    setisLoading(true);
    let bearerAuth = token;

    fetch(process.env.REACT_APP_API_ENDPOINT + endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("all sales", data.data.conversions);
        if (data.success) {
          setPlaceholder(data.data.conversions);
        }
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
        console.log("get sales => " + err);
      });
  };

  const getCampaignAffiliates = () => {
    if (!id) return;

    // setisLoading(true);
    let bearerAuth = token;

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `/promote-campaign/get-affiliates/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setexistingAffiliates(data.data.affiliates);
          // setisLoading(false);
        }
      })
      .catch((err) => {
        // setisLoading(false);
        console.log("get sales => " + err);
      });
  };

  const handleFormFieldChange = (field: string, val: any) => {
    setnewSaleData({
      ...newSaleData,
      [field]: val,
    });
  };

  const handleFormClose = () => {
    onCloseNewSaleModal();
    setnewSaleData({
      affiliate_id: "",
      amount: "",
      product_id: "",
    });
  };

  const ifFilterExists = () => {
    if (isLoading) return false;
    if (searchVal || filterData.date || filterData.status) return true;
    return false;
  };

  const handleViewAffiliate = (selectedAff: any) => {
    let affInfo = existingAffiliates.find(
      (aff) => aff.aff_orgId === selectedAff
    );

    if (affInfo) {
      setExternalAffInfo(affInfo);
      setTabIndex(3);
    }
  };

  const verifyNewSaleFormData = () => {
    if (!newSaleData.affiliate_id)
      return {
        isValid: false,
        reason: "Please Select an Affiliate Organization",
      };
    if (!newSaleData.amount)
      return { isValid: false, reason: "Please enter a product amount" };

    return { isValid: true, reason: "" };
  };

  const noResult = () => {
    if (!isLoading && !sales.length)
      return { result: true, reason: "You currently do not have any sale" };

    if (!isLoading && !filteredSales.length && searchVal)
      return { result: true, reason: "No Results Found" };

    if (!isLoading && !filteredSales.length && filterData.date)
      return { result: true, reason: "No Results Found" };

    if (!isLoading && !filteredSales.length && filterData.status)
      return { result: true, reason: "No Results Found" };

    return { result: false, reason: "" };
  };

  const handleNewSaleBtn = () => {
    if (!campaignPromotion?.length)
      return toast({
        title: "Not Permitted To Add Sale",
        description: "Add affiliates to your campaigns to record new sales",
        status: "info",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });

    return onOpenNewSaleModal();
  };

  const addNewSale = () => {
    setisCreatingSale(true);

    const formValidation = verifyNewSaleFormData();
    setfilterData({
      date: "",
      status: "",
    });

    // @ts-ignore
    if (affiliate_id == "") {
      setisCreatingSale(false);
      return toast({
        title: "Add Sale Error",
        description: formValidation.reason,
        status: "error",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
    }

    let bearerAuth = token;

    const payload = {
      campaignId: Number(id),
      product_id: product_id,
      amount: Number(amount),
      affiliate_orgId: Number(affiliate_id),
    };
    console.log("payload", payload);

    fetch(process.env.REACT_APP_API_ENDPOINT + `events/conversion/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("sale", data);
        if (data.success) {
          handleFormClose();
          setisLoading(true);
          setupdateSalesTable(!updateSalesTable);
          setisCreatingSale(false);
          setnewSaleAdded(true);

          return toast({
            title: "Added Sale Successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          setisCreatingSale(false);
          return toast({
            title: "Add Sale Error",
            description: data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      });
  };

  useEffect(() => {
    getSales(`/events/conversion/get-conversions/${id}`, setsales);
    getCampaignAffiliates();
  }, [updateSalesTable]);

  useEffect(() => {
    if (filterData.date && !filterData.status) {
      setsearchVal("");
      getSales(
        `/events/conversion/get-conversions/${id}?date=${filterData.date}`,
        setfilteredSales
      );
      setnewSaleAdded(false);
    } else if (!filterData.date && filterData.status) {
      setsearchVal("");
      getSales(
        `/events/conversion/get-conversions/${id}?status=${filterData.status}`,
        setfilteredSales
      );
      setnewSaleAdded(false);
    } else if (filterData.date && filterData.status) {
      setsearchVal("");
      getSales(
        `/events/conversion/get-conversions/${id}?date=${filterData.date}&status=${filterData.status}`,
        setfilteredSales
      );
      setnewSaleAdded(false);
    }
  }, [filterData]);

  useEffect(() => {
    setfilteredSales(
      fuse.search(searchVal).map((sale: any) => ({ ...sale.item }))
    );

    if (filterData.date || filterData.status) {
      setfilterData({ date: "", status: "" });
    }
    setnewSaleAdded(false);
  }, [searchVal]);

  useEffect(() => {
    let clonedHighlighted: any[] = [];

    highlighted.forEach((item: any) => {
      clonedHighlighted.push({
        dateCreated: item?.createdAt?.split("T")[0] || "",
        organization_name: item?.aff_org.companyname,
        sales: item?.amount || 0,
        commission: item?.amount || 0,
        status: item.status,
        productId: item?.metadata.product_id || "-",
        email: item?.aff_email || "",
        paymentDueDate:
          moment(item?.payouts[0].dateScheduledFor).format("MMM Do YYYY") || "",
      });
    });

    setdataToDownLoad(clonedHighlighted);
  }, [highlighted]);

  const [csvFileDownloadLink] = useJsonToCSV(
    [
      "DATE CREATED",
      "ORGANIZATION",
      "SALES ($)",
      "COMMISSON ($)",
      "STATUS",
      "PRODUCT ID",
      "EMAIL",
      "PAYMENT DUE DATE",
    ],
    dataToDownLoad
  );
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [isDropdownOpen2, setisDropdownOpen2] = useState(false);
  const [affiliate_id, setaffiliate_id] = useState("");
  const [affiliate_Comp, setaffiliate_Comp] = useState("");
  const [amount, setamount] = useState(0);
  const [product_id, setproduct_id] = useState("");

  const toggleDropD = () => {
    setisDropdownOpen(!isDropdownOpen);
  };
  const toggleDropD1 = () => {
    setisDropdownOpen2(!isDropdownOpen2);
  };

  const [dateFilterToggle, setDateFilterToggle] = useState(false);
  const [statusFilterToggle, setStatusFilterToggle] = useState(false);

  return (
    <Stack pb="100px">
      <Box
        display="flex"
        alignItems="center"
        justifyContent={{ base: "space-around", md: "space-between" }}
        mt="36px"
        mb="28px"
      >
        <HStack spacing={{ base: "14px", md: "24px" }}>
          <Button
            borderRadius="8px"
            variant="outline"
            color="rgba(50, 50, 93, 1)"
            onClick={() => setfilterIsOpen(!filterIsOpen)}
          >
            <MdFilterList fontSize={15} />
            <Text
              display={{ base: "none", md: "block" }}
              fontSize={{ base: "12px", md: "14px" }}
              color="rgba(50, 50, 93, 0.8)"
              fontWeight="500"
              lineHeight={{ base: "18px", md: "21px" }}
              ml="7px"
            >
              Filter
            </Text>
          </Button>

          <Button
            bgColor="#7211D4"
            color="white"
            borderRadius="8px"
            onClick={handleNewSaleBtn}
          >
            <MdOutlineAddBox fontSize={15} />
            <Text
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="500"
              lineHeight={{ base: "18px", md: "21px" }}
              ml="7px"
            >
              Add New Sale
            </Text>
          </Button>
        </HStack>

        {highlighted.length !== 0 ? (
          <Link
            href={csvFileDownloadLink}
            download={`${campaignName}_Sales.csv`}
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight={{ base: "18px", md: "21px" }}
            fontWeight="500"
            width={{ base: "90px", md: "130px" }}
            borderRadius="8px"
            height="35px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="rgba(50, 50, 93, 0.8)"
            border="0.5px solid rgba(50, 50, 93, 0.4)"
            _hover={{ textDecoration: "none" }}
          >
            <Flex align={"center"} gap={2}>
              <FaFileExport />
              Export CSV
            </Flex>
          </Link>
        ) : (
          <Tooltip
            label={
              "select the sales you want to export with the tickbox to continue"
            }
            fontSize="md"
          >
            <Button
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight={{ base: "18px", md: "21px" }}
              fontWeight="500"
              width={{ base: "90px", md: "130px" }}
              borderRadius="8px"
              height="35px"
              display="flex"
              isDisabled={true}
              alignItems="center"
              justifyContent="center"
              color="rgba(50, 50, 93, 0.8)"
              border="0.5px solid rgba(50, 50, 93, 0.4)"
              _hover={{ textDecoration: "none" }}
            >
              <Flex align={"center"} gap={2}>
                <FaFileExport />
                Export CSV
              </Flex>
            </Button>
          </Tooltip>
        )}
      </Box>

      {filterIsOpen && (
        <Box
          display={{ base: "flex", md: "flex" }}
          flexDirection={{ base: "column", md: "row" }}
          alignItems={["start", "center"]}
          p="13px 7px"
          bgColor="#F2EFF6"
          mb="8px"
          mt="8px"
        >
          <InputGroup
            w={{ base: "100%", md: "500px" }}
            mb={{ base: "16px", md: "0px" }}
          >
            <InputLeftElement
              pointerEvents="none"
              children={<RiSearchLine color="rgba(50, 50, 93, 0.8)" />}
            />
            <Input
              css={{
                border: "0.5px solid rgba(50, 50, 93, 0.4)",
                "::-webkit-input-placeholder": { fontSize: "14px" },
              }}
              type="text"
              value={searchVal}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setsearchVal(e.target.value)
              }
              fontSize="16px"
              mr={{ md: "20px" }}
              bg="white"
              focusBorderColor="#7211D4"
              placeholder="Search for Organizations, Product ID"
            />
          </InputGroup>

          <Flex
            gap="14px"
            flexDirection={["column", "row"]}
            mt={"8px"}
            // w={["100%", ""]}
          >
            <Flex flexDir={"column"} w={"100%"} position={"relative"}>
              <Flex
                justify={"space-between"}
                align={"center"}
                bgColor={"white"}
                p={3}
                w={["100%", "150px"]}
                cursor={"pointer"}
                h={"80%"}
                onClick={() => {
                  setDateFilterToggle((prev) => !prev);
                }}
              >
                <Text>{filterData.date == "" ? "Date" : filterData.date}</Text>
                <MdArrowDropDown fontSize={"24px"} />
              </Flex>

              {dateFilterToggle && (
                <Box
                  position={"absolute"}
                  top={"80%"}
                  w={["100%", "150px"]}
                  zIndex={1}
                >
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, date: "last_day" });
                      setDateFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Last Day</Text>
                  </Flex>
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, date: "last_30_days" });
                      setDateFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Last 30 Days</Text>
                  </Flex>
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, date: "last_quater" });
                      setDateFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Last Quarter</Text>
                  </Flex>
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, date: "last_year" });
                      setDateFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Last Year</Text>
                  </Flex>
                </Box>
              )}
            </Flex>
            <Flex flexDir={"column"} w={"100%"} position={"relative"}>
              <Flex
                justify={"space-between"}
                align={"center"}
                bgColor={"white"}
                p={3}
                w={["100%", "150px"]}
                cursor={"pointer"}
                h={"80%"}
                onClick={() => {
                  setStatusFilterToggle((prev) => !prev);
                }}
              >
                <Text>
                  {filterData.status == "" ? "Status" : filterData.status}
                </Text>
                <MdArrowDropDown fontSize={"24px"} />
              </Flex>

              {statusFilterToggle && (
                <Box
                  position={"absolute"}
                  top={"80%"}
                  w={["100%", "150px"]}
                  zIndex={1}
                >
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, status: "paid" });
                      setStatusFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Paid</Text>
                  </Flex>
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, status: "pending" });
                      setStatusFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Pending</Text>
                  </Flex>
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, status: "declined" });
                      setStatusFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Declined</Text>
                  </Flex>
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, status: "overdue" });
                      setStatusFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Overdue</Text>
                  </Flex>
                </Box>
              )}
            </Flex>
            {/* <Select
              w={{ base: "100%", md: "120px" }}
              bg="white"
              placeholder="Date"
              cursor={"pointer"}
              value={filterData.date}
              onChange={(e) =>
                setfilterData({ ...filterData, date: e.target.value })
              }
              icon={<MdArrowDropDown />}
            >
              <option value="last_day">Last Day</option>
              <option value="last_30_days">Last 30 Days</option>
              <option value="last_quater">Last Quarter</option>
              <option value="last_year">Last Year</option>
            </Select> */}

            {/* <Select
              w={{ base: "100%", md: "120px" }}
              bg="white"
              placeholder="Status"
              cursor={"pointer"}
              value={filterData.status}
              onChange={(e) =>
                setfilterData({ ...filterData, status: e.target.value })
              }
              icon={<MdArrowDropDown />}
            >
              <option value="paid">Paid</option>
              <option value="pending">Pending</option>
              <option value="declined">Declined</option>
              <option value="overdue">Overdue</option>
            </Select> */}
          </Flex>
        </Box>
      )}

      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          h="40vh"
          alignItems="center"
          pt="20px"
        >
          <Image className="loading" src="/assets/logo.png" height="60px" />
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="70px"
            color="#000000"
            mt="-15px"
          >
            Loading Sales Table...
          </Text>
        </Box>
      ) : (
        <SalesTable
          newSaleAdded={newSaleAdded}
          setnewSaleAdded={setnewSaleAdded}
          sales={ifFilterExists() ? filteredSales : sales}
          highlighted={highlighted}
          sethighlighted={sethighlighted}
          handleViewAff={handleViewAffiliate}
        />
      )}

      {noResult().result && (
        <Box
          w="100%"
          height="30vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={{ base: "0px 16px", md: "0px" }}
          mb="21px"
        >
          <Image
            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
            height="180px"
            objectFit="contain"
            mb="16px"
          />

          <Text
            fontWeight="600"
            fontSize="12px"
            lineHeight="22px"
            color="#32325D"
            textAlign="center"
          >
            {noResult().reason}
          </Text>
        </Box>
      )}

      <Modal
        size="2xl"
        isOpen={isOpenNewSaleModal}
        onClose={handleFormClose}
        isCentered={true}
      >
        <ModalOverlay />

        <ModalContent borderRadius="20px" p="45px">
          <ModalHeader
            color="#7211D4"
            fontSize="16px"
            lineHeight="24px"
            fontWeight="600"
          >
            Add New Sale
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Stack
              divider={<StackDivider borderColor="rgba(50, 50, 93, 0.3)" />}
              borderTop="0.5px solid rgba(50, 50, 93, 0.3)"
              borderBottom="0.5px solid rgba(50, 50, 93, 0.3)"
            >
              <Box display="flex" alignItems="center" p="23px 0px">
                <Text
                  w="30%"
                  fontSize="14px"
                  lineHeight="21px"
                  fontWeight="500"
                >
                  Affiliate{" "}
                  <span style={{ color: "red", marginLeft: 5 }}>*</span>
                </Text>
                <Box w="70%">
                  <Flex
                    justify={"space-between"}
                    align={"center"}
                    w="100%"
                    px="20px"
                    py="10px"
                    bgColor="rgba(50, 50, 93, 0.06)"
                    borderRadius={10}
                    cursor={"pointer"}
                    onClick={() => toggleDropD()}
                  >
                    {affiliate_Comp ? (
                      <Text>{affiliate_Comp}</Text>
                    ) : (
                      <Text>Affiliate Organization Name</Text>
                    )}
                    <MdArrowDropDown fontSize={20} />
                  </Flex>
                  {isDropdownOpen &&
                    campaignPromotion.map((aff: any) => (
                      <Flex
                        w="100%"
                        px="20px"
                        py="10px"
                        bgColor="#fff"
                        borderRadius={10}
                        borderRightWidth={0.5}
                        borderLeftWidth={0.5}
                        borderBottomWidth={0.5}
                        borderLeftColor={"#c4c4c4"}
                        borderRightColor={"#c4c4c4"}
                        borderBottomColor={"#c4c4c4"}
                        cursor={"pointer"}
                        onClick={() => {
                          setaffiliate_id(aff.aff_org.id);
                          setaffiliate_Comp(aff.aff_org.companyname);
                          toggleDropD();
                        }}
                      >
                        {aff.aff_org.companyname}
                      </Flex>
                    ))}
                </Box>
              </Box>

              <Box display="flex" alignItems="center" p="23px 0px">
                <Text
                  w="30%"
                  fontSize="14px"
                  lineHeight="21px"
                  fontWeight="500"
                >
                  Amount
                  <span style={{ color: "red", marginLeft: 5 }}>*</span>
                </Text>
                <Flex w="70%" align={"center"} gap={2}>
                  <Text fontWeight={"600"} fontSize={"16px"}>
                    $
                  </Text>
                  <Input
                    placeholder={"0"}
                    // value={amount}
                    onChange={(e: any) => setamount(e.target.value)}
                    type="number"
                    bgColor="rgba(50, 50, 93, 0.06)"
                    color="rgba(50, 50, 93, 0.9)"
                    borderRadius="8px"
                    border="0.5px solid rgba(50, 50, 93, 0.4)"
                  />
                </Flex>
              </Box>

              <Box display="flex" alignItems="center" p="23px 0px">
                <Text
                  w="30%"
                  fontSize="14px"
                  lineHeight="21px"
                  fontWeight="500"
                >
                  Product ID{" "}
                  {/* <span style={{ color: "red", marginLeft: 5 }}>*</span> */}
                </Text>
                <Box w="70%">
                  <Flex
                    justify={"space-between"}
                    align={"center"}
                    w="100%"
                    px="20px"
                    py="10px"
                    bgColor="rgba(50, 50, 93, 0.06)"
                    borderRadius={10}
                    cursor={"pointer"}
                    onClick={() => toggleDropD1()}
                  >
                    {product_id ? (
                      <Text>{product_id}</Text>
                    ) : (
                      <Text>Product ID</Text>
                    )}
                    <MdArrowDropDown fontSize={20} />
                  </Flex>
                  {isDropdownOpen2 &&
                    productLinks.map((product: any) => (
                      <Flex
                        w="100%"
                        px="20px"
                        py="10px"
                        bgColor="#fff"
                        borderRadius={10}
                        borderRightWidth={0.5}
                        borderLeftWidth={0.5}
                        borderBottomWidth={0.5}
                        borderLeftColor={"#c4c4c4"}
                        borderRightColor={"#c4c4c4"}
                        borderBottomColor={"#c4c4c4"}
                        cursor={"pointer"}
                        onClick={() => {
                          setproduct_id(product.id);
                          toggleDropD1();
                        }}
                      >
                        {product.id}
                      </Flex>
                    ))}
                </Box>
              </Box>
            </Stack>
          </ModalBody>

          <ModalFooter display="flex" justifyContent="right" p="21px 0px">
            <Button
              bgColor="#F0F3F9"
              borderRadius="8px"
              fontWeight="500"
              fontSize={{ base: "14px", md: "16px" }}
              lineHeight={{ base: "21px", md: "32px" }}
              color="#7211D4"
              onClick={addNewSale}
              isLoading={isCreatingSale}
              w="max-content"
            >
              Add New Sale
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default Sales;
