import React, { useState, useEffect } from "react";
import { getToken } from "../../firebaseinit";
import useUserToken from "../../hooks/useUserToken";

const Notifications = (props) => {
  const token = useUserToken();
  const [isTokenFound, setTokenFound] = useState(false);
  console.log("Token found", isTokenFound);

  // To load once
  useEffect(() => {
    let data;

    async function tokenFunc() {
      data = await getToken(setTokenFound);
      if (data) {
        console.log("Token is", data);
        localStorage.setItem("pushNotification", "enabled");
        postFcmToken(data);
      }
      return data;
    }

    tokenFunc();
  }, [setTokenFound]);
  const postFcmToken = (data) => {
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + "notification/fcm-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
      body: JSON.stringify({
        fcmToken: data,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
        } else {
          // @ts-ignore
          // formViewRef.current.scrollIntoView();
        }
      })
      .catch((err) => {
        console.log("here");
      });
  };
  return <></>;
};

Notifications.propTypes = {};

export default Notifications;
