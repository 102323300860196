import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { AiFillFile } from "react-icons/ai";

type AssetFolderProps = {
  color: string;
  title: string | undefined | null;
  numberOfFiles: number | undefined | null;
};

const AssetFolder = ({ color, title, numberOfFiles }: AssetFolderProps) => {
  return (
    <Box display={{ base: "flex", md: "block" }}>
      <AiFillFile color={color} fontSize={25} />

      <Box ml={{ base: "8px", md: "0px" }} mt={{ base: "0px", md: "16px" }}>
        <Text
          fontWeight="500"
          fontSize="14px"
          lineHeight="21px"
          mb={{ base: "6px", md: "21px" }}
        >
          {title}
        </Text>
        <Text fontWeight="400" fontSize="12px" lineHeight="18px">
          {numberOfFiles} files
        </Text>
      </Box>
    </Box>
  );
};

export default AssetFolder;
