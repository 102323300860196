import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Link,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  css,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdFiberManualRecord } from "react-icons/md";
import useJsonToCSV from "../../../hooks/useJsonToCSV";
import useUserToken from "../../../hooks/useUserToken";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import PaginationComponent from "../../explore/affiliates/paginationWrapper";
import { useNavigate, useSearchParams } from "react-router-dom";

const Reports = () => {
  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  const toast = useToast();
  const token = useUserToken("affiliate");

  const [loading, setloading] = useState(false);
  const [dataToDownLoad, setdataToDownLoad] = useState([]);

  const [reportFilter, setreportFilter] = useState({
    campaignId: "",
    date: "",
  });

  const titles = [
    "DATE CREATED",
    "CAMPAIGN NAME",
    "PRODUCT ID",
    "Leads",
    "Sales",
    "EARNINGS",
    "STATUS",
  ];

  const [reportsData, setreportsData] = useState<any>([]);
  const [campaignsData, setcampaignsData] = useState<any[]>([]);

  const [currentPageC, setCurrentPageC] = useState(1);
  const pageSizeC = 10;
  const startIndexC = (currentPageC - 1) * pageSizeC;
  const endIndexC = startIndexC + pageSizeC;
  let currentDataC = reportsData.slice(startIndexC, endIndexC);

  const navigate = useNavigate();
  let [searchParams, _] = useSearchParams();

  const existingFilter = () => {
    if (!reportFilter) return false;
    if (reportFilter.campaignId || reportFilter.date) {
      return true;
    }
    return false;
  };

  const getAffiliateCampaigns = () => {
    let bearerAuth = token;

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `promote-campaign/affiliate/campaigns?status=approved`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data.campaigns.length > 0) {
          setcampaignsData(
            data.data.campaigns.map((cmp: any) => ({
              title: cmp.campaign.campaign_name || "",
              id: cmp.campaign.id || "",
            }))
          );
        }
      })
      .catch((err) => {});
  };

  const getAffiliateAnalytics = (endpoint: string) => {
    let bearerAuth = token;

    setloading(true);
    fetch(process.env.REACT_APP_API_ENDPOINT + endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setloading(false);
          if (data.data.analytics && data.data.analytics?.campaignTotalSales) {
            setreportsData(data.data.analytics.campaignTotalSales);
            setdataToDownLoad(
              data.data.analytics?.campaignTotalSales.map((cmp: any) => ({
                dateCreated: cmp.date?.split("T")[0] || "",
                campaign_name: cmp.campaignName || "",
                productId:
                  (cmp.productId &&
                    cmp.productId.map((p: any) => p.id).join(", ")) ||
                  "",
                leads: cmp.totalLeads || 0,
                sales: cmp.totalSales || 0,
                earnings: cmp.totalCommissions || 0,
                status: cmp.status || "",
              }))
            );
          }
        } else {
          setloading(false);
        }
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const nextPageC = () => {
    if (currentPageC < Math.ceil(reportsData.length / pageSizeC)) {
      setCurrentPageC(currentPageC + 1);
    }
  };

  const prevPageC = () => {
    if (currentPageC > 1) {
      setCurrentPageC(currentPageC - 1);
    }
  };

  const [csvFileDownloadLink] = useJsonToCSV(
    [
      "DATE CREATED",
      "CAMPAIGN NAME",
      "PRODUCT ID",
      "LEADS",
      "SALES ($)",
      "EARNINGS ($)",
      "STATUS",
    ],
    dataToDownLoad
  );

  let filterCheck = searchParams.get("hasquery");

  const checkToken = async () => {
    if (!token) return;

    await fetch(process.env.REACT_APP_API_ENDPOINT + `/auth/valid-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data?.valid) {
          return;
        } else {
          window.localStorage.clear();
          sessionStorage.removeItem("currentUserType");
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          navigate("/login");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (token) {
      checkToken();
    }
  }, [token]);

  useEffect(() => {
    getAffiliateCampaigns();
    getAffiliateAnalytics("affiliate/analytics");
  }, []);

  useEffect(() => {
    if (reportFilter.date && !reportFilter.campaignId) {
      getAffiliateAnalytics(`affiliate/analytics?date=${reportFilter.date}`);
      navigate("/affiliate-reports?hasquery=true");
    } else if (!reportFilter.date && reportFilter.campaignId) {
      getAffiliateAnalytics(
        `affiliate/analytics?campaignId=${reportFilter.campaignId}`
      );
      navigate("/affiliate-reports?hasquery=true");
    } else if (reportFilter.date && reportFilter.campaignId) {
      getAffiliateAnalytics(
        `affiliate/analytics?campaignId=${reportFilter.campaignId}&date=${reportFilter.date}`
      );
      navigate("/affiliate-reports?hasquery=true");
    } else {
      getAffiliateAnalytics("affiliate/analytics");
    }
  }, [reportFilter]);

  useEffect(() => {
    if (!filterCheck) {
      setreportFilter({
        campaignId: "",
        date: "",
      });
    }
  }, [filterCheck]);

  return (
    <Box padding="30px 28px">
      <Box bg="white" w="100%" padding="28px 22px">
        <Flex
          w="100%"
          alignItems="center"
          pb="11px"
          marginBottom="15px"
          borderBottom="1px solid #EAECF0"
        >
          <Select
            placeholder="Campaigns"
            minW="140px"
            rounded="8px"
            w="max"
            border="0.5px solid #E5E4E4"
            outline="0"
            mr="12px"
            colorScheme="purple"
            id="reportSelect"
            _focus={{ ring: "0", border: "0.5px solid #7211D4" }}
            value={reportFilter.campaignId}
            onChange={(e) =>
              setreportFilter({
                ...reportFilter,
                campaignId: e.target.value || "",
              })
            }
          >
            {campaignsData.map((campaign: any) => (
              <option value={campaign?.id || ""}>
                {campaign?.title || ""}
              </option>
            ))}
          </Select>

          <Select
            placeholder="Date"
            minW="140px"
            w="max"
            rounded="8px"
            border="0.5px solid #E5E4E4"
            _focus={{ ring: "0", border: "0.5px solid #7211D4" }}
            id="reportSelect"
            value={reportFilter.date}
            onChange={(e) =>
              setreportFilter({ ...reportFilter, date: e.target.value || "" })
            }
          >
            <option value="last_day">Last Day</option>
            <option value="last_30_days">Last 30 Days</option>
            <option value="last_quater">Last Quarter</option>
            <option value="last_year">Last Year</option>
          </Select>

          {reportsData && reportsData.length ? (
            <Link
              href={csvFileDownloadLink}
              download={`Reports.csv`}
              fontSize={{ base: "0.75rem", md: "0.9rem" }}
              bgColor=" #7211D4"
              border=" 0.5px solid #E5E4E4"
              borderRadius="8px"
              marginLeft="auto"
              padding="8px 20px"
            >
              <Text fontWeight=" 500" color=" #F0F3F9">
                Export CSV
              </Text>
            </Link>
          ) : (
            ""
          )}
        </Flex>

        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            h="40vh"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <Image className="loading" src="/assets/logo.png" height="60px" />
              <Text
                fontWeight="400"
                fontSize="16px"
                lineHeight="70px"
                color="#000000"
                mt="-15px"
              >
                Loading....
              </Text>
            </Box>
          </Box>
        ) : (
          <Box
            css={
              isDesktop
                ? {}
                : css({
                    "::-webkit-scrollbar": { display: "none" },
                    "-webkit-overflow-scrolling": "touch",
                  })
            }
            overflowX={{ base: "scroll", md: "visible" }}
            maxWidth="100%"
          >
            {reportsData && reportsData.length ? (
              <Table mb="29px" border="1px solid #EAECF0" borderRadius="8px">
                <Thead>
                  <Tr height="44px" bgColor="#F9FAFB">
                    {titles.map((title) => {
                      return (
                        <Th
                          color="#667085"
                          textTransform="uppercase"
                          lineHeight="18px"
                          fontSize="12px"
                          fontWeight="500"
                          key={title}
                        >
                          {title}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>

                <Tbody>
                  {reportsData.map((item: any, index: number) => {
                    return (
                      <Tr key={index} height="80px" width="100%">
                        <Td>
                          <Text
                            color="#667085"
                            lineHeight={{ base: "18px", md: "21px" }}
                            fontSize={{ base: "12px", md: "14px" }}
                            fontWeight="500"
                          >
                            {item.date?.split("T")[0] || ""}
                          </Text>
                        </Td>

                        <Td>
                          <Text
                            color="#101828"
                            lineHeight={{ base: "18px", md: "21px" }}
                            fontSize={{ base: "12px", md: "14px" }}
                            fontWeight="500"
                          >
                            {item.campaignName}
                          </Text>
                        </Td>

                        <Td>
                          <Text
                            color="#101828"
                            lineHeight={{ base: "18px", md: "21px" }}
                            fontSize={{ base: "12px", md: "14px" }}
                            fontWeight="500"
                          >
                            {(item.productId &&
                              item.productId
                                .map((p: any) => p.id)
                                .join(", ")) ||
                              ""}
                          </Text>
                        </Td>

                        <Td>
                          <Text
                            color="#667085"
                            lineHeight={{ base: "18px", md: "21px" }}
                            fontSize={{ base: "12px", md: "14px" }}
                            fontWeight="500"
                          >
                            {item?.totalLeads}
                          </Text>
                        </Td>

                        <Td>
                          <Text
                            color="#667085"
                            lineHeight={{ base: "18px", md: "21px" }}
                            fontSize={{ base: "12px", md: "14px" }}
                            fontWeight="500"
                          >
                            ${item?.totalSales || 0}
                          </Text>
                        </Td>

                        <Td>
                          <Text
                            color="#667085"
                            lineHeight={{ base: "18px", md: "21px" }}
                            fontSize={{ base: "12px", md: "14px" }}
                            fontWeight="500"
                          >
                            ${item?.totalCommissions || 0}
                          </Text>
                        </Td>

                        <Td>
                          {item?.status === "approved" ? (
                            <Box
                              color="#22C55E"
                              rounded="40px"
                              bg="#DCFCE7"
                              display="flex"
                              alignItems="center"
                              w="max"
                              padding="6px 8px"
                            >
                              <MdFiberManualRecord fontSize="0.75rem" />
                              <Text
                                textTransform="capitalize"
                                fontSize="0.75rem"
                                lineHeight="1.25rem"
                                fontWeight="500"
                                ml="8px"
                              >
                                {item.status || ""}
                              </Text>
                            </Box>
                          ) : item?.status === "declined" ||
                            item?.status === "suspended" ? (
                            <Box
                              color="#FACC15"
                              rounded="40px"
                              bg="#FEF9C3"
                              display="flex"
                              alignItems="center"
                              w="max"
                              padding="6px 8px"
                            >
                              <MdFiberManualRecord fontSize="0.75rem" />
                              <Text
                                textTransform="capitalize"
                                fontSize="0.75rem"
                                lineHeight="1.25rem"
                                fontWeight="500"
                                color="#713F12"
                                ml="8px"
                              >
                                {item.status || ""}
                              </Text>
                            </Box>
                          ) : (
                            <Box
                              color="#FACC15"
                              rounded="40px"
                              bg="#FEF9C3"
                              display="flex"
                              alignItems="center"
                              w="max"
                              padding="6px 8px"
                            >
                              <MdFiberManualRecord fontSize="0.75rem" />
                              <Text
                                textTransform="capitalize"
                                fontSize="0.75rem"
                                lineHeight="1.25rem"
                                fontWeight="500"
                                color="#713F12"
                                ml="8px"
                              >
                                {item.status || ""}
                              </Text>
                            </Box>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            ) : reportsData && !reportsData.length ? (
              <Box
                w="100%"
                height="50vh"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                p={{ base: "0px 16px", md: "0px" }}
                mb="21px"
              >
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
                  height="250px"
                  objectFit="contain"
                  mb="16px"
                />

                <Text
                  fontWeight="600"
                  fontSize="14px"
                  lineHeight="21px"
                  color="#32325D"
                  textAlign="center"
                >
                  {existingFilter()
                    ? "No reports found"
                    : "No data available yet, when you have reports you'll see them here."}
                </Text>
              </Box>
            ) : (
              ""
            )}
          </Box>
        )}

        {/* <Flex
          w={"100%"}
          // bgColor={"red"}
          h={"50px"}
          mb="29px"
          justify={"flex-end"}
          align={"center"}
        >
          <Flex align={"center"} gap={3}>
            <IconButton
              aria-label="move left"
              icon={<ChevronLeftIcon fontSize={16} />}
              onClick={prevPageC}
            />
            <Flex
              borderWidth={0.5}
              borderColor={"#c4c4c4"}
              w={35}
              h={35}
              justify={"center"}
              align={"center"}
              borderRadius={10}
            >
              <Text>{currentPageC}</Text>
            </Flex>
            <IconButton
              aria-label="move right"
              icon={<ChevronRightIcon fontSize={16} />}
              onClick={nextPageC}
            />
          </Flex>
        </Flex> */}
      </Box>
    </Box>
  );
};

export default Reports;
