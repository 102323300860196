import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Campaign from "./campaign";
import Explore from "./explore";
import Reports from "./reports";
import Landing from "./landing";
import LoginLoader from "../components/loader";
import AccountSettings from "./account";
import Notification from "./notifications";
import { onMessageListener } from "../firebaseinit";
import Notifications from "../components/firebase-notifications/notification";
import ReactNotificationComponent from "../components/firebase-notifications/reactNotification";
import { getCurrentUserInstance } from "../state/localstorage";
import AffiliateCampaign from "./affiliate/campaign";
import AffiliateExplore from "./affiliate/explore";
import AffiliateReports from "./affiliate/reports";
import AffiliateSettings from "./affiliate/settings";
import AffiliateLanding from "./affiliate/landing";
import AffiliateNotification from "./affiliate/notifications";
import ReferMetricks from "../components/referMetricks/ReferMetricks";
import AffiliateSettingsTab from "../components/affiliate/affiliate-sidebar-settings";
import AdvertiserSettingsTab from "../components/advertiserSettings/AdvertiserSettingsTab";
import { Box, Image, Text } from "@chakra-ui/react";
import { gsap, Power2 } from "gsap";
import useUserToken from "../hooks/useUserToken";

const Dashboard = () => {
  const [show, setShow] = useState(false);
  const [orgLoading, setorgLoading] = useState<boolean | null>(null);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const user = getCurrentUserInstance();
  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      // console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));
  // console.log(show, notification);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (user.organisation.userType === "affiliate") {
      if (user.organisation.signup_step === 1) {
        navigate("/signup/affiliate-company-profile?default-country=Nigeria");
      }
      if (user.organisation.signup_step === 2) {
        navigate("/signup/affiliate-information");
      }
      if (user.organisation.signup_step === 3) {
        navigate("/signup/affiliate-niche");
      }
      if (user.organisation.signup_step === 4) {
        navigate("/signup/affiliate-background");
      }
    } else {
      if (user.organisation.signup_step === 1) {
        navigate("/signup/company-profile?default-country=Nigeria");
      }
      if (user.organisation.signup_step === 2) {
        navigate("/signup/marketing-assets");
      }
      if (user.organisation.signup_step === 3) {
        navigate("/signup/product-details");
      }
      if (user.organisation.signup_step === 4) {
        navigate("/signup/niche");
      }
      if (user.organisation.signup_step === 5) {
        navigate("/signup/billing");
      }
    }

    const timeOut = setTimeout(function () {
      setLoading(false);
    }, 1000); //wait 5 seconds

    return () => window.clearTimeout(timeOut);
  }, [user]);

  useEffect(() => {
    if (orgLoading !== null) {
      setorgLoading(true);
    } else {
      setorgLoading(false);
    }

    let timeout = setTimeout(function () {
      setorgLoading(false);
    }, 2000);

    return () => window.clearTimeout(timeout);
  }, [user.organisation.companyname]);

  useEffect(() => {
    gsap.fromTo(
      ".loading",
      {
        opacity: 0,
        ease: Power2.easeInOut,
        scale: 0,
        y: 80,
      },
      {
        opacity: 1,
        duration: 1.5,
        scale: 1,
        ease: Power2.easeInOut,
        repeat: -1,
        y: 0,
        yoyo: true,
      }
    );
  }, []);

  return (
    <Layout>
      {orgLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          h="90vh"
          alignItems="center"
        >
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Image className="loading" src="/assets/logo.png" height="60px" />

            <Text
              fontWeight="400"
              fontSize="16px"
              lineHeight="70px"
              color="#000000"
              mt="-15px"
            >
              {`Switching To ${user.organisation?.companyname}`}
            </Text>
          </Box>
        </Box>
      ) : (
        <>
          {show ? (
            <ReactNotificationComponent
              title={notification.title}
              body={notification.body}
            />
          ) : (
            <></>
          )}
          <Notifications />
          {user.organisation.userType === "advertiser" && (
            <>
              <Routes>
                <Route path="/" element={<Navigate replace to="dashboard" />} />
                <Route path="dashboard" element={<Landing />} />
                <Route path="campaigns/*" element={<Campaign />} />
                <Route path="reports" element={<Reports />} />
                <Route path="explore/*" element={<Explore />} />
                <Route path="settings/*" element={<AdvertiserSettingsTab />} />
                <Route path="notifications" element={<Notification />} />
                <Route
                  path="/dashboard/account-settings"
                  element={<AccountSettings />}
                />
                <Route path="metricks" element={<ReferMetricks />} />
              </Routes>
            </>
          )}
          {user.organisation.userType === "affiliate" && (
            <>
              <Routes>
                <Route
                  path="/"
                  element={<Navigate replace to="affiliate-dashboard" />}
                />
                <Route
                  path="affiliate-dashboard"
                  element={<AffiliateLanding />}
                />
                <Route
                  path="affiliate-campaigns/*"
                  element={<AffiliateCampaign />}
                />
                <Route
                  path="affiliate-reports"
                  element={<AffiliateReports />}
                />
                <Route
                  path="affiliate-explore/*"
                  element={<AffiliateExplore />}
                />
                <Route
                  path="affiliate-settings"
                  element={<AffiliateSettings />}
                />
                <Route path="settings/*" element={<AffiliateSettingsTab />} />
                <Route
                  path="affiliate-notifications"
                  element={<AffiliateNotification />}
                />
                <Route path="metricks" element={<ReferMetricks />} />
              </Routes>
            </>
          )}
        </>
      )}
    </Layout>
  );
};
export default Dashboard;
