import { Box, Flex, Stack, Image, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BaseButton } from "../button";

const NoData = () => {
  const navigate = useNavigate();
  return (
    <Stack background="#ffffff">
      <Flex
        m="30px 0px"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <Image
            src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg`}
          />
        </Box>
        <Box
          mt="16px"
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Text
            fontWeight="600"
            fontSize="16px"
            lineHeight="24px"
            color="#32325D"
          >
            Create a campaign to see your data
          </Text>
          <Box width="max-content" m="29px 0px" textAlign="center">
            <BaseButton
              text="Create Campaign"
              textColor="#fff"
              bgColor="#5C14CE"
              leftIcon={
                <Image
                  mr="7px"
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/df57e3b5-fda6-4bb5-88aa-2420eb4a55ae.svg"
                />
              }
              type="submit"
              clickEventHandler={() => navigate("/campaigns/create")}
            />
          </Box>
        </Box>
      </Flex>
    </Stack>
  );
};

export default NoData;
