import { Box, IconButton, Image } from "@chakra-ui/react";
import React from "react";
import { MdClose } from "react-icons/md";
import { getCurrentUserInstance } from "../../state/localstorage";
import AdvertiserChat from "./AdvertiserChat";
import AffiliateChat from "./AffChat";

const ChatElement = ({ onClose, data }: { onClose: () => void; data: any }) => {
  let user = getCurrentUserInstance();
  console.log("check", data);
  return (
    <Box
      width={{ base: "100%", md: "30%" }}
      maxW={{ md: "480px" }}
      maxH="830px"
      height={{ base: "100vh", md: "75vh" }}
      position="fixed"
      boxShadow="0px 3px 20px rgba(0, 0, 0, 0.3)"
      zIndex="40"
      bottom={{ md: "80px" }}
      right={{ md: "30px" }}
    >
      <Box
        w="100%"
        roundedTop="14px"
        bgColor="rgba(50, 50, 93, 1)"
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="15%"
      >
        <Image
          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/563b137d-0cf2-4c27-a868-566711f0e169.svg"
          alt="chat"
        />

        <IconButton
          position="absolute"
          _hover={{ background: "#536885c2" }}
          top="12px"
          right="20px"
          aria-label="chat-close"
          onClick={onClose}
          variant="ghost"
          icon={<MdClose color="#FFFF" />}
        />
      </Box>

      <Box height="85%" bgColor="white" maxH="85%">
        {user.organisation.userType === "affiliate" ? (
          <AffiliateChat data={data} />
        ) : (
          // <></>
          <AdvertiserChat data={data} />
        )}
      </Box>
    </Box>
  );
};

export default ChatElement;
