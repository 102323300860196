export const DashboardIcon = (props: any) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9998 1H13.2867C14.2331 1 15.0003 1.77422 15.0003 2.72903V5.03488C15.0003 5.989 14.2331 6.76391 13.2867 6.76391H10.9998C10.0533 6.76391 9.28613 5.989 9.28613 5.03488V2.72903C9.28613 1.77422 10.0533 1 10.9998 1Z"
        stroke="none"
        strokeWidth="1.05002"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.71434 1H4.99987C5.94699 1 6.71421 1.77422 6.71421 2.72903V5.03488C6.71421 5.989 5.94699 6.76391 4.99987 6.76391H2.71434C1.76722 6.76391 1 5.989 1 5.03488V2.72903C1 1.77422 1.76722 1 2.71434 1Z"
        stroke="none"
        strokeWidth="1.05002"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.71434 9.23633H4.99987C5.94699 9.23633 6.71421 10.0105 6.71421 10.9661V13.2712C6.71421 14.226 5.94699 15.0002 4.99987 15.0002H2.71434C1.76722 15.0002 1 14.226 1 13.2712V10.9661C1 10.0105 1.76722 9.23633 2.71434 9.23633Z"
        stroke="none"
        strokeWidth="1.05002"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9998 9.23633H13.2867C14.2331 9.23633 15.0003 10.0105 15.0003 10.9661V13.2712C15.0003 14.226 14.2331 15.0002 13.2867 15.0002H10.9998C10.0533 15.0002 9.28613 14.226 9.28613 13.2712V10.9661C9.28613 10.0105 10.0533 9.23633 10.9998 9.23633Z"
        stroke="none"
        strokeWidth="1.05002"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
    </svg>
  );
};
