import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { StepWrapper } from "../StepWrapper";
import { MdCancel } from "react-icons/md";
import { useEffect, useRef, useState } from "react";
import {
  IoArrowBackSharp,
  IoArrowForwardSharp,
  IoChevronDown,
  IoChevronUp,
} from "react-icons/io5";
import { BsCheckSquareFill, BsFillCaretUpFill, BsSearch } from "react-icons/bs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseButton } from "../../button";
import { useCookies } from "react-cookie";
import { toast, ToastContainer } from "react-toastify";
import {
  getCurrentUserInstance,
  setCurrentUserType,
  setLocalUserInstance,
} from "../../../state/localstorage";
import React from "react";

const useOutsideAlerter = (ref: any, closeHandler: Function) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        closeHandler();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

export const AffiliateNiche = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [orgType, setOrgType] = useState(false);
  useEffect(() => {
    if (searchParams.get("type") !== null) {
      setOrgType(searchParams.get("type") ? true : false);
    }
  }, [searchParams, orgType]);
  const [cookies] = useCookies([
    "user_id",
    "access_token",
    "aff_user",
    "aff_access_token",
  ]);

  const localUser = getCurrentUserInstance();
  localUser && console.log(localUser);

  const [searchQuery, setSearchQuery] = useState("");
  const [showIndustries, setShowIndustries] = useState(false);
  const [nicheData, setNicheData] = useState(
    localUser && localUser.nicheData
      ? localUser.nicheData
      : [
          {
            niche: "Accessories",
            selected: false,
          },
          {
            niche: "Acne",
            selected: false,
          },
          {
            niche: "Alcohol",
            selected: false,
          },
          {
            niche: "Air-Purification",
            selected: false,
          },
          {
            niche: "Anti-Aging",
            selected: false,
          },
          {
            niche: "Anxiety-Management",
            selected: false,
          },
          {
            niche: "Apparel",
            selected: false,
          },
          {
            niche: "Bags and purses",
            selected: false,
          },
          {
            niche: "Bed and bath",
            selected: false,
          },
          {
            niche: "Beddings",
            selected: false,
          },
          {
            niche: "Blood-Pressure",
            selected: false,
          },

          {
            niche: "Diabetes",
            selected: false,
          },
          {
            niche: "Cash-on-Delivery",
            selected: false,
          },
          {
            niche: "CBD & Marijuana",
            selected: false,
          },
          {
            niche: "Coffee",
            selected: false,
          },
          {
            niche: "Beddings",
            selected: false,
          },

          {
            niche: "Cognitive",
            selected: false,
          },
          {
            niche: "Cognitive-Enhancements",
            selected: false,
          },
          {
            niche: "Cosmetics",
            selected: false,
          },
          {
            niche: "Credit card",
            selected: false,
          },
          {
            niche: "Credit reporting and management",
            selected: false,
          },
          {
            niche: "Crochet and Knitting",
            selected: false,
          },
          {
            niche: "Cryptocurrency trading",
            selected: false,
          },
          {
            niche: "Debt",
            selected: false,
          },
          {
            niche: "Debt reduction and Settlement",
            selected: false,
          },
          {
            niche: "Decor",
            selected: false,
          },
          {
            niche: "Diabetes",
            selected: false,
          },
          {
            niche: "Diet",
            selected: false,
          },
          {
            niche: "Diet and recipes",
            selected: false,
          },
          {
            niche: "Digital marketing",
            selected: false,
          },
          {
            niche: "Drones",
            selected: false,
          },

          {
            niche: "E-Cig",
            selected: false,
          },
          {
            niche: "Education",
            selected: false,
          },
          {
            niche: "Embroidery",
            selected: false,
          },
          {
            niche: "Energy",
            selected: false,
          },
          {
            niche: "Energy drinks",
            selected: false,
          },
          {
            niche: "Erectile-Dysfunction",
            selected: false,
          },
          {
            niche: "Essential oils",
            selected: false,
          },

          {
            niche: "E-Sports",
            selected: false,
          },
          {
            niche: "Exercise",
            selected: false,
          },
          {
            niche: "Exercise-equipment",
            selected: false,
          },
          {
            niche: "Eye-Care",
            selected: false,
          },
          {
            niche: "Fat-Burners",
            selected: false,
          },
          {
            niche: "Feminine-Care",
            selected: false,
          },
          {
            niche: "Fertility",
            selected: false,
          },
          {
            niche: "Fitness",
            selected: false,
          },
          {
            niche: "Fitness tech and apps",
            selected: false,
          },
          {
            niche: "Fragrances",
            selected: false,
          },
          {
            niche: "Furniture",
            selected: false,
          },
          {
            niche: "Gadget",
            selected: false,
          },
          {
            niche: "Gaming",
            selected: false,
          },
          {
            niche: "Gardening",
            selected: false,
          },
          {
            niche: "GPS",
            selected: false,
          },
          {
            niche: "Grooming and Hygiene products",
            selected: false,
          },
          {
            niche: "Gut-Health",
            selected: false,
          },
          {
            niche: "Hair and hair products",
            selected: false,
          },
          {
            niche: "Health and wellness",
            selected: false,
          },
          {
            niche: "Herbal",
            selected: false,
          },
          {
            niche: "Home",
            selected: false,
          },
          {
            niche: "Home security",
            selected: false,
          },
          {
            niche: "HVAC",
            selected: false,
          },
          {
            niche: "Hydration",
            selected: false,
          },
          {
            niche: "Immunity",
            selected: false,
          },
          {
            niche: "Incontinence",
            selected: false,
          },
          {
            niche: "Investment portfolio management and trading",
            selected: false,
          },
          {
            niche: "Insurance",
            selected: false,
          },
          {
            niche: "Jewelry",
            selected: false,
          },
          {
            niche: "Joint-Pain",
            selected: false,
          },
          {
            niche: "Kids",
            selected: false,
          },
          {
            niche: "Kitchen and Dining",
            selected: false,
          },
          {
            niche: "Lashes",
            selected: false,
          },
          {
            niche: "Lead-Gen",
            selected: false,
          },
          {
            niche: "Loan application and management",
            selected: false,
          },
          {
            niche: "Loan application and management",
            selected: false,
          },
          {
            niche: "Luggage",
            selected: false,
          },
          {
            niche: "Male-Skin",
            selected: false,
          },
          {
            niche: "Meal kit and subscription boxes",
            selected: false,
          },
          {
            niche: "Mood-Enhancers",
            selected: false,
          },
          {
            niche: "Mortgage",
            selected: false,
          },

          {
            niche: "Muscle",
            selected: false,
          },
          {
            niche: "Nootropics",
            selected: false,
          },
          {
            niche: "Nutrition and supplements",
            selected: false,
          },
          {
            niche: "Online banking and savings",
            selected: false,
          },
          {
            niche: "Oral-Care",
            selected: false,
          },
          {
            niche: "Organic",
            selected: false,
          },
          {
            niche: "Others",
            selected: false,
          },
          {
            niche: "Outdoors",
            selected: false,
          },
          {
            niche: "Pain-Management",
            selected: false,
          },
          {
            niche: "Pain-Relief",
            selected: false,
          },
          {
            niche: "Parenting",
            selected: false,
          },
          {
            niche: "Personal development",
            selected: false,
          },
          {
            niche: "Personal workout programs",
            selected: false,
          },
          {
            niche: "Pets",
            selected: false,
          },
          {
            niche: "Phone-Accessories",
            selected: false,
          },
          {
            niche: "Photography",
            selected: false,
          },
          {
            niche: "Plant and flower",
            selected: false,
          },
          {
            niche: "Pottery and ceramics",
            selected: false,
          },
          {
            niche: "Pregnancy",
            selected: false,
          },
          {
            niche: "Security devices",
            selected: false,
          },
          {
            niche: "Sexual-Wellness",
            selected: false,
          },
          {
            niche: "Skin and skincare",
            selected: false,
          },
          {
            niche: "Software and apps",
            selected: false,
          },
          {
            niche: "Speakers and headphones",
            selected: false,
          },
          {
            niche: "Sports",
            selected: false,
          },
          {
            niche: "Stationary",
            selected: false,
          },
          {
            niche: "Stress-Management",
            selected: false,
          },
          {
            niche: "Sweepstakes",
            selected: false,
          },
          {
            niche: "Tea",
            selected: false,
          },
          {
            niche: "Toys",
            selected: false,
          },
          {
            niche: "Travel",
            selected: false,
          },
          {
            niche: "Telemedicine",
            selected: false,
          },
          {
            niche: "Testing-Kits",
            selected: false,
          },
          {
            niche: "Tracking devices",
            selected: false,
          },
          {
            niche: "TV sets and remote controls",
            selected: false,
          },
          {
            niche: "Vegan",
            selected: false,
          },
          {
            niche: "Vitamins and supplements",
            selected: false,
          },

          {
            niche: "VPN",
            selected: false,
          },

          {
            niche: "Watches",
            selected: false,
          },

          {
            niche: "Wearable",
            selected: false,
          },
          {
            niche: "Weight-Loss",
            selected: false,
          },
          {
            niche: "Wills and estate planning",
            selected: false,
          },
          {
            niche: "Women's-Health",
            selected: false,
          },
          {
            niche: "Yoga",
            selected: false,
          },
        ]
  );

  const toggleHandler = () => {
    return setShowIndustries(!showIndustries);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setShowIndustries(false));

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [saveAndExit, setSaveAndExit] = useState(false);
  const submitHandler = () => {
    if (
      nicheData.filter((singleNiche: any) => singleNiche.selected).length < 1
    ) {
      toast.warning("Please complete all required fields");
      return;
    }

    const payload = {
      niche: nicheData
        .filter((singleNiche: any) => singleNiche.selected)
        .map((data: any) => data.niche),
    };

    setLoading(true);
    fetch(process.env.REACT_APP_API_ENDPOINT + "auth/affiliate-signup/step4", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.aff_access_token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);

        if (data.success) {
          setLocalUserInstance({ ...localUser, nicheData });
          setCurrentUserType("affiliate");

          const user = getCurrentUserInstance();
          user.organisation.signup_step = 5;
          setLocalUserInstance(user);
          if (orgType) {
            saveAndExit
              ? window.open("https://www.metricks.io", "_self")
              : navigate("/signup/affiliate-background?type=newOrganization");
          } else {
            saveAndExit
              ? window.open("https://www.metricks.io", "_self")
              : navigate("/signup/affiliate-background");
          }
        }
        console.log(data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const step = [
    {
      title: "User Profile",
      status: "passed",
      key: 1,
    },
    {
      title: "Company Profile",
      status: "passed",
      key: 2,
    },
    {
      title: "Affiliate Information",
      status: "passed",
      key: 3,
    },
    {
      title: "Niche",
      status: "current",
      key: 4,
    },
    {
      title: "Affiliate Background",
      status: "default",
      key: 5,
    },
  ];
  const orgSteps = [
    {
      title: "Company Profile",
      status: "passed",
      key: 1,
    },
    {
      title: "Affiliate Information",
      status: "passed",
      key: 2,
    },
    {
      title: "Niche",
      status: "current",
      key: 3,
    },
    {
      title: "Affiliate Background",
      status: "default",
      key: 4,
    },
  ];
  const steps = orgType ? orgSteps : step;
  return (
    <StepWrapper
      step={steps.map((item: any) => {
        if (item.status === "current") {
          let step = `STEP ${item.key} OF ${steps.length}`;
          return step;
        }
      })}
      header="Setting up your affiliate account "
      baseText="Niche"
      stepsMap={steps}
    >
      <Flex
        position={"relative"}
        direction={"column"}
        alignItems={"center"}
        w={["90%", "60%"]}
      >
        <Flex w={"100%"} direction={"column"}>
          <ToastContainer />
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            height="80vh"
          >
            <Box>
              <Heading
                mb="1em"
                lineHeight="40px"
                fontSize="15px"
                fontWeight={showIndustries ? "medium" : "normal"}
                color={showIndustries ? " #5C14CE" : "#737373"}
              >
                What industy are you targeting?{" "}
                <span style={{ color: "#dc3545" }}>*</span>
              </Heading>

              <Box ref={wrapperRef}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  borderBottom={
                    showIndustries ? "1px solid #5C14CE" : "1px solid #B6BDC3"
                  }
                  pb=".3em"
                  cursor="pointer"
                  onClick={toggleHandler}
                >
                  <Flex columnGap=".5em" rowGap=".5em" flexWrap="wrap">
                    {nicheData.filter(
                      (singleNiche: any) => singleNiche.selected
                    ).length >= 1 ? (
                      nicheData
                        .filter((singleNiche: any) => singleNiche.selected)
                        .map(
                          (
                            singleNiche: { niche: string; selected: boolean },
                            i: any
                          ) => {
                            return (
                              <Box
                                key={i}
                                bgColor="#F0F5FF"
                                py={["3px", "3px", "6px"]}
                                px=".4em"
                                alignItems="center"
                                display="flex"
                                borderRadius="4px"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <Text
                                  mr=".8em"
                                  fontSize="14px"
                                  fontWeight="500"
                                  color="#000"
                                >
                                  {singleNiche.niche}
                                </Text>
                                <MdCancel
                                  color="#000"
                                  cursor="pointer"
                                  onClick={() => {
                                    const newState = nicheData.map(
                                      (data: any, x: any) => {
                                        if (singleNiche.niche === data.niche) {
                                          return { ...data, selected: false };
                                        } else {
                                          return data;
                                        }
                                      }
                                    );
                                    setNicheData(newState);
                                  }}
                                />
                              </Box>
                            );
                          }
                        )
                    ) : (
                      <Text fontSize="14px" fontWeight="500" color="#000">
                        Select Option
                      </Text>
                    )}
                  </Flex>

                  <Box>
                    {!showIndustries ? (
                      <IoChevronDown
                        fontSize="20px"
                        color="#737373"
                        cursor="pointer"
                        onClick={toggleHandler}
                      />
                    ) : (
                      <IoChevronUp
                        fontSize="20px"
                        color="#737373"
                        cursor="pointer"
                        onClick={toggleHandler}
                      />
                    )}
                  </Box>
                </Box>

                {showIndustries && (
                  <Box
                    p={["1em", "1em", "1.5em"]}
                    my="1em"
                    boxShadow="xl"
                    rounded="xl"
                    maxH="60vh"
                    overflowY="scroll"
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      my=".2em"
                    >
                      <Text fontSize="14px" fontWeight="500" color="#737373">
                        Search Niche
                      </Text>

                      <BsFillCaretUpFill
                        color="#CACBD4"
                        cursor="pointer"
                        onClick={toggleHandler}
                      />
                    </Flex>

                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<BsSearch color="gray.300" />}
                      />
                      <Input
                        type="text"
                        _focus={{ ring: "0" }}
                        border="0"
                        bgColor="#F4F6F8"
                        value={searchQuery}
                        onChange={(e: React.ChangeEvent<any>) =>
                          setSearchQuery(e.target.value)
                        }
                      />
                    </InputGroup>

                    {nicheData.map((singleNiche: any, i: any) => {
                      return (
                        <Box
                          key={i}
                          display={
                            searchQuery !== "" &&
                            !singleNiche.niche
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                              ? "none"
                              : "flex"
                          }
                          justifyContent="space-between"
                          alignItems="center"
                          my="1em"
                          cursor="pointer"
                          _hover={{ bgColor: "gray.50" }}
                          onClick={() => {
                            const newState = nicheData.map(
                              (data: any, x: any) => {
                                if (nicheData[i] == data) {
                                  return { ...data, selected: !data.selected };
                                } else {
                                  return data;
                                }
                              }
                            );
                            setNicheData(newState);
                          }}
                          p=".5em"
                          borderRadius="3px"
                        >
                          <Text
                            fontWeight="500"
                            color="#737373"
                            fontSize="14px"
                          >
                            {singleNiche.niche}
                          </Text>

                          {singleNiche.selected ? (
                            <BsCheckSquareFill color="#5C15CE" />
                          ) : (
                            <Box
                              width="1em"
                              height="1em"
                              border="1.5px solid gray"
                              borderRadius="4px"
                            ></Box>
                          )}

                          {/* <Checkbox
                          onChange={(e) => {
                            if (e.target.checked) {
                              const newState = nicheData.map(
                                (data: any, x: any) => {
                                  if (nicheData[i] == data) {
                                    return { ...data, selected: true };
                                  } else {
                                    return data;
                                  }
                                }
                              );
                              setNicheData(newState);
                            } else {
                              const newState = nicheData.map(
                                (data: any, x: any) => {
                                  if (nicheData[i] == data) {
                                    return { ...data, selected: false };
                                  } else {
                                    return data;
                                  }
                                }
                              );
                              setNicheData(newState);
                            }
                          }}
                          mr=".2em"
                          defaultChecked={singleNiche.selected}
                          colorScheme="purple"
                        >
                          {""}
                        </Checkbox> */}
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            </Box>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              mb={{ base: "1em", md: "1.75em" }}
              mt={{ base: "3em", lg: showIndustries ? "3em" : "25vh" }}
              flexDirection={{ base: "column-reverse", lg: "row" }}
              rowGap=".6em"
            >
              <Button
                px="0"
                color="#5C14CE"
                bgColor="#fff"
                fontWeight="500"
                fontSize="14px"
                _hover={{ bgColor: "#fff" }}
                _focus={{ bgColor: "#fff" }}
                onClick={() => navigate("/signup/affiliate-information")}
                leftIcon={<IoArrowBackSharp />}
              >
                Back to the previous step
              </Button>
              <Box
                width={["100%", "50%", "28%"]}
                display="flex"
                alignItems="center"
                mb={{ base: "13px", md: "0px" }}
                justifyContent="flex-end"
                // fgh
                flexDirection={["column-reverse", "row"]}
                rowGap=".6em"
                justifySelf="flex-end"
              >
                <Box ml={["0em", "1em"]} width="100%">
                  <BaseButton
                    text="Next step"
                    textColor="#fff"
                    bgColor="#5C14CE"
                    loadingState={loading}
                    rightIcon={<IoArrowForwardSharp />}
                    clickEventHandler={submitHandler}
                  />
                </Box>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </StepWrapper>
  );
};
