import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Box, Text } from "@chakra-ui/react";
import { MdArrowForwardIos } from "react-icons/md";
import SettingsOverview from "./SettingsOverview";
import OrganizationSettings from "./OrganizationSettings";
import Managers from "./Managers";
import useUserToken from "../../../hooks/useUserToken";

const AffiliateSettingsTab = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pageName, setpageName] = useState<string>("");
  const token = useUserToken("affiliate");

  const checkPageName = () => {
    if (location.pathname === "/settings") {
      return "";
    } else if (location.pathname === "/settings/payout-manager") {
      return "Payout Manager";
    } else if (location.pathname === "/settings/managers") {
      return "Managers";
    } else if (location.pathname === "/settings/organization") {
      return "Organization";
    } else {
      return "";
    }
  };

  const checkToken = async () => {
    if (!token) return;

    await fetch(process.env.REACT_APP_API_ENDPOINT + `/auth/valid-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data?.valid) {
          return;
        } else {
          window.localStorage.clear();
          sessionStorage.removeItem("currentUserType");
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          navigate("/login");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (token) {
      checkToken();
    }
  }, [token]);

  useEffect(() => {
    setpageName(checkPageName());
  }, [location.pathname]);

  return (
    <Box>
      <Box display="flex" bgColor="white" alignItems="center" p="12px 19px">
        <Text
          color="rgba(50, 50, 93, 0.5)"
          fontSize="12px"
          lineHeight="18px"
          mr="3px"
          cursor="pointer"
          onClick={() => navigate("/settings")}
          _hover={{ textDecoration: "underline" }}
        >
          Settings
        </Text>

        <MdArrowForwardIos
          style={{ fontSize: "14px", color: "rgba(50, 50, 93, 0.5)" }}
        />

        <Text
          color="rgba(50, 50, 93, 1)"
          fontSize="12px"
          lineHeight="18px"
          ml="3px"
        >
          {pageName}
        </Text>
      </Box>

      <Box p="0px 20px">
        <Routes>
          <Route path="/" element={<SettingsOverview />} />
          <Route path="/organization" element={<OrganizationSettings />} />
          <Route path="/managers" element={<Managers />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default AffiliateSettingsTab;
