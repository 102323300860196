import {
  Box,
  Flex,
  Image,
  Input,
  Select,
  Stack,
  Text,
  InputGroup,
  InputLeftElement,
  Button,
  Container,
  useToast,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
// @ts-ignore
import { getList } from "country-list-with-dial-code-and-flag";
// @ts-ignore
import { EditorState, convertToRaw } from "draft-js";
// @ts-ignore
import draftToHtml from "draftjs-to-html";
// @ts-ignore
import { convertFromHTML, ContentState } from "draft-js";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  // IoArrowBackSharp,
  IoArrowForwardSharp,
  IoChevronDown,
  IoChevronUp,
} from "react-icons/io5";
import { MdCancel } from "react-icons/md";

import { BsCheckSquareFill, BsFillCaretUpFill, BsSearch } from "react-icons/bs";
// import { BiDollar } from "react-icons/bi";
// @ts-ignore
// import { EditorState } from "draft-js";
import { UploadFileInputUI } from "../../UploadFileInputUI";
import { TextEditor } from "../../TextEditor";
// import { useAllCurrencies } from "../../../hooks/useAllCurrencies";
import { useOutsideAlerter } from "../../../hooks/useOutsideAlerter";
import { industries } from "../../../utils/niche";
import { BaseButton } from "../../button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useConvertToBs64 } from "../../../hooks/useConvertToBase64";
import { apiReqHandler } from "../../../helper/apiReqHandler";
import { endpoints } from "../../../utils/endpoints";
import { getCookie } from "cookies-next";
import { uploadPhoto } from "../../../helper/uploadPhoto";

import { CurrencyInputField } from "../../formInput/CurrencyInput";
import { useRecoilState } from "recoil";
import { campaignState } from "../../../state/campaign";

import { fetchCampaign } from "../../../helper/fetchCampaign";
import { CampaignHeader } from "./Header";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";

export const CampaignDetails = () => {
  const [campaignStateObj, setCampaignStateObj] = useRecoilState(campaignState);
  // console.log("industries", industries);
  const [searchParams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const campaignId = searchParams.get("campaign");

  const [editPage] = useState(
    window.location.pathname.includes("/edit-campaign")
  );

  useEffect(() => {
    if (!window.location.pathname.includes("/edit-campaign") && !campaignId) {
      setCampaignStateObj({});
    }
  }, []);

  //

  const [campaignName, setCampaignName] = useState(
    campaignStateObj?.campaignName ? campaignStateObj?.campaignName : ""
  );
  const [landingPage, setLandingPage] = useState(
    campaignStateObj?.landingPage ? campaignStateObj?.landingPage : ""
  );
  const [productType, setProductType] = useState(
    campaignStateObj?.productType ? campaignStateObj?.productType : "SaaS"
  );
  const [bannerImage, setBannerImage]: any = useState(
    campaignStateObj?.bannerImage ? campaignStateObj?.bannerImage : null
  );
  const [backgroundImage, setBackgroundImage]: any = useState(
    campaignStateObj?.backgroundImage ? campaignStateObj?.backgroundImage : null
  );

  // let blocksFromHTML: any;
  // campaignStateObj?.description
  //   ? (blocksFromHTML = convertFromHTML(campaignStateObj?.description))
  //   : (blocksFromHTML = null);

  const [description, setDescription] = useState(() => {
    if (campaignStateObj.description)
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(campaignStateObj?.description).contentBlocks,
          convertFromHTML(campaignStateObj?.description).entityMap
        )
      );
    else EditorState.createEmpty();
  });

  const [allCountries, setAllCountries] = useState(
    campaignStateObj?.allCountries
      ? campaignStateObj?.allCountries
      : getList().map((country: any) => {
          return { country, selected: false };
        })
  );

  // console.log("allCountries", allCountries);
  const [searchQuery, setSearchQuery] = useState("");
  const [showIndustries, setShowIndustries] = useState(false);

  const [nicheData, setNicheData] = useState(industries);
  const toggleHandler = () => {
    return setShowIndustries(!showIndustries);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setShowIndustries(false));

  const [countrySearchQuery, setCountrySearchQuery] = useState("");
  const [showCountryFilter, setShowCountryFilter] = useState(false);

  const countryToggleHandler = () => {
    return setShowCountryFilter(!showCountryFilter);
  };

  const countryWrapperRef = useRef(null);
  useOutsideAlerter(countryWrapperRef, () => setShowCountryFilter(false));
  const { convertFile } = useConvertToBs64();
  const toast = useToast();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [bannerImgUrl, setBannerImgUrl]: any = useState(null);
  const [bgImgUrl, setBgImgUrl]: any = useState(null);

  const [isDescEmpty, setIsDescEmpty] = useState(false);
  const [isNicheEmpty, setIsNicheEmpty] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [isEmptyShipTo, setIsEmptyShipTo] = useState(false);
  const [saveAndExit, setSaveAndExit] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    function handleBeforeUnload(event: any) {
      if (!formSubmitted) {
        event.preventDefault();
        event.returnValue = "";
      }
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formSubmitted]);

  const reqestHandler: any = async () => {
    const urlPattern = new RegExp(
      "^([a-zA-Z]+:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$", // fragment locator
      "i"
    );

    // if (landingPage?.length >= 1 && !urlPattern.test(landingPage)) {
    //   setIsValidUrl(false);
    // }

    // @ts-ignore
    if (!description?.getCurrentContent()?.hasText()) {
      setIsDescEmpty(true);
    }

    if (selectedItem.length === 0) {
      setIsNicheEmpty(true);
    }

    if (selectedItemC.length === 0) {
      setIsEmptyShipTo(true);
    }

    if (
      // @ts-ignore
      !description?.getCurrentContent()?.hasText() ||
      selectedItem.length === 0 ||
      selectedItemC.length === 0
    )
      return;

    const payload = {
      campaign_name: campaignName,
      // landing_page_url: landingPage,
      product_type: productType.toLowerCase(),
      banner_image:
        typeof bannerImage === "string"
          ? bannerImage
          : await uploadPhoto(bannerImage),
      background_image:
        typeof backgroundImage === "string"
          ? backgroundImage
          : await uploadPhoto(backgroundImage),
      // @ts-ignore
      description: draftToHtml(convertToRaw(description?.getCurrentContent())),
      // product_price: Number(productPrice),
      //   payout_threshold: payoutThreshold,
      niche: selectedItem,
      countriesToShip: selectedItemC,

      //   currency: currency.split(" - ")[0],
    };

    const { res, error } = await apiReqHandler({
      endPoint: campaignId
        ? `${endpoints.campaign.CREATE.STEP_ONE}?campaignId=${campaignId}`
        : endpoints.campaign.CREATE.STEP_ONE,
      method: "POST",
      payload,
      //   @ts-ignore
      bearerAuth: getCookie("access_token"),
    });

    if (error)
      return toast({
        title: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });

    if (res) {
      // @ts-ignore
      console.log("res 1", res.data.data);
      setCampaignStateObj({
        campaignId: res.data.data.id,
        campaignName,
        landingPage,
        productType,
        bannerImage,
        backgroundImage,

        // @ts-ignore
        description: draftToHtml(
          convertToRaw(description?.getCurrentContent())
        ),
        // productPrice,
        nicheData,
        allCountries,
        ...campaignStateObj,
      });
    }
    return { res, error };
  };

  const submitHandler: any = async (e: any) => {
    e.preventDefault();
    setFormSubmitted(true);

    // @ts-ignore
    if (!description?.getCurrentContent()?.hasText()) {
      setIsDescEmpty(true);
    }

    if (selectedItem.length === 0) {
      setIsNicheEmpty(true);
    }

    if (selectedItemC.length === 0) {
      setIsEmptyShipTo(true);
    }

    if (
      // @ts-ignor
      !description?.getCurrentContent().hasText() ||
      selectedItem.length === 0 ||
      selectedItemC.length === 0
    ) {
      return console.log("NOt now");
    } else {
      setLoader(true);
      const { res, error } = await reqestHandler();
      console.log("num1", res);

      setLoader(false);
      if (!error)
        return navigate(
          editPage
            ? `/campaigns/edit-campaign/customization?product=${productType.toLowerCase()}&campaign=${
                // @ts-ignore
                res?.data?.data?.id
              }`
            : `/campaigns/customization?product=${productType.toLowerCase()}&campaign=${
                // @ts-ignore
                res?.data?.data?.id
              }`
        );
    }
  };

  const saveAndExitHandler = async () => {
    // if (campaignName.length < 1 || landingPage.length < 1) return;
    // if (campaignName.length < 1) return;

    // const urlPattern = new RegExp(
    //   "^([a-zA-Z]+:\\/\\/)?" + // protocol
    //     "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    //     "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
    //     "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    //     "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    //     "(\\#[-a-z\\d_]*)?$", // fragment locator
    //   "i"
    // );

    // if (landingPage?.length >= 1 && !urlPattern.test(landingPage)) {
    //   setIsValidUrl(false);
    // }

    // @ts-ignore
    if (!description?.getCurrentContent()?.hasText()) {
      setIsDescEmpty(true);
    }

    if (nicheData.filter((niche: any) => niche.selected)?.length === 0) {
      setIsNicheEmpty(true);
    }

    if (allCountries.filter((ctry: any) => ctry.selected)?.length === 0) {
      setIsEmptyShipTo(true);
    }

    if (
      // @ts-ignor
      !description?.getCurrentContent().hasText() ||
      nicheData.filter((niche: any): any => niche.selected)?.length === 0 ||
      allCountries.filter((ctry: any): any => ctry.selected)?.length === 0
    ) {
      return console.log("NOt now");
    } else {
      setSaveAndExit(true);
      const { res, error } = await reqestHandler();
      console.log("u looking for me", res);
      if (res) {
        return navigate("/campaigns");
      }
      setSaveAndExit(false);

      if (!error) {
        setCampaignStateObj({});
        return navigate("/campaigns");
      }
    }
  };

  useEffect(() => {
    if (isDescEmpty) {
      const descErr = document.querySelector(".descError");
      descErr?.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    // if (isValidUrl === false) {
    //   const urlErr = document.querySelector(".urlError");
    //   urlErr?.scrollIntoView({ behavior: "smooth", block: "start" });
    // }

    if (isNicheEmpty) {
      const nicheErr = document.querySelector(".nicheError");
      nicheErr?.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    if (isEmptyShipTo) {
      const shipErr = document.querySelector(".shipError");
      shipErr?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [isDescEmpty, isNicheEmpty, isEmptyShipTo]);

  if (
    (bannerImage &&
      typeof bannerImage !== "string" &&
      Object.keys(bannerImage)?.length !== 0) ||
    bannerImage?.name
  ) {
    (async () => {
      const url = await convertFile(bannerImage);
      setBannerImgUrl(url);
      console.log("TEXT");
    })();
  }

  if (
    (backgroundImage &&
      typeof backgroundImage !== "string" &&
      Object.keys(backgroundImage)?.length !== 0) ||
    backgroundImage?.name
  ) {
    (async () => {
      const url = await convertFile(backgroundImage);
      setBgImgUrl(url);
    })();
  }

  const [lockCampaign, setLockCampaign] = useState(false);

  useEffect(() => {
    if (campaignId) {
      const getCampaignData = async () => {
        setLoading(true);

        const { campaignData } = await fetchCampaign({ campaignId });

        setLoading(false);

        // if (campaignData.isComplete) {
        console.log("step 1", campaignData);
        if (campaignData?.status?.toLowerCase() === "active") {
          setLockCampaign(true);
        }
        setCampaignName(campaignData?.campaign_name);
        setLandingPage(campaignData?.landing_page_url);
        setBackgroundImage(campaignData?.background_image);
        setBannerImage(campaignData?.banner_image);
        setProductType(campaignData?.product_type);

        setDescription(() =>
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(campaignData?.description).contentBlocks,
              convertFromHTML(campaignData?.description).entityMap
            )
          )
        );

        selectedItems(campaignData.niche);
        selectedItemsC(campaignData.countriesToShip);

        // setAllCountries(() =>
        //   allCountries.map((item: any) => {
        //     if (campaignData.countriesToShip.includes(item.country.name)) {
        //       return { ...item, selected: true };
        //     } else {
        //       return { ...item, selected: false };
        //     }
        //   })
        // );
      };
      getCampaignData();
    }
  }, []);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [campaignNameEmpty, setCampaignNameEmpty] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const imageupload: any = async (data: any) => {
    // if (landingPage?.length >= 1 && !urlPattern.test(landingPage)) {
    //   setIsValidUrl(false);
    // }
    setSaveAndExit(true);

    if (campaignName == "") {
      setCampaignNameEmpty(true);
    }

    // @ts-ignore
    if (!description?.getCurrentContent()?.hasText()) {
      setIsDescEmpty(true);
    }

    if (selectedItem.length === 0) {
      setIsNicheEmpty(true);
    }

    if (selectedItemC.length === 0) {
      setIsEmptyShipTo(true);
    }

    if (
      // @ts-ignore
      !description?.getCurrentContent()?.hasText() ||
      selectedItem.length === 0 ||
      selectedItemC.length === 0
    )
      setSaveAndExit(false);
    return;

    const payload = {
      campaign_name: campaignName,
      // landing_page_url: landingPage,
      product_type: productType.toLowerCase(),
      banner_image:
        typeof bannerImage === "string"
          ? bannerImage
          : await uploadPhoto(bannerImage),
      background_image: await uploadPhoto(data),
      // @ts-ignore
      description: draftToHtml(convertToRaw(description?.getCurrentContent())),
      // product_price: Number(productPrice),
      //   payout_threshold: payoutThreshold,
      niche: selectedItem,
      countriesToShip: selectedItemC,

      //   currency: currency.split(" - ")[0],
    };

    const { res, error } = await apiReqHandler({
      endPoint: campaignId
        ? `${endpoints.campaign.CREATE.STEP_ONE}?campaignId=${campaignId}`
        : endpoints.campaign.CREATE.STEP_ONE,
      method: "POST",
      payload,
      //   @ts-ignore
      bearerAuth: getCookie("access_token"),
    });

    if (error)
      return toast({
        title: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });

    if (res) {
      // @ts-ignore
      console.log("res 1", res.data.data);
      setCampaignStateObj({
        campaignId: res?.data?.data.id,
        campaignName,
        landingPage,
        productType,
        bannerImage,
        backgroundImage,

        // @ts-ignore
        description: draftToHtml(
          // @ts-ignore
          convertToRaw(description?.getCurrentContent())
        ),
        // productPrice,
        nicheData,
        allCountries,
        ...campaignStateObj,
      });
      setBgImgUrl(null);
      setSaveAndExit(false);
      setCrop({ x: 0, y: 0 });
      navigate("/campaigns");
    }
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        bgImgUrl,
        croppedAreaPixels,
        rotation
      );
      // console.log("donee", croppedImage.file);
      onClose();

      setBackgroundImage(await uploadPhoto(croppedImage.file));
      // setBgImgUrl(croppedImage.url);
      // imageupload(croppedImage.file);
      window.opener.location.href = window.opener.location;
      // setCrop({ x: 0, y: 0 });
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const onClosed = useCallback(() => {
    // setBgImgUrl(null)
    console.log("closed");
  }, []);

  // console.log({ backgroundImage, bannerImage });

  const [selectedItem, selectedItems]: any = useState([]);
  const [selectedItemC, selectedItemsC]: any = useState([]);
  const handleSelect = (item: any) => {
    console.log("selectedCountry", selectedItem), item;
    // const isSelected = selectedItem.includes(item);
    const isSelected = selectedItem.some(
      (selectedItemData: any) => selectedItemData === item
    );
    // setSelectAll(false);
    // console.log(isSelected);
    if (isSelected) {
      // If item is already selected, remove it from the selectedItems array
      selectedItems((prevSelectedItems: any) =>
        prevSelectedItems.filter((selectedItem: any) => selectedItem !== item)
      );
    } else {
      // If item is not selected, add it to the selectedItems array
      selectedItems((prevSelectedItems: any) => [...prevSelectedItems, item]);
    }
  };
  const handleSelectC = (item: any) => {
    console.log("selectedCountry", item);
    // const isSelected = selectedItem.includes(item);
    const isSelected = selectedItemC.some(
      (selectedItemData: any) => selectedItemData == item
    );
    // setSelectAll(false);
    console.log(isSelected);
    if (isSelected) {
      selectedItemsC((prevSelectedItems: any) =>
        prevSelectedItems.filter((selectedItem: any) => selectedItem !== item)
      );
    } else {
      // selectedItemsC((prevSelectedItems: any) =>
      //   prevSelectedItems.filter((selectedItem: any) => selectedItem !== item)
      // );
      selectedItemsC((prevSelectedItems: any) => [...prevSelectedItems, item]);
    }
  };

  return (
    <Box m="0px 0px 22px 0px">
      <CampaignHeader currentStep={1} />

      {loading && editPage ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          h="50vh"
          alignItems="center"
          pt="20px"
        >
          <Image className="loading" src="/assets/logo.png" height="60px" />
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="70px"
            color="#000000"
            mt="-15px"
          >
            Fetching Campaign Details ...
          </Text>
        </Box>
      ) : (
        <Stack bgColor="#fff" padding="1.3em" borderRadius=".4em" mx={"22px"}>
          <Heading
            fontSize="1em"
            color="#32325D"
            borderBottom="1px solid"
            borderColor="gray.300"
            pb="1.3em"
          >
            Campaign Details
          </Heading>

          <form onSubmit={submitHandler} encType="multipart/form-data">
            {/* <Container maxW="container.lg" mx="auto"> */}
            <Flex
              borderBottom={["0", "1px solid #CBD5E0"]}
              py={[".5em", "1em"]}
              alignItems="center"
              my="1em"
              flexDirection={["column", "row"]}
              borderTopColor={{ md: "gray.300" }}
              gap={3}
            >
              <Flex width={["100%", "30%"]}>
                <Text
                  fontSize={["14px", "1em"]}
                  color="#32325D"
                  flexGrow={1}
                  width={{ base: "100%", md: "49%" }}
                  textAlign="left"
                  mb={[".3em", "0em"]}
                >
                  Campaign Name
                  <span style={{ color: "red", marginLeft: 5 }}>*</span>
                </Text>
              </Flex>
              <Flex width={["100%", "50%"]} flexDir={"column"}>
                <Input
                  type="text"
                  value={campaignName}
                  onChange={(e: React.ChangeEvent<any>) => {
                    setCampaignName(e.target.value);
                  }}
                  _focus={{ ring: "0", borderColor: "#5C14CE" }}
                  isRequired={true}
                  // isDisabled={!editState}
                  style={{
                    height: "50px",
                    backgroundColor: "#fefdff",
                    // borderColor: "#c4c4c4",
                    borderRadius: "10px",
                  }}
                />
                {campaignNameEmpty ? (
                  <Text
                    fontSize="14px"
                    color="red.300"
                    fontWeight="medium"
                    className="descError"
                  >
                    This field is required
                  </Text>
                ) : null}
              </Flex>
            </Flex>

            <Flex
              borderBottom={["0", "1px solid #CBD5E0"]}
              py={[".5em", "1em"]}
              alignItems="center"
              my="1em"
              flexDirection={["column", "row"]}
              borderTopColor={{ md: "gray.300" }}
              gap={3}
              columnGap="1em"
            >
              <Flex width={["100%", "30%"]}>
                <Text
                  fontSize={["14px", "1em"]}
                  color="#32325D"
                  flexGrow={1}
                  width={{ base: "100%", md: "49%" }}
                  textAlign="left"
                  mb={[".3em", "0em"]}
                >
                  Product Type
                  <span style={{ color: "red", marginLeft: 5 }}>*</span>
                </Text>
              </Flex>

              <Flex
                width={["100%", "50%"]}
                alignItems="center"
                justifyContent="space-between"
                columnGap=".5em"
              >
                <Select
                  _focus={{ ring: "0", borderColor: "#5C14CE" }}
                  isRequired={true}
                  value={productType}
                  onChange={(e: React.ChangeEvent<any>) =>
                    setProductType(e.target.value)
                  }
                  width={{ base: "100%" }}
                  isDisabled={editPage && lockCampaign}
                  style={{
                    height: "50px",
                    backgroundColor: "#fefdff",
                    // borderColor: "#c4c4c4",
                    borderRadius: "10px",
                  }}
                >
                  {["SaaS", "Ecommerce"].map((prdType) => (
                    <option key={prdType} value={prdType.toLowerCase()}>
                      {prdType}
                    </option>
                  ))}
                </Select>

                {editPage && lockCampaign ? (
                  <img
                    alt=""
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/b50a18cd-5809-45f9-a35b-ba79eab96c4b.svg"
                  />
                ) : null}
              </Flex>
            </Flex>

            <Flex
              borderBottom={["0", "1px solid #CBD5E0"]}
              py={[".5em", "1em"]}
              alignItems="center"
              my="1em"
              flexDirection={["column", "row"]}
              borderTopColor={{ md: "gray.300" }}
              gap={3}
              columnGap="1em"
            >
              <Flex width={["100%", "30%"]}>
                <Text
                  fontSize={["14px", "1em"]}
                  color="#32325D"
                  flexGrow={1}
                  width={{ base: "100%", md: "49%" }}
                  textAlign="left"
                  mb={[".3em", "0em"]}
                >
                  Logo
                  <span style={{ color: "red", marginLeft: 5 }}>*</span>
                </Text>
              </Flex>

              <Box
                width={["100%", "50%"]}
                style={{
                  backgroundColor: "#fefdff",
                  borderRadius: "10px",
                }}
              >
                <Box w={"100%"}>
                  <label htmlFor="banner-image">
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      id="banner-image"
                      style={{ display: "none" }}
                      onChange={(e: React.ChangeEvent<any>) => {
                        setBannerImage(e.target.files[0]);
                      }}
                    />
                    <UploadFileInputUI
                      filename={
                        bannerImage &&
                        typeof bannerImage !== "string" &&
                        Object.keys(bannerImage)?.length !== 0
                          ? bannerImage?.name
                          : ""
                      }
                    />
                  </label>
                </Box>
                {bannerImgUrl || typeof bannerImage === "string" ? (
                  <img
                    src={
                      typeof bannerImage === "string"
                        ? bannerImage
                        : bannerImgUrl
                    }
                    alt=""
                    style={{
                      aspectRatio: "3/2",
                      objectFit: "contain",
                      height: "200px",
                      width: "50%",
                      margin: "1em 0em .3em 0em",
                    }}
                  />
                ) : null}
              </Box>
            </Flex>

            <Flex
              borderBottom={["0", "1px solid #CBD5E0"]}
              py={[".5em", "1em"]}
              alignItems="center"
              my="1em"
              flexDirection={["column", "row"]}
              borderTopColor={{ md: "gray.300" }}
              gap={3}
              columnGap="1em"
            >
              <Flex width={["100%", "30%"]}>
                <Text
                  fontSize={["14px", "1em"]}
                  color="#32325D"
                  flexGrow={1}
                  width={{ base: "100%", md: "49%" }}
                  textAlign="left"
                  mb={[".3em", "0em"]}
                >
                  Background Image
                  <span style={{ color: "red", marginLeft: 5 }}>*</span>
                </Text>
              </Flex>
              <Modal isOpen={isOpen} onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent bg="blackAlpha.300">
                  <ModalHeader color="white">
                    Crop Image to fit background
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Box w="100%" h="50vh">
                      {bgImgUrl && (
                        <Cropper
                          image={bgImgUrl}
                          crop={crop}
                          rotation={rotation}
                          zoom={zoom}
                          aspect={3 / 1}
                          onCropChange={setCrop}
                          onRotationChange={setRotation}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                        />
                      )}
                    </Box>
                  </ModalBody>

                  <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                      Close
                    </Button>
                    <Button variant="solid" onClick={showCroppedImage}>
                      Crop
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              <Box
                width={["100%", "50%"]}
                style={{
                  backgroundColor: "#fefdff",
                  borderRadius: "10px",
                }}
              >
                <Box width={{ base: "100%" }}>
                  {/* <Button onClick={onOpen}>Open Modal</Button> */}
                  <label htmlFor="background-image">
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      id="background-image"
                      style={{ display: "none" }}
                      onChange={async (e: React.ChangeEvent<any>) => {
                        // setBackgroundImage(e.target.files[0]);
                        const url = await convertFile(e.target.files[0]);
                        setBgImgUrl(url);
                        // console.log("raw file", e.target.files[0]);
                        onOpen();
                      }}
                    />
                    <UploadFileInputUI
                      filename={
                        backgroundImage &&
                        typeof backgroundImage !== "string" &&
                        Object.keys(backgroundImage)?.length !== 0
                          ? backgroundImage?.name
                          : ""
                      }
                    />
                  </label>
                </Box>

                {backgroundImage ? (
                  <img
                    alt=""
                    src={
                      typeof backgroundImage === "string"
                        ? backgroundImage
                        : bgImgUrl
                    }
                    style={{
                      aspectRatio: "3/2",
                      objectFit: "contain",
                      height: "200px",
                      width: "50%",
                      margin: "1em 0em .3em 0em",
                    }}
                  />
                ) : null}
              </Box>
            </Flex>

            <Flex
              borderBottom={["0", "1px solid #CBD5E0"]}
              py={[".5em", "1em"]}
              alignItems="center"
              my="1em"
              flexDirection={["column", "row"]}
              borderTopColor={{ md: "gray.300" }}
              gap={3}
              columnGap="1em"
            >
              <Flex width={["100%", "30%"]}>
                <Text
                  fontSize={["14px", "1em"]}
                  color="#32325D"
                  flexGrow={1}
                  width={{ base: "100%", md: "49%" }}
                  textAlign="left"
                  mb={[".3em", "0em"]}
                >
                  Description
                  <span style={{ color: "red", marginLeft: 5 }}>*</span>
                </Text>
              </Flex>
              <Box
                width={["100%", "50%"]}
                style={{
                  backgroundColor: "#fefdff",
                  borderRadius: "10px",
                }}
              >
                <TextEditor
                  contentState={description}
                  stateHandler={setDescription}
                />
                {isDescEmpty ? (
                  <Text
                    fontSize="14px"
                    color="red.300"
                    fontWeight="medium"
                    className="descError"
                  >
                    This field is required
                  </Text>
                ) : null}
              </Box>
            </Flex>

            <Flex
              alignItems={!showIndustries ? "center" : "flex-start"}
              borderBottom={["0", "1px solid #CBD5E0"]}
              py={[".5em", "1em"]}
              my="1em"
              flexDirection={["column", "row"]}
              borderTopColor={{ md: "gray.300" }}
              gap={3}
              columnGap="1em"
            >
              <Flex width={["100%", "30%"]}>
                <Text
                  fontSize={["14px", "1em"]}
                  color="#32325D"
                  flexGrow={1}
                  width={{ base: "100%", md: "49%" }}
                  textAlign="left"
                  mb={[".3em", "0em"]}
                >
                  Niche
                  <span style={{ color: "red", marginLeft: 5 }}>*</span>
                </Text>
              </Flex>
              <Box width={["100%", "50%"]}>
                <Flex
                  width={"100%"}
                  alignItems="center"
                  justifyContent="space-between"
                  columnGap=".5em"
                  style={{
                    backgroundColor: "#fefdff",
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    border="1px solid"
                    borderColor="gray.300"
                    borderRadius=".3em"
                    // h="50px"
                    pb=".3em"
                    mb="1em"
                    cursor={"pointer"}
                    onClick={toggleHandler}
                    ref={wrapperRef}
                    p=".4em"
                  >
                    <Flex columnGap=".5em" rowGap=".5em" flexWrap="wrap">
                      {selectedItem.length >= 1 ? (
                        selectedItem.map((singleNiche: any, i: any) => {
                          return (
                            <Box
                              key={i}
                              bgColor="#F0F5FF"
                              py={["3px", "3px", "6px"]}
                              px=".4em"
                              alignItems="flex-start"
                              display="flex"
                              borderRadius="4px"
                            >
                              <Text
                                mr=".8em"
                                fontSize="14px"
                                fontWeight="400"
                                color="gray.800"
                              >
                                {singleNiche}
                              </Text>
                              <MdCancel
                                color="#000"
                                cursor="pointer"
                                onClick={() => {
                                  // const newState = nicheData.map(
                                  //   (data: any, x: any) => {
                                  //     if (nicheData[i] == data) {
                                  //       return { ...data, selected: false };
                                  //     } else {
                                  //       return data;
                                  //     }
                                  //   }
                                  // );
                                  // setNicheData(newState);
                                  handleSelect(singleNiche);
                                }}
                              />
                            </Box>
                          );
                        })
                      ) : (
                        <Box
                          // bgColor="#F0F5FF"
                          py={["3px", "3px", "6px"]}
                          px=".4em"
                          alignItems="flex-start"
                          display="flex"
                          borderRadius="4px"
                        >
                          <Text>Select niche</Text>
                        </Box>
                      )}
                    </Flex>

                    <Box>
                      {!showIndustries ? (
                        <IoChevronDown
                          cursor={
                            editPage && lockCampaign ? "not-allowed" : "pointer"
                          }
                          onClick={
                            editPage && lockCampaign ? () => {} : toggleHandler
                          }
                        />
                      ) : (
                        <IoChevronUp
                          cursor={
                            editPage && lockCampaign ? "not-allowed" : "pointer"
                          }
                          onClick={
                            editPage && lockCampaign ? () => {} : toggleHandler
                          }
                        />
                      )}
                    </Box>
                  </Box>
                </Flex>

                {showIndustries && (
                  <Box
                    p={["1em", "1em", "1.5em"]}
                    my="1em"
                    boxShadow="xl"
                    rounded="xl"
                    height="60vh"
                    overflowY="scroll"
                    ref={wrapperRef}
                  >
                    <Flex
                      alignItems="flex-start"
                      justifyContent="space-between"
                      my=".2em"
                    >
                      <Text fontSize="14px" color="gray.400" fontWeight="400">
                        Search Niche
                      </Text>

                      <BsFillCaretUpFill
                        color="#CACBD4"
                        cursor="pointer"
                        onClick={toggleHandler}
                      />
                    </Flex>

                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<BsSearch color="gray.300" />}
                      />
                      <Input
                        type="text"
                        _focus={{ ring: "0" }}
                        border="0"
                        bgColor="#F4F6F8"
                        value={searchQuery}
                        onChange={(e: React.ChangeEvent<any>) =>
                          setSearchQuery(e.target.value)
                        }
                      />
                    </InputGroup>

                    {nicheData
                      .filter((singleNiche: any) =>
                        singleNiche.niche
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      )
                      .map((singleNiche: any, i: any) => {
                        return (
                          <Box
                            key={i}
                            display={"flex"}
                            justifyContent="space-between"
                            alignItems="center"
                            my="1em"
                            cursor="pointer"
                            _hover={{ bgColor: "gray.50" }}
                            onClick={() => {
                              handleSelect(singleNiche.niche);
                            }}
                            p=".5em"
                            borderRadius="3px"
                          >
                            <Text>{singleNiche.niche}</Text>

                            {selectedItem.some(
                              (selectedItemData: any) =>
                                selectedItemData === singleNiche.niche
                            ) ? (
                              <BsCheckSquareFill color="#5C15CE" />
                            ) : (
                              <Box
                                width="1em"
                                height="1em"
                                border="1.5px solid gray"
                                borderRadius="4px"
                              ></Box>
                            )}
                          </Box>
                        );
                      })}
                    {nicheData.length > 0 &&
                      nicheData.filter(
                        (countryData: any) =>
                          !countryData.niche
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                      ).length === nicheData.length && (
                        <Text fontSize="14px" color="gray.500" mt="1em">
                          No data found
                        </Text>
                      )}
                  </Box>
                )}

                {isNicheEmpty ? (
                  <Text
                    fontSize="14px"
                    color="red.300"
                    mt={showIndustries ? "0em" : "-0.6em"}
                    fontWeight="medium"
                    className="nicheError"
                  >
                    This field is required
                  </Text>
                ) : null}
              </Box>
            </Flex>

            <Flex
              alignItems={!showCountryFilter ? "center" : "flex-start"}
              // justifyContent="space-between"
              columnGap="1em"
              my="1em"
              flexDirection={["column", "row"]}
              borderBottom={["0", "1px solid #CBD5E0"]}
              pb={showCountryFilter ? "35vh" : "4em"}
              py={[".5em", "1em"]}
              borderTopColor={{ md: "gray.300" }}
              gap={3}
            >
              <Flex width={["100%", "30%"]}>
                <Text
                  fontSize={["14px", "1em"]}
                  color="#32325D"
                  flexGrow={1}
                  width={{ base: "100%", md: "49%" }}
                  textAlign="left"
                  mb={[".3em", "0em"]}
                >
                  What countries can you ship to?
                  <span style={{ color: "red", marginLeft: 5 }}>*</span>
                </Text>
              </Flex>
              <Box width={["100%", "50%"]} ref={countryWrapperRef}>
                <Flex
                  width={"100%"}
                  direction={"column"}
                  style={{
                    backgroundColor: "#fefdff",
                    borderRadius: "10px",
                  }}
                  display="flex"
                  // border="1px solid"
                  borderColor="gray.300"
                  borderRadius=".3em"
                  h="100%"
                  pb=".3em"
                  mb="1em"
                  cursor={"pointer"}
                >
                  <Flex
                    borderBottom="1px solid #B6BDC3"
                    p=".3em"
                    justifyContent="space-between"
                    alignItems="center"
                    cursor="pointer"
                    onClick={() => {
                      if (!showCountryFilter) {
                        setShowCountryFilter(true);
                      }
                    }}
                    border="1px solid"
                    borderColor="gray.300"
                    borderRadius=".3em"
                    w={"100%"}
                  >
                    <Flex rowGap=".5em" columnGap=".5em" flexWrap="wrap">
                      {selectedItemC.length >= 1 ? (
                        selectedItemC.map((data: any, i: any) => {
                          return (
                            <Box
                              key={i}
                              bgColor="#F0F5FF"
                              py={["3px", "3px", "6px"]}
                              px=".4em"
                              alignItems="flex-start"
                              display="flex"
                              borderRadius="4px"
                            >
                              {/* <Box>{data.country.flag}</Box> */}
                              <Text
                                mr=".8em"
                                ml=".5em"
                                fontSize="14px"
                                fontWeight="400"
                                color="gray.800"
                              >
                                {data}
                              </Text>

                              <MdCancel
                                color="#000"
                                cursor="pointer"
                                onClick={() => {
                                  handleSelectC(data);
                                }}
                              />
                            </Box>
                          );
                        })
                      ) : (
                        <Box
                          // bgColor="#F0F5FF"
                          py={["3px", "3px", "6px"]}
                          px=".4em"
                          alignItems="flex-start"
                          display="flex"
                          borderRadius="4px"
                        >
                          <Text cursor="pointer" onClick={countryToggleHandler}>
                            Select countries
                          </Text>
                        </Box>
                      )}
                    </Flex>
                    <Box>
                      {!showCountryFilter ? (
                        <IoChevronDown
                          cursor="pointer"
                          onClick={countryToggleHandler}
                        />
                      ) : (
                        <IoChevronUp
                          cursor="pointer"
                          onClick={countryToggleHandler}
                        />
                      )}
                    </Box>
                  </Flex>

                  {showCountryFilter && (
                    <Box
                      p={["1em", "1em", "1.5em"]}
                      my="1em"
                      boxShadow="xl"
                      rounded="xl"
                      height="60vh"
                      overflowY="scroll"
                      ref={wrapperRef}
                    >
                      <Flex
                        alignItems="flex-start"
                        justifyContent="space-between"
                        my=".5em"
                      >
                        <Text fontSize="14px" color="gray.400" fontWeight="400">
                          Search Country
                        </Text>

                        <BsFillCaretUpFill
                          color="#CACBD4"
                          cursor="pointer"
                          onClick={countryToggleHandler}
                        />
                      </Flex>

                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<BsSearch color="gray.300" />}
                        />
                        <Input
                          type="text"
                          _focus={{ ring: "0" }}
                          border="0"
                          bgColor="#F4F6F8"
                          value={countrySearchQuery}
                          onChange={(e: React.ChangeEvent<any>) =>
                            setCountrySearchQuery(e.target.value)
                          }
                        />
                      </InputGroup>

                      {allCountries
                        .filter((countryData: any) =>
                          countryData.country.name
                            .toLowerCase()
                            .includes(countrySearchQuery.toLowerCase())
                        )
                        .map((countryData: any, i: any) => (
                          <Box
                            key={i}
                            display={"flex"}
                            justifyContent="space-between"
                            alignItems="center"
                            cursor="pointer"
                            _hover={{ bgColor: "gray.50" }}
                            p=".5em"
                            my="2px"
                            borderRadius="3px"
                            onClick={() => {
                              handleSelectC(countryData.country.name);
                            }}
                          >
                            <Flex alignItems="flex-start" mt="1em">
                              <div> {countryData.country.flag}</div>
                              <Text ml="1em">{countryData.country.name}</Text>
                            </Flex>

                            {selectedItemC.some(
                              (selectedItemData: any) =>
                                selectedItemData === countryData.country.name
                            ) ? (
                              <BsCheckSquareFill color="#5C15CE" />
                            ) : (
                              <Box
                                width="1em"
                                height="1em"
                                border="1.5px solid gray"
                                borderRadius="4px"
                              ></Box>
                            )}
                          </Box>
                        ))}

                      {allCountries.length > 0 &&
                        allCountries.filter(
                          (countryData: any) =>
                            !countryData.country.name
                              .toLowerCase()
                              .includes(countrySearchQuery.toLowerCase())
                        ).length === allCountries.length && (
                          <Text fontSize="14px" color="gray.500" mt="1em">
                            No data found
                          </Text>
                        )}
                    </Box>
                  )}

                  {isEmptyShipTo ? (
                    <Text
                      fontSize="14px"
                      color="red.300"
                      fontWeight="medium"
                      className="shipError"
                    >
                      This field is required
                    </Text>
                  ) : null}
                </Flex>
              </Box>
            </Flex>

            <Flex
              alignItems="center"
              justifyContent="space-between"
              mb="1em"
              flexDirection={["column-reverse", "row"]}
              rowGap=".6em"
              w={["100%", "81%"]}
              py={10}
            >
              <Box width={["100%", "20%"]}>
                <Button
                  py="1.5em"
                  w="100%"
                  px={["3em", "2em"]}
                  fontWeight="500"
                  fontSize="14px"
                  color="#5C14CE"
                  bgColor="#F0F5FF"
                  onClick={() => {
                    imageupload();
                  }}
                  isLoading={saveAndExit}
                  // type="submit"
                >
                  Save and Exit
                </Button>
              </Box>
              <Box width={["100%", "20%"]}>
                <BaseButton
                  text="Continue"
                  textColor="#fff"
                  bgColor="#5C14CE"
                  rightIcon={<IoArrowForwardSharp />}
                  type="submit"
                  loadingState={loader}
                />
              </Box>
            </Flex>
            {/* </Container> */}
          </form>
        </Stack>
      )}
    </Box>
  );
};
