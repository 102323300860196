import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Circle,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Stack,
  StackDivider,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdArrowDropDown, MdFilterList, MdOutlineAddBox } from "react-icons/md";
import { RiSearchLine } from "react-icons/ri";
import useUserToken from "../../../../../hooks/useUserToken";
import Fuse from "fuse.js";
import AffiliatesTable from "./AffiliatesTable";
import NewAffForm from "./components/NewAffForm";
import TopAffiliate from "../overview/top-affiliate";
import AffiliateDetails from "./AffiliateDetails";
import { HiOutlineExclamationCircle } from "react-icons/hi";

const CampaignAffiliates = ({
  id,
  campaignName,
  externalAffInfo,
  setExternalInfo,
}: {
  id: string | undefined;
  campaignName: string;
  externalAffInfo: any;
  setExternalInfo: React.Dispatch<any>;
}) => {
  const [filterData, setfilterData] = useState({
    date: "",
    status: "",
  });

  const [highlighted, sethighlighted] = useState<any[]>([]);
  const [affiliates, setAffiliates] = useState<any[]>([]);
  const [filteredAffiliates, setfilteredAffiliates] = useState<any[]>([]);
  const [top5Affiliates, settop5Affiliates] = useState<any[]>([]);
  const [isLoading, setisLoading] = useState(true);
  const [newAffFormIsOpen, setnewAffFormIsOpen] = useState(false);
  const [currentSelectedAffilite, setcurrentSelectedAffilite] =
    useState<any>(null);
  const [updateAffTable, setupdateAffTable] = useState<boolean>(false);
  const [isUpdatingStatus, setisUpdatingStatus] = useState<string>("");

  const [searchVal, setsearchVal] = useState<string>("");
  const [filterIsOpen, setfilterIsOpen] = useState(false);

  const token = useUserToken();
  const toast = useToast();

  const options = {
    keys: ["aff_org.companyname"],
  };

  const fuse = new Fuse(affiliates, options);

  const getCampaignAffiliates = (
    endpoint: string,
    setPlaceholder: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    if (!id) return;

    setisLoading(true);
    let bearerAuth = token;

    fetch(process.env.REACT_APP_API_ENDPOINT + endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setPlaceholder(data.data.affiliates);
          setisLoading(false);
        }
      })
      .catch((err) => {
        setisLoading(false);
        console.log("get sales => " + err);
      });
  };

  const getTop5Affiliates = () => {
    if (!id) return;

    let bearerAuth = token;

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `explore/offers/top5-affiliates/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          settop5Affiliates(data.data.top5Affiliates);
          setisLoading(false);
        }
      })
      .catch((err) => {
        console.log("get top 5 affiliates => " + err);
      });
  };

  const checkStatusType = (status: string) => {
    if (status === "approved") return "approve";
    if (status === "declined") return "decline";
    if (status === "suspended") return "suspend";
    return status;
  };

  const changeAffiliateStatus = (status: string) => {
    let temp = [...highlighted];
    let promotionIds: any[] = [];

    let error = "";

    temp.forEach((aff: any) => {
      if (status === "suspended" && aff.status !== "approved") {
        error = "Only approved affiliates can be suspended";
      }
      promotionIds.push(aff.id);
    });

    if (error)
      return toast({
        title: error,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

    if (!promotionIds.length)
      return toast({
        title: `Please select the affiliate(s) you want to ${checkStatusType(
          status
        )}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

    setisUpdatingStatus(status);
    let bearerAuth = token;

    const payload = {
      status,
      promotionIds,
    };

    fetch(
      process.env.REACT_APP_API_ENDPOINT + `promote-campaign/multiple-approve`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setisUpdatingStatus("");
          setupdateAffTable(!updateAffTable);
          sethighlighted([]);
          return toast({
            title: `Affiliate(s) have been ${status} successfully`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          setisUpdatingStatus("");
          return toast({
            title: `Failed to ${checkStatusType(status)} affiliate(s)`,
            description: data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      });
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ifFilterExists = () => {
    if (isLoading) return false;
    if (searchVal || filterData.date || filterData.status) return true;
    return false;
  };

  useEffect(() => {
    if (searchVal) {
      setsearchVal("");
    }
    if (filterData.date || filterData.status) {
      setfilterData({
        date: "",
        status: "",
      });
    }

    if (!currentSelectedAffilite) {
      getCampaignAffiliates(
        `/promote-campaign/get-affiliates/${id}`,
        setAffiliates
      );
      getTop5Affiliates();
    }
  }, [updateAffTable, currentSelectedAffilite]);

  useEffect(() => {
    if (filterData.date && !filterData.status) {
      setsearchVal("");
      getCampaignAffiliates(
        `/promote-campaign/get-affiliates/${id}?date=${filterData.date}`,
        setfilteredAffiliates
      );
    } else if (!filterData.date && filterData.status) {
      setsearchVal("");
      getCampaignAffiliates(
        `/promote-campaign/get-affiliates/${id}?status=${filterData.status}`,
        setfilteredAffiliates
      );
    } else if (filterData.date && filterData.status) {
      setsearchVal("");
      getCampaignAffiliates(
        `/promote-campaign/get-affiliates/${id}?date=${filterData.date}&status=${filterData.status}`,
        setfilteredAffiliates
      );
    } else {
      // do nothing
    }
  }, [filterData]);

  useEffect(() => {
    setfilteredAffiliates(
      fuse.search(searchVal).map((sale: any) => ({ ...sale.item }))
    );

    if (filterData.date || filterData.status) {
      setfilterData({ date: "", status: "" });
    }
  }, [searchVal]);

  const noResult = () => {
    if (!isLoading && !affiliates.length)
      return {
        result: true,
        reason: "You currently do not have any affiliates",
      };
    if (!isLoading && !filteredAffiliates.length && searchVal)
      return { result: true, reason: "No Results Found" };

    if (!isLoading && !filteredAffiliates.length && filterData.date)
      return { result: true, reason: "No Results Found" };

    if (!isLoading && !filteredAffiliates.length && filterData.status)
      return { result: true, reason: "No Results Found" };

    return { result: false, reason: "" };
  };

  if (newAffFormIsOpen)
    return (
      <NewAffForm
        campaignName={campaignName}
        onClose={setnewAffFormIsOpen}
        id={id}
      />
    );

  if (currentSelectedAffilite || externalAffInfo)
    return (
      <AffiliateDetails
        aff_info={externalAffInfo ? externalAffInfo : currentSelectedAffilite}
        campaignId={id}
        setcurrentSelectedAffiliate={setcurrentSelectedAffilite}
        setExternalAffInfo={setExternalInfo}
      />
    );

  const [dateFilterToggle, setDateFilterToggle] = useState(false);
  const [statusFilterToggle, setStatusFilterToggle] = useState(false);

  return (
    <Stack pb="100px">
      <Box mt="28px">
        {top5Affiliates.length ? (
          <TopAffiliate top5Affiliates={top5Affiliates} />
        ) : (
          ""
        )}
      </Box>

      <Box
        display="flex"
        alignItems={{ md: "center" }}
        justifyContent="space-between"
        mt="8px"
      >
        <Flex
          flexDir={{ base: "column-reverse", md: "row" }}
          alignItems={{ md: "center" }}
          w={{ base: "100%", md: "auto" }}
        >
          <Box
            display="flex"
            alignItems="center"
            mr={{ base: "0px", md: "14px" }}
            mt={{ base: "14px", md: "0px" }}
            justifyContent="space-between"
          >
            <Button
              borderRadius="8px"
              variant="outline"
              w="max-content"
              color="rgba(50, 50, 93, 1)"
              onClick={() => setfilterIsOpen(!filterIsOpen)}
            >
              <MdFilterList fontSize={15} />
              <Text
                fontSize={{ base: "12px", md: "14px" }}
                color="rgba(50, 50, 93, 0.8)"
                fontWeight="500"
                lineHeight={{ base: "18px", md: "21px" }}
                ml="7px"
              >
                Filter
              </Text>
            </Button>

            <Button
              bgColor="#7211D4"
              color="white"
              borderRadius="8px"
              ml="14px"
              display={{ base: "inline-flex", md: "none" }}
              onClick={() => setnewAffFormIsOpen(true)}
            >
              <MdOutlineAddBox fontSize={15} />
              <Text
                fontSize={{ base: "12px", md: "14px" }}
                fontWeight="500"
                lineHeight={{ base: "18px", md: "21px" }}
                ml="7px"
              >
                Add New Affiliate
              </Text>
            </Button>
          </Box>

          <HStack spacing="14px">
            {highlighted[0]?.status !== "approved" && (
              <Tooltip
                label={
                  highlighted.length === 0
                    ? "select the affiliates you want to approve with the tickbox to continue"
                    : ""
                }
                fontSize="md"
              >
                <Button
                  fontSize={{ base: "12px", md: "14px" }}
                  fontWeight="500"
                  lineHeight={{ base: "18px", md: "21px" }}
                  variant="outline"
                  color="rgba(50, 50, 93, 0.8)"
                  padding={{ base: "8px 14px", md: "8px 30px" }}
                  border="0.5px solid rgba(50, 50, 93, 0.4)"
                  w={{ base: "33%", md: "auto" }}
                  isLoading={isUpdatingStatus === "approved"}
                  isDisabled={
                    isUpdatingStatus !== "" || highlighted.length !== 1
                  }
                  onClick={() => changeAffiliateStatus("approved")}
                >
                  Approve
                </Button>
              </Tooltip>
            )}

            <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
              <ModalOverlay />
              <ModalContent
                // mx={{ base: ".6em", md: "auto" }}
                marginTop={{ base: "15em", md: "18em" }}
                borderRadius={10}
                py={5}
              >
                <ModalCloseButton />
                <ModalBody>
                  <Flex py="1.5em" direction={"column"} gap={5}>
                    <Circle size="60px" bg="#fef3f2">
                      <Circle size="40px" bg="#fee4e2" color="#d92d21">
                        <HiOutlineExclamationCircle size={25} />
                      </Circle>
                    </Circle>
                    <Box>
                      <Text fontWeight={600} fontSize={"1.5em"}>
                        Decline Affiliate
                      </Text>
                      <Text>
                        Are you sure you want to decline this affiliate
                      </Text>
                    </Box>
                  </Flex>
                </ModalBody>

                <ModalFooter justifyContent="flex-end">
                  <Button borderRadius="8px" bgColor="#7211D4" color="white">
                    {/* <IoPersonOutline fontSize={14} /> */}
                    <Text
                      fontWeight="500"
                      fontSize={{ base: "12px", md: "14px" }}
                      lineHeight={{ base: "18px", md: "21px" }}
                      ml="7px"
                      onClick={onClose}
                    >
                      No, Cancel
                    </Text>
                  </Button>

                  <Button
                    bgColor="#F0F3F9"
                    borderRadius="8px"
                    fontWeight="500"
                    fontSize={{ base: "12px", md: "14px" }}
                    lineHeight={{ base: "18px", md: "21px" }}
                    color="#7211D4"
                    w="max-content"
                    ml="13px"
                    onClick={() => {
                      onClose();
                      changeAffiliateStatus("declined");
                    }}
                  >
                    {/* <Image mr="7px" src="/assets/png/invoice-icon.png" /> */}
                    Yes, Decline
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {highlighted[0]?.status !== "declined" && (
              <Tooltip
                label={
                  highlighted.length === 0
                    ? "select the affiliates you want to decline with the tickbox to continue"
                    : ""
                }
                fontSize="md"
              >
                <Button
                  variant="outline"
                  fontSize={{ base: "12px", md: "14px" }}
                  fontWeight="500"
                  lineHeight={{ base: "18px", md: "21px" }}
                  color="rgba(50, 50, 93, 0.8)"
                  padding={{ base: "8px 14px", md: "8px 30px" }}
                  border="0.5px solid rgba(50, 50, 93, 0.4)"
                  isLoading={isUpdatingStatus === "declined"}
                  w={{ base: "33%", md: "auto" }}
                  isDisabled={
                    isUpdatingStatus !== "" || highlighted.length !== 1
                  }
                  onClick={() => onOpen()}
                >
                  Decline
                </Button>
              </Tooltip>
            )}
            {highlighted[0]?.status !== "declined" &&
              highlighted[0]?.status !== "suspended" && (
                <Tooltip
                  label={
                    highlighted.length === 0
                      ? "select the affiliates you want to suspend with the tickbox to continue"
                      : ""
                  }
                  fontSize="md"
                >
                  <Button
                    variant="outline"
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                    lineHeight={{ base: "18px", md: "21px" }}
                    padding={{ base: "8px 14px", md: "8px 30px" }}
                    color="rgba(50, 50, 93, 0.8)"
                    border="0.5px solid rgba(50, 50, 93, 0.4)"
                    w={{ base: "33%", md: "auto" }}
                    isLoading={isUpdatingStatus === "suspended"}
                    isDisabled={
                      isUpdatingStatus !== "" || highlighted.length !== 1
                    }
                    onClick={() => changeAffiliateStatus("suspended")}
                  >
                    Suspend
                  </Button>
                </Tooltip>
              )}
          </HStack>
        </Flex>

        <Button
          bgColor="#7211D4"
          color="white"
          borderRadius="8px"
          display={{ base: "none", md: "inline-flex" }}
          onClick={() => setnewAffFormIsOpen(true)}
        >
          <MdOutlineAddBox fontSize={15} />
          <Text
            fontSize={{ base: "12px", md: "14px" }}
            fontWeight="500"
            lineHeight={{ base: "18px", md: "21px" }}
            ml="7px"
          >
            Add New Affiliate
          </Text>
        </Button>
      </Box>

      {filterIsOpen && (
        <Box
          display={{ base: "flex", md: "flex" }}
          flexDirection={{ base: "column", md: "row" }}
          alignItems={"start"}
          p="13px 7px"
          bgColor="#F2EFF6"
          mt="8px"
        >
          <InputGroup
            w={{ base: "100%", md: "300px" }}
            mb={{ base: "16px", md: "0px" }}
          >
            <InputLeftElement
              pointerEvents="none"
              children={<RiSearchLine color="rgba(50, 50, 93, 0.8)" />}
            />
            <Input
              css={{
                border: "0.5px solid rgba(50, 50, 93, 0.4)",
                "::-webkit-input-placeholder": { fontSize: "14px" },
              }}
              type="text"
              value={searchVal}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setsearchVal(e.target.value)
              }
              fontSize="15px"
              mr={{ md: "20px" }}
              bg="white"
              focusBorderColor="#7211D4"
              placeholder="Search for affiliate organizations"
            />
          </InputGroup>

          <Flex
            gap="14px"
            flexDirection={["column", "row"]}
            // mt={"8px"}
            // w={["100%", ""]}
          >
            <Flex flexDir={"column"} w={"100%"} position={"relative"}>
              <Flex
                justify={"space-between"}
                align={"center"}
                bgColor={"white"}
                p={3}
                w={["100%", "150px"]}
                cursor={"pointer"}
                h={"80%"}
                onClick={() => {
                  setDateFilterToggle((prev) => !prev);
                }}
              >
                <Text>{filterData.date == "" ? "Date" : filterData.date}</Text>
                <MdArrowDropDown fontSize={"24px"} />
              </Flex>

              {dateFilterToggle && (
                <Box
                  position={"absolute"}
                  top={"80%"}
                  w={["100%", "150px"]}
                  zIndex={1}
                >
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, date: "last_day" });
                      setDateFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Last Day</Text>
                  </Flex>
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, date: "last_30_days" });
                      setDateFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Last 30 Days</Text>
                  </Flex>
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, date: "last_quater" });
                      setDateFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Last Quarter</Text>
                  </Flex>
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, date: "last_year" });
                      setDateFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Last Year</Text>
                  </Flex>
                </Box>
              )}
            </Flex>
            <Flex flexDir={"column"} w={"100%"} position={"relative"}>
              <Flex
                justify={"space-between"}
                align={"center"}
                bgColor={"white"}
                p={3}
                w={["100%", "150px"]}
                cursor={"pointer"}
                h={"80%"}
                onClick={() => {
                  setStatusFilterToggle((prev) => !prev);
                }}
              >
                <Text>
                  {filterData.status == "" ? "Status" : filterData.status}
                </Text>
                <MdArrowDropDown fontSize={"24px"} />
              </Flex>

              {statusFilterToggle && (
                <Box
                  position={"absolute"}
                  top={"80%"}
                  w={["100%", "150px"]}
                  zIndex={1}
                >
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, status: "paid" });
                      setStatusFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Paid</Text>
                  </Flex>
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, status: "pending" });
                      setStatusFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Pending</Text>
                  </Flex>
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, status: "declined" });
                      setStatusFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Declined</Text>
                  </Flex>
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, status: "overdue" });
                      setStatusFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Overdue</Text>
                  </Flex>
                </Box>
              )}
            </Flex>
            {/* <Select
              w={{ base: "100%", md: "120px" }}
              bg="white"
              placeholder="Date"
              cursor={"pointer"}
              value={filterData.date}
              onChange={(e) =>
                setfilterData({ ...filterData, date: e.target.value })
              }
              icon={<MdArrowDropDown />}
            >
              <option value="last_day">Last Day</option>
              <option value="last_30_days">Last 30 Days</option>
              <option value="last_quater">Last Quarter</option>
              <option value="last_year">Last Year</option>
            </Select> */}

            {/* <Select
              w={{ base: "100%", md: "120px" }}
              bg="white"
              placeholder="Status"
              cursor={"pointer"}
              value={filterData.status}
              onChange={(e) =>
                setfilterData({ ...filterData, status: e.target.value })
              }
              icon={<MdArrowDropDown />}
            >
              <option value="paid">Paid</option>
              <option value="pending">Pending</option>
              <option value="declined">Declined</option>
              <option value="overdue">Overdue</option>
            </Select> */}
          </Flex>
        </Box>
      )}

      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          h="40vh"
          alignItems="center"
          pt="20px"
        >
          <Image className="loading" src="/assets/logo.png" height="60px" />
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="70px"
            color="#000000"
            mt="-15px"
          >
            Loading Affiliates Table...
          </Text>
        </Box>
      ) : (
        <AffiliatesTable
          affiliates={ifFilterExists() ? filteredAffiliates : affiliates}
          highlighted={highlighted}
          sethighlighted={sethighlighted}
          setcurrentSelectedAffiliate={setcurrentSelectedAffilite}
        />
      )}

      {noResult().result && (
        <Box
          w="100%"
          height="30vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={{ base: "0px 16px", md: "0px" }}
          mb="21px"
        >
          <Image
            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
            height="180px"
            objectFit="contain"
            mb="16px"
          />

          <Text
            fontWeight="600"
            fontSize="12px"
            lineHeight="22px"
            color="#32325D"
            textAlign="center"
          >
            {noResult().reason}
          </Text>
        </Box>
      )}
    </Stack>
  );
};

export default CampaignAffiliates;
