// @ts-ignore
import { getList } from "country-list-with-dial-code-and-flag";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import React, { ChangeEvent, useEffect, useState } from "react";
import { BaseButton } from "../button";
import { StepWrapper } from "./StepWrapper";
import { IoArrowForwardSharp, IoArrowBackSharp } from "react-icons/io5";
import { FormInput } from "../formInput";
import { getUrlQueryString } from "../../services/urlQuery";
import { useCountries } from "../../hooks/useCountries";
import { toast, ToastContainer } from "react-toastify";
import {
  AiFillInstagram,
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillLinkedin,
} from "react-icons/ai";

import { MdOutlineSocialDistance, MdCancel } from "react-icons/md";
import {
  getCurrentUserInstance,
  setCurrentUserType,
  setLocalUserInstance,
} from "../../state/localstorage";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { RiArrowRightFill } from "react-icons/ri";
export const CompanyProfile = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [cookies] = useCookies(["user_id", "access_token"]);
  const [, setCookie] = useCookies(["user", "user_id", "access_token"]);
  const [orgType, setOrgType] = useState(false);
  const [userId, setUserId] = useState("");
  // @ts-ignore
  let localUser;
  if (searchParams.get("userId") !== null) {
  } else {
    localUser = getCurrentUserInstance();
  }

  useEffect(() => {
    if (searchParams.get("userId") !== null) {
      setOrgType(searchParams.get("userId") ? true : false);
    }
    if (searchParams.get("userId") !== null) {
      setUserId(searchParams.get("userId")!);
    }
  }, [searchParams, orgType, userId]);
  localUser && console.log(localUser);

  console.log(getList());
  const [allCountries] = useCountries();
  console.log(allCountries[160]);
  const [companyName, setCompanyName] = useState(
    localUser && localUser?.organisation?.companyname
      ? localUser?.organisation?.companyname
      : ""
  );
  const [country, setCountry] = useState(
    localUser && localUser?.organisation?.country
      ? localUser?.organisation?.country
      : getUrlQueryString("default-country")
      ? getUrlQueryString("default-country")
      : "NG"
  );
  // console.log(country);
  const [address, setAddress] = useState(
    localUser && localUser?.organisation?.address
      ? localUser?.organisation?.address
      : ""
  );
  const [localState, setLocalState] = useState(
    localUser && localUser?.organisation?.state
      ? localUser?.organisation?.state
      : ""
  );
  const [city, setCity] = useState(
    localUser && localUser?.organisation?.city
      ? localUser?.organisation?.city
      : ""
  );
  const [zipCode, setZipCode] = useState(
    localUser && localUser?.organisation?.zip_code
      ? localUser?.organisation?.zip_code
      : ""
  );
  const [webURL, setWebURL] = useState(
    localUser && localUser?.organisation?.website
      ? localUser?.organisation?.website
      : ""
  );
  const [socialData, setSocialData] = useState({ title: "", url: "" });
  const [socials, setSocials]: [
    socials: { title: string; url: string }[],
    setSocials: React.Dispatch<React.SetStateAction<any>>
  ] = useState(
    localUser && localUser.socials
      ? localUser.socials
      : [{ title: "", url: "" }]
  );

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const addSocialHandler = () => {
    if (socialData.title === "" || socialData.url === "") {
      return;
    }

    setSocials((currentSocials: { title: string; url: string }[]) => [
      ...currentSocials.filter((socialData) => socialData.title !== ""),
      socialData,
    ]);

    setSocialData({ title: "", url: "" });
  };

  const urlPattern = new RegExp(
    // "^(https?:\\/\\/)?" + // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$", // validate fragment locator
    "i"
  );
  const [saveAndExit, setSaveAndExit] = useState(false);
  const [invalidURL, setInValidURL] = useState(false);
  const submitHandler = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    // if (!urlPattern.test(webURL)) {
    //   setInValidURL(true);
    //   return;
    // }

    if (urlPattern.test(webURL) && invalidURL) {
      setInValidURL(false);
    }
    setLoading(true);
    const payload = {
      companyname: companyName,
      country,
      address,
      state: (
        allCountries && allCountries[0].country !== "" && localState === ""
          ? allCountries.find(
              (singleCountry: { country: string; state: string[] }) =>
                singleCountry.country === country
            ).states[0]
          : localState
      )
        ? allCountries && allCountries[0].country !== "" && localState === ""
          ? allCountries.find(
              (singleCountry: { country: string; state: string[] }) =>
                singleCountry.country === country
            ).states[0]
          : localState
        : city,
      city,
      zip_code: zipCode,
      website: webURL,
      socialHandles:
        socials.length >= 1
          ? socials.reduce(
              (acc, cur) => ({ ...acc, [cur.title.toLowerCase()]: cur.url }),
              {}
            )
          : {},
    };
    if (orgType) {
      const orgPayload = {
        companyname: companyName,
        country,
        address,
        state: (
          allCountries && allCountries[0].country !== "" && localState === ""
            ? allCountries.find(
                (singleCountry: { country: string; state: string[] }) =>
                  singleCountry.country === country
              ).states[0]
            : localState
        )
          ? allCountries && allCountries[0].country !== "" && localState === ""
            ? allCountries.find(
                (singleCountry: { country: string; state: string[] }) =>
                  singleCountry.country === country
              ).states[0]
            : localState
          : city,
        city,
        zip_code: zipCode,
        website: webURL,
        userType: "advertiser",
        socialHandles:
          socials.length >= 1
            ? socials.reduce(
                (acc, cur) => ({
                  ...acc,
                  [cur.title.toLowerCase()]: cur.url,
                }),
                {}
              )
            : {},
      };
      fetch(
        process.env.REACT_APP_API_ENDPOINT + `organisation/create/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.access_token}`,
          },
          body: JSON.stringify(orgPayload),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setLocalUserInstance({ ...data.data.user, socials });
            setCurrentUserType("advertiser");

            window.localStorage.setItem("organization", "yes");
            let expires = new Date();
            expires.setTime(expires.getTime() + 720 * 60 * 60 * 1000);
            console.log(data.data);
            window.localStorage.setItem("organization", "no");
            let userData = data.data.user;
            userData.onboarded = true;
            setCookie("user", userData, {
              path: "/",
              expires,
            });
            setCookie("user_id", data.data.user.user_id, {
              path: "/",
              expires,
            });

            setCookie("access_token", data.data.token, {
              path: "/",
              expires,
            });
            saveAndExit
              ? window.open("https://www.metricks.io", "_self")
              : navigate("/signup/marketing-assets?type=newOrganization");
          }
          console.log(data);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      fetch(process.env.REACT_APP_API_ENDPOINT + "auth/signup/step2", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.access_token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setCurrentUserType("advertiser");
            setLocalUserInstance(
              // @ts-ignore
              Object.keys(localUser)
                ? // @ts-ignore
                  { ...localUser, ...data.data.user, socials }
                : { ...data.data.user, socials }
            );

            saveAndExit
              ? window.open("https://www.metricks.io", "_self")
              : navigate("/signup/marketing-assets");
          }
          console.log("step2", data);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }

    // window.open("https://metricks.io", "_self");
    // navigate("/signup/marketing-assets?type=newOrganization");
  };
  const step = [
    {
      title: "User Profile",
      status: "passed",
      key: 1,
    },
    {
      title: "Company Profile",
      status: "current",
      key: 2,
    },

    {
      title: "Marketing Assets",
      status: "default",
      key: 3,
    },
    {
      title: "Product Details",
      status: "default",
      key: 4,
    },
    {
      title: "Niche",
      status: "default",
      key: 5,
    },
    {
      title: "Billing",
      status: "default",
      key: 6,
    },
  ];
  const orgSteps = [
    {
      title: "Company Profile",
      status: "current",
      key: 1,
    },

    {
      title: "Marketing Assets",
      status: "default",
      key: 2,
    },
    {
      title: "Product Details",
      status: "default",
      key: 3,
    },
    {
      title: "Niche",
      status: "default",
      key: 4,
    },
    {
      title: "Billing",
      status: "default",
      key: 5,
    },
  ];
  const steps = orgType ? orgSteps : step;

  return (
    <StepWrapper
      step={steps.map((item: any) => {
        if (item.status === "current") {
          let step = `STEP ${item.key} OF ${steps.length}`;
          return step;
        }
      })}
      header="Setting up your advertiser account"
      baseText="Company profile"
      stepsMap={steps}
    >
      <Flex
        position={"relative"}
        direction={"column"}
        alignItems={"center"}
        w={["90%", "60%"]}
        // backgroundColor={"red"}
      >
        <Flex w={"100%"}>
          <form onSubmit={submitHandler} style={{ width: "100%" }}>
            <FormInput
              label="Company's Name"
              type="text"
              holder="James O' Conell "
              value={companyName}
              stateHandler={setCompanyName}
              required={true}
            />

            <FormControl isRequired={true}>
              <FormLabel
                htmlFor="country"
                fontSize="15px"
                fontWeight="400"
                color="#737373"
                _focus={{ color: "#5C14CE" }}
              >
                Country
              </FormLabel>

              <Flex
                alignItems="center"
                justifyContent="space-between"
                borderBottom="1.5px solid #B6BDC3"
                my="1.6em"
              >
                <Box>
                  {
                    getList().filter((info: any) => info.name === country)[0]
                      .flag
                  }
                </Box>
                <Select
                  border="0"
                  flexGrow={1}
                  // @ts-ignore
                  value={country}
                  onChange={(e: React.ChangeEvent<any>) =>
                    setCountry(e.target.value)
                  }
                  _focus={{ ring: "0" }}
                >
                  {getList()
                    .map((data: any) => data.name)
                    .map((name: string, i: any) => {
                      return (
                        <option key={i} value={name}>
                          {name}
                        </option>
                      );
                    })}
                </Select>
              </Flex>
            </FormControl>
            <FormInput
              label="Address"
              type="text"
              holder="14, The Blue Posts, Cellar, 28 Rupert St."
              value={address}
              stateHandler={setAddress}
              required={true}
            />

            {allCountries && allCountries[0].country !== "" && (
              // localState !== "" &&
              <FormInput
                label="State"
                type="select"
                value={
                  allCountries &&
                  allCountries[0].country !== "" &&
                  localState === ""
                    ? allCountries.find(
                        (singleCountry: { country: string; state: string[] }) =>
                          singleCountry.country === country
                      ).states[0]
                    : localState
                }
                stateHandler={setLocalState}
                required={false}
                holder="Select your state"
                options={
                  allCountries && allCountries[0].country !== ""
                    ? allCountries.find(
                        (singleCountry: { country: string; state: string[] }) =>
                          singleCountry.country === country
                      ).states
                    : [""]
                }
              />
            )}

            <FormInput
              label="City"
              type="text"
              holder="The Blue Posts"
              value={city}
              stateHandler={setCity}
              required={true}
            />

            <FormInput
              label="Zip Code"
              type="number"
              holder="10025"
              value={zipCode}
              stateHandler={setZipCode}
              required={true}
            />

            <FormInput
              label="Website URL"
              type="text"
              holder="https://jamesoconell.com"
              value={webURL}
              stateHandler={setWebURL}
              required={true}
            />
            {webURL !== "" && invalidURL && !urlPattern.test(webURL) && (
              <small style={{ color: "#fc8383" }}>
                Please input a valid URL to proceed.
              </small>
            )}

            <Box my="1.6em">
              <Text fontSize="15px" fontWeight="400" color="#737373">
                Social Media Handle
              </Text>

              <Flex alignItems="center" justifyContent="space-between">
                <Box mr={[".5em", "1em"]} flexGrow={1} mb="7px">
                  <FormInput
                    label=""
                    type="text"
                    holderSpecific="Link Title"
                    value={socialData.title}
                    changeEventHandler={(value: string) =>
                      setSocialData({
                        title: value,
                        url: socialData.url,
                      })
                    }
                  />
                </Box>
                <Box
                  ml={[".5em", "1em"]}
                  display="flex"
                  alignItems="center"
                  flexGrow={1}
                >
                  <FormInput
                    label=""
                    type="text"
                    holderSpecific="Username"
                    value={socialData.url}
                    changeEventHandler={(value: string) =>
                      setSocialData({
                        title: socialData.title,
                        url: value,
                      })
                    }
                  />

                  <Button
                    ml={[".5em", "1em"]}
                    bgColor="#5C14CE"
                    color="#fff"
                    borderRadius=".8em"
                    fontWeight="500"
                    fontSize="13px"
                    px="2em"
                    onClick={addSocialHandler}
                  >
                    Add
                  </Button>
                </Box>
              </Flex>

              {socials.length >= 1 &&
                socials[0].title !== "" &&
                socials.map(
                  (socialInfo: { title: string; url: string }, i: any) => {
                    return (
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        borderBottom="1.5px solid #B6BDC3"
                        pb="4px"
                        my="8px"
                        key={i}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          bgColor="#F0F5FF"
                          borderRadius=".4em"
                          py={["3px", "3px", "6px"]}
                          px=".4em"
                          width={{ base: "38%", md: "38%", lg: "45%" }}
                          mr=".5em"
                        >
                          {socialInfo.title.toLowerCase() === "instagram" ? (
                            <AiFillInstagram color="#999999" />
                          ) : socialInfo.title.toLowerCase() === "facebook" ? (
                            <AiFillFacebook color="#999999" />
                          ) : socialInfo.title.toLowerCase() === "twitter" ? (
                            <AiFillTwitterSquare color="#999999" />
                          ) : socialInfo.title.toLowerCase() === "linkedin" ? (
                            <AiFillLinkedin color="#999999" />
                          ) : (
                            <MdOutlineSocialDistance color="#999999" />
                          )}
                          <Text
                            fontSize="14px"
                            fontWeight="400"
                            color="gray.800"
                            ml=".5em"
                          >
                            {socialInfo.title}
                          </Text>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          bgColor="#F0F5FF"
                          borderRadius=".4em"
                          py={["3px", "3px", "6px"]}
                          px=".4em"
                          width={{ base: "56%", md: "58%", lg: "53%" }}
                          ml=".5em"
                        >
                          <Text
                            fontSize="14px"
                            fontWeight="400"
                            color="gray.800"
                            mr=".5em"
                            overflowWrap="break-word"
                          >
                            {socialInfo.url.length > 15
                              ? socialInfo.url.slice(0, 15) + "..."
                              : socialInfo.url}
                          </Text>
                          <MdCancel
                            color="#000"
                            cursor="pointer"
                            onClick={() =>
                              setSocials((currentSocials: any) =>
                                currentSocials.filter(
                                  (currentData: any) =>
                                    currentData !== currentSocials[i]
                                )
                              )
                            }
                          />
                        </Box>
                      </Flex>
                    );
                  }
                )}
            </Box>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              mb="1em"
              mt="2.5em"
              flexDirection={["column-reverse", "row"]}
              rowGap=".6em"
            >
              <Box width={["100%", "50%", "28%"]}>
                {/* <BaseButton
                text="Skip"
                textColor="#5C14CE"
                bgColor="#F0F5FF"
                clickEventHandler={() =>
                  //   window.open("https://metricks.io", "_self")
                  navigate("/signup/marketing-assets?type=newOrganization")
                }
              /> */}
              </Box>

              <Box width={["100%", "50%", "28%"]}>
                <BaseButton
                  text="Next step"
                  textColor="#fff"
                  bgColor="#5C14CE"
                  rightIcon={<RiArrowRightFill />}
                  type="submit"
                  loadingState={loading}
                />
              </Box>
            </Flex>
          </form>
        </Flex>
      </Flex>
    </StepWrapper>
  );
};
