// @ts-ignore
import { getList } from "country-list-with-dial-code-and-flag";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { StepWrapper } from "../StepWrapper";
import { BsCheckSquareFill, BsFillCaretUpFill, BsSearch } from "react-icons/bs";
import React, { useEffect, useState, useRef } from "react";
import { BaseButton } from "../../button";
import {
  IoArrowBackSharp,
  IoArrowForwardSharp,
  IoChevronDown,
  IoChevronUp,
} from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdCancel } from "react-icons/md";
import { useCookies } from "react-cookie";
import { toast, ToastContainer } from "react-toastify";

import {
  getCurrentUserInstance,
  setCurrentUserType,
  setLocalUserInstance,
} from "../../../state/localstorage";
import { FormInput } from "../../formInput";

const useOutsideAlerter = (ref: any, closeHandler: Function) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        closeHandler();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

export const AffiliateBackground = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [orgType, setOrgType] = useState(false);
  useEffect(() => {
    if (searchParams.get("type") !== null) {
      setOrgType(searchParams.get("type") ? true : false);
    }
  }, [searchParams, orgType]);
  const [cookies] = useCookies([
    "user_id",
    "access_token",
    "aff_user",
    "aff_access_token",
  ]);

  const localUser = getCurrentUserInstance();
  const [allCountries, setAllCountries] = useState(
    localUser && localUser.rawShipToCountries
      ? localUser.rawShipToCountries
      : getList().map((country: any) => {
          return { country, selected: false };
        })
  );
  const size = ["0-1", "2-4", "5-9", "10 >"];
  const howLong = ["0-1 year", "2-4 years", "5-9 years", "10 years > "];

  const [allSize, setAllSize] = useState(
    howLong.map((country: any) => {
      return { country, selected: false };
    })
  );
  const [allTeamSize, setAllTeamSize] = useState(
    size.map((country: any) => {
      return { country, selected: false };
    })
  );
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [showTeamFilter, setShowTeamFilter] = useState(false);
  const [showCountryFilter, setShowCountryFilter] = useState(false);

  let affReq = sessionStorage.getItem("campaignId");

  const toggleHandler = () => {
    return setShowFilter(!showFilter);
  };
  const toggleTeamHandler = () => {
    return setShowTeamFilter(!showTeamFilter);
  };
  const toggleCountryHandler = () => {
    return setShowCountryFilter(!showCountryFilter);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setShowFilter(false));
  const wrapperTeamRef = useRef(null);
  useOutsideAlerter(wrapperTeamRef, () => setShowTeamFilter(false));
  const wrapperCountryRef = useRef(null);
  useOutsideAlerter(wrapperCountryRef, () => setShowCountryFilter(false));
  const [cardData, setCardData] = useState(
    localUser && localUser.rawProductTypes
      ? localUser.rawProductTypes
      : [
          {
            defaultIcon:
              "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/dee212f9-5a5d-4fd6-869d-b4e0fa080ed3.svg",
            coloredIcon: "/assets/svg/colored-ecommerce.svg",
            title: "E-commerce",
            selected: false,
          },
          {
            defaultIcon:
              "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/a7631722-e9dd-4f15-94de-f9c97efe7cba.svg",
            coloredIcon:
              "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/dfee292c-71af-43d6-87c9-5307de051e34.svg",
            title: "SaaS",
            selected: false,
          },
        ]
  );

  const [saveAndExit, setSaveAndExit] = useState(false);
  const [loading, setLoading] = useState(false);
  const submitHandler = () => {
    if (
      cardData.filter((dt: any) => dt.selected).length < 1 ||
      allCountries.filter((countryData: any) => countryData.selected).length <
        1 ||
      allSize.filter((countryData: any) => countryData.selected).length < 1 ||
      allTeamSize.filter((countryData: any) => countryData.selected).length < 1
    ) {
      toast.warning("Complete all required fields to continue");
      return;
    }
    setLoading(true);
    const payload = {
      affiliate_years: allSize
        .filter((countryData: any) => countryData.selected)
        .map((data: any) => data.country)[0],
      team_size: allTeamSize
        .filter((countryData: any) => countryData.selected)
        .map((data: any) => data.country)[0],
      productType: cardData
        .filter((dt: any) => dt.selected)
        .map((data: any) => data.title.split("-").join("").toLowerCase()),
      countriesToShip: allCountries
        .filter((countryData: any) => countryData.selected)
        .map((data: any) => data.country.name),
    };

    fetch(process.env.REACT_APP_API_ENDPOINT + "auth/affiliate-signup/step5", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.aff_access_token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);

        if (data.success) {
          setLocalUserInstance(data.data.user);
          setCurrentUserType("affiliate");

          saveAndExit
            ? window.open("https://www.metricks.io", "_self")
            : navigate(
                `/affiliate-dashboard${
                  affReq ? `?affCampaignRequest=${affReq}` : ""
                }`
              );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const [teamSize, setTeamSize] = useState(size[0]);
  const [teamTime, setTeamTime] = useState("");
  const step = [
    {
      title: "User Profile",
      status: "passed",
      key: 1,
    },
    {
      title: "Company Profile",
      status: "passed",
      key: 2,
    },
    {
      title: "Affiliate Information",
      status: "passed",
      key: 3,
    },
    {
      title: "Niche",
      status: "passed",
      key: 4,
    },
    {
      title: "Affiliate Background",
      status: "current",
      key: 5,
    },
  ];
  const orgSteps = [
    {
      title: "Company Profile",
      status: "passed",
      key: 1,
    },
    {
      title: "Affiliate Information",
      status: "passed",
      key: 2,
    },
    {
      title: "Niche",
      status: "passed",
      key: 3,
    },
    {
      title: "Affiliate Background",
      status: "current",
      key: 4,
    },
  ];
  const steps = orgType ? orgSteps : step;
  return (
    <StepWrapper
      step={steps.map((item: any) => {
        if (item.status === "current") {
          let step = `STEP ${item.key} OF ${steps.length}`;
          return step;
        }
      })}
      header="Setting up your affiliate account"
      baseText="Affiliate Background"
      stepsMap={steps}
    >
      <Flex
        position={"relative"}
        direction={"column"}
        alignItems={"center"}
        w={["90%", "60%"]}
        // backgroundColor={"red"}
      >
        <Flex w={"100%"} direction={"column"}>
          <Box ref={wrapperRef}>
            <ToastContainer />
            <Box>
              <Heading
                fontSize="15px"
                mb="1em"
                lineHeight="40px"
                fontWeight={showFilter ? "medium" : "normal"}
                color={showFilter ? " #5C14CE" : "#737373"}
                mt="2em"
              >
                How long have you been an affliate marketer?{" "}
                <span style={{ color: "#dc3545" }}>*</span>
              </Heading>

              <Box ref={wrapperRef}>
                <Flex
                  borderBottom={
                    showFilter ? "1px solid #5C14CE" : "1px solid #B6BDC3"
                  }
                  pb=".3em"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  cursor="pointer"
                  onClick={toggleHandler}
                >
                  <Flex rowGap=".5em" columnGap=".5em" flexWrap="wrap">
                    {allSize.filter((countryData: any) => countryData.selected)
                      .length >= 1 ? (
                      allSize
                        .filter((countryData: any) => countryData.selected)
                        .map((data: any, i: any) => {
                          return (
                            <Box
                              key={i}
                              alignItems="center"
                              display="flex"
                              borderRadius="4px"
                            >
                              <Text
                                mr=".8em"
                                fontSize="14px"
                                fontWeight="500"
                                color="#000"
                              >
                                {data.country}
                              </Text>
                            </Box>
                          );
                        })
                    ) : (
                      <Text
                        cursor="pointer"
                        fontSize="14px"
                        fontWeight="500"
                        color="#000"
                        onClick={toggleHandler}
                      >
                        Select
                      </Text>
                    )}
                  </Flex>
                  <Box>
                    {!showFilter ? (
                      <IoChevronDown
                        fontSize="20px"
                        color="#737373"
                        cursor="pointer"
                        onClick={toggleHandler}
                      />
                    ) : (
                      <IoChevronUp
                        fontSize="20px"
                        color="#737373"
                        cursor="pointer"
                        onClick={toggleHandler}
                      />
                    )}
                  </Box>
                </Flex>

                {showFilter && (
                  <Box
                    p={["1em", "1em", "1.5em"]}
                    my="1em"
                    boxShadow="xl"
                    rounded="xl"
                    maxH="50vh"
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      my=".5em"
                    >
                      <Text fontSize="14px" color="#737373" fontWeight="500">
                        Number of years{" "}
                      </Text>

                      <BsFillCaretUpFill
                        color="#CACBD4"
                        cursor="pointer"
                        onClick={toggleHandler}
                      />
                    </Flex>

                    {allSize.map((countryData: any, i: any) => {
                      return (
                        <Box
                          display={
                            searchQuery !== "" &&
                            !countryData.country
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                              ? "none"
                              : "flex"
                          }
                          justifyContent="space-between"
                          alignItems="center"
                          key={i}
                          cursor="pointer"
                          _hover={{ bgColor: "gray.50" }}
                          p=".5em"
                          my="2px"
                          borderRadius="3px"
                          onClick={() => {
                            const newState = allSize.map(
                              (data: any, x: any) => {
                                if (allSize[i] == data) {
                                  return { ...data, selected: true };
                                } else {
                                  return { ...data, selected: false };
                                }
                              }
                            );
                            setAllSize(newState);
                            toggleHandler();
                          }}
                        >
                          <Flex alignItems="center" mt="1em">
                            <Text
                              ml="1em"
                              fontSize="14px"
                              color="#737373"
                              fontWeight="500"
                            >
                              {countryData.country}
                            </Text>
                          </Flex>
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            </Box>

            <Box mt="2em">
              <Heading
                fontSize="15px"
                mb="1em"
                fontWeight={showTeamFilter ? "medium" : "normal"}
                color={showTeamFilter ? " #5C14CE" : "#737373"}
                lineHeight="40px"
              >
                What is the size of your team?{" "}
                <span style={{ color: "#dc3545" }}>*</span>
              </Heading>

              <Box ref={wrapperTeamRef}>
                <Flex
                  borderBottom={
                    showTeamFilter ? "1px solid #5C14CE" : "1px solid #B6BDC3"
                  }
                  pb=".3em"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  cursor="pointer"
                  onClick={toggleTeamHandler}
                >
                  <Flex rowGap=".5em" columnGap=".5em" flexWrap="wrap">
                    {allTeamSize.filter(
                      (countryData: any) => countryData.selected
                    ).length >= 1 ? (
                      allTeamSize
                        .filter((countryData: any) => countryData.selected)
                        .map((data: any, i: any) => {
                          return (
                            <Box
                              key={i}
                              alignItems="center"
                              display="flex"
                              borderRadius="4px"
                            >
                              <Text
                                mr=".8em"
                                fontSize="14px"
                                fontWeight="500"
                                color="#000"
                              >
                                {data.country}
                              </Text>
                            </Box>
                          );
                        })
                    ) : (
                      <Text
                        cursor="pointer"
                        fontSize="14px"
                        fontWeight="500"
                        color="#000"
                        onClick={toggleTeamHandler}
                      >
                        Select Team Size
                      </Text>
                    )}
                  </Flex>
                  <Box>
                    {!showTeamFilter ? (
                      <IoChevronDown
                        fontSize="20px"
                        color="#737373"
                        cursor="pointer"
                        onClick={toggleTeamHandler}
                      />
                    ) : (
                      <IoChevronUp
                        fontSize="20px"
                        color="#737373"
                        cursor="pointer"
                        onClick={toggleTeamHandler}
                      />
                    )}
                  </Box>
                </Flex>
                {showTeamFilter && (
                  <Box
                    p={["1em", "1em", "1.5em"]}
                    my="1em"
                    boxShadow="xl"
                    rounded="xl"
                    maxHeight="50vh"
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      my=".5em"
                    >
                      <Text fontSize="14px" fontWeight="500" color="#737373">
                        Team Size
                      </Text>

                      <BsFillCaretUpFill
                        color="#CACBD4"
                        cursor="pointer"
                        onClick={toggleTeamHandler}
                      />
                    </Flex>

                    {allTeamSize.map((countryData: any, i: any) => {
                      return (
                        <Box
                          display={
                            searchQuery !== "" &&
                            !countryData.country
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                              ? "none"
                              : "flex"
                          }
                          justifyContent="space-between"
                          alignItems="center"
                          key={i}
                          cursor="pointer"
                          _hover={{ bgColor: "gray.50" }}
                          p=".5em"
                          my="2px"
                          borderRadius="3px"
                          onClick={() => {
                            const newState = allTeamSize.map(
                              (data: any, x: any) => {
                                if (allTeamSize[i] == data) {
                                  return { ...data, selected: true };
                                } else {
                                  return { ...data, selected: false };
                                }
                              }
                            );
                            setAllTeamSize(newState);
                            toggleTeamHandler();
                          }}
                        >
                          <Flex alignItems="center" mt="1em">
                            <Text ml="1em" fontWeight="500" color="#737373">
                              {countryData.country}
                            </Text>
                          </Flex>
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            </Box>

            <Box mt="2em">
              <Heading
                fontSize="15px"
                mb="1em"
                fontWeight={showCountryFilter ? "medium" : "normal"}
                color={showCountryFilter ? " #5C14CE" : "#737373"}
                lineHeight="40px"
              >
                What countries do you advertise in?{" "}
                <span style={{ color: "#dc3545" }}>*</span>
              </Heading>

              <Box ref={wrapperCountryRef}>
                <Flex
                  borderBottom={
                    showCountryFilter
                      ? "1px solid #5C14CE"
                      : "1px solid #B6BDC3"
                  }
                  pb=".3em"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  cursor="pointer"
                  onClick={toggleCountryHandler}
                >
                  <Flex rowGap=".5em" columnGap=".5em" flexWrap="wrap">
                    {allCountries.filter(
                      (countryData: any) => countryData.selected
                    ).length >= 1 ? (
                      allCountries
                        .filter((countryData: any) => countryData.selected)
                        .map((data: any, i: any) => {
                          return (
                            <Box
                              key={i}
                              bgColor="#F0F5FF"
                              py={["3px", "3px", "6px"]}
                              px=".4em"
                              alignItems="center"
                              display="flex"
                              borderRadius="4px"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Text
                                mr=".8em"
                                ml=".8em"
                                fontSize="14px"
                                fontWeight="500"
                                color="#000"
                              >
                                {data.country.name}
                              </Text>

                              <MdCancel
                                color="#000"
                                cursor="pointer"
                                onClick={(e: React.ChangeEvent<any>) => {
                                  e.stopPropagation();
                                  const newState = allCountries.map(
                                    (delData: any, x: any) => {
                                      if (
                                        data?.country?.name ==
                                        delData?.country?.name
                                      ) {
                                        return {
                                          ...delData,
                                          selected: false,
                                        };
                                      } else {
                                        return delData;
                                      }
                                    }
                                  );
                                  setAllCountries(newState);
                                }}
                              />
                            </Box>
                          );
                        })
                    ) : (
                      <Text
                        cursor="pointer"
                        fontSize="14px"
                        fontWeight="500"
                        color="#000"
                        onClick={toggleCountryHandler}
                      >
                        Select countries
                      </Text>
                    )}
                  </Flex>
                  <Box>
                    {!showCountryFilter ? (
                      <IoChevronDown
                        cursor="pointer"
                        fontSize="20px"
                        color="#737373"
                        onClick={toggleCountryHandler}
                      />
                    ) : (
                      <IoChevronUp
                        fontSize="20px"
                        color="#737373"
                        cursor="pointer"
                        onClick={toggleCountryHandler}
                      />
                    )}
                  </Box>
                </Flex>

                {showCountryFilter && (
                  <Box
                    p={["1em", "1em", "1.5em"]}
                    my="1em"
                    boxShadow="xl"
                    rounded="xl"
                    height="60vh"
                    overflowY="scroll"
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      my=".5em"
                    >
                      <Text fontSize="14px" color="#737373" fontWeight="500">
                        Search Country
                      </Text>

                      <BsFillCaretUpFill
                        color="#CACBD4"
                        cursor="pointer"
                        onClick={toggleCountryHandler}
                      />
                    </Flex>

                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<BsSearch color="gray.300" />}
                      />
                      <Input
                        type="text"
                        _focus={{ ring: "0" }}
                        border="0"
                        bgColor="#F4F6F8"
                        value={searchQuery}
                        onChange={(e: React.ChangeEvent<any>) =>
                          setSearchQuery(e.target.value)
                        }
                      />
                    </InputGroup>

                    {allCountries.map((countryData: any, i: any) => {
                      return (
                        <Box
                          display={
                            searchQuery !== "" &&
                            !countryData.country.name
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                              ? "none"
                              : "flex"
                          }
                          justifyContent="space-between"
                          alignItems="center"
                          key={i}
                          cursor="pointer"
                          _hover={{ bgColor: "gray.50" }}
                          p=".5em"
                          my="2px"
                          borderRadius="3px"
                          onClick={() => {
                            const newState = allCountries.map(
                              (data: any, x: any) => {
                                if (allCountries[i] == data) {
                                  return { ...data, selected: !data.selected };
                                } else {
                                  return data;
                                }
                              }
                            );
                            setAllCountries(newState);
                          }}
                        >
                          <Flex alignItems="center" mt="1em">
                            <div> {countryData.country.flag}</div>
                            <Text
                              ml="1em"
                              color="#737373"
                              fontWeight="500"
                              fontSize="14px"
                            >
                              {countryData.country.name}
                            </Text>
                          </Flex>

                          {countryData.selected ? (
                            <BsCheckSquareFill color="#5C15CE" />
                          ) : (
                            <Box
                              width="1em"
                              height="1em"
                              border="1.5px solid gray"
                              borderRadius="4px"
                            ></Box>
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <Box mt="2em">
            <Heading
              fontSize="15px"
              mb="1em"
              fontWeight="normal"
              lineHeight="40px"
              color="#737373"
            >
              What type of products do you promote?{" "}
              <span style={{ color: "#dc3545" }}>*</span>
            </Heading>

            <Flex columnGap="1em" w="100%" alignItems="center">
              {cardData.map((dt: any, i: any) => (
                <Box
                  key={i}
                  _hover={{ bgColor: "#F5F8FF" }}
                  border="2px solid"
                  borderColor={dt.selected ? "#7211D4" : "#D1D1D1"}
                  bgColor={dt.selected ? "#F5F8FF" : "#ffffff"}
                  padding="16px 16px 10px 16px"
                  borderRadius="0.4em"
                  width="50%"
                  maxW="268px"
                  minHeight={{ base: "148px", md: "170px" }}
                  display="flex"
                  alignItems="flex-start"
                  flexDir="column"
                  // flexDirection={["row-reverse", "column"]}
                  justifyContent="space-between"
                  cursor="pointer"
                  onClick={() => {
                    const newState = cardData.map((dt: any) => {
                      if (cardData[i] == dt) {
                        return {
                          ...dt,
                          selected: !dt.selected,
                        };
                      } else {
                        return dt;
                      }
                    });

                    setCardData(newState);
                  }}
                >
                  {!dt.selected ? (
                    <Box
                      width="1em"
                      height="1em"
                      border="1.5px solid #A4B3C3"
                      borderRadius="4px"
                      cursor="pointer"
                      onClick={() => {
                        const newState = cardData.map((dt: any) => {
                          if (cardData[i] == dt) {
                            return { ...dt, selected: !dt.selected };
                          } else {
                            return dt;
                          }
                        });
                        setCardData(newState);
                      }}
                    ></Box>
                  ) : (
                    <BsCheckSquareFill
                      color="#5C15CE"
                      cursor="pointer"
                      onClick={() => {
                        const newState = cardData.map((dt: any) => {
                          if (cardData[i] == dt) {
                            return { ...dt, selected: !dt.selected };
                          } else {
                            return dt;
                          }
                        });
                        setCardData(newState);
                      }}
                    />
                  )}

                  <Box my=".3em">
                    <Image
                      src={dt.selected ? dt.coloredIcon : dt.defaultIcon}
                    />
                    <Text
                      fontWeight="500"
                      fontSize="14px"
                      lineHeight="26px"
                      color={dt.selected ? "#7211D4" : "#000000"}
                      mt=".2em"
                    >
                      {dt.title}
                    </Text>
                  </Box>
                </Box>
              ))}
            </Flex>
          </Box>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            mb={{ base: "1em", md: "1.75em" }}
            flexDirection={{ base: "column-reverse", lg: "row" }}
            rowGap=".6em"
            mt={{ base: "3em", lg: showFilter ? "3em" : "25vh" }}
          >
            <Button
              px="0"
              color="#5C14CE"
              bgColor="#fff"
              fontWeight="500"
              fontSize="14px"
              _hover={{ bgColor: "#fff" }}
              _focus={{ bgColor: "#fff" }}
              onClick={() => navigate("/signup/affiliate-niche")}
              leftIcon={<IoArrowBackSharp />}
            >
              Back to the previous step
            </Button>
            <Box
              width={["100%", "50%", "28%"]}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              flexDirection={["column-reverse", "row"]}
              rowGap=".6em"
              mb={{ base: "13px", md: "0px" }}
              justifySelf="flex-end"
            >
              <Box ml={["0em", "1em"]} width="100%">
                <BaseButton
                  text="Next step"
                  textColor="#fff"
                  bgColor="#5C14CE"
                  loadingState={loading}
                  rightIcon={<IoArrowForwardSharp />}
                  clickEventHandler={submitHandler}
                />
              </Box>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </StepWrapper>
  );
};
