import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { IoFilterOutline } from "react-icons/io5";
import ListChat from "./elements/ListChat";
import moment from "moment";
import AffiliateChatView from "./elements/AffiliateChatView";
// import { io } from "socket.io-client";
import useUserToken from "../../hooks/useUserToken";
import { getCurrentUserInstance } from "../../state/localstorage";

const affiliateChats = [
  {
    name: "Angelina Martin",
    id: 1,
    image:
      "https://tse4.explicit.bing.net/th?id=OIP.y-R6b74yIBCpAOfYIBVQpAHaLH&pid=Api&P=0&w=300&h=300",
    lastMessage: "I am sorry i forgot to reply the chat that we were having",
    unreadMessageCount: 3,
    isOnline: true,
  },
  {
    name: "Alex Rednalexa",
    id: 2,
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/5257be03-c9e3-407d-a7c5-f96c4bbf15d2.png",
    lastMessage: "I am sorry i forgot to reply the chat that we were having",
    unreadMessageCount: 2,
  },
  {
    name: "Angelina Martin",
    id: 3,
    image:
      "https://tse4.explicit.bing.net/th?id=OIP.y-R6b74yIBCpAOfYIBVQpAHaLH&pid=Api&P=0&w=300&h=300",
    lastMessage: "I am sorry i forgot to reply the chat that we were having",
    unreadMessageCount: 0,
    isOnline: true,
  },
  {
    name: "Alex Rednalexa",
    id: 4,
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/5257be03-c9e3-407d-a7c5-f96c4bbf15d2.png",
    lastMessage: "I am sorry i forgot to reply the chat that we were having",
    unreadMessageCount: 2,
    isOnline: true,
  },
  {
    name: "Alex Rednalexa",
    id: 5,
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/5257be03-c9e3-407d-a7c5-f96c4bbf15d2.png",
    lastMessage: "I am sorry i forgot to reply the chat that we were having",
    unreadMessageCount: 2,
  },
];

const AffiliateChat = (data: any) => {
  const user = getCurrentUserInstance();
  console.log("user", user);
  const token = useUserToken();
  const [searchValue, setsearchValue] = useState("");
  const [showFilter, setshowFilter] = useState(false);
  const filterRef = useRef(null);
  const topElementRef = useRef(null);
  const [chatList, setChatList]: any[] = useState([]);
  const [currentChat, setcurrentChat] = useState(null);
  const [campaign, setCampaigns] = useState([]);

  // const socket = io("https://metricks-backend-service.herokuapp.com", {
  //   transports: ["polling", "websocket"],
  //   upgrade: false,
  // });
  useEffect(() => {
    // socket.on("connect", () => {
    //   socket.emit("CHAT_LIST", { parentId: user.id });
    // });
  }, []);

  // useEffect(() => {
  //   socket.on("connect", () => {
  //     socket.on("CHAT_LIST_RESPONSE", (event: any) => {
  //       setChatList(event);
  //       console.log("Message from aff ", event);
  //       console.log("aff", event);
  //       // setLoading(false);
  //     });
  //   });
  //   // getCampaigns();
  //   // return () => {
  //   //   socket.on("disconnect", () => {
  //   //     console.log(disconnect);
  //   //   });
  //   // };
  // }, [socket]);

  // const sendMessage = () => {
  //   socket.on("connect", () => {
  //     socket.emit("CHAT_PRIVATE", {
  //       userId: 101,
  //       orgId: 44,
  //       message: "new test",
  //       parentId: user.id,
  //     });
  //   });
  //   console.log("test", user.id);
  // };

  if (currentChat)
    return (
      <AffiliateChatView
        updateCurrentChat={setcurrentChat}
        currentChat={currentChat}
        // socket={socket}
      />
    );

  return (
    <Box height="100%" position="relative" pb="1rem" overflowY="scroll">
      <Box
        padding="15px 20px 0px 20px"
        position="sticky"
        bg="white"
        zIndex="4"
        top="0"
        borderBottom="1px solid rgba(50, 50, 93, 0.25)"
      >
        <Flex
          bgColor="rgba(242, 238, 246, 1)"
          alignItems="center"
          px="25px"
          py="14px"
        >
          <Flex
            alignItems="center"
            flex="1"
            bg="white"
            border="0.5px solid rgba(50, 50, 93, 0.2)"
            rounded="6px"
            p="10px 14px"
          >
            <CiSearch fontSize="0.9rem" />
            <input
              placeholder="Search keywords..."
              value={searchValue}
              style={{
                flex: 1,
                padding: "0px",
                border: "none",
                fontSize: "0.75rem",
                marginLeft: "13px",
              }}
              id="chatInput"
              onChange={(e) => setsearchValue(e.target.value)}
            />
          </Flex>
        </Flex>

        <Box borderTop="1px solid rgba(50, 50, 93, 0.25)" mt="10px" py="10px">
          <Text
            fontSize="0.75rem"
            fontWeight="600"
            lineHeight="18px"
            color="rgba(50, 50, 93, 1)"
            textTransform="uppercase"
            // onClick={sendMessage}
          >
            List of advertisers
          </Text>
        </Box>
      </Box>

      {chatList.length ? (
        <VStack px="20px" pb="20px">
          {chatList.map((chat: any, i: number) => (
            <ListChat
              chatId={chat.toUser.id}
              key={i}
              lastMessage={chat.message}
              userImage={chat.profile_image}
              username={`${chat.toUser.firstname} ${chat.toUser.lastname}`}
              openChatView={setcurrentChat}
              unreadMessageCount={1}
              lastMessageDate={moment(chat.updatedAt).fromNow()}
              isOnline={true || false}
            />
          ))}
        </VStack>
      ) : (
        <Box
          height="100%"
          w="100%"
          display="flex"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box>
            <Image
              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
              height="220px"
              objectFit="contain"
              mb="16px"
            />

            <Text
              fontSize="14px"
              lineHeight="21px"
              color="rgba(50, 50, 93, 1)"
              maxW="204px"
              fontWeight="600"
              textAlign="center"
            >
              No data to display yet. Start a new conversation
            </Text>
          </Box>
        </Box>
      )}

      {!affiliateChats.length ? (
        <Box position="sticky" bottom="0" display="flex" justifyContent="end">
          <Image
            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/132b5cdd-6c1f-4cef-8d7e-c9d6fe01442b.svg"
            height="80px"
            width="80px"
            objectFit="contain"
            cursor="pointer"
          />
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default AffiliateChat;
