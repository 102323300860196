import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { InputHints } from "../InputHints";
import {
  getCurrentUserInstance,
  setLocalUserInstance,
} from "../../state/localstorage";
import { useCookies } from "react-cookie";
import axios from "axios";
// import { Phone } from "../formInput/Phone";
import PhoneInput from "react-phone-number-input";
import { Phone } from "./Phone";
import { FormInput } from "../formInput";
import _ from "lodash";
import { CheckIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

export const Account = ({
  getEditProfileState,
  accessToken,
  userInstance,
}: {
  getEditProfileState: Function;
  accessToken: string;
  userInstance: any;
}) => {
  const navigage = useNavigate();
  const [editState, setEditState] = useState(false);
  const editToggleHandler = () => setEditState(!editState);

  const [firstname, setFirstname] = useState(
    userInstance && userInstance.firstname ? userInstance.firstname : ""
  );
  const [lastname, setLastname] = useState(
    userInstance && userInstance.lastname ? userInstance.lastname : ""
  );
  const [email, setEmail] = useState(
    userInstance && userInstance.email ? userInstance.email : ""
  );
  const [phone, setPhone] = useState("");
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const [avatar, setAvatar] = useState<{ url: any; info: any }>({
    url: "",
    info: "",
  });
  const [uploadingPhoto, setuploadingPhoto] = useState(false);

  useEffect(() => {
    getEditProfileState(editState);
  }, [editState]);

  const [loading, setLoading] = useState(false);

  const getUserData = () => {
    setLoading(true);
    fetch(process.env.REACT_APP_API_ENDPOINT + `auth/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken ? `Bearer ${accessToken}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setLoading(false);
          console.log(data.data.user.phoneNumber);
          setAvatar({ url: data.data?.user?.profile_image || "", info: "" });
          setPhone(data.data.user.phoneNumber);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("get my info => " + err);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const hanldeFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setuploadingPhoto(true);

      const formData = new FormData();
      formData.append("photo", e.target.files[0]);

      fetch(process.env.REACT_APP_API_ENDPOINT + `upload/photo`, {
        method: "POST",
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : "",
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setAvatar({
              url: data.data.url || "",
              info: e.target.files ? e.target.files[0] : "",
            });
            return setuploadingPhoto(false);
          } else {
            setuploadingPhoto(false);
            return toast.error("Failed to upload photo");
          }
        })
        .catch((err) => {
          setuploadingPhoto(false);
          return toast.error("Failed to upload photo");
        });
    }
  };

  const updateDetailsHandler = async (e: React.ChangeEvent<any>) => {
    e.preventDefault();

    setLoading(true);

    const payload = {
      firstname,
      lastname,
      email,
      phoneNumber: phone,
      profile_image: avatar.url,
      // currentPassword: currentPass,
      updatePassword: false,
      // newPassword: "",
    };

    await fetch(process.env.REACT_APP_API_ENDPOINT + `auth/update-profile`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);

        if (!data.success) {
          toast.warn(data.message);
        }

        if (data.success) {
          toast.success("Your profile has been updated");
          const user = getCurrentUserInstance();
          let result = Object.assign({}, user, data.data);
          setLocalUserInstance(result);
          setEditState(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("ERR", err);
      });
  };

  const [passLoader, setPassloader] = useState(false);
  const updatePasswordHandler = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    if (confPass !== newPass) return toast.warn("Passwords should match");

    setPassloader(true);
    const payload = {
      currentPassword: currentPass,
      newPassword: newPass,
      updatePassword: true,
    };

    fetch(process.env.REACT_APP_API_ENDPOINT + `auth/update-profile`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        setPassloader(false);

        if (!data.success) {
          toast.warn(data.message);
        }

        if (data.success) {
          const timeOut = window.setTimeout(
            () => toast.success("Your password has been updated"),
            1000 / 2
          );

          localStorage.clear();
          document.cookie =
            "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie =
            "user_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie =
            "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          //  redirect to login page
          navigage("/login");
        }
      })
      .catch((err) => {
        setPassloader(false);
        console.log("ERR", err);
      });
  };

  const [editPassword, setEditPassword] = useState(false);
  const [showCurr, setShowCurr] = useState(false);
  const [showNew, setshowNew] = useState(false);
  const [showconf, setshowConf] = useState(false);

  return (
    <Box bgColor="#fff">
      <ToastContainer />
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          h="50vh"
          alignItems="center"
          pt="20px"
        >
          <Image className="loading" src="/assets/logo.png" height="60px" />
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="70px"
            color="#000000"
            mt="-15px"
          >
            Fetching profile details...
          </Text>
        </Box>
      ) : (
        <>
          <Box
            boxShadow="xl"
            bgColor="#fff"
            // borderLeftRadius=".6em"
            // borderRightRadius=".6em"
            borderBottomLeftRadius=".6em"
            borderEndEndRadius=".6em"
          >
            <form onSubmit={updateDetailsHandler}>
              {editState && (
                <Flex
                  columnGap="1.2em"
                  borderBottom="1px solid"
                  borderBottomColor="gray.300"
                  px={{ md: "1.5em", lg: "2em" }}
                  py=".5em"
                  justifyContent="space-between"
                >
                  <Flex alignItems="center" columnGap="1em">
                    <BsArrowLeft
                      cursor="pointer"
                      onClick={() => setEditState(false)}
                    />
                    <Text fontWeight="600">Edit Profile</Text>
                  </Flex>

                  <Button
                    type="submit"
                    fontSize="14px"
                    fontWeight="500"
                    colorScheme="purple"
                    bgColor="#7211D4"
                    isLoading={loading}
                  >
                    Update Profile
                  </Button>
                </Flex>
              )}
              <Flex
                px={["1em", "1.5em", "2em"]}
                py="2em"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <Flex
                  columnGap="1em"
                  alignItems="center"
                  flexDir={["column", "row"]}
                >
                  {/* <Image src=""/> */}
                  <Box position="relative" alignSelf={["flex-start"]}>
                    {avatar.url ? (
                      <Image
                        src={avatar.url}
                        objectFit="cover"
                        rounded="full"
                        width={["6em", "6em", "8em"]}
                        height={["6em", "6em", "8em"]}
                      />
                    ) : (
                      <Box
                        bgColor="gray.300"
                        width={["6em", "6em", "8em"]}
                        height={["6em", "6em", "8em"]}
                        borderRadius={["3em", "3em", "4em"]}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Heading fontSize="1.3em">
                          {userInstance.firstname[0].toUpperCase() +
                            userInstance.lastname[0].toUpperCase()}
                        </Heading>
                      </Box>
                    )}

                    {editState && (
                      <>
                        <label htmlFor="avatar">
                          <input
                            type="file"
                            accept="image/png, image/jpeg"
                            id="avatar"
                            style={{ display: "none" }}
                            onChange={hanldeFileChange}
                          />
                          <Flex flexDir="row-reverse">
                            <Box
                              position="absolute"
                              bottom={[!avatar ? "0" : "1em"]}
                              right=".5em"
                              p=".5em"
                              bgColor="white"
                              boxShadow="base"
                              borderRadius="1em"
                              cursor="pointer"
                            >
                              <FiEdit3 />
                            </Box>

                            {uploadingPhoto ? (
                              <small>uploading...</small>
                            ) : (
                              <small>
                                {
                                  // @ts-ignore
                                  avatar &&
                                  avatar.info?.name &&
                                  avatar.info?.name?.length > 8
                                    ? // @ts-ignore
                                      avatar.info.name.slice(0, 8) + "..."
                                    : // @ts-ignore
                                    avatar &&
                                      avatar.info?.name &&
                                      avatar.info?.name?.length <= 8
                                    ? // @ts-ignore
                                      avatar.info.name
                                    : ""
                                }
                              </small>
                            )}
                          </Flex>
                        </label>
                      </>
                    )}
                  </Box>
                  <Heading fontSize="1em" color="#32325D">
                    {userInstance.firstname + " " + userInstance.lastname}
                  </Heading>
                </Flex>

                {!editState && (
                  <Button
                    color="#32325DCC"
                    fontSize="14px"
                    fontWeight="500"
                    border="1px"
                    borderColor="gray.300"
                    _hover={{ bgColor: "gray.300" }}
                    onClick={editToggleHandler}
                    size={["sm", "md"]}
                  >
                    Edit Profile
                  </Button>
                )}
              </Flex>

              <Box pb="1em" px={["1em", "1.5em", "2em"]}>
                <Flex
                  alignItems="center"
                  // justifyContent="space-between"
                  // columnGap="1em"
                  my="1em"
                  flexDirection={["column", "row"]}
                  borderTop={["0px", "1px solid"]}
                  borderTopColor={{ md: "gray.300" }}
                  pt={[".5em", "1em"]}
                  gap={3}
                >
                  <Flex width={["100%", "30%"]}>
                    <Heading
                      fontSize={["14px", "1em"]}
                      color="#32325D"
                      flexGrow={1}
                      width={{ base: "100%", md: "49%" }}
                      textAlign="left"
                      mb={[".3em", "0em"]}
                    >
                      First Name
                      <span style={{ color: "red", marginLeft: 5 }}>*</span>
                    </Heading>
                  </Flex>
                  <Flex width={["100%", "50%"]}>
                    <Input
                      type="text"
                      value={firstname}
                      onChange={(e: React.ChangeEvent<any>) => {
                        const camelCaseText = _.camelCase(e.target.value);
                        const final =
                          camelCaseText.charAt(0).toUpperCase() +
                          camelCaseText.slice(1);
                        return setFirstname(final);
                      }}
                      _focus={{ ring: "0", borderColor: "#5C14CE" }}
                      isRequired={true}
                      isDisabled={!editState}
                      style={{
                        height: "50px",
                        backgroundColor: "#fefdff",
                        borderColor: !editState ? "#c4c4c4" : "#7211D4",
                        borderRadius: "10px",
                      }}
                    />
                  </Flex>
                </Flex>
                <Flex
                  alignItems="center"
                  // justifyContent="space-between"
                  // columnGap="1em"
                  my="1em"
                  flexDirection={["column", "row"]}
                  borderTop={["0px", "1px solid"]}
                  borderTopColor={{ md: "gray.300" }}
                  pt={[".5em", "1em"]}
                  gap={3}
                >
                  <Flex width={["100%", "30%"]}>
                    <Heading
                      fontSize={["14px", "1em"]}
                      color="#32325D"
                      flexGrow={1}
                      width={{ base: "100%", md: "49%" }}
                      textAlign="left"
                      mb={[".3em", "0em"]}
                    >
                      Last Name
                      <span style={{ color: "red", marginLeft: 5 }}>*</span>
                    </Heading>
                  </Flex>
                  <Flex width={["100%", "50%"]}>
                    <Input
                      type="text"
                      value={lastname}
                      onChange={(e: React.ChangeEvent<any>) => {
                        const camelCaseText = _.camelCase(e.target.value);
                        const final =
                          camelCaseText.charAt(0).toUpperCase() +
                          camelCaseText.slice(1);
                        return setLastname(final);
                      }}
                      _focus={{ ring: "0", borderColor: "#5C14CE" }}
                      isRequired={true}
                      isDisabled={!editState}
                      style={{
                        height: "50px",
                        backgroundColor: "#fefdff",
                        borderColor: !editState ? "#c4c4c4" : "#7211D4",
                        borderRadius: "10px",
                      }}
                    />
                  </Flex>
                </Flex>

                <Flex
                  alignItems="center"
                  // justifyContent="space-between"
                  // columnGap="1em"
                  my="1em"
                  flexDirection={["column", "row"]}
                  borderTop={["0px", "1px solid"]}
                  borderTopColor={{ md: "gray.300" }}
                  pt={[".5em", "1em"]}
                  gap={3}
                >
                  <Flex width={["100%", "30%"]}>
                    <Heading
                      fontSize={["14px", "1em"]}
                      color="#32325D"
                      flexGrow={1}
                      width={{ base: "100%", md: "49%" }}
                      textAlign="left"
                      mb={[".3em", "0em"]}
                    >
                      Email Address
                      <span style={{ color: "red", marginLeft: 5 }}>*</span>
                    </Heading>
                  </Flex>
                  <Flex width={["100%", "50%"]}>
                    <Input
                      type="email"
                      value={email}
                      onChange={(e: React.ChangeEvent<any>) =>
                        setEmail(e.target.value)
                      }
                      _focus={{ ring: "0", borderColor: "#5C14CE" }}
                      isRequired={true}
                      isDisabled={!editState}
                      style={{
                        height: "50px",
                        backgroundColor: "#fefdff",
                        borderColor: !editState ? "#c4c4c4" : "#7211D4",
                        borderRadius: "10px",
                      }}
                    />
                  </Flex>
                </Flex>

                <Flex
                  alignItems="center"
                  // justifyContent="space-between"
                  // columnGap="1em"
                  my="1em"
                  flexDirection={["column", "row"]}
                  borderTop={["0px", "1px solid"]}
                  borderTopColor={{ md: "gray.300" }}
                  pt={[".5em", "1em"]}
                  gap={3}
                >
                  <Flex width={["100%", "30%"]}>
                    <Heading
                      fontSize={["14px", "1em"]}
                      color="#32325D"
                      flexGrow={1}
                      width={{ base: "100%", md: "49%" }}
                      textAlign="left"
                      mb={[".3em", "0em"]}
                    >
                      Phone Number
                      <span style={{ color: "red", marginLeft: 5 }}>*</span>
                    </Heading>
                  </Flex>
                  <Flex width={["100%", "50%"]}>
                    <Phone
                      holder=""
                      label="Phone Number"
                      value={phone}
                      stateHandler={setPhone}
                      isDisabled={!editState}
                    />
                  </Flex>
                </Flex>

                {/* <Flex
              alignItems="center"
              justifyContent="space-between"
              columnGap="1em"
              my="1em"
              flexDirection={["column", "row"]}
              borderTop={["0px", "1px solid"]}
              borderTopColor={{ md: "gray.300" }}
              py={[".5em", "1em"]}
            >
              <Heading
                fontSize={["14px", "1em"]}
                color="#32325D"
                flexGrow={1}
                width={{ base: "100%", md: "49%" }}
                textAlign="left"
                mb={[".3em", "0em"]}
              >
                Password
              </Heading>
              <Input
                type="password"
                placeholder="**********"
                value={currentPass}
                onChange={(e: React.ChangeEvent<any>) =>
                  setCurrentPass(e.target.value)
                }
                _focus={{ ring: "0", borderColor: "#5C14CE" }}
                isRequired={true}
                isDisabled={!editState}
              />
            </Flex> */}
              </Box>
            </form>
          </Box>

          <Box bgColor="#fff" my="2em" borderRadius=".6em" boxShadow="xl">
            <form onSubmit={updatePasswordHandler}>
              <Box py="1.5em">
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  px="2em"
                  pb="1em"
                  // borderBottom="1px solid"
                  borderTopColor={{ md: "gray.300" }}
                  borderBottomColor="gray.300"
                >
                  {!editPassword ? (
                    <Heading fontSize="1.1em" color="#7211D4">
                      Password
                    </Heading>
                  ) : (
                    <Flex alignItems="center" columnGap="1em">
                      <BsArrowLeft
                        cursor="pointer"
                        onClick={() => setEditPassword(false)}
                      />
                      <Text fontWeight="600"> Edit Password</Text>
                    </Flex>
                  )}

                  {!editPassword && (
                    <Button
                      color="#32325DCC"
                      fontSize="14px"
                      fontWeight="500"
                      border="1px"
                      borderColor="gray.300"
                      _hover={{ bgColor: "gray.300" }}
                      onClick={() => setEditPassword(true)}
                      size={["sm", "md"]}
                    >
                      Change Password
                    </Button>
                  )}

                  {editPassword && (
                    <Button
                      isLoading={passLoader}
                      type="submit"
                      fontSize="14px"
                      fontWeight="500"
                      colorScheme="purple"
                      bgColor="#7211D4"
                    >
                      Update Password
                    </Button>
                  )}
                </Flex>

                <Box mt="1em" px="2em">
                  <Flex
                    alignItems="center"
                    // justifyContent="space-between"
                    // columnGap="1em"
                    my="1em"
                    flexDirection={["column", "row"]}
                    borderTop={["0px", "1px solid"]}
                    borderTopColor={{ md: "gray.300" }}
                    pt={[".5em", "1em"]}
                    gap={3}
                  >
                    <Flex width={["100%", "30%"]}>
                      <Heading
                        fontSize={["14px", "1em"]}
                        color="#32325D"
                        flexGrow={1}
                        width={{ base: "100%", md: "49%" }}
                        textAlign="left"
                        mb={[".3em", "0em"]}
                      >
                        Current Password
                        <span style={{ color: "red", marginLeft: 5 }}>*</span>
                      </Heading>
                    </Flex>

                    <Flex width={["100%", "50%"]}>
                      <InputGroup size="md">
                        <Input
                          pr="4.5rem"
                          type={showCurr ? "text" : "password"}
                          placeholder={"**********"}
                          onChange={(e: React.ChangeEvent<any>) =>
                            setCurrentPass(e.target.value)
                          }
                          _focus={{ ring: "0", borderColor: "#5C14CE" }}
                          isRequired={true}
                          isDisabled={!editPassword}
                          backgroundColor={"#fefdff"}
                          p={7}
                        />
                        <InputRightElement width="4.5rem" p={7}>
                          {editPassword && (
                            <IconButton
                              isRound={true}
                              variant="ghost"
                              backgroundColor="white"
                              aria-label="Done"
                              fontSize="14px"
                              // icon={<ViewIcon />}
                              onClick={() => setShowCurr(!showCurr)}
                              icon={showCurr ? <ViewIcon /> : <ViewOffIcon />}
                            />
                          )}
                        </InputRightElement>
                      </InputGroup>
                    </Flex>
                  </Flex>

                  <Flex
                    alignItems="center"
                    // justifyContent="space-between"
                    // columnGap="1em"
                    my="1em"
                    flexDirection={["column", "row"]}
                    borderTop={["0px", "1px solid"]}
                    borderTopColor={{ md: "gray.300" }}
                    pt={[".5em", "1em"]}
                    gap={3}
                  >
                    <Flex width={["100%", "30%"]}>
                      <Heading
                        fontSize={["14px", "1em"]}
                        color="#32325D"
                        flexGrow={1}
                        width={{ base: "100%", md: "49%" }}
                        textAlign="left"
                        mb={[".3em", "0em"]}
                      >
                        New Password
                        <span style={{ color: "red", marginLeft: 5 }}>*</span>
                      </Heading>
                    </Flex>

                    <Flex direction={"column"} width={["100%", "50%"]} gap={5}>
                      <InputGroup size="md">
                        <Input
                          pr="4.5rem"
                          type={showNew ? "text" : "password"}
                          placeholder={"Enter new password"}
                          onChange={(e: React.ChangeEvent<any>) =>
                            setNewPass(e.target.value)
                          }
                          _focus={{ ring: "0", borderColor: "#5C14CE" }}
                          isRequired={true}
                          isDisabled={!editPassword}
                          p={7}
                        />
                        <InputRightElement width="4.5rem" p={7}>
                          {editPassword && (
                            <IconButton
                              isRound={true}
                              variant="ghost"
                              backgroundColor="white"
                              aria-label="Done"
                              fontSize="14px"
                              onClick={() => setshowNew(!showNew)}
                              icon={showNew ? <ViewIcon /> : <ViewOffIcon />}
                            />
                          )}
                        </InputRightElement>
                      </InputGroup>
                      {newPass !== "" &&
                        (newPass.length < 8 ||
                          !/[A-Z]/.test(newPass) ||
                          !/[a-z]/.test(newPass) ||
                          !/\d/.test(newPass) ||
                          !/[^\w\s\_]/.test(newPass)) && (
                          <Box mt="-1em">
                            <InputHints
                              showWhen={newPass !== ""}
                              title="Your password must be"
                              hints={[
                                {
                                  passed: newPass.length > 7 ? true : false,
                                  text: "at least 8 characters long",
                                },
                                {
                                  passed: /[A-Z]/.test(newPass) ? true : false,
                                  text: "at least one uppercase letter",
                                },
                                {
                                  passed: /[a-z]/.test(newPass) ? true : false,
                                  text: "at least one lowercase letter",
                                },
                                {
                                  passed: /\d/.test(newPass) ? true : false,
                                  text: "at least one number",
                                },
                                {
                                  passed: /[^\w\s\_]/.test(newPass)
                                    ? true
                                    : false,
                                  text: "at least one special character e.g. @#$%^&*()",
                                },
                              ]}
                            />
                          </Box>
                        )}
                    </Flex>
                  </Flex>

                  <Flex
                    alignItems="center"
                    // justifyContent="space-between"
                    // columnGap="1em"
                    my="1em"
                    flexDirection={["column", "row"]}
                    borderTop={["0px", "1px solid"]}
                    borderTopColor={{ md: "gray.300" }}
                    pt={[".5em", "1em"]}
                    gap={3}
                  >
                    <Flex width={["100%", "30%"]}>
                      <Heading
                        fontSize={["14px", "1em"]}
                        color="#32325D"
                        flexGrow={1}
                        width={{ base: "100%", md: "49%" }}
                        textAlign="left"
                        mb={[".3em", "0em"]}
                      >
                        Confirm Password
                        <span style={{ color: "red", marginLeft: 5 }}>*</span>
                      </Heading>
                    </Flex>
                    <Flex direction={"column"} width={["100%", "50%"]} gap={5}>
                      <InputGroup size="md">
                        <Input
                          pr="4.5rem"
                          type={showconf ? "text" : "password"}
                          placeholder={"Confirm your new password"}
                          onChange={(e: React.ChangeEvent<any>) =>
                            setConfPass(e.target.value)
                          }
                          _focus={{ ring: "0", borderColor: "#5C14CE" }}
                          isRequired={true}
                          isDisabled={!editPassword}
                          p={7}
                        />
                        <InputRightElement width="4.5rem" p={7}>
                          {editPassword && (
                            <IconButton
                              isRound={true}
                              variant="ghost"
                              backgroundColor="white"
                              aria-label="Done"
                              fontSize="14px"
                              onClick={() => setshowConf(!showconf)}
                              icon={showconf ? <ViewIcon /> : <ViewOffIcon />}
                            />
                          )}
                        </InputRightElement>
                      </InputGroup>
                      {confPass !== "" && newPass !== confPass && (
                        <Box mt="-1em">
                          <InputHints
                            showWhen={confPass !== ""}
                            title="Passwords do not match"
                            hints={[
                              {
                                passed: newPass === confPass ? true : false,
                                text: "Your passwords must match",
                              },
                            ]}
                          />
                        </Box>
                      )}
                    </Flex>
                  </Flex>
                </Box>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Box>
  );
};
