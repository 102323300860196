import axios from "axios";
import { useEffect, useState } from "react";

export const useCountries = () => {
  const [allCountries, setAllCountries]: any = useState([
    { country: "", states: [] },
  ]);

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await axios.get(
        "https://countriesnow.space/api/v0.1/countries/states"
      );

      !response.data.error
        ? setAllCountries(
            response.data.data.map((country: any) => {
              return {
                country: country.name,
                states: country.states.map((state: any) => state.name),
              };
            })
          )
        : setAllCountries(["Error fetching countries"]);
    };

    fetchCountries();
  }, []);
  return [allCountries];
};
