import React, { useEffect, useState } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { BaseButton } from "../button";
import { useNavigate } from "react-router-dom";
import { getCurrentUserInstance } from "../../state/localstorage";
import moment from "moment";
const BillingCard = () => {
  const [open, setOpen] = useState(true);
  const user = getCurrentUserInstance();
  const navigate = useNavigate();
  console.log("trial", user.organisation.stripe_subscription_status);
  const handleClose = () => {
    setOpen(false);
  };
  const targetDate = moment(user?.createdAt);
  const currentDate = moment();
  const differenceInDays = currentDate.diff(targetDate, "days");

  return (
    <>
      {user?.organisation?.stripe_subscription_status !== "active" && (
        <Flex>
          {open && (
            <Box
              width="100%"
              height="auto"
              background="linear-gradient(90deg, #7211D4 -1.38%, #FA9445 98.36%)"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={["10px 15px", "8px 40px"]}
            >
              <Box width={["70%", "75%"]} display="flex" alignItems="center">
                <Box paddingRight="20px" display={["none", "block"]}>
                  <Image src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/67383841-4615-4d83-a13b-abb2b273b46e.svg" />
                </Box>
                <Box>
                  {differenceInDays <= 14 ? (
                    <Text fontWeight="400" fontSize="12px" color="#ffffff">
                      {`You are on a 14 days trial with ${
                        14 - differenceInDays
                      } ${
                        14 - differenceInDays > 1 ? "days" : "day"
                      } left. You will be billed at the end of your free trial`}
                    </Text>
                  ) : differenceInDays > 14 && differenceInDays <= 21 ? (
                    <Text fontWeight="400" fontSize="12px" color="#ffffff">
                      {` Your 14 days trial is over. You have ${7} days grace period to update your billing details.`}
                    </Text>
                  ) : (
                    <Text fontWeight="400" fontSize="12px" color="#ffffff">
                      Your 14 days free trial is over, Update your billing
                      details to keep your plan benefits.
                    </Text>
                  )}
                </Box>
              </Box>
              <Box
                width={["30%", "25%"]}
                justifyContent="space-between"
                display="flex"
                alignItems={["flex-end", "center"]}
                flexDirection={["column", "row"]}
              >
                {differenceInDays >= 14 && (
                  <Box width={["100%", "auto"]} order={["3", "1"]}>
                    <BaseButton
                      text="Add billing Info"
                      textColor="#32325D"
                      bgColor="#FFFFFF"
                      type="submit"
                      border=" 0.5px solid #E5E4E4"
                      clickEventHandler={() => navigate("/suspended")}
                      borderRadius="8px"
                      fontSize={["10px", "14px"]}
                    />
                  </Box>
                )}
                <Box display={["none", "block"]}>
                  <Image src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/7d6863af-4142-44a4-ab9c-6ab2d4f7f427.svg" />
                </Box>
                <Box
                  display="block"
                  order={["1", "3"]}
                  marginBottom={["12px", "0px"]}
                >
                  <Image
                    onClick={handleClose}
                    cursor="pointer"
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/c6952621-b44b-491e-91aa-2ef4900e477a.svg"
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Flex>
      )}
    </>
  );
};

export default BillingCard;
