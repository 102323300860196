import { Box, Heading, Image, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState, useContext } from "react";
import OrganizationHeader from "./components/OrganizationHeader";
import useUserToken from "../../hooks/useUserToken";
import Organization from "./organization";
import Address from "./address";
import MarketingAssets from "./marketingAssets";
import ProductDetails from "./productDetails";
import { setLocalUserInstance } from "../../state/localstorage";
import { TemporaryNavData } from "../context/TemporaryNavData";
import { Niche } from "./niche";
import { useSearchParams } from "react-router-dom";
const SettingLayout = () => {
  const [currentStep, setcurrentStep] = useState("Organization Profile");
  const [loading, setloading] = useState(true);
  const [orgDetails, setorgDetails] = useState<{ [key: string]: any }>({});
  let [searchParams, _] = useSearchParams();
  const [refreshField, setrefreshField] = useState(0);
  useEffect(() => {
    getOrgDetails();
  }, []);
  const token: string = useUserToken();
  console.log("tokennnn", token);

  const getOrgDetails = () => {
    setloading(true);
    let bearerAuth = token;

    fetch(process.env.REACT_APP_API_ENDPOINT + "auth/me", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.data.user.organisation.website);
        if (data.success) {
          setorgDetails(data.data.user.organisation || {});
          setLocalUserInstance(data.data.user);
        }
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        console.log("org campaign => " + err);
      });
  };
  const getPageTitle = (step: string) => {
    if (step === "address") {
      // setcurrentStep("Address");
      return "Address";
    } else if (step === "marketingassets") {
      // setcurrentStep("Marketing Assets");
      return "Marketing Assets";
    } else if (step === "productdetails") {
      // setcurrentStep("Product Details");
      return "Product Details";
    } else if (step === "niche") {
      // setcurrentStep("Niche");
      return "Niche";
    } else {
      // setcurrentStep("Organization Profile");
      return "Organization Profile";
    }
  };

  return (
    <Stack m="28px 0px" minH="80vh">
      <OrganizationHeader
        currentStep={currentStep}
        setCurrentStep={setcurrentStep}
      />

      <Box m="20px 0px" bgColor="white" p="25px 30px" minH="50vh">
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            h="20vh"
            alignItems="center"
            pt="20px"
          >
            <Image className="loading" src="/assets/logo.png" height="60px" />
            <Text
              fontWeight="400"
              fontSize="16px"
              lineHeight="70px"
              color="#000000"
              mt="-15px"
            >
              Loading organisation Settings...
            </Text>
          </Box>
        ) : getPageTitle(searchParams.get("tab") || "") ===
          "Organization Profile" ? (
          <Organization
            //   @ts-ignore
            setrefreshData={setrefreshField}
            currentStep={currentStep}
            setCurrentStep={setcurrentStep}
            orgCompanyName={orgDetails.companyname || ""}
            orgDescription={orgDetails.description || ""}
            orgDisplayName={orgDetails.displayname || ""}
            orgSocials={orgDetails.socialHandles || {}}
            orgWebsite={orgDetails.website || ""}
            getOrgDetails={getOrgDetails}
          />
        ) : getPageTitle(searchParams.get("tab") || "") === "Address" ? (
          <Address
            orgAddress={orgDetails.address || ""}
            orgCity={orgDetails.city || ""}
            orgCountry={orgDetails.country || ""}
            orgState={orgDetails.state || ""}
            orgZipCode={orgDetails.zip_code || ""}
            setrefreshData={setrefreshField}
            getOrgDetails={getOrgDetails}
            currentStep={currentStep}
            setCurrentStep={setcurrentStep}
          />
        ) : getPageTitle(searchParams.get("tab") || "") ===
          "Marketing Assets" ? (
          <MarketingAssets
            setorgDetails={setorgDetails}
            orgDetails={orgDetails}
            setrefreshData={setrefreshField}
            getOrgDetails={getOrgDetails}
            currentStep={currentStep}
            setCurrentStep={setcurrentStep}
          />
        ) : getPageTitle(searchParams.get("tab") || "") ===
          "Product Details" ? (
          <ProductDetails
            currentStep={currentStep}
            setCurrentStep={setcurrentStep}
            orgrawShipToCountries={orgDetails.rawShipToCountries}
            getOrgDetails={getOrgDetails}
            setrefreshData={setrefreshField}
          />
        ) : (
          <Niche
            currentStep={currentStep}
            setCurrentStep={setcurrentStep}
            getOrgDetails={getOrgDetails}
            setrefreshData={setrefreshField}
          />
        )}
      </Box>
    </Stack>
  );
};

export default SettingLayout;
