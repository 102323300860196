import React from "react";
import Notifications from "../components/notifications";
const Notification = () => {
  return (
    <>
      <Notifications />
    </>
  );
};

export default Notification;
