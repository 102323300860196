import { Flex, Slide, Text, useEditable } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

interface StepSchema {
  title: string;
  status: string;
  key: number;
}
const ProgressBar = ({ steps }: { steps: StepSchema[] }) => {
  const [progressStep, setProgressStep] = useState(20);
  const [progressTitle, setProgressTitle] = useState("");
  const [progressValue, setProgressValue] = useState(1);

  useEffect(() => {
    steps.map((step, i) => {
      if (step.status === "current") {
        setProgressTitle(step.title);
        setProgressValue(step.key);
        setProgressStep((step.key * 100) / steps.length);
      }
    });
  }, []);
  return (
    <Flex direction={"column"} w={"100%"} gap={3}>
      <Flex justify={"space-between"}>
        <Text>{progressTitle}</Text>
        <Text fontWeight={700}>
          {progressValue}/{steps.length}
        </Text>
      </Flex>
      <Flex w={"100%"} h={"7px"} backgroundColor={"#5C14CEA3"} borderRadius={5}>
        <Flex
          h={"100%"}
          borderRadius={5}
          backgroundColor={"#DAC7F6"}
          transition={"ease-out"}
          w={progressStep + "%"}
        ></Flex>
      </Flex>
    </Flex>
  );
};

export default ProgressBar;
