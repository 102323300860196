import {
  Heading,
  ModalBody,
  Text,
  UnorderedList,
  ListItem,
  Box,
  extendTheme,
  Flex,
} from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { campaignState } from "../../../../state/campaign";
// import ReactHighlightSyntax from "react-highlight-syntax";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { getCurrentUserInstance } from "../../../../state/localstorage";
const JsDetails = () => {
  const user = getCurrentUserInstance();
  // console.log("user Data", user.organisation.apikey);
  const api = `${user.organisation.apikey}`;
  // slice(
  //   0,
  //   60
  // )}\n${user.organisation.apikey.slice(61)}`;
  const code = `<script async src='https://tracker-v2.vercel.app/tracker.min.js' data-\n metricks='<your_api_key>'></script>`;
  const code2 = `<script type= "text/javascript">\nawait metricks.lead ({\ncustomer_id: 'cus_xxxxxxxxx',\nemail: 'yourcustomer@email.com',\nname: 'Jane Doe',\nmetadata: {},\n})\n</script>`;
  const code3 = `<script type= "text/javascript">\nawait metricks.lead ({\n customer_id: 'cus_xxxxxxxxx',\nemail: 'yourcustomer@email.com', \nname: 'Jane Doe',\n metadata: {},\n})\n</script>`;
  const test = `When a lead is created, it will show up in your metricks dashboard > Campaigns > Leads`;

  const theme = extendTheme({
    styles: {
      global: {
        mark: {
          color: "black",
        },
      },
    },
  });
  return (
    <ModalBody py="1em" h="600px">
      <Box
        height={{ base: "500", lg: "700px" }}
        overflowY="auto"
        px={{ base: "1px", lg: "4em" }}
      >
        <Heading size="sm" color="#32325D" mb="20px">
          Adding the Metricks Script to your website
        </Heading>
        <hr />
        <Box width="100%" mt="20px">
          <Heading size="sm" color="#32325D" mb="20px">
            metricks API Key
          </Heading>
          {/* <ReactHighlightSyntax
            language={"JavaScript"}
            theme={"Base16AtelierCaveLight"}
            copy={true}
            copyBtnTheme={"Dark"}
          >
        </ReactHighlightSyntax> */}
          <SyntaxHighlighter
            language="javascript"
            style={docco}
            customStyle={{
              padding: "15px",
            }}
          >
            {api}
          </SyntaxHighlighter>
        </Box>
        <Text mt="20px">
          To add the metricks Script to your website, you must add a small
          snippet of code to your website. The code is less than 13kb and, once
          installed, requires no further maintenance to function from your end.
        </Text>
        <Text mt="20px" mb="10px">
          Copy the tracking script and paste it at the bottom and inside your
          website's "head" tag.
        </Text>
        {/* <ReactHighlightSyntax
          language={"JavaScript"}
          theme={"Base16AtelierCaveLight"}
          copy={true}
          copyBtnTheme={"Dark"}
        >
      </ReactHighlightSyntax> */}
        <SyntaxHighlighter
          language="javascript"
          style={docco}
          customStyle={{
            padding: "15px",
          }}
        >
          {code}
        </SyntaxHighlighter>
        <Heading size="sm" color="#32325D" mt="20px">
          Tracking Affiliate leads
        </Heading>
        <Text mt="20px">
          Tracking Affiliate Leads Leads are created when a customer clicks on
          an affiliate link, and a tracking cookie is placed on their device to
          track their activities related to the affiliate link.
        </Text>
        <Text mt="10px">{test}</Text>
        <Text mt="10px">
          This process usually happens on the signup page or order form to
          record all valid leads.
        </Text>
        <Text mt="10px">
          metricks will only save users who signed up after visiting a referral
          link and have a valid cookie in their browser.
        </Text>
        {/* <ReactHighlightSyntax
          language={"JavaScript"}
          theme={"Base16AtelierCaveLight"}
          copy={true}
          copyBtnTheme={"Dark"}
        >
      </ReactHighlightSyntax> */}
        <SyntaxHighlighter
          language="javascript"
          style={docco}
          customStyle={{
            padding: "15px",
          }}
        >
          {code2}
        </SyntaxHighlighter>
        <Text size="md" mt="20px">
          Arguments
        </Text>
        <UnorderedList listStyleType="disc">
          <ListItem>
            Customer ID: should be a string, and it's required. This is the ID
            of your customer, and it should be unique for every user.
          </ListItem>
          <ListItem>Name: This should be a string and optional.</ListItem>
          <ListItem>Email: This should be a string and optional.</ListItem>
          <ListItem>
            Metadata: This should be an object and optional, and it can also be
            used to store additional data.
          </ListItem>
          {/* <ListItem>
            <Box
              as="span"
              display="inline-block"
              w="6px"
              h="6px"
              bg="blue.500"
              borderRadius="50%"
              mr="4px"
              mt="4px"
              position="relative"
              top="2px"
            />
            Item 1
          </ListItem> */}
        </UnorderedList>
        <Text size="md" fontWeight="bold" mt="20px" mb="20px">
          Tracking Affiliates conversions
        </Text>
        <hr />
        <Text size="sm" mt="20px" mb="20px">
          You'll need to run the function below to track sales or conversions.
          This process usually happens on a "thank-you" page after the customer
          purchases or subscribes.
        </Text>
        {/* <ReactHighlightSyntax
          language={"JavaScript"}
          theme={"Base16AtelierCaveLight"}
          copy={true}
          copyBtnTheme={"Dark"}
        >
      </ReactHighlightSyntax> */}
        <SyntaxHighlighter
          language="javascript"
          style={docco}
          customStyle={{
            padding: "15px",
          }}
        >
          {code3}
        </SyntaxHighlighter>
        <UnorderedList listStyleType="disc" mt="20px">
          <ListItem>
            Customer ID: should be a string, and it's required. This is the ID
            of your customer, and it should be unique for every user.
          </ListItem>
          <ListItem>Name: This should be a string and optional.</ListItem>
          <ListItem>Email: This should be a string and optional.</ListItem>
          <ListItem>
            Metadata: This should be an object and optional, and it can also be
            used to store additional data.
          </ListItem>
          {/* <ListItem>
            <Box
              as="span"
              display="inline-block"
              w="6px"
              h="6px"
              bg="blue.500"
              borderRadius="50%"
              mr="4px"
              mt="4px"
              position="relative"
              top="2px"
            />
            Item 1
          </ListItem> */}
        </UnorderedList>
      </Box>
    </ModalBody>
  );
};

export default JsDetails;
