import { Box, Heading, Image, Text } from "@chakra-ui/react";
import "./style.css";
import React, { useState, useEffect } from "react";
import { FormInput } from "../formInput";
import { Link, useNavigate } from "react-router-dom";
import Container from "./container";
import { usePutRequest } from "../../hooks/usePutRequest";
import { ToastContainer, toast } from "react-toastify";
import { forgotState } from "../../state/login";
import { useRecoilState } from "recoil";
import { BaseButton } from "../button";
import { IoArrowForwardSharp } from "react-icons/io5";
const Reset = () => {
  const [putHandler, serverResponse, serverError] = usePutRequest();
  const [text, setText] = useRecoilState(forgotState);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  let navigate = useNavigate();
  useEffect(() => {
    toast.error(serverError);
  }, [serverError]);
  const sumbitHandler = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    if (email) {
      setLoading(true);
      putHandler({
        endPoint: process.env.REACT_APP_API_ENDPOINT + "auth/forgot-password",

        dataObject: {
          email: email,
        },
      });
    } else {
      toast.warn("Please complete all required fields");
      setLoading(false);
    }
  };
  if (serverResponse) {
    if (serverResponse.data.success) {
      setText(email);
      localStorage.setItem("remember_email", email);
      navigate("/reset-password/token");
    }
  }
  return (
    <>
      <ToastContainer />
      <Container>
        <Box
          display="flex"
          width="100%"
          height="100vh"
          overflow="hidden"
          left="0"
          bottom="0"
          zIndex="10"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          bgColor="#f0f5ff"
        >
          <Image src="/assets/logo.png" mb="1.5em" height="50px" />
          <Heading
            fontWeight="700"
            color="#000000"
            fontSize="32px"
            lineHeight="60px"
          >
            Hey there,
          </Heading>

          <Box width={["90%", "50%"]}>
            <Text
              fontSize="18px"
              fontWeight="400"
              marginBottom="40px"
              textAlign="center"
              display={["none", "block"]}
            >
              Please enter your email below and we will send you <br />
              instructions to reset your password.
            </Text>
            <form
              onSubmit={sumbitHandler}
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                flexDirection: "column",
                width: "100%",
                height: "70vh",
              }}
              className="bigger"
            >
              <Box width="100%">
                <FormInput
                  label="Email Address"
                  type="email"
                  value={email}
                  stateHandler={setEmail}
                  required={true}
                  holder="example@gmail.com"
                />
              </Box>
              <Box width="100%">
                <Box my="30px">
                  <BaseButton
                    text="Continue"
                    padded={true}
                    textColor="#fff"
                    bgColor="#5C14CE"
                    type="submit"
                    loadingState={loading && !serverError}
                    loaderText="Loading..."
                    clickEventHandler={() => console.log("working")}
                    rightIcon={<IoArrowForwardSharp />}
                  />
                </Box>
                <Text
                  color="#918A8A"
                  mb="2em"
                  fontSize="14px"
                  fontWeight="400"
                  textAlign="center"
                >
                  Trouble resetting your password? Contact &nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto:support@metricks.com"
                  >
                    <Text as="span" color="#5C14CE !important">
                      support@metricks.com
                    </Text>
                  </a>
                </Text>
              </Box>
            </form>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Reset;
