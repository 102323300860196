import {
  Box,
  Button,
  Heading,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  ModalHeader,
  Stack,
  Container,
  List,
  ListItem,
  ListIcon,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tab,
  Badge,
  Image,
  Tabs,
  TabList,
  css,
  TabPanels,
  TabPanel,
  useMediaQuery,
  Input,
  Textarea,
  Circle,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react-use-disclosure";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  BsCaretDown,
  BsCaretUp,
  BsCheck2Circle,
  BsChevronDown,
  BsChevronUp,
  BsFillCheckCircleFill,
  BsFillCheckSquareFill,
  BsPlusLg,
} from "react-icons/bs";

import { IoArrowBackSharp, IoArrowForwardSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { BaseButton } from "../button";

import {
  Elements,
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { toast, ToastContainer } from "react-toastify";
import { useCookies } from "react-cookie";
import { MdCheckCircle } from "react-icons/md";
import moment from "moment";
import { RiVisaLine } from "react-icons/ri";
import { BsCircle } from "react-icons/bs";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { MasterCard } from "../../assets";
import Earnings from "./Earnings";
import { AddIcon } from "@chakra-ui/icons";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#282838",
      border: "1px solid #D1D1D1",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const ChangeBillingModal = ({
  accessToken,
  closeModal,
}: {
  accessToken: string;
  closeModal: Function;
}) => {
  const plans = [
    {
      package: "Starter",
      monthlyPrice: "$40",
      yearlyPlan: "140px",
      features: [
        "Unlimited Affiliates",
        "Free Transactional fees",
        "Trigger notifications",
        "In-app messaging",
      ],
    },
    {
      package: "Pro",
      monthlyPrice: "$40",
      yearlyPlan: "140px",
      features: [
        "Unlimited Affiliates",
        "Free Transactional fees",
        "Trigger notifications",
        "In-app messaging",
      ],
    },
    {
      package: "Premium",
      monthlyPrice: "$40",
      yearlyPlan: "140px",
      features: [
        "Unlimited Affiliates",
        "Free Transactional fees",
        "Trigger notifications",
        "In-app messaging",
      ],
    },
    {
      package: "Premium",
      monthlyPrice: "$40",
      yearlyPlan: "Let's Talk",
      features: [],
    },
  ];

  const [showIndexFeature, setShowIndexFeature]: [
    showIndexFeature: null | { index: number; show: boolean },
    setShowIndexFeature: any
  ] = useState(null);

  const featuresToggleHandler = (index: number) => {
    setShowIndexFeature({
      index,
      // @ts-ignore
      show:
        // @ts-ignore
        index === showIndexFeature?.index
          ? // @ts-ignore
            !showIndexFeature?.show
          : true,
    });
  };

  const [initLoader, setInitLoader] = useState({ state: false, idx: 0 });
  const changePlanHandler = (plan: string) => {
    const payload = {
      plan: plan.toLowerCase(),
    };

    axios
      .post(process.env.REACT_APP_API_ENDPOINT + `billing/upgrade`, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        if (response.data.success) toast.success("Success");
        const outTime = window.setTimeout(closeModal(), 1000 / 2);
        return () => window.clearTimeout(outTime);
      })
      .catch((err) => {
        const axiosErr = err.response;
        toast.warn(axiosErr.data.message);
      });

    setInitLoader({ state: false, idx: 0 });
  };

  return (
    <Box
      zIndex="3"
      bgColor="blackAlpha.600"
      minHeight="100vh"
      width="100vw"
      position="absolute"
      top="0"
      left="0"
      display="flex"
      alignItems="center"
      justifyContent="center"
      py={["2em", "0em"]}
      onClick={() => closeModal()}
    >
      <ToastContainer />
      <Container
        mx="auto"
        maxW="container.md"
        borderRadius=".3em"
        bgColor="#fff"
        onClick={(e) => e.stopPropagation()}
      >
        <Stack spacing={4} py="1.5em">
          <Heading fontSize="1em" fontWeight="500">
            Plans & Pricing
          </Heading>

          <Box width="100%" height="1px" bgColor="gray.300"></Box>

          <Stack
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            {plans.map((plan, x) => {
              return (
                <Box
                  key={x}
                  border="1px solid"
                  borderColor="gray.300"
                  borderRadius=".3em"
                  p=".8em"
                  color="#32325D"
                  width={{ base: "100%", md: "25%" }}
                >
                  <Flex alignItems="center" justifyContent="space-between">
                    <Heading fontSize="1em">{plan.package}</Heading>
                    {plan.features.length >= 1 && (
                      <Box
                        cursor="pointer"
                        onClick={() => featuresToggleHandler(x)}
                      >
                        {/* @ts-ignore */}
                        {showIndexFeature?.index === x &&
                        // @ts-ignore
                        showIndexFeature?.show ? (
                          <BsChevronUp />
                        ) : (
                          <BsChevronDown />
                        )}
                      </Box>
                    )}
                  </Flex>
                  <Text
                    fontSize="12px"
                    fontWeight="medium"
                    my="1em"
                    color="gray.500"
                  >
                    {`Billed at ${plan.monthlyPrice} monthly`}
                  </Text>
                  <Heading fontSize="1.2em" mb="1em">
                    {plan.yearlyPlan}
                    <small
                      style={{
                        fontSize: "12px",
                        color: "#718096",
                        fontWeight: "500",
                      }}
                    >
                      /year
                    </small>
                  </Heading>

                  <Button
                    fontSize="14px"
                    fontWeight="500"
                    bgColor="#6B46C1"
                    color="#fff"
                    py=".3em"
                    width="100%"
                    mb="1em"
                    isLoading={initLoader.idx === x && initLoader.state}
                    onClick={() => {
                      setInitLoader({ idx: x, state: true });
                      changePlanHandler(plan.package);
                    }}
                  >
                    Choose Plan
                  </Button>

                  {showIndexFeature &&
                    //  @ts-ignore
                    showIndexFeature?.index === x &&
                    //  @ts-ignore
                    showIndexFeature?.show && (
                      <List spacing={1}>
                        {plan.features.length >= 1 &&
                          plan.features.map((feature, i) => {
                            return (
                              <ListItem
                                fontSize="13px"
                                fontWeight="medium"
                                key={i}
                              >
                                <ListIcon as={MdCheckCircle} color="#00BA6C" />
                                {feature}
                              </ListItem>
                            );
                          })}
                      </List>
                    )}
                </Box>
              );
            })}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export const Billing = ({
  accessToken,
  userInstance,
}: {
  accessToken: string;
  userInstance: any;
}) => {
  // console.log(userInstance);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [changePlanModal, setChangePlanModal] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [selectedCard, setSelectedCard] = useState(cardData ? cardData[0] : []);

  const [invoice, setInvoice] = useState([]);

  const getBillingCards = () => {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_API_ENDPOINT + `billing/cards`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          setCardData(res.data.data.data);
          // console.log("DATA", res.data.data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Card Error:", error);
      });
  };
  useEffect(() => {
    getBillingCards();
    // ! Invoice
    axios
      .get(process.env.REACT_APP_API_ENDPOINT + `billing/invoices`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setInvoice(res.data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Invoice Error:", error);
      });
  }, []);

  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isCancelopen, setCancelopen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [showCard, setShowCard] = useState(true);
  const [activateSub, setActivateSub] = useState(false);
  const [error, setError] = useState(false);

  const [description, setDescription] = useState("");

  const submitHandler = async (event: React.ChangeEvent<any>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);
    const card = elements.getElement(CardNumberElement);
    // @ts-ignore
    const result = await stripe.createToken(card);
    if (result.error) {
      toast.error(result.error.message);
      setLoading(false);
    } else {
      // Send the token to your server.
      console.log(result);

      const payload = {
        source: result.token.id,
        // @ts-ignore
        card_id: result.token.card.id,
        plan: "basic",
      };

      fetch(process.env.REACT_APP_API_ENDPOINT + "billing/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          console.log(data);
          if (!data.success) {
            toast.warning(data.message);
            return;
          }

          toast.success("Billing info updated");
          onClose();
          getBillingCards();
          // const timeOut = window.setTimeout(
          //   () => window.location.reload(),
          //   1000 / 2
          // );
          // return () => window.clearTimeout(timeOut);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const cancelSubscription = async () => {
    // console.log(accessToken);
    if (description === "") {
      setError(true);
      return;
    }

    setLoading(true);

    const payload = {
      reason: description,
    };

    fetch(process.env.REACT_APP_API_ENDPOINT + "billing/cancel-subscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === false) {
          setLoading(false);
          console.log(data);

          toast.error("Cancelling subscription failed");
        } else {
          setLoading(false);
          console.log(data);
          toast.success("Subscription Cancelled");
          setSuccessOpen(true);
          setShowCard(false);
          setCancelopen(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const modalStateHandler = () => {
    setChangePlanModal(false);
  };

  const [invoiceForDownload, setInvoiceForDownload] = useState("");
  const [isDesktop] = useMediaQuery("(min-width: 768px)");

  const invoiceDownloadHandler = () => {
    if (invoiceForDownload === "") {
      return toast.info("Please select an option");
    }

    return window.open(invoiceForDownload, "_self");
  };

  return (
    <Box bgColor="#fff" borderBottomRadius=".6em">
      <ToastContainer />
      {changePlanModal && (
        <ChangeBillingModal
          accessToken={accessToken}
          closeModal={modalStateHandler}
        />
      )}
      {/* payment method */}
      <Modal isOpen={isOpen} onClose={onClose} size={"2xl"} isCentered={true}>
        <ModalOverlay />
        <ModalContent p={5}>
          <ModalHeader>Update Payment Method</ModalHeader>
          {/* <ModalHeader>Modal Title</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody>
            <Box pt="2em">
              <form onSubmit={submitHandler}>
                <Flex direction={"column"} gap={10}>
                  <label style={{ fontWeight: "500", marginTop: "1em" }}>
                    Card number
                    <Box
                      w="full"
                      borderColor="#c4c4c4"
                      borderRadius="5px"
                      borderWidth="1px"
                      p={4}
                    >
                      <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                    </Box>
                  </label>

                  <label style={{ fontWeight: "500" }}>
                    Expiration month and year
                    <Box
                      w="full"
                      borderColor="#c4c4c4"
                      borderRadius="5px"
                      borderWidth="1px"
                      p={4}
                    >
                      <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                    </Box>
                  </label>

                  <label style={{ fontWeight: "500" }}>
                    CVC
                    <Box
                      w="full"
                      borderColor="#c4c4c4"
                      borderRadius="5px"
                      borderWidth="1px"
                      p={4}
                    >
                      <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                    </Box>
                  </label>
                </Flex>
              </form>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              mb="1em"
              mt="2.5em"
              flexDirection={{
                md: "row",
              }}
              rowGap=".6em"
            >
              <Button
                border="1px"
                borderColor="#5C14CE"
                color="#5C14CE"
                bgColor="#fff"
                fontWeight="500"
                fontSize="14px"
                px="2em"
                _hover={{ bgColor: "#fff" }}
                _focus={{ bgColor: "#fff" }}
                width={["48%"]}
                onClick={onClose}
              >
                Cancel
              </Button>

              <Button
                disabled={!stripe}
                bgColor="#5C15CE"
                color="#fff"
                borderRadius=".4em"
                _hover={{ bgColor: "#5c15cedb" }}
                // px="2em"
                width={["48%"]}
                // width={["100%", "40%"]}
                fontWeight="500"
                fontSize="14px"
                type="submit"
                isLoading={loading}
                onClick={(e) => submitHandler(e)}
              >
                Update
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* cancel subscription */}
      <Modal isOpen={isCancelopen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent
          borderRadius="1em"
          p=".8em"
          height="370px"
          mx={{ base: ".6em", md: "auto" }}
          marginTop={{ base: "15em", md: "18em" }}
        >
          <Box
            width=".8em"
            height="370px"
            position="absolute"
            left="0"
            bgGradient="linear(360deg, #7211D4 -1.38%, #FA9445 98.36%)"
            top="0"
            borderLeftRadius="1em"
          ></Box>
          {/* <ModalHeader mt="1em">Modal Title</ModalHeader> */}
          <ModalCloseButton
            bgColor="gray.100"
            borderRadius="1.5em"
            p="1.5em"
            top="1.5em"
            right="1.8em"
            fontSize="1em"
            // my={10}
            onClick={() => setCancelopen(false)}
          />

          <ModalBody mt="5em" mr="1em">
            <Text
              fontSize={{ base: "14px", md: "1.3em" }}
              fontWeight="500"
              mb={{ base: "1.6em", md: "2em" }}
            >
              Are you sure you want to cancel your subscription?
            </Text>

            <Textarea
              size={"sm"}
              resize={"none"}
              placeholder="Tell us why you want to cancel your subscription..."
              p={5}
              onChange={(e: React.ChangeEvent<any>) =>
                setDescription(e.target.value)
              }
              isInvalid={error}
              focusBorderColor="#5C14CE"
              // _focus={{ ring: "0", borderColor: "#5C14CE" }}
              style={{
                height: "100px",
                backgroundColor: "#e8ecf4",
                borderColor: "#e8ecf4",
                borderRadius: "10px",
              }}
            />
            <Box
              width="100%"
              height="1px"
              mx="auto"
              bgColor="gray.200"
              mt="1em"
            ></Box>
          </ModalBody>

          <ModalFooter>
            <Button
              bgColor="#7211D4"
              color="#F0F5FF"
              rightIcon={<IoArrowForwardSharp />}
              onClick={() => cancelSubscription()}
              isLoading={loading}
            >
              Confirm Cancellation
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Success Modal */}
      <Modal
        isOpen={successOpen}
        onClose={onClose}
        size={"md"}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent p={5} borderRadius={10}>
          {/* <ModalHeader>Update Payment Method</ModalHeader> */}
          {/* <ModalHeader>Modal Title</ModalHeader> */}
          {/* <ModalCloseButton /> */}
          <ModalBody>
            <Flex
              pt="2em"
              justify={"center"}
              align={"center"}
              direction={"column"}
              px={"49px"}
            >
              <Image
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/d5d558a8-0f2a-45e7-adca-3587559b1118.svg"
                h={{ base: "auto", md: "auto" }}
                mb={{ md: "1.5em" }}
              />
              <Text fontSize={20} fontWeight={500} textAlign={"center"}>
                Your subscription has been cancelled
              </Text>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="center"
              mb="1em"
              mt="2.5em"
              rowGap=".6em"
            >
              <Button
                // disabled={!stripe}
                bgColor="#5C15CE"
                color="#fff"
                borderRadius=".4em"
                _hover={{ bgColor: "#5c15cedb" }}
                // px="2em"
                width={["80%"]}
                // width={["100%", "40%"]}
                fontWeight="500"
                fontSize="14px"
                type="submit"
                isLoading={loading}
                onClick={(e) => {
                  getBillingCards();
                  setSuccessOpen(false);
                }}
                p={7}
              >
                Continue
              </Button>
            </Flex>
          </ModalBody>
          {/* <ModalFooter>
           
          </ModalFooter> */}
        </ModalContent>
      </Modal>
      {/* Activate sub  */}
      <Modal
        isOpen={activateSub}
        onClose={onClose}
        size={"lg"}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent p={5} borderRadius={20}>
          {/* <ModalHeader>Update Payment Method</ModalHeader> */}
          {/* <ModalHeader>Modal Title</ModalHeader> */}
          {/* <ModalCloseButton /> */}
          <ModalBody>
            <Flex gap={5} direction={"column"}>
              <Circle size="60px" bg="#ecfdf3">
                <Circle size="40px" bg="#d1fadf" color="#43b780">
                  <BsCheck2Circle size={25} />
                </Circle>
              </Circle>
              <Text fontWeight={"600"} fontSize={20}>
                Activate Subscription
              </Text>
              <Text fontWeight={"400"} fontSize={15}>
                Are you sure you want to activate your subscription
              </Text>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                mb="1em"
                mt="2.5em"
                flexDirection={{
                  md: "row",
                }}
                rowGap=".6em"
              >
                <Button
                  border="1px"
                  borderColor="#5C14CE"
                  color="#5C14CE"
                  bgColor="#fff"
                  fontWeight="500"
                  fontSize="14px"
                  px="2em"
                  py={"1.5em"}
                  _hover={{ bgColor: "#fff" }}
                  _focus={{ bgColor: "#fff" }}
                  width={["48%"]}
                  onClick={() => setActivateSub(false)}
                >
                  Cancel
                </Button>

                <Button
                  // disabled={!stripe}
                  border="1px"
                  borderColor="#5C14CE"
                  bgColor="#5C15CE"
                  color="#fff"
                  borderRadius=".4em"
                  _hover={{ bgColor: "#5c15cedb" }}
                  px="2em"
                  width={["48%"]}
                  py={"1.5em"}
                  // width={["100%", "40%"]}
                  fontWeight="500"
                  fontSize="14px"
                  // isLoading={loading}
                  onClick={() => {
                    setActivateSub(false);
                    onOpen();
                  }}
                >
                  Activate
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          h="50vh"
          alignItems="center"
          pt="20px"
        >
          <Image className="loading" src="/assets/logo.png" height="60px" />
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="70px"
            color="#000000"
            mt="-15px"
          >
            Fetching card details...
          </Text>
        </Box>
      ) : (
        <Box px={{ base: "1em", md: "1.5em", lg: "2em" }}>
          {cardData && cardData.length >= 1 && (
            <Box py="3em" borderBottom="1px solid" borderBottomColor="gray.300">
              <Button
                fontSize="14px"
                fontWeight="500"
                colorScheme="#6B46C1"
                bgColor="#eb645b"
                onClick={() => setCancelopen(true)}
              >
                Cancel Subscription
              </Button>
            </Box>
          )}

          <Box py="1.5em">
            {!cardData && (
              <Heading
                fontSize=".9em"
                color="#32325D"
                fontWeight="600"
                mb="2em"
              >
                Payment method
              </Heading>
            )}

            {cardData && cardData.length >= 1 ? (
              <Flex direction="column" rowGap="1.5em">
                {cardData.map((card: any) => {
                  return (
                    <Box
                      w="100%"
                      //  @ts-ignore
                      bg={"#ecf3fe"}
                      h="100px"
                      mb="20px"
                      borderColor={
                        //  @ts-ignore
                        "#7211d4"
                      }
                      borderWidth="0.5px"
                      borderRadius="10px"
                      p={5}
                      _hover={{
                        bg: "Alabaster",
                        borderColor: "PurplishBlue",
                      }}
                      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
                      _active={{
                        bg: "#dddfe2",
                        transform: "scale(0.98)",
                        borderColor: "#bec3c9",
                      }}
                      //  @ts-ignore
                      key={card.id}
                      onClick={() => {
                        //  @ts-ignore
                        console.log(card.id);
                        //  @ts-ignore
                        setSelectedCard(card);
                        //  @ts-ignore
                        // setCardID(card.id);
                      }}
                    >
                      <Flex>
                        <Flex w="20%" ml={3}>
                          {/* @ts-ignore */}
                          {card?.brand === "Visa" ? (
                            <RiVisaLine
                              size="50px"
                              color="#254294"
                              style={{
                                backgroundColor: "white",
                                padding: "5px",
                                borderRadius: "5px",
                              }}
                            />
                          ) : (
                            <Image
                              src={MasterCard}
                              style={{
                                // backgroundColor: "white",
                                padding: "5px",
                                borderRadius: "5px",
                                width: "60px",
                              }}
                            />
                          )}
                        </Flex>
                        <Flex direction="column" w="60%" gap={3}>
                          <Heading
                            as="h5"
                            size="md"
                            color="#000"
                            fontWeight={500}
                          >
                            {/* @ts-ignore  */}
                            {card?.brand === "Visa" ? "Visa" : "Master"} ending
                            {/* @ts-ignore  */}
                            {" in"} {card.last4}
                          </Heading>

                          <Text color="#000">
                            {/* @ts-ignore  */}
                            Expiry {card.exp_month} / {card.exp_year}
                          </Text>
                        </Flex>
                        <Flex w="20%" justify="end">
                          {/* @ts-ignore  */}
                          {/* {selectedCard?.id == card.id ? (
                          <BsFillCheckCircleFill size="20px" color="#7211d4" />
                        ) : (
                          <BsCircle size="20px" color="#000" />
                        )} */}
                        </Flex>
                      </Flex>
                    </Box>
                  );
                })}
              </Flex>
            ) : (
              <Flex
                w={"100%"}
                borderColor={"#c4c4c4"}
                p={10}
                borderWidth={1}
                direction={"column"}
                align={"center"}
                gap={5}
              >
                <Flex
                  w={"100%"}
                  justify={"center"}
                  align={"center"}
                  direction={"column"}
                  // gap={5}
                  // borderTop={"1px"}
                  // borderTopColor={"#c4c4c4"}
                  // pt={20}
                >
                  <Image
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
                    width="150px"
                    height="150px"
                  />
                  <Text fontWeight={"500"} fontSize={15} textAlign={"center"}>
                    There is currently no payment method
                  </Text>
                  <Text
                    color={"#c4c4c4"}
                    fontWeight={"400"}
                    textAlign={"center"}
                  >
                    Re-activate subscription by adding a payment method
                  </Text>
                </Flex>
                <Button
                  leftIcon={<AddIcon />}
                  // colorScheme="teal"
                  variant="solid"
                  w={"250px"}
                  p={"20px"}
                  _hover={{
                    bg: "#7211d4",
                    borderColor: "PurplishBlue",
                    color: "white",
                  }}
                  transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
                  _active={{
                    bg: "#7211d4",
                    transform: "scale(0.98)",
                    borderColor: "#7211d4",
                  }}
                  bgColor={"#7211d4"}
                  color={"white"}
                  onClick={() => setActivateSub(true)}
                >
                  Re-activate Subscription
                </Button>
              </Flex>
            )}

            {cardData && cardData.length >= 1 && (
              <Flex
                alignItems="center"
                columnGap=".8em"
                my="1.5em"
                cursor="pointer"
                onClick={onOpen}
              >
                <BsPlusLg fontSize=".9em" />
                <Heading fontSize=".9em" color="#32325D" fontWeight="600">
                  Update Card
                </Heading>
              </Flex>
            )}
            <Box w={"100%"}>
              <Tabs
                colorScheme="purple"
                w="100%"
                isFitted={isDesktop ? true : false}
              >
                <TabPanels>
                  <TabPanel>
                    <Earnings />
                  </TabPanel>

                  <TabPanel>
                    <Earnings />
                  </TabPanel>

                  <TabPanel>
                    <Earnings />
                  </TabPanel>

                  <TabPanel>
                    <Earnings />
                  </TabPanel>

                  <TabPanel>
                    <Earnings />
                  </TabPanel>

                  <TabPanel>
                    <Earnings />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
