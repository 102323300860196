import {
  Heading,
  ModalBody,
  Text,
  UnorderedList,
  ListItem,
  Box,
  extendTheme,
} from "@chakra-ui/react";
import React from "react";
// import ReactHighlightSyntax from "react-highlight-syntax";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { getCurrentUserInstance } from "../../../../state/localstorage";
const WixDetails = () => {
  const user = getCurrentUserInstance();
  const api = `${user.organisation.apikey}`;
  const complexText1 = `In the “Site Structure” panel on the left of your page, under Pages > Shop, click “Thank You Page”. Here, click on the sample order details in the middle of the editor. This will open the properties panel and show you the section’s ID. If the value after ID is not thankYouPage1, copy the actual value and replace “thankYouPage1” at the top of the code snippet you see in the last step`;
  const code = `let thankYouPageId="thankYouPage1",\napikey="Your metricks API Key",\napiUrl="https://api.metricks.io/api/v1";\n

  import a from "wix-location" ; import c from "wix-window"; import{fetch as 
    d} from "wix-fetch"; import{local as e} from "wix-storage";
    let query=a.query,removeUndefined=a=>{for(var b in a)void0===a[b]&&delete a[b];\nreturn a},
    getUtms=()=>{let b=["utm_source","utm_medium","utm_term","utm_content",\n"utm_campaign",],c={};
    for(var a=0,e=b.length;a<e;a++)for(var d in 
    query)d===b[a]&&(c[b[a]]=d);return c},request=(e,a,b,h)=>{a=removeUndefined(a);
    let f=e;a="POST"===b?JSON.stringify(a):null;let 
    g={"Content-Type":"application/json",Authorization:\`Bearer {apikey}\`},c={method:b,body:a,\nheaders:g};a&&(c.body=a),d(f,c).then(a=>a.ok?a.json():null).then(a=>{a&&"function"==\ntypeof h&&h(a)})};if(query.ref){let b={unique_link:query.ref,utms:getUtms()||{}};request\n(apiUrl+"/wix/click",b,"POST",a=>{console.log(a.data),e.setItem("__metr_ref",a.data.affCode),\ne.setItem("__metr_metrId",a.data.metricksid)})}$w.onReady(function(){let a=$w("#thankYouPage1");\nArray.isArray(a)||a.getOrder().then(a=>{let b=e.getItem("__metr_ref"),\nc=e.getItem("__metr_metrId");if(!b)return;let d={order_id:a._id,currency:a.currency,amount:\na.totals.subtotal-a.totals.discount,subtotal:a.totals.subtotal,customer_id:a.buyerInfo.id,\nname:a.buyerInfo.firstName+" "+a.buyerInfo.lastName,\nemail:a.buyerInfo.email,utms:getUtms()||{},clickId:c,unique_link:b};\nrequest(apiUrl+"/wix/conversion",d,"POST")}).catch(()=>{})})
`;
  return (
    <ModalBody py="1em" h="600px">
      <Box
        height={{ base: "500", lg: "700px" }}
        overflowY="auto"
        px={{ base: "1px", lg: "4em" }}
      >
        <Heading size="sm" color="#32325D" mb="20px">
          Note: This integration only works for WIX online stores. Other WIX use
          cases  are not supported.
        </Heading>
        <hr />
        <Box width="100%" mt="20px">
          <Heading size="sm" color="#32325D" mb="20px">
            Metricks API Key
          </Heading>
          {/* <ReactHighlightSyntax
            language={"JavaScript"}
            theme={"Base16AtelierCaveLight"}
            copy={true}
            copyBtnTheme={"Dark"}
          >
        </ReactHighlightSyntax> */}
          <SyntaxHighlighter
            language="javascript"
            style={docco}
            customStyle={{
              padding: "15px",
            }}
          >
            {api}
          </SyntaxHighlighter>
        </Box>
        <Heading size="sm" color="#32325D" mb="20px" mt="20px">
          Adding code to masterPage.js
        </Heading>
        <hr />
        <UnorderedList>
          <ListItem listStyleType="none" mt="20px">
            <Box
              as="span"
              display="inline-block"
              w="8px"
              h="8px"
              bg="#7211D4"
              borderRadius="50%"
              mr="6px"
              position="relative"
            />
            Click “Edit Site” to go to your Wix Store Editor in your WIX Store
            dashboard.
          </ListItem>
          <ListItem listStyleType="none" mt="20px">
            <Box
              as="span"
              display="inline-block"
              w="8px"
              h="8px"
              bg="#7211D4"
              borderRadius="50%"
              mr="6px"
              position="relative"
            />
            In your WIX Store editor, click “Dev Mode” at the top, and turn on
            Dev mode.
          </ListItem>
          <ListItem listStyleType="none" mt="20px">
            <Box
              as="span"
              display="inline-block"
              w="8px"
              h="8px"
              bg="#7211D4"
              borderRadius="50%"
              mr="6px"
              position="relative"
            />
            {complexText1}
          </ListItem>
          <ListItem listStyleType="none" mt="20px">
            <Box
              as="span"
              display="inline-block"
              w="8px"
              h="8px"
              bg="#7211D4"
              borderRadius="50%"
              mr="6px"
              position="relative"
            />
            Once you have confirmed the ID of your Thank You page, find
            masterPage.js and click to open it.
          </ListItem>
          <ListItem listStyleType="none" mt="20px" mb="20px">
            <Box
              as="span"
              display="inline-block"
              w="8px"
              h="8px"
              bg="#7211D4"
              borderRadius="50%"
              mr="6px"
              position="relative"
            />
            In the masterPage.js tab that opens at the bottom of the page, erase
            all of the existing code and paste the following code.
          </ListItem>
        </UnorderedList>
        {/* <ReactHighlightSyntax
          language={"JavaScript"}
          theme={"Base16AtelierCaveLight"}
          copy={true}
          copyBtnTheme={"Dark"}
        >
      </ReactHighlightSyntax> */}
        <SyntaxHighlighter
          language="javascript"
          style={docco}
          customStyle={{
            padding: "15px",
          }}
        >
          {code}
        </SyntaxHighlighter>
      </Box>
    </ModalBody>
  );
};

export default WixDetails;
