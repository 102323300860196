import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import OrganizationSettingsFooter from "./OrganizationSettingsFooter";
import { industries } from "../../../../utils/niche";
import { useOutsideAlerter } from "../../../../hooks/useOutsideAlerter";
import { MdCancel } from "react-icons/md";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { BsCheckSquareFill, BsFillCaretUpFill, BsSearch } from "react-icons/bs";

const AffiliateOrgNiche = ({
  orgNiche,
  setRefreshData,
}: {
  setRefreshData: React.Dispatch<React.SetStateAction<number>>;
  orgNiche: string[];
}) => {
  const [showIndustries, setShowIndustries] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [nicheData, setNicheData] = useState(
    orgNiche && orgNiche.length
      ? industries.map((item: any) => {
          if (orgNiche.includes(item?.niche)) {
            return { niche: item?.niche || "", selected: true };
          } else {
            return item;
          }
        })
      : industries
  );
  const toggleHandler = () => {
    return setShowIndustries(!showIndustries);
  };

  const [newUpdate, setnewUpdate] = useState(false);
  const [count, setcount] = useState(1);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setShowIndustries(false));

  useEffect(() => {
    if (count > 1) {
      setnewUpdate(true);
      sessionStorage.setItem("affiliateSettingsChange", "new update");
    }
    setcount(count + 1);
  }, [nicheData]);

  const validator = () => {
    let selected = nicheData.filter((niche: any) => niche.selected);

    if (!nicheData || !selected.length)
      return { isValid: false, error: "You must select at least one niche" };

    return { isValid: true, error: "" };
  };

  return (
    <Box minHeight="70vh">
      <Flex
        columnGap="1em"
        my="1em"
        flexDirection={["column", "row"]}
        borderTop={["0px", "0.5px solid"]}
        borderTopColor={{ md: "rgba(50, 50, 93, 0.30)" }}
        py={[".5em", "1em"]}
      >
        <Text
          fontSize={"0.9rem"}
          color="#32325D"
          fontWeight="500"
          width={{ base: "100%", md: "30%" }}
          textAlign="left"
          mb={[".3em", "0em"]}
        >
          Niche <span style={{ color: "#dc3545" }}>*</span>
        </Text>

        <Box width={{ base: "100%", md: "70%" }} maxW="572px">
          <Flex w="100%" columnGap=".5em" alignItems="center">
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              border="0.6px solid"
              borderColor="rgba(50, 50, 93, 0.30)"
              borderRadius="8px"
              minHeight="48px"
              padding="0px 12px 0px 12px"
              mb="1em"
              cursor={"pointer"}
              onClick={toggleHandler}
              ref={wrapperRef}
            >
              <Flex columnGap=".5em" rowGap=".5em" flexWrap="wrap">
                {nicheData.filter((singleNiche: any) => singleNiche.selected)
                  .length >= 1 ? (
                  nicheData
                    .filter((singleNiche: any) => singleNiche.selected)
                    .map(
                      (
                        singleNiche: { niche: string; selected: boolean },
                        i: any
                      ) => {
                        return (
                          <Box
                            key={i}
                            bgColor="#F0F5FF"
                            py={["3px", "3px", "6px"]}
                            px=".4em"
                            alignItems="flex-start"
                            display="flex"
                            borderRadius="4px"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Text
                              mr=".8em"
                              fontSize="14px"
                              fontWeight="400"
                              color="gray.800"
                            >
                              {singleNiche.niche}
                            </Text>
                            <MdCancel
                              color="#000"
                              cursor="pointer"
                              onClick={() => {
                                const newState = nicheData.map(
                                  (data: any, x: any) => {
                                    if (singleNiche.niche == data.niche) {
                                      return { ...data, selected: false };
                                    } else {
                                      return data;
                                    }
                                  }
                                );
                                setNicheData(newState);
                              }}
                            />
                          </Box>
                        );
                      }
                    )
                ) : (
                  <Text>Select niche</Text>
                )}
              </Flex>

              <Box>
                {!showIndustries ? (
                  <IoChevronDown cursor={"pointer"} onClick={toggleHandler} />
                ) : (
                  <IoChevronUp cursor={"pointer"} onClick={toggleHandler} />
                )}
              </Box>
            </Box>
          </Flex>

          {showIndustries && (
            <Box
              p={["1em", "1em", "1.5em"]}
              my="1em"
              boxShadow="xl"
              rounded="xl"
              height="60vh"
              overflowY="scroll"
              ref={wrapperRef}
            >
              <Flex
                alignItems="flex-start"
                justifyContent="space-between"
                my=".2em"
              >
                <Text fontSize="14px" color="gray.400" fontWeight="400">
                  Search Niche
                </Text>

                <BsFillCaretUpFill
                  color="#CACBD4"
                  cursor="pointer"
                  onClick={toggleHandler}
                />
              </Flex>

              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<BsSearch color="gray.300" />}
                />
                <Input
                  type="text"
                  _focus={{ ring: "0" }}
                  border="0"
                  bgColor="#F4F6F8"
                  value={searchQuery}
                  onChange={(e: React.ChangeEvent<any>) =>
                    setSearchQuery(e.target.value)
                  }
                />
              </InputGroup>

              {nicheData.map((singleNiche: any, i: any) => {
                return (
                  <Box
                    key={i}
                    display={
                      searchQuery !== "" &&
                      !singleNiche.niche
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                        ? "none"
                        : "flex"
                    }
                    justifyContent="space-between"
                    alignItems="flex-start"
                    my="1em"
                    cursor="pointer"
                    _hover={{ bgColor: "gray.50" }}
                    onClick={() => {
                      const newState = nicheData.map((data: any, x: any) => {
                        if (nicheData[i] == data) {
                          return { ...data, selected: !data.selected };
                        } else {
                          return data;
                        }
                      });
                      setNicheData(newState);
                    }}
                    p=".5em"
                    borderRadius="3px"
                  >
                    <Text>{singleNiche.niche}</Text>

                    {singleNiche.selected ? (
                      <BsCheckSquareFill color="#5C15CE" />
                    ) : (
                      <Box
                        width="1em"
                        height="1em"
                        border="1.5px solid gray"
                        borderRadius="4px"
                      ></Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          )}

          {nicheData.filter((niche: any) => niche.selected)?.length === 0 ? (
            <Text
              fontSize="14px"
              color="red.300"
              mt={showIndustries ? "0em" : "-0.6em"}
              fontWeight="medium"
              className="nicheError"
            >
              This field is required
            </Text>
          ) : null}
        </Box>
      </Flex>

      <OrganizationSettingsFooter
        hasUpdate={newUpdate}
        sethasUpdate={setnewUpdate}
        setRefreshData={setRefreshData}
        dataToUpdate={{
          niche: nicheData
            .filter((niche: any) => niche.selected)
            ?.map((nicheObj: any) => nicheObj.niche),
        }}
        validator={validator}
      />
    </Box>
  );
};

export default AffiliateOrgNiche;
