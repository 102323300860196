import {
  Box,
  Flex,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  css,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import AffUserProfile from "./components/AffUserProfile";
import AffiliateOrg from "./components/AffiliateOrg";
import AffiliateInfo from "./components/AffiliateInfo";
import useUserToken from "../../../../../hooks/useUserToken";
import AffiliateReports from "./components/AffiliateReports";
import AffCustomQuestions from "./components/AffCustomQuestions";

const AffiliateDetails = ({
  aff_info,
  setcurrentSelectedAffiliate,
  campaignId,
  setExternalAffInfo,
}: {
  aff_info: any;
  campaignId: string | undefined;
  setcurrentSelectedAffiliate: React.Dispatch<any>;
  setExternalAffInfo: React.Dispatch<any>;
}) => {
  const [affiliate, setaffiliate] = useState<{ [key: string]: any }>({});
  const [isLoading, setisLoading] = useState(true);
  const [isDesktop] = useMediaQuery("(min-width: 768px)");

  const token = useUserToken();

  const getAffiliateDetails = () => {
    setisLoading(true);

    let bearerAuth = token;

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `explore/find-affiliates/${aff_info?.aff_orgId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("aff info", data);
        if (data.success) {
          setaffiliate(data.data.affiliate);
          setisLoading(false);
        }
      })
      .catch((err) => {
        setisLoading(false);
        console.log("get top 5 affiliates => " + err);
      });
  };

  useEffect(() => {
    getAffiliateDetails();
  }, [aff_info]);

  const tabData = [
    {
      label: "User Profile",
      content: (
        <AffUserProfile
          profileImage={affiliate?.user?.profile_image || ""}
          id={aff_info?.id}
          status={aff_info?.status}
          firstname={affiliate?.user?.firstname || "-"}
          lastname={affiliate?.user?.lastname || "-"}
          email={affiliate?.user?.email || "-"}
          phone={affiliate?.user?.phoneNumber || "-"}
        />
      ),
    },
    {
      label: "Organization",
      content: (
        <AffiliateOrg
          companyName={affiliate?.companyname || ""}
          website={affiliate?.website || ""}
          address={affiliate?.address || ""}
          state={affiliate?.state || ""}
          country={affiliate?.country || ""}
          city={affiliate?.city || ""}
          socials={affiliate?.socialHandles || []}
        />
      ),
    },
    {
      label: "Affiliate Information",
      content: (
        <AffiliateInfo
          affiliateType={affiliate?.affiliate_type || ""}
          countriesToShip={affiliate?.countriesToShip || []}
          experience={affiliate?.affiliate_years || ""}
          productType={affiliate?.productType || []}
          size={affiliate?.team_size || "-"}
          niche={affiliate?.niche || []}
        />
      ),
    },
    {
      label: "Reports",
      content: (
        <AffiliateReports id={campaignId} affOrgId={aff_info?.aff_orgId} />
      ),
    },
    {
      label: "Custom Questions",
      content: (
        <AffCustomQuestions
          campaignId={campaignId}
          affOrgId={aff_info?.aff_orgId}
        />
      ),
    },
    // {
    //   label: "Referrals",
    //   content: "Affiliate Referrals",
    // },
  ];

  return (
    <Box>
      <Box display="flex" alignItems="center" cursor="pointer" mt="26px">
        <Flex
          onClick={() => {
            setExternalAffInfo(null);
            setcurrentSelectedAffiliate(null);
          }}
          align={"center"}
        >
          <MdKeyboardBackspace color="#32325D" />
          <Text
            color="#32325D"
            lineHeight="40px"
            fontSize="14px"
            fontWeight="400"
            pl="8px"
            display={{ base: "none", md: "block" }}
          >
            Back to the previous page
          </Text>
        </Flex>
        <Text
          color="#32325D"
          lineHeight="40px"
          fontSize="14px"
          fontWeight="400"
          pl="8px"
          display={{ base: "block", md: "none" }}
        >
          Back
        </Text>
      </Box>

      <Tabs position="relative" isFitted={true} mt="26px" variant="unstyled">
        <TabList
          borderBottom="0.5px solid rgba(50, 50, 93, 0.3)"
          css={
            isDesktop
              ? {}
              : css({
                  "::-webkit-scrollbar": { display: "none" },
                  "-webkit-overflow-scrolling": "touch",
                })
          }
          overflowX={{ base: "auto", md: "visible" }}
        >
          {tabData.map((tab, index) => (
            <Tab
              key={index}
              color="#32325D"
              fontSize="12px"
              fontWeight="500"
              whiteSpace={{ base: "nowrap", md: "normal" }}
              _selected={{ color: "#7211D4" }}
            >
              {tab.label}
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          {tabData.map((tab, index) => (
            <TabPanel pl="0px" pr="0px" key={index}>
              {tab.content}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default AffiliateDetails;
