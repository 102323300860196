import {
  Box,
  Button,
  // Container,
  Flex,
  // FormControl,
  // FormHelperText,
  // FormLabel,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  // Input,
  // Select,
  Stack,
  // Switch,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getCookie } from "cookies-next";
import { useEffect, useState } from "react";
// import { BsPlusCircle } from "react-icons/bs";
import { IoArrowBackSharp, IoArrowForwardSharp } from "react-icons/io5";
// import { MdDelete } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import { apiReqHandler } from "../../../helper/apiReqHandler";
import { endpoints } from "../../../utils/endpoints";
import { BaseButton } from "../../button";
import FormBuilder from "editorjs-form-builder";
import { useRecoilState } from "recoil";
import { campaignState } from "../../../state/campaign";
import { CampaignHeader } from "./Header";
import { fetchCampaign } from "../../../helper/fetchCampaign";

export const Questions = () => {
  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get("campaign");
  const productType = searchParams.get("product");

  const [campaignStateObj, setCampaignStateObj] = useRecoilState(campaignState);
  console.log("HERE", { campaignStateObj });

  const [editPage] = useState(
    window.location.pathname.includes("/edit-campaign")
  );

  const toast = useToast();

  const [questions, setQuestions]: any = useState(null);

  //   questions.length >= 1 && console.log(questions);

  const [saveAndExit, setSaveAndExit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [activateLoader, setActivateLoader] = useState(false);
  const toastt = useToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [campaignName, setCampaignName] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [status, setstatus] = useState("");
  useEffect(() => {
    // questionConstruct(campaignStateObj?.questions);
    getCampaignData();
    function handleBeforeUnload(event: any) {
      if (!formSubmitted) {
        event.preventDefault();
        event.returnValue = "";
      }
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formSubmitted]);
  const [loading, setLoading] = useState(false);
  const submitHandler = async () => {
    setFormSubmitted(true);
    if (questions.length < 0) return;
    setCampaignStateObj({ ...campaignStateObj, questions });

    setLoader(true);

    if (questions[0].data.label == "") {
      console.log("submit questions mee", questions);

      const { res, error } = await apiReqHandler({
        //   @ts-ignore
        endPoint: endpoints.campaign.CREATE.STEP_FOUR(campaignId),
        method: "PUT",
        payload: { questions: [], step: 4, isComplete: true },
        //   @ts-ignore
        bearerAuth: getCookie("access_token"),
      });
      setLoader(false);
      if (res) onOpen();
      if (error) {
        console.log("ERR", error);
        return toast({
          title: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          status: "warning",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    } else {
      const { res, error } = await apiReqHandler({
        //   @ts-ignore
        endPoint: endpoints.campaign.CREATE.STEP_FOUR(campaignId),
        method: "PUT",
        payload: { questions, step: 4, isComplete: true },
        //   @ts-ignore
        bearerAuth: getCookie("access_token"),
      });
      setLoader(false);
      if (res) onOpen();
      if (error) {
        console.log("ERR", error);
        return toast({
          title: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          status: "warning",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
  };
  const getCampaignData = async () => {
    setLoading(true);
    // @ts-ignore
    const { campaignData } = await fetchCampaign({ campaignId });
    questionConstruct(campaignData.customQuestion);
    setLoading(false);
    setCampaignName(campaignData?.campaign_name);
    setstatus(campaignData?.status);
    console.log("data", campaignData);
  };
  const questionConstruct = (test: any) => {
    let array: any = [];
    console.log("test", test);
    if (editPage && campaignStateObj?.questions !== null) {
      test.map((question: any) => {
        if (question.type !== "multipleChoiceQuestion") {
          console.log("normal", question);
          array = [
            ...array,
            {
              data: {
                help: question?.help,
                label: question?.label,
                placeholder: question?.placeholder,
                required: question?.required,
              },
              id: `${question.id}`,
              type: question.type,
            },
          ];
        } else {
          console.log("abnormal", question);
          array = [
            ...array,
            {
              data: {
                help: question?.help,
                label: question?.label,
                multipleChoice: question?.multipleChoice,
                options: question?.options,
              },
              id: `${question.id}`,
              type: question?.type,
            },
          ];
        }
      });
    }
    console.log("array", array);
    const questionIsEmpty =
      test[0]?.data?.label === "" &&
      test[0]?.data?.help === "" &&
      test[0]?.data?.placeholder === "";
    if (!questionIsEmpty) {
      setQuestions(array);
    } else {
      setQuestions([]);
    }
  };
  const test = [
    {
      id: "8Z7TsKEv7e",
      type: "textQuestion",
      data: {
        label: "First Name",
        help: "",
        placeholder: "Eg Uche",
        required: true,
      },
    },
    {
      id: "7ZcdGLmOCO",
      type: "emailQuestion",
      data: {
        label: "Email",
        help: "",
        placeholder: "your email",
        required: true,
      },
    },
  ];

  const saveAndExitHandler = () => {
    setCampaignStateObj({});
    navigate("/campaigns");
  };

  const backToPreviousHandler = () => {
    setCampaignStateObj({ ...campaignStateObj, questions });
    navigate(
      editPage
        ? `/campaigns/edit-campaign/marketing-assets?product=${productType}&campaign=${campaignId}`
        : `/campaigns/marketing-assets?product=${productType}&campaign=${campaignId}`
    );
  };
  const navHandler = () => {
    setCampaignStateObj({});
    navigate(`/campaigns?campaign=${campaignId}`);
  };
  const activateCampaignHandler = async (status: "active" | "draft") => {
    setActivateLoader(true);

    const { res, error } = await apiReqHandler({
      //   @ts-ignore
      endPoint: endpoints.campaign.UPDATE_ONE(campaignId),
      method: "PUT",
      payload: { status },
      //   @ts-ignore
      bearerAuth: getCookie("access_token"),
    });
    setActivateLoader(false);

    if (error) {
      console.log("ERR", error);
      return toastt({
        title: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }

    if (res) navHandler();
  };

  return (
    <Box m="0px 0px 22px 0px">
      <CampaignHeader currentStep={4} />

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent
          borderRadius="1em"
          p=".8em"
          height="235px"
          mx={{ base: ".6em", md: "auto" }}
          marginTop={{ base: "15em", md: "18em" }}
        >
          <Box
            width=".8em"
            height="235px"
            position="absolute"
            left="0"
            bgGradient="linear(360deg, #7211D4 -1.38%, #FA9445 98.36%)"
            top="0"
            borderLeftRadius="1em"
          ></Box>
          {/* <ModalHeader mt="1em">Modal Title</ModalHeader> */}
          <ModalCloseButton
            bgColor="gray.100"
            borderRadius="1.5em"
            p="1.5em"
            top="1.5em"
            right="1.8em"
            fontSize="1em"
            onClick={onClose}
          />
          {editPage ? (
            <ModalBody mt="2.5em" mr="1em">
              <Text
                fontSize={{ base: "14px", md: "1.3em" }}
                fontWeight="medium"
                mb={{ base: "1.6em", md: "2em" }}
              >
                Do you want to save changes you have made to this campaign ?
              </Text>
              <Box
                width="90%"
                height="1px"
                mx="auto"
                bgColor="gray.200"
                mb=".6em"
              ></Box>
            </ModalBody>
          ) : (
            <ModalBody mt="2.5em" mr="1em">
              <Text
                fontSize={{ base: "14px", md: "1.3em" }}
                fontWeight="medium"
                mb={{ base: "1.6em", md: "2em" }}
              >
                You have {editPage ? "updated" : "created"}{" "}
                <span style={{ color: "#8663D7" }}>{campaignName}</span>! Do you
                want to take it live or save it as a draft?
              </Text>
              <Box
                width="90%"
                height="1px"
                mx="auto"
                bgColor="gray.200"
                mb=".6em"
              ></Box>
            </ModalBody>
          )}

          <ModalFooter>
            {
              <Button
                variant="ghost"
                mr={3}
                onClick={() => {
                  if (status === "active" && editPage) {
                    onClose();
                    setCampaignStateObj({});
                    // navHandler();
                  } else {
                    onClose();
                    activateCampaignHandler("draft");
                  }
                }}
              >
                {status === "active" ? "Cancel" : "Save as draft"}
              </Button>
            }
            <Button
              bgColor="#F0F5FF"
              color="#7211D4"
              rightIcon={<IoArrowForwardSharp />}
              onClick={() => activateCampaignHandler("active")}
              isLoading={activateLoader}
            >
              {editPage ? "Update" : "Create campaign"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {loading && editPage ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          h="50vh"
          alignItems="center"
          pt="20px"
        >
          <Image className="loading" src="/assets/logo.png" height="60px" />
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="70px"
            color="#000000"
            mt="-15px"
          >
            Fetching Campaign Details ...
          </Text>
        </Box>
      ) : (
        <Stack
          bgColor="#fff"
          padding="1.3em"
          borderRadius=".4em"
          spacing={4}
          mx={"22px"}
        >
          <Box
            borderBottom="1px solid"
            borderColor="gray.300"
            pb="1.3em"
            mx={"22px"}
          >
            <Heading fontSize="1em" color="#32325D">
              Custom Questions
            </Heading>
            <Text>
              Metricks asks affiliates how they intend to push your campaign.Add
              extra custom questions for your affiliates to answer before they
              join your campaign (optional)
            </Text>
          </Box>

          <Box>
            {/* @ts-ignore */}

            {questions !== null && (
              <FormBuilder
                initialData={questions}
                onChange={(data: any) => setQuestions(data)}
              />
            )}
          </Box>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            mb="2em"
            mt="1em"
            flexDirection={{ base: "column-reverse", lg: "row" }}
            rowGap=".6em"
          >
            <Button
              px="0"
              color="#5C14CE"
              bgColor="#fff"
              fontWeight="500"
              fontSize="14px"
              // width={{ md: "40%" }}
              _hover={{ bgColor: "#fff" }}
              _focus={{ bgColor: "#fff" }}
              onClick={backToPreviousHandler}
              leftIcon={<IoArrowBackSharp />}
            >
              Back to the previous page
            </Button>

            <Box
              width={{ base: "100%", lg: "40%" }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection={["column-reverse", "row"]}
              rowGap=".6em"
              justifySelf="flex-end"
            >
              <Box mr={["0em", "1em"]} width={["100%", "60%", "50%"]}>
                <BaseButton
                  text="Save and Exit"
                  textColor="#5C14CE"
                  bgColor="#F0F5FF"
                  clickEventHandler={saveAndExitHandler}
                />
              </Box>

              <Box mr={["0em", "1em"]} width={["100%", "60%", "50%"]}>
                <BaseButton
                  text={editPage ? "Update Campaign" : "Create Campaign"}
                  textColor="#fff"
                  bgColor="#5C14CE"
                  loadingState={loader}
                  rightIcon={<IoArrowForwardSharp />}
                  clickEventHandler={submitHandler}
                />
              </Box>
            </Box>
          </Flex>
        </Stack>
      )}
    </Box>
  );
};
