import { endpoints } from "../utils/endpoints";

export const uploadPhoto = async (file: any) => {
  const formData = new FormData();
  formData.append("photo", file);
  const response = await fetch(endpoints.photo.UPLOAD, {
    method: "POST",
    body: formData,
  });

  const res = await response.json();

  return res?.data?.url;
};
