import { Box, Input, Text } from "@chakra-ui/react";
import React from "react";

const LeadFormField = ({ label, value }: { label: string; value: string }) => {
  return (
    <Box className="flex w-full">
      <Text
        color="#32325D"
        fontWeight="600"
        fontSize="0.9rem"
        w="35%"
        mr="24px"
      >
        {label}
      </Text>

      <Input
        disabled={true}
        _disabled={{ opacity: 1 }}
        value={value}
        bg="#F1F4FA"
        color="#32325D"
        rounded="8px"
        border="0.6px solid rgba(50, 50, 93, 0.30)"
        flexGrow="1"
        pt="12px"
        pb="16px"
        fontSize="0.9rem"
      />
    </Box>
  );
};

export default LeadFormField;
