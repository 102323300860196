import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import SettingsInput from "./SettingsInput";

import { useOutsideAlerter } from "../../../hooks/useOutsideAlerter";
import { MdCancel } from "react-icons/md";
import useUserToken from "../../../hooks/useUserToken";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { BsCheckSquareFill, BsFillCaretUpFill, BsSearch } from "react-icons/bs";

const ManagersForm = ({
  isOpen,
  onClose,
  formData,
  campaigns,
  type,
  setRefresh,
}: {
  isOpen: boolean;
  onClose: () => void;
  formData?: { [key: string]: any };
  type: string;
  campaigns: any[];
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [firstName, setfirstName] = useState(formData?.firstName || "");
  const [lastName, setlastName] = useState(formData?.lastName || "");
  const [email, setemail] = useState(formData?.email || "");
  console.log("formdata", formData);
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [processing, setprocessing] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [campaignsList, setCampaignsList] = useState(campaigns);

  const [selectedItem, selectedItems]: any = useState(
    formData?.campaigns.map((item2: any) => {
      return campaigns.find((item1: any) => item1.id == item2);
    }) || []
  );
  const handleSelect = (item: any) => {
    console.log("selectedCap", selectedItem);
    // const isSelected = selectedItem.includes(item);
    const isSelected = selectedItem.some(
      (selectedItemData: any) => selectedItemData.id === item.id
    );
    // setSelectAll(false);
    // console.log(isSelected);
    if (isSelected) {
      // If item is already selected, remove it from the selectedItems array
      selectedItems((prevSelectedItems: any) =>
        prevSelectedItems.filter(
          (selectedItem: any) => selectedItem.id !== item.id
        )
      );
    } else {
      // If item is not selected, add it to the selectedItems array
      selectedItems((prevSelectedItems: any) => [...prevSelectedItems, item]);
    }
  };
  const toggleHandler = () => {
    return setShowCampaigns(!showCampaigns);
  };

  const token = useUserToken();
  const toast = useToast();

  const validate = (type?: string) => {
    let filteredCamps = campaignsList.filter(
      (campaign: any) => campaign.selected
    );

    if (!selectedItem.length)
      return {
        valid: false,
        error: "You must select at least one campaign for the manager",
      };

    if (!email)
      return {
        valid: false,
        error: "Manager's email is required",
      };

    if (type) {
      if (!firstName && type === "update")
        return {
          valid: false,
          error: "Manager's first name is required",
        };

      if (!lastName && type === "update")
        return {
          valid: false,
          error: "Manager's last name is required",
        };
    }

    return {
      valid: true,
      error: "",
    };
  };

  const handleClose = () => {
    setemail("");
    selectedItems([]);
    onClose();
  };

  const inviteManager = () => {
    let bearerAuth = token;
    setprocessing(true);
    const validation = validate();

    if (!validation.valid) {
      setprocessing(false);
      return toast({
        title: "Failed Invite New Manager",
        description: validation.error,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }

    const selectedCampaigns = campaignsList.filter(
      (campaign: any) => campaign.selected
    );

    const payload = {
      campaignIds: selectedItem.map((campaign: any) => campaign?.id) || [],
      email: email,
    };

    fetch(process.env.REACT_APP_API_ENDPOINT + `invite/manager`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          handleClose();
          setprocessing(false);

          return toast({
            title: "An Invite has been sent to the manager",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          setprocessing(false);
          return toast({
            title: "Failed invite new manager",
            description: data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      });
  };

  const updateManager = () => {
    // now
    let bearerAuth = token;
    setprocessing(true);

    const validation = validate("update");

    if (!validation.valid) {
      setprocessing(false);
      return toast({
        title: "Failed Invite New Manager",
        description: validation.error,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }

    const selectedCampaigns = campaignsList.filter(
      (campaign: any) => campaign.selected
    );

    const payload = {
      firstname: firstName,
      lastname: lastName,
      campaignIds: selectedItem.map((campaign: any) => campaign?.id) || [],
      email: email,
      permissionId: formData?.permissionId || 0,
      invitedBy: formData?.invitedBy || 0,
    };
    console.log("payload", payload);

    fetch(process.env.REACT_APP_API_ENDPOINT + `edit/manager`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          handleClose();
          setRefresh(Math.random() * 300);
          setprocessing(false);

          return toast({
            title: "Manager has been updated successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          setprocessing(false);

          return toast({
            title: "Failed to update manager",
            description: data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      });
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setShowCampaigns(false));

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size="xl" isCentered={true}>
        <ModalOverlay />
        <ModalContent
          borderRadius="1em"
          p=".8em"
          mx={{ base: ".6em", md: "auto" }}
        >
          <Box
            width=".8em"
            height="100%"
            position="absolute"
            left="0"
            bgGradient="linear(360deg, #7211D4 -1.38%, #FA9445 98.36%)"
            top="0"
            borderLeftRadius="1em"
          ></Box>
          <ModalHeader mt="3rem" color="#32325D" fontWeight="500">
            {type === "Edit" ? "EDIT AFFILATE MANAGER" : "ADD AFFILATE MANAGER"}
          </ModalHeader>

          <ModalBody>
            {type === "Edit" ? (
              <SettingsInput
                label="First Name"
                isRequired={true}
                type="text"
                changeHandler={setfirstName}
                value={firstName}
              />
            ) : (
              ""
            )}

            {type === "Edit" ? (
              <SettingsInput
                label="Last Name"
                isRequired={true}
                type="text"
                changeHandler={setlastName}
                value={lastName}
              />
            ) : (
              ""
            )}

            <SettingsInput
              label="Email Address"
              isRequired={true}
              type="text"
              changeHandler={setemail}
              value={email}

              // isDisabled={type === "Edit" ? true : false}
            />

            <Flex
              justifyContent="space-between"
              columnGap="1em"
              my="1em"
              flexDirection={["column", "row"]}
              borderTop={["0px", "0.5px solid"]}
              borderTopColor={{ md: "rgba(50, 50, 93, 0.30)" }}
              py={[".5em", "1em"]}
            >
              <Text
                fontSize="14px"
                color="#32325D"
                fontWeight="500"
                flexGrow={1}
                width={{ base: "100%", md: "40%" }}
                textAlign="left"
                mb={[".3em", "0em"]}
              >
                Campaigns
              </Text>

              <Box width={{ base: "100%" }}>
                <Flex w="100%" columnGap=".5em" alignItems="center">
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    border="0.6px solid"
                    borderColor="rgba(50, 50, 93, 0.30)"
                    borderRadius="8px"
                    p=".9em"
                    mb="1em"
                    cursor={"pointer"}
                    // onClick={toggleHandler}
                    ref={wrapperRef}
                    onClick={toggleHandler}
                  >
                    <Flex columnGap=".5em" rowGap=".5em" flexWrap="wrap">
                      {selectedItem.length >= 1 ? (
                        selectedItem.map((campaign: any, i: any) => {
                          return (
                            <Box
                              key={i}
                              bgColor="#F0F5FF"
                              py={["3px", "3px", "6px"]}
                              px=".4em"
                              alignItems="flex-start"
                              display="flex"
                              borderRadius="4px"
                              onClick={toggleHandler}
                            >
                              <Text
                                mr=".8em"
                                fontSize="14px"
                                fontWeight="400"
                                color="gray.800"
                              >
                                {campaign.name || ""}
                              </Text>
                              <MdCancel
                                color="#000"
                                cursor="pointer"
                                onClick={() => {
                                  // const newState = campaignsList.map(
                                  //   (data: any, x: any) => {
                                  //     if (campaignsList[i] == data) {
                                  //       return { ...data, selected: false };
                                  //     } else {
                                  //       return data;
                                  //     }
                                  //   }
                                  // );
                                  // setCampaignsList(newState);
                                  handleSelect(campaign);
                                }}
                              />
                            </Box>
                          );
                        })
                      ) : (
                        <Text>Select Campaign</Text>
                      )}
                    </Flex>

                    <Box>
                      {!showCampaigns ? (
                        <IoChevronDown
                          cursor={"pointer"}
                          onClick={toggleHandler}
                        />
                      ) : (
                        <IoChevronUp
                          cursor={"pointer"}
                          onClick={toggleHandler}
                        />
                      )}
                    </Box>
                  </Box>
                </Flex>

                {showCampaigns && (
                  <Box
                    p={["1em", "1em", "1.5em"]}
                    my="1em"
                    boxShadow="xl"
                    rounded="xl"
                    maxH="40vh"
                    overflowY="scroll"
                    ref={wrapperRef}
                  >
                    <Flex
                      alignItems="flex-start"
                      justifyContent="space-between"
                      my=".2em"
                    >
                      <Text fontSize="14px" color="gray.400" fontWeight="400">
                        Search Campaign
                      </Text>

                      <BsFillCaretUpFill
                        color="#CACBD4"
                        cursor="pointer"
                        onClick={toggleHandler}
                      />
                    </Flex>

                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<BsSearch color="gray.300" />}
                      />
                      <Input
                        type="text"
                        _focus={{ ring: "0" }}
                        border="0"
                        bgColor="#F4F6F8"
                        value={searchQuery}
                        onChange={(e: React.ChangeEvent<any>) =>
                          setSearchQuery(e.target.value)
                        }
                      />
                    </InputGroup>

                    {campaignsList.map((campaign: any, i: any) => {
                      return (
                        <Box
                          key={i}
                          display={
                            searchQuery !== "" &&
                            !campaign.name
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                              ? "none"
                              : "flex"
                          }
                          justifyContent="space-between"
                          alignItems="flex-start"
                          my="1em"
                          cursor="pointer"
                          _hover={{ bgColor: "gray.50" }}
                          onClick={() => {
                            // const newState = campaignsList.map(
                            //   (data: any, x: any) => {
                            //     if (campaignsList[i] == data) {
                            //       return { ...data, selected: !data.selected };
                            //     } else {
                            //       return data;
                            //     }
                            //   }
                            // );
                            // setCampaignsList(newState);
                            handleSelect(campaign);
                          }}
                          p=".5em"
                          borderRadius="3px"
                        >
                          <Text>{campaign.name}</Text>

                          {selectedItem.some(
                            (selectedItemData: any) =>
                              selectedItemData.id === campaign.id
                          ) ? (
                            <BsCheckSquareFill color="#5C15CE" />
                          ) : (
                            <Box
                              width="1em"
                              height="1em"
                              border="1.5px solid gray"
                              borderRadius="4px"
                            ></Box>
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} px="2em" onClick={handleClose}>
              Cancel
            </Button>
            {type === "Edit" ? (
              <Button
                bgColor="#F0F5FF"
                color="#7211D4"
                px="2em"
                disabled={selectedItem.length === 0 ? true : false}
                isLoading={processing}
                onClick={updateManager}
              >
                Update
              </Button>
            ) : (
              <Button
                bgColor="#F0F5FF"
                color="#7211D4"
                px="2em"
                isLoading={processing}
                disabled={selectedItem.length === 0 ? true : false}
                onClick={inviteManager}
              >
                Add New Manager
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ManagersForm;
