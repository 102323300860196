import { useEffect, useState } from "react";
import useUserToken from "./useUserToken";

export const useCurrentOrg = () => {
  const [currentOrgData, setcurrentOrgData] = useState<any>({});
  const [isVerified, setisVerified] = useState<boolean>(false);
  const token = useUserToken();

  const fetchOrgData = () => {
    let bearerAuth = token;

    fetch(process.env.REACT_APP_API_ENDPOINT + `/auth/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setcurrentOrgData(data.data.user.organisation);
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
        }
      })
      .catch((err) => {
        console.log("org data: => " + err);
      });
  };

  const checkIfRegISComplete = () => {
    if (!currentOrgData) return setisVerified(false);

    if (
      currentOrgData.country &&
      currentOrgData.city &&
      currentOrgData.state &&
      currentOrgData.country &&
      currentOrgData.zip_code &&
      currentOrgData.companyname &&
      currentOrgData.address &&
      currentOrgData.assetType &&
      currentOrgData.productType &&
      currentOrgData.niche &&
      currentOrgData.countriesToShip &&
      currentOrgData.website
    )
      return setisVerified(true);

    return setisVerified(false);
  };

  useEffect(() => {
    fetchOrgData();
  }, []);

  useEffect(() => {
    checkIfRegISComplete();
  }, [currentOrgData]);

  return [currentOrgData, isVerified];
};

export const useCurrentAffiliateOrg = () => {
  const [currentOrgData, setcurrentOrgData] = useState<any>({});
  const [isVerified, setisVerified] = useState<boolean>(false);
  const token = useUserToken("affiliate");

  const fetchOrgData = () => {
    let bearerAuth = token;

    fetch(process.env.REACT_APP_API_ENDPOINT + `/auth/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setcurrentOrgData(data.data.user.organisation);
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
        }
      })
      .catch((err) => {
        console.log("org data: => " + err);
      });
  };

  const checkIfRegISComplete = () => {
    if (!currentOrgData) return setisVerified(false);

    if (
      currentOrgData.affiliate_type &&
      currentOrgData.productType &&
      currentOrgData.niche &&
      currentOrgData.countriesToShip &&
      currentOrgData.affiliate_years &&
      currentOrgData.team_size
    )
      return setisVerified(true);

    return setisVerified(false);
  };

  useEffect(() => {
    fetchOrgData();
  }, []);

  useEffect(() => {
    checkIfRegISComplete();
  }, [currentOrgData]);

  return [currentOrgData, isVerified];
};
