import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";

import { MdCancel } from "react-icons/md";
import { useContext, useEffect, useRef, useState } from "react";
import {
  IoArrowBackSharp,
  IoArrowForwardSharp,
  IoChevronDown,
  IoChevronUp,
} from "react-icons/io5";
import { BsCheckSquareFill, BsFillCaretUpFill, BsSearch } from "react-icons/bs";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useCookies } from "react-cookie";
import { toast, ToastContainer } from "react-toastify";
import {
  getCurrentUserInstance,
  setLocalUserInstance,
} from "../../../state/localstorage";
import { industries } from "../../../utils/niche";
import { useOutsideAlerter } from "../../../hooks/useOutsideAlerter";
import { TemporaryNavData } from "../../context/TemporaryNavData";
import OrganizationSettingsFooter from "../components/OrganizationSettingsFooter";

export const Niche = ({
  currentStep,
  setCurrentStep,
  getOrgDetails,
  setrefreshData,
}: {
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>;
  currentStep: string;
  getOrgDetails: any;
  setrefreshData: React.Dispatch<React.SetStateAction<number>>;
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { shopify, setShopify } = useContext(TemporaryNavData);
  console.log("testing shopify", shopify);
  const [orgType, setOrgType] = useState(false);
  useEffect(() => {
    if (searchParams.get("type") !== null) {
      setOrgType(searchParams.get("type") ? true : false);
    }
    industries.forEach((item: any) => {
      console.log(item);
      if (localUser.organisation.niche.includes(item.niche)) {
        item.selected = true;
      }
    });
    console.log("here", industries);
    setNicheData(industries);
  }, [searchParams, orgType]);

  const [cookies] = useCookies(["user_id", "access_token"]);

  const localUser = getCurrentUserInstance();
  const [newUpdate, setnewUpdate] = useState(false);
  const [count, setcount] = useState(3);
  // localUser && console.log(localUser);

  const [searchQuery, setSearchQuery] = useState("");
  const [showIndustries, setShowIndustries] = useState(false);
  const [nicheData, setNicheData]: any = useState([]);

  const toggleHandler = () => {
    return setShowIndustries(!showIndustries);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setShowIndustries(false));

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [saveAndExit, setSaveAndExit] = useState(false);
  const updateObj = {
    niche: nicheData
      .filter((singleNiche: any) => singleNiche.selected)
      .map((data: any) => data.niche),
  };

  const validator = () => {
    if (
      nicheData.filter((singleNiche: any) => singleNiche.selected).length < 1
    ) {
      return {
        isValid: false,
        error: "Please complete all required fields or save and exit",
      };
    }

    return { isValid: true, error: "" };
  };
  const submitHandler = () => {
    if (
      nicheData.filter((singleNiche: any) => singleNiche.selected).length < 1
    ) {
      toast.warning("Please complete all required fields or save and exit");
      return;
    }

    const payload = {
      niche: nicheData
        .filter((singleNiche: any) => singleNiche.selected)
        .map((data: any) => data.niche),
    };

    setLoading(true);
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `${shopify ? "auth/signup/step5?shopify=true" : "auth/signup/step5"}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.access_token}`,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // navigate("/dashboard");
        console.log("niche data", data);
        setLoading(false);
        // console.log("step5", data);
        if (data.success) {
          setLocalUserInstance({ ...localUser, nicheData });
          const user = getCurrentUserInstance();
          user.organisation.signup_step = shopify ? 6 : 5;
          setLocalUserInstance(user);
          if (orgType) {
            saveAndExit
              ? window.open("https://www.Metricks.io", "_self")
              : navigate(
                  `${
                    shopify
                      ? "/dashboard"
                      : "/signup/billing?type=newOrganization"
                  }`
                );
          } else {
            saveAndExit
              ? window.open("https://www.Metricks.io", "_self")
              : navigate(`${shopify ? "/dashboard" : "/signup/billing"}`);
          }
        }
        console.log(data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const step = [
    {
      title: "User Profile",
      status: "passed",
      key: 1,
    },
    {
      title: "Company Profile",
      status: "passed",
      key: 2,
    },

    {
      title: "Marketing Assets",
      status: "passed",
      key: 3,
    },
    {
      title: "Product Details",
      status: "passed",
      key: 4,
    },
    {
      title: "Niche",
      status: "current",
      key: 5,
    },
    {
      title: "Billing",
      status: "default",
      key: 6,
    },
  ];
  const orgSteps = [
    {
      title: "Company Profile",
      status: "passed",
      key: 1,
    },

    {
      title: "Marketing Assets",
      status: "passed",
      key: 2,
    },
    {
      title: "Product Details",
      status: "passed",
      key: 3,
    },
    {
      title: "Niche",
      status: "current",
      key: 4,
    },
    {
      title: "Billing",
      status: "default",
      key: 5,
    },
  ];
  const steps = orgType ? orgSteps : step;

  const [selectedItem, selectedItems]: any = useState([]);
  const handleSelect = (item: any) => {
    console.log("selectedNiche", selectedItem), item;
    // const isSelected = selectedItem.includes(item);
    const isSelected = selectedItem.some(
      (selectedItemData: any) => selectedItemData === item
    );
    // setSelectAll(false);
    // console.log(isSelected);
    if (isSelected) {
      // If item is already selected, remove it from the selectedItems array
      selectedItems((prevSelectedItems: any) =>
        prevSelectedItems.filter((selectedItem: any) => selectedItem !== item)
      );
    } else {
      // If item is not selected, add it to the selectedItems array
      selectedItems((prevSelectedItems: any) => [...prevSelectedItems, item]);
    }
  };
  return (
    <>
      <ToastContainer />
      <Box>
        {/* <Heading fontSize="1rem" color="#32325D" fontWeight={"600"} pb="34px">
          {currentStep}
        </Heading> */}
        <Heading fontSize="1em" mb="1em">
          Which industy are you targeting?
          <span style={{ color: "red", marginLeft: 5 }}>*</span>
        </Heading>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          borderBottom="1px solid #B6BDC3"
          pb=".3em"
          mb="1em"
          cursor="pointer"
          onClick={toggleHandler}
          ref={wrapperRef}
        >
          <Flex columnGap=".5em" rowGap=".5em" flexWrap="wrap">
            {nicheData.filter((singleNiche: any) => singleNiche.selected)
              .length >= 1 &&
              nicheData
                .filter((singleNiche: any) => singleNiche.selected)
                .map(
                  (
                    singleNiche: { niche: string; selected: boolean },
                    i: any
                  ) => {
                    return (
                      <Box
                        key={i}
                        bgColor="#F0F5FF"
                        py={["3px", "3px", "6px"]}
                        px=".4em"
                        alignItems="center"
                        display="flex"
                        borderRadius="4px"
                      >
                        <Text
                          mr=".8em"
                          fontSize="14px"
                          fontWeight="400"
                          color="gray.800"
                        >
                          {singleNiche.niche}
                        </Text>
                        <MdCancel
                          color="#000"
                          cursor="pointer"
                          onClick={() => {
                            const newState = nicheData.map(
                              (data: any, x: any) => {
                                if (nicheData[i] == data) {
                                  return { ...data, selected: false };
                                } else {
                                  return data;
                                }
                              }
                            );
                            setNicheData(newState);
                          }}
                        />
                      </Box>
                    );
                  }
                )}
          </Flex>

          <Box>
            {!showIndustries ? (
              <IoChevronDown cursor="pointer" onClick={toggleHandler} />
            ) : (
              <IoChevronUp cursor="pointer" onClick={toggleHandler} />
            )}
          </Box>
        </Box>

        {showIndustries && (
          <Box
            p={["1em", "1em", "1.5em"]}
            my="1em"
            boxShadow="xl"
            rounded="xl"
            height="60vh"
            overflowY="scroll"
            ref={wrapperRef}
          >
            <Flex alignItems="center" justifyContent="space-between" my=".2em">
              <Text fontSize="14px" color="gray.400" fontWeight="400">
                Search Niche
              </Text>

              <BsFillCaretUpFill
                color="#CACBD4"
                cursor="pointer"
                onClick={toggleHandler}
              />
            </Flex>

            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<BsSearch color="gray.300" />}
              />
              <Input
                type="text"
                _focus={{ ring: "0" }}
                border="0"
                bgColor="#F4F6F8"
                value={searchQuery}
                onChange={(e: React.ChangeEvent<any>) =>
                  setSearchQuery(e.target.value)
                }
              />
            </InputGroup>

            {nicheData.map((singleNiche: any, i: any) => {
              return (
                <Box
                  key={i}
                  display={
                    searchQuery !== "" &&
                    !singleNiche.niche
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                      ? "none"
                      : "flex"
                  }
                  justifyContent="space-between"
                  alignItems="center"
                  my="1em"
                  cursor="pointer"
                  _hover={{ bgColor: "gray.50" }}
                  onClick={() => {
                    const newState = nicheData.map((data: any, x: any) => {
                      if (nicheData[i] == data) {
                        return { ...data, selected: !data.selected };
                      } else {
                        return data;
                      }
                    });
                    setNicheData(newState);
                  }}
                  p=".5em"
                  borderRadius="3px"
                >
                  <Text>{singleNiche.niche}</Text>

                  {singleNiche.selected ? (
                    <BsCheckSquareFill color="#5C15CE" />
                  ) : (
                    <Box
                      width="1em"
                      height="1em"
                      border="1.5px solid gray"
                      borderRadius="4px"
                    ></Box>
                  )}

                  {/* <Checkbox
                        onChange={(e) => {
                          if (e.target.checked) {
                            const newState = nicheData.map(
                              (data: any, x: any) => {
                                if (nicheData[i] == data) {
                                  return { ...data, selected: true };
                                } else {
                                  return data;
                                }
                              }
                            );
                            setNicheData(newState);
                          } else {
                            const newState = nicheData.map(
                              (data: any, x: any) => {
                                if (nicheData[i] == data) {
                                  return { ...data, selected: false };
                                } else {
                                  return data;
                                }
                              }
                            );
                            setNicheData(newState);
                          }
                        }}
                        mr=".2em"
                        defaultChecked={singleNiche.selected}
                        colorScheme="purple"
                      >
                        {""}
                      </Checkbox> */}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>

      <Flex mt={300}></Flex>
      <OrganizationSettingsFooter
        setRefreshData={setrefreshData}
        hasUpdate={newUpdate}
        sethasUpdate={setnewUpdate}
        validator={validator}
        dataToUpdate={updateObj}
        getOrgDetails={getOrgDetails}
      />
    </>
  );
};
