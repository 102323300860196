import { apiReqHandler } from "../helper/apiReqHandler";
import { endpoints } from "../utils/endpoints";
import { getCookie } from "cookies-next";

export const fetchCampaign = async ({ campaignId }: { campaignId: string }) => {
  const { res } = await apiReqHandler({
    endPoint: endpoints.campaign.FETCH_ONE(campaignId),
    method: "GET",

    //   @ts-ignore
    bearerAuth: getCookie("access_token"),
  });

  const campaignData: any = res?.data?.data?.campaign;

  return { campaignData };
};
