import React from "react";
import Notifications from "../../components/notifications";
const AffiliateNotification = () => {
  return (
    <>
      <Notifications />
    </>
  );
};

export default AffiliateNotification;
