import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import { IoArrowBackSharp, IoArrowForwardSharp } from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseButton } from "../button";
import { StepWrapper } from "./StepWrapper";
import React, { useContext, useEffect, useMemo, useState } from "react";
// @ts-ignore
import useResponsiveFontSize from "../../hooks/useResponsiveFontSize";

import {
  Elements,
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { toast, ToastContainer } from "react-toastify";
import { useCookies } from "react-cookie";
import {
  getCurrentUserInstance,
  setCurrentUserType,
  setLocalUserInstance,
} from "../../state/localstorage";
import { TemporaryNavData } from "../context/TemporaryNavData";
import { v4 as uuidv4 } from "uuid";
import { FaStarOfLife } from "react-icons/fa";
import { RiArrowRightFill } from "react-icons/ri";

const CARD_ELEMENT_OPTIONS = {
  showIcon: true,
  style: {
    base: {
      color: "#282838",
      border: "1px solid #D1D1D1",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

declare global {
  interface Window {
    Metricks: any;
  }
}

export const Billing = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { shopify, setShopify } = useContext(TemporaryNavData);
  console.log("shopify", shopify);
  const [orgType, setOrgType] = useState(false);
  useEffect(() => {
    // Add your script here
    const script = document.createElement("script");
    script.innerHTML = `
    gtag('event', 'conversion', {'send_to': 'AW-16468569519/59ZECOrg8pcZEK_b6aw9'});
    `;
    document.head.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);
  useEffect(() => {
    if (searchParams.get("type") !== null) {
      setOrgType(searchParams.get("type") ? true : false);
    }
  }, [searchParams, orgType]);
  const [cookies] = useCookies(["user_id", "access_token"]);
  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const submitHandler = async (event: React.ChangeEvent<any>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);
    const card = elements.getElement(CardNumberElement);
    // @ts-ignore
    const result = await stripe.createToken(card);
    // console.log("result", result);
    if (result.error) {
      toast.error(result.error.message);
      setLoading(false);
    } else {
      // Send the token to your server.
      console.log(result);

      const payload = {
        source: result.token.id,
        // @ts-ignore
        card_id: result.token.card.id,
        plan: "basic",
      };
      // console.log("payload", payload);

      let orderId = uuidv4();
      await window.Metricks.conversion({
        order_id: orderId,
        product_id: "",
        amount: 59,
        currency: "usd",
      });

      fetch(process.env.REACT_APP_API_ENDPOINT + "billing/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.access_token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);

          if (!data.success) {
            toast.warning(data.message);
            return;
          }
          setCurrentUserType("advertiser");
          const user = getCurrentUserInstance();
          user.organisation.signup_step = 6;
          setLocalUserInstance(user);
          navigate("/dashboard");
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };
  const step = [
    {
      title: "User Profile",
      status: "passed",
      key: 1,
    },
    {
      title: "Company Profile",
      status: "passed",
      key: 2,
    },

    {
      title: "Marketing Assets",
      status: "passed",
      key: 3,
    },
    {
      title: "Product Details",
      status: "passed",
      key: 4,
    },
    {
      title: "Niche",
      status: "passed",
      key: 5,
    },
    {
      title: "Billing",
      status: "current",
      key: 6,
    },
  ];
  const orgSteps = [
    {
      title: "Company Profile",
      status: "passed",
      key: 1,
    },

    {
      title: "Marketing Assets",
      status: "passed",
      key: 2,
    },
    {
      title: "Product Details",
      status: "passed",
      key: 3,
    },
    {
      title: "Niche",
      status: "passed",
      key: 4,
    },
    {
      title: "Billing",
      status: "current",
      key: 5,
    },
  ];
  const steps = orgType ? orgSteps : step;
  return (
    <StepWrapper
      step={steps.map((item: any) => {
        if (item.status === "current") {
          let step = `STEP ${item.key} OF ${steps.length}`;
          return step;
        }
      })}
      header="Setting up your advertiser account"
      baseText="Billing"
      stepsMap={steps}
    >
      <Flex
        position={"relative"}
        direction={"column"}
        alignItems={"center"}
        w={["90%", "60%"]}
        // backgroundColor={"red"}
      >
        <Flex w={"100%"} direction={"column"}>
          <ToastContainer />
          <Heading fontSize="1.4em" mb=".5em">
            Confirm your 14 days trial
          </Heading>
          <Text color="#737373" fontSize={"1.3em"} fontWeight={700}>
            Your account will not be charged today, your first charge is in 14
            days. You can cancel at anytime.
          </Text>
          <Box py={["3em", "3em"]}>
            <form onSubmit={submitHandler}>
              <Flex direction={"column"} gap={10}>
                <label style={{ fontWeight: "500", marginTop: "1em" }}>
                  <Flex gap={1}>
                    <Text>Card number </Text>
                    <FaStarOfLife fontSize={"5px"} color="red" />
                  </Flex>
                  <Box
                    w="full"
                    borderColor="#c4c4c4"
                    borderRadius="5px"
                    borderWidth="1px"
                    p={4}
                  >
                    <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                  </Box>
                </label>

                <label style={{ fontWeight: "500" }}>
                  <Flex gap={1}>
                    <Text>Expiration month and year</Text>
                    <FaStarOfLife fontSize={"5px"} color="red" />
                  </Flex>
                  <Box
                    w="full"
                    borderColor="#c4c4c4"
                    borderRadius="5px"
                    borderWidth="1px"
                    p={4}
                  >
                    <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                  </Box>
                </label>

                <label style={{ fontWeight: "500" }}>
                  <Flex gap={1}>
                    <Text>CVC</Text>
                    <FaStarOfLife fontSize={"5px"} color="red" />
                  </Flex>
                  <Box
                    w="full"
                    borderColor="#c4c4c4"
                    borderRadius="5px"
                    borderWidth="1px"
                    p={4}
                  >
                    <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                  </Box>
                </label>
              </Flex>

              <Flex
                alignItems="center"
                justifyContent="space-between"
                mb="1em"
                mt={["100px", "200px"]}
                flexDirection={{ base: "column-reverse", md: "row" }}
                rowGap=".6em"
              >
                <Button
                  p="0"
                  color="#5C14CE"
                  bgColor="#fff"
                  fontWeight="500"
                  fontSize="14px"
                  // width={{ md: "40%" }}
                  _hover={{ bgColor: "#fff" }}
                  _focus={{ bgColor: "#fff" }}
                  onClick={() => navigate("/signup/niche")}
                  leftIcon={<IoArrowBackSharp />}
                >
                  Back to the previous step
                </Button>

                {/* <Button
                disabled={!stripe}
                bgColor="#5C15CE"
                color="#fff"
                borderRadius=".4em"
                // px="2em"
                width={["100%", "40%"]}
                fontWeight="500"
                fontSize="14px"
                type="submit"
                isLoading={loading}
              >
                Create account
              </Button> */}
                <Box width={["100%", "30%", "30%"]}>
                  <BaseButton
                    text="Create account"
                    textColor="#fff"
                    bgColor="#5C14CE"
                    loadingState={loading}
                    rightIcon={<RiArrowRightFill />}
                    clickEventHandler={submitHandler}
                  />
                </Box>
              </Flex>
            </form>
          </Box>
        </Flex>
      </Flex>
    </StepWrapper>
  );
};
