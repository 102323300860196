import React from "react";
import ExploreAdvertisers from "../components/explore";
import { Route, Routes } from "react-router-dom";
import ViewExplore from "../components/explore/explore-details";
import SelectCampaign from "../components/explore/select-campaigns";
import ViewCampaign from "../components/explore/explore-campaign-details";
import Affiliates from "../components/explore/affiliates";
import AffiliateDetails from "../components/explore/affiliate-details";

const Explore = () => {
  return (
    <Routes>
      <Route path="/" element={<ExploreAdvertisers />} />
      <Route path="/view" element={<ViewExplore />} />
      <Route path="/campaigns" element={<SelectCampaign />} />
      <Route path="/campaign" element={<ViewCampaign />} />
      <Route path="/affiliates" element={<Affiliates />} />
      <Route path="/affiliate" element={<AffiliateDetails />} />
    </Routes>
  );
};

export default Explore;
