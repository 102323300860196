import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Text,
  Tr,
  Th,
  Td,
  TableContainer,
  Image,
  Stack,
  Box,
  Button,
  useDisclosure,
  useMediaQuery,
  css,
  Flex,
} from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useUserToken from "../../../../hooks/useUserToken";
import { gsap, Power2 } from "gsap";
import Fuse from "fuse.js";

const CampaignTable = ({
  tabStatus,
  filterData,
  searchVal,
}: {
  tabStatus: string;
  filterData: any;
  searchVal: string;
}) => {
  const getStatusColor = (status: string) => {
    if (status === "approved") {
      return "#239654";
    } else if (status === "pending") {
      return "#F3994A";
    } else {
      return "#EB5757";
    }
  };

  const token: string = useUserToken("affiliate");
  const { isOpen, onOpen, onClose: onButtonClose } = useDisclosure();
  let [searchParams, _] = useSearchParams();
  const [loading, setloading] = useState(true);

  const [fetchedCampaigns, setfetchedCampaigns] = useState<any[]>([]);
  const [searchResult, setsearchResult] = useState<any[]>([]);
  const [unFilteredCampaigns, setunFilteredCampaigns] = useState<any[]>([]);

  const navigate = useNavigate();
  const options = {
    keys: ["campaign.campaign_name"],
  };
  const fuse = new Fuse(unFilteredCampaigns, options);

  const getCampaigns = async () => {
    setloading(true);
    let bearerAuth = token;

    let final_endpoint = "";

    if (searchParams.get("id") && searchParams.get("status")) {
      final_endpoint = `promote-campaign/affiliate/campaigns?status=${searchParams.get(
        "status"
      )}${filterData.date ? "&date=" + filterData.date : ""}${
        filterData.status ? "&campaignStatus=" + filterData.status : ""
      }`;
    } else {
      final_endpoint = `promote-campaign/affiliate/campaigns?status=${tabStatus}${
        filterData.date ? "&date=" + filterData.date : ""
      }${filterData.status ? "&campaignStatus=" + filterData.status : ""}`;
    }

    await fetch(process.env.REACT_APP_API_ENDPOINT + final_endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setfetchedCampaigns(data.data.campaigns);
          setloading(false);
        } else {
          setfetchedCampaigns([]);
          setloading(false);
        }
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const getUnFilteredCampaigns = async () => {
    let bearerAuth = token;

    let final_endpoint = "";

    if (searchParams.get("id") && searchParams.get("status")) {
      final_endpoint = `promote-campaign/affiliate/campaigns?status=${searchParams.get(
        "status"
      )}`;
    } else {
      final_endpoint = `promote-campaign/affiliate/campaigns?status=${tabStatus}`;
    }

    await fetch(process.env.REACT_APP_API_ENDPOINT + final_endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setunFilteredCampaigns(data.data.campaigns);
        } else {
          // @ts-ignore
        }
      })
      .catch((err) => {});
  };

  const checkForNoResults = () => {
    if (loading) return false;
    if (searchVal && !searchResult.length) return true;
    if (fetchedCampaigns.length === 0 && !loading) return true;
    return false;
  };

  const renderNoResultValue = () => {
    if (!filterData.date && !filterData.status && searchVal) {
      if (tabStatus === "pending") {
        return (
          'No pending request to promote a campaign with the name "' +
          searchVal +
          '"'
        );
      } else if (tabStatus === "approved") {
        return (
          'No campaign with the name "' +
          searchVal +
          '" has been approved for you to promote'
        );
      } else if (tabStatus === "suspended") {
        return 'No campaign with the name "' + searchVal + '" is suspended';
      } else {
        return (
          'No request to promote a campaign with the name "' +
          searchVal +
          '" has been declined'
        );
      }
    } else if (filterData.date && !filterData.status && !searchVal) {
      let dayText = filterData.date.split("_").join(" ");
      if (tabStatus === "pending") {
        return (
          "No pending request to promote a campaign was made in the " + dayText
        );
      } else if (tabStatus === "approved") {
        return "No campaign has been approved for promotion in the " + dayText;
      } else if (tabStatus === "suspended") {
        return "No approved campaign has been suspended in the " + dayText;
      } else {
        return (
          "No request to promote campaign has been declined in the " + dayText
        );
      }
    } else if (!filterData.date && filterData.status && !searchVal) {
      return "No campaign approved for you to promote is " + filterData.status;
    } else {
      if (tabStatus === "pending") {
        return "No pending request to promote a campaign";
      } else if (tabStatus === "approved") {
        return "No campaign has been approved for you to promote";
      } else if (tabStatus === "suspended") {
        return "No approved campaign has been suspended";
      } else {
        return "No request to promote campaign has been declined";
      }
    }
  };

  useEffect(() => {
    getCampaigns();
  }, [tabStatus, filterData]);

  useEffect(() => {
    gsap.fromTo(
      ".loading",
      {
        opacity: 0,
        ease: Power2.easeInOut,
        scale: 0,
        y: 80,
      },
      {
        opacity: 1,
        duration: 1.5,
        scale: 1,
        ease: Power2.easeInOut,
        repeat: -1,
        y: 0,
        yoyo: true,
      }
    );
  }, []);

  useEffect(() => {
    getUnFilteredCampaigns();
  }, [tabStatus]);

  useEffect(() => {
    setsearchResult(fuse.search(searchVal).map((item) => ({ ...item.item })));
  }, [searchVal]);

  const titles = ["S/N", "CAMPAIGN", "EARNINGS", "STATUS", "ACTION"];
  const [isDesktop] = useMediaQuery("(min-width: 768px)");

  return (
    <Box
      mt="8px"
      fontStyle="normal"
      css={
        isDesktop
          ? {}
          : css({
              "::-webkit-scrollbar": { display: "none" },
              "-webkit-overflow-scrolling": "touch",
            })
      }
      overflowX={{ base: "scroll", md: "visible" }}
      maxWidth="100%"
      bgColor="#ffffff"
      borderRadius="8px"
    >
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          pt="20px"
        >
          <Image className="loading" src="/assets/logo.png" height="60px" />
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="70px"
            color="#000000"
            mt="-15px"
          >
            Loading....
          </Text>
        </Box>
      ) : (
        <Table>
          {!checkForNoResults() && (
            <Thead>
              <Tr height="53px">
                {titles.map((title) => {
                  return (
                    <Th
                      color="rgba(50, 50, 93, 0.8)"
                      textTransform="uppercase"
                      lineHeight="18px"
                      fontSize="12px"
                      fontWeight="500"
                      key={title}
                    >
                      {title}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
          )}

          {searchVal ? (
            <Tbody>
              {searchResult.map((item: any, index: number) => {
                return (
                  <Tr
                    key={item.id}
                    height="80px"
                    bgColor={`${
                      item.campaignId == searchParams.get("id")
                        ? "purple.100"
                        : ""
                    }`}
                  >
                    <Td>
                      <Text
                        color="#32325D"
                        lineHeight={{ base: "18px", md: "21px" }}
                        fontSize={{ base: "12px", md: "15px" }}
                        fontWeight="500"
                      >
                        {index + 1}.
                      </Text>
                    </Td>
                    <Td>
                      <Text
                        color="#32325D"
                        lineHeight={{ base: "18px", md: "21px" }}
                        fontSize={{ base: "12px", md: "15px" }}
                        fontWeight="500"
                      >
                        {item?.campaign.campaign_name}
                      </Text>
                    </Td>

                    <Td>
                      <Text
                        color="#32325D"
                        lineHeight={{ base: "18px", md: "21px" }}
                        fontSize={{ base: "12px", md: "15px" }}
                        fontWeight="500"
                      >
                        ${item?.earnings || 0}
                      </Text>
                    </Td>
                    <Td>
                      <Box display="flex" alignItems="center">
                        <Box
                          background={getStatusColor(item.status)}
                          height="12px"
                          width="12px"
                          borderRadius="50%"
                        ></Box>
                        <Text
                          color="#32325D"
                          lineHeight={{ base: "18px", md: "21px" }}
                          fontSize={{ base: "12px", md: "15px" }}
                          fontWeight="500"
                          marginLeft="8px"
                          textTransform="capitalize"
                        >
                          {item.status}
                        </Text>
                      </Box>
                    </Td>

                    <Td>
                      <Box display="flex" alignItems="center">
                        {item.status === "approved" && (
                          <Button
                            bgColor="transparent"
                            mr="20px"
                            border=" 1px solid rgba(50, 50, 93, 0.4)"
                            borderRadius="8px"
                            fontWeight="500"
                            fontSize={{ base: "12px", md: "14px" }}
                            lineHeight={{ base: "18px", md: "21px" }}
                            color="#32325D"
                            p={5}
                            isDisabled={true}
                          >
                            <Flex gap={3} align={"center"}>
                              <Image
                                mr="7px"
                                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/7c0491c8-88dc-4ed0-b604-50804239f2a9.svg"
                              />
                              <Text>Report</Text>
                              <Text
                                bgColor={"#f9f5fd"}
                                color={"#7211D4"}
                                px={1}
                                py={0.5}
                              >
                                Coming Soon
                              </Text>
                            </Flex>
                          </Button>
                        )}

                        <Button
                          bgColor="transparent"
                          border=" 1px solid rgba(50, 50, 93, 0.4)"
                          borderRadius="8px"
                          fontWeight=" 500"
                          fontSize={{ base: "12px", md: "14px" }}
                          lineHeight={{ base: "18px", md: "21px" }}
                          color=" #7211D4"
                          onClick={() => {
                            if (
                              item.status !== "approved" &&
                              item.status !== "suspended"
                            ) {
                              return navigate(
                                `/affiliate-explore/view/${item.campaignId}`
                              );
                            } else {
                              return navigate(
                                `/affiliate-campaigns/view/${item.campaignId}?ref=${item.affiliateLink}`
                              );
                            }
                          }}
                        >
                          <Image
                            mr="7px"
                            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/f5d72c76-51c1-41ff-ae9c-56dcafc8ec72.svg"
                          />
                          View
                        </Button>
                      </Box>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          ) : (
            <Tbody>
              {fetchedCampaigns.map((item: any, index: number) => {
                return (
                  <Tr
                    key={item.id}
                    height="80px"
                    bgColor={`${
                      item.campaignId == searchParams.get("id")
                        ? "purple.100"
                        : ""
                    }`}
                  >
                    <Td>
                      <Text
                        color="#32325D"
                        lineHeight={{ base: "18px", md: "21px" }}
                        fontSize={{ base: "12px", md: "15px" }}
                        fontWeight="500"
                      >
                        {index + 1}.
                      </Text>
                    </Td>
                    <Td>
                      <Text
                        color="#32325D"
                        lineHeight={{ base: "18px", md: "21px" }}
                        fontSize={{ base: "12px", md: "15px" }}
                        fontWeight="500"
                      >
                        {item?.campaign.campaign_name}
                      </Text>
                    </Td>

                    <Td>
                      <Text
                        color="#32325D"
                        lineHeight={{ base: "18px", md: "21px" }}
                        fontSize={{ base: "12px", md: "15px" }}
                        fontWeight="500"
                      >
                        ${item?.earnings || 0}
                      </Text>
                    </Td>
                    <Td>
                      <Box display="flex" alignItems="center">
                        <Box
                          background={getStatusColor(item.status)}
                          height="12px"
                          width="12px"
                          borderRadius="50%"
                        ></Box>
                        <Text
                          color="#32325D"
                          lineHeight={{ base: "18px", md: "21px" }}
                          fontSize={{ base: "12px", md: "15px" }}
                          fontWeight="500"
                          marginLeft="8px"
                          textTransform="capitalize"
                        >
                          {item.status}
                        </Text>
                      </Box>
                    </Td>

                    <Td>
                      <Box display="flex" alignItems="center">
                        {item.status === "approved" && (
                          <Button
                            bgColor="transparent"
                            mr="20px"
                            border=" 1px solid rgba(50, 50, 93, 0.4)"
                            borderRadius="8px"
                            fontWeight="500"
                            fontSize={{ base: "12px", md: "14px" }}
                            lineHeight={{ base: "18px", md: "21px" }}
                            color="#32325D"
                            p={5}
                            isDisabled={true}
                          >
                            <Flex gap={3} align={"center"}>
                              <Image
                                mr="7px"
                                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/7c0491c8-88dc-4ed0-b604-50804239f2a9.svg"
                              />
                              <Text>Report</Text>
                              <Text
                                bgColor={"#f9f5fd"}
                                color={"#7211D4"}
                                px={1}
                                py={0.5}
                              >
                                Coming Soon
                              </Text>
                            </Flex>
                          </Button>
                        )}

                        <Button
                          bgColor="transparent"
                          border=" 1px solid rgba(50, 50, 93, 0.4)"
                          borderRadius="8px"
                          fontWeight=" 500"
                          fontSize={{ base: "12px", md: "14px" }}
                          lineHeight={{ base: "18px", md: "21px" }}
                          color=" #7211D4"
                          onClick={() => {
                            if (
                              item.status !== "approved" &&
                              item.status !== "suspended"
                            ) {
                              return navigate(
                                `/affiliate-explore/view/${item.campaignId}`
                              );
                            } else {
                              return navigate(
                                `/affiliate-campaigns/view/${item.campaignId}?ref=${item.affiliateLink}`
                              );
                            }
                          }}
                        >
                          <Image
                            mr="7px"
                            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/f5d72c76-51c1-41ff-ae9c-56dcafc8ec72.svg"
                          />
                          View
                        </Button>
                      </Box>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
      )}

      {checkForNoResults() && (
        <Box
          margin="0px auto"
          p="20px"
          // background={{ base: "white", md: "#F0F5FF" }}
          maxWidth="100%"
        >
          <Image
            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
            height={{ base: "150px", md: "250px" }}
            margin="0px auto"
            objectFit="contain"
            mb="8px"
          />

          <Text
            fontWeight="600"
            fontSize="12px"
            lineHeight="22px"
            color="#32325D"
            textAlign="center"
          >
            {renderNoResultValue()}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default CampaignTable;
