import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
} from "@chakra-ui/react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Account } from "./Account";
import { Billing } from "./Billing";
import { getCurrentUserInstance } from "../../state/localstorage";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

//! Make sure to call `loadStripe` outside of a component's render to avoid
//! recreating the `Stripe` object on every render.
// @ts-ignore
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const AccountSettingsWrapper = () => {
  const localUser = getCurrentUserInstance();
  const [cookies] = useCookies();

  const [showBilling, setShowBilling] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);

  const getEditProfileState = (state: boolean) => {
    setHideHeader(state);
  };
  console.log("tokeen", localUser.organisation.shopify_access_token);
  return (
    <>
      <Box
        // boxShadow="xl"
        // bgColor="#fff"
        borderRadius={["0.4em", ".6em"]}
        ml={["0em", "1.5em"]}
        my="2em"
        // py="1.5em"
        width={["90%", "88%", "92%", "95%"]}
        mx="auto"
        // pr={[0, 5]}
        pt={[0, 30]}
        pb={[0, 5]}
      >
        {!hideHeader && (
          <Flex
            columnGap="1.2em"
            borderBottom="1px solid"
            borderBottomColor="gray.300"
            // px={["1em", "1.5em", "2em"]}
            pl={{ md: "1.5em", lg: "2em" }}
            justifyContent={"flex-start"}
            bgColor="#fff"
            pt="1.5em"
            borderTopRadius=".6em"
          >
            <Heading
              fontSize="1em"
              fontWeight="300"
              cursor="pointer"
              py=".5em"
              px=".8em"
              borderBottom={!showBilling ? "3px solid #7211D4" : "none"}
              _hover={{ borderBottom: "3px solid #7211D4" }}
              onClick={() => {
                setShowBilling(false);
              }}
            >
              Profile
            </Heading>
            {!localUser.organisation.shopify_access_token && (
              <Heading
                fontSize="1em"
                fontWeight="300"
                cursor="pointer"
                p=".5em"
                py=".5em"
                px=".8em"
                borderBottom={showBilling ? "3px solid #7211D4" : "none"}
                _hover={{ borderBottom: "3px solid #7211D4" }}
                onClick={() => setShowBilling(true)}
              >
                Account Billing
              </Heading>
            )}
          </Flex>
        )}

        {!showBilling ? (
          <Account
            getEditProfileState={getEditProfileState}
            accessToken={cookies.access_token}
            userInstance={localUser}
          />
        ) : (
          <Elements stripe={stripePromise}>
            <Billing
              accessToken={cookies.access_token}
              userInstance={localUser}
            />
          </Elements>
        )}
      </Box>
    </>
  );
};
