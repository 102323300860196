import React, { useState } from "react";
import {
  Avatar,
  Box,
  Image,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {
  getCurrentUserInstance,
  setCurrentUserType,
  setLocalUserInstance,
} from "../../../state/localstorage";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { PromptModal } from "../sidebar/PromptModal";
const OrganizationSwitch = ({ organization, selectCallback }: any) => {
  console.log("na me", organization);
  const user = getCurrentUserInstance();
  const navigate = useNavigate();
  const [, setCookie] = useCookies([
    "user",
    "access_token",
    "aff_user",
    "aff_access_token",
  ]);

  const switchProfile = (id: string) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "organisation/switch", {
      method: "POST",
      body: JSON.stringify({
        orgId: id,
        userId: user.id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          let expires = new Date();
          expires.setTime(expires.getTime() + 720 * 60 * 60 * 1000);
          let userData = data.data.user;

          if (selectCallback) {
            selectCallback();
          }

          userData.onboarded = true;
          setCurrentUserType(userData.organisation.userType || "");
          setLocalUserInstance(userData);

          if (userData.organisation.userType === "affiliate") {
            setCookie("aff_user", data.data.user, {
              path: "/",
              expires,
            });
            setCookie("aff_access_token", data.data.token, {
              path: "/",
              expires,
            });
          } else {
            setCookie("user", data.data.user, {
              path: "/",
              expires,
            });
            setCookie("access_token", data.data.token, {
              path: "/",
              expires,
            });
          }

          setTimeout(function () {
            userData.organisation.userType === "affiliate"
              ? navigate("/affiliate-dashboard")
              : navigate("/dashboard");
          }, 1000); //wait 1 second
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
        }
      })
      .catch((err) => {
        console.log("here");
      });
  };

  const { pathname } = window.location;
  const {
    isOpen: isOpenCampaignPrompt,
    onOpen: onOpenCampaignPrompt,
    onClose: onCloseCampaignPrompt,
  } = useDisclosure();
  const {
    isOpen: forTestOpen,
    onOpen: forTestonOpen,
    onClose: forTestClose,
  } = useDisclosure();
  const handleRedirect = () => {
    // navigate(
    //   `/signup?type=newOrganization&country=${user.organisation.country}&userId=${user.id}`
    // );
    if (pathname.includes("/campaigns") === false || !pathname.split("/")[2])
      return navigate(
        `/signup?type=newOrganization&country=${user.organisation.country}&userId=${user.id}`
      );

    if (pathname.includes("/campaigns") && pathname.split("/")[1]) {
      onOpenCampaignPrompt();
    }
  };
  return (
    <>
      <PromptModal
        isOpen={isOpenCampaignPrompt}
        onClose={onCloseCampaignPrompt}
        title="Leave page?"
        message="Changes you made may not be saved."
        onClose2={forTestClose}
        route={`/signup?type=newOrganization&country=${user.organisation.country}&userId=${user.id}`}
      />
      <Box
        display="flex"
        flexDirection="column"
        alignItems={{ md: "center" }}
        justifyContent={{ md: "center" }}
      >
        {organization &&
          organization.map((item: any) => {
            return (
              <Box
                key={item.id}
                height="20px"
                mt="15px"
                mb={{ md: "5px" }}
                display={item.signup_step >= 5 ? "flex" : "none"}
                onClick={() => switchProfile(item.id)}
                cursor="pointer"
                backgroundColor={
                  user.organisation.id === item.id
                    ? { base: "", md: "rgba(114, 17, 212, 0.04)" }
                    : "#fffffr"
                }
              >
                <Tooltip
                  label={`${item.displayname || item.companyname} \n${
                    item.userType
                  }`}
                  placement="right"
                  whiteSpace="break-spaces"
                  textTransform="capitalize"
                  bgColor="#7211D4"
                  padding="5px 15px"
                >
                  <Box display="flex" alignItems="center">
                    <Avatar
                      id="my-anchor-element"
                      size={"xs"}
                      name={item.displayname || item.companyname}
                    />
                    <Text
                      display={{ base: "block", md: "none" }}
                      fontSize="0.75rem"
                      fontWeight="400"
                      color="#32325D"
                      ml="12px"
                    >
                      {item.displayname || item.companyname}
                    </Text>
                  </Box>
                </Tooltip>
              </Box>
            );
          })}
        <Box
          cursor="pointer"
          height="20px"
          mt="15px"
          mb="5px"
          onClick={handleRedirect}
        >
          <Tooltip
            label="Create New"
            placement="right"
            whiteSpace="break-spaces"
            textTransform="capitalize"
            bgColor="#7211D4"
            padding="5px 15px"
          >
            <Image
              id="second-app-title"
              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/30795c21-69d8-48b2-97be-a4de055dbf10.svg"
            />
          </Tooltip>
        </Box>
      </Box>
    </>
  );
};
export default OrganizationSwitch;
