import { Flex, Heading, Input, Select, Text } from "@chakra-ui/react";
import React from "react";

const SettingsInput = ({
  changeHandler,
  value,
  type,
  label,
  isRequired,
  options,
  isDisabled,
}: {
  changeHandler: React.Dispatch<any>;
  value: string;
  type: string;
  label: string;
  isRequired: boolean;
  options?: any[];
  isDisabled?: boolean;
}) => {
  return (
    <Flex
      alignItems="center"
      columnGap="1em"
      my="1em"
      flexDirection={["column", "row"]}
      borderTop={["0px", "0.5px solid"]}
      borderTopColor={{ md: "rgba(50, 50, 93, 0.30)" }}
      py={[".5em", "1em"]}
    >
      <Text
        fontSize="0.9rem"
        color="#32325D"
        fontWeight="500"
        width={{ base: "100%", md: "30%" }}
        textAlign="left"
        mb={[".3em", "0em"]}
      >
        {label} <span style={{ color: "#dc3545" }}>*</span>
      </Text>

      {type === "select" ? (
        <Select
          _focus={{ ring: "0", borderColor: "#5C14CE" }}
          border="0.6px solid rgba(50, 50, 93, 0.30)"
          borderRadius="8px"
          color="#32325D"
          maxW="572px"
          height="48px"
          fontWeight="400"
          id="organizationSelect"
          fontSize="14px"
          disabled={isDisabled}
          isRequired={true}
          value={value}
          onChange={(e: React.ChangeEvent<any>) =>
            changeHandler(e.target.value)
          }
          width={{ base: "100%", md: "70%" }}
          _disabled={{ color: "#32325D" }}
        >
          {options?.map((opt: any, index: number) => {
            return (
              <option key={index} value={opt || ""}>
                {opt || ""}
              </option>
            );
          })}
        </Select>
      ) : (
        <Input
          border="0.6px solid rgba(50, 50, 93, 0.30)"
          borderRadius="8px"
          color="#32325D"
          fontWeight="400"
          height="48px"
          fontSize="14px"
          padding="12px 20px 15px 20px"
          width={{ base: "100%", md: "70%" }}
          maxW="572px"
          type={type}
          disabled={isDisabled}
          value={value}
          onChange={(e: React.ChangeEvent<any>) =>
            changeHandler(e.target.value)
          }
          _focus={{ ring: "0", borderColor: "#5C14CE" }}
          isRequired={isRequired}
          _disabled={{ color: "#32325D" }}
        />
      )}
    </Flex>
  );
};

export default SettingsInput;
