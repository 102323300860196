import React, { useEffect, useState } from "react";
import BillingCard from "../billing-card";
import {
  Box,
  Image,
  Text,
  Stack,
  Select,
  useColorModeValue,
  Flex,
  Button,
  Slide,
  useDisclosure,
  Thead,
  Table,
  Th,
  Tr,
  Tbody,
  Td,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Textarea,
  ModalFooter,
} from "@chakra-ui/react";
import TopAffiliate from "./top-affiliate";
import { DAY_FILTER } from "../../utils/option";
import useUserToken from "../../hooks/useUserToken";
import { getCurrentUserInstance } from "../../state/localstorage";
import { Graph } from "./charts";
import { ResponsiveChoropleth } from "@nivo/geo";
import { ResponsiveLine } from "@nivo/line";
import { data2, data3, features } from "./charts/features";

import { getCookie } from "cookies-next";
import { endpoints } from "../../utils/endpoints";
import { apiReqHandler } from "../../helper/apiReqHandler";
import { useNavigate } from "react-router-dom";
import { gsap, Power2 } from "gsap";
import { useMediaQuery } from "react-responsive";
import "intro.js/introjs.css";
import { Steps, Hints } from "intro.js-react";
import {
  ArrowForwardIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { RiArrowRightFill } from "react-icons/ri";
import { IoArrowForwardSharp } from "react-icons/io5";
const Dashboard = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure();
  const user = getCurrentUserInstance();
  const token = useUserToken();
  const isOrganization = window.localStorage.getItem("organization");
  const [analytics, setAnalytics]: any = useState({});
  const [leadsVsConversionsMetric, setleadsVsConversionsMetric]: any = useState(
    []
  );
  const [countries, setCountries]: any = useState([]);
  const [analyticsValue, setAnalyticsValue] = useState("");
  const [top10Affiliates, settop10Affiliates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  let currentData = analytics?.affiliateReports?.slice(startIndex, endIndex);

  const nextPage = () => {
    if (
      currentPage < Math.ceil(analytics?.affiliateReports?.length / pageSize)
    ) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const checkToken = async () => {
    if (!token) return;

    await fetch(process.env.REACT_APP_API_ENDPOINT + `/auth/valid-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data?.valid) {
          return;
        } else {
          window.localStorage.clear();
          sessionStorage.removeItem("currentUserType");
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          navigate("/login");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (token) {
      checkToken();
    }
  }, [token]);

  useEffect(() => {
    createNotification();
    getCampaigns();
    getTopAffiliate();

    if (isOrganization === "yes") {
      window.location.hash = "no-back-button";
      // Again because Google Chrome doesn't insert
      // the first hash into the history
      window.location.hash = "Again-No-back-button";
      window.onhashchange = function () {
        window.location.hash = "no-back-button";
      };
    }
    gsap.fromTo(
      ".loading",
      {
        opacity: 0,
        ease: Power2.easeInOut,
        scale: 0,
        y: 80,
      },
      {
        opacity: 1,
        duration: 1.5,
        scale: 1,
        ease: Power2.easeInOut,
        repeat: -1,
        y: 0,
        yoyo: true,
      }
    );
  }, []);

  useEffect(() => {
    getAnalytics(analyticsValue);
    gsap.fromTo(
      ".loading",
      {
        opacity: 0,
        ease: Power2.easeInOut,
        scale: 0,
        y: 80,
      },
      {
        opacity: 1,
        duration: 1.5,
        scale: 1,
        ease: Power2.easeInOut,
        repeat: -1,
        y: 0,
        yoyo: true,
      }
    );
  }, [analyticsValue]);

  const createNotification = () => {
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + "/notification/push", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
      body: JSON.stringify({
        subject: "Story of my life",
        message: "All the heart I broke in lekki, only God fit to protect me",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
        }
      })
      .catch((err) => {
        console.log("here");
      });
  };

  const getAnalytics = async (analyticsValue: any) => {
    setLoading(true);
    const { res, error } = await apiReqHandler({
      endPoint:
        endpoints.dashboard.ANALYTICS +
        `?affiliateReportSort=${analyticsValue ? analyticsValue : ""}`,
      method: "GET",
      //   @ts-ignore
      bearerAuth: getCookie("access_token"),
    });
    setLoading(false);
    //   @ts-ignore

    if (res?.data.data.analytics == undefined) {
      navigate("/login");
    }

    //   @ts-ignore
    setAnalytics(res?.data.data.analytics);
    setleadsVsConversionsMetric(
      //   @ts-ignore
      res?.data.data.analytics.leadsVsConversionsMetric
    );
    setCountries(
      //   @ts-ignore
      res?.data.data.analytics.countries
    );
    if (error?.response?.status == 402) {
      navigate("/suspended");
    }
    // console.log("fetvh", res);
    //   @ts-ignore
  };
  const getCampaigns = () => {
    // setLoading(true);
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + "campaign/fetch-campaigns", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          const activeCamps: any = data.data.campaigns.filter(
            (item: any, i: number) => item.campaignStatus === "active"
          );
          setCampaigndata(activeCamps);

          // setCampaigndata(data.data.campaigns);
          // setLoading(false);
          // console.log("fetched value", data.data.campaigns);
        } else {
          // formViewRef.current.scrollIntoView();
          // setLoading(false);
        }
      })

      .catch((err) => {
        // @ts-ignore
        setCampaigndata([]);
        if (err.response.status == 402) {
          navigate("/suspended");
        }
        // setLoading(false);
      });
  };
  const getTopAffiliate = async () => {
    // console.log(endpoints.campaign.VERIFY(campaignId));
    const { res } = await apiReqHandler({
      endPoint: endpoints.dashboard.TOPAFFILIATES,
      method: "GET",
      //   @ts-ignore
      bearerAuth: getCookie("access_token"),
    });

    //   @ts-ignore
    settop10Affiliates(res?.data?.data?.top10);
    // console.log("fetvh", res);
    //   @ts-ignore
    console.log("status", res.data.data.top10);
  };

  const titles = [
    "ORGANIZATION",
    "ALL CAMPAIGNS",
    "ALL SALES",
    "ALL LEADS",
    "ALL EARNINGS",
    "ACTION",
  ];

  const [date, setDate] = useState();
  const [campaigns, setCampaigns] = useState("");
  const [campaigndata, setCampaigndata] = useState([]);
  const [newSteps, setNewSteps] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".hello",
        intro: "Hello step",
      },
      {
        element: ".exploreded",
        intro: "World step",
      },
    ],
    hintsEnabled: true,
    hints: [
      {
        element: ".hello",
        hint: "Hello hint",
        hintPosition: "middle-right",
      },
      {
        element: ".exploreded",
        hint: "Hello hint",
        hintPosition: "middle-right",
      },
    ],
  });
  const onExit = () => {
    setNewSteps((prev) => ({ ...prev, stepsEnabled: false }));
  };

  const toggleSteps = () => {
    setNewSteps((prevState) => ({
      ...prevState,
      stepsEnabled: !prevState.stepsEnabled,
    }));
  };
  useEffect(() => {
    // Add your script here
    const script = document.createElement("script");
    script.innerHTML = `
      gtag('event', 'conversion', {'send_to': 'AW-16468569519/2mIMCIiGkpUZEK_b6aw9'});
    `;
    document.head.appendChild(script);

    // Clean up the script when the component unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []);
  useEffect(() => {
    // Add your Google Tag Manager script here
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtm.js?id=GTM-WLXVVHB4";
    script.async = true;
    document.head.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);
  const [isCancelopen, setCancelopen] = useState(true);
  const { isOpen: isOpenn, onOpen, onClose } = useDisclosure();

  return (
    <>
      {isMobile && (
        <Modal isOpen={isCancelopen} onClose={onClose} size="md" isCentered>
          <ModalOverlay />
          <ModalContent
            borderRadius="1em"
            p=".8em"
            // height="250px"
            mx={{ base: ".6em", md: "auto" }}
            // marginTop={{ base: "15em", md: "18em" }}
          >
            <Box
              width=".8em"
              height="full"
              position="absolute"
              left="0"
              bgGradient="linear(360deg, #7211D4 -1.38%, #FA9445 98.36%)"
              top="0"
              borderLeftRadius="1em"
            ></Box>
            {/* <ModalHeader mt="1em">Modal Title</ModalHeader> */}
            <ModalCloseButton
              bgColor="gray.100"
              borderRadius="1.5em"
              p="1.5em"
              top="1.5em"
              right="1.8em"
              fontSize="1em"
              // my={10}
              onClick={() => setCancelopen(false)}
            />

            <ModalBody mt="5em" mr="1em">
              <Text
                fontSize={{ base: "14px", md: "1.3em" }}
                fontWeight="500"
                // mb={{ base: "1.6em", md: "2em" }}
              >
                Dear User,
              </Text>
              <Text
                fontSize={{ base: "14px", md: "1.3em" }}
                fontWeight="500"
                // mb={{ base: "1.6em", md: "2em" }}
              >
                Please note that the mobile view is currently not fully
                optimized. For the best optimal usability, we recommend using
                the desktop view.
              </Text>

              <Box
                width="100%"
                height="1px"
                mx="auto"
                bgColor="gray.200"
                mt="1em"
              ></Box>
            </ModalBody>

            <ModalFooter>
              <Button
                bgColor="#7211D4"
                color="#F0F5FF"
                rightIcon={<IoArrowForwardSharp />}
                onClick={() => setCancelopen(false)}
                // isLoading={loading}
              >
                Continue
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <Stack
        pb={["30px", "300px"]}
        background="#F0F3F9"
        h={"100%"}
        position={"relative"}
      >
        <BillingCard />
        <Box
          height="82px"
          width="100%"
          background={useColorModeValue("#ffffff", "gray.300")}
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          justifyContent="space-between"
          margin="0px !important"
          p={["15px 15px", "15px 40px"]}
        >
          <Box>
            <Text
              fontWeight="500"
              fontSize="16px"
              color="#32325D"
              lineHeight="24px"
              textTransform="capitalize"
            >
              Hi {user.firstname} {user.lastname},
            </Text>
          </Box>

          <Flex>
            <Text
              fontWeight="400"
              fontSize="12px"
              color="rgba(50, 50, 93, 0.7)"
              lineHeight="20px"
            >
              Here's the latest report on your campaigns
            </Text>
            {/* <Button onClick={onToggle}>Click Me</Button> */}
          </Flex>
        </Box>
        <Box pl={["0px", "20px"]}>
          {top10Affiliates?.length > 1 && (
            <TopAffiliate top10Affiliates={top10Affiliates} />
          )}
        </Box>

        {/* <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          p={["0px 15px", "0px 40px"]}
          marginTop="23px !important"
        > */}
        {/* <Box>
            <Select
              border="0"
              flexGrow={1}
              width="auto"
              background={useColorModeValue("#ffffff", "gray.300")}
              // @ts-ignore
              value={date}
              placeholder="Date"
              onChange={(e: React.ChangeEvent<any>) => {
                console.log(e.target.value);
                // @ts-ignore
                setAnalyticsValue({ ...analytics, date: e.target.value });
                setDate(e.target.value);
              }}
              _focus={{ ring: "0" }}
            >
              {DAY_FILTER.map((item) => {
                return (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </Select>
          </Box> */}
        {/* <Box>
            <Select
              border="0"
              flexGrow={1}
              background={useColorModeValue("#ffffff", "gray.300")}
              value={campaigns}
              placeholder="Campaigns"
              onChange={(e: React.ChangeEvent<any>) => {
                console.log(e.target.value);
                // @ts-ignore
                setAnalyticsValue({
                  ...analytics,
                  campaignid: e.target.value,
                });
                setCampaigns(e.target.value);
              }}
              _focus={{ ring: "0" }}
            >
              {campaigndata.map((item) => {
                return (
                  // @ts-ignore
                  <option key={item.campaignId} value={item.campaignId}>
                    {/* @ts-ignore */}
        {/* {item?.campaignName}
                  </option> */}
        {/* );
              })} */}
        {/* </Select>
          </Box> */}
        {/* </Box> */}
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            h="50vh"
            alignItems="center"
            pt="20px"
          >
            <Image className="loading" src="/assets/logo.png" height="60px" />
            <Text
              fontWeight="400"
              fontSize="16px"
              lineHeight="70px"
              color="#000000"
              mt="-15px"
            >
              Fetching transaction details...
            </Text>
          </Box>
        ) : (
          <>
            {analytics && (
              <Box
                p={["20px", "20px 40px"]}
                display="flex"
                alignItems="center"
                flexDirection={["column", "row"]}
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <Box
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="column"
                  justifyContent="space-between"
                  background="#ffffff"
                  borderRadius="16px"
                  // height="105px"
                  width={["100%", "100%", "233px", "233px", "18%", "18%"]}
                  padding="20px"
                  marginBottom={["25px", "25px", "25px", "25px", "25px", "0px"]}
                  boxShadow="0px 4px 8px rgba(211, 211, 211, 0.25)"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Flex direction={"column"}>
                      <Text
                        fontWeight="600"
                        fontSize="27px"
                        color=" #32325D"
                        lineHeight="41px"
                      >
                        {/* @ts-ignore */}
                        {`${
                          analytics.allAffliliates
                            ? analytics.allAffliliates
                            : 0
                        }`}
                      </Text>
                      <Box>
                        <Text
                          fontWeight="400"
                          fontSize="14px"
                          color=" rgba(50, 50, 93, 0.8)"
                        >
                          All Affiliates
                        </Text>
                      </Box>
                    </Flex>
                    <Box>
                      <Image
                        src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/c118e92b-ffbc-4400-94b8-a3da927cdd25.svg`}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="column"
                  justifyContent="space-between"
                  background="#ffffff"
                  borderRadius="16px"
                  // height="105px"
                  width={["100%", "100%", "233px", "233px", "18%", "18%"]}
                  padding="20px"
                  marginBottom={["25px", "25px", "25px", "25px", "25px", "0px"]}
                  boxShadow="0px 4px 8px rgba(211, 211, 211, 0.25)"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Flex direction={"column"}>
                      <Text
                        fontWeight="600"
                        fontSize="27px"
                        color=" #32325D"
                        lineHeight="41px"
                      >
                        {/* @ts-ignore */}
                        {`$ ${
                          analytics.totalSales
                            ? analytics.totalSales
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : 0
                        }`}
                      </Text>
                      <Box>
                        <Text
                          fontWeight="400"
                          fontSize="14px"
                          color=" rgba(50, 50, 93, 0.8)"
                        >
                          Sales
                        </Text>
                      </Box>
                    </Flex>
                    <Box>
                      <Image
                        src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/ce8b9e7e-0888-44ed-a05e-78f9ca558270.svg`}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="column"
                  justifyContent="space-between"
                  background="#ffffff"
                  borderRadius="16px"
                  // height="105px"
                  width={["100%", "100%", "233px", "233px", "18%", "18%"]}
                  padding="20px"
                  marginBottom={["25px", "25px", "25px", "25px", "25px", "0px"]}
                  boxShadow="0px 4px 8px rgba(211, 211, 211, 0.25)"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Flex direction={"column"}>
                      <Text
                        fontWeight="600"
                        fontSize="27px"
                        color=" #32325D"
                        lineHeight="41px"
                      >
                        {/* @ts-ignore */}
                        {`$ ${
                          analytics.pendingCommission
                            ? analytics.pendingCommission
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : 0
                        }`}
                      </Text>
                      <Box>
                        <Text
                          fontWeight="400"
                          fontSize="14px"
                          color=" rgba(50, 50, 93, 0.8)"
                        >
                          Pending Payouts
                        </Text>
                      </Box>
                    </Flex>
                    <Box>
                      <Image
                        src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/b0bb8de5-e8af-424e-a322-2cc4af57f5a4.svg`}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="column"
                  justifyContent="space-between"
                  background="#ffffff"
                  borderRadius="16px"
                  // height="105px"
                  width={["100%", "100%", "233px", "233px", "18%", "18%"]}
                  padding="20px"
                  marginBottom={["25px", "25px", "25px", "25px", "25px", "0px"]}
                  boxShadow="0px 4px 8px rgba(211, 211, 211, 0.25)"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Flex direction={"column"}>
                      <Text
                        fontWeight="600"
                        fontSize="27px"
                        color=" #32325D"
                        lineHeight="41px"
                      >
                        {/* @ts-ignore */}
                        {`$ ${
                          analytics.paidCommission
                            ? analytics.paidCommission
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : 0
                        }`}
                      </Text>
                      <Box>
                        <Text
                          fontWeight="400"
                          fontSize="14px"
                          color=" rgba(50, 50, 93, 0.8)"
                        >
                          Paid Payouts
                        </Text>
                      </Box>
                    </Flex>
                    <Box>
                      <Image
                        src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/e1e900d9-3158-44bb-b8f5-9a7752b50307.svg`}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="column"
                  justifyContent="space-between"
                  background="#ffffff"
                  borderRadius="16px"
                  // height="105px"
                  width={["100%", "100%", "233px", "233px", "18%", "18%"]}
                  padding="20px"
                  marginBottom={["25px", "25px", "25px", "25px", "25px", "0px"]}
                  boxShadow="0px 4px 8px rgba(211, 211, 211, 0.25)"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Flex direction={"column"}>
                      <Text
                        fontWeight="600"
                        fontSize="27px"
                        color=" #32325D"
                        lineHeight="41px"
                      >
                        {/* @ts-ignore */}
                        {`${
                          analytics.leads
                            ? analytics.leads
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : 0
                        }`}
                      </Text>
                      <Box>
                        <Text
                          fontWeight="400"
                          fontSize="14px"
                          color=" rgba(50, 50, 93, 0.8)"
                        >
                          Leads
                        </Text>
                      </Box>
                    </Flex>
                    <Box>
                      <Image
                        src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/c484706e-f7e1-4ffe-a15f-4ddcd913f066.svg`}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

            <Box
              display="flex"
              alignItems="center"
              flexDirection={["column", "row"]}
              justifyContent="space-between"
              p={["0px 15px", "0px 40px"]}
              marginTop="10px !important"
              w="100%"
              overflow={["scroll", "hidden"]}
            >
              <Box
                w={["100%", "100%"]}
                // h={["350px", "400px"]}
                backgroundColor="#fff"
                py={["25px ", "20px"]}
                borderRadius={10}
                px={5}
              >
                <Box
                  borderBottom={"1px"}
                  w={"100%"}
                  borderBlockEndColor={"#EAECF0"}
                  pb={3}
                >
                  <Text fontWeight={"medium"}>Affiliate Board</Text>
                </Box>
                <Flex
                  w={"100%"}
                  justify={"space-between"}
                  borderBottom={"1px"}
                  borderBlockEndColor={"#EAECF0"}
                  direction={"row"}
                  py={3}
                >
                  <Flex>
                    <Select
                      // flexGrow={1}
                      background={useColorModeValue("#ffffff", "gray.300")}
                      border="0.6px solid #EAECF0"
                      borderRadius="8px"
                      w={["150px", "200px"]}
                      // @ts-ignore
                      value={analyticsValue}
                      placeholder="Sort by"
                      onChange={(e: React.ChangeEvent<any>) => {
                        setAnalyticsValue(e.target.value);
                      }}

                      // style={{
                      //   width: "200px",
                      // }}
                    >
                      {[
                        { name: "Highest to Lowest", value: "h2l" },
                        { name: "Lowest to Highest", value: "l2h" },
                      ].map((item, i) => {
                        return (
                          <option key={i} value={item.value}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Select>
                  </Flex>
                  <Button
                    textColor="#000"
                    bgColor="#fff"
                    onClick={() => {
                      navigate(`/reports`);
                    }}
                    fontWeight="medium"
                  >
                    <Flex align={"center"} gap={3} color={"#5C14CE"}>
                      <Text>See All Reports</Text>
                      <ArrowForwardIcon />
                    </Flex>
                  </Button>
                </Flex>

                <Flex
                  w={"100%"}
                  borderBottom={"1px"}
                  borderBlockEndColor={"#EAECF0"}
                  py={3}
                  direction={"column"}
                  overflow={["scroll", "hidden"]}
                >
                  <Table mb="29px">
                    <Thead>
                      <Tr height="35px" bgColor="#F9FAFB">
                        {titles.map((title) => {
                          return (
                            <Th
                              color="rgba(50, 50, 93, 0.8)"
                              textTransform="uppercase"
                              lineHeight="18px"
                              fontSize="12px"
                              fontWeight="500"
                              key={title}
                              whiteSpace={{ base: "nowrap", md: "normal" }}
                            >
                              {title}
                            </Th>
                          );
                        })}
                      </Tr>
                    </Thead>
                    <Tbody w={"100%"}>
                      {currentData?.length >= 1 ? (
                        currentData?.map((item: any, i: any) => (
                          <Tr height="50px" width="100%" key={i}>
                            <Td>
                              <Text>{item.aff_organisation_name}</Text>
                            </Td>
                            <Td>
                              <Text>{item.totalCampaigns}</Text>
                            </Td>
                            <Td>
                              <Text>${item.totalSales}</Text>
                            </Td>
                            <Td>
                              <Text>{item.totalLeads}</Text>
                            </Td>
                            <Td>
                              <Text>${item.totalCommissions}</Text>
                            </Td>
                            <Td>
                              <Button
                                textColor="#000"
                                p={0}
                                bgColor="#fff"
                                onClick={() => {
                                  navigate(
                                    `/explore/affiliate?id=${item.aff_organisation_id}`
                                  );
                                }}
                                fontWeight="medium"
                              >
                                <Flex
                                  align={"center"}
                                  gap={3}
                                  color={"#5C14CE"}
                                >
                                  <Text>View Profile</Text>
                                  {/* <ArrowForwardIcon /> */}
                                </Flex>
                              </Button>
                            </Td>
                          </Tr>
                        ))
                      ) : (
                        <Flex
                          w={"100%"}
                          // bgColor={"red"}
                          //  h={"50px"}
                          mb="29px"
                          justify={"center"}
                          align={"center"}
                          flexDirection={"column"}
                        ></Flex>
                      )}
                    </Tbody>
                  </Table>
                  {currentData < 1 && (
                    <Flex
                      w={"100%"}
                      // bgColor={"red"}
                      //  h={"50px"}
                      mb="29px"
                      justify={"center"}
                      align={"center"}
                      flexDirection={"column"}
                    >
                      <Image
                        src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
                        width="150px"
                        height="150px"
                      />
                      <Text fontWeight={"medium"}>
                        You currently have no Campaigns Live
                      </Text>
                    </Flex>
                  )}
                  <Flex
                    w={"100%"}
                    // bgColor={"red"}
                    h={"50px"}
                    mb="29px"
                    justify={"flex-end"}
                    align={"center"}
                  >
                    <Flex align={"center"} gap={3}>
                      <IconButton
                        aria-label="Search database"
                        icon={<ChevronLeftIcon fontSize={16} />}
                        onClick={prevPage}
                      />
                      <Flex
                        borderWidth={0.5}
                        borderColor={"#c4c4c4"}
                        w={35}
                        h={35}
                        justify={"center"}
                        align={"center"}
                        borderRadius={10}
                      >
                        <Text>{currentPage}</Text>
                      </Flex>
                      <IconButton
                        aria-label="Search database"
                        icon={<ChevronRightIcon fontSize={16} />}
                        onClick={nextPage}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
            </Box>
          </>
        )}
      </Stack>
    </>
  );
};

export default Dashboard;
