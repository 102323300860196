import {
  Box,
  Checkbox,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { IoArrowForwardSharp } from "react-icons/io5";
import { useNavigate, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  setCurrentUserType,
  setLocalUserInstance,
} from "../../../state/localstorage";
import { BaseButton } from "../../button";
import { FormInput } from "../../formInput";
import OrganizationContent from "../organizations";
import CampaignInfo from "./campaignInfo";

const AffiliateLogin = () => {
  const navigate = useNavigate();
  const [, setCookie] = useCookies([
    "user",
    "access_token",
    "aff_user",
    "aff_access_token",
  ]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allOrganization, setAllOrganization] = useState("");
  const [organization, setOrganization] = useState();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef(null);

  const modalHandler = (data: any) => {
    setOrganization(data);
    onClose();
    loginOrg(data);
  };
  const [clientError, setClientError]: [
    clientError: string[],
    setClientError: React.Dispatch<React.SetStateAction<any>>
  ] = useState([]);

  const formViewRef = useRef(null);

  const submitHandler = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    setLoading(true);

    if (email && password) {
      fetch(process.env.REACT_APP_API_ENDPOINT + "auth/signin", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setAllOrganization(data.data.organisations);
            onOpen();
          } else {
            // @ts-ignore
            toast.error(data.message);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      toast.error("Please complete all required fields");
      setClientError([...clientError, "Please complete all required fields"]);
    }
  };

  const loginOrg = (data: any) => {
    setLoading(true);
    fetch(process.env.REACT_APP_API_ENDPOINT + "auth/signin", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        password: password,
        orgId: data["id"],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.success) {
          const customId = "custom-id-yes";
          toast.success("Login successful", {
            toastId: customId,
          });
          let expires = new Date();
          expires.setTime(expires.getTime() + 720 * 60 * 60 * 1000);
          if (data.data.user.organisation.userType === "affiliate") {
            setCookie("aff_user", data.data.user, {
              path: "/",
              expires,
            });
            setCookie("aff_access_token", data.data.token, {
              path: "/",
              expires,
            });
          } else {
            setCookie("user", data.data.user, {
              path: "/",
              expires,
            });
            setCookie("access_token", data.data.token, {
              path: "/",
              expires,
            });
          }

          setCurrentUserType(data.data.user.organisation.userType || "");
          setLocalUserInstance(data.data.user);
          setTimeout(function () {
            if (data.data.user.organisation.userType === "affiliate") {
              navigate("/affiliate-dashboard");
            } else if (data.data.user.organisation.userType === "advertiser") {
              navigate("/dashboard");
            }
          }, 1000); //wait 1 second
        } else {
          // @ts-ignore
          toast.error(data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Box
      display="flex"
      width={{ base: "100%", md: "100vw" }}
      justifyContent="center"
      alignItems="center"
      height={{ md: "100vh" }}
      p={{ base: "20px", md: "0px" }}
    >
      <ToastContainer />
      <Flex
        flexDirection={{ base: "column-reverse", md: "row" }}
        justifyContent={{ base: "start", md: "center" }}
        alignItems="center"
      >
        <Stack>
          <Image
            src="/assets/logo.png"
            mb="48px"
            display={{ base: "none", md: "block" }}
            height="52px"
            width="98px"
            objectFit="contain"
          />

          <Text
            color="#32325D"
            fontSize="16px"
            lineHeight="26px"
            fontWeight="400"
            mb="45px"
          >
            Log in or create an affiliate account on Metricks to join the
            campaign
          </Text>

          <Box>
            <form onSubmit={submitHandler}>
              <Box>
                <FormInput
                  label="Email address"
                  type="email"
                  value={email}
                  stateHandler={setEmail}
                  required={true}
                  holder="example@gmail.com"
                />
                <FormInput
                  label="Password"
                  type="password"
                  value={password}
                  stateHandler={setPassword}
                  required={true}
                  holder="Enter password"
                />

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom="30px"
                >
                  <Checkbox
                    colorScheme="purple"
                    color="#A5A5A5"
                    alignItems="center"
                    onChange={(e: React.ChangeEvent<any>) =>
                      setRemember(e.target.checked)
                    }
                  >
                    <Text
                      color="#A5A5A5"
                      marginLeft="6px"
                      fontSize="12px"
                      fontWeight="400px"
                    >
                      Remember me
                    </Text>
                  </Checkbox>

                  <Link to="/reset-password">
                    <Text color="#EB813C" fontSize="12px" fontWeight="400px">
                      Forgot password?
                    </Text>
                  </Link>
                </Box>
              </Box>

              <BaseButton
                text="Login"
                padded={true}
                textColor="#fff"
                bgColor="#5C14CE"
                type="submit"
                loadingState={loading}
                loaderText="Loading..."
                clickEventHandler={submitHandler}
                rightIcon={<IoArrowForwardSharp />}
              />

              <Text color="#333333" mt="15px" mb="2em" textAlign="center">
                Don't have an account? {"   "}
                <Link to="/signup/affiliate-profile">
                  <span
                    style={{ color: "#5C14CE", textDecoration: "underline" }}
                  >
                    Sign Up
                  </span>
                </Link>
              </Text>

              <Modal
                onClose={onClose}
                size={{ base: "full", md: "5xl" }}
                finalFocusRef={btnRef}
                isOpen={isOpen}
              >
                <ModalOverlay />
                <ModalContent
                  borderRadius="39px"
                  // width={{ base: "auto", md: "70%" }}
                >
                  <ModalBody borderRadius="39px" height="538px" p="0">
                    <OrganizationContent
                      data={allOrganization}
                      modalHandler={modalHandler}
                    />
                  </ModalBody>
                </ModalContent>
              </Modal>
            </form>
          </Box>
        </Stack>

        <CampaignInfo />
      </Flex>
    </Box>
  );
};

export default AffiliateLogin;
