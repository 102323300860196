import React, { useRef, useEffect, useState } from "react";
import {
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Image,
  Stack,
  Button,
  css,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper styles
// @ts-ignore
import { getList } from "country-list-with-dial-code-and-flag";
import ReactCountryFlag from "react-country-flag";
import Countries from "./countries";
const Overview = ({ campaign }) => {
  console.log(campaign, "campai");
  const swiperRef = useRef();
  const [country, setCountry] = useState([]);
  useEffect(() => {
    console.log(campaign.countriesToShip, "ghan");
    getCountryFlag();
  }, []);
  const getCountryFlag = () => {
    let data = [];
    campaign.countriesToShip.split(",").map((item) => {
      getList().filter((id) => {
        if (id.name === item) {
          data.push(id);
        }
      });
    });
    console.log(data, "data");
    setCountry(data);
  };
  return (
    <Stack pb="100px">
      <Box
        background="#F1F4FA"
        borderRadius=" 8px"
        h="95px"
        display={["-webkit-inline-box", "flex"]}
        alignItems="center"
        justifyContent="space-between"
        overflowX="auto"
        css={css({
          scrollbarWidth: "none",
          "::-webkit-scrollbar": { display: "none" },
          "-webkit-overflow-scrolling": "touch",
          boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
          border: "0 none",
        })}
      >
        <Box
          width="177px"
          display="flex"
          pl="33px"
          justifyContent="flex-start"
          flexDirection="column"
          borderRight="1px solid rgba(50, 50, 93, 0.5)"
        >
          <Text
            color="#32325D"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            {campaign.product_type}
          </Text>
          <Text
            color="rgba(50, 50, 93, 0.8)"
            lineHeight="18px"
            fontSize="12px"
            fontWeight="400"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            Product
          </Text>
        </Box>
        <Box
          width="177px"
          display="flex"
          pl="33px"
          justifyContent="flex-start"
          flexDirection="column"
          borderRight="1px solid rgba(50, 50, 93, 0.5)"
        >
          <Text
            color="#32325D"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            {campaign.commission_amount}
          </Text>
          <Text
            color="rgba(50, 50, 93, 0.8)"
            lineHeight="18px"
            fontSize="12px"
            fontWeight="400"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            Commission
          </Text>
        </Box>
        <Box
          width="177px"
          display="flex"
          pl="33px"
          justifyContent="flex-start"
          flexDirection="column"
          borderRight="1px solid rgba(50, 50, 93, 0.5)"
        >
          <Text
            color="#32325D"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            {campaign.commission_amount}
          </Text>
          <Text
            color="rgba(50, 50, 93, 0.8)"
            lineHeight="18px"
            fontSize="12px"
            fontWeight="400"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            All Sales
          </Text>
        </Box>
        <Box
          width="177px"
          display="flex"
          pl="33px"
          justifyContent="flex-start"
          flexDirection="column"
          borderRight="1px solid rgba(50, 50, 93, 0.5)"
        >
          <Text
            color="#32325D"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            350123456
          </Text>
          <Text
            color="rgba(50, 50, 93, 0.8)"
            lineHeight="18px"
            fontSize="12px"
            fontWeight="400"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            Affiliates
          </Text>
        </Box>
        <Box
          width="177px"
          display="flex"
          pl="33px"
          justifyContent="flex-start"
          flexDirection="column"
          borderRight="1px solid rgba(50, 50, 93, 0.5)"
        >
          <Text
            color="#32325D"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            {campaign?.createdAt?.split("T")[0]}
          </Text>
          <Text
            color="rgba(50, 50, 93, 0.8)"
            lineHeight="18px"
            fontSize="12px"
            fontWeight="400"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            Launch Date
          </Text>
        </Box>
        <Box
          width="177px"
          display="flex"
          pl="33px"
          justifyContent="flex-start"
          flexDirection="column"
        >
          <Text
            color="#32325D"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            {campaign?.createdAt?.split("T")[0]}
          </Text>
          <Text
            color="rgba(50, 50, 93, 0.8)"
            lineHeight="18px"
            fontSize="12px"
            fontWeight="400"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            End Date
          </Text>
        </Box>
      </Box>
      {/* <Countries countries={campaign.countriesToShip} /> */}
      <Stack
        margin="18px"
        background="#FFFFFF"
        width="100%"
        height="152px"
        padding={["16px 0px", "16px 27px"]}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb="20px"
        >
          <Box display="flex" alignItems="center">
            <Text
              fontWeight="500"
              fontSize="12px"
              color=" #32325D"
              lineHeight="18px"
              opacity="0.8"
              letterSpacing="0.02em"
              textTransform="uppercase"
            >
              Countries
            </Text>
          </Box>
          <Box display="flex" alignItems="center">
            <Button
              background="#FFFFFF"
              boxShadow="0px 0px 7.42829px rgba(114, 17, 212, 0.25)"
              borderRadius=" 4.64268px"
              width="32px"
              height="28px"
              onClick={() => swiperRef.current?.slidePrev()}
            >
              <Image
                transform="rotate(180deg)"
                src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/67853fcc-a95e-4476-be48-14434b51b27d.svg`}
              />
            </Button>
            <Button
              background="#FFFFFF"
              boxShadow="0px 0px 7.42829px rgba(114, 17, 212, 0.25)"
              borderRadius=" 4.64268px"
              width="32px"
              height="28px"
              ml="18px"
              onClick={() => swiperRef.current?.slideNext()}
            >
              <Image
                src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/67853fcc-a95e-4476-be48-14434b51b27d.svg`}
              />
            </Button>
          </Box>
        </Box>
        <Box position="relative">
          <Swiper
            spaceBetween={10}
            breakpoints={{
              // when window width is >= 370px
              370: {
                slidesPerView: 2.3,
              },
              // when window width is >= 400px
              460: {
                slidesPerView: 2.3,
              },
              // when window width is >= 640px
              640: {
                slidesPerView: 4.4,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 3.4,
              },
              // when window width is >= 900px
              900: {
                slidesPerView: 3.4,
              },
              // when window width is >= 1024px
              1024: {
                slidesPerView: 4.3,
              },
              // when window width is >= 1200px
              1200: {
                slidesPerView: 5,
              },
              // when window width is >= 1500px
              1500: {
                slidesPerView: 5,
              },
            }}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {country.map((item) => {
              return (
                <SwiperSlide position="relative" key={item.code}>
                  <Box display="flex" alignItems="center">
                    <Box
                      ml="14px"
                      display="flex"
                      alignItems="center"
                      background="#FFFFFF"
                      border=" 0.5px solid rgba(50, 50, 93, 0.5)"
                      borderRadius=" 80px"
                    >
                      <Box ml="14px" mt="9px" mb="9px">
                        <Box width="31px" height="30px">
                          <ReactCountryFlag countryCode={item.code} svg />
                        </Box>
                      </Box>
                      <Box pl="14px" pr="20px">
                        <Text
                          fontWeight="500"
                          fontSize="14px"
                          color=" #32325D"
                          lineHeight="21px"
                        >
                          {item.name}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </Stack>
      <Box
        padding={["24px 12px", "24px 28px"]}
        background="#F1F4FA"
        borderRadius=" 8px"
      >
        <Text
          color="#32325D"
          lineHeight="18px"
          fontSize="12px"
          fontWeight="500"
          opacity="0.8"
          letterSpacing="0.02em"
          textTransform="uppercase"
        >
          Description
        </Text>
        <Text
          color="rgba(50, 50, 93, 0.8)"
          lineHeight="22px"
          fontSize="14px"
          fontWeight="300"
          opacity="0.8"
          pt={["13px", "26px"]}
        >
          {campaign.description}
        </Text>
      </Box>
      <Box
        padding={["24px 0px", "24px 28px"]}
        background="#FFFFFF"
        borderRadius=" 8px"
      >
        <Text
          color="#32325D"
          lineHeight="18px"
          fontSize="12px"
          fontWeight="500"
          opacity="0.8"
          letterSpacing="0.02em"
          textTransform="uppercase"
        >
          TERMS AND CONDITIONS
        </Text>
        <Accordion defaultIndex={[0]} allowMultiple>
          <AccordionItem p="0" mt="20px" bgColor="white">
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    pl="0"
                    pr="0"
                  >
                    <Box>
                      <Text
                        color="rgba(50, 50, 93,0.8)"
                        lineHeight="22px"
                        fontSize="14px "
                        fontWeight="300"
                        opacity="0.8"
                        textAlign="start"
                      >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vitae nibh elementum, eget eu cursus massa.
                      </Text>
                    </Box>
                    {isExpanded ? (
                      <Image
                        transform="rotate(45deg)"
                        src="/assets/svg/accordion-icon.svg"
                      />
                    ) : (
                      <Image src="/assets/svg/accordion-icon.svg" />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} pl="0" pr="0">
                  <Text
                    color="rgba(50, 50, 93,0.8)"
                    lineHeight="22px"
                    fontSize="12px"
                    fontWeight="300"
                    opacity="0.8"
                  >
                    {campaign.terms}
                  </Text>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </Box>
    </Stack>
  );
};

export default Overview;
