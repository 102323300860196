import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

interface requestSchema {
  endPoint: string;
  dataObject: any;
  bearerAuth?: string;
}

export const usePutRequest = () => {
  const [serverResponse, setServerResponse]: any = useState(null);
  const [serverError, setServerError]: any = useState(null);

  const putHandler = async ({
    endPoint,
    dataObject,
    bearerAuth,
  }: requestSchema) => {
    setServerError(null);
    const options = {
      url: endPoint,
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        "Content-Type": "application/json;charset=UTF-8",
      },

      data: dataObject,
    };

    axios(options)
      .then((response) => {
        setServerResponse(response);
        if (response.data.success) {
          setServerError(null);
        }
      })
      .catch((error) => {
        // Clear old errors
        setServerError(error.response.data.message);
        console.log("RES ERR", error.response.data.message);
        error.response.data.message.forEach((err: string) => toast.error(err));
      });
  };

  return [putHandler, serverResponse, serverError];
};
