import axios from "axios";
interface RequestSchema {
  endPoint: string;
  method: "GET" | "POST" | "PATCH" | "PUT" | "DELETE";
  payload?: any;
  bearerAuth?: string;
}

export const apiReqHandler = async ({
  endPoint,
  payload,
  method,
  bearerAuth,
}: RequestSchema) => {
  let reqConfig = {
    url: endPoint,
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };

  if (bearerAuth) {
    // @ts-ignore
    reqConfig.headers.Authorization = `Bearer ${bearerAuth}`;
  }

  if (payload) {
    // @ts-ignore
    reqConfig.data = payload;
  }

  try {
    const res = await axios(reqConfig);

    return {
      error: !res ? "Error sending request" : null,
      res: res ? res : null,
    };
  } catch (err: any) {
    return { error: err, res: null };
  }
};
