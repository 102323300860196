import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  css,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import useUserToken from "../../../../../hooks/useUserToken";
import UnavailableInfo from "../../../../affiliate/campaigns/view-campaigns/tabs/UnavailableInfo";
import moment from "moment";
import { MdArrowDropDown } from "react-icons/md";
import { ArrowBackIcon, ArrowLeftIcon } from "@chakra-ui/icons";

const CampaignLeads = ({
  campaignName,
  id,
  refreshData,
  setExternalAffInfo,
  setTabIndex,
}: {
  campaignName: string;
  id: string | undefined;
  refreshData: boolean;
  setExternalAffInfo: React.Dispatch<any>;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const titles = [
    "Date Created",
    "Affililate Organization",
    "Product ID",
    "Status",
    "Action",
  ];

  const [campaignLeads, setcampaignLeads] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  const token = useUserToken();

  const getLeads = () => {
    if (!id) return;

    let bearerAuth = token;
    fetch(
      process.env.REACT_APP_API_ENDPOINT + `/events/lead/advertiser/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("leads", data);
        if (data.success) {
          setcampaignLeads(data.data.leads);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("get campaign => " + err);
        setLoading(false);
      });
  };

  const getSales = (
    endpoint: string,
    setPlaceholder: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    if (!id) return;

    // setisLoading(true);
    let bearerAuth = token;

    fetch(process.env.REACT_APP_API_ENDPOINT + endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("all sales", data.data.conversions);
        if (data.success) {
          console.log("check lead conv", data.data.conversions);
          setPlaceholder(data.data.conversions);
        }
        // setisLoading(false);
      })
      .catch((err) => {
        // setisLoading(false);
        console.log("get sales => " + err);
      });
  };
  const getStatusColor = (status: string) => {
    if (status.toLowerCase() === "converted") return "#239654";

    if (status.toLowerCase() === "pending") return "#F3994A";

    return "#F3994A";
  };
  const handleViewAff = (selectedAff: any) => {
    let affInfo = campaignLeads.find((lead) => lead.aff_orgId === selectedAff);
    console.log(affInfo);
    const modData = {
      aff_org: "",
      aff_orgId: affInfo.aff_orgId,
      campaign: {
        id: affInfo.campaignId,
        campaign_name: "",
      },
      campaignId: affInfo.campaignId,
      createdAt: affInfo.createdAt,
      id: affInfo.id,
      status: "approved",
    };
    if (affInfo) {
      setExternalAffInfo(modData);
      setTabIndex(3);
    }
  };
  const [filterData, setfilterData] = useState("status");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sales, setsales] = useState<any[]>([]);
  const {
    isOpen: isOpenConv,
    onOpen: onOpenConv,
    onClose: onCloseConv,
  } = useDisclosure();

  const titless = [
    "DATE CREATED",
    "ORGANIZATION",
    "SALES",
    "COMMISSION",
    "STATUS",
  ];

  useEffect(() => {
    getLeads();
  }, [id]);

  const filteredDataFUnction = (filterData: any, status: any) => {
    setfilterData(status);

    const final =
      status === "pending"
        ? campaignLeads.filter((item) => item?.converted === false)
        : campaignLeads.filter((item) => item?.converted === true);

    setcampaignLeads(final);
  };

  return (
    <Box>
      <Box
        css={
          isDesktop
            ? {}
            : css({
                "::-webkit-scrollbar": { display: "none" },
                "-webkit-overflow-scrolling": "touch",
              })
        }
        overflowX={{ base: "scroll", md: "visible" }}
        maxWidth="100%"
      >
        {campaignLeads && (
          <Select
            w={{ base: "50%", md: "120px" }}
            bg="white"
            alignContent={"center"}
            placeholder="Status"
            my={5}
            value={filterData}
            onChange={(e) => {
              filteredDataFUnction(filterData, e.target.value);
            }}
            icon={<MdArrowDropDown />}
          >
            <option value="converted">Converted</option>
            <option value="pending">Pending</option>
          </Select>
        )}
        <Table mb="29px">
          <Thead>
            <Tr height="53px" bgColor="#F1F4FA">
              {titles.map((title) => {
                return (
                  <Th
                    color="rgba(50, 50, 93, 0.8)"
                    textTransform="uppercase"
                    lineHeight="18px"
                    fontSize="12px"
                    fontWeight="500"
                    key={title}
                    whiteSpace={{ base: "nowrap", md: "normal" }}
                  >
                    {title}
                  </Th>
                );
              })}
            </Tr>
          </Thead>

          <Tbody>
            {campaignLeads.map((item: any, index: number) => {
              return (
                <Tr key={index} height="80px" width="100%">
                  <Modal
                    isOpen={isOpen}
                    onClose={onClose}
                    size={"4xl"}
                    isCentered
                  >
                    <ModalOverlay />
                    <ModalContent
                      // mx={{ base: ".6em", md: "auto" }}
                      // marginTop={{ base: "15em", md: "18em" }}
                      borderRadius={10}
                      isTruncated
                      p={7}
                    >
                      <ModalCloseButton />
                      <ModalBody>
                        <Box py="1.5em">
                          <Heading fontSize="xl" mb="1.5em">
                            Leads Profile
                          </Heading>
                          <Divider />
                          <Flex direction={"column"} gap={5}>
                            <Flex w={"100%"} my={5}>
                              <Box w={"30%"}>
                                <Text fontWeight={"600"}>Affiliate </Text>
                                <Text fontWeight={"600"}> Organization</Text>
                              </Box>
                              <Flex w={"70%"} gap={5}>
                                <Avatar
                                  name={item.aff_org.companyname}
                                  width="40px"
                                  height="40px"
                                  // src={item.bannerImage}
                                />
                                <Flex direction={"column"} gap={3}>
                                  <Text>{item.aff_org.companyname}</Text>
                                  <Flex direction={["column", "row"]}>
                                    <Button
                                      borderRadius="8px"
                                      mr={3}
                                      onClick={() => {
                                        onClose();
                                        onCloseConv();
                                        handleViewAff(item?.aff_orgId);
                                      }}
                                      fontWeight="500"
                                      fontSize={{ base: "12px", md: "14px" }}
                                      lineHeight={{ base: "18px", md: "21px" }}
                                      color="#000"
                                      p="7px 16px"
                                      bgColor="#fff"
                                      border="0.5px solid rgba(229, 228, 228, 1)"
                                    >
                                      Affiliate Profile
                                    </Button>

                                    <Button
                                      borderRadius="8px"
                                      onClick={() => {
                                        onClose();
                                        onOpenConv();
                                        getSales(
                                          `/events/conversion/get-conversions/${item.campaignId}?customer_id=${item.customer_id}`,
                                          setsales
                                        );
                                      }}
                                      fontWeight="500"
                                      fontSize={{ base: "12px", md: "14px" }}
                                      lineHeight={{ base: "18px", md: "21px" }}
                                      color="#fff"
                                      p="7px 16px"
                                      bgColor="#7F56D9"
                                      border="0.5px solid rgba(229, 228, 228, 1)"
                                    >
                                      View History
                                    </Button>
                                  </Flex>
                                </Flex>
                              </Flex>
                            </Flex>
                            <Divider />

                            {item?.name && (
                              <>
                                <Flex w={"100%"}>
                                  <Flex w={"30%"}>
                                    <Text>First Name</Text>
                                  </Flex>
                                  <Flex w={"70%"}>
                                    <Box
                                      w={"90%"}
                                      p={3}
                                      bgColor={"#f2f4fa"}
                                      border="0.5px solid #c4c4c4"
                                      borderRadius={10}
                                    >
                                      <Text>{item?.name}</Text>
                                    </Box>
                                  </Flex>
                                </Flex>
                                <Divider />
                              </>
                            )}
                            {item?.phone_number && (
                              <>
                                <Flex w={"100%"}>
                                  <Flex w={"30%"}>
                                    <Text>Phone Number</Text>
                                  </Flex>
                                  <Flex w={"70%"}>
                                    <Box
                                      w={"90%"}
                                      p={3}
                                      bgColor={"#f2f4fa"}
                                      border="0.5px solid #c4c4c4"
                                      borderRadius={10}
                                    >
                                      <Text>{item?.phone_number}</Text>
                                    </Box>
                                  </Flex>
                                </Flex>
                                <Divider />
                              </>
                            )}

                            {item?.email && (
                              <>
                                <Flex w={"100%"}>
                                  <Flex w={"30%"}>
                                    <Text>Email</Text>
                                  </Flex>
                                  <Flex w={"70%"}>
                                    <Box
                                      w={"90%"}
                                      p={3}
                                      bgColor={"#f2f4fa"}
                                      border="0.5px solid #c4c4c4"
                                      borderRadius={10}
                                    >
                                      <Text>{item?.email}</Text>
                                    </Box>
                                  </Flex>
                                </Flex>
                                <Divider />
                              </>
                            )}

                            {item?.country && (
                              <>
                                <Flex w={"100%"}>
                                  <Flex w={"30%"}>
                                    <Text>Country</Text>
                                  </Flex>
                                  <Flex w={"70%"}>
                                    <Box
                                      w={"90%"}
                                      p={3}
                                      bgColor={"#f2f4fa"}
                                      border="0.5px solid #c4c4c4"
                                      borderRadius={10}
                                    >
                                      <Text>{item?.country}</Text>
                                    </Box>
                                  </Flex>
                                </Flex>
                                <Divider />
                              </>
                            )}

                            {item?.state && (
                              <>
                                <Flex w={"100%"}>
                                  <Flex w={"30%"}>
                                    <Text>State</Text>
                                  </Flex>
                                  <Flex w={"70%"}>
                                    <Box
                                      w={"90%"}
                                      p={3}
                                      bgColor={"#f2f4fa"}
                                      border="0.5px solid #c4c4c4"
                                      borderRadius={10}
                                    >
                                      <Text>{item?.state}</Text>
                                    </Box>
                                  </Flex>
                                </Flex>
                                <Divider />
                              </>
                            )}
                          </Flex>
                        </Box>
                      </ModalBody>

                      <ModalFooter justifyContent="flex-end">
                        <Box width={{ base: "40%", md: "50%" }}></Box>
                        {/* <Button
                          textColor="#000"
                          bgColor="#fff"
                          onClick={onClose}
                          fontWeight="medium"
                        >
                          Cancel
                        </Button>
                        <Spacer /> */}
                        <Button
                          textColor="#5C14CE"
                          bgColor="#fff"
                          // @ts-ignore
                          onClick={() => onClose()}
                          fontWeight="medium"
                          // isLoading={createBusy}
                        >
                          Exit
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                  <Modal
                    isOpen={isOpenConv}
                    onClose={onCloseConv}
                    size={"4xl"}
                    isCentered
                  >
                    <ModalOverlay />
                    <ModalContent
                      // mx={{ base: ".6em", md: "auto" }}
                      // marginTop={{ base: "15em", md: "18em" }}
                      borderRadius={10}
                      isTruncated
                      p={7}
                    >
                      <ModalCloseButton />
                      <ModalBody>
                        <Box py="1.5em">
                          <Heading fontSize="xl" mb="1.5em">
                            Leads Profile
                          </Heading>
                          <Divider />
                          <Flex direction={"column"} gap={5}>
                            <Flex w={"100%"} my={5}>
                              <Box w={"30%"}>
                                <Text fontWeight={"600"}>Affiliate </Text>
                                <Text fontWeight={"600"}> Organization</Text>
                              </Box>
                              <Flex w={"70%"} gap={5}>
                                <Avatar
                                  name={item.aff_org.companyname}
                                  width="40px"
                                  height="40px"
                                  // src={item.bannerImage}
                                />

                                <Flex direction={"column"} gap={3}>
                                  <Text>{item.aff_org.companyname}</Text>
                                  <Flex direction={["column", "row"]}>
                                    <Button
                                      borderRadius="8px"
                                      mr={3}
                                      onClick={() => {
                                        onClose();
                                        onCloseConv();
                                        handleViewAff(item?.aff_orgId);
                                      }}
                                      fontWeight="500"
                                      fontSize={{ base: "12px", md: "14px" }}
                                      lineHeight={{ base: "18px", md: "21px" }}
                                      color="#000"
                                      p="7px 16px"
                                      bgColor="#fff"
                                      border="0.5px solid rgba(229, 228, 228, 1)"
                                    >
                                      Affiliate Profile
                                    </Button>
                                    {/* onClick={() => {
                                      onCloseConv();
                                      onOpen();
                                    }} */}
                                    <Button
                                      borderRadius="8px"
                                      onClick={() => {
                                        onClose();
                                        onOpenConv();
                                        getSales(
                                          `/events/conversion/get-conversions/${item.campaignId}?customer_id=${item.customer_id}`,
                                          setsales
                                        );
                                      }}
                                      fontWeight="500"
                                      fontSize={{ base: "12px", md: "14px" }}
                                      lineHeight={{ base: "18px", md: "21px" }}
                                      color="#fff"
                                      p="7px 16px"
                                      bgColor="#7F56D9"
                                      border="0.5px solid rgba(229, 228, 228, 1)"
                                      isDisabled
                                    >
                                      View History
                                    </Button>
                                  </Flex>
                                </Flex>
                              </Flex>
                            </Flex>
                            <Divider />

                            <Table>
                              <Thead>
                                <Tr height="53px" bgColor="#F1F4FA">
                                  {titless.map((title, i) => (
                                    <Th
                                      color="rgba(50, 50, 93, 0.8)"
                                      textTransform="uppercase"
                                      lineHeight="18px"
                                      fontSize="12px"
                                      fontWeight="500"
                                      key={i}
                                      whiteSpace={{
                                        base: "nowrap",
                                        md: "normal",
                                      }}
                                    >
                                      {title}
                                    </Th>
                                  ))}
                                </Tr>
                              </Thead>

                              <Tbody>
                                {sales.map((item: any, index: number) => {
                                  return (
                                    <Tr key={index} height="80px" width="100%">
                                      <Td
                                        width={{
                                          base: "max-content",
                                          md: "",
                                        }}
                                      >
                                        <Text
                                          color="#32325D"
                                          lineHeight={{
                                            base: "18px",
                                            md: "21px",
                                          }}
                                          fontSize={{
                                            base: "12px",
                                            md: "14px",
                                          }}
                                          fontWeight="500"
                                        >
                                          {item?.createdAt?.split("T")[0] || ""}
                                        </Text>
                                      </Td>

                                      <Td maxWidth="120px">
                                        <Tooltip
                                          label={item?.aff_org.companyname}
                                          fontSize="md"
                                        >
                                          <Text
                                            color="#32325D"
                                            lineHeight={{
                                              base: "18px",
                                              md: "21px",
                                            }}
                                            fontSize={{
                                              base: "12px",
                                              md: "14px",
                                            }}
                                            fontWeight="500"
                                            overflow="hidden"
                                            textOverflow="ellipsis"
                                            whiteSpace="nowrap"
                                          >
                                            {item?.aff_org.companyname || "-"}
                                          </Text>
                                        </Tooltip>
                                      </Td>

                                      <Td>
                                        <Text
                                          color="#32325D"
                                          lineHeight={{
                                            base: "18px",
                                            md: "21px",
                                          }}
                                          fontSize={{
                                            base: "12px",
                                            md: "14px",
                                          }}
                                          fontWeight="500"
                                        >
                                          $ {item?.amount || 0}
                                        </Text>
                                      </Td>

                                      <Td>
                                        <Text
                                          color="#32325D"
                                          lineHeight={{
                                            base: "18px",
                                            md: "21px",
                                          }}
                                          fontSize={{
                                            base: "12px",
                                            md: "14px",
                                          }}
                                          fontWeight="500"
                                        >
                                          $ {item?.commission?.amount || 0}
                                        </Text>
                                      </Td>

                                      <Td>
                                        <Flex alignItems="center">
                                          <Box
                                            backgroundColor={getStatusColor(
                                              item?.status
                                            )}
                                            height="12px"
                                            width="12px"
                                            borderRadius="50%"
                                          ></Box>
                                          <Box>
                                            <Text
                                              color="#32325D"
                                              lineHeight="18px"
                                              fontSize="12px"
                                              fontWeight="500"
                                              ml="6px"
                                              textTransform="capitalize"
                                            >
                                              {item?.status}
                                            </Text>
                                          </Box>
                                        </Flex>
                                      </Td>
                                    </Tr>
                                  );
                                })}
                              </Tbody>
                            </Table>
                          </Flex>
                        </Box>
                      </ModalBody>

                      <ModalFooter justifyContent="space-between">
                        {/* <Box width={{ base: "40%", md: "50%" }}></Box> */}
                        <Button
                          textColor="#000"
                          bgColor="#fff"
                          onClick={() => {
                            onCloseConv();
                            onOpen();
                          }}
                          fontWeight="medium"
                        >
                          <Flex align={"center"} gap={3} color={"#5C14CE"}>
                            <ArrowBackIcon />
                            <Text>Back to the previous page</Text>
                          </Flex>
                        </Button>
                        <Spacer />
                        <Button
                          textColor="#5C14CE"
                          bgColor="#fff"
                          // @ts-ignore
                          onClick={() => onCloseConv()}
                          fontWeight="medium"
                          // isLoading={createBusy}
                        >
                          Exit
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                  <Td>
                    <Text
                      color="#32325D"
                      lineHeight={{ base: "18px", md: "21px" }}
                      fontSize={{ base: "12px", md: "14px" }}
                      fontWeight="500"
                    >
                      {/* {item.createdAt?.split("T")[0] || ""} */}
                      {moment(item.createdAt).format("MMM Do YYYY")}
                    </Text>
                  </Td>

                  <Td>
                    <Text
                      color="#32325D"
                      lineHeight={{ base: "18px", md: "21px" }}
                      fontSize={{ base: "12px", md: "14px" }}
                      fontWeight="500"
                    >
                      {item?.aff_org?.companyname || "-"}
                    </Text>
                  </Td>

                  <Td>
                    <Text
                      color="#32325D"
                      lineHeight={{ base: "18px", md: "21px" }}
                      fontSize={{ base: "12px", md: "14px" }}
                      fontWeight="500"
                    >
                      {item?.conversion.product_id || "-"}
                    </Text>
                  </Td>
                  <Td>
                    <Flex alignItems="center">
                      <Text
                        color={
                          Object.keys(item?.conversion).length == 0
                            ? "#713f11"
                            : "#14532d"
                        }
                        lineHeight={{ base: "18px", md: "21px" }}
                        fontSize={{ base: "12px", md: "14px" }}
                        fontWeight="500"
                        bgColor={
                          Object.keys(item?.conversion).length == 0
                            ? "#fef9c3"
                            : "#dcfce7"
                        }
                        p={2}
                        flexDir={"row"}
                        display={"flex"}
                        alignItems={"center"}
                        gap={2}
                        borderRadius={10}
                      >
                        <Box
                          backgroundColor={
                            Object.keys(item?.conversion).length == 0
                              ? getStatusColor("pending")
                              : getStatusColor("Converted")
                          }
                          height="12px"
                          width="12px"
                          borderRadius="50%"
                        ></Box>
                        {Object.keys(item?.conversion).length == 0 ? (
                          <Text>Pending</Text>
                        ) : (
                          <Text>Converted</Text>
                        )}
                      </Text>
                    </Flex>
                  </Td>

                  <Td>
                    <Button
                      borderRadius="8px"
                      fontWeight="500"
                      fontSize={{ base: "12px", md: "14px" }}
                      lineHeight={{ base: "18px", md: "21px" }}
                      color="#7211D4"
                      p="7px 16px"
                      bgColor="#f1f3f9"
                      border="0.5px solid #f1f3f9"
                      onClick={() => {
                        // setcurrentLead(item);
                        onOpen();
                      }}
                    >
                      View Profile
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>

      {!campaignLeads.length && (
        <Box
          w="100%"
          height="50vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={{ base: "0px 16px", md: "0px" }}
        >
          <Image
            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
            height="250px"
            objectFit="contain"
            mb="16px"
          />

          <Text
            mb="6px"
            fontWeight="600"
            fontSize="14px"
            lineHeight="21px"
            color="#32325D"
            textAlign="center"
          >
            No data available yet, when you have Leads you will see them here.
          </Text>

          {/* <Text color="#7211D4" fontWeight="600" fontSize="12px" lineHeight="18px">
        Coming Soon
      </Text> */}
        </Box>
      )}
    </Box>
  );
};

export default CampaignLeads;
