import {
  Box,
  Stack,
  Text,
  Image,
  GridItem,
  Grid,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Avatar,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import {
  MdArrowForward,
  MdChevronRight,
  MdKeyboardBackspace,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useCurrentOrg } from "../../../hooks/useCurrentOrg";
import Filter from "./filter";
import PaginationComponent from "./paginationWrapper";
import Fuse from "fuse.js";
import useUserToken from "../../../hooks/useUserToken";
import { gsap, Power2 } from "gsap";

const Affiliates = () => {
  const navigate = useNavigate();
  const [_, isVerified] = useCurrentOrg();

  const token = useUserToken();

  const [filterData, setfilterData] = useState<any>({
    country: [],
    product: [],
    experience: [],
    search: [],
  });

  const [paginationData, setpaginationData] = useState({
    currentPage: 1,
    pageSize: 6,
  });

  const [fetchedAffiliates, setfetchedAffiliates] = useState<any[]>([]);
  const [searchResult, setsearchResult] = useState<any>([]);
  const [searchVal, setsearchVal] = useState<string>("");
  const [filterInfo, setfilterInfo] = useState<any>(null);
  const [advancedFilterChange, setadvancedFilterChange] =
    useState<boolean>(false);
  const [listAffiliates, setlistAffiliates] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchedAffiliates, setsearchedAffiliates] = useState<any[]>([]);
  const [isLoadingSearchResults, setisLoadingSearchResults] = useState(false);

  const options = {
    keys: ["user.firstname", "user.lastname"],
  };
  const fuse = new Fuse(listAffiliates, options);

  const {
    isOpen: onModalIsOpen,
    onOpen: onModalOnOpen,
    onClose,
  } = useDisclosure();

  const findAffiliates = () => {
    setLoading(true);
    let bearerAuth = token;

    const baseUrl = "/explore/find-affiliates";

    let queryString = "";

    const countryy = filterData.country;

    const affyrs = filterData.experience;

    const prod = filterData.product;

    if (countryy.length >= 1) {
      queryString += `country=${countryy + ""}`;
    }
    if (affyrs.length >= 1) {
      queryString +=
        queryString.length > 0
          ? `&affiliate_years=${affyrs + ""}`
          : `affiliate_years=${affyrs + ""}`;
    }
    if (prod.length >= 1) {
      queryString +=
        queryString.length > 0
          ? `&productType=${(prod + "").toLowerCase()}`
          : `productType=${(prod + "").toLowerCase()}`;
    }

    const finalUrl =
      queryString.length > 0 ? `${baseUrl}?${queryString}` : baseUrl;

    console.log(finalUrl);

    fetch(process.env.REACT_APP_API_ENDPOINT + finalUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("recommended", data);
        if (data.success) {
          setLoading(false);
          setlistAffiliates(data.data.affiliates);
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("find affiliates: =>" + err);
      });
  };

  const getPageData = () => {
    if (!listAffiliates.length) return;

    setsearchedAffiliates([]);
    setLoading(true);

    let startIndex = (paginationData.currentPage - 1) * paginationData.pageSize;
    let endIndex = startIndex + paginationData.pageSize;

    if (!searchVal && !filterInfo) {
      setfetchedAffiliates(
        listAffiliates.filter(
          (item: any, index: number) => index >= startIndex && index < endIndex
        )
      );
    } else {
      setsearchedAffiliates(
        searchResult.filter(
          (item: any, index: number) => index >= startIndex && index < endIndex
        )
      );
    }

    setLoading(false);
  };

  const checkFilteredItems = (arr: any[]) => {
    let foundItems: any[] = [];
    let checked: any[] = [];

    if (arr) {
      arr.forEach((item: any) => {
        if (
          filterData.country.includes(item?.country) &&
          !checked.includes(item.id || "")
        ) {
          foundItems = [...foundItems, item];
          checked.push(item.id || "");
        }

        if (
          filterData.experience.includes(item.affiliate_years) &&
          !checked.includes(item.id || "")
        ) {
          foundItems = [...foundItems, item];
          checked.push(item.id || "");
        }

        if (item.productType && item.productType.length) {
          for (let i = 0; i < item.productType.length; i++) {
            if (
              filterData.product.includes(item.productType[i].toUpperCase()) &&
              !checked.includes(item.id || "")
            ) {
              foundItems = [...foundItems, item];
              checked.push(item.id || "");
              break;
            }
          }
        }
      });
    }

    return foundItems;
  };

  const triggerOnFilter = () => {
    if (!listAffiliates.length) return;
    setisLoadingSearchResults(true);
    const dataSets = checkFilteredItems(listAffiliates);

    setsearchResult(dataSets);
    setisLoadingSearchResults(false);

    if (filterData.country.length > 0) return setfilterInfo(dataSets);
    if (filterData.product.length > 0) return setfilterInfo(dataSets);
    if (filterData.experience.length > 0) return setfilterInfo(dataSets);

    return setfilterInfo(null);
  };

  const checkForNoResults = () => {
    if (loading) return false;

    if (!searchedAffiliates.length && searchVal) return true;
    if (!searchedAffiliates.length && filterInfo) return true;
    if (!listAffiliates.length) return true;

    return false;
  };

  useEffect(() => {
    findAffiliates();
  }, [filterData]);

  useEffect(() => {
    getPageData();
  }, [listAffiliates, paginationData, searchResult]);

  useEffect(() => {
    setsearchResult(fuse.search(searchVal));
    setpaginationData({
      currentPage: 1,
      pageSize: 6,
    });
  }, [searchVal]);

  useEffect(() => {
    triggerOnFilter();
  }, [advancedFilterChange]);

  useEffect(() => {
    gsap.fromTo(
      ".loading",
      {
        opacity: 0,
        ease: Power2.easeInOut,
        scale: 0,
        y: 80,
      },
      {
        opacity: 1,
        duration: 1.5,
        scale: 1,
        ease: Power2.easeInOut,
        repeat: -1,
        y: 0,
        yoyo: true,
      }
    );
  }, []);

  const space_years = useCallback((timeframe: any) => {
    let result = "";

    if (!timeframe) return result;

    let splited = timeframe.split("-");

    result = splited[0] + " - " + splited[1];

    return result;
  }, []);

  const fmtContentType = useCallback((text: any) => {
    if (!text) return "";

    let splitted = text.split("_");

    let firstWord = splitted[0][0].toUpperCase() + splitted[0].substr(1);

    if (splitted.length < 2) return firstWord;
    let secondWord = splitted[1][0].toUpperCase() + splitted[1].substr(1);

    let result = firstWord + " " + secondWord;

    return result;
  }, []);

  return (
    <Stack bgColor="#F1F4FA" pb="20px">
      <Box
        display="flex"
        mb="30px"
        padding="12px 20px"
        alignItems="center"
        bgColor="#F3F3F9"
        css={{
          boxShadow: "0px 12px 24px rgba(190, 190, 190, 0.25)",
        }}
      >
        <Text
          fontSize="12px"
          fontWeight="500"
          lineHeight="18px"
          color="#32325D"
          mr="5px"
          cursor="pointer"
          _hover={{ textDecoration: "underline" }}
          onClick={() => navigate(-1)}
        >
          Explore
        </Text>

        <MdChevronRight size={20} color="#32325D" />

        <Text
          fontSize="12px"
          lineHeight="18px"
          color="#32325D"
          fontWeight="400"
          ml="5px"
        >
          Affiliates
        </Text>
      </Box>

      <Box padding={{ base: "0px", md: "0px 20px" }}>
        <Stack
          bgColor="white"
          spacing={30}
          pl="40px"
          pr="40px"
          pt="22px"
          pb="100px"
        >
          <Box
            display="flex"
            alignItems="center"
            cursor="pointer"
            onClick={() => navigate(`/explore`)}
          >
            <MdKeyboardBackspace color="#32325D" />

            <Text
              color="#32325D"
              lineHeight="40px"
              fontSize="14px"
              fontWeight="400"
              display={{ base: "none", md: "block" }}
              pl="8px"
            >
              Back to the previous page
            </Text>

            <Text
              color="#32325D"
              lineHeight="40px"
              fontSize="14px"
              fontWeight="400"
              display={{ base: "block", md: "none" }}
              pl="8px"
            >
              Back
            </Text>
          </Box>

          <Filter
            filterData={filterData}
            setfilterData={setfilterData}
            searchVal={searchVal}
            setSearchVal={setsearchVal}
            advancedFilterChange={advancedFilterChange}
            setAdvancedFilterChange={setadvancedFilterChange}
            setFilterInfo={setfilterInfo}
            setsearchResult={setsearchResult}
          />

          {checkForNoResults() && (
            <Box margin="0px auto" w="80%" p="45px 20px" background=" #F0F5FF">
              <Text
                fontWeight="600"
                fontSize="12px"
                lineHeight="22px"
                color="#32325D"
                textAlign="center"
              >
                No Affiliates Found
              </Text>
            </Box>
          )}

          {loading && (
            <Box
              display="flex"
              justifyContent="center"
              h="40vh"
              alignItems="center"
            >
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Image
                  className="loading"
                  src="/assets/logo.png"
                  height="80px"
                />
                <Text
                  fontWeight="400"
                  fontSize="16px"
                  lineHeight="70px"
                  color="#000000"
                  mt="-15px"
                >
                  Loading....
                </Text>
              </Box>
            </Box>
          )}

          {filterInfo && (
            <Grid
              templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
              gap="18px"
            >
              <Box>
                <Text
                  fontSize="12px"
                  lineHeight="18px"
                  fontWeight="500"
                  color="#32325D"
                >
                  Recommended
                </Text>
              </Box>
              {searchedAffiliates?.map((item: any) => {
                return (
                  <GridItem
                    width="100%"
                    borderRadius="8px"
                    padding="25px"
                    key={item?.id}
                    border="1px solid rgba(50, 50, 93, 0.1)"
                  >
                    <Box
                      mb="26px"
                      filter="auto"
                      blur={isVerified ? "0px" : "4px"}
                      display="flex"
                      alignItems="center"
                    >
                      {item.user.profile_image ? (
                        <Image
                          rounded="full"
                          src={item?.user.profile_image}
                          height="50px"
                          width="50px"
                        />
                      ) : (
                        <Avatar
                          name={item.user.firstname + " " + item.user.lastname}
                        />
                      )}
                      <Text
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="21px"
                        color="#32325D"
                        ml="8px"
                      >
                        {item?.user.firstname + " " + item?.user.lastname}
                      </Text>
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="18px"
                        color="rgba(50, 50, 93, 0.8)"
                        opacity="0.8"
                      >
                        Affiliate Type
                      </Text>

                      <Text
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="21px"
                        color="#32325D"
                      >
                        {fmtContentType(item?.affiliate_type)}
                      </Text>
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt="8px"
                      borderBottom=" 1px solid rgba(50, 50, 93, 0.1)"
                      pb="15px"
                      mb="9px"
                    >
                      <Text
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="18px"
                        color="rgba(50, 50, 93, 0.8)"
                        opacity="0.8"
                      >
                        Experience
                      </Text>

                      <Text
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="21px"
                        color="#32325D"
                      >
                        {space_years(item?.affiliate_years)}
                      </Text>
                    </Box>

                    <Button
                      bgColor="#F0F3F9"
                      border=" 0.5px solid #E5E4E4"
                      borderRadius="8px"
                      width="100%"
                      onClick={
                        isVerified
                          ? () => navigate(`/explore/affiliate?id=${item?.id}`)
                          : onModalOnOpen
                      }
                    >
                      <Text
                        fontWeight="500"
                        fontSize="16px"
                        lineHeight="21px"
                        color=" #7211D4"
                        mr="7px"
                      >
                        View
                      </Text>

                      <MdArrowForward color="#7211D4" size={16} />
                    </Button>
                  </GridItem>
                );
              })}
            </Grid>
          )}

          {searchVal && (
            <Grid
              templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
              gap="18px"
            >
              <Box>
                <Text
                  fontSize="12px"
                  lineHeight="18px"
                  fontWeight="500"
                  color="#32325D"
                >
                  Recommended
                </Text>
              </Box>
              {searchedAffiliates?.map((item: any) => {
                return (
                  <GridItem
                    width="100%"
                    borderRadius="8px"
                    padding="25px"
                    key={item?.item.id}
                    border="1px solid rgba(50, 50, 93, 0.1)"
                  >
                    <Box
                      mb="26px"
                      filter="auto"
                      blur={isVerified ? "0px" : "4px"}
                      display="flex"
                      alignItems="center"
                    >
                      {item?.item.user.profile_image ? (
                        <Image
                          rounded="full"
                          src={item?.item.user.profile_image}
                          height="50px"
                          width="50px"
                        />
                      ) : (
                        <Avatar
                          name={
                            item?.item.user.firstname +
                            " " +
                            item?.item.user.lastname
                          }
                        />
                      )}
                      <Text
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="21px"
                        color="#32325D"
                        ml="8px"
                      >
                        {item?.item.user.firstname +
                          " " +
                          item?.item.user.lastname}
                      </Text>
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="18px"
                        color="rgba(50, 50, 93, 0.8)"
                        opacity="0.8"
                      >
                        Affiliate Type
                      </Text>

                      <Text
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="21px"
                        color="#32325D"
                      >
                        {fmtContentType(item?.item.affiliate_type)}
                      </Text>
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt="8px"
                      borderBottom=" 1px solid rgba(50, 50, 93, 0.1)"
                      pb="15px"
                      mb="9px"
                    >
                      <Text
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="18px"
                        color="rgba(50, 50, 93, 0.8)"
                        opacity="0.8"
                      >
                        Experience
                      </Text>

                      <Text
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="21px"
                        color="#32325D"
                      >
                        {space_years(item?.item.affiliate_years) + " years"}
                      </Text>
                    </Box>

                    <Button
                      bgColor="#F0F3F9"
                      border=" 0.5px solid #E5E4E4"
                      borderRadius="8px"
                      width="100%"
                      onClick={
                        isVerified
                          ? () =>
                              navigate(`/explore/affiliate?id=${item?.item.id}`)
                          : onModalOnOpen
                      }
                    >
                      <Text
                        fontWeight="500"
                        fontSize="16px"
                        lineHeight="21px"
                        color=" #7211D4"
                        mr="7px"
                      >
                        View
                      </Text>

                      <MdArrowForward color="#7211D4" size={16} />
                    </Button>
                  </GridItem>
                );
              })}
            </Grid>
          )}

          {!searchVal && !filterInfo && (
            <>
              <Box>
                <Text
                  fontSize="12px"
                  lineHeight="18px"
                  fontWeight="500"
                  color="#32325D"
                >
                  Recommended
                </Text>
              </Box>

              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(3, 1fr)",
                }}
                gap="18px"
              >
                {fetchedAffiliates.map((item) => (
                  <GridItem
                    width="100%"
                    borderRadius="8px"
                    padding="25px"
                    key={item?.id}
                    border="1px solid rgba(50, 50, 93, 0.1)"
                  >
                    <Box
                      mb="26px"
                      filter="auto"
                      blur={isVerified ? "0px" : "4px"}
                      display="flex"
                      alignItems="center"
                    >
                      {item?.user.profile_image ? (
                        <Image
                          rounded="full"
                          src={item.user.profile_image}
                          height="50px"
                          width="50px"
                        />
                      ) : (
                        <Avatar
                          name={item.user.firstname + " " + item.user.lastname}
                        />
                      )}
                      <Text
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="21px"
                        color="#32325D"
                        ml="8px"
                      >
                        {item.user.firstname + " " + item.user.lastname}
                      </Text>
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="18px"
                        color="rgba(50, 50, 93, 0.8)"
                        opacity="0.8"
                      >
                        Affiliate Type
                      </Text>

                      <Text
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="21px"
                        color="#32325D"
                      >
                        {fmtContentType(item.affiliate_type)}
                      </Text>
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt="8px"
                      borderBottom=" 1px solid rgba(50, 50, 93, 0.1)"
                      pb="15px"
                      mb="9px"
                    >
                      <Text
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="18px"
                        color="rgba(50, 50, 93, 0.8)"
                        opacity="0.8"
                      >
                        Experience
                      </Text>

                      <Text
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="21px"
                        color="#32325D"
                      >
                        {item.affiliate_years}
                      </Text>
                    </Box>

                    <Button
                      bgColor="#F0F3F9"
                      border=" 0.5px solid #E5E4E4"
                      borderRadius="8px"
                      width="100%"
                      onClick={
                        isVerified
                          ? () => navigate(`/explore/affiliate?id=${item.id}`)
                          : onModalOnOpen
                      }
                    >
                      <Text
                        fontWeight="500"
                        fontSize="16px"
                        lineHeight="21px"
                        color=" #7211D4"
                        mr="7px"
                      >
                        View
                      </Text>

                      <MdArrowForward color="#7211D4" size={16} />
                    </Button>
                  </GridItem>
                ))}
              </Grid>
            </>
          )}

          <Modal onClose={onClose} size="lg" isCentered isOpen={onModalIsOpen}>
            <ModalOverlay />
            <ModalContent borderRadius="10px">
              <ModalHeader display="flex" justifyContent="right">
                <Image
                  onClick={onClose}
                  cursor="pointer"
                  ml="7px"
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/3b803641-20b6-4e47-b554-8336ebd3ec5b.svg"
                />
              </ModalHeader>
              <ModalBody
                paddingBottom="26px"
                borderBottom="1px solid rgba(50, 50, 93, 0.3)"
              >
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="30px"
                  color=" rgba(50, 50, 93, 0.8)"
                  textAlign="center"
                >
                  Complete your organisation information to view this affiliate
                </Text>
              </ModalBody>
              <ModalFooter display="flex" justifyContent="left">
                <Button
                  ml="15px"
                  bgColor=" #F0F5FF"
                  color="#5C14CE"
                  borderRadius="8px"
                  onClick={onClose}
                >
                  Proceed
                  <Image
                    cursor="pointer"
                    ml="10px"
                    mt="1px"
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/37d9e26a-0ec4-462d-98ac-8fca88493c0b.svg"
                  />
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <PaginationComponent
            paginationData={paginationData}
            setPaginationData={setpaginationData}
            dataLength={
              searchVal || filterInfo
                ? searchResult.length
                : listAffiliates.length
            }
          />
        </Stack>
      </Box>
    </Stack>
  );
};

export default Affiliates;
