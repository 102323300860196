import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { getCurrentUserInstance } from "../../../state/localstorage";
import moment from "moment";

const Message = ({ messageData }: { messageData: any }) => {
  const user = getCurrentUserInstance();
  return (
    <Box
      ml={user.id == messageData.fromUser.id ? "auto" : "0px"}
      w="100%"
      pr="24px"
    >
      <Flex
        flexDir={user.id == messageData.fromUser.id ? "row-reverse" : "row"}
      >
        <Box
          mr={user.id == messageData.fromUser.id ? "12px" : "0px"}
          ml={user.id == messageData.fromUser.id ? "0px" : "12px"}
          display="flex"
          flexDir="column"
          alignItems={user.id == messageData.fromUser.id ? "end" : "start"}
        >
          <Text
            color={
              user.id == messageData.fromUser.id
                ? "white"
                : "rgba(50, 50, 93, 1)"
            }
            bgColor={
              user.id == messageData.fromUser.id
                ? "rgba(114, 17, 212, 1)"
                : "rgba(244, 247, 255, 1)"
            }
            w="max-content"
            p="12px 14px"
            maxW="300px"
            roundedTop="11px"
            roundedBottomLeft="11px"
            fontSize="0.75rem"
            minW="100px"
            fontWeight="500"
          >
            {messageData.message || ""}
          </Text>

          <Flex alignItems="center">
            <Text
              fontSize="0.75rem"
              fontWeight="500"
              lineHeight="18px"
              color="rgba(50, 50, 93, 1)"
            >
              {user.id == messageData.fromUser.id
                ? messageData.fromUser.firstname
                : messageData.toUser.firstname}
            </Text>

            <Box
              h="6px"
              w="6px"
              mx="6px"
              bgColor="rgba(132, 132, 158, 1)"
              rounded="full"
            />

            <Text
              fontSize="0.75rem"
              fontWeight="500"
              lineHeight="18px"
              color="rgba(50, 50, 93, 0.7)"
            >
              {user.id == messageData.fromUser.id
                ? moment(messageData.updatedAt).fromNow()
                : moment(messageData.updatedAt).fromNow()}
            </Text>
          </Flex>
        </Box>

        <Avatar
          src={
            user.id == messageData.fromUser.id
              ? messageData.fromUser.profile_image
              : messageData.toUser.profile_image
          }
          name={
            user.id == messageData.fromUser.id
              ? messageData.fromUser.firstname
              : messageData.toUser.firstname
          }
          size="xs"
        />
      </Flex>
    </Box>
  );
};

export default Message;
