import React from "react";
import { Route, Routes } from "react-router-dom";
import Campaign from "../../components/affiliate/campaigns";
import ViewCampaign from "../../components/affiliate/campaigns/view-campaigns";

const AffiliateCampaigns = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Campaign />} />
        <Route path="/view/:id" element={<ViewCampaign />} />
      </Routes>
    </>
  );
};

export default AffiliateCampaigns;
