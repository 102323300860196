import { getCookie } from "cookies-next";
const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const endpoints = {
  campaign: {
    CREATE: {
      STEP_ONE: `${BASE_URL}campaign/create/step1`,
      STEP_TWO: (id: string) => `${BASE_URL}campaign/create/step2/${id}`,
      STEP_FOUR: (id: string) => `${BASE_URL}campaign/create/step4/${id}`,
      STEP_FIVE: (id: string) => `${BASE_URL}campaign/create/step5/${id}`,
    },
    FETCH_ONE: (id: string) => `${BASE_URL}campaign/${id}`,
    UPDATE_ONE: (id: string) => `${BASE_URL}campaign/edit/${id}`,
    VERIFY: (id: string) => `${BASE_URL}campaign/check-verification/${id}`,
  },
  drive: {
    FOLDER: {
      CREATE: `${BASE_URL}drive/add-folder`,
      DELETE: `${BASE_URL}drive/delete-folder`,
      ALL: (campaignId: string) => `${BASE_URL}drive/all-folders/${campaignId}`,
    },
    FILE: {
      UPLOAD: `${BASE_URL}drive/upload-file`,
      DELETE: `${BASE_URL}drive/delete-file`,
    },
    NEWFOLDER: {
      CREATE: (id: string | undefined) =>
        `${BASE_URL}campaign/create/step3/${id}/create-folder`,
      DELETE: (id: any) =>
        `${BASE_URL}campaign/create/step3/${id}/delete-asset`,
      ALL: (id: string) => `${BASE_URL}campaign/create/step3/${id}/all-folders`,
      UPLOAD: (id: any) =>
        `${BASE_URL}campaign/create/step3/${id}/upload-assets`,
      FOLDERASSETS: (id: any, folderId: any) =>
        `${BASE_URL}campaign/create/step3/${id}/folder/
        ${folderId}    
        `,
    },
  },
  photo: {
    UPLOAD: `${BASE_URL}upload/photo`,
    UPLOADASSET: `${BASE_URL}upload/frontend-assets`,
  },
  dashboard: {
    TOPAFFILIATES: `${BASE_URL}/advertiser/top-ten-affiliates`,
    ANALYTICS: `${BASE_URL}/advertiser/analytics`,
  },
};
