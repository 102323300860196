import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Select,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getCurrentUserInstance } from "../state/localstorage";
import useUserToken from "../hooks/useUserToken";
import { useNavigate } from "react-router-dom";
import { apiReqHandler } from "../helper/apiReqHandler";
import { endpoints } from "../utils/endpoints";
import { gsap, Power2 } from "gsap";
import { getCookie } from "cookies-next";
import TopAffiliate from "./top-affiliate";
import ReportsTable from "../components/reports/ReportsTable";
import { MdArrowDropDown, MdFilterList } from "react-icons/md";
import { FaFileExport } from "react-icons/fa";
import useJsonToCSV from "../hooks/useJsonToCSV";
import { RiSearchLine } from "react-icons/ri";
import { TbFileReport } from "react-icons/tb";
import ReportsTsbleCsmp from "../components/reports/ReportsTsbleCsmp";

const Reports = () => {
  const user = getCurrentUserInstance();
  const token = useUserToken();
  const [campaigns, setCampaigns] = useState("");
  const [campaigndata, setCampaigndata] = useState([]);
  const [top10Affiliates, settop10Affiliates] = useState([]);
  const [AffReports, setcAffReports] = useState<any[]>([]);
  const [CampReports, setCampReports] = useState<any[]>([]);
  const [filteredReports, setfilteredReports] = useState<any[]>([]);
  const [searchVal, setsearchVal] = useState<string>("");
  const [filterData, setfilterData] = useState({
    date: "",
    status: "",
  });
  const [analyticsValue, setAnalyticsValue] = useState({
    date: "",
    campaignId: "",
  });
  const [loading, setLoading] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [updateAction, setupdateAction] = useState("");
  const [status, setStatus] = useState("");
  const [campaignReportsDate, setcampaignReportsDate] = useState("");
  const [affiliateReportSort, setaffiliateReportSort] = useState("");
  const [campaignReportSort, setcampaignReportSort] = useState("");
  const [existingAffiliates, setexistingAffiliates] = useState<any[]>([]);
  const [highlighted, sethighlighted] = useState<any[]>([]);
  const [highlighted2, sethighlighted2] = useState<any[]>([]);
  const [dataToDownLoad, setdataToDownLoad] = useState<any[]>([]);
  const [dataToDownLoad2, setdataToDownLoad2] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  let currentData = AffReports.slice(startIndex, endIndex);
  const [currentPageC, setCurrentPageC] = useState(1);
  const pageSizeC = 10;
  const startIndexC = (currentPageC - 1) * pageSizeC;
  const endIndexC = startIndexC + pageSizeC;
  let currentDataC = CampReports.slice(startIndexC, endIndexC);

  const nextPage = () => {
    if (currentPage < Math.ceil(AffReports.length / pageSize)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPageC = () => {
    if (currentPageC < Math.ceil(CampReports.length / pageSizeC)) {
      setCurrentPageC(currentPageC + 1);
    }
  };

  const prevPageC = () => {
    if (currentPageC > 1) {
      setCurrentPageC(currentPageC - 1);
    }
  };
  const getCampaigns = () => {
    // setLoading(true);
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + "campaign/fetch-campaigns", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          const activeCamps: any = data.data.campaigns.filter(
            (item: any, i: number) => item.campaignStatus === "active"
          );
          setCampaigndata(activeCamps);

          // setCampaigndata(data.data.campaigns);
          // setLoading(false);
          // console.log("fetched value", data.data.campaigns);
        } else {
          // formViewRef.current.scrollIntoView();
          // setLoading(false);
        }
      })

      .catch((err) => {
        // @ts-ignore
        setCampaigndata([]);
        if (err.response.status == 402) {
          navigate("/suspended");
        }
        // setLoading(false);
      });
  };
  const getTopAffiliate = async () => {
    // console.log(endpoints.campaign.VERIFY(campaignId));
    const { res } = await apiReqHandler({
      endPoint: endpoints.dashboard.TOPAFFILIATES,
      method: "GET",
      //   @ts-ignore
      bearerAuth: getCookie("access_token"),
    });

    //   @ts-ignore
    settop10Affiliates(res?.data?.data?.top10);

    //   @ts-ignore
  };
  const handleViewAffiliate = (selectedAff: any) => {
    let affInfo = existingAffiliates.find(
      (aff) => aff.aff_orgId === selectedAff
    );

    // if (affInfo) {
    //   setExternalAffInfo(affInfo);
    //   setTabIndex(3);
    // }
  };
  const getAnalytics = async () => {
    setLoading(true);
    const { res, error } = await apiReqHandler({
      endPoint:
        endpoints.dashboard.ANALYTICS +
        `?status=${status ? status : ""}&campaignReportsDate=${
          campaignReportsDate ? campaignReportsDate : ""
        }&affiliateReportSort=${
          affiliateReportSort ? affiliateReportSort : ""
        }&campaignReportSort=${campaignReportSort ? campaignReportSort : ""}`,
      method: "GET",
      //   @ts-ignore
      bearerAuth: getCookie("access_token"),
    });
    setLoading(false);
    //   @ts-ignore

    if (res?.data.data.analytics == undefined) {
      navigate("/login");
    }

    //   @ts-ignore

    //   @ts-ignore
    setAnalytics(res?.data.data.analytics);

    setcAffReports(res?.data.data.analytics.affiliateReports);
    console.log(
      "inside campanalytics",
      res?.data.data.analytics.campaignReports
    );
    setCampReports(res?.data.data.analytics.campaignReports);

    if (error?.response?.status == 402) {
      navigate("/suspended");
    }
  };

  const checkToken = async () => {
    if (!token) return;

    await fetch(process.env.REACT_APP_API_ENDPOINT + `/auth/valid-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data?.valid) {
          return;
        } else {
          window.localStorage.clear();
          sessionStorage.removeItem("currentUserType");
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          navigate("/login");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (token) {
      checkToken();
    }
  }, [token]);

  useEffect(() => {
    getAnalytics();
    gsap.fromTo(
      ".loading",
      {
        opacity: 0,
        ease: Power2.easeInOut,
        scale: 0,
        y: 80,
      },
      {
        opacity: 1,
        duration: 1.5,
        scale: 1,
        ease: Power2.easeInOut,
        repeat: -1,
        y: 0,
        yoyo: true,
      }
    );
  }, [status, campaignReportsDate, affiliateReportSort, campaignReportSort]);

  const ifFilterExists = () => {
    if (isLoading) return false;
    if (searchVal || filterData.date || filterData.status) return true;
    return false;
  };

  useEffect(() => {
    getCampaigns();
    getTopAffiliate();

    if (isOrganization === "yes") {
      window.location.hash = "no-back-button";
      // Again because Google Chrome doesn't insert
      // the first hash into the history
      window.location.hash = "Again-No-back-button";
      window.onhashchange = function () {
        window.location.hash = "no-back-button";
      };
    }
    gsap.fromTo(
      ".loading",
      {
        opacity: 0,
        ease: Power2.easeInOut,
        scale: 0,
        y: 80,
      },
      {
        opacity: 1,
        duration: 1.5,
        scale: 1,
        ease: Power2.easeInOut,
        repeat: -1,
        y: 0,
        yoyo: true,
      }
    );
  }, []);
  const navigate = useNavigate();

  const isOrganization = window.localStorage.getItem("organization");
  const [analytics, setAnalytics]: any = useState({});
  const [filterIsOpen, setfilterIsOpen] = useState(false);
  const [reportSwitch, setreportSwitch] = useState(true);

  useEffect(() => {
    let clonedHighlighted: any[] = [];

    console.log("highlighted", highlighted);
    highlighted.forEach((item: any) => {
      clonedHighlighted.push({
        Organization: item?.aff_organisation_name || "",
        AllCampaigns: item?.totalCampaigns || "0",
        AllSales: item?.totalSales || 0,
        AllLeads: item?.totalLeads,
        AllEarnings: item.totalCommissions || 0,
      });
    });

    // console.log("highlighted", highlighted);
    setdataToDownLoad(clonedHighlighted);
  }, [highlighted]);
  useEffect(() => {
    let clonedHighlighted2: any[] = [];
    console.log("highlighted2", highlighted2);

    highlighted2.forEach((item: any) => {
      clonedHighlighted2.push({
        campaign: item.campaignName,
        DateCreated: item?.date?.split("T")[0],
        Affiliates: item?.affiliates,
        AllSales: item?.totalSales,
        AllEarnings: item.totalCommissions,
        Status: item?.status,
      });
    });

    setdataToDownLoad2(clonedHighlighted2);
  }, [highlighted2]);
  const [csvFileDownloadLink] = useJsonToCSV(
    ["ORGANIZATION", "ALL CAMPAIGNS", "ALL SALES", "ALL LEADS", "ALL EARNINGS"],
    dataToDownLoad
  );

  return (
    <Box padding={["0px 8px", "30px 28px"]}>
      <Box bg="white" w="100%" padding="28px 30px">
        {reportSwitch && (
          <Box pl={["0px", "0px"]}>
            {top10Affiliates?.length > 1 && (
              <TopAffiliate top10Affiliates={top10Affiliates} />
            )}
          </Box>
        )}
        <Box
          display={{ base: "none", md: "flex" }}
          alignItems="center"
          justifyContent="space-between"
          mb="28px"
        >
          <Flex gap={5}>
            <Button
              borderRadius="8px"
              variant="outline"
              color="rgba(50, 50, 93, 1)"
              onClick={() => setfilterIsOpen(!filterIsOpen)}
            >
              <MdFilterList fontSize={15} />
              <Text
                fontSize="14px"
                color="rgba(50, 50, 93, 0.8)"
                fontWeight="500"
                lineHeight="21px"
                ml="7px"
              >
                Filter
              </Text>
            </Button>

            <Button
              borderRadius="8px"
              variant="outline"
              bgColor="#F7F1FD"
              onClick={() => setreportSwitch(!reportSwitch)}
            >
              <TbFileReport fontSize={15} />
              <Text
                fontSize="14px"
                color="rgba(50, 50, 93, 0.8)"
                fontWeight="600"
                lineHeight="21px"
                ml="7px"
              >
                {reportSwitch ? "Campaign Reports" : "Affiliate Reports"}
              </Text>
            </Button>
          </Flex>

          {reportSwitch &&
            (highlighted.length > 0 ? (
              <Link
                href={csvFileDownloadLink}
                download={`Reports.csv`}
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight={{ base: "18px", md: "21px" }}
                fontWeight="500"
                width={{ base: "90px", md: "130px" }}
                borderRadius="8px"
                height="35px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgColor={"#7211D4"}
                _hover={{ textDecoration: "none" }}
              >
                <Flex align={"center"} gap={2} color="#fff">
                  <FaFileExport color="#fff" />
                  {/* ef */}
                  Export CSV
                </Flex>
              </Link>
            ) : (
              <Tooltip
                label={
                  "select the campaign you want to export with the tickbox to continue"
                }
                fontSize="md"
              >
                <Link
                  // href={csvFileDownloadLink}
                  // download={`Reports.csv`}
                  fontSize={{ base: "12px", md: "14px" }}
                  lineHeight={{ base: "18px", md: "21px" }}
                  fontWeight="500"
                  width={{ base: "90px", md: "130px" }}
                  borderRadius="8px"
                  height="35px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bgColor={"#F8FAFB"}
                  _hover={{ textDecoration: "none" }}
                >
                  <Flex align={"center"} gap={2} color="#BCBCCA">
                    <FaFileExport color="#BCBCCA" />
                    {/* ef */}
                    Export CSV
                  </Flex>
                </Link>
              </Tooltip>
            ))}
        </Box>

        {filterIsOpen &&
          (reportSwitch ? (
            <Box
              display={{ base: "flex", md: "flex" }}
              flexDirection={{ base: "column", md: "row" }}
              alignItems={{ base: "start", md: "center" }}
              p="13px 7px"
              bgColor="#F2EFF6"
              mb="8px"
              mt="8px"
            >
              <InputGroup
                w={{ base: "100%", md: "480px" }}
                mb={{ base: "16px", md: "0px" }}
              >
                <InputLeftElement
                  pointerEvents="none"
                  children={<RiSearchLine color="rgba(50, 50, 93, 0.8)" />}
                />
                <Input
                  css={{
                    border: "0.5px solid rgba(50, 50, 93, 0.4)",
                  }}
                  type="text"
                  value={searchVal}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setsearchVal(e.target.value)
                  }
                  fontSize="16px"
                  mr={{ md: "20px" }}
                  bg="white"
                  focusBorderColor="#7211D4"
                  placeholder="Search for Organizations"
                />
              </InputGroup>

              <HStack spacing="10px">
                {/* <Select
                  w={{ base: "100%", md: "170px" }}
                  bg="white"
                  placeholder="Date"
                  value={campaignReportsDate}
                  onChange={(e) => setcampaignReportsDate(e.target.value)}
                  icon={<MdArrowDropDown />}
                >
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </Select> */}
                <Select
                  w={{ base: "100%", md: "170px" }}
                  bg="white"
                  placeholder="Sort by"
                  value={affiliateReportSort}
                  onChange={(e) => {
                    setaffiliateReportSort(e.target.value);
                    // console.log(e.target.value);
                  }}
                  icon={<MdArrowDropDown />}
                >
                  <option value="h2l">Highest to Lowest</option>
                  <option value="l2h">Lowest to Highest</option>
                </Select>

                {/* <Select
                  w={{ base: "100%", md: "170px" }}
                  bg="white"
                  placeholder="Status"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                    console.log(e.target.value);
                  }}
                  icon={<MdArrowDropDown />}
                >
                  <option value="active">Active</option>
                  <option value="paused">Paused</option>
                  <option value="draft">Draft</option>
                </Select> */}
              </HStack>
            </Box>
          ) : (
            <Box
              display={{ base: "flex", md: "flex" }}
              flexDirection={{ base: "column", md: "row" }}
              alignItems={{ base: "start", md: "center" }}
              p="13px 7px"
              bgColor="#F2EFF6"
              mb="8px"
              mt="8px"
            >
              <InputGroup
                w={{ base: "100%", md: "480px" }}
                mb={{ base: "16px", md: "0px" }}
              >
                <InputLeftElement
                  pointerEvents="none"
                  children={<RiSearchLine color="rgba(50, 50, 93, 0.8)" />}
                />
                <Input
                  css={{
                    border: "0.5px solid rgba(50, 50, 93, 0.4)",
                  }}
                  type="text"
                  value={searchVal}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setsearchVal(e.target.value)
                  }
                  fontSize="16px"
                  mr={{ md: "20px" }}
                  bg="white"
                  focusBorderColor="#7211D4"
                  placeholder="Search for Organizations"
                />
              </InputGroup>

              <HStack spacing="10px">
                <Select
                  w={{ base: "100%", md: "170px" }}
                  bg="white"
                  placeholder="Date"
                  value={campaignReportsDate}
                  onChange={(e) => setcampaignReportsDate(e.target.value)}
                  icon={<MdArrowDropDown />}
                >
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </Select>
                <Select
                  w={{ base: "100%", md: "170px" }}
                  bg="white"
                  placeholder="Sort by"
                  value={campaignReportSort}
                  onChange={(e) => {
                    setcampaignReportSort(e.target.value);
                    // console.log(e.target.value);
                  }}
                  icon={<MdArrowDropDown />}
                >
                  <option value="h2l">Highest to Lowest</option>
                  <option value="l2h">Lowest to Highest</option>
                </Select>

                <Select
                  w={{ base: "100%", md: "170px" }}
                  bg="white"
                  placeholder="Status"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                    console.log(e.target.value);
                  }}
                  icon={<MdArrowDropDown />}
                >
                  <option value="active">Active</option>
                  <option value="paused">Paused</option>
                  <option value="draft">Draft</option>
                </Select>
              </HStack>
            </Box>
          ))}

        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            h="40vh"
            alignItems="center"
            pt="20px"
          >
            <Image className="loading" src="/assets/logo.png" height="60px" />
            <Text
              fontWeight="400"
              fontSize="16px"
              lineHeight="70px"
              color="#000000"
              mt="-15px"
            >
              Loading Reports Table...
            </Text>
          </Box>
        ) : reportSwitch ? (
          <ReportsTable
            AffReports={!ifFilterExists() ? currentData : filteredReports}
            highlighted={highlighted}
            sethighlighted={sethighlighted}
            highlighted2={highlighted2}
            sethighlighted2={sethighlighted2}
            handleViewAff={handleViewAffiliate}
            nextPage={nextPage}
            prevPage={prevPage}
            currentPage={currentPage}
            searchVal={searchVal}
          />
        ) : (
          <ReportsTsbleCsmp
            AffReports={!ifFilterExists() ? currentDataC : filteredReports}
            highlighted={highlighted}
            sethighlighted={sethighlighted}
            highlighted2={highlighted2}
            sethighlighted2={sethighlighted2}
            handleViewAff={handleViewAffiliate}
            nextPageC={nextPageC}
            prevPageC={prevPageC}
            currentPageC={currentPageC}
            searchVal={searchVal}
          />
        )}
      </Box>
    </Box>
  );
};

export default Reports;
