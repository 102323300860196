import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Image,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useToast,
  useDisclosure,
  Center,
  ModalCloseButton,
  Checkbox,
  Link,
} from "@chakra-ui/react";
// @ts-ignore
import { useNavigate, useParams } from "react-router-dom";
import { MdChevronRight, MdKeyboardBackspace, MdShare } from "react-icons/md";
import Overview from "../../../explore/explore-campaign-details/tabs/overview";
import useUserToken from "../../../../hooks/useUserToken";
import QuestionsForm from "./QuestionsForm";
import { gsap, Power2 } from "gsap";
import { useCountdown } from "../../../../hooks/useCountDown";
import { AiOutlineLink } from "react-icons/ai";

const ViewCampaign = () => {
  const token = useUserToken("affiliate");
  const navigate = useNavigate();

  let { id } = useParams();

  const [campaign, setCampaign] = useState<any>({
    campaign_mode: "",
    campaign_name: "",
    status: "",
    createdAt: "",
    landing_page_url: "",
    countriesToShip: "",
    id: null,
    banner_image: "",
    background_image: "",
  });
  const [questions, setquestions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [answers, setanswers] = useState<any>({});
  const [currentAffiliate, setcurrentAffiliate] = useState<any>({
    id: null,
    firstname: "",
    lastname: "",
  });
  const [promoteCampaignReq, setpromoteCampaignReq] = useState({
    id: null,
    status: "",
    declinedDate: null,
  });
  const [refreshData, setrefreshData] = useState(0);

  const TEN_DAYS_IN_MS = 10 * 24 * 60 * 60 * 1000;
  let dateTimeAfterTenDays = promoteCampaignReq.declinedDate
    ? new Date(promoteCampaignReq?.declinedDate).getTime() + TEN_DAYS_IN_MS
    : 0;

  const [days, hours, minutes, seconds] = useCountdown(dateTimeAfterTenDays);

  const checkToken = async () => {
    if (!token) return;

    await fetch(process.env.REACT_APP_API_ENDPOINT + `/auth/valid-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data?.valid) {
          return;
        } else {
          window.localStorage.clear();
          sessionStorage.removeItem("currentUserType");
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          navigate("/login");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (token) {
      checkToken();
    }
  }, [token]);

  useEffect(() => {
    if (id) {
      getCampaign(id);
      getCampaignQuestions(id);
      getCurrentAffiliate();
    }
  }, [refreshData]);

  useEffect(() => {
    getPromoteCampaignReq();
  }, [campaign]);

  useEffect(() => {
    gsap.fromTo(
      ".loading",
      {
        opacity: 0,
        ease: Power2.easeInOut,
        scale: 0,
        y: 80,
      },
      {
        opacity: 1,
        duration: 1.5,
        scale: 1,
        ease: Power2.easeInOut,
        repeat: -1,
        y: 0,
        yoyo: true,
      }
    );
  }, []);

  const getCampaign = (id: string | undefined) => {
    setLoading(true);
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + `campaign/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setCampaign(data.data.campaign);
        } else {
          // formViewRef.current.scrollIntoView();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("get campaign => " + err);
        setLoading(false);
      });
  };

  const getCampaignQuestions = (id: string | undefined) => {
    let bearerAuth = token;

    fetch(
      process.env.REACT_APP_API_ENDPOINT + `/campaign/${id}/fetch-questions`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setquestions(data.data.questions);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("get campaign questions => " + err);
        setLoading(false);
      });
  };

  const getCurrentAffiliate = () => {
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + `auth/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setcurrentAffiliate({
            id: data.data.user.id,
            firstname: data.data.user.firstname,
            lastname: data.data.user.lastname,
            roleId: data.data.user?.roleId || "",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("get campaign => " + err);
        setLoading(false);
      });
  };

  const getPromoteCampaignReq = () => {
    if (!campaign.id) return;

    let bearerAuth = token;

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `promote-campaign/affiliate/campaigns?campaignId=${campaign?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data.campaigns.length > 0) {
          setpromoteCampaignReq({
            id: data.data.campaigns[data.data.campaigns.length - 1]?.id,
            status: data.data.campaigns[data.data.campaigns.length - 1]?.status,
            declinedDate:
              data.data.campaigns[data.data.campaigns.length - 1]?.declinedDate,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("affiliate's campaigs => " + err);
      });
  };

  const getStatusColor = (status: string) => {
    if (status === "active") {
      return "#239654";
    }
    if (status === "paused") {
      return "#F3994A";
    }
    if (status === "inactive") {
      return "#EB5757";
    }
  };

  const {
    isOpen: onModalIsOpen,
    onOpen: onModalOnOpen,
    onClose,
  } = useDisclosure();

  const {
    isOpen: isOpen,
    onOpen: onOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const {
    isOpen: isCopyModalOpen,
    onOpen: onCopyModalOpen,
    onClose: onCopyModalClose,
  } = useDisclosure();

  const toast = useToast();
  const [creatingOffers, setCreatingOffers] = useState<boolean>(false);
  const [hasAgreedToTerms, sethasAgreedToTerms] = useState(false);
  const [defaultAns, setdefaultAns] = useState<string>("");

  const JoinCampaign = () => {
    setCreatingOffers(true);

    let inCompleteQuestion: any = null;

    if (!defaultAns) {
      setCreatingOffers(false);

      return toast({
        title: "Join Campaign Error",
        description: `You must provide an answer to how you intend to promote the campaign`,
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }

    for (let i = 0; i < questions.length; i++) {
      let qst = questions[i];

      if (!answers[`q${qst.id}`] && qst.required) {
        inCompleteQuestion = qst;
        setCreatingOffers(false);

        return toast({
          title: "Join Campaign Error",
          description: `The answer to "${inCompleteQuestion.label}" is required`,
          status: "warning",
          duration: 6000,
          isClosable: true,
          position: "top-right",
        });
      }

      if (!answers[`q${qst.id}`]?.answer && qst.required) {
        inCompleteQuestion = qst;
        setCreatingOffers(false);

        return toast({
          title: "Join Campaign Error",
          description: `The answer to "${inCompleteQuestion.label}" is required`,
          status: "warning",
          duration: 6000,
          isClosable: true,
          position: "top-right",
        });
      }
    }

    if (!hasAgreedToTerms) {
      setCreatingOffers(false);

      return toast({
        title: "Join Campaign Error",
        description: `You must agree to Metrics Terms and Conditions`,
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }

    let bearerAuth = token;

    const payload = {
      answers: Object.values(answers),
      campaignId: campaign.id,
      advertiser_orgId: campaign?.orgId,
      advertiser_userId: campaign?.userId,
      default_answer: defaultAns,
    };

    fetch(process.env.REACT_APP_API_ENDPOINT + `promote-campaign/join`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          onClose();
          onOpen();
          setrefreshData(Math.random() * 3000);
          return setCreatingOffers(false);
        } else {
          setCreatingOffers(false);
          return toast({
            title: "Join Campaign Error",
            description: data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      });
  };

  const handleSizeClick = () => {
    onModalOnOpen();
  };

  const CopyText = async (text: string) => {
    if (!text) return;

    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const returnClockFormat = () => {
    let hrs: string | number = "";
    if (days <= 0) {
      hrs = hours > 9 ? hours : "0" + hours;
    } else {
      hrs = hours * days > 9 ? hours * days : "0" + hours;
    }
    let mins = minutes > 9 ? minutes : "0" + minutes;
    let secs = seconds > 9 ? seconds : "0" + seconds;
    return `${hrs}h : ${mins}m : ${secs}s`;
  };

  const checkIfDeclined = () => {
    if (promoteCampaignReq.status !== "declined") return false;

    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) return false;
    return true;
  };

  const handleShareLink = () => {
    if (!campaign.campaign_name) return;

    const textToCopy =
      "You’ve been invited to join " +
      currentAffiliate.firstname +
      " " +
      currentAffiliate.lastname +
      "’s affiliate campaign: " +
      campaign?.campaign_name +
      ".\nSignup to join thousands of affiliates that promote campaigns on metricks and start earning today. https://metricks-main-app-v2.vercel.app/affiliate-login?affiliate_name=" +
      currentAffiliate.firstname +
      "&id=" +
      campaign?.id;

    CopyText(textToCopy)
      .then(() => {
        toast({
          title: "Copied Link",
          description:
            campaign?.campaign_name + " campaign link copied to clipboard",
          status: "info",
          duration: 4000,
          position: "top-right",
        });
      })
      .catch(() => {
        toast({
          title: "Copied",
          description: "Failed to copy " + campaign?.campaign_name + " link",
          status: "info",
          duration: 3000,
          position: "top-right",
        });
      });
  };

  const handleCopyLink = () => {
    if (!campaign.campaign_name) return;

    const textToCopy =
      "https://metricks-main-app-v2.vercel.app/affiliate-login?affiliate_name=" +
      currentAffiliate.firstname +
      "&id=" +
      campaign?.id;

    CopyText(textToCopy)
      .then(() => {
        toast({
          title: "Copied Link",
          description:
            campaign?.campaign_name + " campaign link copied to clipboard",
          status: "info",
          duration: 4000,
          position: "top-right",
        });
      })
      .catch(() => {
        toast({
          title: "Copied",
          description: "Failed to copy " + campaign?.campaign_name + " link",
          status: "info",
          duration: 3000,
          position: "top-right",
        });
      });
  };

  return loading ? (
    <Box display="flex" h="80vh" alignItems="center" justifyContent="center">
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Image className="loading" src="/assets/logo.png" height="60px" />
        <Text
          fontWeight="400"
          fontSize="16px"
          lineHeight="70px"
          color="#000000"
          mt="-15px"
        >
          Loading....
        </Text>
      </Box>
    </Box>
  ) : (
    <Stack padding={{ base: "0px", md: "19px" }} bgColor="#F1F4FA">
      <Box
        display="flex"
        p={{ base: "13px 19px", md: "13px 20px" }}
        mb="12px"
        bgColor={{ base: "white", md: "transparent" }}
        alignItems="center"
      >
        <Text
          fontSize={{ base: "14px", md: "12px" }}
          fontWeight={{ base: "700", md: "500" }}
          lineHeight={{ base: "21px", md: "18px" }}
          color="#32325D"
          mr="6px"
          cursor="pointer"
        >
          Explore
        </Text>

        <MdChevronRight size={18} color="#32325D" />

        <Text
          fontSize={{ base: "14px", md: "12px" }}
          fontWeight={{ base: "400", md: "500" }}
          lineHeight={{ base: "21px", md: "18px" }}
          color="#32325D"
          ml="6px"
        >
          Join Campaign
        </Text>
      </Box>

      <Stack bgColor="white">
        <Box
          width="100%"
          height={{ base: "89px", md: "269px" }}
          bgGradient="linear(90deg, #1BD75F -164%, #1A1414 143.66%)"
          borderRadius="8px"
          position="relative"
          backgroundImage={
            campaign.background_image ? campaign.background_image : ""
          }
        >
          <Image
            position="absolute"
            bottom={{ base: "-50%", md: "-40%" }}
            left={{ base: "19px", md: "47px" }}
            width={{ base: "80px", md: "179px" }}
            height={{ base: "80px", md: "179px" }}
            borderRadius="50%"
            src={campaign?.banner_image ? campaign?.banner_image : ""}
          />
        </Box>

        <Box
          display={{ base: "none", md: "flex" }}
          alignItems="center"
          cursor="pointer"
          justifyContent="end"
          pr="47px"
          mt="34px"
          _hover={{ color: "#7211D4" }}
          onClick={() => navigate(-1)}
        >
          <MdKeyboardBackspace color="#32325D" />
          <Text
            color="#32325D"
            lineHeight="40px"
            fontSize="14px"
            fontWeight="400"
            pl="8px"
          >
            Back to the previous page
          </Text>
        </Box>

        <Box
          paddingBottom={{ base: "0px", md: "34px" }}
          pt={{ base: "60px", md: "100px" }}
          pl={{ base: "19px", md: "47px" }}
          pr={{ base: "19px", md: "47px" }}
        >
          <Text
            color="#32325D"
            lineHeight={{ base: "24px", md: "36px" }}
            fontSize={{ base: "16px", md: "24px" }}
            fontWeight={{ base: "500", md: "600" }}
            textTransform="capitalize"
          >
            {campaign?.campaign_name}
          </Text>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Text
                color="rgba(50, 50, 93, 0.8)"
                lineHeight="21px"
                fontSize="14px"
                fontWeight="400"
                textTransform="capitalize"
              >
                {campaign?.campaign_mode} Campaign
              </Text>

              <Image
                margin="0px 13px"
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/b4254b20-01f9-4451-9ede-2e8b98258404.svg"
              />

              <Box display="flex" alignItems="center">
                <Box
                  background={getStatusColor(campaign?.status || "active")}
                  height={{ base: "8px", md: "12px" }}
                  width={{ base: "8px", md: "12px" }}
                  borderRadius="50%"
                ></Box>
                <Text
                  color="#32325D"
                  lineHeight="21px"
                  fontSize="14px"
                  ml="6px"
                  fontWeight="400"
                  textTransform="capitalize"
                >
                  {campaign?.status}
                </Text>
              </Box>
            </Box>

            <Box display={["none", "flex"]} alignItems="center">
              <Center
                bgColor="#F0F3F9"
                cursor="pointer"
                borderRadius="full"
                mr={{ base: "12px", md: "25px" }}
                h={{ base: "32px", md: "40px" }}
                w={{ base: "32px", md: "40px" }}
                onClick={handleCopyLink}
              >
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/e107144c-810b-4130-b98e-15c142b0dff3.svg"
                  alt="copy"
                />
              </Center>

              {promoteCampaignReq.id ? (
                <>
                  {promoteCampaignReq.status === "pending" ? (
                    <Box
                      padding="7px 14px"
                      bgColor="#f3994a3d"
                      borderRadius="8px"
                    >
                      <Text
                        fontWeight=" 500"
                        fontSize="14px"
                        lineHeight="21px"
                        color="#F3994A"
                      >
                        Request Is Pending
                      </Text>
                    </Box>
                  ) : checkIfDeclined() ? (
                    <Box
                      padding="7px 16px"
                      bgColor="rgba(255, 194, 191, 0.5)"
                      borderRadius="8px"
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      width="max-content"
                    >
                      <Text
                        fontWeight=" 500"
                        fontSize="16px"
                        lineHeight="24px"
                        color="rgba(236, 99, 92, 1)"
                      >
                        {returnClockFormat()}
                      </Text>
                      <Text
                        fontWeight="400"
                        fontSize="12px"
                        lineHeight="16px"
                        color="rgba(236, 99, 92, 1)"
                        borderTop="0.8px solid rgba(236, 99, 92, 0.3)"
                        marginTop="3px"
                        paddingTop="3px"
                        width="full"
                        textAlign="center"
                      >
                        Try again in {days} days
                      </Text>
                    </Box>
                  ) : (
                    <Button
                      bgColor=" #7211D4"
                      border=" 0.5px solid #E5E4E4"
                      borderRadius="8px"
                      display={
                        currentAffiliate?.roleId === 6
                          ? "none"
                          : { base: "none", md: "flex" }
                      }
                      onClick={handleSizeClick}
                    >
                      <Text
                        fontWeight=" 500"
                        fontSize="14px"
                        lineHeight="21px"
                        color=" #F0F3F9"
                        display={["none", "block"]}
                      >
                        Join Campaign
                      </Text>
                      <Image
                        ml="7px"
                        src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/f986e464-5df1-47da-a06a-cea4503eb0e9.svg"
                      />
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  bgColor=" #7211D4"
                  border=" 0.5px solid #E5E4E4"
                  borderRadius="8px"
                  display={
                    currentAffiliate?.roleId === 6
                      ? "none"
                      : { base: "none", md: "flex" }
                  }
                  onClick={handleSizeClick}
                >
                  <Text
                    fontWeight=" 500"
                    fontSize="14px"
                    lineHeight="21px"
                    color=" #F0F3F9"
                    display={["none", "block"]}
                  >
                    Join Campaign
                  </Text>
                  <Image
                    ml="7px"
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/f986e464-5df1-47da-a06a-cea4503eb0e9.svg"
                  />
                </Button>
              )}

              {/* modal goes here */}
              <Modal
                closeOnOverlayClick={false}
                isOpen={onModalIsOpen}
                isCentered
                size="2xl"
                onClose={onClose}
              >
                <ModalOverlay />

                <ModalContent>
                  <ModalHeader
                    fontSize="16px"
                    fontWeight="600"
                    lineHeight="25px"
                    color="rgba(50, 50, 93, 1)"
                    textTransform="capitalize"
                  >
                    {campaign?.campaign_name || ""}
                  </ModalHeader>
                  <ModalCloseButton />

                  <ModalBody>
                    <Text
                      mb="4px"
                      fontSize="16px"
                      fontWeight="600"
                      lineHeight="25px"
                      color="rgba(50, 50, 93, 1)"
                    >
                      You are almost there
                    </Text>

                    <Text
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="17px"
                      color="rgba(50, 50, 93, 0.6)"
                      mb="28px"
                    >
                      Answer the following questions and your application to
                      join the campaign will be submitted
                    </Text>

                    <QuestionsForm
                      questions={questions}
                      answers={answers}
                      defaultAns={defaultAns}
                      setanswers={setanswers}
                      setdefaultAns={setdefaultAns}
                    />

                    <Checkbox
                      colorScheme="purple"
                      spacing="14px"
                      isChecked={hasAgreedToTerms}
                      onChange={() => sethasAgreedToTerms(!hasAgreedToTerms)}
                    >
                      <Text
                        fontSize="14px"
                        letterSpacing="2%"
                        lineHeight="21px"
                        fontWeight="500"
                      >
                        I agree to Metricks{" "}
                        <a
                          style={{ color: "#7211D4" }}
                          href="https://www.Metricks.io/legal/terms"
                          target="_blank"
                        >
                          Terms and Conditions
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://www.Metricks.io/legal/privacy-policy"
                          target="_blank"
                          style={{ color: "#7211D4" }}
                        >
                          Privacy Policy
                        </a>
                      </Text>
                    </Checkbox>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      colorScheme="purple"
                      w="100%"
                      isLoading={creatingOffers}
                      onClick={JoinCampaign}
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>

              <Modal onClose={onModalClose} size="md" isOpen={isOpen}>
                <ModalOverlay />
                <ModalContent maxWidth="65% !important" borderRadius=" 10px">
                  <ModalHeader display="flex" justifyContent="right">
                    <Image
                      onClick={onModalClose}
                      cursor="pointer"
                      ml="7px"
                      src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/3b803641-20b6-4e47-b554-8336ebd3ec5b.svg"
                    />
                  </ModalHeader>

                  <ModalBody
                    paddingBottom="26px"
                    borderBottom="1px solid rgba(50, 50, 93, 0.3)"
                  >
                    <Text
                      fontWeight="500"
                      fontSize="18px"
                      lineHeight="30px"
                      color=" rgba(50, 50, 93, 0.8)"
                      textAlign="center"
                    >
                      Your request to join{" "}
                      <Text
                        display="inline"
                        textTransform="capitalize"
                        color=" #7211D4"
                      >
                        {" "}
                        {campaign.campaign_name}{" "}
                      </Text>{" "}
                      campaign has been submitted.
                    </Text>
                  </ModalBody>

                  <ModalFooter display="flex" justifyContent="left">
                    <Button
                      ml="15px"
                      bgColor=" #F0F5FF"
                      color="#5C14CE"
                      borderRadius="8px"
                      onClick={() =>
                        navigate(
                          `/affiliate-campaigns?id=${campaign.id}&status=pending`
                        )
                      }
                    >
                      Proceed
                      <Image
                        cursor="pointer"
                        ml="10px"
                        mt="1px"
                        src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/37d9e26a-0ec4-462d-98ac-8fca88493c0b.svg"
                      />
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Box>

            <Box display={["flex", "none"]} alignItems="center">
              <Center
                bgColor="#F0F3F9"
                borderRadius="full"
                h="30px"
                w="30px"
                onClick={handleCopyLink}
              >
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/e107144c-810b-4130-b98e-15c142b0dff3.svg"
                  alt="copy"
                />
              </Center>
            </Box>
          </Box>

          {/* {campaign.landing_page_url && (
            <Box color="#7211D4" w="max-content">
              <Link
                href={campaign?.landing_page_url}
                isExternal
                display="flex"
                width="max-content"
                mt="20px"
                fontSize="12px"
                alignItems="center"
                _visited={{ color: "#7211D4" }}
              >
                <AiOutlineLink />
                {campaign?.landing_page_url}
              </Link>
            </Box>
          )} */}
        </Box>

        <Modal
          isCentered
          closeOnOverlayClick={true}
          isOpen={isCopyModalOpen}
          onClose={onCopyModalClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />

            <ModalBody pb={6}>
              <Box display="flex" m="38px 0px" alignItems="center">
                <Button
                  color="#7211D4"
                  bgColor="#F0F3F9"
                  mr="24px"
                  onClick={handleCopyLink}
                >
                  <Image
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/e107144c-810b-4130-b98e-15c142b0dff3.svg"
                    alt="copy"
                  />
                  <Text
                    fontSize="14px"
                    lineHeight="21px"
                    fontWeight="500"
                    ml="8px"
                  >
                    Copy Campaign Link
                  </Text>
                </Button>

                <Button
                  color="#7211D4"
                  bgColor="#F0F3F9"
                  onClick={handleShareLink}
                >
                  <MdShare size={24} />
                  <Text
                    fontSize="14px"
                    lineHeight="21px"
                    fontWeight="500"
                    ml="8px"
                  >
                    Share to Chat
                  </Text>
                </Button>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Box display={{ base: "block", md: "none" }} p="24px 20px">
          {promoteCampaignReq.id ? (
            <>
              {promoteCampaignReq.status === "pending" ? (
                <Box
                  padding="7px 14px"
                  bgColor="#f3994a3d"
                  borderRadius="8px"
                  width="100%"
                  textAlign="center"
                >
                  <Text
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="21px"
                    color="#F3994A"
                  >
                    Request Is Pending
                  </Text>
                </Box>
              ) : checkIfDeclined() ? (
                <Box
                  padding="7px 16px"
                  bgColor="rgba(255, 194, 191, 0.5)"
                  borderRadius="8px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  width={{ base: "100%", md: "150px" }}
                >
                  <Text
                    fontWeight=" 500"
                    fontSize="16px"
                    lineHeight="24px"
                    color="rgba(236, 99, 92, 1)"
                  >
                    {returnClockFormat()}
                  </Text>
                  <Text
                    fontWeight="400"
                    fontSize="12px"
                    lineHeight="16px"
                    color="rgba(236, 99, 92, 1)"
                    borderTop="0.8px solid rgba(236, 99, 92, 0.3)"
                    marginTop="3px"
                    paddingTop="3px"
                    width="full"
                    textAlign="center"
                  >
                    Try again in {days} days
                  </Text>
                </Box>
              ) : (
                <Button
                  bgColor=" #7211D4"
                  border=" 0.5px solid #E5E4E4"
                  borderRadius="8px"
                  display={currentAffiliate?.roleId === 6 ? "none" : "flex"}
                  width="100%"
                  onClick={handleSizeClick}
                >
                  <Text
                    fontWeight=" 500"
                    fontSize="14px"
                    lineHeight="21px"
                    color=" #F0F3F9"
                  >
                    Join Campaign
                  </Text>
                  <Image
                    ml="7px"
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/f986e464-5df1-47da-a06a-cea4503eb0e9.svg"
                  />
                </Button>
              )}
            </>
          ) : (
            <Button
              bgColor=" #7211D4"
              border=" 0.5px solid #E5E4E4"
              borderRadius="8px"
              width="100%"
              display={currentAffiliate?.roleId === 6 ? "none" : "flex"}
              onClick={handleSizeClick}
            >
              <Text
                fontWeight=" 500"
                fontSize="14px"
                lineHeight="21px"
                color=" #F0F3F9"
              >
                Join Campaign
              </Text>
              <Image
                ml="7px"
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/f986e464-5df1-47da-a06a-cea4503eb0e9.svg"
              />
            </Button>
          )}
        </Box>

        <Overview campaign={campaign} />
      </Stack>
    </Stack>
  );
};

export default ViewCampaign;
