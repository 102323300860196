import { Box, Flex, Heading, useMediaQuery } from "@chakra-ui/react";

interface StepSchema {
  title: string;
  status: string;
  key: number;
}

export const StepsNav = ({ steps }: { steps: StepSchema[] }) => {
  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  return (
    <Box display={["flex", "block"]}>
      {steps.map((step, i) => {
        return (
          <Box key={i} display={["flex", "block"]} alignItems={["baseline"]}>
            <Flex
              flexDirection={["column", "row"]}
              alignItems="center"
              my=".7em"
            >
              <Box
                height="2em"
                width="2em"
                mx={[".5em", ""]}
                mr=".8em"
                border="2px"
                borderColor={
                  step.status === "current"
                    ? "#5C14CE"
                    : step.status === "passed"
                    ? "#11D686"
                    : "#B3B3B3"
                }
                borderRadius="4px"
              >
                <Box
                  height="1em"
                  width="1em"
                  mr="1em"
                  bgColor={
                    step.status === "current"
                      ? "#5C14CE"
                      : step.status === "passed"
                      ? "#11D686"
                      : "#B3B3B3"
                  }
                  display={step.status === "current" ? "flex" : "none"}
                  borderRadius="4px"
                  mt="5px"
                  ml="5px"
                ></Box>
              </Box>

              <Heading
                as="h4"
                fontSize={["12px", "1.5em"]}
                fontWeight="550"
                textAlign={["center", "left"]}
                color={
                  step.status === "current"
                    ? "#5C14CE"
                    : step.status === "passed"
                    ? "#11D686"
                    : "#B3B3B3"
                }
              >
                {step.title}
              </Heading>
            </Flex>
            {i !== steps.indexOf(steps[steps.length - 1]) && (
              <Flex direction={["row", "column"]} gap={1}>
                <Box
                  width={["8px", "4px"]}
                  height={[".5em", ".7em"]}
                  backgroundColor={
                    step.status === "current"
                      ? "#5C14CE"
                      : step.status === "passed"
                      ? "#11D686"
                      : "#B3B3B3"
                  }
                  ml={["0em", "1.2em"]}
                ></Box>
                <Box
                  width={["8px", "4px"]}
                  height={[".5em", ".7em"]}
                  backgroundColor={
                    step.status === "current"
                      ? "#5C14CE"
                      : step.status === "passed"
                      ? "#11D686"
                      : "#B3B3B3"
                  }
                  ml={["0em", "1.2em"]}
                ></Box>
              </Flex>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
