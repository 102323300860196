import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Image,
  Stack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  css,
  useToast,
  useMediaQuery,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Center,
  Link,
  Flex,
  ModalFooter,
  Avatar,
} from "@chakra-ui/react";
// @ts-ignore
import { TabsIcon } from "../../layout/sidebar/icons/tabs";
import useUserToken from "../../../hooks/useUserToken";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  MdChevronRight,
  MdOutlinePointOfSale,
  MdShare,
  MdSpatialTracking,
} from "react-icons/md";
import UnavailableInfo from "../../affiliate/campaigns/view-campaigns/tabs/UnavailableInfo";
import Overview from "./tabs/overview";
import { gsap, Power2 } from "gsap";
import Sales from "./tabs/sales";
import Commissions from "./tabs/commissions";
import CampaignAffiliates from "./tabs/affiliates";
import { AiOutlineLink } from "react-icons/ai";
import { GrOverview } from "react-icons/gr";
import CampaignAssets from "./tabs/assets/CampaignAssets";
import CampaignLeads from "./tabs/leads";
import { IoCashOutline, IoPeopleSharp } from "react-icons/io5";
import {
  FaChartLine,
  FaDatabase,
  FaDropbox,
  FaFunnelDollar,
} from "react-icons/fa";
import { PiBinocularsDuotone } from "react-icons/pi";
import PostbackTracking from "./tabs/postbackTracking";

const ViewCampaign = () => {
  let [searchParams, _] = useSearchParams();
  console.log(searchParams.get("offers"));
  console.log("tab", searchParams.get("tab"));
  const getStatusColor = (status: string) => {
    if (status === "active") {
      return "#239654";
    }
    if (status === "paused") {
      return "#F3994A";
    }
    if (status === "draft") {
      return "#EB5757";
    }
  };

  const token = useUserToken();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    isOpen: isCopyModalOpen,
    onOpen: onCopyModalOpen,
    onClose: onCopyModalClose,
  } = useDisclosure();

  let { id } = useParams();

  const [campaign, setCampaign] = useState({
    campaign_mode: "",
    campaign_name: "",
    status: "",
    createdAt: "",
    countriesToShip: "",
    id: "",
    banner_image: "",
    landing_page_url: "",
    background_image: "",
    campaignPromotion: [],
    product_id: [],
  });
  const [currentAdv, setcurrentAdv] = useState<any>({
    id: null,
    firstname: "",
    lastname: "",
  });
  const [loading, setLoading] = useState(false);
  const [refreshCommissions, setrefreshCommissions] = useState(false);
  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [externalAffInfo, setexternalAffInfo] = useState<any>(null);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    if (index === 2) {
      setrefreshCommissions(!refreshCommissions);
    }
  };

  const tabs = [
    {
      title: "Overview",
      content: <Overview campaign={campaign} id={id} />,
    },
    {
      title: "Sales",
      content: (
        <Sales
          campaignName={campaign.campaign_name}
          id={id || ""}
          campaignPromotion={campaign?.campaignPromotion || []}
          productLinks={campaign?.product_id || []}
          setExternalAffInfo={setexternalAffInfo}
          setTabIndex={setTabIndex}
        />
      ),
    },
    {
      title: "Commissions",
      content: (
        <Commissions
          refreshData={refreshCommissions}
          id={id}
          campaignName={campaign.campaign_name}
          setExternalAffInfo={setexternalAffInfo}
          setTabIndex={setTabIndex}
        />
      ),
    },
    {
      title: "Affiliates",
      content: (
        <CampaignAffiliates
          id={id}
          campaignName={campaign.campaign_name}
          externalAffInfo={externalAffInfo}
          setExternalInfo={setexternalAffInfo}
        />
      ),
    },
    {
      title: "Assets",
      content: <CampaignAssets campaignId={id} />,
    },
    {
      title: "Leads",
      content: (
        <CampaignLeads
          refreshData={refreshCommissions}
          id={id}
          campaignName={campaign.campaign_name}
          setExternalAffInfo={setexternalAffInfo}
          setTabIndex={setTabIndex}
        />
      ),
    },
    {
      title: "Funnels",
      content: <UnavailableInfo tab="funnels" />,
    },
    // {
    //   title: "Pixels",
    //   content: <UnavailableInfo tab="pixels" />,
    // },test
    {
      title: "Postback Tracking",
      content: <PostbackTracking campaignId={id} />,
    },
  ];

  const getCampaign = (id: string | undefined) => {
    setLoading(true);
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + `campaign/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("where err", data);
        if (data.success) {
          setCampaign(data.data.campaign);
        } else {
          // formViewRef.current.scrollIntoView();
          console.log("error", data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("get campaign => " + err);
        setLoading(false);
      });
  };

  const CopyText = async (text: string) => {
    if (!text) return;

    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  useEffect(() => {
    if (id) {
      getCampaign(id);
    }
  }, []);

  useEffect(() => {
    getAdminOffers();
    gsap.fromTo(
      ".loading",
      {
        opacity: 0,
        ease: Power2.easeInOut,
        scale: 0,
        y: 80,
      },
      {
        opacity: 1,
        duration: 1.5,
        scale: 1,
        ease: Power2.easeInOut,
        repeat: -1,
        y: 0,
        yoyo: true,
      }
    );
  }, []);

  const handleShareLink = () => {
    if (!campaign.campaign_name) return;

    const textToCopy =
      "You’ve been invited to join: " +
      campaign?.campaign_name +
      " campaign." +
      ".\nSignup to join thousands of affiliates that promote campaigns on Metricks and start earning today. " +
      window.location.hostname +
      "/affiliate-login?id=" +
      campaign?.id;

    CopyText(textToCopy)
      .then(() => {
        toast({
          title: "Copied Link",
          description:
            campaign?.campaign_name + " campaign link copied to clipboard",
          status: "info",
          duration: 4000,
          position: "top-right",
        });
      })
      .catch(() => {
        toast({
          title: "Copied",
          description: "Failed to copy " + campaign?.campaign_name + " link",
          status: "info",
          duration: 3000,
          position: "top-right",
        });
      });
  };

  const handleCopyLink = () => {
    if (!campaign.campaign_name) return;

    const textToCopy =
      window.location.hostname + "/affiliate-login?id=" + campaign?.id;

    CopyText(textToCopy)
      .then(() => {
        toast({
          title: "Copied Link",
          description:
            campaign?.campaign_name + " campaign link copied to clipboard",
          status: "info",
          duration: 4000,
          position: "top-right",
        });
      })
      .catch(() => {
        toast({
          title: "Copied",
          description: "Failed to copy " + campaign?.campaign_name + " link",
          status: "info",
          duration: 3000,
          position: "top-right",
        });
      });
  };

  const [allOffers, setAllOffers] = useState([]);
  const AlreadyInOffer = (name: string) => {
    if (!allOffers.length) return false;

    let offerForCampaign = allOffers.find(
      (item: any) => item?.campaign.campaign_name === name
    );

    if (!offerForCampaign) return false;
    return true;
  };
  const getAdminOffers = () => {
    setLoading(true);
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + `explore/offers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setAllOffers(data.data.offers);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const {
    isOpen: onModalIsOpen,
    onOpen: onModalOnOpen,
    onClose,
  } = useDisclosure();
  const [creatingOffers, setCreatingOffers] = useState(false);
  const handleSizeClick = () => {
    onModalOnOpen();
  };
  const createOffer = () => {
    setCreatingOffers(true);

    if (AlreadyInOffer(campaign.campaign_name)) {
      toast({
        title: "Error creating Offer",
        description: "This campaign has already been added to offers",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setCreatingOffers(false);
    }

    if (campaign.status !== "active") {
      setCreatingOffers(false);
      return toast({
        title: "Error creating Offer",
        description:
          "A Campaign that is not active cannot be added to offers. Make the campaign active and try again",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }

    let bearerAuth = token;
    const payload = { campaignId: campaign.id };

    fetch(process.env.REACT_APP_API_ENDPOINT + `explore/offers/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          handleSizeClick();
        }
      })
      .catch((err) => {
        toast({
          title: "Server Error",
          description: "Unable to create offer for campaign",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        console.log("submit offer: =>" + err);
      });

    setCreatingOffers(false);
  };

  return loading ? (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      h="80vh"
      alignItems="center"
      pt="20px"
    >
      <Image className="loading" src="/assets/logo.png" height="60px" />
      <Text
        fontWeight="400"
        fontSize="16px"
        lineHeight="70px"
        color="#000000"
        mt="-15px"
      >
        Loading....
      </Text>
    </Box>
  ) : (
    <Stack padding="19px" bgColor="#F1F4FA">
      <Modal onClose={onClose} size={"md"} isOpen={onModalIsOpen}>
        <ModalOverlay />
        <ModalContent maxWidth="65% !important" borderRadius=" 10px">
          <ModalHeader display="flex" justifyContent="right">
            <Image
              onClick={onClose}
              cursor="pointer"
              ml="7px"
              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/3b803641-20b6-4e47-b554-8336ebd3ec5b.svg"
            />
          </ModalHeader>
          <ModalBody
            paddingBottom="26px"
            borderBottom="1px solid rgba(50, 50, 93, 0.3)"
          >
            <Text
              fontWeight="500"
              fontSize="18px"
              lineHeight="30px"
              color=" rgba(50, 50, 93, 0.8)"
              textAlign="center"
            >
              You have submitted{" "}
              <Text display="inline" color=" #7211D4">
                {" "}
                {campaign.campaign_name}{" "}
              </Text>{" "}
              for review.
            </Text>
          </ModalBody>
          <ModalFooter display="flex" justifyContent="left">
            <Button
              ml="15px"
              bgColor=" #F0F5FF"
              color="#5C14CE"
              borderRadius="8px"
              onClick={() => navigate(`/explore?tab=pending`)}
            >
              Proceed
              <Image
                cursor="pointer"
                ml="10px"
                mt="1px"
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/37d9e26a-0ec4-462d-98ac-8fca88493c0b.svg"
              />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box display="flex" mb="12px" alignItems="center">
        <Text
          fontSize="12px"
          fontWeight="500"
          lineHeight="18px"
          color="#32325D"
          mr="5px"
          cursor="pointer"
          _hover={{ textDecoration: "underline" }}
          onClick={() => navigate(-1)}
        >
          Campaign
        </Text>

        <MdChevronRight size={20} color="#32325D" />

        <Text
          fontSize="12px"
          lineHeight="18px"
          color="#32325D"
          fontWeight="400"
          ml="5px"
        >
          Overview
        </Text>
      </Box>

      <Stack bgColor="white" w="100%">
        <Box
          width="100%"
          height={{ base: "89px", md: "269px" }}
          bgGradient="linear(90deg, #1BD75F -164%, #1A1414 143.66%)"
          borderRadius="8px"
          position="relative"
          backgroundImage={
            campaign.background_image ? campaign.background_image : ""
          }
        >
          <Avatar
            position="absolute"
            bottom={{ base: "-50%", md: "-30%" }}
            left={{ base: "19px", md: "47px" }}
            width={{ base: "80px", md: "179px" }}
            height={{ base: "80px", md: "179px" }}
            borderRadius="50%"
            objectFit="cover"
            src={campaign?.banner_image ? campaign?.banner_image : ""}
            borderColor={
              "linear-gradient(to right, #bb52aa 0%, #bb52aa 33%, #63ff85 66%, #63ff85 100%)"
            }
            borderWidth={5}
          />
        </Box>

        <Box
          paddingBottom="34px"
          pt={{ base: "60px", md: "100px" }}
          pl={{ base: "19px", md: "47px" }}
          pr={{ base: "19px", md: "47px" }}
        >
          <Text
            color="#32325D"
            lineHeight={{ base: "21px", md: "36px" }}
            fontSize={{ base: "16px", md: "24px" }}
            fontWeight="600"
            textTransform="capitalize"
          >
            {campaign?.campaign_name}
          </Text>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Text
                color="rgba(50, 50, 93, 0.8)"
                lineHeight="21px"
                fontSize="14px"
                fontWeight="400"
                textTransform="capitalize"
              >
                {campaign?.campaign_mode} Campaign
              </Text>

              <Image
                margin="0px 13px"
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/b4254b20-01f9-4451-9ede-2e8b98258404.svg"
              />

              <Box display="flex" alignItems="center">
                <Box
                  background={getStatusColor(campaign?.status || "active")}
                  height={{ base: "8px", md: "12px" }}
                  width={{ base: "8px", md: "12px" }}
                  borderRadius="50%"
                ></Box>
                <Text
                  color="#32325D"
                  lineHeight="21px"
                  fontSize="14px"
                  ml="6px"
                  fontWeight="400"
                  textTransform="capitalize"
                >
                  {campaign?.status}
                </Text>
              </Box>
            </Box>

            <Box display={{ base: "none", md: "flex" }} alignItems="center">
              <Center
                bgColor="#F0F3F9"
                cursor="pointer"
                p="8px 19px"
                mr="17px"
                borderRadius="8px"
                // onClick={onCopyModalOpen}
                onClick={handleCopyLink}
              >
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/e107144c-810b-4130-b98e-15c142b0dff3.svg"
                  alt="copy"
                />
                <Text
                  fontSize="14px"
                  lineHeight="21px"
                  fontWeight="500"
                  ml="8px"
                  color="#32325D"
                >
                  Copy Campaign Link
                </Text>
              </Center>

              <Button
                bgColor="#F0F3F9"
                // border=" 0.5px solid #E5E4E4"
                borderRadius="8px"
                onClick={() =>
                  navigate(`/campaigns/edit-campaign?campaign=${campaign.id}`)
                }
                color="rgba(50, 50, 93, 0.8)"
              >
                <Image
                  h="20px"
                  w="20px"
                  objectFit="contain"
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/16d4ad64-7df3-4360-b6eb-64a312641020.svg"
                />
                <Text
                  fontSize="14px"
                  lineHeight="21px"
                  fontWeight="500"
                  ml="8px"
                >
                  Edit Campaign
                </Text>
              </Button>

              {/* {searchParams.get("offers") && ( */}
              <Box ml="18px">
                <Button
                  bgColor=" #7211D4"
                  border=" 0.5px solid #E5E4E4"
                  borderRadius="8px"
                  onClick={createOffer}
                  isLoading={creatingOffers}
                >
                  <Text
                    fontWeight=" 500"
                    fontSize="14px"
                    lineHeight="21px"
                    color=" #F0F3F9"
                    display={["none", "block"]}
                  >
                    Submit for review
                  </Text>
                  <Image
                    ml="7px"
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/f986e464-5df1-47da-a06a-cea4503eb0e9.svg"
                  />
                </Button>
              </Box>
              {/* )} */}
            </Box>

            <Box display={{ base: "flex", md: "none" }} alignItems="center">
              <Image
                cursor="pointer"
                mr="9px"
                h="24px"
                w="24px"
                objectFit="contain"
                src="/assets/svg/view-mini.svg"
                // onClick={onCopyModalOpen}
                onClick={handleCopyLink}
              />

              <Image
                h="24px"
                w="24px"
                objectFit="contain"
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/16d4ad64-7df3-4360-b6eb-64a312641020.svg"
                onClick={() =>
                  navigate(`/campaigns/edit-campaign?campaign=${campaign.id}`)
                }
              />
            </Box>
          </Box>

          {campaign.landing_page_url && (
            <Box color="#7211D4" w="max-content">
              <Link
                href={
                  campaign?.landing_page_url.includes("https://")
                    ? `${campaign?.landing_page_url}`
                    : `https://${campaign?.landing_page_url}`
                }
                isExternal
                display="flex"
                width="max-content"
                fontSize="12px"
                alignItems="center"
                _visited={{ color: "#7211D4" }}
              >
                <AiOutlineLink />
                {campaign?.landing_page_url.includes("https://")
                  ? `${campaign?.landing_page_url}`
                  : `https://${campaign?.landing_page_url}`}
              </Link>
            </Box>
          )}
        </Box>

        <Modal
          isCentered
          closeOnOverlayClick={true}
          isOpen={isCopyModalOpen}
          onClose={onCopyModalClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />

            <ModalBody pb={6}>
              <Box
                display="flex"
                m="38px 0px"
                alignItems="center"
                justifyContent={"center"}
              >
                <Button
                  color="#7211D4"
                  bgColor="#F0F3F9"
                  mr="24px"
                  onClick={handleCopyLink}
                >
                  <Image
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/e107144c-810b-4130-b98e-15c142b0dff3.svg"
                    alt="copy"
                  />
                  <Text
                    fontSize="14px"
                    lineHeight="21px"
                    fontWeight="500"
                    ml="8px"
                  >
                    Copy Campaign Link
                  </Text>
                </Button>

                {/* <Button
                  color="#7211D4"
                  bgColor="#F0F3F9"
                  onClick={handleShareLink}
                >
                  <MdShare size={24} />
                  <Text
                    fontSize="14px"
                    lineHeight="21px"
                    fontWeight="500"
                    ml="8px"
                  >
                    Share to Chat
                  </Text>
                </Button> */}
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Tabs
          // colorScheme="purple"
          pl={{ base: "0px", md: "47px" }}
          pr={{ base: "0px", md: "47px" }}
          width="100%"
          index={tabIndex}
          onChange={handleTabsChange}
          isFitted={isDesktop ? true : false}
        >
          <TabList
            css={
              isDesktop
                ? {}
                : css({
                    "::-webkit-scrollbar": { display: "none" },
                    "-webkit-overflow-scrolling": "touch",
                  })
            }
            overflowX={{ base: "auto", md: "visible" }}
          >
            <Tab
              fontSize="14px"
              lineHeight="21px"
              _selected={{
                color: "#7211d4",
                bg: "white",
                borderBottomColor: "#7211d4",
              }}
            >
              <Flex gap={2} align={"center"} justify={"center"}>
                <PiBinocularsDuotone />
                Overview
              </Flex>
            </Tab>
            <Tab
              fontSize="14px"
              lineHeight="21px"
              _selected={{
                color: "#7211d4",
                bg: "white",
                borderBottomColor: "#7211d4",
              }}
            >
              <Flex gap={2} align={"center"} justify={"center"}>
                <MdOutlinePointOfSale />
                Sales
              </Flex>
            </Tab>
            <Tab
              fontSize="14px"
              lineHeight="21px"
              _selected={{
                color: "#7211d4",
                bg: "white",
                borderBottomColor: "#7211d4",
              }}
            >
              <Flex gap={2} align={"center"} justify={"center"}>
                <IoCashOutline />
                Commissions
              </Flex>
            </Tab>
            <Tab
              fontSize="14px"
              lineHeight="21px"
              _selected={{
                color: "#7211d4",
                bg: "white",
                borderBottomColor: "#7211d4",
              }}
            >
              <Flex gap={2} align={"center"} justify={"center"}>
                <IoPeopleSharp />
                Affiliates
              </Flex>
            </Tab>
            <Tab
              fontSize="14px"
              lineHeight="21px"
              _selected={{
                color: "#7211d4",
                bg: "white",
                borderBottomColor: "#7211d4",
              }}
            >
              <Flex gap={2} align={"center"} justify={"center"}>
                <FaDropbox />
                Assets
              </Flex>
            </Tab>
            <Tab
              fontSize="14px"
              lineHeight="21px"
              _selected={{
                color: "#7211d4",
                bg: "white",
                borderBottomColor: "#7211d4",
              }}
            >
              <Flex gap={2} align={"center"} justify={"center"}>
                <FaChartLine />
                Leads
              </Flex>
            </Tab>
            <Tab
              fontSize="14px"
              lineHeight="21px"
              _selected={{
                color: "#7211d4",
                bg: "white",
                borderBottomColor: "#7211d4",
              }}
            >
              <Flex gap={2} align={"center"} justify={"center"}>
                <FaFunnelDollar />
                Funnels
              </Flex>
            </Tab>

            <Tab
              fontSize="14px"
              lineHeight="21px"
              _selected={{
                color: "#7211d4",
                bg: "white",
                borderBottomColor: "#7211d4",
              }}
            >
              <Flex gap={2} align={"center"} justify={"center"}>
                <FaDatabase />
                Postback Tracking
              </Flex>
            </Tab>
          </TabList>

          <TabPanels>
            {tabs.map((item: any) => (
              <TabPanel>{item?.content}</TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Stack>
    </Stack>
  );
};

export default ViewCampaign;
