import { Avatar, AvatarBadge, Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { getCurrentUserInstance } from "../../../state/localstorage";

type ListChatType = {
  unreadMessageCount?: number;
  lastMessage: string;
  username: string;
  lastMessageDate: string;
  userImage: string;
  isOnline?: boolean;
  openChatView: React.Dispatch<React.SetStateAction<any>>;
  chatId: string | number;
};

const ListChat = ({
  unreadMessageCount,
  lastMessage,
  username,
  lastMessageDate,
  userImage,
  chatId,
  isOnline,
  openChatView,
}: ListChatType) => {
  const user = getCurrentUserInstance();

  const setCurrentChat = () => {
    openChatView({
      unreadMessageCount,
      lastMessage,
      username,
      lastMessageDate,
      userImage,
      isOnline,
      chatId,
    });
  };

  return (
    <Flex w="100%" pt="1rem" cursor="pointer" onClick={setCurrentChat}>
      <Avatar size="sm" name={username} src={userImage}>
        {isOnline ? (
          <AvatarBadge bg="rgba(31, 191, 126, 1)" boxSize="1em" />
        ) : (
          <AvatarBadge borderColor="grey.500" bg="grey.200" boxSize="1em" />
        )}
      </Avatar>

      <Box ml="12px" mr="auto" maxW="60%" flex="1">
        <Text
          fontSize="0.9rem"
          lineHeight="21px"
          color="rgba(50, 50, 93, 1)"
          fontWeight="600"
        >
          {username}
        </Text>

        <LinesEllipsis
          text={lastMessage || ""}
          maxLine="1"
          ellipsis="..."
          trimRight
          basedOn="letters"
          style={{
            marginTop: "4px",
            fontSize: "0.75rem",
            fontWeight: "500",
            color: "rgba(50, 50, 93, 1)",
          }}
        />
      </Box>

      <Box display="flex" alignItems="end" flexDir="column">
        <Text
          fontSize="0.75rem"
          lineHeight="16px"
          fontWeight="500"
          color="rgba(50, 50, 93, 1)"
        >
          {lastMessageDate}
        </Text>

        {unreadMessageCount ? (
          <Box
            height="17px"
            width="20px"
            rounded="full"
            bg="rgba(23, 186, 119, 1)"
            display="flex"
            alignItems="center"
            mt="6px"
            justifyContent="center"
          >
            <Text fontSize="10px" color="white" lineHeight="15px">
              {unreadMessageCount || 0}
            </Text>
          </Box>
        ) : (
          ""
        )}
      </Box>
    </Flex>
  );
};

export default ListChat;
