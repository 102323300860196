import React, { useEffect, useState } from "react";
import BillingCard from "../../billing-card";
import {
  Box,
  Image,
  Text,
  Stack,
  Select,
  useColorModeValue,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Checkbox,
  ModalFooter,
  Button,
  Flex,
  css,
  Table,
  Thead,
  Tr,
  useMediaQuery,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import TopAffiliate from "./top-affiliate";
import { DAY_FILTER } from "../../../utils/option";
import useUserToken from "../../../hooks/useUserToken";
import { getCurrentUserInstance } from "../../../state/localstorage";
import { BaseButton } from "../../button";
import QuestionsForm from "../affiliate-explore/view-campaigns/QuestionsForm";
import { useNavigate } from "react-router-dom";
import { gsap, Power2 } from "gsap";
import { IoIosArrowForward } from "react-icons/io";
import { MdArrowDropDown, MdFiberManualRecord } from "react-icons/md";

const Dashboard = () => {
  const user = getCurrentUserInstance();
  const token = useUserToken("affiliate");
  const isOrganization = window.localStorage.getItem("organization");
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    createNotification();
    if (isOrganization === "yes") {
      window.location.hash = "no-back-button";
      // Again because Google Chrome doesn't insert
      // the first hash into the history
      window.location.hash = "Again-No-back-button";
      window.onhashchange = function () {
        window.location.hash = "no-back-button";
      };
    }
  }, []);

  const data = [
    {
      title: "All Sales",
      image:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/a477d2cc-2147-4b8e-be05-53f1e5f3080c.svg",
      value: "totalSales",
    },
    {
      title: "All Payouts",
      image:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/95fc42ab-831f-4ec9-af5b-fd10ac0826c0.svg",
      value: "totalPayouts",
    },
    {
      title: "Pending Payouts",
      image:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/1366f117-5fb6-4a32-a07f-16d7397443a3.svg",
      value: "pendingCommission",
    },
    {
      title: "Rejected Payouts",
      image:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/40981450-c821-4af5-8aa9-8c6f31ea83fb.svg",
      value: "rejectedPayouts",
    },
    {
      title: "Leads",
      image:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/1f787f1e-8f37-48f7-b0e9-2e27faff1003.svg",
      value: "totalLeads",
    },
  ];

  const createNotification = () => {
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + "/notification/push", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
      body: JSON.stringify({
        subject: "Story of my life",
        message: "All the heart I broke in lekki, only God fit to protect me",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("notification", data);
        if (data.success) {
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
        }
      })
      .catch((err) => {
        console.log("here", err);
      });
  };

  const [campaign, setCampaign] = useState<any>({
    campaign_mode: "",
    campaign_name: "",
    status: "",
    createdAt: "",
    countriesToShip: "",
    id: null,
    banner_image: "",
    background_image: "",
  });
  const [promoteCampaignReq, setpromoteCampaignReq] = useState({
    id: null,
    status: "",
  });
  const [questions, setquestions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filterLoading, setfilterLoading] = useState(false);
  const [answers, setanswers] = useState<any>({});
  const [hasRequested, sethasRequested] = useState(false);
  const [creatingOffers, setCreatingOffers] = useState<boolean>(false);
  const [hasAgreedToTerms, sethasAgreedToTerms] = useState(false);
  const [defaultAns, setdefaultAns] = useState<string>("");
  const [affStatus, setaffStatus] = useState("");
  const [analyticsData, setanalyticsData] = useState<any>({});
  const [campaignsData, setcampaignsData] = useState<{ [key: string]: any }[]>(
    []
  );
  const [sortValue, setsortValue] = useState("");

  const {
    isOpen: onModalIsOpen,
    onOpen: onModalOnOpen,
    onClose,
  } = useDisclosure();

  const {
    isOpen: isOpen,
    onOpen: onOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const openCampaignQst = () => {
    onModalOnOpen();
  };

  const checkToken = async () => {
    if (!token) return;

    await fetch(process.env.REACT_APP_API_ENDPOINT + `/auth/valid-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data?.valid) {
          return;
        } else {
          window.localStorage.clear();
          sessionStorage.removeItem("currentUserType");
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          navigate("/login");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (token) {
      checkToken();
    }
  }, [token]);

  useEffect(() => {
    if (sessionStorage.getItem("campaignId")) {
      getCampaign(sessionStorage.getItem("campaignId"));
      getCampaignQuestions(sessionStorage.getItem("campaignId"));
      setaffStatus(sessionStorage.getItem("affiliateStatus") || "");
    }
  }, []);

  useEffect(() => {
    if (campaign.id) {
      getPromoteCampaignReq();
    }
  }, [campaign]);

  const getCampaign = (id: string | null) => {
    console.log("inside getcampaign", data);
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + `campaign/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setCampaign(data.data.campaign);
        }
      })
      .catch((err) => {});
  };

  const getCampaignQuestions = (id: string | null) => {
    let bearerAuth = token;

    fetch(
      process.env.REACT_APP_API_ENDPOINT + `/campaign/${id}/fetch-questions`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setquestions(data.data.questions);
        }
      })
      .catch((err) => {
        console.log("get campaign questions => " + err);
      });
  };

  const getPromoteCampaignReq = () => {
    if (!campaign.id) return;

    let bearerAuth = token;

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `promote-campaign/affiliate/campaigns?campaignId=${campaign?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data.campaigns.length > 0) {
          onOpen();
          sethasRequested(true);
          setpromoteCampaignReq({
            id: data.data.campaigns[0]?.id,
            status: data.data.campaigns[0]?.status,
          });
        } else {
          openCampaignQst();
        }
      })
      .catch((err) => {
        console.log("affiliate's campaigs => " + err);
      });
  };

  const getAffiliateAnalytics = (endpoint: string, hasFilter?: boolean) => {
    let bearerAuth = token;

    if (hasFilter) {
      setfilterLoading(true);
    } else {
      setLoading(true);
    }
    fetch(process.env.REACT_APP_API_ENDPOINT + endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          if (hasFilter) {
            setfilterLoading(false);
          } else {
            setLoading(false);
          }
          setanalyticsData(data.data.analytics);
        } else {
          if (hasFilter) {
            setfilterLoading(false);
          } else {
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        if (hasFilter) {
          setfilterLoading(false);
        } else {
          setLoading(false);
        }
      });
  };

  const JoinCampaign = () => {
    setCreatingOffers(true);
    const affStatus = sessionStorage.getItem("affiliateStatus");
    let inCompleteQuestion: any = null;

    if (!defaultAns) {
      setCreatingOffers(false);

      return toast({
        title: "Join Campaign Error",
        description: `You must provide an answer to how you intend to promote the campaign`,
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }

    for (let i = 0; i < questions.length; i++) {
      let qst = questions[i];

      if (!answers[`q${qst.id}`] && qst.required) {
        inCompleteQuestion = qst;
        setCreatingOffers(false);

        return toast({
          title: "Join Campaign Error",
          description: `The answer to "${inCompleteQuestion.label}" is required`,
          status: "warning",
          duration: 6000,
          isClosable: true,
          position: "top-right",
        });
      }

      if (!answers[`q${qst.id}`]?.answer && qst.required) {
        inCompleteQuestion = qst;
        setCreatingOffers(false);

        return toast({
          title: "Join Campaign Error",
          description: `The answer to "${inCompleteQuestion.label}" is required`,
          status: "warning",
          duration: 6000,
          isClosable: true,
          position: "top-right",
        });
      }
    }

    if (!hasAgreedToTerms) {
      setCreatingOffers(false);

      return toast({
        title: "Join Campaign Error",
        description: `You must agree to Metrics Terms and Conditions`,
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }

    let bearerAuth = token;

    const payload = {
      answers: Object.values(answers),
      campaignId: campaign.id,
      advertiser_orgId: campaign?.orgId,
      advertiser_userId: campaign?.userId,
      default_answer: defaultAns,
      status: affStatus ? affStatus : "pending",
    };

    fetch(process.env.REACT_APP_API_ENDPOINT + `promote-campaign/join`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          onClose();
          onOpen();
          sessionStorage.removeItem("affiliateStatus");
          sessionStorage.removeItem("campaignId");
          sessionStorage.removeItem("affiliate_name");

          return setCreatingOffers(false);
        } else {
          setCreatingOffers(false);
          return toast({
            title: "Join Campaign Error",
            description: data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      });
  };

  useEffect(() => {
    getAffiliateAnalytics("affiliate/analytics");
    // getAffiliateCampaigns();
    gsap.fromTo(
      ".loading",
      {
        opacity: 0,
        ease: Power2.easeInOut,
        scale: 0,
        y: 80,
      },
      {
        opacity: 1,
        duration: 1.5,
        scale: 1,
        ease: Power2.easeInOut,
        repeat: -1,
        y: 0,
        yoyo: true,
      }
    );
  }, []);

  useEffect(() => {
    if (sortValue) {
      getAffiliateAnalytics(`affiliate/analytics?sort=${sortValue}`, true);
    }
  }, [sortValue]);

  const [isDesktop] = useMediaQuery("(min-width: 768px)");

  const titles = [
    "DATE CREATED",
    "CAMPAIGN NAME",
    "PRODUCT ID",
    "Leads",
    "Sales",
    "EARNINGS",
    "STATUS",
  ];

  if (loading)
    return (
      <Box display="flex" justifyContent="center" h="80vh" alignItems="center">
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Image className="loading" src="/assets/logo.png" height="80px" />
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="70px"
            color="#000000"
            mt="-15px"
          >
            Loading...
          </Text>
        </Box>
      </Box>
    );

  return (
    <Stack pb="100px">
      <Box
        width="100%"
        background={useColorModeValue("#ffffff", "gray.300")}
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        justifyContent="center"
        margin="0px !important"
        p={["17px 15px", "17px 40px"]}
      >
        <Text
          fontWeight="500"
          fontSize="16px"
          color="#32325D"
          lineHeight="24px"
          textTransform="capitalize"
        >
          Hi {user.firstname} {user.lastname},
        </Text>

        <Text
          fontWeight="400"
          fontSize="14px"
          color="rgba(50, 50, 93, 0.7)"
          lineHeight="20px"
        >
          Here's the latest report on your campaigns
        </Text>
      </Box>

      <Box paddingLeft={["15px", "40px"]} paddingRight={["15px", "40px"]}>
        <TopAffiliate bgColor="#f9fafd" />
      </Box>

      <Box
        p={["20px", "23px 40px"]}
        display="flex"
        alignItems="center"
        flexDirection={["column", "row"]}
        justifyContent="space-between"
        flexWrap="wrap"
      >
        {data.map((item, index) => {
          return (
            <Box
              key={index}
              display="flex"
              alignItems="flex-start"
              flexDirection="column"
              justifyContent="space-between"
              background="#ffffff"
              borderRadius="16px"
              height="105px"
              width={["100%", "100%", "233px", "233px", "18%", "18%"]}
              padding="20px"
              marginBottom={["25px", "25px", "25px", "25px", "25px", "0px"]}
              boxShadow="0px 4px 8px rgba(211, 211, 211, 0.25)"
            >
              <Box
                display="flex"
                alignItems="flex-start"
                width="100%"
                justifyContent="space-between"
                mb="12px"
              >
                <Text
                  fontWeight="600"
                  fontSize="27px"
                  color=" #32325D"
                  lineHeight="41px"
                >
                  {index === 4 ? "" : "$"}
                  {analyticsData[item.value] || 0}
                </Text>

                <Image src={item.image} />
              </Box>

              <Text
                fontWeight="500"
                fontSize="0.9rem"
                lineHeight="20px"
                color="#32325D"
              >
                {item.title}
              </Text>
            </Box>
          );
        })}
      </Box>

      <Box
        display="flex"
        alignItems="center"
        flexDirection={["column", "row"]}
        justifyContent="space-between"
        p={["0px 15px", "0px 40px"]}
      >
        <Box
          width={["100%", "100%"]}
          bgColor="#ffffff"
          borderRadius="16px"
          boxShadow="0px 4px 8px rgba(211, 211, 211,
          0.25)"
          padding={["15px", "20px"]}
          minHeight="377px"
        >
          <Box borderBottom="1px solid #EAECF0" mb="16px">
            <Text
              fontWeight="500"
              fontSize="1rem"
              lineHeight="18px"
              color="#18181B"
              paddingBottom="14px"
            >
              Campaign Board
            </Text>
          </Box>

          <Box
            borderBottom="1px solid #EAECF0"
            mb="16px"
            display="flex"
            alignItems="center"
            pb="14px"
            justifyContent="space-between"
          >
            <Select
              fontSize="0.9rem"
              lineHeight="1.25rem"
              color="rgba(50, 50, 93, 0.80)"
              fontWeight="500"
              w="max"
              value={sortValue}
              onChange={(e) => setsortValue(e.target.value)}
            >
              <option value="">Sort by</option>
              <option value="h2l">Highest to Lowest</option>
              <option value="l2h">Lowest to Highest</option>
            </Select>

            <Button
              borderRadius="8px"
              fontWeight="500"
              fontSize={{ base: "0.75rem", md: "0.9rem" }}
              lineHeight={{ base: "18px", md: "21px" }}
              color="#7211D4"
              bgColor="transparent"
              onClick={() => navigate("/affiliate-reports")}
            >
              See All Reports
              <IoIosArrowForward />
            </Button>
          </Box>

          {filterLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              h="30vh"
              alignItems="center"
            >
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Image className="loading" src="/assets/logo.png" />
                <Text
                  fontWeight="400"
                  fontSize="16px"
                  lineHeight="70px"
                  color="#000000"
                  mt="-15px"
                >
                  Loading....
                </Text>
              </Box>
            </Box>
          ) : (
            <Box
              css={
                isDesktop
                  ? {}
                  : css({
                      "::-webkit-scrollbar": { display: "none" },
                      "-webkit-overflow-scrolling": "touch",
                    })
              }
              overflowX={{ base: "scroll", md: "visible" }}
              maxWidth="100%"
            >
              {analyticsData &&
              analyticsData?.campaignTotalSales &&
              analyticsData?.campaignTotalSales.length ? (
                <Table mb="29px" border="1px solid #EAECF0" borderRadius="8px">
                  <Thead>
                    <Tr height="44px" bgColor="#F9FAFB">
                      {titles.map((title) => {
                        return (
                          <Th
                            color="#667085"
                            textTransform="uppercase"
                            lineHeight="18px"
                            fontSize="12px"
                            fontWeight="500"
                            key={title}
                          >
                            {title}
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>

                  <Tbody>
                    {analyticsData?.campaignTotalSales
                      .filter((item: any, idx: number) => idx < 10)
                      .map((item: any, index: number) => {
                        return (
                          <Tr key={index} height="80px" width="100%">
                            <Td>
                              <Text
                                color="#667085"
                                lineHeight={{ base: "18px", md: "21px" }}
                                fontSize={{ base: "12px", md: "14px" }}
                                fontWeight="500"
                              >
                                {item.date?.split("T")[0] || ""}
                              </Text>
                            </Td>

                            <Td>
                              <Text
                                color="#101828"
                                lineHeight={{ base: "18px", md: "21px" }}
                                fontSize={{ base: "12px", md: "14px" }}
                                fontWeight="500"
                              >
                                {item.campaignName}
                              </Text>
                            </Td>

                            <Td>
                              <Text
                                color="#101828"
                                lineHeight={{ base: "18px", md: "21px" }}
                                fontSize={{ base: "12px", md: "14px" }}
                                fontWeight="500"
                              >
                                {(item.productId &&
                                  item.productId
                                    .map((p: any) => p.id)
                                    .join(", ")) ||
                                  ""}
                              </Text>
                            </Td>

                            <Td>
                              <Text
                                color="#667085"
                                lineHeight={{ base: "18px", md: "21px" }}
                                fontSize={{ base: "12px", md: "14px" }}
                                fontWeight="500"
                              >
                                {item?.totalLeads}
                              </Text>
                            </Td>

                            <Td>
                              <Text
                                color="#667085"
                                lineHeight={{ base: "18px", md: "21px" }}
                                fontSize={{ base: "12px", md: "14px" }}
                                fontWeight="500"
                              >
                                ${item?.totalSales || 0}
                              </Text>
                            </Td>

                            <Td>
                              <Text
                                color="#667085"
                                lineHeight={{ base: "18px", md: "21px" }}
                                fontSize={{ base: "12px", md: "14px" }}
                                fontWeight="500"
                              >
                                ${item?.totalCommissions || 0}
                              </Text>
                            </Td>

                            <Td>
                              {item?.status === "approved" ? (
                                <Box
                                  color="#22C55E"
                                  rounded="40px"
                                  bg="#DCFCE7"
                                  display="flex"
                                  alignItems="center"
                                  w="max"
                                  padding="6px 8px"
                                >
                                  <MdFiberManualRecord fontSize="0.75rem" />
                                  <Text
                                    textTransform="capitalize"
                                    fontSize="0.75rem"
                                    lineHeight="1.25rem"
                                    fontWeight="500"
                                    ml="8px"
                                  >
                                    {item.status || ""}
                                  </Text>
                                </Box>
                              ) : (
                                <Box
                                  color="#FACC15"
                                  rounded="40px"
                                  bg="#FEF9C3"
                                  display="flex"
                                  alignItems="center"
                                  w="max"
                                  padding="6px 8px"
                                >
                                  <MdFiberManualRecord fontSize="0.75rem" />
                                  <Text
                                    textTransform="capitalize"
                                    fontSize="0.75rem"
                                    lineHeight="1.25rem"
                                    fontWeight="500"
                                    color="#713F12"
                                    ml="8px"
                                  >
                                    {item.status || ""}
                                  </Text>
                                </Box>
                              )}
                            </Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              ) : (
                <Box
                  w="100%"
                  height="50vh"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  p={{ base: "0px 16px", md: "0px" }}
                  mb="21px"
                >
                  <Image
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
                    height="250px"
                    objectFit="contain"
                    mb="16px"
                  />

                  <Text
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="21px"
                    color="#32325D"
                    textAlign="center"
                  >
                    No data available yet, when you have reports you'll see them
                    here.
                  </Text>
                </Box>
              )}
            </Box>
          )}
        </Box>

        <Modal
          closeOnOverlayClick={false}
          isOpen={onModalIsOpen}
          isCentered
          size="xl"
          onClose={() => {
            onClose();
            navigate("/affiliate-dashboard");
          }}
        >
          <ModalOverlay />

          <ModalContent>
            <ModalHeader
              fontSize="16px"
              fontWeight="600"
              lineHeight="25px"
              color="rgba(50, 50, 93, 1)"
              textTransform="capitalize"
            >
              {campaign?.campaign_name || ""}
            </ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <Text
                mb="4px"
                fontSize="16px"
                fontWeight="600"
                lineHeight="25px"
                color="rgba(50, 50, 93, 1)"
              >
                You are almost there
              </Text>

              <Text
                fontSize="12px"
                fontWeight="400"
                lineHeight="17px"
                color="rgba(50, 50, 93, 0.6)"
                mb="28px"
              >
                Answer the following questions and your application to join the
                campaign will be submitted
              </Text>

              <QuestionsForm
                questions={questions}
                answers={answers}
                defaultAns={defaultAns}
                setanswers={setanswers}
                setdefaultAns={setdefaultAns}
              />

              <Checkbox
                colorScheme="purple"
                spacing="14px"
                isChecked={hasAgreedToTerms}
                onChange={() => sethasAgreedToTerms(!hasAgreedToTerms)}
              >
                <Text
                  fontSize="14px"
                  letterSpacing="2%"
                  lineHeight="21px"
                  fontWeight="500"
                >
                  I agree to metricks{" "}
                  <a
                    style={{ color: "#7211D4" }}
                    href="https://www.metricks.io/termsandconditions"
                    target="_blank"
                  >
                    Terms and Conditions
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.metricks.io/PrivacyPolicy"
                    target="_blank"
                    style={{ color: "#7211D4" }}
                  >
                    Privacy Policy
                  </a>
                </Text>
              </Checkbox>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="purple"
                w="100%"
                isLoading={creatingOffers}
                onClick={JoinCampaign}
              >
                Submit
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          onClose={() => {
            navigate("/affiliate-dashboard");
            sessionStorage.removeItem("affiliateStatus");
            sessionStorage.removeItem("campaignId");
            sessionStorage.removeItem("affiliate_name");
            onModalClose();
          }}
          size="md"
          isOpen={isOpen}
        >
          <ModalOverlay />
          <ModalContent maxWidth="65% !important" borderRadius=" 10px">
            <ModalHeader display="flex" justifyContent="right">
              <Image
                onClick={() => {
                  navigate("/affiliate-dashboard");
                  sessionStorage.removeItem("affiliateStatus");
                  sessionStorage.removeItem("campaignId");
                  sessionStorage.removeItem("affiliate_name");
                  onModalClose();
                }}
                cursor="pointer"
                ml="7px"
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/3b803641-20b6-4e47-b554-8336ebd3ec5b.svg"
              />
            </ModalHeader>

            <ModalBody
              paddingBottom="26px"
              borderBottom="1px solid rgba(50, 50, 93, 0.3)"
            >
              {hasRequested ? (
                <Text
                  fontWeight="500"
                  fontSize="18px"
                  lineHeight="30px"
                  color=" rgba(50, 50, 93, 0.8)"
                  textAlign="center"
                >
                  You have already submitted a request to join{" "}
                  <Text
                    display="inline"
                    textTransform="capitalize"
                    color=" #7211D4"
                  >
                    {" "}
                    {campaign.campaign_name}{" "}
                  </Text>{" "}
                  campaign.
                </Text>
              ) : (
                <Text
                  fontWeight="500"
                  fontSize="18px"
                  lineHeight="30px"
                  color=" rgba(50, 50, 93, 0.8)"
                  textAlign="center"
                >
                  Your request to join{" "}
                  <Text
                    display="inline"
                    textTransform="capitalize"
                    color=" #7211D4"
                  >
                    {" "}
                    {campaign.campaign_name}{" "}
                  </Text>{" "}
                  campaign has been submitted.
                </Text>
              )}
            </ModalBody>

            <ModalFooter display="flex" justifyContent="left">
              <Button
                ml="15px"
                bgColor=" #F0F5FF"
                color="#5C14CE"
                borderRadius="8px"
                onClick={() => {
                  if (hasRequested) {
                    navigate(
                      `/affiliate-campaigns?id=${campaign.id}&status=${promoteCampaignReq.status}`
                    );
                    sessionStorage.removeItem("affiliateStatus");
                    sessionStorage.removeItem("campaignId");
                    sessionStorage.removeItem("affiliate_name");
                  } else {
                    navigate(
                      `/affiliate-campaigns?id=${campaign.id}${
                        affStatus === "approved"
                          ? "&status=approved"
                          : "&status=pending"
                      }`
                    );
                  }
                }}
              >
                Proceed
                <Image
                  cursor="pointer"
                  ml="10px"
                  mt="1px"
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/37d9e26a-0ec4-462d-98ac-8fca88493c0b.svg"
                />
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Stack>
  );
};

export default Dashboard;
