import { Box, Button, Image, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useCurrentAffiliateOrg } from "../../../../hooks/useCurrentOrg";
import useUserToken from "../../../../hooks/useUserToken";
import { FaRegImage } from "react-icons/fa6";

type RankedCampaignProps = {
  onModalOpen: () => void;
};

const RankedCampaings = ({ onModalOpen }: RankedCampaignProps) => {
  SwiperCore.use([Autoplay]);
  const swiperRef = useRef<any>();

  const navigate = useNavigate();
  const token = useUserToken("affiliate");
  const [topCampaigns, settopCampaigns] = useState<any[]>([]);

  const [_, profileIsComplete] = useCurrentAffiliateOrg();

  const getTop10Campaigns = () => {
    let bearerAuth = token;

    fetch(
      process.env.REACT_APP_API_ENDPOINT + "explore/offers/top10-campaigns",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          settopCampaigns(data.data.top10Campaigns);
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
        }
      })
      .catch((err) => {
        console.log("top 10 campaigns => " + err);
      });
  };

  useEffect(() => {
    getTop10Campaigns();
  }, []);

  const handleView = (id: number, status: string) => {
    // if (!profileIsComplete) return onModalOpen();

    if (status === "not_joined")
      return navigate(`/affiliate-explore/view/${id}`);

    return navigate(`/affiliate-campaigns?id=${id}&status=${status}`);
  };

  return (
    <Stack mb="60px" position="relative">
      <Box
        mb={{ base: "24px", md: "30px" }}
        pb={{ base: "0px", md: "17px" }}
        borderBottom={{
          base: "0px solid grey",
          md: "1px solid rgba(50, 50, 93, 0.3)",
        }}
      >
        <Text
          fontSize={{ base: "14px", md: "12px" }}
          textTransform="uppercase"
          lineHeight="18px"
          fontWeight={{ base: "500", md: "500" }}
          color="#32325D"
        >
          Top ranked Campaigns
        </Text>
      </Box>

      <Box position="relative">
        <Swiper
          spaceBetween={25}
          slidesPerView="auto"
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          autoplay={{ delay: 1000 }}
          rewind={true}
        >
          {topCampaigns.map((item: any, index: number) => {
            return (
              <SwiperSlide
                key={`${index + 1}`}
                onClick={() =>
                  handleView(item.id, item.campaignPromotionStatus)
                }
                style={{ width: "fit-content", height: "100%" }}
              >
                <Box
                  background="#FFFFFF"
                  border="0.5px solid rgba(50, 50, 93, 0.15)"
                  borderRadius=" 8px"
                  width="100%"
                  cursor="pointer"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    p="8px"
                  >
                    <Box
                      filter="auto"
                      blur={profileIsComplete ? "0px" : "0px"}
                      display="flex"
                      alignItems="center"
                    >
                      {item?.banner_image ? (
                        <Image
                          width="31px"
                          height="30px"
                          objectFit="cover"
                          src={item?.banner_image}
                        />
                      ) : (
                        <FaRegImage color="#b8b0ef" size={20} />
                      )}

                      <Text
                        fontWeight="600"
                        fontSize="0.9rem"
                        color=" #32325D"
                        lineHeight="21px"
                        pl="14px"
                        pr="20px"
                      >
                        {(item?.campaign_name && item?.campaign_name.length > 15
                          ? `${item?.campaign_name?.substring(0, 15)}...`
                          : item?.campaign_name) || ""}
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        fontWeight="600"
                        fontSize="0.9rem"
                        color=" #32325D"
                        lineHeight="21px"
                        opacity="0.5"
                      >
                        {`${index + 1}`}
                      </Text>
                    </Box>
                  </Box>

                  <Box p="8px" width="100%" borderTop="1px solid #F0F3F9">
                    <Box display="flex" alignItems="center">
                      <Text
                        fontWeight="400"
                        fontSize="0.75rem"
                        color=" #32325D"
                        lineHeight="18px"
                        opacity="0.5"
                        minWidth="90px"
                      >
                        Commission
                      </Text>
                      <Text
                        fontWeight="500"
                        fontSize="0.75rem"
                        color=" #32325D"
                        lineHeight="18px"
                      >
                        {item.commission_percentage !== null ? "%" : "$"}
                        {`${
                          item.commission_percentage !== null
                            ? item.commission_percentage || 0
                            : item.commission_amount || 0
                        }`}
                      </Text>
                    </Box>

                    <Box display="flex" alignItems="center">
                      <Text
                        fontWeight="400"
                        fontSize="0.75rem"
                        color=" #32325D"
                        lineHeight="18px"
                        opacity="0.5"
                        minWidth="90px"
                      >
                        Category
                      </Text>
                      <Text
                        fontWeight="500"
                        fontSize="0.75rem"
                        color=" #32325D"
                        lineHeight="18px"
                      >
                        ECOMMERCE
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </SwiperSlide>
            );
          })}
          <SwiperSlide key="blank" style={{ width: "fit-content" }}>
            <Box width="25px"></Box>
          </SwiperSlide>
        </Swiper>
      </Box>

      <Button
        background="#FFFFFF"
        boxShadow="0px 0px 7.42829px rgba(114, 17, 212, 0.25)"
        borderRadius=" 4.64268px"
        width="32px"
        height="28px"
        position="absolute"
        top={{ base: "50%", md: "100px" }}
        zIndex="10"
        right="-20px"
        onClick={() => swiperRef.current?.slideNext()}
      >
        <Image
          src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/67853fcc-a95e-4476-be48-14434b51b27d.svg`}
        />
      </Button>
    </Stack>
  );
};

export default RankedCampaings;
