import { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Box, Text } from "@chakra-ui/react";

interface PropSchema {
  holder: string;
  value: any;
  label: string;
  stateHandler: React.Dispatch<React.SetStateAction<any>>;
  required?: boolean;
}

export const Phone = ({
  holder,
  value,
  stateHandler,
  label,
  required,
}: PropSchema) => {
  const [focused, setfocused] = useState(false);

  return (
    <Box my=".5em">
      <Text
        fontSize="14px"
        mb=".1em"
        fontWeight={focused ? "medium" : "normal"}
        color={focused ? " #5C14CE" : "#737373"}
      >
        {`${label}`}
        {required ? <span style={{ color: "#dc3545" }}> *</span> : ""}
      </Text>
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        defaultCountry="NG"
        placeholder={holder}
        onFocus={(e) => setfocused(true)}
        onBlur={(e) => setfocused(false)}
        value={value}
        onChange={stateHandler}
        required={true}
        className="signup-phone-input"
        fontSize="15px"
        style={{
          padding: "1em 0em",
          color: "#000",
        }}
      />
    </Box>
  );
};
