import { Box, Stack } from "@chakra-ui/react";
import { ReactNode } from "react";
interface ContainerProps {
  children: ReactNode;
}
const Container = ({ children, ...rest }: ContainerProps) => {
  return (
    <Stack
      height="auto"
      width="100%"
      direction="row"
      justifyContent="space-between"
      position="relative"
    >
      <Box
        height={["14px", "60px"]}
        position="absolute"
        width={["14px", "60px"]}
        borderRadius="50%"
        bgGradient="linear(180deg, #7211D4 0%, #f0f5ff 49.14%)"
        transform="rotate(-16.74deg)"
        top="10%"
        left="7%"
        zIndex="100"
      ></Box>
      <Box
        height={["14px", "60px"]}
        position="absolute"
        width={["14px", "60px"]}
        borderRadius="50%"
        bgGradient="linear(180deg,  #FB9B54 0%, #f0f5ff 49.14%)"
        transform="rotate(47.45deg)"
        top="10%"
        right="7%"
        zIndex="100"
      ></Box>
      {children}
      <Box
        height={["14px", "60px"]}
        position="absolute"
        width={["14px", "60px"]}
        borderRadius="50%"
        bgGradient="linear(180deg, #4665A8 0%, #f0f5ff 49.14%)"
        transform="rotate(-16.74deg)"
        top="38%"
        left="12%"
        zIndex="100"
      ></Box>
      <Box
        height={["14px", "40px"]}
        position="absolute"
        width={["14px", "40px"]}
        borderRadius="50%"
        bgGradient="linear(180deg,  #C44CC0 0%, #f0f5ff 49.14%)"
        transform="rotate(47.45deg)"
        top="30%"
        right="17%"
        zIndex="100"
      ></Box>
    </Stack>
  );
};

export default Container;
