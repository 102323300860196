import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Link,
  Select,
  Stack,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useUserToken from "../../../hooks/useUserToken";
import { FaEdit } from "react-icons/fa";
import { gsap, Power2 } from "gsap";
import useJsonToCSV from "../../../hooks/useJsonToCSV";

const PayoutManager = () => {
  const [paypalDetails, setpaypalDetails] = useState({
    paypalId: "",
    paypalKey: "",
    payoutFrequency: "weekly",
    payoutRepeat: "1",
  });
  const [loading, setloading] = useState(true);
  const [editMode, seteditMode] = useState(false);
  const [savingSettings, setsavingSettings] = useState(false);
  const [dataToDownload, setdataToDownload] = useState([]);

  const token = useUserToken();
  const toast = useToast();

  const getPaypalDetails = () => {
    let bearerAuth = token;
    setloading(true);

    fetch(process.env.REACT_APP_API_ENDPOINT + `/paypal/settings`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setloading(false);

          setpaypalDetails({
            paypalId: data.data.response.paypal_client_id
              ? data.data.response.paypal_client_id
              : "",
            paypalKey: data.data.response.paypal_secret
              ? data.data.response.paypal_secret
              : "",
            payoutFrequency: data.data.response.payment_interval,
            payoutRepeat: `${data.data.response.payment_repeat}`,
          });
        }
      })
      .catch((err) => {
        setloading(false);
        console.log("get payout manger => " + err);
      });
  };

  const savePayoutManagerSettings = () => {
    let bearerAuth = token;
    setsavingSettings(true);

    const payload = {
      // paypalClientId: paypalDetails.paypalId,
      // paypalClientSecret: paypalDetails.paypalKey,
      paymentInterval: paypalDetails.payoutFrequency,
      paymentRepeat: Number(paypalDetails.payoutRepeat),
    };

    fetch(process.env.REACT_APP_API_ENDPOINT + `paypal/settings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setsavingSettings(false);
          seteditMode(false);

          return toast({
            title: `Payout Settings have been saved successfully`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          setsavingSettings(false);

          return toast({
            title: `Failed to save payout settings`,
            description: data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      });
  };

  const getPendingPayouts = () => {
    let bearerAuth = token;

    fetch(process.env.REACT_APP_API_ENDPOINT + `/download/pending/payouts`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          console.log(data.data.response);
          setdataToDownload(data.data.response);
        }
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        console.log("get payout manger => " + err);
      });
  };

  const [csvFileDownloadLink] = useJsonToCSV(
    ["FIRST NAME", "LAST NAME", "PAYPAL EMAIL", "COMPANY NAME"],
    dataToDownload
  );
  const [isDesktop] = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    getPaypalDetails();
    getPendingPayouts();
    gsap.fromTo(
      ".loading",
      {
        opacity: 0,
        ease: Power2.easeInOut,
        scale: 0,
        y: 80,
      },
      {
        opacity: 1,
        duration: 1.5,
        scale: 1,
        ease: Power2.easeInOut,
        repeat: -1,
        y: 0,
        yoyo: true,
      }
    );
  }, []);

  return (
    <Stack m="28px 0px" bgColor="white" p="25px 30px" minH="50vh">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="14px"
      >
        <Text
          fontWeight="600"
          fontSize={{ base: "14px", md: "16px" }}
          lineHeight="24px"
          color="rgba(50, 50, 93, 1)"
        >
          Payout Manager
        </Text>

        <Box display="flex" alignItems="center">
          <Link
            href={csvFileDownloadLink}
            download={`Pending_Payout.csv`}
            height="38px"
            borderRadius="8px"
            display="flex"
            bgColor="#7211D4"
            color="white"
            alignItems="center"
            justifyContent="center"
            mr="14px"
            px="8px"
            _hover={{ textDecoration: "none" }}
          >
            <Text
              color="white"
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight={{ base: "18px", md: "21px" }}
              fontWeight="500"
            >
              {isDesktop ? "Download Pending Affiliate Payment" : "Download"}
            </Text>
          </Link>

          <FaEdit
            style={{ color: "#7211D4", fontSize: "16px", cursor: "pointer" }}
            onClick={() => seteditMode(!editMode)}
          />
        </Box>
      </Box>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          h="20vh"
          alignItems="center"
          pt="20px"
        >
          <Image className="loading" src="/assets/logo.png" height="60px" />
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="70px"
            color="#000000"
            mt="-15px"
          >
            Loading Payout Manager Settings...
          </Text>
        </Box>
      ) : (
        <Box w={"100%"}>
          {/* <Box
            display="flex"
            alignItems={{ base: "start", md: "center" }}
            flexDir={{ base: "column", md: "row" }}
            justifyContent="space-between"
            py="16px"
            mb="7px"
            borderTop="0.5px solid rgba(50, 50, 93, 0.3)"
            >
            <Text fontWeight="500" fontSize="14px" lineHeight="21px">
              Paypal ID
              </Text>
              
            <Input
            p="12px 30px"
            color="rgba(50, 50, 93, 1)"
            borderRadius="8px"
              width={{ base: "full", md: "60%" }}
              border="0.6px solid rgba(50, 50, 93, 0.3)"
              value={paypalDetails.paypalId}
              disabled={!editMode ? true : false}
              _disabled={{ opacity: "0.8" }}
              onChange={(e) =>
                setpaypalDetails({
                  ...paypalDetails,
                  paypalId: e.target.value,
                })
              }
              />
          </Box>
          
          <Box
          display="flex"
            alignItems={{ base: "start", md: "center" }}
            flexDir={{ base: "column", md: "row" }}
            justifyContent="space-between"
            py="16px"
            mb="7px"
            borderTop="0.5px solid rgba(50, 50, 93, 0.3)"
            >
            <Text fontWeight="500" fontSize="14px" lineHeight="21px">
            Paypal Key
            </Text>
            
            <Input
            p="12px 30px"
              color="rgba(50, 50, 93, 1)"
              borderRadius="8px"
              width={{ base: "full", md: "60%" }}
              border="0.6px solid rgba(50, 50, 93, 0.3)"
              value={paypalDetails.paypalKey}
              disabled={!editMode ? true : false}
              _disabled={{ opacity: "0.8" }}
              onChange={(e) =>
                setpaypalDetails({
                  ...paypalDetails,
                  paypalKey: e.target.value,
                })
              }
              />
          </Box> */}

          <Box
            display="flex"
            alignItems={{ base: "start", md: "center" }}
            flexDir={{ base: "column", md: "row" }}
            justifyContent="space-between"
            py="16px"
            mb="7px"
            w={"100%"}
            // bgColor={"red"}
            borderTop="0.5px solid rgba(50, 50, 93, 0.3)"
          >
            <Flex width={["100%", "30%"]}>
              <Text fontWeight="500" fontSize="14px" lineHeight="21px">
                Payout frequency
              </Text>
            </Flex>

            <Flex width={["100%", "70%"]}>
              <Select
                p="12px 0px"
                mb="7px"
                color="rgba(50, 50, 93, 1)"
                borderRadius="8px"
                width={{ base: "full", md: "60%" }}
                border="0.6px solid rgba(50, 50, 93, 0.3)"
                disabled={!editMode ? true : false}
                _disabled={{ opacity: "0.8" }}
                value={paypalDetails.payoutFrequency}
                onChange={(e) =>
                  setpaypalDetails({
                    ...paypalDetails,
                    payoutFrequency: e.target.value,
                  })
                }
                style={{
                  padding: "10px",
                  height: "50px",
                }}
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="annually">Annually</option>
              </Select>
            </Flex>
          </Box>

          <Box
            display="flex"
            alignItems={{ base: "start", md: "center" }}
            flexDir={{ base: "column", md: "row" }}
            justifyContent="space-between"
            py="16px"
            borderTop="0.5px solid rgba(50, 50, 93, 0.3)"
          >
            <Flex width={["100%", "30%"]}>
              <Text fontWeight="500" fontSize="14px" lineHeight="21px">
                Paypal Repeat
              </Text>
            </Flex>
            <Flex width={["100%", "70%"]}>
              <Input
                p="12px 30px"
                color="rgba(50, 50, 93, 1)"
                borderRadius="8px"
                type="number"
                width={{ base: "full", md: "60%" }}
                border="0.6px solid rgba(50, 50, 93, 0.3)"
                value={paypalDetails.payoutRepeat}
                disabled={!editMode ? true : false}
                _disabled={{ opacity: "0.8" }}
                onChange={(e) =>
                  setpaypalDetails({
                    ...paypalDetails,
                    payoutRepeat: e.target.value,
                  })
                }
                style={{
                  height: "50px",
                  backgroundColor: "#fefdff",
                  borderColor: "#c4c4c4",
                  borderRadius: "10px",
                }}
              />
            </Flex>
          </Box>
        </Box>
      )}

      <Box
        display={editMode ? "flex" : "none"}
        justifyContent="end"
        mt="28px"
        pt="16px"
        borderTop="0.5px solid rgba(50, 50, 93, 0.3)"
      >
        <Button
          borderRadius="8px"
          bgColor="#7211D4"
          color="white"
          fontWeight="500"
          mr="14px"
          fontSize={{ base: "12px", md: "14px" }}
          lineHeight={{ base: "18px", md: "21px" }}
          disabled={
            !paypalDetails.paypalId ||
            !paypalDetails.paypalKey ||
            savingSettings
              ? true
              : false
          }
          isLoading={savingSettings}
          onClick={savePayoutManagerSettings}
        >
          Save Settings
        </Button>
      </Box>
    </Stack>
  );
};

export default PayoutManager;
