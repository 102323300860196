import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { InputHints } from "../../InputHints";
import { useCookies } from "react-cookie";
import axios from "axios";
import {
  getCurrentUserInstance,
  setLocalUserInstance,
} from "../../../state/localstorage";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import useUserToken from "../../../hooks/useUserToken";

export const Account = () => {
  const navigage = useNavigate();
  const [editState, setEditState] = useState(false);
  const editToggleHandler = () => setEditState(!editState);

  const [currentUser, setcurrentUser] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  });
  const [currentUserPhone, setcurrentUserPhone] = useState<any>("");
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const [cookies] = useCookies();
  const [avatar, setAvatar] = useState<{ url: any; info: any }>({
    url: "",
    info: "",
  });
  const [uploadingPhoto, setuploadingPhoto] = useState(false);
  const [showNewPass, setshowNewPass] = useState(false);
  const [showConfirmPass, setshowConfirmPass] = useState(false);
  const [showCurrentPass, setshowCurrentPass] = useState(false);

  const [loading, setLoading] = useState(false);
  const accessToken = useUserToken("affiliate");

  const getUserData = () => {
    fetch(process.env.REACT_APP_API_ENDPOINT + `auth/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken ? `Bearer ${accessToken}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setAvatar({ url: data.data?.user?.profile_image || "", info: "" });
          setcurrentUser({
            firstName: data.data?.user?.firstname || "",
            lastName: data.data?.user?.lastname || "",
            email: data.data?.user?.email || "",
            phoneNumber: data.data?.user?.phoneNumber || "",
          });
          setcurrentUserPhone(data.data?.user?.phoneNumber || "");
        }
      })
      .catch((err) => {
        console.log("get my info => " + err);
      });
  };

  const checkToken = async () => {
    if (!accessToken) return;

    await fetch(process.env.REACT_APP_API_ENDPOINT + `/auth/valid-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: accessToken }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data?.valid) {
          return;
        } else {
          window.localStorage.clear();
          sessionStorage.removeItem("currentUserType");
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          navigage("/login");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (accessToken) {
      checkToken();
    }
  }, [accessToken]);

  useEffect(() => {
    getUserData();
  }, []);

  const hanldeFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setuploadingPhoto(true);

      const formData = new FormData();
      formData.append("photo", e.target.files[0]);

      fetch(process.env.REACT_APP_API_ENDPOINT + `upload/photo`, {
        method: "POST",
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : "",
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setAvatar({
              url: data.data.url || "",
              info: e.target.files ? e.target.files[0] : "",
            });
            return setuploadingPhoto(false);
          } else {
            setuploadingPhoto(false);
            return toast.error("Failed to upload photo");
          }
        })
        .catch((err) => {
          setuploadingPhoto(false);
          return toast.error("Failed to upload photo");
        });
    }
  };

  const updateDetailsHandler = async (e: React.ChangeEvent<any>) => {
    e.preventDefault();

    setLoading(true);

    if (!currentUserPhone || currentUserPhone.length <= 6) {
      setLoading(false);
      return toast.warn("Phone number should be valid");
    }

    console.log(currentUserPhone);

    const payload = {
      firstname: currentUser.firstName,
      lastname: currentUser.lastName,
      email: currentUser.email,
      phoneNumber: currentUserPhone,
      profile_image: avatar.url,
      updatePassword: false,
      // newPassword: "",
    };

    await fetch(process.env.REACT_APP_API_ENDPOINT + `auth/update-profile`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.success) {
          toast.warn(data.message);
        }

        if (data.success) {
          toast.success("Your profile has been updated");
          const user = getCurrentUserInstance();
          let result = Object.assign({}, user, data.data);
          setLocalUserInstance(result);
          setEditState(false);
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("ERR", err);
      });
  };

  const [passLoader, setPassloader] = useState(false);

  const updatePasswordHandler = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    if (!confPass) return toast.warn("Please complete all required field");
    if (confPass !== newPass) return toast.warn("Passwords should match");

    setPassloader(true);
    const payload = {
      currentPassword: currentPass,
      newPassword: newPass,
      updatePassword: true,
    };

    fetch(process.env.REACT_APP_API_ENDPOINT + `auth/update-profile`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        setPassloader(false);

        if (!data.success) {
          toast.warn(data.message);
        }

        if (data.success) {
          const timeOut = window.setTimeout(
            () => toast.success("Your password has been updated"),
            1000 / 2
          );

          localStorage.clear();
          document.cookie =
            "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie =
            "user_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie =
            "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          //  redirect to login page
          navigage("/login");
        }
      })
      .catch((err) => {
        setPassloader(false);
        console.log("ERR", err);
      });
  };

  const getPhoneCode = (value: string) => {
    const parsedValue = parsePhoneNumber(value ? value : "", "NG");
    if (parsedValue) {
      return parsedValue.country || "NG";
    }

    return "NG";
  };

  const [editPassword, setEditPassword] = useState(false);

  return (
    <Flex flexDirection="column" py={{ md: "28px" }}>
      <ToastContainer />
      <Box
        alignItems="center"
        display={{ base: "flex", md: "none" }}
        p="15px 24px"
      >
        <Text
          fontSize="14px"
          lineHeight="21px"
          fontWeight="700"
          color="#32325D"
          mr="6px"
        >
          Dashboard
        </Text>

        <MdChevronRight size={16} color="#32325D" />

        <Text
          ml="6px"
          fontSize="14px"
          lineHeight="21px"
          fontWeight="400"
          color="#32325D"
        >
          Account Settings
        </Text>
      </Box>

      <Box
        px="24px"
        bgColor={{ base: "#FAFBFE", md: "transparent" }}
        py={{ base: "10px", md: "0px" }}
      >
        <Box
          alignItems="center"
          display={{ base: "flex", md: "none" }}
          my="12px"
          onClick={() => navigage(-1)}
        >
          <MdChevronLeft size={20} color="#32325D" />
          <Text
            fontSize="14px"
            lineHeight="21px"
            fontWeight="500"
            color="#32325D"
            mr="10px"
          >
            Back
          </Text>
        </Box>

        <Box bgColor="#fff" rounded="8px">
          <form onSubmit={updateDetailsHandler}>
            <Flex
              columnGap="1.2em"
              borderBottom="1px solid"
              borderBottomColor="gray.300"
              px={{ base: "1.25rem", md: "1.5em", lg: "2em" }}
              pt="1em"
              pb="1.6em"
              justifyContent="space-between"
            >
              {editState ? (
                <Flex
                  alignItems="center"
                  columnGap={{ base: "0.5rem", md: "1em" }}
                >
                  <BsArrowLeft
                    cursor="pointer"
                    onClick={() => setEditState(false)}
                  />
                  <Text
                    fontSize={{ base: "0.9rem", md: "1em" }}
                    color="#7211D4"
                    fontWeight="600"
                    lineHeight="24px"
                  >
                    Edit Profile
                  </Text>
                </Flex>
              ) : (
                <Text
                  fontSize={{ base: "0.9rem", md: "1em" }}
                  color="#32325D"
                  fontWeight="600"
                  lineHeight="24px"
                >
                  Profile
                </Text>
              )}

              {editState ? (
                <Button
                  type="submit"
                  fontSize={{ base: "0.75rem", md: "0.9rem" }}
                  fontWeight="500"
                  colorScheme="purple"
                  bgColor="#7211D4"
                  isLoading={loading}
                >
                  Update Profile
                </Button>
              ) : (
                ""
              )}
            </Flex>

            <Flex
              px={["1em", "1.5em", "2em"]}
              pt="2rem"
              pb={{ base: "0px", md: "2em" }}
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Flex
                columnGap="1em"
                alignItems="center"
                flexDir={["column", "row"]}
              >
                {/* <Image src=""/> */}
                <Box position="relative" alignSelf={["flex-start"]}>
                  {avatar.url ? (
                    <Image
                      src={avatar.url}
                      objectFit="cover"
                      rounded="full"
                      width={["6em", "6em", "8em"]}
                      height={["6em", "6em", "8em"]}
                    />
                  ) : (
                    <Box
                      bgColor="gray.300"
                      width={["6em", "6em", "8em"]}
                      height={["6em", "6em", "8em"]}
                      borderRadius={["3em", "3em", "4em"]}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Text
                        fontSize="1.125rem"
                        lineHeight="27px"
                        fontWeight="600"
                        color="#32325D"
                      >
                        {(currentUser.firstName
                          ? currentUser.firstName.toUpperCase()[0]
                          : "") +
                          (currentUser.lastName
                            ? currentUser.lastName.toUpperCase()[0]
                            : "") || "-"}
                      </Text>
                    </Box>
                  )}

                  {editState && (
                    <>
                      <label htmlFor="avatar">
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          id="avatar"
                          style={{ display: "none" }}
                          onChange={hanldeFileChange}
                        />
                        <Flex flexDir="row-reverse">
                          <Box
                            position="absolute"
                            bottom={[!avatar ? "0" : "1em"]}
                            right=".5em"
                            p=".5em"
                            bgColor="white"
                            boxShadow="base"
                            borderRadius="1em"
                            cursor="pointer"
                          >
                            <FiEdit3 />
                          </Box>

                          {uploadingPhoto ? (
                            <small>uploading...</small>
                          ) : (
                            <small>
                              {
                                // @ts-ignore
                                avatar &&
                                avatar.info?.name &&
                                avatar.info?.name?.length > 8
                                  ? // @ts-ignore
                                    avatar.info.name.slice(0, 8) + "..."
                                  : // @ts-ignore
                                  avatar &&
                                    avatar.info?.name &&
                                    avatar.info?.name?.length <= 8
                                  ? // @ts-ignore
                                    avatar.info.name
                                  : ""
                              }
                            </small>
                          )}
                        </Flex>
                      </label>
                    </>
                  )}
                </Box>
                <Text
                  fontSize="18px"
                  lineHeight="27px"
                  fontWeight="600"
                  color="#32325D"
                  ml={{ md: "28px" }}
                  mt={{ base: "1rem", md: "0rem" }}
                >
                  {currentUser.firstName + " " + currentUser.lastName}
                </Text>
              </Flex>

              {!editState && (
                <Button
                  color="rgba(50, 50, 93, 0.80)"
                  fontSize="0.9rem"
                  variant="outline"
                  fontWeight="500"
                  border="0.5px solid"
                  borderColor="rgba(50, 50, 93, 0.50)"
                  onClick={editToggleHandler}
                  size={["sm", "md"]}
                >
                  Edit Profile
                </Button>
              )}
            </Flex>

            <Box
              pt={{ base: "1rem", md: "2em" }}
              pb="1em"
              px={["1.25rem", "1.5em", "2em"]}
            >
              <Flex
                alignItems="center"
                justifyContent="start"
                my="1.5em"
                flexDirection={["column", "row"]}
                borderTop={["0px", "1px solid"]}
                borderTopColor={{ md: "gray.300" }}
                pt={[".5em", "1.5em"]}
              >
                <Text
                  fontSize="0.9rem"
                  lineHeight="21px"
                  fontWeight="600"
                  color="#32325D"
                  width={{ base: "100%", md: "30%" }}
                >
                  First Name <span style={{ color: "#dc3545" }}>*</span>
                </Text>
                <Input
                  maxW="580px"
                  mt={{ base: "10px", md: "0px" }}
                  w={{ base: "100%", md: "70%" }}
                  _disabled={{ opacity: "100%" }}
                  color="#32325D"
                  fontWeight="500"
                  fontSize="0.9rem"
                  lineHeight="21px"
                  border="0.6px solid rgba(50, 50, 93, 0.3)"
                  p="14px 32px"
                  type="text"
                  value={currentUser.firstName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setcurrentUser({
                      ...currentUser,
                      firstName: e.target.value
                        .toLowerCase()
                        .replace(/(^|\s)\S/g, (l) => l.toUpperCase()),
                    })
                  }
                  _focus={{ ring: "0", borderColor: "#5C14CE" }}
                  _placeholder={{ color: "rgba(50, 50, 93, 0.40)" }}
                  isRequired={true}
                  isDisabled={!editState}
                />
              </Flex>
              <Flex
                alignItems="center"
                justifyContent="start"
                my="1.5em"
                flexDirection={["column", "row"]}
                borderTop={["0px", "1px solid"]}
                borderTopColor={{ md: "gray.300" }}
                pt={[".5em", "1.5em"]}
              >
                <Text
                  fontSize="0.9rem"
                  lineHeight="21px"
                  fontWeight="600"
                  color="#32325D"
                  width={{ base: "100%", md: "30%" }}
                >
                  Last Name <span style={{ color: "#dc3545" }}>*</span>
                </Text>
                <Input
                  maxW="580px"
                  mt={{ base: "10px", md: "0px" }}
                  w={{ base: "100%", md: "70%" }}
                  _disabled={{ opacity: "100%" }}
                  color="#32325D"
                  fontWeight="500"
                  fontSize="0.9rem"
                  lineHeight="21px"
                  border="0.6px solid rgba(50, 50, 93, 0.3)"
                  p="14px 32px"
                  type="text"
                  value={currentUser.lastName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setcurrentUser({
                      ...currentUser,
                      lastName: e.target.value
                        .toLowerCase()
                        .replace(/(^|\s)\S/g, (l) => l.toUpperCase()),
                    })
                  }
                  _placeholder={{ color: "rgba(50, 50, 93, 0.40)" }}
                  _focus={{ ring: "0", borderColor: "#5C14CE" }}
                  isRequired={true}
                  isDisabled={!editState}
                />
              </Flex>

              <Flex
                alignItems="center"
                justifyContent="start"
                my="1.5em"
                flexDirection={["column", "row"]}
                borderTop={["0px", "1px solid"]}
                borderTopColor={{ md: "gray.300" }}
                pt={[".5em", "1.5em"]}
              >
                <Text
                  fontSize="0.9rem"
                  lineHeight="21px"
                  fontWeight="600"
                  color="#32325D"
                  width={{ base: "100%", md: "30%" }}
                >
                  Email Address <span style={{ color: "#dc3545" }}>*</span>
                </Text>
                <Input
                  maxW="580px"
                  mt={{ base: "10px", md: "0px" }}
                  w={{ base: "100%", md: "70%" }}
                  _disabled={{ opacity: "100%" }}
                  color="#32325D"
                  fontWeight="500"
                  fontSize="0.9rem"
                  p="14px 32px"
                  lineHeight="21px"
                  border="0.6px solid rgba(50, 50, 93, 0.3)"
                  type="email"
                  value={currentUser.email}
                  onChange={(e: React.ChangeEvent<any>) =>
                    setcurrentUser({ ...currentUser, email: e.target.value })
                  }
                  _focus={{ ring: "0", borderColor: "#5C14CE" }}
                  _placeholder={{ color: "rgba(50, 50, 93, 0.40)" }}
                  isRequired={true}
                  isDisabled={!editState}
                />
              </Flex>

              <Flex
                alignItems="center"
                justifyContent="start"
                my="1.5em"
                flexDirection={["column", "row"]}
                borderTop={["0px", "1px solid"]}
                borderTopColor={{ md: "gray.300" }}
                py={[".5em", "1em"]}
              >
                <Text
                  fontSize="0.9rem"
                  lineHeight="21px"
                  fontWeight="600"
                  color="#32325D"
                  width={{ base: "100%", md: "30%" }}
                >
                  Phone Number <span style={{ color: "#dc3545" }}>*</span>
                </Text>

                <Box
                  maxW="580px"
                  mt={{ base: "10px", md: "0px" }}
                  w={{ base: "100%", md: "70%" }}
                >
                  <PhoneInput
                    international
                    countryCallingCodeEditable={true}
                    defaultCountry={getPhoneCode(currentUser.phoneNumber)}
                    countrySelectProps={{ disabled: editState ? false : true }}
                    placeholder={""}
                    value={currentUserPhone}
                    onChange={setcurrentUserPhone}
                    required={true}
                    fontSize="15px"
                    style={{
                      color: "#32325D",
                      fontWeight: "500",
                      fontSize: "0.9rem",
                      lineHeight: "21px",
                    }}
                    readOnly={editState ? false : true}
                    className="accountSettingsPhone"
                  />
                </Box>
              </Flex>
            </Box>
          </form>
        </Box>

        <Box bgColor="#fff" my="2em" rounded="8px">
          <form onSubmit={updatePasswordHandler}>
            <Box py="1.5em">
              <Flex
                alignItems="center"
                justifyContent="space-between"
                px={{ base: "1.25rem", md: "1.5em", lg: "2em" }}
                pb="1.6em"
                borderBottom="1px solid"
                borderTopColor={{ md: "gray.300" }}
                borderBottomColor="gray.300"
              >
                {!editPassword ? (
                  <Text
                    fontSize={{ base: "0.9rem", md: "1em" }}
                    color="#7211D4"
                    fontWeight="600"
                    lineHeight="24px"
                  >
                    Change Password
                  </Text>
                ) : (
                  <Flex
                    alignItems="center"
                    columnGap={{ base: "0.5rem", md: "1em" }}
                  >
                    <BsArrowLeft
                      cursor="pointer"
                      onClick={() => setEditPassword(false)}
                    />
                    <Text
                      fontSize={{ base: "0.9rem", md: "1em" }}
                      color="#7211D4"
                      fontWeight="600"
                      lineHeight="24px"
                    >
                      Edit Password
                    </Text>
                  </Flex>
                )}

                {!editPassword && (
                  <Button
                    color="rgba(50, 50, 93, 0.80)"
                    fontSize={{ base: "0.75rem", md: "0.9rem" }}
                    variant="outline"
                    fontWeight="500"
                    border="0.5px solid"
                    borderColor="rgba(50, 50, 93, 0.50)"
                    onClick={() => setEditPassword(true)}
                    size={["sm", "md"]}
                  >
                    Change Password
                  </Button>
                )}

                {editPassword && (
                  <Button
                    isLoading={passLoader}
                    type="submit"
                    fontSize={{ base: "0.75rem", md: "0.9rem" }}
                    fontWeight="500"
                    colorScheme="purple"
                    bgColor="#7211D4"
                  >
                    Update Password
                  </Button>
                )}
              </Flex>

              <Box mt="1em" px={{ base: "1.25rem", md: "1.5em", lg: "2em" }}>
                <Flex
                  alignItems="center"
                  justifyContent="start"
                  my="1.5em"
                  flexDirection={["column", "row"]}
                >
                  <Text
                    fontSize="0.9rem"
                    lineHeight="21px"
                    fontWeight="600"
                    color="#32325D"
                    width={{ base: "100%", md: "30%" }}
                  >
                    Current Password <span style={{ color: "#dc3545" }}>*</span>
                  </Text>

                  <InputGroup
                    alignItems="center"
                    maxWidth="580px"
                    w={{ base: "100%", md: "70%" }}
                    mt={{ base: "10px", md: "0px" }}
                  >
                    <Input
                      type={showCurrentPass ? "text" : "password"}
                      p="14px 32px"
                      _disabled={{ opacity: "100%" }}
                      color="#32325D"
                      fontWeight="500"
                      fontSize="0.9rem"
                      lineHeight="21px"
                      border="0.6px solid rgba(50, 50, 93, 0.3)"
                      value={currentPass}
                      onChange={(e: React.ChangeEvent<any>) =>
                        setCurrentPass(e.target.value)
                      }
                      _focus={{ ring: "0", borderColor: "#5C14CE" }}
                      isDisabled={!editPassword}
                      placeholder="Enter your current password"
                      _placeholder={{ color: "rgba(50, 50, 93, 0.40)" }}
                    />

                    {editPassword && (
                      <InputRightElement>
                        {showCurrentPass ? (
                          <AiFillEye
                            size="14px"
                            color="#32325D"
                            onClick={() => setshowCurrentPass(false)}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <AiFillEyeInvisible
                            size="14px"
                            color="#32325D"
                            onClick={() => setshowCurrentPass(true)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </InputRightElement>
                    )}
                  </InputGroup>
                </Flex>

                <Flex
                  alignItems={
                    newPass !== "" &&
                    (newPass.length < 8 ||
                      !/[A-Z]/.test(newPass) ||
                      !/[a-z]/.test(newPass) ||
                      !/\d/.test(newPass) ||
                      !/[^\w\s\_]/.test(newPass))
                      ? "flex-start"
                      : "center"
                  }
                  justifyContent="start"
                  my="1.5em"
                  flexDirection={["column", "row"]}
                  borderTop={["0px", "1px solid"]}
                  borderTopColor={{ md: "gray.300" }}
                  pt={"1.5em"}
                >
                  <Text
                    fontSize="0.9rem"
                    lineHeight="21px"
                    fontWeight="600"
                    color="#32325D"
                    width={{ base: "100%", md: "30%" }}
                  >
                    New Password <span style={{ color: "#dc3545" }}>*</span>
                  </Text>

                  <Box
                    flexGrow="1"
                    w={{ base: "100%", md: "70%" }}
                    mt={{ base: "10px", md: "0px" }}
                  >
                    <InputGroup alignItems="center" maxW="580px">
                      <Input
                        _disabled={{ opacity: "100%" }}
                        color="#32325D"
                        fontWeight="500"
                        fontSize="0.9rem"
                        flexGrow="1"
                        lineHeight="21px"
                        p="14px 32px"
                        border="0.6px solid rgba(50, 50, 93, 0.3)"
                        type={showNewPass ? "text" : "password"}
                        value={newPass}
                        onChange={(e: React.ChangeEvent<any>) =>
                          setNewPass(e.target.value)
                        }
                        _focus={{ ring: "0", borderColor: "#5C14CE" }}
                        isDisabled={!editPassword}
                        placeholder="Enter your new password"
                        _placeholder={{ color: "rgba(50, 50, 93, 0.40)" }}
                      />

                      {editPassword && (
                        <InputRightElement>
                          {showNewPass ? (
                            <AiFillEye
                              size="14px"
                              color="#32325D"
                              onClick={() => setshowNewPass(false)}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <AiFillEyeInvisible
                              size="14px"
                              color="#32325D"
                              onClick={() => setshowNewPass(true)}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </InputRightElement>
                      )}
                    </InputGroup>

                    {newPass !== "" &&
                      editPassword &&
                      (newPass.length < 8 ||
                        !/[A-Z]/.test(newPass) ||
                        !/[a-z]/.test(newPass) ||
                        !/\d/.test(newPass) ||
                        !/[^\w\s\_]/.test(newPass)) && (
                        <Box mt="0.75em" bgColor="white" maxW="580px">
                          <InputHints
                            showWhen={newPass !== ""}
                            title="Your password must be"
                            hints={[
                              {
                                passed: newPass.length > 7 ? true : false,
                                text: "at least 8 characters long",
                              },
                              {
                                passed: /[A-Z]/.test(newPass) ? true : false,
                                text: "at least one uppercase letter",
                              },
                              {
                                passed: /[a-z]/.test(newPass) ? true : false,
                                text: "at least one lowercase letter",
                              },
                              {
                                passed: /\d/.test(newPass) ? true : false,
                                text: "at least one number",
                              },
                              {
                                passed: /[^\w\s\_]/.test(newPass)
                                  ? true
                                  : false,
                                text: "at least one special character e.g. @#$%^&*()",
                              },
                            ]}
                          />
                        </Box>
                      )}
                  </Box>
                </Flex>

                <Flex
                  alignItems={
                    confPass !== "" && newPass !== confPass ? "" : "center"
                  }
                  justifyContent="start"
                  my="1.5em"
                  flexDirection={["column", "row"]}
                  borderTop={["0px", "1px solid"]}
                  borderTopColor={{ md: "gray.300" }}
                  py={[".5em", "1.5em"]}
                >
                  <Text
                    fontSize="0.9rem"
                    lineHeight="21px"
                    fontWeight="600"
                    color="#32325D"
                    width={{ base: "100%", md: "30%" }}
                  >
                    Confirm New Password{" "}
                    <span style={{ color: "#dc3545" }}>*</span>
                  </Text>

                  <Box
                    maxW="580px"
                    flexGrow="1"
                    w={{ base: "100%", md: "70%" }}
                    mt={{ base: "10px", md: "0px" }}
                  >
                    <InputGroup alignItems="center">
                      <Input
                        _disabled={{ opacity: "100%" }}
                        color="#32325D"
                        fontWeight="500"
                        fontSize="0.9rem"
                        p="14px 32px"
                        lineHeight="21px"
                        border="0.6px solid rgba(50, 50, 93, 0.3)"
                        type={showConfirmPass ? "text" : "password"}
                        value={confPass}
                        onChange={(e: React.ChangeEvent<any>) =>
                          setConfPass(e.target.value)
                        }
                        _focus={{ ring: "0", borderColor: "#5C14CE" }}
                        isDisabled={!editPassword}
                        placeholder="Confirm your new password"
                        _placeholder={{ color: "rgba(50, 50, 93, 0.40)" }}
                      />

                      {editPassword && (
                        <InputRightElement>
                          {showConfirmPass ? (
                            <AiFillEye
                              size="14px"
                              color="#32325D"
                              onClick={() => setshowConfirmPass(false)}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <AiFillEyeInvisible
                              size="14px"
                              color="#32325D"
                              onClick={() => setshowConfirmPass(true)}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </InputRightElement>
                      )}
                    </InputGroup>

                    {confPass !== "" &&
                      newPass !== confPass &&
                      editPassword && (
                        <Box mt="0.75em" bgColor="white" maxW="580px">
                          <InputHints
                            showWhen={confPass !== ""}
                            title="Passwords do not match"
                            hints={[
                              {
                                passed: newPass === confPass ? true : false,
                                text: "Your passwords must match",
                              },
                            ]}
                          />
                        </Box>
                      )}
                  </Box>
                </Flex>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Flex>
  );
};
