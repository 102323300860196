import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  css,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoPersonOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import LogRefund from "./LogRefund";
import { MdAssignmentReturn } from "react-icons/md";

type SalesTableProps = {
  sales: any[];
  sethighlighted: React.Dispatch<React.SetStateAction<any[]>>;
  highlighted: any[];
  newSaleAdded: boolean;
  setnewSaleAdded: React.Dispatch<React.SetStateAction<boolean>>;
  handleViewAff: (selectedAff: any) => void;
};

type CheckBoxTrackType = {
  [key: string | number]: boolean;
};

const titles = [
  "",
  "DATE CREATED",
  "ORGANIZATION",
  "SALES",
  "COMMISSION",
  "STATUS",
  "PRODUCT ID",
  "ACTION",
];

const SalesTable = ({
  sales,
  sethighlighted,
  highlighted,
  newSaleAdded,
  setnewSaleAdded,
  handleViewAff,
}: SalesTableProps) => {
  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  console.log("sales", sales);
  const [checkBoxTracker, setcheckBoxTracker] = useState<CheckBoxTrackType>({});
  const [saleToRefund, setsaleToRefund] = useState<null | number>(null);

  const navigate = useNavigate();
  const {
    isOpen: isOpenRefundForm,
    onOpen: onOpenRefundForm,
    onClose: onCloseRefundForm,
  } = useDisclosure();

  const handleSelect = (sale: any) => {
    console.log("sale", sale);
    const foundSale = highlighted.find((item: any) => item.id === sale?.id);

    if (foundSale) {
      sethighlighted(highlighted.filter((item: any) => item.id !== sale?.id));
      setcheckBoxTracker({ ...checkBoxTracker, [sale.id]: false });
    } else {
      sethighlighted([...highlighted, sale]);
      setcheckBoxTracker({ ...checkBoxTracker, [sale.id]: true });
    }

    setnewSaleAdded(false);
  };
  // const handleSelectAll = () => {
  //   const allSalesIds = sales.map((sale) => sale.id);

  //   // Check if all sales are already in highlighted
  //   const allInHighlighted = allSalesIds.every((id) => checkBoxTracker[id]);

  //   if (allInHighlighted) {
  //     // If all are already highlighted, remove all from highlighted
  //     sethighlighted([]);
  //     setcheckBoxTracker({});
  //   } else {
  //     // Add all sales to highlighted
  //     sethighlighted([...sales]);
  //     // Update checkBoxTracker to mark all as selected
  //     const updatedCheckBoxTracker = {};
  //     allSalesIds.forEach((id) => {
  //       // updatedCheckBoxTracker[id] = true;
  //       setcheckBoxTracker({ ...checkBoxTracker, [id]: true });
  //     });
  //     // setcheckBoxTracker(updatedCheckBoxTracker);
  //   }

  //   setnewSaleAdded(false);
  // };

  const handleSelectAll = () => {
    let obj = {};

    if (highlighted.length === sales.length && sales.length !== 0) {
      sethighlighted([]);
      setcheckBoxTracker({});
    } else {
      sethighlighted(sales);

      sales.forEach((aff: { [key: string]: any }) => {
        obj = { ...obj, [aff.id]: true };
      });
      setcheckBoxTracker(obj);
    }
  };

  const handleLogRefund = (id: number) => {
    setsaleToRefund(id);
    onOpenRefundForm();
  };

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      @keyframes blink {
        0% {
          background-color: #d1b8f7;
        }
        50% {
          background-color: #ffdcaa;
        }
        100% {
          background-color: #d1b8f7;
        }
      }
    `;

    document.head.appendChild(style);
  }, []);

  const checkBackgroundColor = (index: number) => {
    if (newSaleAdded && index === 0) return true;

    return false;
  };

  const getStatusColor = (status: string) => {
    if (status.toLowerCase() === "paid") return "#239654";

    if (status.toLowerCase() === "pending") return "#F3994A";

    return "#eb5757cd";
  };

  return (
    <Box
      css={
        isDesktop
          ? {}
          : css({
              "::-webkit-scrollbar": { display: "none" },
              "-webkit-overflow-scrolling": "touch",
            })
      }
      overflowX={{ base: "scroll", md: "visible" }}
      maxWidth="100%"
    >
      <Table mb="29px">
        <Thead>
          <Tr height="53px" bgColor="#F1F4FA">
            {titles.map((title, i) => {
              return (
                <Th
                  color="rgba(50, 50, 93, 0.8)"
                  textTransform="uppercase"
                  lineHeight="18px"
                  fontSize="12px"
                  fontWeight="500"
                  key={i}
                  whiteSpace={{ base: "nowrap", md: "normal" }}
                >
                  {title ? (
                    title
                  ) : (
                    <Checkbox
                      colorScheme="purple"
                      onChange={() => handleSelectAll()}
                      isChecked={
                        highlighted.length === sales.length &&
                        sales.length !== 0
                      }
                    />
                  )}
                </Th>
              );
            })}
          </Tr>
        </Thead>

        <Tbody>
          {sales.map((item: any, index: number) => {
            return (
              <Tr
                key={index}
                height="80px"
                width="100%"
                bgColor={checkBoxTracker[item.id] ? "purple.100" : ""}
                style={{
                  animation: checkBackgroundColor(index)
                    ? "blink 1s linear 2s"
                    : "",
                }}
              >
                <Td pl="20px" pr="10px">
                  <Checkbox
                    colorScheme="purple"
                    onChange={() => handleSelect(item)}
                    isChecked={checkBoxTracker[item.id] || false}
                  />
                </Td>

                <Td width={{ base: "max-content", md: "" }}>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    {item?.createdAt?.split("T")[0] || ""}
                  </Text>
                </Td>

                <Td maxWidth="120px">
                  <Tooltip label={item?.aff_org.companyname} fontSize="md">
                    <Text
                      color="#32325D"
                      lineHeight={{ base: "18px", md: "21px" }}
                      fontSize={{ base: "12px", md: "14px" }}
                      fontWeight="500"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {item?.aff_org.companyname || "-"}
                    </Text>
                  </Tooltip>
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    $ {item?.amount || 0}
                  </Text>
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    $ {item?.commission?.amount || 0}
                  </Text>
                </Td>

                <Td>
                  <Flex alignItems="center">
                    <Box
                      backgroundColor={getStatusColor(item?.status)}
                      height="12px"
                      width="12px"
                      borderRadius="50%"
                    ></Box>
                    <Box>
                      <Text
                        color="#32325D"
                        lineHeight="18px"
                        fontSize="12px"
                        fontWeight="500"
                        ml="6px"
                        textTransform="capitalize"
                      >
                        {item?.status}
                      </Text>
                    </Box>
                  </Flex>
                </Td>

                <Td>
                  <Tooltip label={item?.metadata.product_id} fontSize="md">
                    <Text
                      color="#32325D"
                      lineHeight={{ base: "18px", md: "21px" }}
                      fontSize={{ base: "12px", md: "14px" }}
                      fontWeight="500"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {item?.metadata.product_id || "-"}
                    </Text>
                  </Tooltip>
                </Td>

                <Td>
                  <Flex align="center">
                    <Tooltip label="View Profile" fontSize="md">
                      <Box
                        onClick={() => handleViewAff(item?.aff_orgId)}
                        cursor="pointer"
                      >
                        <IoPersonOutline fontSize={22} color="#7211D4" />
                      </Box>
                    </Tooltip>

                    {/* <Tooltip label="Log Refund" fontSize="md">
                      <Image
                        src="/assets/png/log-refund.png"
                        onClick={() => handleLogRefund(item.id || 0)}
                        cursor="pointer"
                        ml="18px"
                      />
                    </Tooltip> */}
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <LogRefund
        saleToRefund={saleToRefund}
        onClose={onCloseRefundForm}
        isOpen={isOpenRefundForm}
      />
    </Box>
  );
};

export default SalesTable;
