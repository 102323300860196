import {
  Heading,
  ModalBody,
  Text,
  UnorderedList,
  ListItem,
  Box,
  extendTheme,
} from "@chakra-ui/react";
import React from "react";
// import ReactHighlightSyntax from "react-highlight-syntax";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { getCurrentUserInstance } from "../../../../state/localstorage";
const PeddleDetails = () => {
  const user = getCurrentUserInstance();
  const api = `${user.organisation.apikey}`;
  const string1 =
    "To do that, go to Developer tools > Authentication and create a new API key from the form.";
  const string2 = `In Paddle, go to Developer tools > Alerts / Webhooks and make sure the following events have "Webhook" turned on:
    `;
  const string3 = `This part requires adding a small snippet of code to your website. The code is less than 13kb> and requires no further maintenance from your end once installed.
    `;
  const string4 = `Copy the tracking script and paste it at the bottom of and inside your website's <head> tag.
    `;

  const code = `<script async src='https://tracker-v2.vercel.app/tracker.min.js'\ndata-metricks='<your_api_key>' data-integration='paddle'></script>`;
  const code1 = `<script async src='https://tracker-v2.vercel.app/tracker.min.js'\ndata-metricks='<your_api_key>' data-integration='paddle'></script>`;
  const code2 = `<script src="https://cdn.paddle.com/paddle/paddle.js"></script>\n<script type="text/javascript">\nPaddle.Setup({\nvendor: 123456789,\npassthrough: \`{"metrAffiliate": \${metricks.getReferralCode()}}\`, });\n</script>`;
  return (
    <ModalBody py="1em" h="600px">
      <Box
        height={{ base: "500", lg: "700px" }}
        overflowY="auto"
        px={{ base: "1px", lg: "4em" }}
      >
        <Heading size="sm" color="#32325D" mb="20px">
          Adding your Paddle API credentials to metricks
        </Heading>
        <hr />
        <Box width="100%" mt="20px">
          <Heading size="sm" color="#32325D" mb="20px">
            metricks API Key
          </Heading>
          {/* <ReactHighlightSyntax
            language={"JavaScript"}
            theme={"Base16AtelierCaveLight"}
            copy={true}
            copyBtnTheme={"Dark"}
          >
        </ReactHighlightSyntax> */}
          <SyntaxHighlighter
            language="javascript"
            style={docco}
            customStyle={{
              padding: "15px",
            }}
          >
            {api}
          </SyntaxHighlighter>
        </Box>

        <Text mt="20px">
          To connect metricks to your app, you must create a new API key in
          Paddle and paste it into your metricks Paddle Integration settings.
        </Text>
        <Text mt="20px">{string1}</Text>
        <Text mt="20px">
          Then click "Reveal Auth Code" for the API key you created and copy the
          code. Paste this and the vendor ID at the top of the page into
          metricks Paddle Settings Page.
        </Text>

        <Heading size="sm" color="#32325D" mb="20px" mt="20px">
          Set up webhooks in Paddle.
        </Heading>
        <hr />
        <Text mt="20px">
          For metricks to read the transaction information from your
          Paddle-powered subscriptions, you need to set up webhooks.
        </Text>
        <Text mt="20px">{string2}</Text>
        <UnorderedList>
          <ListItem listStyleType="none" mt="20px">
            <Box
              as="span"
              display="inline-block"
              w="8px"
              h="8px"
              bg="#7211D4"
              borderRadius="50%"
              mr="6px"
              position="relative"
            />
            Subscription Created
          </ListItem>
          <ListItem listStyleType="none" mt="20px">
            <Box
              as="span"
              display="inline-block"
              w="8px"
              h="8px"
              bg="#7211D4"
              borderRadius="50%"
              mr="6px"
              position="relative"
            />
            Subscription Updated
          </ListItem>
          <ListItem listStyleType="none" mt="20px">
            <Box
              as="span"
              display="inline-block"
              w="8px"
              h="8px"
              bg="#7211D4"
              borderRadius="50%"
              mr="6px"
              position="relative"
            />
            Subscription Cancelled
          </ListItem>
          <ListItem listStyleType="none" mt="20px">
            <Box
              as="span"
              display="inline-block"
              w="8px"
              h="8px"
              bg="#7211D4"
              borderRadius="50%"
              mr="6px"
              position="relative"
            />
            Subscription Payment Success
          </ListItem>
          <ListItem listStyleType="none" mt="20px">
            <Box
              as="span"
              display="inline-block"
              w="8px"
              h="8px"
              bg="#7211D4"
              borderRadius="50%"
              mr="6px"
              position="relative"
            />
            Subscription Payment Refunded
          </ListItem>
        </UnorderedList>
        <Text mt="20px">
          Make sure you use the metricks webhook URL from your settings page and
          paste it into the "URL for receiving webhook alerts" field in the same
          Alerts / Webhooks page in Paddle, and click “save”.
        </Text>
        <Heading size="sm" color="#32325D" mb="20px" mt="20px">
          Adding the metricks Script to your website
        </Heading>
        <hr />
        <Text mt="20px">{string3}</Text>
        <Text mt="20px">{string4}</Text>
        {/* <ReactHighlightSyntax
          language={"JavaScript"}
          theme={"Base16AtelierCaveLight"}
          copy={true}
          copyBtnTheme={"Dark"}
        >
      </ReactHighlightSyntax> */}
        <SyntaxHighlighter
          language="javascript"
          style={docco}
          customStyle={{
            padding: "15px",
          }}
        >
          {code}
        </SyntaxHighlighter>
        <Heading size="sm" color="#32325D" mb="20px" mt="20px">
          Pass the Affiliate Id to Paddle
        </Heading>
        <hr />
        <Text mt="20px">
          You will need to pass the Affiliate Id to your Paddle JS library.
          Affiliate Id is in this format yourdomain.com/?ref=AffiliateId.
        </Text>
        <Text mt="20px">{string4}</Text>
        {/* <ReactHighlightSyntax
          language={"JavaScript"}
          theme={"Base16AtelierCaveLight"}
          copy={true}
          copyBtnTheme={"Dark"}
        >
      </ReactHighlightSyntax> */}
        <SyntaxHighlighter
          language="javascript"
          style={docco}
          customStyle={{
            padding: "15px",
          }}
        >
          {code1}
        </SyntaxHighlighter>
        <Text mt="20px">The setup will look like this.</Text>
        {/* <ReactHighlightSyntax
          language={"JavaScript"}
          theme={"Base16AtelierCaveLight"}
          copy={true}
          copyBtnTheme={"Dark"}
        >
      </ReactHighlightSyntax> */}
        <SyntaxHighlighter
          language="javascript"
          style={docco}
          customStyle={{
            padding: "15px",
          }}
        >
          {code2}
        </SyntaxHighlighter>
      </Box>
    </ModalBody>
  );
};

export default PeddleDetails;
