import React, { useEffect, useState } from "react";
import {
  Box,
  Image,
  Text,
  Stack,
  Grid,
  GridItem,
  Button,
  useDisclosure,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import useUserToken from "../../../hooks/useUserToken";
import RankedCampaings from "./ranked-campains-section";
import { MdArrowForward } from "react-icons/md";
import { useNavigate, Link } from "react-router-dom";
import { useCurrentAffiliateOrg } from "../../../hooks/useCurrentOrg";
import { gsap, Power2 } from "gsap";
import { FaRegImage } from "react-icons/fa";

const ExploreAffiliate = () => {
  const navigate = useNavigate();
  const token = useUserToken("affiliate");
  const [offers, setOffers] = useState<any[]>([]);
  const [loading, setloading] = useState<boolean>(true);

  const [_, profileIsComplete] = useCurrentAffiliateOrg();

  useEffect(() => {
    getOffers();
  }, []);

  useEffect(() => {
    gsap.fromTo(
      ".loading",
      {
        opacity: 0,
        ease: Power2.easeInOut,
        scale: 0,
        y: 80,
      },
      {
        opacity: 1,
        duration: 1.5,
        scale: 1,
        ease: Power2.easeInOut,
        repeat: -1,
        y: 0,
        yoyo: true,
      }
    );
  }, []);

  const checkToken = async () => {
    if (!token) return;

    await fetch(process.env.REACT_APP_API_ENDPOINT + `/auth/valid-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data?.valid) {
          return;
        } else {
          window.localStorage.clear();
          sessionStorage.removeItem("currentUserType");
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          navigate("/login");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (token) {
      checkToken();
    }
  }, [token]);

  const {
    isOpen: onModalIsOpen,
    onOpen: onModalOpen,
    onClose,
  } = useDisclosure();

  const getOffers = () => {
    setloading(true);

    let bearerAuth = token;
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "explore/offers/recommended-campaigns",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("recommended", data);
        if (data.success) {
          setOffers(data.data.recommendedCampaigns);
        }
        setloading(false);
      })
      .catch((err) => {
        console.log("here");
        setloading(false);
      });
  };

  const handleView = (item: any) => {
    if (!profileIsComplete) return onModalOpen();

    let bearerAuth = token;

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `promote-campaign/affiliate/campaigns?campaignId=${item.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data.campaigns.length > 0) {
          if (
            data.data.campaigns[0]?.status === "approved" ||
            data.data.campaigns[0]?.status === "suspended"
          ) {
            return navigate(
              `/affiliate-campaigns/view/${item.id}?ref=${data.data.campaigns[0]?.affiliateLink}`
            );
            // return navigate(
            //   `/affiliate-campaigns?id=${item.id}&status=approved`
            // );
          } else {
            return navigate(`/affiliate-explore/view/${item.id}`);
          }
        }
      })
      .catch((err) => {});

    return navigate(`/affiliate-explore/view/${item.id}`);
  };

  if (loading)
    return (
      <Box display="flex" justifyContent="center" h="80vh" alignItems="center">
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Image className="loading" src="/assets/logo.png" height="80px" />
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="70px"
            color="#000000"
            mt="-15px"
          >
            Loading....
          </Text>
        </Box>
      </Box>
    );

  return (
    <Stack padding="36px 23px" bgColor="#F1F4FA">
      <Box
        padding={{ base: "16px 0px", md: "38px 40px" }}
        bgColor={{ base: "transparent", md: "white" }}
      >
        <RankedCampaings onModalOpen={onModalOpen} />

        <Box>
          <Text
            fontSize={{ base: "14px", md: "12px" }}
            textTransform="uppercase"
            lineHeight="18px"
            fontWeight={{ base: "500", md: "500" }}
            color="#32325D"
            opacity={{ base: "1", md: "0.8px" }}
            mb={{ base: "24px", md: "20px" }}
          >
            Recommended
          </Text>
          {offers && offers.length ? (
            <Grid
              templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
              gap={{ base: "16px", md: "22px" }}
              width="100%"
            >
              {offers.slice(0, 3).map((item: any) => {
                return (
                  <GridItem
                    background="#FFFFFF"
                    boxShadow=" 0px 0px 20px rgba(0, 0, 0, 0.08)"
                    borderRadius="8px"
                    padding="15px"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      filter="auto"
                      blur={profileIsComplete ? "0px" : "0px"}
                    >
                      {item?.banner_image ? (
                        <Image
                          height="35px"
                          width="35px"
                          borderRadius="50%"
                          src={item?.banner_image}
                        />
                      ) : (
                        <FaRegImage color="#b8b0ef" size={20} />
                      )}
                      <Text
                        fontWeight="500"
                        fontSize="14px"
                        color="#32325D"
                        lineHeight="21px"
                        ml="15px"
                        textTransform="capitalize"
                      >
                        {item?.campaign_name}
                      </Text>
                    </Box>

                    <Image
                      height="201px"
                      mt="19px"
                      mb="15px"
                      width="100%"
                      objectFit="cover"
                      borderRadius="6px"
                      src={
                        item.background_image
                          ? item.background_image
                          : `https://metricksassets.s3.us-east-2.amazonaws.com/development/default/760d200f-31f2-4e6a-aa21-cad8038f4eae.png`
                      }
                    />

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      borderBottom=" 1px solid rgba(50, 50, 93, 0.3)"
                      pb="13px"
                      mb="8px"
                    >
                      <Box
                        width="50%"
                        borderRight=" 1px solid rgba(50, 50, 93, 0.3)"
                      >
                        <Text
                          fontWeight="500"
                          fontSize="14px"
                          color="#32325D"
                          lineHeight="21px"
                          textTransform="capitalize"
                        >
                          {item.product_type === "saas"
                            ? item.product_type.slice(0, 3) +
                              item.product_type.slice(3).toUpperCase()
                            : item.product_type}
                        </Text>
                        <Text
                          fontWeight="400"
                          fontSize="11px"
                          color="rgba(50, 50, 93, 0.6)"
                          lineHeight="16px"
                        >
                          Category
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          fontWeight="500"
                          fontSize="14px"
                          color="#32325D"
                          lineHeight="21px"
                        >
                          {item.commission_type === "percentage" ? "%" : "$"}
                          {`${
                            item.commission_type === "percentage"
                              ? item.commission_percentage || 0
                              : item.commission_amount || 0
                          }`}
                        </Text>
                        <Text
                          fontWeight="400"
                          fontSize="11px"
                          color="rgba(50, 50, 93, 0.6)"
                          lineHeight="16px"
                        >
                          Commission
                        </Text>
                      </Box>
                    </Box>

                    <Button
                      bgColor="#F0F3F9"
                      border=" 0.5px solid #E5E4E4"
                      borderRadius="8px"
                      width="100%"
                      onClick={() => handleView(item)}
                    >
                      <Text
                        fontWeight="500"
                        fontSize="16px"
                        lineHeight="21px"
                        color=" #7211D4"
                        mr="7px"
                      >
                        View
                      </Text>

                      <MdArrowForward color="#7211D4" size={16} />
                    </Button>
                  </GridItem>
                );
              })}
            </Grid>
          ) : (
            <Box
              w="100%"
              height="50vh"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              p={{ base: "0px 16px", md: "0px" }}
              mb="21px"
            >
              <Image
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
                height="250px"
                objectFit="contain"
                mb="16px"
              />

              <Text
                fontWeight="500"
                fontSize="14px"
                lineHeight="21px"
                color="#32325D"
                textAlign="center"
              >
                No campaigns available in offers yet.
              </Text>
            </Box>
          )}
        </Box>

        <Box
          mt="67px"
          display={offers.length ? "flex" : "none"}
          justifyContent="center"
        >
          <Link to="/affiliate-explore/explore">
            <Text
              fontFamily="500"
              fontSize="1rem"
              lineHeight="24px"
              textDecoration="underline"
              color="#7211D4"
            >
              View all campaigns
            </Text>
          </Link>
        </Box>

        <Modal onClose={onClose} size="lg" isCentered isOpen={onModalIsOpen}>
          <ModalOverlay />
          <ModalContent borderRadius="10px">
            <ModalHeader display="flex" justifyContent="right">
              <Image
                onClick={onClose}
                cursor="pointer"
                ml="7px"
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/3b803641-20b6-4e47-b554-8336ebd3ec5b.svg"
              />
            </ModalHeader>

            <ModalBody
              paddingBottom="26px"
              borderBottom="1px solid rgba(50, 50, 93, 0.3)"
            >
              <Text
                fontWeight="500"
                fontSize="14px"
                lineHeight="30px"
                color=" rgba(50, 50, 93, 0.8)"
                textAlign="center"
              >
                Incomplete profile, complete your organization profile to find
                new campaigns here
              </Text>
            </ModalBody>

            <ModalFooter display="flex" justifyContent="left">
              <Button
                ml="15px"
                bgColor=" #F0F5FF"
                color="#5C14CE"
                borderRadius="8px"
                onClick={onClose}
              >
                Proceed
                <Image
                  cursor="pointer"
                  ml="10px"
                  mt="1px"
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/37d9e26a-0ec4-462d-98ac-8fca88493c0b.svg"
                />
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Stack>
  );
};

export default ExploreAffiliate;
