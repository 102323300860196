import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Image,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { BsCheckSquareFill } from "react-icons/bs";
import { IoArrowBackSharp, IoArrowForwardSharp } from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import {
  getCurrentUserInstance,
  setLocalUserInstance,
} from "../../../state/localstorage";
import OrganizationSettingsFooter from "../components/OrganizationSettingsFooter";
// testing
const MarketingAssets = ({
  setorgDetails,
  orgDetails,
  setrefreshData,
  getOrgDetails,
  currentStep,
  setCurrentStep,
}: {
  setorgDetails: any;
  orgDetails: any;
  setrefreshData: React.Dispatch<React.SetStateAction<number>>;
  getOrgDetails: any;
  currentStep: any;
  setCurrentStep: any;
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [orgType, setOrgType] = useState(false);
  interface DataItem {
    defaultIcon: string;
    coloredIcon: string;
    title: string;
    selected: boolean;
  }
  const details: DataItem[] = [
    {
      defaultIcon:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/1cf27151-86ff-4659-a875-9cd2ff216d76.svg",
      coloredIcon:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/c72cf922-8c5a-4761-bc92-852f7c0387f0.svg",
      title: "Promotional pictures & videos",
      selected: false,
    },
    {
      defaultIcon:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/bbe2ed45-5c45-4612-a2dc-6bb231afb066.svg",
      coloredIcon:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/25a3a426-1fe5-4002-8a3f-6b30990d5d5b.svg",
      title: "Testimonials & reviews",
      selected: false,
    },
    {
      defaultIcon:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/d3426775-561d-4798-83f9-f565efa061d7.svg",
      coloredIcon:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/e77855c3-94d4-4b3d-870c-d3736ad77caf.svg",
      title: "Brand ambassadors",
      selected: false,
    },
    {
      defaultIcon:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/511431b7-955e-4f2f-bde8-8e553e49f429.svg",
      coloredIcon:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/7e4631eb-e0e8-4add-a903-cc231c69ca31.svg",
      title: "Celebrity endorsements",
      selected: false,
    },
    {
      defaultIcon:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/d079624e-1b7a-470c-89a7-e783c514496f.svg",
      coloredIcon:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/6641c769-1f14-440d-ade1-3f5768d390bb.svg",
      title: "Other",
      selected: false,
    },
  ];
  const [newUpdate, setnewUpdate] = useState(false);
  useEffect(() => {
    details.forEach((item: any) => {
      if (localUser.organisation.assetType.includes(item.title)) {
        item.selected = true;
      }
      // @ts-ignore
      setData(details);
    }, []);

    // Now the selected property in data will be updated
    // console.log("here", data);
    if (searchParams.get("type") !== null) {
      setOrgType(searchParams.get("type") ? true : false);
    }
  }, [searchParams, orgType]);
  const [cookies] = useCookies(["user_id", "access_token"]);

  const localUser = getCurrentUserInstance();
  // console.log(localUser.organisation);
  const [count, setcount] = useState(2);

  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (count > 1) {
      setnewUpdate(true);
      sessionStorage.setItem("advertizerSettingsChange", "new update");
    }
    setcount(count + 1);
  }, [data]);
  const [saveAndExit, setSaveAndExit] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateData = {
    assetType: data
      .filter((dt: any) => dt.selected)
      .map((data: any) => data.title),
  };
  const validator = () => {
    if (data.filter((dt: any) => dt.selected).length < 1) {
      return { isValid: false, error: "An asset has to be selected" };
    }
    return { isValid: true, error: "" };
  };
  return (
    <Box>
      <ToastContainer />
      {/* <Heading fontSize="1rem" color="#32325D" fontWeight={"600"} pb="34px">
        {currentStep}
      </Heading> */}
      <Heading fontSize="1em" mb="1em">
        What marketing assets do you have?
        <span style={{ color: "red", marginLeft: 5 }}>*</span>
      </Heading>

      <Flex flexWrap={["wrap", "wrap", "wrap"]} rowGap="1em" columnGap="1em">
        {data.map((dt: any, i: any) => (
          <Box
            key={i}
            _hover={{ bgColor: "#F5F8FF" }}
            border="1.5px solid"
            borderColor={dt.selected ? "#7211D4" : "#D1D1D1"}
            bgColor={dt.selected ? "#F5F8FF" : "#ffffff"}
            padding="16px 16px 10px 16px"
            borderRadius="0.4em"
            width={["100%", "45%", "48%", "260px"]}
            minHeight={["100px", "148px", "160px"]}
            display="flex"
            alignItems="flex-start"
            flexDirection={["row-reverse", "column"]}
            justifyContent="space-between"
            cursor="pointer"
            onClick={() => {
              const newState = data.map((dt: any) => {
                if (data[i] == dt) {
                  return {
                    ...dt,
                    selected: !dt.selected,
                  };
                } else {
                  return dt;
                }
              });
              // @ts-ignore
              setData(newState);
              setorgDetails({ ...orgDetails, assetType: newState });
            }}
          >
            {!dt.selected ? (
              <Box
                width="1em"
                height="1em"
                border="1.5px solid #A4B3C3"
                borderRadius="4px"
                cursor="pointer"
                onClick={() => {
                  const newState = data.map((dt: any) => {
                    if (data[i] == dt) {
                      return { ...dt, selected: !dt.selected };
                    } else {
                      return dt;
                    }
                  });
                  // @ts-ignore
                  setData(newState);
                }}
              ></Box>
            ) : (
              <BsCheckSquareFill
                color="#5C15CE"
                cursor="pointer"
                onClick={() => {
                  const newState = data.map((dt: any) => {
                    if (data[i] == dt) {
                      return { ...dt, selected: !dt.selected };
                    } else {
                      return dt;
                    }
                  });
                  // @ts-ignore
                  setData(newState);
                }}
              />
            )}

            <Box my=".3em">
              <Image src={dt.selected ? dt.coloredIcon : dt.defaultIcon} />
              <Text
                fontWeight="400"
                fontSize="14px"
                lineHeight="26px"
                color={dt.selected ? "#7211D4" : "#000000"}
                mt=".2em"
              >
                {dt.title}
              </Text>
            </Box>
          </Box>
        ))}
      </Flex>

      <OrganizationSettingsFooter
        hasUpdate={newUpdate}
        sethasUpdate={setnewUpdate}
        dataToUpdate={updateData}
        setRefreshData={setrefreshData}
        validator={validator}
        getOrgDetails={getOrgDetails}
      />
    </Box>
  );
};

export default MarketingAssets;
