import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Stack,
  Text,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Center,
  Image,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  IoAddSharp,
  IoArrowBackSharp,
  IoArrowForwardSharp,
} from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseButton } from "../../button";
import { apiReqHandler } from "../../../helper/apiReqHandler";
import { endpoints } from "../../../utils/endpoints";
import { AiFillCloseCircle } from "react-icons/ai";
import { getCookie } from "cookies-next";
import { useRecoilState } from "recoil";
import { campaignState } from "../../../state/campaign";
import { CampaignHeader } from "./Header";
import { Grid } from "semantic-ui-react";
import { RiVisaLine } from "react-icons/ri";
import { BsCircle, BsFillCheckCircleFill } from "react-icons/bs";
import { MasterCard } from "../../../assets";
import { toast, ToastContainer } from "react-toastify";
import { useCookies } from "react-cookie";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";

// import { userState } from "../../../state/user";

const CARD_ELEMENT_OPTIONS = {
  showIcon: true,
  style: {
    base: {
      // iconColor: "#000",
      color: "#282838",
      border: "1px solid #000",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",

      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const ProductDetails = () => {
  const [campaignStateObj, setCampaignStateObj] = useRecoilState(campaignState);
  // console.log(
  //   "the value",
  //   campaignStateObj?.productIds !== undefined
  //     ? campaignStateObj?.productIds
  //     : []
  // );
  const [cookies] = useCookies(["user_id", "access_token"]);

  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get("campaign");
  // const productType = searchParams.get("product");
  const [editPage] = useState(
    window.location.pathname.includes("/edit-campaign")
  );
  const elements = useElements();
  const stripe = useStripe();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [productType, setproductType] = useState("");
  const [isPaymentOpen, setPaymentClose] = useState(false);
  console.log("access token", getCookie("access_token"));

  const [productIds, setProductIds] = useState([]);
  const [productIdsActive, setProductIdsActive] = useState([]);
  console.log("productIds", campaignStateObj);
  const [productId, setProductId] = useState("");
  const [productIdLink, setProductIdLink] = useState("");
  const [trialPeriod, setTrialPeriod] = useState(
    campaignStateObj.trialPeriod ? campaignStateObj.trialPeriod : 0
  );
  const [subInterval, setSubInterval] = useState(
    campaignStateObj.subInterval ? campaignStateObj.subInterval : "Daily"
  );
  const [refCommissionSupport, setRefCommissionSupport] = useState(
    campaignStateObj.refCommissionSupport
      ? campaignStateObj.refCommissionSupport
      : "ON"
  );
  const [payoutFreq, setPayoutFreq] = useState("15 days");

  const toastt = useToast();
  const [loader, setLoader] = useState(false);
  const [saveAndExit, setSaveAndExit] = useState(false);
  const navigate = useNavigate();

  const [campaignName, setCampaignName] = useState("");
  const [lockCampaign, setLockCampaign] = useState(false);

  const listOfCards: { id: number; cardType: string }[] = [
    { id: 0, cardType: "master" },
    { id: 1, cardType: "Visa" },
  ];
  const [cardList, setCardList] = useState([]);
  const [status, setstatus] = useState("");

  // console.log("cardlist num 1", cardList[0].id);
  //   @ts-ignore
  const [selectedCard, setSelectedCard] = useState(cardList[0]);

  const [addNewCard, setAddNewCard] = useState(false);
  const [cardId, setCardID] = useState("");

  // const getCampaignData = async () => {
  //   // @ts-ignore
  //   const { campaignData } = await fetchCampaign({ campaignId });
  //   // questionConstruct(campaignData.customQuestion);

  //   // console.log("data question", campaignData);
  // };

  const fetchCampaign = async (id: string) => {
    const { res, error } = await apiReqHandler({
      endPoint: endpoints.campaign.FETCH_ONE(id),
      method: "GET",
      //   @ts-ignore
      bearerAuth: getCookie("access_token"),
    });

    const campaignData = res?.data?.data?.campaign;
    // console.log("fetcheddata", campaignData.status);
    setstatus(campaignData?.status);
    // console.log("checkinggggg", campaignData.product_type);
    setproductType(campaignData?.product_type);
    // setProductIds(campaignData.product_id);

    if (campaignData?.status?.toLowerCase() === "active") {
      setLockCampaign(true);
    }
    //   @ts-ignore
    setCampaignName(campaignData?.campaign_name);

    if (editPage) {
      setProductIdsActive(
        campaignData?.product_id === null ? [] : campaignData?.product_id
      );
      setTrialPeriod(
        campaignData?.trial_period ? campaignData?.trial_period : 0
      );
      setSubInterval(
        campaignData?.interval
          ? campaignData?.interval[0].toUpperCase() +
              campaignData?.interval?.slice(1)
          : "Daily"
      );

      setRefCommissionSupport(campaignData?.ref_comm_support ? "ON" : "OFF");
    }
  };
  useEffect(() => {
    // getCampaignData();
    // @ts-ignore
    fetchCampaign(campaignId);
    // @ts-ignore
    // fetchAttachedCard(campaignId);
  }, [campaignId]);

  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    // fetchCards();
    function handleBeforeUnload(event: any) {
      if (!formSubmitted) {
        event.preventDefault();
        event.returnValue = "";
      }
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formSubmitted]);

  const togglePaymentModal = () => {};

  const submitHandler = async (e: any) => {
    e.preventDefault();
    // addNewCard && (await addCardHandlerStripe(e));
    // await attachCardToCampaigne(campaignStateObj.campaignId);
    setPaymentClose(false);
    setFormSubmitted(true);

    const payload = {
      products: [...productIds, ...productIdsActive],
      trial_period: Number(trialPeriod),
      interval: subInterval.toLowerCase(),
      ref_comm_support: refCommissionSupport === "ON" ? true : false,
      // payout_terms: `NET${payoutFreq.split(" ")[0]}`,
    };

    setLoader(true);

    const { res, error } = await apiReqHandler({
      //   @ts-ignore
      endPoint: endpoints.campaign.CREATE.STEP_FIVE(campaignId),
      method: "PUT",
      payload,
      //   @ts-ignore
      bearerAuth: getCookie("access_token"),
    });
    setLoader(false);

    // console.log({ res, error });
    if (error) {
      console.log("ERR", error);
      return toastt({
        title: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }

    if (res) onOpen();
  };
  const attachCardToCampaigne = async (id: any) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + `/campaign/${id}/attach-card`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.access_token}`,
      },
      body: JSON.stringify({
        card_id: cardId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.data);
        if (data.success) {
          toastt({
            title: "Card attached successfully",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top-right",
          });

          return;
        }
      });
  };

  const addProdIdHandler = () => {
    // console.log([...productIds, { id: productId, link: productIdLink }]);
    //   @ts-ignore
    setProductIds([...productIds, { id: productId, link: productIdLink }]);
    setProductId("");
    setProductIdLink("");
  };

  const removeProdIdHandler = (id: string) => {
    setProductIds(() => productIds.filter((pid: any) => pid.id !== id));
  };
  const removeProdIdHandlerActive = (id: string) => {
    setProductIdsActive(() =>
      productIdsActive.filter((pid: any) => pid.id !== id)
    );
  };

  const navHandler = () => {
    setCampaignStateObj({});
    navigate(`/campaigns?campaign=${campaignId}`);
  };

  const backToPreviousHandler = () => {
    setCampaignStateObj({
      ...campaignStateObj,
      productIds,
      trialPeriod,
      subInterval,
      refCommissionSupport,
    });
    navigate(
      editPage
        ? `/campaigns/edit-campaign/questions?product=${productType}&campaign=${campaignId}`
        : `/campaigns/questions?product=${productType}&campaign=${campaignId}`
    );
  };

  const [activateLoader, setActivateLoader] = useState(false);

  const activateCampaignHandler = async (status: "active" | "draft") => {
    setActivateLoader(true);

    const { res, error } = await apiReqHandler({
      //   @ts-ignore
      endPoint: endpoints.campaign.UPDATE_ONE(campaignId),
      method: "PUT",
      payload: { status },
      //   @ts-ignore
      bearerAuth: getCookie("access_token"),
    });
    setActivateLoader(false);

    if (error) {
      console.log("ERR", error);
      return toastt({
        title: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }

    if (res) navHandler();
  };

  const saveAndExitHandler = () => {
    setCampaignStateObj({});
    navigate("/campaigns");
  };

  const addCardHandlerStripe = async (event: React.ChangeEvent<any>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // setLoading(true);
    // @ts-ignore
    const card = elements.getElement(CardNumberElement);
    // @ts-ignore
    const result = await stripe.createToken(card);
    // @ts-ignore
    // console.log(result.token.id);
    if (result.error) {
      toast.error(result.error.message);
      // setLoading(false);
    } else {
      // Send the token to your server.
      console.log(result.token);

      const payload = {
        source: result.token.id,
      };

      fetch(process.env.REACT_APP_API_ENDPOINT + "/billing/add-card", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.access_token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((data) => {
          // setLoading(false);
          console.log(data);
          if (!data.success) {
            toastt({
              title: data.message,
              status: "warning",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });

            return;
          }
          fetchCards();
          // toast.success("Card added successfully");

          setAddNewCard(false);
          toastt({
            title: "Card added successfully",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top-right",
          });
          // const user = getCurrentUserInstance();
          // user.organisation.signup_step = 6;
          // setLocalUserInstance(user);
          // navigate("/dashboard");
        })
        .catch((err) => {
          // setLoading(false);
          console.log(err);
        });
    }
  };

  const fetchCards = async () => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "/billing/cards", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.access_token}`,
      },
      // body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("cards", data.data);

        setCardList(data.data.data);

        setSelectedCard(data.data.data[0]);
      });
  };

  const fetchAttachedCard = async (id: string) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + `/campaign/${id}/fetch-card`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.access_token}`,
      },
      // body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data.success) {
          console.log("cards attached", data.data.card);
          setSelectedCard(data.data.card);
        }

        // setSelectedCard(data.data.data[0]);
      });
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent
          borderRadius="1em"
          p=".8em"
          height="235px"
          mx={{ base: ".6em", md: "auto" }}
          marginTop={{ base: "15em", md: "18em" }}
        >
          <Box
            width=".8em"
            height="235px"
            position="absolute"
            left="0"
            bgGradient="linear(360deg, #7211D4 -1.38%, #FA9445 98.36%)"
            top="0"
            borderLeftRadius="1em"
          ></Box>
          {/* <ModalHeader mt="1em">Modal Title</ModalHeader> */}
          <ModalCloseButton
            bgColor="gray.100"
            borderRadius="1.5em"
            p="1.5em"
            top="1.5em"
            right="1.8em"
            fontSize="1em"
            onClick={navHandler}
          />
          {editPage ? (
            <ModalBody mt="2.5em" mr="1em">
              <Text
                fontSize={{ base: "14px", md: "1.3em" }}
                fontWeight="medium"
                mb={{ base: "1.6em", md: "2em" }}
              >
                Do you want to save changes you have made to this campaign ?
              </Text>
              <Box
                width="90%"
                height="1px"
                mx="auto"
                bgColor="gray.200"
                mb=".6em"
              ></Box>
            </ModalBody>
          ) : (
            <ModalBody mt="2.5em" mr="1em">
              <Text
                fontSize={{ base: "14px", md: "1.3em" }}
                fontWeight="medium"
                mb={{ base: "1.6em", md: "2em" }}
              >
                You have {editPage ? "updated" : "created"}{" "}
                <span style={{ color: "#8663D7" }}>{campaignName}</span>! Do you
                want to take it live or save it as a draft?
              </Text>
              <Box
                width="90%"
                height="1px"
                mx="auto"
                bgColor="gray.200"
                mb=".6em"
              ></Box>
            </ModalBody>
          )}

          <ModalFooter>
            {
              <Button
                variant="ghost"
                mr={3}
                onClick={() =>
                  status === "active" && editPage === true
                    ? navHandler()
                    : activateCampaignHandler("draft")
                }
              >
                {status === "active" ? "Cancel" : "Save as draft"}
              </Button>
            }
            <Button
              bgColor="#F0F5FF"
              color="#7211D4"
              rightIcon={<IoArrowForwardSharp />}
              onClick={() => activateCampaignHandler("active")}
              isLoading={activateLoader}
            >
              {editPage ? "Update" : "Take it Live!"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isPaymentOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        {addNewCard ? (
          <ModalContent
            borderRadius="1em"
            p=".5em"
            // height="235px"
            // mx={{ base: ".6em", md: "auto" }}
            marginTop={{ base: "15em", md: "6em" }}
          >
            <ModalHeader mt="1em">Add new payment method</ModalHeader>

            <ModalBody>
              <Box
                height={{ base: "200px", lg: "250px" }}
                // overflowY="auto"
                // px={{ base: "1px", lg: "4em" }}
                w="100%"
                // backgroundColor="red"
              >
                <Box
                  // my="2em"
                  // py={["3em", "5em"]}
                  width="100%"
                  borderColor="#000"
                  borderRadius="10px"
                >
                  <form onSubmit={(e) => console.log(e)}>
                    <label style={{ fontWeight: "500" }}>
                      Card number
                      <Box
                        w="full"
                        borderColor="#c4c4c4"
                        borderRadius="5px"
                        borderWidth="1px"
                        p={4}
                      >
                        <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                      </Box>
                    </label>
                    <Box my="1em">
                      <label style={{ fontWeight: "500" }}>
                        Expiration month and year
                        <Box
                          w="full"
                          borderColor="#c4c4c4"
                          borderRadius="5px"
                          borderWidth="1px"
                          p={4}
                        >
                          <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                        </Box>
                      </label>
                    </Box>

                    <label style={{ fontWeight: "500" }}>
                      CVC
                      <Box
                        w="full"
                        borderColor="#c4c4c4"
                        borderRadius="5px"
                        borderWidth="1px"
                        p={4}
                      >
                        <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                      </Box>
                    </label>

                    {/* <Flex align="center" py={{ base: "1em", lg: "2em" }}>
                      <Button variant="ghost" onClick={addCardHandlerStripe}>
                        <Flex
                          height={{ base: "50px", lg: "100px" }}
                          overflowY="auto"
                          align="center"
                          gap={5}
                        >
                          <IoAddSharp size="20px" />
                          <Text>Add new card</Text>
                        </Flex>
                      </Button>
                    </Flex> */}
                  </form>
                </Box>
              </Box>
              <Flex
                align="center"
                p={{ base: "1em", lg: "2em" }}
                onClick={() => {
                  setAddNewCard(true);
                }}
              ></Flex>

              <Box
                width="90%"
                height="1px"
                mx="auto"
                bgColor="gray.200"
                mb=".6em"
              ></Box>
            </ModalBody>

            <ModalFooter>
              <Button
                variant="ghost"
                mr={3}
                onClick={() => setAddNewCard(false)}
                // onClick={
                //   editPage ? () => activateCampaignHandler("draft") : navHandler
                // }
              >
                Cancel
              </Button>
              <Button
                bgColor="#F0F5FF"
                color="#7211D4"
                rightIcon={<IoArrowForwardSharp />}
                onClick={submitHandler}
                isLoading={activateLoader}
              >
                Submit
              </Button>
            </ModalFooter>
          </ModalContent>
        ) : (
          <ModalContent
            borderRadius="1em"
            p=".5em"
            marginTop={{ base: "15em", md: "6em" }}
          >
            <ModalHeader mt="1em">
              <Center>
                Add your card details to your campaign to activate it.
              </Center>
            </ModalHeader>

            <ModalBody>
              {/* @ts-ignore */}
              {cardList.length < 1 ? (
                <Box
                  // height={{ base: "200px", lg: "250px" }}
                  overflowY="auto"
                  px={{ base: "1px", lg: "4em" }}
                >
                  <Flex align="flex-end">
                    <Heading size="md">No Attached Card:</Heading>
                    <Text>Kindly Add a card</Text>
                  </Flex>
                </Box>
              ) : (
                <Box
                  height={{ base: "200px", lg: "250px" }}
                  overflowY="auto"
                  px={{ base: "1px", lg: "4em" }}
                >
                  {cardList.map((item, i) => {
                    return (
                      <Box
                        w="100%"
                        //  @ts-ignore
                        bg={selectedCard?.id === item.id ? "#ecf3fe" : "#fff"}
                        h="120px"
                        mb="20px"
                        borderColor={
                          //  @ts-ignore
                          selectedCard?.id === item.id ? "#7211d4" : "#32325D33"
                        }
                        borderWidth="1px"
                        borderRadius="10px"
                        p={5}
                        _hover={{
                          bg: "Alabaster",
                          borderColor: "PurplishBlue",
                        }}
                        transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
                        _active={{
                          bg: "#dddfe2",
                          transform: "scale(0.98)",
                          borderColor: "#bec3c9",
                        }}
                        //  @ts-ignore
                        key={item.id}
                        onClick={() => {
                          //  @ts-ignore
                          console.log(item.id);
                          setSelectedCard(item);
                          //  @ts-ignore
                          setCardID(item.id);
                        }}
                      >
                        <Flex>
                          <Flex w="20%" ml={3}>
                            {/* @ts-ignore */}
                            {item?.brand === "Visa" ? (
                              <RiVisaLine
                                size="50px"
                                color="#254294"
                                style={{
                                  backgroundColor: "white",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              />
                            ) : (
                              <Image
                                src={MasterCard}
                                style={{
                                  // backgroundColor: "white",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  width: "60px",
                                }}
                              />
                            )}
                          </Flex>
                          <Flex direction="column" w="60%" gap={3}>
                            <Heading as="h5" size="md" color="#254294">
                              {/* @ts-ignore  */}
                              {item?.brand === "Visa" ? "Visa" : "Master"}{" "}
                              ending
                              {/* @ts-ignore  */}
                              {" in"} {item.last4}
                            </Heading>

                            <Text color="#254294">
                              {/* @ts-ignore  */}
                              Expiry {item.exp_month} / {item.exp_year}
                            </Text>
                          </Flex>
                          <Flex w="20%" justify="end">
                            {/* @ts-ignore  */}
                            {selectedCard?.id == item.id ? (
                              <BsFillCheckCircleFill
                                size="20px"
                                color="#7211d4"
                              />
                            ) : (
                              <BsCircle size="20px" color="#000" />
                            )}
                          </Flex>
                        </Flex>
                      </Box>
                    );
                  })}
                </Box>
              )}
              <Flex align="center" p={{ base: "1em", lg: "2em" }}>
                <Button
                  variant="ghost"
                  onClick={() => {
                    setAddNewCard(true);
                  }}
                >
                  <Flex
                    height={{ base: "50px", lg: "100px" }}
                    overflowY="auto"
                    align="center"
                    gap={5}
                  >
                    <IoAddSharp size="20px" />
                    <Text>Add new card</Text>
                  </Flex>
                </Button>
              </Flex>

              <Box
                width="90%"
                height="1px"
                mx="auto"
                bgColor="gray.200"
                mb=".6em"
              ></Box>
            </ModalBody>

            <ModalFooter>
              <Button
                variant="ghost"
                mr={3}
                onClick={() => setPaymentClose(false)}
              >
                Cancel
              </Button>
              <Button
                bgColor="#F0F5FF"
                color="#7211D4"
                rightIcon={<IoArrowForwardSharp />}
                onClick={submitHandler}
                isLoading={activateLoader}
              >
                Continue
              </Button>
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>

      <Box m="0px 22px 22px 22px">
        <CampaignHeader currentStep={5} />

        <Stack bgColor="#fff" padding="1.3em" borderRadius=".4em">
          <Heading
            fontSize="1em"
            color="#32325D"
            borderBottom="1px solid"
            borderColor="gray.300"
            pb="1.3em"
          >
            Product Information
          </Heading>

          <form>
            {/* <Container maxW="container.lg" mx="auto"> */}
            <Flex
              alignItems="flex-start"
              justifyContent="space-between"
              columnGap="1em"
              my="1em"
              flexDirection={["column", "row"]}
              borderBottom={["0", "1px solid #CBD5E0"]}
              py={[".5em", "1em"]}
            >
              <Text
                fontSize={["14px", "1em"]}
                color="#32325D"
                flexGrow={1}
                width={{ base: "100%", md: "49%" }}
                textAlign="left"
                mb={[".3em", "0em"]}
              >
                Product ID
              </Text>
              <Box width="100%">
                {/* <form onSubmit={addProdIdHandler}> */}
                <Flex
                  align="center"
                  justifyContent="space-between"
                  flexDir={{ base: "column", md: "row" }}
                  rowGap=".6em"
                >
                  <Flex
                    align="center"
                    gap=".5em"
                    width={{ base: "100%", md: "85%" }}
                  >
                    <Input
                      type="text"
                      value={productId}
                      placeholder="Product ID"
                      onChange={(e: React.ChangeEvent<any>) =>
                        setProductId(e.target.value)
                      }
                      _focus={{ ring: "0", borderColor: "#5C14CE" }}
                      isRequired={true}
                      borderColor="gray.300"
                      style={{
                        height: "50px",
                        backgroundColor: "#fefdff",
                        // borderColor: "#c4c4c4",
                        borderRadius: "10px",
                      }}
                    />
                    <Input
                      type="text"
                      value={productIdLink}
                      placeholder="Product ID Link"
                      onChange={(e: React.ChangeEvent<any>) =>
                        setProductIdLink(e.target.value)
                      }
                      _focus={{ ring: "0", borderColor: "#5C14CE" }}
                      isRequired={true}
                      borderColor="gray.300"
                      style={{
                        height: "50px",
                        backgroundColor: "#fefdff",
                        // borderColor: "#c4c4c4",
                        borderRadius: "10px",
                      }}
                    />
                  </Flex>
                  <Button
                    onClick={addProdIdHandler}
                    color="#fff"
                    bgColor="#5C14CE"
                    marginLeft={{ base: "0em", md: "0em" }}
                    width={{ base: "100%", md: "13%" }}
                  >
                    Add
                  </Button>
                </Flex>
                {/* </form> */}

                {productIds && productIds?.length >= 1 ? (
                  <Box
                    // align="center"
                    // gap=".5em"
                    // p=".2em"
                    // border="1px"
                    // borderColor="#5C14CE"
                    // borderColor="gray.300"
                    mt=".5em"
                    // borderRadius=".4em"
                  >
                    {productIds?.map(
                      (
                        { id, link }: { id: string; link: string },
                        idx: any
                      ) => {
                        return (
                          <Flex
                            key={idx}
                            align="center"
                            columnGap=".2em"
                            // bgColor="gray.200"
                            p=".2em"
                            borderRadius=".2em"
                          >
                            <Box
                              border="1px"
                              borderRadius=".2em"
                              borderColor="#5C14CE"
                              width={{ base: "42.5%" }}
                            >
                              <Text m=".3em" bgColor="gray.100" p=".1em">
                                {id}
                              </Text>
                            </Box>

                            <Box
                              border="1px"
                              borderRadius=".2em"
                              borderColor="#5C14CE"
                              width={{ base: "42.5%" }}
                            >
                              <Text m=".3em" bgColor="gray.100" p=".1em">
                                {link}
                              </Text>
                            </Box>

                            <Box ml={{ base: ".5em" }}>
                              <AiFillCloseCircle
                                color="black"
                                cursor="pointer"
                                fontSize="1.2em"
                                onClick={() => removeProdIdHandler(id)}
                              />
                            </Box>
                          </Flex>
                        );
                      }
                    )}
                  </Box>
                ) : null}
                {productIdsActive && productIdsActive?.length >= 1 ? (
                  <Box
                    // align="center"
                    // gap=".5em"
                    // p=".2em"
                    // border="1px"
                    // borderColor="#5C14CE"
                    // borderColor="gray.300"
                    mt=".5em"
                    // borderRadius=".4em"
                  >
                    {productIdsActive?.map(
                      (
                        { id, link }: { id: string; link: string },
                        idx: any
                      ) => {
                        return (
                          <Flex
                            key={idx}
                            align="center"
                            columnGap=".2em"
                            // bgColor="gray.200"
                            p=".2em"
                            borderRadius=".2em"
                            direction={["column-reverse", "row"]}
                          >
                            <Box
                              border="1px"
                              borderRadius=".2em"
                              borderColor="#5C14CE"
                              width={["100%", "42.5%"]}
                              p={2}
                            >
                              <Text
                                m=".3em"
                                bgColor="gray.100"
                                p="5px"
                                px={"15px"}
                              >
                                {id}
                              </Text>
                            </Box>

                            <Box
                              border="1px"
                              borderRadius=".2em"
                              borderColor="#5C14CE"
                              width={["100%", "42.5%"]}
                              p={2}
                            >
                              <Text
                                m=".3em"
                                bgColor="gray.100"
                                p="5px"
                                px={"15px"}
                              >
                                {link}
                              </Text>
                            </Box>

                            {status === "draft" ? (
                              <Box ml={{ base: ".5em" }}>
                                <AiFillCloseCircle
                                  color="black"
                                  cursor="pointer"
                                  fontSize="1.2em"
                                  onClick={() => removeProdIdHandlerActive(id)}
                                />
                              </Box>
                            ) : (
                              <Image
                                alt=""
                                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/b50a18cd-5809-45f9-a35b-ba79eab96c4b.svg"
                                display={["none", "flex"]}
                              />
                            )}
                          </Flex>
                        );
                      }
                    )}
                  </Box>
                ) : null}
              </Box>
            </Flex>

            {productType?.toLowerCase() === "saas" && (
              <>
                <Flex
                  alignItems="flex-start"
                  justifyContent="space-between"
                  columnGap="1em"
                  my="1em"
                  flexDirection={["column", "row"]}
                  borderBottom={["0", "1px solid #CBD5E0"]}
                  py={[".5em", "1em"]}
                >
                  <Text
                    fontSize={["14px", "1em"]}
                    color="#32325D"
                    flexGrow={1}
                    width={{ base: "100%", md: "49%" }}
                    textAlign="left"
                    mb={[".3em", "0em"]}
                  >
                    Trial Period
                  </Text>

                  <Flex
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                    columnGap=".5em"
                  >
                    <InputGroup alignItems={"center"} gap={5}>
                      <Input
                        type="text"
                        value={trialPeriod}
                        // placeholder="Enter the trail period for your SaaS product"
                        onChange={(e: React.ChangeEvent<any>) =>
                          setTrialPeriod(e.target.value)
                        }
                        _focus={{ ring: "0", borderColor: "#5C14CE" }}
                        isRequired={true}
                        borderColor="gray.300"
                        style={{
                          height: "50px",
                          backgroundColor: "#fefdff",
                          // borderColor: "#c4c4c4",
                          borderRadius: "10px",
                          width: "85%",
                        }}
                        isDisabled={editPage && lockCampaign ? true : false}
                      />

                      <InputRightAddon
                        children="Days"
                        bgColor="#5C14CE"
                        color="#fff"
                        fontWeight={600}
                        px={7}
                        py={5}
                        borderRadius={5}
                      />
                    </InputGroup>

                    {editPage && lockCampaign ? (
                      <img
                        alt=""
                        src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/b50a18cd-5809-45f9-a35b-ba79eab96c4b.svg"
                      />
                    ) : null}
                  </Flex>
                </Flex>

                <Flex
                  alignItems="flex-start"
                  justifyContent="space-between"
                  columnGap="1em"
                  my="1em"
                  flexDirection={["column", "row"]}
                  borderBottom={["0", "1px solid #CBD5E0"]}
                  py={[".5em", "1em"]}
                >
                  <Text
                    fontSize={["14px", "1em"]}
                    color="#32325D"
                    flexGrow={1}
                    width={{ base: "100%", md: "49%" }}
                    textAlign="left"
                    mb={[".3em", "0em"]}
                  >
                    Subscription Interval
                  </Text>

                  <Flex
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                    columnGap=".5em"
                  >
                    <Select
                      _focus={{ ring: "0", borderColor: "#5C14CE" }}
                      isRequired={true}
                      value={subInterval}
                      onChange={(e: React.ChangeEvent<any>) =>
                        setSubInterval(e.target.value)
                      }
                      width={{ base: "100%" }}
                      isDisabled={editPage && lockCampaign ? true : false}
                      style={{
                        height: "50px",
                        backgroundColor: "#fefdff",
                        // borderColor: "#c4c4c4",
                        borderRadius: "10px",
                      }}
                    >
                      {["Daily", "Weekly", "Monthly", "Yearly"].map(
                        (interval) => (
                          <option key={interval} value={interval}>
                            {interval}
                          </option>
                        )
                      )}
                    </Select>

                    {editPage && lockCampaign ? (
                      <img
                        alt=""
                        src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/b50a18cd-5809-45f9-a35b-ba79eab96c4b.svg"
                      />
                    ) : null}
                  </Flex>
                </Flex>
              </>
            )}

            <Flex
              alignItems="flex-start"
              justifyContent="space-between"
              columnGap="1em"
              my="1em"
              flexDirection={["column", "row"]}
              borderBottom={["0", "1px solid #CBD5E0"]}
              py={[".5em", "1em"]}
            >
              <Text
                fontSize={["14px", "1em"]}
                color="#32325D"
                flexGrow={1}
                width={{ base: "100%", md: "49%" }}
                textAlign="left"
                mb={[".3em", "0em"]}
              >
                Referral commission support
              </Text>
              <Select
                _focus={{ ring: "0", borderColor: "#5C14CE" }}
                isRequired={true}
                value={refCommissionSupport}
                onChange={(e: React.ChangeEvent<any>) =>
                  setRefCommissionSupport(e.target.value)
                }
                width={{ base: "100%" }}
                style={{
                  height: "50px",
                  backgroundColor: "#fefdff",
                  // borderColor: "#c4c4c4",
                  borderRadius: "10px",
                }}
              >
                {["ON", "OFF"].map((commSup) => (
                  <option key={commSup} value={commSup}>
                    {commSup}
                  </option>
                ))}
              </Select>
            </Flex>

            {/* 
            // PAYOUT TERMS
            {productType?.toLowerCase() === "saas" && (
              <Flex
                alignItems="flex-start"
                justifyContent="space-between"
                columnGap="1em"
                my="1em"
                flexDirection={["column", "row"]}
                borderBottom={["0", "1px solid #CBD5E0"]}
                py={[".5em", "1em"]}
              >
                <Text
                  fontSize={["14px", "1em"]}
                  color="#32325D"
                  flexGrow={1}
                  width={{ base: "100%", md: "49%" }}
                  textAlign="left"
                  mb={[".3em", "0em"]}
                >
                  Payout frequency
                </Text>
                <Select
                  _focus={{ ring: "0", borderColor: "#5C14CE" }}
                  isRequired={true}
                  value={payoutFreq}
                  onChange={(e: React.ChangeEvent<any>) =>
                    setPayoutFreq(e.target.value)
                  }
                  width={{ base: "100%" }}
                >
                  {["15 Days", "30 Days", "45days", "60 Days"].map(
                    (reqData) => (
                      <option key={reqData} value={reqData}>
                        {reqData}
                      </option>
                    )
                  )}
                </Select>
              </Flex>
            )} */}

            <Flex
              alignItems="center"
              justifyContent="space-between"
              mb="1em"
              mt="6em"
              flexDirection={{ base: "column-reverse", lg: "row" }}
              rowGap=".6em"
            >
              <Button
                px="0em"
                color="#5C14CE"
                bgColor="#fff"
                fontWeight="500"
                fontSize="14px"
                // width={{ md: "40%" }}
                _hover={{ bgColor: "#fff" }}
                _focus={{ bgColor: "#fff" }}
                onClick={backToPreviousHandler}
                leftIcon={<IoArrowBackSharp />}
              >
                Back to the previous page
              </Button>

              <Box
                width={{ base: "100%", lg: "40%" }}
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
                flexDirection={["column-reverse", "row"]}
                rowGap=".6em"
                justifySelf="flex-end"
              >
                <Box mr={["0em", "1em"]} width={["100%", "60%", "50%"]}>
                  <BaseButton
                    text="Save and Exit"
                    textColor="#5C14CE"
                    bgColor="#F0F5FF"
                    clickEventHandler={saveAndExitHandler}
                  />
                </Box>

                <Box mr={["0em", "1em"]} width={["100%", "60%", "50%"]}>
                  <BaseButton
                    text={editPage ? "Update Campaign" : "Create Campaign"}
                    textColor="#fff"
                    bgColor="#5C14CE"
                    loadingState={loader}
                    rightIcon={<IoArrowForwardSharp />}
                    clickEventHandler={submitHandler}
                  />
                </Box>
              </Box>
            </Flex>
            {/* </Container> */}
          </form>
        </Stack>
      </Box>
    </>
  );
};
