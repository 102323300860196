import { Box, Button, HStack, Image, Text } from "@chakra-ui/react";
import React from "react";

type paginationDataType = {
  currentPage: number;
  pageSize: number;
};

type paginationComponentPropTypes = {
  paginationData: paginationDataType;
  setPaginationData: React.Dispatch<
    React.SetStateAction<{
      currentPage: number;
      pageSize: number;
    }>
  >;
  dataLength: number;
};

const PaginationComponent = ({
  paginationData,
  setPaginationData,
  dataLength,
}: paginationComponentPropTypes) => {
  const goNext = () => {
    setPaginationData({
      ...paginationData,
      currentPage: paginationData.currentPage + 1,
    });
  };

  const goBack = () => {
    if (paginationData.currentPage <= 1) return;
    setPaginationData({
      ...paginationData,
      currentPage: paginationData.currentPage - 1,
    });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      css={{ borderTop: "1px solid rgba(50, 50, 93, 0.15)" }}
      pt="20px"
      justifyContent="end"
    >
      <HStack spacing="6px">
        {paginationData.currentPage > 1 && (
          <Button
            onClick={goBack}
            bgColor="#F8F7F5"
            p="7px 11px"
            boxShadow="md"
          >
            <Image
              w="7px"
              h="11px"
              transform="rotate(180deg)"
              src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/67853fcc-a95e-4476-be48-14434b51b27d.svg`}
            />
          </Button>
        )}

        <Box border="1px solid rgba(50, 50, 93, 0.1)" p="3px 11px">
          <Text
            fontSize="12px"
            lineHeight="18px"
            fontWeight="500"
            color="#19073B"
          >
            {paginationData.currentPage}
          </Text>
        </Box>

        {Math.ceil(dataLength / paginationData.pageSize) >
          paginationData.currentPage && (
          <Button
            bgColor="#F8F7F5"
            onClick={goNext}
            p="7px 11px"
            boxShadow="md"
          >
            <Image
              w="7px"
              h="11px"
              src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/67853fcc-a95e-4476-be48-14434b51b27d.svg`}
            />
          </Button>
        )}
      </HStack>
    </Box>
  );
};

export default PaginationComponent;
