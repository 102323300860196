import { useEffect, useRef, useState } from "react";
import { FormInput } from "../formInput/index";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SideBar from "./sidebar";
import { BaseButton } from "../button";
import { IoArrowForwardSharp } from "react-icons/io5";
import { useCookies } from "react-cookie";
import {
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  ModalBody,
  Button,
  Box,
  Heading,
  Stack,
  Checkbox,
  Image,
  Divider,
  Flex,
  useToast,
} from "@chakra-ui/react";
import OrganizationContent from "./organizations";
import { useMediaQuery } from "react-responsive";

import React from "react";
import {
  setCurrentUserType,
  setLocalUserInstance,
} from "../../state/localstorage";
const LoginPage = () => {
  const navigate = useNavigate();
  const [, setCookie] = useCookies([
    "user",
    "access_token",
    "aff_user",
    "aff_access_token",
  ]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef(null);
  const [remember, setRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allOrganization, setAllOrganization] = useState<any[]>([]);
  const [organization, setOrganization] = useState();
  const modalHandler = (data: any) => {
    setOrganization(data);
    onClose();
    loginOrg(data);
  };
  const [clientError, setClientError]: [
    clientError: string[],
    setClientError: React.Dispatch<React.SetStateAction<any>>
  ] = useState([]);

  const formViewRef = useRef(null);

  const submitHandler = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    setLoading(true);
    if (email && password) {
      fetch(process.env.REACT_APP_API_ENDPOINT + "auth/signin", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);

          if (data.success) {
            if (data.data.user && data.data.user.roleId === 6) {
              setAllOrganization([
                {
                  id: data.data.user.organisation.id,
                  orgType: data.data.user.organisation.userType,
                  companyName: data.data.user.organisation.companyname,
                  isDraft:
                    !data.data.user.organisation.signUpStep ||
                    data.data.user.organisation.signUpStep < 5,
                },
              ]);
            } else {
              setAllOrganization(
                data.data.organisations.map((org: any) => ({
                  ...org,
                  isDraft: !org.signUpStep || org.signUpStep < 5,
                })) || []
              );
            }

            onOpen();
          } else {
            // @ts-ignore
            formViewRef.current.scrollIntoView();

            toast({
              title: data.message,
              status: "error",
              duration: 4000,
              isClosable: true,
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("here");
        });
    } else {
      setLoading(false);
      setClientError([...clientError, "Please complete all required fields"]);
      toast({
        title: "Please complete all required fields",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const loginOrg = (data: any) => {
    setLoading(true);
    fetch(process.env.REACT_APP_API_ENDPOINT + "auth/signin", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        password: password,
        orgId: data["id"],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          toast({
            title: "Login successful",
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top-right",
          });
          let expires = new Date();
          expires.setTime(expires.getTime() + 720 * 60 * 60 * 1000);
          if (data.data.user.organisation.userType === "affiliate") {
            setCookie("aff_user", data.data.user, {
              path: "/",
              expires,
            });
            setCookie("aff_access_token", data.data.token, {
              path: "/",
              expires,
            });
          } else {
            setCookie("user", data.data.user, {
              path: "/",
              expires,
            });
            setCookie("access_token", data.data.token, {
              path: "/",
              expires,
            });
          }

          if (data.data.user.organisation.userType) {
            setCurrentUserType(data.data.user.organisation.userType || "");
          }
          setLocalUserInstance(data.data.user);
          setTimeout(function () {
            if (data.data.user.organisation.userType === "affiliate") {
              navigate("/affiliate-dashboard");
            } else if (data.data.user.organisation.userType === "advertiser") {
              navigate("/dashboard");
            }
          }, 1000);

          //wait 1 second
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();

          toast({
            title: data.message,
            status: "error",
            duration: 4000,
            isClosable: true,
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("here");
      });
  };

  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  return (
    <>
      <Box width="200px">
        <ToastContainer />
      </Box>
      <Flex
        // height="auto"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        ref={formViewRef}
      >
        <SideBar />
        <Box
          display="flex"
          width={["100%", "50%"]}
          // minH="100vh"
          height={{ base: "auto", md: "100%" }}
          overflowY={{ base: "unset", md: "hidden" }}
          overflowX="hidden"
          left="0"
          bottom="0"
          padding={{ base: "24px", md: "0px" }}
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Box display={{ base: "block", md: "none" }}>
            <Image src="/assets/logo.png" mb="1.5em" height="50px" />
          </Box>
          <Flex
            width={{ base: "100%", md: "80%" }}
            justify={"center"}
            align={"center"}
          >
            <form
              onSubmit={submitHandler}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",

                height: "60%",
              }}
            >
              <Flex
                justify={{ base: "center", md: "space-between" }}
                direction={"column"}
                width={{ base: "100%", md: "90%" }}
              >
                <Box width="100%">
                  <Heading
                    fontWeight="700"
                    color="#000000;"
                    fontSize={["24px", "32px"]}
                    lineHeight="48px"
                  >
                    Hey there, welcome back.
                  </Heading>
                  <Text
                    color="#737373"
                    fontSize={["12px", "16px"]}
                    fontWeight="400"
                    lineHeight="26px"
                  >
                    Login to your Metricks account
                  </Text>
                  <Divider
                    orientation="horizontal"
                    mb={"2em"}
                    mt={"1em"}
                    variant={"dashed"}
                    color={"#c4c4c4"}
                  />
                  <FormInput
                    label="Email address"
                    type="email"
                    value={email}
                    stateHandler={setEmail}
                    required={true}
                    holder="example@gmail.com"
                  />
                  <FormInput
                    label="Password"
                    type="password"
                    value={password}
                    stateHandler={setPassword}
                    required={true}
                    holder="Enter password"
                  />
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom="30px"
                  >
                    <Checkbox
                      colorScheme="purple"
                      color="#A5A5A5"
                      alignItems="center"
                      onChange={(e: React.ChangeEvent<any>) =>
                        setRemember(e.target.checked)
                      }
                    >
                      <Text
                        color="#A5A5A5"
                        marginLeft="6px"
                        fontSize="12px"
                        fontWeight="400px"
                      >
                        Remember me
                      </Text>
                    </Checkbox>
                    <Link to="/reset-password">
                      <Text color="#EB813C" fontSize="12px" fontWeight="400px">
                        Forgot password?
                      </Text>
                    </Link>
                  </Box>
                </Box>
                <Box width="100%" mt={"100px"}>
                  <BaseButton
                    text="Login"
                    padded={true}
                    textColor="#fff"
                    bgColor="#5C14CE"
                    type="submit"
                    loadingState={loading}
                    loaderText="Loading..."
                    clickEventHandler={submitHandler}
                    rightIcon={<IoArrowForwardSharp />}
                  />
                  {/* <Link to="/suspended"> */}
                  <Text
                    color="#333333"
                    mt="15px"
                    // mb="2em"
                    textAlign="center"
                    // onClick={() => }
                  >
                    Don't have an account? {"   "}
                    <Link
                      to="/signup"
                      onClick={() => {
                        setLocalUserInstance({});
                      }}
                    >
                      <span
                        style={{
                          color: "#5C14CE",
                          textDecoration: "underline",
                        }}
                      >
                        Sign Up
                      </span>
                    </Link>
                  </Text>
                  {/* </Link> */}
                  <Modal
                    onClose={onClose}
                    finalFocusRef={btnRef}
                    isOpen={isOpen}
                    size={isMobile ? "full" : "xl"}
                  >
                    <ModalOverlay />
                    <ModalContent
                      maxWidth={isMobile ? "100vw" : "auto"}
                      width={isMobile ? "auto" : "70%"}
                      borderRadius="39px"
                    >
                      <ModalBody borderRadius="39px" height="538px" p="0">
                        <OrganizationContent
                          data={allOrganization}
                          modalHandler={modalHandler}
                        />
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                </Box>
              </Flex>
            </form>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default LoginPage;
