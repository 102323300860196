import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CiSearch } from "react-icons/ci";
import { IoFilterOutline } from "react-icons/io5";
import ListChat from "./elements/ListChat";
import moment from "moment";
import { useOutsideAlerter } from "../../hooks/useOutsideAlerter";
import AdvertiserChatView from "./elements/AdvertiserChatView";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { getCurrentUserInstance } from "../../state/localstorage";
import useUserToken from "../../hooks/useUserToken";

import { TemporaryNavData } from "../context/TemporaryNavData";

const advertiserChats: any[] = [
  {
    name: "Angelina Martin",
    id: 1,
    image:
      "https://tse4.explicit.bing.net/th?id=OIP.y-R6b74yIBCpAOfYIBVQpAHaLH&pid=Api&P=0&w=300&h=300",
    lastMessage: "I am sorry i forgot to reply the chat that we were having",
    unreadMessageCount: 3,
    isOnline: true,
  },
  {
    name: "Alex Rednalexa",
    id: 2,
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/5257be03-c9e3-407d-a7c5-f96c4bbf15d2.png",
    lastMessage: "I am sorry i forgot to reply the chat that we were having",
    unreadMessageCount: 2,
  },
  {
    name: "Angelina Martin",
    id: 3,
    image:
      "https://tse4.explicit.bing.net/th?id=OIP.y-R6b74yIBCpAOfYIBVQpAHaLH&pid=Api&P=0&w=300&h=300",
    lastMessage: "I am sorry i forgot to reply the chat that we were having",
    unreadMessageCount: 0,
    isOnline: true,
  },
  {
    name: "Alex Rednalexa",
    id: 4,
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/5257be03-c9e3-407d-a7c5-f96c4bbf15d2.png",
    lastMessage: "I am sorry i forgot to reply the chat that we were having",
    unreadMessageCount: 2,
    isOnline: true,
  },
  {
    name: "Alex Rednalexa",
    id: 5,
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/5257be03-c9e3-407d-a7c5-f96c4bbf15d2.png",
    lastMessage: "I am sorry i forgot to reply the chat that we were having",
    unreadMessageCount: 2,
  },
];

// search for old chat with this below--

{
  /* <IconButton
height="100%"
width="100%"
variant="ghost"
padding="4px"
onClick={openChats}
aria-label="open chat"
icon={
  <Image src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/7a074a02-6aaa-46d4-8fe3-2a3c262defb4.png" />
}
/> */
}

const AdvertiserChat = (data: any) => {
  const user = getCurrentUserInstance();
  console.log("user", user);

  const token = useUserToken();
  const [searchValue, setsearchValue] = useState("");
  const [showFilter, setshowFilter] = useState(false);
  const filterRef = useRef(null);
  const topElementRef = useRef(null);
  const [chatList, setChatList]: any[] = useState([]);
  const [currentChat, setcurrentChat] = useState(null);
  const [campaign, setCampaigns] = useState([]);
  const getCampaigns = () => {
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + "campaign/fetch-campaigns", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.success) {
          setCampaigns(data.data.campaigns);
        } else {
          // @ts-ignore
          console.log(data);
        }
      })
      .catch((err) => {
        setCampaigns([]);
      });
  };

  // const { socket } = useContext(TemporaryNavData);

  // const socket2 = io("http://localhost:3001");

  useOutsideAlerter(filterRef, () => setshowFilter(false));

  // useEffect(() => {
  //   socket.on("connect", () => {
  //     socket.emit("CHAT_LIST", { parentId: user.id });
  //   });
  // }, []);
  const [socketUrl, setSocketUrl] = useState(
    "wss://metricks-backend-service.herokuapp.com"
  );
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

  useEffect(() => {
    if (lastMessage !== null) {
      const message = JSON.parse(lastMessage.data);
      switch (message.type) {
        case "CHAT_LIST":
          console.log(message.payload);
          break;
        case "GET_PRIVATE_RESPONSE":
          console.log(message);
          break;
        // Handle other response events here...
        default:
          break;
      }
    }
  }, [lastMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];
  console.log(connectionStatus);

  const handleSendMessage = (messageText: string) => {
    const message = {
      type: "CHAT_MASS",
      payload: { users: [5, 6], orgId: 4, message: messageText },
    };
    sendMessage(JSON.stringify(message));
  };
  const requestPrivateChat = (userId: number) => {
    const message = { type: "GET_PRIVATE", payload: { parentId: userId } };
    sendMessage(JSON.stringify(message));
  };
  const sendPrivateMessage = (
    userId: number,
    orgId: number,
    messageText: string,
    parentId: number
  ) => {
    const message = {
      type: "CHAT_PRIVATE",
      payload: { userId, orgId, message: messageText, parentId },
    };
    sendMessage(JSON.stringify(message));
  };
  useEffect(() => {
    // setcurrentChat(data.data);
    // console.log("websock");

    // socket.on("connect", () => {
    //   socket.emit("CHAT_LIST", { parentId: user.id }); // Assuming user.id is available
    //   socket.on("CHAT_LIST_RESPONSE", (event: any) => {
    //     setChatList(event);
    //     console.log("Message from server ", event);
    //     console.log("event", event);
    //     // setLoading(false);
    //   });
    // });
    return () => {};
  }, []);

  // const sendMessage = () => {
  // socket.on("connect", () => {
  //   socket.emit("CHAT_PRIVATE", {
  //     userId: 101,
  //     orgId: 44,
  //     message: "from aff",
  //     parentId: 40,
  //   });
  // });
  //   console.log("test");
  // };
  const getPrivateChat = () => {
    // socket.on("connect", () => {
    //   socket.emit(
    //     "GET_PRIVATE",
    //     {
    //       userId: 6,
    //     },
    //     (event: any) => {
    //       console.log("GETPrivate ", event);
    //       // setLoading(false);
    //     }
    //   );
    //   socket.emit("GET_PRIVATE_RESPONSE", (event: any) => {
    //     console.log("private response ", event);
    //     // setLoading(false);
    //   });
    // });
  };

  // const [isChatOpen, setisChatOpen] = useState(false);
  // {isChatOpen ? (
  //   <Slide direction="bottom" in={isChatOpen}>
  //     <ChatElement onClose={closeChat} />
  //   </Slide>
  // ) : (
  //   ""
  // )}

  // const openChats = () => {
  //   setisChatOpen(true);
  // };

  // const closeChat = () => {
  //   setisChatOpen(false);
  // };
  //   <IconButton
  //   height="100%"
  //   width="100%"
  //   variant="ghost"
  //   padding="4px"
  //   onClick={openChats}
  //   aria-label="open chat"
  //   icon={
  //     <Image src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/7a074a02-6aaa-46d4-8fe3-2a3c262defb4.png" />
  //   }
  // />

  if (currentChat !== null)
    return (
      <AdvertiserChatView
        updateCurrentChat={setcurrentChat}
        currentChat={currentChat}
      />
    );

  return (
    <Box height="100%" position="relative" overflowY="scroll">
      <Box
        padding="15px 20px 0px 20px"
        ref={topElementRef}
        position={"sticky"}
        bgColor="white"
        zIndex="4"
        top="0"
        borderBottom="1px solid rgba(50, 50, 93, 0.25)"
      >
        <Flex
          bgColor="rgba(242, 238, 246, 1)"
          alignItems="center"
          px="18px"
          py="14px"
          position="relative"
        >
          <Button
            leftIcon={<IoFilterOutline fontSize="0.9rem" />}
            color="rgba(50, 50, 93, 0.8)"
            bgColor="white"
            variant="solid"
            border="0.5px solid rgba(50, 50, 93, 0.2)"
            p="10px 14px"
            fontSize="0.7rem"
            mr="15px"
            onClick={() => setshowFilter(!showFilter)}
          >
            Filter
          </Button>

          <Flex
            alignItems="center"
            flex="1"
            bg="white"
            border="0.5px solid rgba(50, 50, 93, 0.2)"
            rounded="6px"
            p="10px 14px"
          >
            <CiSearch
              fontSize="0.9rem"
              onClick={() => sendPrivateMessage(101, 44, "hello", 40)}
              cursor={"pointer"}
            />
            <input
              placeholder="Search keywords..."
              value={searchValue}
              style={{
                flex: 1,
                padding: "0px",
                border: "none",
                fontSize: "0.75rem",
                marginLeft: "13px",
              }}
              id="chatInput"
              onChange={(e) => setsearchValue(e.target.value)}
            />
          </Flex>

          {showFilter ? (
            <Box
              bgColor="white"
              py="26px"
              px="28px"
              rounded="8px"
              position="absolute"
              w="70%"
              boxShadow="0px 0px 30px rgba(0, 0, 0, 0.06)"
              top="90%"
              left="0px"
            >
              <Select
                w="full"
                fontSize="0.9rem"
                _focus={{ ring: "0", border: "0.5px solid #7211D4" }}
              >
                <option value="">Select Campaign</option>
              </Select>

              <Flex
                justifyContent="end"
                alignItems="center"
                mt="20px"
                pt="8px"
                borderTop="0.5px solid rgba(0, 0, 0, 0.15)"
              >
                <Button
                  variant="ghost"
                  color="#32325D"
                  rounded="8px"
                  p="6.5px 18px"
                  fontSize="0.75rem"
                  lineHeight="18px"
                  mr="20px"
                  onClick={() => setshowFilter(false)}
                >
                  Cancel
                </Button>

                <Button
                  bgColor="#7211D4"
                  color="white"
                  rounded="8px"
                  p="6.5px 18px"
                  fontSize="0.75rem"
                  lineHeight="18px"
                >
                  Apply
                </Button>
              </Flex>
            </Box>
          ) : (
            ""
          )}
        </Flex>

        <Box borderTop="1px solid rgba(50, 50, 93, 0.25)" mt="10px" py="10px">
          <Text
            fontSize="0.75rem"
            fontWeight="600"
            lineHeight="18px"
            color="rgba(50, 50, 93, 1)"
            textTransform="uppercase"
            // onClick={sendMessage}
          >
            List of affiliates
          </Text>
        </Box>
      </Box>

      {chatList.length ? (
        <VStack px="20px" pb="20px">
          {chatList.map((chat: any, i: number) => (
            <ListChat
              chatId={chat.toUser.id}
              key={i}
              lastMessage={chat.message}
              userImage={chat.profile_image}
              username={`${chat.toUser.firstname} ${chat.toUser.lastname}`}
              openChatView={setcurrentChat}
              unreadMessageCount={1}
              lastMessageDate={moment(chat.updatedAt).fromNow()}
              isOnline={true || false}
            />
          ))}
        </VStack>
      ) : (
        <Box
          height="80%"
          w="100%"
          display="flex"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box>
            <Image
              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
              height="220px"
              objectFit="contain"
              mb="16px"
            />

            <Text
              fontSize="14px"
              lineHeight="21px"
              color="rgba(50, 50, 93, 1)"
              maxW="204px"
              fontWeight="600"
              textAlign="center"
            >
              No data to display yet. Start a new conversation
            </Text>
          </Box>
        </Box>
      )}

      {!advertiserChats.length ? (
        <Box position="sticky" bottom="0" display="flex" justifyContent="end">
          <Image
            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/132b5cdd-6c1f-4cef-8d7e-c9d6fe01442b.svg"
            height="80px"
            width="80px"
            objectFit="contain"
            cursor="pointer"
          />
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default AdvertiserChat;
