import { Stack, StackDivider } from "@chakra-ui/react";
import React from "react";
import AffiliateProperty from "./Detail";

type AffiliateInfo = {
  affiliateType: string;
  experience: string;
  size: string;
  countriesToShip: any[];
  productType: string;
  niche: any[];
};

const AffiliateInfo = ({
  affiliateType,
  experience,
  size,
  countriesToShip,
  productType,
  niche,
}: AffiliateInfo) => {
  return (
    <Stack divider={<StackDivider borderColor="rgba(50, 50, 93, 0.3)" />}>
      <AffiliateProperty
        type="string"
        fieldData={
          affiliateType.split("_")[0] + " " + affiliateType.split("_")[1]
        }
        label="Affiliate Type"
      />

      <AffiliateProperty
        type="string"
        fieldData={experience ? experience : ""}
        label="Experience"
      />

      <AffiliateProperty
        type="string"
        fieldData={size}
        label="Size of Organization"
      />
      <AffiliateProperty
        type="list"
        fieldData={countriesToShip}
        label="Traffic Countries"
      />
      <AffiliateProperty
        type="list"
        fieldData={productType}
        label="Affiliate Product Type"
      />
      <AffiliateProperty type="list" fieldData={niche} label="Niche" />
    </Stack>
  );
};

export default AffiliateInfo;
