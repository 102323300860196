import React from "react";
import { Box, Text, Image, Link } from "@chakra-ui/react";

const StepsContent = ({ step, title, content }: any) => {
  return (
    <Box width={{ base: "100%", md: "25em" }}>
      <Text
        display="flex"
        alignItems="center"
        justifyContent="flex-end !important"
        fontWeight="400"
        fontSize="12px"
        lineHeight="18px"
        color="#000000"
        opacity="0.5"
      >
        {step}/6
      </Text>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Text
          fontWeight="500"
          fontSize="14px"
          lineHeight="21px"
          color="#000000"
          paddingTop="8px"
        >
          {title}
        </Text>

        {step !== "6" ? (
          <Text
            fontWeight="400"
            fontSize="14px"
            lineHeight="24px"
            color="#49484D"
            paddingTop="10px"
            width="76%"
          >
            {content}
          </Text>
        ) : (
          <>
            <Text
              fontWeight="400"
              fontSize="14px"
              lineHeight="24px"
              color="#49484D"
              paddingTop="10px"
              width="76%"
            >
              If you need more help, send an email to
            </Text>
            <Link
              href="mailto:support@metricks.io"
              isExternal
              color="#7211D4"
              fontWeight="medium"
              fontSize="12px"
              lineHeight="18px"
              textDecoration="underline"
            >
              support@metricks.io
            </Link>
          </>
        )}
      </Box>
    </Box>
  );
};

export default StepsContent;
