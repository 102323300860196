import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { MdArrowForward, MdClose, MdKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useCurrentAffiliateOrg } from "../../../../hooks/useCurrentOrg";
import useUserToken from "../../../../hooks/useUserToken";
import PaginationComponent from "../../../explore/affiliates/paginationWrapper";
import Filter from "./Filter";
import Fuse from "fuse.js";
import { FaRegImage } from "react-icons/fa";

const ExploreCampagins = () => {
  const navigate = useNavigate();
  const [filterData, setFilterData] = useState<any>({
    product: [],
    niche: [],
  });
  const [paginationData, setpaginationData] = useState({
    currentPage: 1,
    pageSize: 6,
  });

  const [searchVal, setSearchVal] = useState<string>("");
  const [fetchedCampaigns, setfetchedCampaigns] = useState<any[]>([]);

  const [customResults, setcustomResults] = useState<any[]>([]);
  const [paginatedCampaigns, setpaginatedCampaigns] = useState<any[]>([]);
  const [paginatedSearch, setpaginatedSearch] = useState<any[]>([]);
  const [paginatedfilter, setpaginatedfilter] = useState<any[]>([]);

  const [loading, setloading] = useState<boolean>(true);

  const token = useUserToken("affiliate");
  const [_, profileIsComplete] = useCurrentAffiliateOrg();

  const getOffers = async () => {
    let bearerAuth = token;
    await fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "explore/offers/recommended-campaigns",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setfetchedCampaigns(data.data.recommendedCampaigns);
        }

        setloading(false);
      })
      .catch((err) => {
        console.log("fetch campaigns => " + err);
        setloading(false);
      });
  };

  const filterValsExist = () => {
    if (!searchVal && !filterData.product.length && !filterData.niche.length)
      return false;

    return true;
  };

  const checkFilteredItems = (arr: any[]) => {
    let foundItems: any[] = [];

    if (
      filterData.product &&
      filterData.product.length &&
      !filterData.niche?.length
    ) {
      arr.forEach((item: any) => {
        if (filterData.product.includes(item?.product_type?.toUpperCase())) {
          foundItems = [...foundItems, item];
        }
      });
    } else if (
      filterData.niche &&
      filterData.niche.length &&
      !filterData.product?.length
    ) {
      arr.forEach((item: any) => {
        if (item.niche) {
          for (let i = 0; i < item.niche.length; i++) {
            if (filterData.niche.includes(item.niche[i])) {
              foundItems = [...foundItems, item];
              break;
            }
          }
        }
      });
    } else if (
      filterData.niche &&
      filterData.niche.length &&
      filterData.product &&
      filterData.product.length
    ) {
      let tempArr: any[] = [];

      arr.forEach((item: any) => {
        if (filterData.product.includes(item?.product_type?.toUpperCase())) {
          tempArr = [...tempArr, item];
        }
      });

      tempArr.forEach((item: any) => {
        if (item?.niche) {
          for (let i = 0; i < item.niche.length; i++) {
            if (filterData.niche.includes(item.niche[i])) {
              foundItems = [...foundItems, item];
              break;
            }
          }
        }
      });
    }

    return foundItems;
  };

  const getPageData = () => {
    if (!fetchedCampaigns.length) return;

    setpaginatedCampaigns([]);
    setpaginatedSearch([]);
    setpaginatedfilter([]);

    setloading(true);

    let startIndex = (paginationData.currentPage - 1) * paginationData.pageSize;
    let endIndex = startIndex + paginationData.pageSize;

    if (filterValsExist()) {
      if (searchVal) {
        setpaginatedSearch(
          customResults.filter(
            (item: any, index: number) =>
              index >= startIndex && index < endIndex
          )
        );
      } else {
        setpaginatedfilter(
          customResults.filter(
            (item: any, index: number) =>
              index >= startIndex && index < endIndex
          )
        );
      }
    } else {
      setpaginatedCampaigns(
        fetchedCampaigns.filter(
          (item: any, index: number) => index >= startIndex && index < endIndex
        )
      );
    }

    setloading(false);
  };

  const filterDataPresent = () => {
    if (filterData.product.length || filterData.niche.length) return true;

    return false;
  };

  const options = {
    keys: ["campaign_name"],
    useExtendedSearch: true,
  };

  const fuse = new Fuse(fetchedCampaigns, options);

  const checkToken = async () => {
    if (!token) return;

    await fetch(process.env.REACT_APP_API_ENDPOINT + `/auth/valid-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data?.valid) {
          return;
        } else {
          window.localStorage.clear();
          sessionStorage.removeItem("currentUserType");
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          navigate("/login");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (token) {
      checkToken();
    }
  }, [token]);

  useEffect(() => {
    getOffers();
  }, []);

  useEffect(() => {
    getPageData();
  }, [fetchedCampaigns, paginationData, customResults]);

  useEffect(() => {
    if (fetchedCampaigns.length) {
      setcustomResults(fuse.search(`'${searchVal}`));
    }
  }, [searchVal]);

  useEffect(() => {
    if (fetchedCampaigns.length) {
      setSearchVal("");
      setcustomResults(checkFilteredItems(fetchedCampaigns));
    }
  }, [filterData]);

  const {
    isOpen: onModalIsOpen,
    onOpen: onModalOpen,
    onClose,
  } = useDisclosure();

  const checkForNoResult = () => {
    if (loading) return false;

    if (!fetchedCampaigns.length) return true;
    if (searchVal && !paginatedSearch.length) return true;
    if (filterDataPresent() && !paginatedfilter.length) return true;

    return false;
  };

  const handleView = (item: any) => {
    if (!profileIsComplete) return onModalOpen();

    let bearerAuth = token;

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `promote-campaign/affiliate/campaigns?campaignId=${item?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data.campaigns.length > 0) {
          if (
            data.data.campaigns[0]?.status === "approved" ||
            data.data.campaigns[0]?.status === "suspended"
          ) {
            return navigate(
              `/affiliate-campaigns/view/${item.id}?ref=${data.data.campaigns[0]?.affiliateLink}`
            );
            // return navigate(
            //   `/affiliate-campaigns?id=${item.id}&status=approved`
            // );
          } else {
            return navigate(`/affiliate-explore/view/${item.id}`);
          }
        }
      })
      .catch((err) => {
        console.log("affiliate's campaigs => " + err);
      });

    return navigate(`/affiliate-explore/view/${item.id}`);
  };

  return (
    <Stack padding={{ base: "20px 19px", md: "36px 23px" }} bgColor="#F1F4FA">
      <Box
        display="flex"
        alignItems="center"
        cursor="pointer"
        onClick={() => navigate(`/affiliate-explore`)}
        mb="10px"
        pl={{ base: "0px", md: "40px" }}
      >
        <MdKeyboardBackspace color="#32325D" />
        <Text
          color="#32325D"
          lineHeight="40px"
          fontSize="0.9rem"
          fontWeight="500"
          pl="8px"
        >
          Back to the previous page
        </Text>
      </Box>

      <Box
        padding={{ base: "0px 0px 80px 0px", md: "24px 40px" }}
        bgColor={{ base: "transparent", md: "white" }}
      >
        <Text
          fontSize={{ base: "14px", md: "12px" }}
          textTransform="uppercase"
          lineHeight="18px"
          fontWeight={{ base: "500", md: "600" }}
          color="#32325D"
          mb="24px"
        >
          Offers you might like
        </Text>

        <Filter
          filterData={filterData}
          setfilterData={setFilterData}
          searchVal={searchVal}
          setSearchVal={setSearchVal}
        />

        {(filterData.product && filterData.product.length) ||
        (filterData.niche && filterData.niche.length) ? (
          <Box
            border="1px solid rgba(50, 50, 93, 0.00)"
            width="100%"
            padding="8px 20px"
            borderRadius="8px"
            marginBottom="32px"
            marginTop="6px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            bgColor="rgba(50, 50, 93, 0.06)"
          >
            <Box>
              <Flex alignItems="center">
                {filterData.product.map((item: any) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    color="#344054"
                    mr="6px"
                    bg="#F0F5FF"
                    borderRadius="16px"
                    padding="2px 8px 2px 6px"
                  >
                    <MdClose
                      onClick={() => {
                        setFilterData({
                          ...filterData,
                          product: filterData.product.filter(
                            (x: string) => x !== item
                          ),
                        });
                      }}
                      cursor="pointer"
                    />
                    <Text fontSize="12px" fontWeight="500" ml="4px">
                      {item}
                    </Text>
                  </Box>
                ))}
              </Flex>

              <Flex alignItems="center" mt="12px" flexWrap="wrap" gap="6px">
                {filterData.niche.map((item: any) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    color="#344054"
                    bg="#F0F5FF"
                    borderRadius="16px"
                    padding="2px 8px 2px 6px"
                  >
                    <MdClose
                      onClick={() => {
                        setFilterData({
                          ...filterData,
                          niche: filterData.niche.filter(
                            (x: string) => x !== item
                          ),
                        });
                      }}
                      cursor="pointer"
                    />
                    <Text fontSize="12px" fontWeight="500" ml="4px">
                      {item}
                    </Text>
                  </Box>
                ))}
              </Flex>
            </Box>

            <Button
              bgColor="#F7F7F7"
              color="#EC635C"
              fontSize="0.75rem"
              fontWeight="medium"
              display="flex"
              alignItems="center"
              onClick={() => setFilterData({ product: [], niche: [] })}
            >
              <Text marginRight="3px">Cancel Filters</Text>

              <MdClose fontSize="16px" />
            </Button>
          </Box>
        ) : (
          ""
        )}

        {searchVal && (
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
            gap={{ base: "16px", md: "18px" }}
            mb="21px"
          >
            {paginatedSearch?.map((item: any) => {
              return (
                <GridItem
                  key={item.item.id}
                  background="#FFFFFF"
                  boxShadow=" 0px 0px 20px rgba(0, 0, 0, 0.08)"
                  borderRadius="8px"
                  padding="15px"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    filter="auto"
                    blur={profileIsComplete ? "0px" : "4px"}
                  >
                    {item.item?.banner_image ? (
                      <Image
                        height="35px"
                        width="35px"
                        borderRadius="50%"
                        src={item.item?.banner_image}
                      />
                    ) : (
                      <FaRegImage color="#b8b0ef" size={20} />
                    )}
                    <Text
                      fontWeight="500"
                      fontSize="14px"
                      color="#32325D"
                      lineHeight="21px"
                      ml="15px"
                      textTransform="capitalize"
                    >
                      {item.item?.campaign_name}
                    </Text>
                  </Box>

                  <Image
                    height="201px"
                    mt="19px"
                    mb="15px"
                    width="100%"
                    objectFit="cover"
                    borderRadius="6px"
                    src={
                      item.item.background_image
                        ? item.item.background_image
                        : `https://metricksassets.s3.us-east-2.amazonaws.com/development/default/760d200f-31f2-4e6a-aa21-cad8038f4eae.png`
                    }
                  />

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    borderBottom=" 1px solid rgba(50, 50, 93, 0.3)"
                    pb="13px"
                    mb="8px"
                  >
                    <Box
                      width="50%"
                      borderRight=" 1px solid rgba(50, 50, 93, 0.3)"
                    >
                      <Text
                        fontWeight="500"
                        fontSize="14px"
                        color="#32325D"
                        lineHeight="21px"
                        textTransform="capitalize"
                      >
                        {item.item?.product_type === "saas"
                          ? item.item.product_type.slice(0, 3) +
                            item.item.product_type.slice(3).toUpperCase()
                          : item.item.product_type}
                      </Text>
                      <Text
                        fontWeight="400"
                        fontSize="11px"
                        color="rgba(50, 50, 93, 0.6)"
                        lineHeight="16px"
                      >
                        Category
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        fontWeight="500"
                        fontSize="14px"
                        color="#32325D"
                        lineHeight="21px"
                      >
                        {item?.item.commission_type === "percentage"
                          ? "%"
                          : "$"}
                        {`${
                          item?.item.commission_type === "percentage"
                            ? item?.item.commission_percentage || 0
                            : item?.item.commission_amount || 0
                        }`}
                      </Text>
                      <Text
                        fontWeight="400"
                        fontSize="11px"
                        color="rgba(50, 50, 93, 0.6)"
                        lineHeight="16px"
                      >
                        Commission
                      </Text>
                    </Box>
                  </Box>

                  <Button
                    bgColor="#F0F3F9"
                    border=" 0.5px solid #E5E4E4"
                    borderRadius="8px"
                    width="100%"
                    onClick={() => handleView(item.item)}
                  >
                    <Text
                      fontWeight="500"
                      fontSize={{ base: "14px", md: "16px" }}
                      lineHeight="21px"
                      color=" #7211D4"
                      mr="7px"
                    >
                      View
                    </Text>

                    <MdArrowForward color="#7211D4" size={16} />
                  </Button>
                </GridItem>
              );
            })}
          </Grid>
        )}

        {!filterValsExist() && (
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
            gap={{ base: "16px", md: "18px" }}
            mb="21px"
          >
            {paginatedCampaigns?.map((item: any) => {
              return (
                <GridItem
                  key={item.id}
                  background="#FFFFFF"
                  boxShadow=" 0px 0px 20px rgba(0, 0, 0, 0.08)"
                  borderRadius="8px"
                  padding="15px"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    filter="auto"
                    blur={profileIsComplete ? "0px" : "4px"}
                  >
                    {item?.banner_image ? (
                      <Image
                        height="35px"
                        width="35px"
                        borderRadius="50%"
                        src={item?.banner_image || ""}
                      />
                    ) : (
                      <FaRegImage color="#b8b0ef" size={20} />
                    )}
                    <Text
                      fontWeight="500"
                      fontSize="14px"
                      color="#32325D"
                      lineHeight="21px"
                      ml="15px"
                      textTransform="capitalize"
                    >
                      {item?.campaign_name}
                    </Text>
                  </Box>

                  <Image
                    height="201px"
                    mt="19px"
                    mb="15px"
                    width="100%"
                    objectFit="cover"
                    borderRadius="6px"
                    src={
                      item.background_image
                        ? item.background_image
                        : `https://metricksassets.s3.us-east-2.amazonaws.com/development/default/760d200f-31f2-4e6a-aa21-cad8038f4eae.png`
                    }
                  />

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    borderBottom=" 1px solid rgba(50, 50, 93, 0.3)"
                    pb="13px"
                    mb="8px"
                  >
                    <Box
                      width="50%"
                      borderRight=" 1px solid rgba(50, 50, 93, 0.3)"
                    >
                      <Text
                        fontWeight="500"
                        fontSize="14px"
                        color="#32325D"
                        lineHeight="21px"
                        textTransform="capitalize"
                      >
                        {item?.product_type === "saas"
                          ? item.product_type.slice(0, 3) +
                            item.product_type.slice(3).toUpperCase()
                          : item.product_type}
                      </Text>
                      <Text
                        fontWeight="400"
                        fontSize="11px"
                        color="rgba(50, 50, 93, 0.6)"
                        lineHeight="16px"
                      >
                        Category
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        fontWeight="500"
                        fontSize="14px"
                        color="#32325D"
                        lineHeight="21px"
                      >
                        {item.commission_type === "percentage" ? "%" : "$"}
                        {`${
                          item.commission_type === "percentage"
                            ? item.commission_percentage || 0
                            : item.commission_amount || 0
                        }`}
                      </Text>
                      <Text
                        fontWeight="400"
                        fontSize="11px"
                        color="rgba(50, 50, 93, 0.6)"
                        lineHeight="16px"
                      >
                        Commission
                      </Text>
                    </Box>
                  </Box>

                  <Button
                    bgColor="#F0F3F9"
                    border=" 0.5px solid #E5E4E4"
                    borderRadius="8px"
                    width="100%"
                    onClick={() => handleView(item)}
                  >
                    <Text
                      fontWeight="500"
                      fontSize={{ base: "14px", md: "16px" }}
                      lineHeight="21px"
                      color=" #7211D4"
                      mr="7px"
                    >
                      View
                    </Text>

                    <MdArrowForward color="#7211D4" size={16} />
                  </Button>
                </GridItem>
              );
            })}
          </Grid>
        )}

        {filterDataPresent() && (
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
            gap={{ base: "16px", md: "18px" }}
            mb="21px"
          >
            {paginatedfilter.map((item: any) => {
              return (
                <GridItem
                  key={item.id}
                  background="#FFFFFF"
                  boxShadow=" 0px 0px 20px rgba(0, 0, 0, 0.08)"
                  borderRadius="8px"
                  padding="15px"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    filter="auto"
                    blur={profileIsComplete ? "0px" : "4px"}
                  >
                    {item?.banner_image ? (
                      <Image
                        height="35px"
                        width="35px"
                        borderRadius="50%"
                        src={item?.banner_image || ""}
                      />
                    ) : (
                      <FaRegImage color="#b8b0ef" size={20} />
                    )}

                    <Text
                      fontWeight="500"
                      fontSize="14px"
                      color="#32325D"
                      lineHeight="21px"
                      ml="15px"
                      textTransform="capitalize"
                    >
                      {item?.campaign_name}
                    </Text>
                  </Box>

                  <Image
                    height="201px"
                    mt="19px"
                    mb="15px"
                    width="100%"
                    objectFit="cover"
                    borderRadius="6px"
                    src={
                      item.background_image
                        ? item.background_image
                        : `https://metricksassets.s3.us-east-2.amazonaws.com/development/default/760d200f-31f2-4e6a-aa21-cad8038f4eae.png`
                    }
                  />

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    borderBottom=" 1px solid rgba(50, 50, 93, 0.3)"
                    pb="13px"
                    mb="8px"
                  >
                    <Box
                      width="50%"
                      borderRight=" 1px solid rgba(50, 50, 93, 0.3)"
                    >
                      <Text
                        fontWeight="500"
                        fontSize="14px"
                        color="#32325D"
                        lineHeight="21px"
                        textTransform="capitalize"
                      >
                        {item?.product_type === "saas"
                          ? item.product_type.slice(0, 3) +
                            item.product_type.slice(3).toUpperCase()
                          : item.product_type}
                      </Text>
                      <Text
                        fontWeight="400"
                        fontSize="11px"
                        color="rgba(50, 50, 93, 0.6)"
                        lineHeight="16px"
                      >
                        Category
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        fontWeight="500"
                        fontSize="14px"
                        color="#32325D"
                        lineHeight="21px"
                      >
                        {item.commission_type === "percentage" ? "%" : "$"}
                        {`${
                          item.commission_type === "percentage"
                            ? item.commission_percentage || 0
                            : item.commission_amount || 0
                        }`}
                      </Text>
                      <Text
                        fontWeight="400"
                        fontSize="11px"
                        color="rgba(50, 50, 93, 0.6)"
                        lineHeight="16px"
                      >
                        Commission
                      </Text>
                    </Box>
                  </Box>

                  <Button
                    bgColor="#F0F3F9"
                    border=" 0.5px solid #E5E4E4"
                    borderRadius="8px"
                    width="100%"
                    onClick={() => handleView(item)}
                  >
                    <Text
                      fontWeight="500"
                      fontSize={{ base: "14px", md: "16px" }}
                      lineHeight="21px"
                      color=" #7211D4"
                      mr="7px"
                    >
                      View
                    </Text>

                    <MdArrowForward color="#7211D4" size={16} />
                  </Button>
                </GridItem>
              );
            })}
          </Grid>
        )}

        {checkForNoResult() && (
          <Box
            w="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p={{ base: "0px 16px", md: "0px" }}
            mb="21px"
          >
            <Image
              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
              height={{ base: "150px", md: "250px" }}
              objectFit="contain"
              mb="8px"
            />

            <Text
              fontWeight="500"
              fontSize="0.9rem"
              lineHeight="21px"
              color="#32325D"
              textAlign="center"
            >
              No campaigns found
            </Text>
          </Box>
        )}

        <PaginationComponent
          paginationData={paginationData}
          setPaginationData={setpaginationData}
          dataLength={
            filterValsExist() ? customResults.length : fetchedCampaigns.length
          }
        />

        <Modal onClose={onClose} size="lg" isCentered isOpen={onModalIsOpen}>
          <ModalOverlay />
          <ModalContent borderRadius="10px">
            <ModalHeader display="flex" justifyContent="right">
              <Image
                onClick={onClose}
                cursor="pointer"
                ml="7px"
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/3b803641-20b6-4e47-b554-8336ebd3ec5b.svg"
              />
            </ModalHeader>

            <ModalBody
              paddingBottom="26px"
              borderBottom="1px solid rgba(50, 50, 93, 0.3)"
            >
              <Text
                fontWeight="500"
                fontSize="14px"
                lineHeight="30px"
                color=" rgba(50, 50, 93, 0.8)"
                textAlign="center"
              >
                Complete your organisation profile to view this campaign
              </Text>
            </ModalBody>

            <ModalFooter display="flex" justifyContent="left">
              <Button
                ml="15px"
                bgColor=" #F0F5FF"
                color="#5C14CE"
                borderRadius="8px"
                onClick={onClose}
              >
                Proceed
                <Image
                  cursor="pointer"
                  ml="10px"
                  mt="1px"
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/37d9e26a-0ec4-462d-98ac-8fca88493c0b.svg"
                />
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Stack>
  );
};

export default ExploreCampagins;
