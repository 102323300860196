import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
// import "./style.css";
import React, { useState, useEffect } from "react";
// import { FormInput } from "../formInput";s
import { Link, useNavigate } from "react-router-dom";
import Container from "../../reset-password/container";
// import { usePutRequest } from "../../hooks/usePutRequest";
// import { ToastContainer, toast } from "react-toastify";
// import { forgotState } from "../../state/login";
// import { useRecoilState } from "recoil";
// import { BaseButton } from "../button";
import { IoArrowForwardSharp } from "react-icons/io5";
import { ToastContainer } from "react-toastify";
import { FormInput } from "../../formInput";
import { BaseButton } from "../../button";
import { FiUploadCloud } from "react-icons/fi";
import { endpoints } from "../../../utils/endpoints";

const UploadAsset = () => {
  //   const [putHandler, serverResponse, serverError] = usePutRequest();
  //   const [text, setText] = useRecoilState(forgotState);
  const [loading, setLoading] = useState(false);
  const [url, seturll] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  let navigate = useNavigate();

  const submitHandler = async (files: any) => {
    const formData = new FormData();
    // console.log("files", files[0]);

    // @ts-ignore
    formData.append("photo", files[0]); // 'file' + i is just an

    // @ts-ignore
    setName(files[0].name);
    formData.append("name", files[0].name);

    setLoading(true);
    try {
      await fetch(endpoints.photo.UPLOADASSET, {
        method: "POST",
        //   headers: {
        //     Authorization: `Bearer ${getCookie("access_token")}`,
        //   },
        body: formData,
      })
        .then((res) => res.json())
        .then((data: any) => {
          if (data.success) {
            seturll(data.data.url);
            console.log("data", data);
            setError("success");
          } else {
            setError(data.message);
            console.log("data", data);
            seturll("");
          }
          setLoading(false);
          // navigate("/dashboard");
        });
    } catch (e) {
      console.log(e);
      // @ts-ignore
      setError("error", e);
      // @ts-ignore
      console.log(e);
      setLoading(false);
    }

    setLoading(false);
  };
  return (
    <>
      <ToastContainer />
      <Container>
        <Box
          display="flex"
          width="100%"
          height="100vh"
          overflow="hidden"
          left="0"
          bottom="0"
          zIndex="10"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          bgColor="#f0f5ff"
        >
          <Image src="/assets/logo.png" mb="1.5em" height="70px" />
          <Heading
            fontWeight="700"
            color="#000000"
            fontSize="32px"
            lineHeight="60px"
          >
            UPLOAD ASSETS HERE
          </Heading>

          <Box width={["90%", "50%"]}>
            <form
              // onSubmit={sumbitHandler}
              encType="multipart/form-data"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
                width: "100%",
                height: "70vh",
              }}
              className="bigger"
            >
              {/* <Box width="100%">
                <FormInput
                  label="Name of image"
                  type="email"
                  value={email}
                  stateHandler={setEmail}
                  required={true}
                  holder="example@gmail.com"
                />
              </Box> */}
              <Text>{name}</Text>
              <label htmlFor="asset">
                <input
                  type="file"
                  //   accept="image/png, image/jpeg"
                  multiple={false}
                  id="asset"
                  accept="image/png, image/gif, image/jpeg"
                  style={{ display: "none" }}
                  onChange={(e: React.ChangeEvent<any>) => {
                    // console.log("check me", e.target.files[0]);
                    setName(e.target.files[0].name);
                    setError("");
                    submitHandler(e.target.files);
                  }}
                />

                <Flex
                  cursor="pointer"
                  bgColor="#F2E5FF"
                  color="gray.700"
                  fontWeight="medium"
                  fontSize="14px"
                  px="1.5em"
                  display="flex"
                  alignItems="center"
                  py=".9em"
                  borderRadius=".4em"
                  w="100%"
                  justifyContent="center"
                >
                  <FiUploadCloud size={"20px"} color="#5C14CE" />

                  <Text ml=".5em" fontWeight="medium">
                    {loading ? "Uploading..." : "Upload"}
                  </Text>
                </Flex>
              </label>
            </form>
            <Flex
              w={"100%"}
              justifyContent="center"
              flexDirection={"column"}
              align={"center"}
            >
              <Text color={"red"}>{error}</Text>
              <Link to={url}>
                <Text textAlign={"center"}>{url}</Text>
              </Link>
            </Flex>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default UploadAsset;
