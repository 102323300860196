import React from "react";
import Dashboard from "../components/dashboard";
const Landing = () => {
  return (
    <>
      <Dashboard />
    </>
  );
};

export default Landing;
