import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useCountries } from "../../../../hooks/useCountries";
import { getUrlQueryString } from "../../../../services/urlQuery";
import { getList } from "country-list-with-dial-code-and-flag";
import SettingsInput from "./SettingsInput";
import { FormInput } from "../../../formInput";
import OrganizationSettingsFooter from "./OrganizationSettingsFooter";

const AffiliateOrgAddress = ({
  orgCountry,
  orgAddress,
  orgState,
  orgCity,
  orgZipCode,
  setRefreshData,
}: {
  orgCountry: string;
  orgAddress: string;
  orgState: string;
  orgCity: string;
  orgZipCode: string;
  setRefreshData: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [allCountries] = useCountries();
  const [country, setCountry] = useState(
    orgCountry
      ? orgCountry
      : getUrlQueryString("default-country")
      ? getUrlQueryString("default-country")
      : ""
  );
  const [address, setAddress] = useState(orgAddress);
  const [localState, setLocalState] = useState(orgState);
  const [city, setCity] = useState(orgCity);
  const [zipCode, setZipCode] = useState(orgZipCode);

  const [newUpdate, setnewUpdate] = useState(false);
  const [count, setcount] = useState(1);

  useEffect(() => {
    if (count > 1) {
      setnewUpdate(true);
      sessionStorage.setItem("affiliateSettingsChange", "new update");
    }
    setcount(count + 1);
  }, [country, address, localState, city, zipCode]);

  const updateData = {
    country,
    address,
    state:
      allCountries && allCountries[0].country !== "" && localState === ""
        ? allCountries.find(
            (singleCountry: { country: string; state: string[] }) =>
              singleCountry.country === country
          )?.states[0]
        : localState,
    city,
    zip_code: zipCode,
  };

  const validator = () => {
    if (!country)
      return { isValid: false, error: "Organization's Country is Required" };
    if (!address)
      return { isValid: false, error: "Organization's Address is Required" };
    if (!localState) {
      return { isValid: false, error: "Organization's State is Required" };
    }
    if (!city) {
      return { isValid: false, error: "Organization's City is Required" };
    }
    if (!zipCode) {
      return { isValid: false, error: "Organization's Zip Code is Required" };
    }
    return { isValid: true, error: "" };
  };

  return (
    <Box>
      <Stack w="full">
        <Box
          display="flex"
          alignItems="center"
          columnGap="1em"
          my="1em"
          flexDirection={["column", "row"]}
          borderTop={["0px", "0.5px solid"]}
          borderTopColor={{ md: "rgba(50, 50, 93, 0.30)" }}
          py={[".5em", "1em"]}
        >
          <Text
            fontSize="0.9rem"
            color="#32325D"
            fontWeight="500"
            width={{ base: "100%", md: "30%" }}
            textAlign="left"
            mb={[".3em", "0em"]}
          >
            Country <span style={{ color: "#dc3545" }}>*</span>
          </Text>

          <Flex
            alignItems="center"
            width={{ base: "100%", md: "70%" }}
            _focusWithin={{ ring: "0", borderColor: "#5C14CE" }}
            border="0.6px solid rgba(50, 50, 93, 0.30)"
            padding="12px 0px 15px 20px"
            height="48px"
            maxWidth="572px"
            borderRadius="8px"
          >
            <Box>
              {getList().find((info: any) => info.name === country)?.flag || ""}
            </Box>
            <Select
              _focus={{ ring: "0", border: "0px" }}
              border="0px"
              color="#32325D"
              fontWeight="400"
              padding="0"
              fontSize="14px"
              // @ts-ignore
              value={country}
              onChange={(e: React.ChangeEvent<any>) =>
                setCountry(e.target.value)
              }
            >
              {getList()
                .map((data: any) => data.name)
                .map((name: string, i: any) => {
                  return (
                    <option key={i} value={name}>
                      {name}
                    </option>
                  );
                })}
            </Select>
          </Flex>
        </Box>

        <SettingsInput
          label="Address"
          type="text"
          value={address}
          changeHandler={setAddress}
          isRequired={true}
        />

        {allCountries && allCountries[0].country !== "" && (
          <SettingsInput
            label="State"
            type="select"
            value={localState}
            changeHandler={setLocalState}
            isRequired={true}
            options={
              allCountries && allCountries[0].country !== ""
                ? allCountries.find(
                    (singleCountry: { country: string; state: string[] }) =>
                      singleCountry.country === country
                  )?.states
                : [""]
            }
          />
        )}

        <SettingsInput
          label="City"
          type="text"
          value={city}
          changeHandler={setCity}
          isRequired={true}
        />

        <SettingsInput
          label="Zip Code"
          type="text"
          value={zipCode}
          changeHandler={setZipCode}
          isRequired={true}
        />
      </Stack>

      <OrganizationSettingsFooter
        hasUpdate={newUpdate}
        sethasUpdate={setnewUpdate}
        dataToUpdate={updateData}
        setRefreshData={setRefreshData}
        validator={validator}
      />
    </Box>
  );
};

export default AffiliateOrgAddress;
