import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";

const ReferMetricksGuide = () => {
  const guides = [
    "A visitor clicks on your affiliate link.",
    "The IP address of visitors is tracked, and a cookie is stored in their browser for this purpose.",
    "The site is being browsed by the visitor.",
    "Assuming the visitor subscribes for one of our plans right away, the subscription will be registered as a sale, and you will receive a 20% commission. If more than one affiliates are involved in this conversion, we would divide the commission between them.",
  ];
  return (
    <Stack mt="50px" px={{ base: "14px", md: "40px" }}>
      <Text fontSize="24px" lineHeight="38px" fontWeight="600" color="#32325D">
        How Does It Work?
      </Text>

      <Text
        fontSize="14px"
        lineHeight="24px"
        fontWeight="400"
        mt="8px"
        mb="4px"
        color="#32325D"
      >
        We take care of everything, including hosting the products and providing
        customer service, and you earn money by referring people to our
        websites. This is how it functions:
      </Text>

      <Stack>
        {guides.map((guide: string) => (
          <Flex mt="13px">
            <Box bgColor="#7211D4" h="14px" w="14px" borderRadius="full"></Box>
            <Text
              ml="12px"
              fontSize="14px"
              lineHeight="24px"
              fontWeight="400"
              color="#32325D"
              maxWidth="600px"
              w={{ base: "80%", md: "auto" }}
            >
              {guide}
            </Text>
          </Flex>
        ))}
      </Stack>
    </Stack>
  );
};

export default ReferMetricksGuide;
