import {
  Box,
  Button,
  Circle,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from "react";
import { RiSearchLine } from "react-icons/ri";
import Fuse from "fuse.js";
import {
  MdChevronRight,
  MdKeyboardBackspace,
  MdOutlineAddBox,
} from "react-icons/md";
import Iframe from "react-iframe";
import { useNavigate } from "react-router-dom";
import useUserToken from "../../../../../hooks/useUserToken";
import AssetFolder from "../../../../affiliate/campaigns/view-campaigns/tabs/assets/AssetFolder";
import { endpoints } from "../../../../../utils/endpoints";
import { AiFillFile, AiFillFileWord } from "react-icons/ai";
import { FaFilePdf } from "react-icons/fa";
import { BsFiletypeDoc } from "react-icons/bs";
import { SlOptions } from "react-icons/sl";
import { apiReqHandler } from "../../../../../helper/apiReqHandler";
import { getCookie } from "cookies-next";
import { FiUploadCloud } from "react-icons/fi";
import { HiOutlineCloudArrowUp } from "react-icons/hi2";

const CampaignAssets = ({ campaignId }: { campaignId: string | undefined }) => {
  const token = useUserToken();

  const [searchVal, setSearchVal] = useState<string>("");
  // const [paginationData, setpaginationData] = useState({
  //   currentPage: 1,
  //   pageSize: 8,
  // });
  const [fetchedFolders, setfetchedFolders] = useState<any[]>([]);
  const [paginatedFolders, setpaginatedFolders] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedFolder, setselectedFolder] = useState<any>(null);
  const [folderAssets, setfolderAssets] = useState<any>([]);
  const [newFolderName, setnewFolderName] = useState<string>("");
  const [isCreatingFolder, setisCreatingFolder] = useState(false);
  const [newFolderAdded, setnewFolderAdded] = useState(false);
  const [isUploadingFile, setisUploadingFile] = useState<boolean>(false);

  const filesRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const options = {
    keys: ["folderName"],
  };

  const fuse = new Fuse(fetchedFolders, options);

  const genColor = () => {
    const value: number = Math.floor(Math.random() * 4);

    if (value === 0) {
      return "rgba(246, 173, 0, 1)";
    } else if (value === 1) {
      return "rgba(72, 118, 249, 1)";
    } else if (value === 2) {
      return "rgba(50, 189, 122, 1)";
    } else {
      return "rgba(250, 78, 78, 1)";
    }
  };

  const openFiles = () => {
    if (filesRef.current) {
      filesRef.current.click();
    }
  };

  const FetchAllCampaignFolders = () => {
    let bearerAuth = token;

    setLoading(true);

    // @ts-ignore
    fetch(endpoints.drive.NEWFOLDER.ALL(campaignId), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          console.log(data);
          setfetchedFolders(data.data);
          setLoading(false);
        } else {
          console.log(data);
          // formViewRef.current.scrollIntoView();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("get campaign => " + err);
        setLoading(false);
      });
  };
  function endsWithExtension(url: any, extension: any) {
    return url.toLowerCase().endsWith(extension);
  }
  const toast = useToast();

  const createNewFolder = () => {
    if (!newFolderName)
      return toast({
        description: "Please enter a folder name",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

    setisCreatingFolder(true);
    let bearerAuth = token;

    const payload = {
      folder_name: newFolderName,
      // campaignId: Number(campaignId),
    };

    fetch(endpoints.drive.NEWFOLDER.CREATE(campaignId), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setisCreatingFolder(false);
          setnewFolderAdded(!newFolderAdded);
          setnewFolderName("");
          onClose();

          return toast({
            title: `${newFolderName} has been added successfully`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          setisCreatingFolder(false);
          return toast({
            title: `Failed to add new folder`,
            description: data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      });
  };

  const handleChange = (files: FileList | null) => {
    if (files) {
      let file = files[0];

      setisUploadingFile(true);
      let bearerAuth = token;

      const formData = new FormData();
      formData.append("asset", file);
      formData.append("folder_id", selectedFolder.id);
      // console.log(selectedFolder.id);

      fetch(endpoints.drive.NEWFOLDER.UPLOAD(campaignId), {
        method: "POST",
        headers: {
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setisUploadingFile(false);
            setnewFolderAdded(!newFolderAdded);
            fetchAssets(selectedFolder);
            return toast({
              title: "New file has been uploaded successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
          } else {
            setisUploadingFile(false);
            fetchAssets(selectedFolder);
            return toast({
              title: "Failed to upload file",
              description: data.message,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          setisUploadingFile(false);
          return toast({
            title: "Failed to upload file",
            description: err.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        });
    }
  };

  useEffect(() => {
    FetchAllCampaignFolders();
  }, [newFolderAdded]);

  useEffect(() => {
    if (campaignId) {
      //   let startIndex =
      //     (paginationData.currentPage - 1) * paginationData.pageSize;
      //   let endIndex = startIndex + paginationData.pageSize;

      if (searchVal) {
        setpaginatedFolders(
          fuse.search(searchVal).map((item) => {
            return item.item;
          })
          // .filter((item, index) => index >= startIndex && index < endIndex)
        );
      } else {
        setpaginatedFolders(
          fetchedFolders
          //   .filter(
          //     (item, index) => index >= startIndex && index < endIndex
          //   )
        );
      }
      setLoading(false);
    }
  }, [fetchedFolders, searchVal]);

  const fetchAssets = async (folder: any) => {
    // console.log(folder.campaignId, folder.id);
    setisUploadingFile(true);
    const { error, res } = await apiReqHandler({
      endPoint: endpoints.drive.NEWFOLDER.FOLDERASSETS(
        folder.campaignId,
        folder.id
      ),
      method: "GET",
      //   @ts-ignore
      bearerAuth: getCookie("access_token"),
    });
    setfolderAssets(res?.data.data.folder.folderAssets || []);
    setisUploadingFile(false);
    console.log(res?.data.data.folder.folderAssets);
  };

  const checkForNoResult = () => {
    if (!fetchedFolders.length && !loading) return true;
    if (searchVal && !paginatedFolders.length) return true;
    return false;
  };

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        h="40vh"
        alignItems="center"
        pt="20px"
      >
        <Image className="loading" src="/assets/logo.png" height="60px" />
        <Text
          fontWeight="400"
          fontSize="16px"
          lineHeight="70px"
          color="#000000"
          mt="-15px"
        >
          Loading Advertiser Table...
        </Text>
      </Box>
    );

  return (
    <Stack pb="100px">
      {selectedFolder && (
        <Box
          display={{ base: "flex", md: "none" }}
          alignItems="center"
          cursor="pointer"
          mb="18px"
          onClick={() => setselectedFolder(null)}
        >
          <MdKeyboardBackspace color="#32325D" />
          <Text
            color="#7211D4"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            pl="8px"
          >
            Back
          </Text>
        </Box>
      )}

      <Box
        mt={{ base: "19px", md: "32px" }}
        borderBottom="0.5px solid rgba(50, 50, 93, 0.3)"
        mb={{ base: "16px", md: "34px" }}
        pb="21px"
        display="flex"
        alignItems={{ base: "start", md: "center" }}
        flexDirection={{ base: "column", md: "row" }}
        justifyContent={selectedFolder ? "space-between" : "start"}
      >
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          alignItems={{ base: "start", md: "center" }}
        >
          <HStack
            spacing="8px"
            mr={{ base: "0px", md: "8px" }}
            mb={{ base: "21px", md: "0px" }}
          >
            <Text
              color={selectedFolder ? "rgba(50, 50, 93, 0.5)" : "#32325D"}
              fontSize="14px"
              fontWeight={selectedFolder ? "500" : "500"}
              lineHeight="21px"
              cursor={selectedFolder ? "pointer" : "default"}
              _hover={{
                textDecoration: `${selectedFolder ? "underline" : "none"}`,
              }}
              onClick={() => setselectedFolder(null)}
            >
              All Folders
            </Text>

            {selectedFolder && (
              <MdChevronRight size={20} color="rgba(50, 50, 93, 0.5)" />
            )}

            {selectedFolder && (
              <Text
                fontSize="14px"
                fontWeight="600"
                lineHeight="21px"
                color="#32325D"
              >
                {selectedFolder.folderName}
              </Text>
            )}
          </HStack>

          {!selectedFolder && (
            <InputGroup
              w={{ base: "100%", md: "280px" }}
              ml={{ base: "0px", md: "26px" }}
            >
              <InputLeftElement
                pointerEvents="none"
                children={<RiSearchLine color="rgba(50, 50, 93, 0.8)" />}
              />
              <Input
                css={{
                  border: "0.5px solid rgba(50, 50, 93, 0.4)",
                }}
                type="text"
                value={searchVal}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchVal(e.target.value)
                }
                fontSize="16px"
                borderRadius={{ base: "100px", md: "8px" }}
                bg={{ base: "white", md: "transparent" }}
                focusBorderColor="#7211D4"
                placeholder="Search folders"
              />
            </InputGroup>
          )}
        </Box>

        {selectedFolder ? (
          <Flex>
            <Link
              bgColor="transparent"
              p="8px 15px"
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight={{ base: "18px", md: "21px" }}
              fontWeight="500"
              color="rgba(50, 50, 93, 1)"
              borderRadius="8px"
              border="0.5px solid rgba(50, 50, 93, 0.4)"
              textAlign="center"
              target="_blank"
              href={selectedFolder.drive_folder}
              _hover={{ textDecoration: "none", color: "rgba(50, 50, 93, 1)" }}
              display={"none"}
            >
              View in Google Drive
            </Link>

            <Button
              bgColor="#7211D4"
              color="white"
              borderRadius="8px"
              p="8px 15px"
              ml="16px"
              onClick={openFiles}
            >
              <MdOutlineAddBox fontSize={15} />
              <Text
                fontWeight="500"
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight={{ base: "18px", md: "21px" }}
                ml="7px"
              >
                Upload
              </Text>
            </Button>

            <input
              type="file"
              id="file"
              ref={filesRef}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e.target.files)
              }
              style={{ display: "none" }}
            />
          </Flex>
        ) : (
          <Button
            bgColor="#7211D4"
            color="white"
            p="8px 15px"
            mt={{ base: "21px", md: "0px" }}
            borderRadius="8px"
            ml={{ md: "auto" }}
            onClick={() =>
              navigate(
                `/campaigns/edit-campaign/marketing-assets?product="ecommerce"&campaign=${campaignId}`
              )
            }
          >
            <MdOutlineAddBox fontSize={15} />
            <Text
              fontWeight="500"
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight={{ base: "18px", md: "21px" }}
              ml="7px"
            >
              Add New Folder
            </Text>
          </Button>
        )}
      </Box>

      {selectedFolder ? (
        <Box>
          {isUploadingFile ? (
            <Spinner
              size="xl"
              color="purple.500"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
            />
          ) : (
            <Flex direction={"row"} w={"100%"} gap={5} flexWrap={"wrap"}>
              {folderAssets < 1 ? (
                <Flex
                  w={"100%"}
                  justify={"center"}
                  align={"center"}
                  direction={"column"}
                  gap={5}
                >
                  <Image
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
                    width="150px"
                    height="150px"
                  />
                  <Text fontWeight={"bold"}>No Top Ranked Affiliate Yet</Text>
                </Flex>
              ) : (
                folderAssets.map((item: any, i: any) => (
                  <Flex
                    key={i}
                    width={300}
                    borderWidth={1}
                    borderColor={"#c4c4c4c4"}
                    borderRadius={10}
                    align={"center"}
                    justify={"center"}
                    py={5}
                    direction={"column"}
                    gap={5}
                  >
                    <Flex
                      width={260}
                      borderWidth={1}
                      borderColor={"#c4c4c4c4"}
                      borderRadius={10}
                      align={"center"}
                      justify={"center"}
                      h={120}
                      overflow={"hidden"}
                      cursor={"pointer"}
                    >
                      {/* @ts-ignore */}
                      {endsWithExtension(item.url, "png") ? (
                        <Image
                          // @ts-ignore
                          src={item.url}
                          w={"auto"}
                          objectFit={"cover"}
                          // @ts-ignore
                          onClick={() => window.open(item.url, "_blank")}
                        />
                      ) : //  @ts-ignore
                      endsWithExtension(item.url, "document") ? (
                        <AiFillFileWord
                          size={100}
                          color="#5C14CE"
                          // @ts-ignore
                          onClick={() => window.open(item.url, "_blank")}
                          cursor={"pointer"}
                        />
                      ) : //  @ts-ignore
                      endsWithExtension(item.url, "pdf") ? (
                        <FaFilePdf
                          size={100}
                          color="#5C14CE"
                          // @ts-ignore
                          onClick={() => window.open(item.url, "_blank")}
                          cursor={"pointer"}
                        />
                      ) : (
                        <AiFillFile
                          size={100}
                          color="#5C14CE"
                          // @ts-ignore
                          onClick={() => window.open(item.url, "_blank")}
                          cursor={"pointer"}
                        />
                      )}
                    </Flex>
                    <Flex width={260} direction={"column"}>
                      <Flex gap={2} align={"baseline"}>
                        <BsFiletypeDoc
                          size={14}
                          color="#5C14CE"
                          fontWeight={"bold"}
                        />
                        {/* @ts-ignore */}
                        <Text fontWeight={"bold"}>{item.name}</Text>
                      </Flex>
                      <Flex justify={"space-between"} align={"center"}>
                        <Flex gap={2} direction={"column"}>
                          <Text fontWeight={"bold"}>File size</Text>
                          {/* @ts-ignore */}
                          <Text>{item.filesize}</Text>
                        </Flex>
                        <Circle
                          borderWidth={1}
                          borderColor={"#c4c4c4c4"}
                          size={10}
                          position={"relative"}
                          // @ts-ignore
                          // onClick={() => setSeleceted(item.url)}
                        >
                          <SlOptions
                            size={14}
                            color="#5C14CE"
                            // @ts-ignore
                            // onClick={() => setSeleceted(item.url)}
                          />
                          {/* @ts-ignore */}
                          {/* {selected === item.url && (
                          <Button
                            position={"absolute"}
                            p={2}
                            bg={"#c4c4c4c4"}
                            top={10}
                            variant={"solid"}
                            // @ts-ignore
                            onClick={() => deleteAsset(item.id)}
                          >
                            <Text>Delete</Text>
                          </Button>
                        )} */}
                        </Circle>
                      </Flex>
                    </Flex>
                  </Flex>
                ))
              )}
            </Flex>
          )}
        </Box>
      ) : paginatedFolders.length > 1 ? (
        <Flex justify={"center"} align={"center"} direction={"column"} gap={5}>
          <Image
            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
            height="250px"
            objectFit="contain"
            mb="16px"
          />
          <Text fontWeight={"bold"}>No Uploads yet</Text>
          {/* <Button
         leftIcon={
           <Image
             mr={{ base: "0px", md: "7px" }}
             src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/df57e3b5-fda6-4bb5-88aa-2420eb4a55ae.svg"
           />
         }
         color="#fff"
         bgColor="#5C14CE"
         onClick={() => navigate("/campaigns/create")}
         fontSize={{ base: "sm", md: "md" }}
       >
         Create Campaign
       </Button> */}
        </Flex>
      ) : (
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(4, 1fr)" }}
          gap={15}
          pb="21px"
          w={"100%"}
          // alignItems={"center"}
          // justifyContent={"center"}
        >
          {paginatedFolders.map((folder: any, i) => {
            return (
              <GridItem
                p="18px"
                cursor="pointer"
                borderRadius="8px"
                border="0.5px solid rgba(179, 179, 179, 1)"
                key={i}
                onClick={() => {
                  setselectedFolder(folder);
                  fetchAssets(folder);
                }}
              >
                <AssetFolder
                  color={genColor()}
                  title={folder?.folderName}
                  numberOfFiles={folder?.folderAssets.length}
                />
              </GridItem>
            );
          })}
        </Grid>
      )}

      {checkForNoResult() && (
        <Box
          w="100%"
          height="30vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={{ base: "0px 16px", md: "0px" }}
          mb="21px"
        >
          <Flex
            w={["100%", "40%"]}
            // borderColor={"#c4c4c4"}
            p={10}
            // borderWidth={1}
            direction={"column"}
            align={"center"}
            gap={5}
          >
            <Flex
              w={"100%"}
              justify={"center"}
              align={"center"}
              direction={"column"}
            >
              <HiOutlineCloudArrowUp size={"120px"} color="#7211d4" />
              <Text fontWeight={"500"} fontSize={15} textAlign={"center"}>
                Start by uploading a file
              </Text>
              <Text color={"#c4c4c4"} fontWeight={"400"} textAlign={"center"}>
                All assets uploaded here will be accessible by your approved
                affiliates
              </Text>
            </Flex>
            {/* <Button
              leftIcon={<FiUploadCloud />}
              // colorScheme="teal"
              variant="solid"
              w={"250px"}
              p={"20px"}
              _hover={{
                bg: "#c",
                borderColor: "PurplishBlue",
                color: "white",
              }}
              transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
              _active={{
                bg: "#7211d4",
                transform: "scale(0.98)",
                borderColor: "#7211d4",
              }}
              bgColor={"#7211d4"}
              color={"white"}
              onClick={onOpen}
            >
              Upload
            </Button> */}
          </Flex>
        </Box>
      )}

      <Modal isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create new Folder</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              focusBorderColor="purple.500"
              value={newFolderName}
              onChange={(e) => setnewFolderName(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              color="purple.500"
              isLoading={isCreatingFolder}
              onClick={createNewFolder}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* {!selectedFolder && (
        <PaginationComponent
          paginationData={paginationData}
          setPaginationData={setpaginationData}
          dataLength={
            searchVal ? paginatedFolders.length : fetchedFolders.length
          }
        />
      )} */}
    </Stack>
  );
};

export default CampaignAssets;
