import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Img,
  Heading,
} from "@chakra-ui/react";
import { useState } from "react";
import { BsSearch } from "react-icons/bs";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { supportedIntegrations } from "./utils";
import JsDetails from "./integrationLangComp/JsDetails";
import PeddleDetails from "./integrationLangComp/PeddleDetails";
import WixDetails from "./integrationLangComp/WixDetails";
import WooDetails from "./integrationLangComp/WooDetails";
interface IntegrationSchema {
  name: string;
  icon: string;
  color: string;
  bgColor: string;
}

const IntegrationModal = ({
  int,
  isOpen,
  onClose,
  setShowOptions,
}: {
  int: IntegrationSchema | null;
  isOpen: boolean;
  onClose: VoidFunction;
  setShowOptions: Function;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setShowOptions(false);
        onClose();
      }}
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent borderRadius="1.3em">
        <ModalHeader
          bgColor={int?.bgColor}
          borderTopRadius="1.1em"
          py="1em"
          mt="-.5em"
        >
          <Flex alignItems="center" columnGap=".3em">
            <Img
              src={int?.icon}
              objectFit="contain"
              alt={int?.name + " metricks integration"}
              height="30px"
            />

            <Text color={int?.color}>
              {int?.name?.toLowerCase() === "paddle"
                ? "Integration"
                : int?.name + " Integration"}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton
          borderRadius="full"
          bgColor="gray.100"
          top="1.5em"
          right="1.5em"
          outline="none"
          // display="flex"
          // alignSelf="center"
          // justifySelf="center"
        />
        {int?.name === "Javascript" ? (
          <JsDetails />
        ) : int?.name === "Paddle" ? (
          <PeddleDetails />
        ) : int?.name === "Wix" ? (
          <WixDetails />
        ) : (
          <WooDetails />
        )}
      </ModalContent>
    </Modal>
  );
};

const IntegrationOptCard = ({
  updateSelectedOption,
  setShowOptions,
}: {
  updateSelectedOption: Function;
  setShowOptions: Function;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchQ, setSearchQ] = useState("");
  const [selectedOptionObj, setSelectedOptObj]: any = useState(null);

  const selectionHandler = (selectedOpt: IntegrationSchema) => {
    setSelectedOptObj(selectedOpt);
    updateSelectedOption(selectedOpt.name);
    onOpen();
  };

  return (
    <>
      {/* <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent borderRadius="1.3em">
          <ModalHeader bgColor="yellow.300" borderTopRadius="1.1em" py="1em">
            {selectedOptionObj?.name}
          </ModalHeader>
          <ModalCloseButton
            borderRadius="full"
            bgColor="gray.100"
            top="1.5em"
            right="1.5em"
            outline="none"
          />
          <ModalBody>In Progress</ModalBody>
        </ModalContent>
      </Modal> */}
      <IntegrationModal
        isOpen={isOpen}
        onClose={onClose}
        int={selectedOptionObj}
        setShowOptions={setShowOptions}
      />
      <Box
        w="full"
        my=".5em"
        p={{ base: ".8em", md: "1em", lg: "1.2em" }}
        //   py={{ base: ".8em", md: "1em", lg: "1.2em" }}
        borderRadius=".4em"
        boxShadow="0px 1px 5px 2px #c7c7c778"
      >
        <Text fontSize="13px" fontWeight="medium" color="gray.600" mb=".8em">
          Search Integration
        </Text>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<BsSearch color="gray.300" />}
          />
          <Input
            type="text"
            bgColor="gray.50"
            value={searchQ}
            onChange={(e) => setSearchQ(e.target.value)}
          />
        </InputGroup>

        <Box mt=".8em">
          {supportedIntegrations.map((int: IntegrationSchema) => (
            <Box
              key={int?.name}
              _hover={{ bgColor: "gray.100" }}
              borderRadius=".1em"
              py=".4em"
              px=".5em"
              my=".2em"
              cursor="pointer"
              onClick={() => selectionHandler(int)}
              display={
                int.name.toLowerCase().includes(searchQ.toLowerCase())
                  ? "block"
                  : "none"
              }
            >
              <Text>{int?.name}</Text>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export const Integration = (verifyStatus: boolean) => {
  const [showOptions, setShowOptions] = useState(false);
  const toggleHandler = () => setShowOptions(!showOptions);
  const [selectedOption, setSelectedOption] = useState("Select");
  const getSelectedOption = (option: string) => setSelectedOption(option);
  // console.log(verifyStatus);
  return (
    <Box w="full">
      <Box
        border="1px solid"
        borderColor="gray.200"
        borderRadius=".4em"
        py="1em"
        px="1em"
        w="full"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={toggleHandler}
        cursor="pointer"
        // role="button"
        // isDisabled={true}
        style={{ pointerEvents: verifyStatus ? "auto" : "none" }}
      >
        <Text>{selectedOption}</Text>
        <Box>
          {showOptions ? (
            <MdOutlineKeyboardArrowUp />
          ) : (
            <MdOutlineKeyboardArrowDown />
          )}
        </Box>
      </Box>
      {showOptions && (
        // @ts-ignore
        <IntegrationOptCard
          updateSelectedOption={getSelectedOption}
          setShowOptions={setShowOptions}
        />
      )}
    </Box>
  );
};
