import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Image,
  Stack,
  Text,
  useToast,
  useDisclosure,
  Center,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  css,
  useMediaQuery,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Link,
  Flex,
} from "@chakra-ui/react";
// @ts-ignore
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  MdChevronRight,
  MdKeyboardBackspace,
  MdShare,
  MdOutlinePointOfSale,
} from "react-icons/md";
import { motion } from "framer-motion";
import useUserToken from "../../../../hooks/useUserToken";
import { BsChatDots } from "react-icons/bs";
import Overview from "./tabs/overview/Overview";
import Earnings from "./tabs/earnings/Earnings";
import Assets from "./tabs/assets";
import Leads from "./tabs/leads";
import UnavailableInfo from "./tabs/UnavailableInfo";
import { AiOutlineLink } from "react-icons/ai";
import {
  FaChartLine,
  FaDatabase,
  FaDropbox,
  FaFunnelDollar,
} from "react-icons/fa";
import { PiBinocularsDuotone } from "react-icons/pi";

const ViewCampaign = () => {
  const token = useUserToken("affiliate");
  const navigate = useNavigate();

  let { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  const [campaign, setCampaign] = useState({
    campaign_mode: "",
    campaign_name: "",
    status: "",
    createdAt: "",
    countriesToShip: "",
    id: "",
    landing_page_url: "",
    banner_image: "",
    background_image: "",
  });

  const [loading, setLoading] = useState(false);
  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  const [earnings, setearnings] = useState(0);
  const [showLandingPageUrl, setshowLandingPageUrl] = useState(false);

  useEffect(() => {
    if (id) {
      getCampaign(id);
    }
  }, []);

  const getCampaign = (id: string | undefined) => {
    setLoading(true);
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + `campaign/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setCampaign(data.data.campaign);
        } else {
          // formViewRef.current.scrollIntoView();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("get campaign => " + err);
        setLoading(false);
      });
  };

  const getStatusColor = (status: string) => {
    if (status === "active") {
      return "#239654";
    } else if (status === "paused") {
      return "#F3994A";
    } else {
      return "#EB5757";
    }
  };

  const toast = useToast();
  const [currentAffiliate, setcurrentAffiliate] = useState<any>({
    id: null,
    firstname: "",
    lastname: "",
    userId: null,
    affOrgId: null,
  });

  const {
    isOpen: isCopyModalOpen,
    onOpen: onCopyModalOpen,
    onClose: onCopyModalClose,
  } = useDisclosure();

  const CopyText = async (text: string) => {
    if (!text) return;

    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const getCampaignEarninngs = () => {
    let bearerAuth = token;
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `/affiliate/analytics?campaignId=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setearnings(
            data.data.analytics.paidCommission +
              data.data.analytics.pendingCommission
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("get earnings => " + err);
        setLoading(false);
      });
  };

  const getCurrentAffiliate = () => {
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + `auth/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setcurrentAffiliate({
            id: data.data.user.id,
            firstname: data.data.user.firstname,
            lastname: data.data.user.lastname,
            affOrgId: data.data.user.organization.id,
            userId: data.data.user.userId,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("get campaign => " + err);
        setLoading(false);
      });
  };

  const getAffiliateCampaign = () => {
    let bearerAuth = token;

    let final_endpoint =
      "promote-campaign/affiliate/campaigns?campaignId=" + (id || "");

    fetch(process.env.REACT_APP_API_ENDPOINT + final_endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data.campaigns && data.data.campaigns.length) {
          setshowLandingPageUrl(data.data.campaigns[0].status === "approved");
        }
      })
      .catch((err) => {});
  };

  const checkToken = async () => {
    if (!token) return;

    await fetch(process.env.REACT_APP_API_ENDPOINT + `/auth/valid-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data?.valid) {
          return;
        } else {
          window.localStorage.clear();
          sessionStorage.removeItem("currentUserType");
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          navigate("/login");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (token) {
      checkToken();
    }
  }, [token]);

  useEffect(() => {
    getCurrentAffiliate();

    getCampaignEarninngs();
    getAffiliateCampaign();
  }, []);

  const handleShareLink = () => {
    if (!campaign.campaign_name) return;

    const textToCopy =
      "You’ve been invited to join " +
      currentAffiliate.firstname +
      " " +
      currentAffiliate.lastname +
      "’s affiliate campaign: " +
      campaign?.campaign_name +
      ".\nSignup to join thousands of affiliates that promote campaigns on Metricks and start earning today. " +
      window.location.hostname +
      "/affiliate-login?affiliate_name=" +
      currentAffiliate.firstname +
      "&id=" +
      campaign?.id;

    CopyText(textToCopy)
      .then(() => {
        toast({
          title: "Copied Link",
          description:
            campaign?.campaign_name + " campaign link copied to clipboard",
          status: "info",
          duration: 4000,
          position: "top-right",
        });
      })
      .catch(() => {
        toast({
          title: "Copied",
          description: "Failed to copy " + campaign?.campaign_name + " link",
          status: "info",
          duration: 3000,
          position: "top-right",
        });
      });
  };

  const handleCopyLink = () => {
    if (!campaign.campaign_name) return;

    const textToCopy =
      window.location.hostname +
      "/affiliate-login?affiliate_name=" +
      currentAffiliate.firstname +
      "&id=" +
      campaign?.id;

    CopyText(textToCopy)
      .then(() => {
        toast({
          title: "Copied Link",
          description:
            campaign?.campaign_name + " campaign link copied to clipboard",
          status: "info",
          duration: 4000,
          position: "top-right",
        });
      })
      .catch(() => {
        toast({
          title: "Copied",
          description: "Failed to copy " + campaign?.campaign_name + " link",
          status: "info",
          duration: 3000,
          position: "top-right",
        });
      });
  };

  return loading ? (
    <Box display="flex" h="80vh" alignItems="center" justifyContent="center">
      <motion.div
        animate={{ opacity: [0.5, 1], scale: [0.8, 1.5] }}
        transition={{
          repeat: Infinity,
          repeatType: "reverse",
          duration: 3,
        }}
      >
        <Text fontSize="20px" fontWeight="500" color=" #7211D4">
          Loading...
        </Text>
      </motion.div>
    </Box>
  ) : (
    <Stack
      padding={{ base: "0px", md: "19px" }}
      bgColor={{ base: "#FAFBFE", md: "#F1F4FA" }}
    >
      <Box
        display="flex"
        mb="12px"
        alignItems="center"
        padding={{ base: "13px 20px", md: "0px" }}
        bg={{ base: "white", md: "transparent" }}
      >
        <Text
          fontSize="12px"
          fontWeight="600"
          lineHeight="18px"
          color="#32325D"
          mr="5px"
          cursor="pointer"
          _hover={{ textDecoration: "underline" }}
          onClick={() => navigate(-1)}
        >
          Campaign
        </Text>

        <MdChevronRight size={20} color="#32325D" />

        <Text
          fontSize="12px"
          lineHeight="18px"
          color="#32325D"
          fontWeight="500"
          ml="5px"
        >
          Overview
        </Text>
      </Box>

      <Stack bgColor="white" w="100%" paddingX={{ base: "19px", md: "0px" }}>
        <Box
          width="100%"
          height={{ base: "89px", md: "269px" }}
          bgGradient="linear(90deg, #1BD75F -164%, #1A1414 143.66%)"
          borderRadius="8px"
          position="relative"
          backgroundImage={
            campaign.background_image ? campaign.background_image : ""
          }
        >
          <Image
            position="absolute"
            bottom={{ base: "-50%", md: "-40%" }}
            left={{ base: "19px", md: "47px" }}
            width={{ base: "80px", md: "179px" }}
            height={{ base: "80px", md: "179px" }}
            borderRadius="50%"
            objectFit="cover"
            src={campaign?.banner_image ? campaign?.banner_image : ""}
          />
        </Box>

        <Box
          display={{ base: "none", md: "flex" }}
          alignItems="center"
          cursor="pointer"
          justifyContent="end"
          pr="47px"
          mt="34px"
          _hover={{ color: "#7211D4" }}
          onClick={() => navigate(-1)}
        >
          <MdKeyboardBackspace color="#32325D" />
          <Text
            color="#32325D"
            lineHeight="40px"
            fontSize="14px"
            fontWeight="400"
            pl="8px"
          >
            Back to the previous page
          </Text>
        </Box>

        <Box
          paddingBottom="34px"
          pt={{ base: "60px", md: "100px" }}
          pl={{ base: "", md: "47px" }}
          pr={{ base: "", md: "47px" }}
        >
          <Text
            color="#32325D"
            lineHeight={{ base: "21px", md: "36px" }}
            fontSize={{ base: "16px", md: "24px" }}
            fontWeight="600"
            textTransform="capitalize"
          >
            {campaign?.campaign_name}
          </Text>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Text
                color="rgba(50, 50, 93, 0.8)"
                lineHeight="21px"
                fontSize="14px"
                fontWeight="500"
                textTransform="capitalize"
              >
                {campaign?.campaign_mode} Campaign
              </Text>

              <Image
                margin="0px 13px"
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/b4254b20-01f9-4451-9ede-2e8b98258404.svg"
              />

              <Box display="flex" alignItems="center">
                <Box
                  background={getStatusColor(campaign?.status || "active")}
                  height={{ base: "8px", md: "12px" }}
                  width={{ base: "8px", md: "12px" }}
                  borderRadius="50%"
                ></Box>
                <Text
                  color="#32325D"
                  lineHeight="21px"
                  fontSize="14px"
                  ml="6px"
                  fontWeight="500"
                  textTransform="capitalize"
                >
                  {campaign?.status}
                </Text>
              </Box>
            </Box>

            <Box display={{ base: "none", md: "flex" }} alignItems="center">
              <Center
                bgColor="#F0F3F9"
                cursor="pointer"
                p="8px"
                borderRadius="8px"
                onClick={handleCopyLink}
              >
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/e107144c-810b-4130-b98e-15c142b0dff3.svg"
                  alt="copy"
                />
                <Text
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="21px"
                  color="#7211D4"
                  ml="6px"
                >
                  Campaign Link
                </Text>
              </Center>
            </Box>

            <Box display={{ base: "flex", md: "none" }} alignItems="center">
              <Center
                bgColor="#F0F3F9"
                borderRadius="full"
                h="30px"
                w="30px"
                onClick={handleCopyLink}
              >
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/e107144c-810b-4130-b98e-15c142b0dff3.svg"
                  alt="copy"
                />
              </Center>
            </Box>
          </Box>

          {campaign?.landing_page_url && showLandingPageUrl && (
            <Box
              color="#7211D4"
              w="max-content"
              maxW="100%"
              wordBreak="break-all"
            >
              <Link
                href={
                  campaign?.landing_page_url.includes("https://")
                    ? `${campaign?.landing_page_url}?ref=${searchParams.get(
                        "ref"
                      )}`
                    : `https://${
                        campaign?.landing_page_url
                      }?ref=${searchParams.get("ref")}`
                }
                isExternal
                display="flex"
                width="max-content"
                mt="20px"
                fontSize="12px"
                alignItems="center"
                _visited={{ color: "#7211D4" }}
              >
                <AiOutlineLink />
                {campaign?.landing_page_url.includes("https://")
                  ? `${campaign?.landing_page_url}?ref=${searchParams.get(
                      "ref"
                    )}`
                  : `https://${
                      campaign?.landing_page_url
                    }?ref=${searchParams.get("ref")}`}
              </Link>
            </Box>
          )}
        </Box>

        <Modal
          isCentered
          closeOnOverlayClick={true}
          isOpen={isCopyModalOpen}
          onClose={onCopyModalClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />

            <ModalBody pb={6}>
              <Box display="flex" m="38px 0px" alignItems="center">
                <Button
                  color="#7211D4"
                  bgColor="#F0F3F9"
                  mr="24px"
                  onClick={handleCopyLink}
                >
                  <Image
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/e107144c-810b-4130-b98e-15c142b0dff3.svg"
                    alt="copy"
                  />
                  <Text
                    fontSize="14px"
                    lineHeight="21px"
                    fontWeight="500"
                    ml="8px"
                  >
                    Copy Campaign Link
                  </Text>
                </Button>

                <Button
                  color="#7211D4"
                  bgColor="#F0F3F9"
                  onClick={handleShareLink}
                >
                  <MdShare size={24} />
                  <Text
                    fontSize="14px"
                    lineHeight="21px"
                    fontWeight="500"
                    ml="8px"
                  >
                    Share to Chat
                  </Text>
                </Button>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Tabs
          colorScheme="purple"
          pl={{ base: "0px", md: "47px" }}
          pr={{ base: "0px", md: "47px" }}
          width="100%"
          defaultIndex={0}
          isFitted={isDesktop ? true : false}
        >
          <TabList
            css={
              isDesktop
                ? {}
                : css({
                    "::-webkit-scrollbar": {
                      display: "none",
                    },
                    "-webkit-overflow-x-scrolling": "touch",
                  })
            }
            borderBottom="none"
            paddingBottom={{ base: "3px", md: "0px" }}
            overflowX={{ base: "auto", md: "visible" }}
          >
            <Tab
              fontSize="14px"
              lineHeight={{ md: "21px" }}
              _selected={{
                color: "#7211D4",
                borderBottom: "1px solid #7211D4",
              }}
              fontWeight="500"
              borderBottom="0.5px solid rgba(50, 50, 93, 0.3)"
              color="rgba(50, 50, 93, 0.4)"
            >
              <Flex gap={2} align={"center"} justify={"center"}>
                <PiBinocularsDuotone />
                Overview
              </Flex>
            </Tab>
            <Tab
              fontSize="14px"
              lineHeight={{ md: "21px" }}
              _selected={{
                color: "#7211D4",
                borderBottom: "1px solid #7211D4",
              }}
              fontWeight="500"
              borderBottom="0.5px solid rgba(50, 50, 93, 0.3)"
              color="rgba(50, 50, 93, 0.4)"
            >
              <Flex gap={2} align={"center"} justify={"center"}>
                <MdOutlinePointOfSale />
                Earnings
              </Flex>
            </Tab>
            <Tab
              fontSize="14px"
              lineHeight={{ md: "21px" }}
              _selected={{
                color: "#7211D4",
                borderBottom: "1px solid #7211D4",
              }}
              fontWeight="500"
              borderBottom="0.5px solid rgba(50, 50, 93, 0.3)"
              color="rgba(50, 50, 93, 0.4)"
            >
              <Flex gap={2} align={"center"} justify={"center"}>
                <FaDropbox />
                Assets
              </Flex>
            </Tab>
            <Tab
              fontSize="14px"
              lineHeight={{ md: "21px" }}
              _selected={{
                color: "#7211D4",
                borderBottom: "1px solid #7211D4",
              }}
              fontWeight="500"
              borderBottom="0.5px solid rgba(50, 50, 93, 0.3)"
              color="rgba(50, 50, 93, 0.4)"
            >
              <Flex gap={2} align={"center"} justify={"center"}>
                <FaChartLine />
                Leads
              </Flex>
            </Tab>
            <Tab
              fontSize="14px"
              lineHeight={{ md: "21px" }}
              _selected={{
                color: "#7211D4",
                borderBottom: "1px solid #7211D4",
              }}
              fontWeight="500"
              borderBottom="0.5px solid rgba(50, 50, 93, 0.3)"
              color="rgba(50, 50, 93, 0.4)"
            >
              <Flex gap={2} align={"center"} justify={"center"}>
                <FaFunnelDollar />
                Funnels
              </Flex>
            </Tab>
            <Tab
              fontSize="14px"
              lineHeight={{ md: "21px" }}
              _selected={{
                color: "#7211D4",
                borderBottom: "1px solid #7211D4",
              }}
              fontWeight="500"
              borderBottom="0.5px solid rgba(50, 50, 93, 0.3)"
              color="rgba(50, 50, 93, 0.4)"
            >
              <Flex gap={2} align={"center"} justify={"center"}>
                <FaDatabase />
                Pixels
              </Flex>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel px="0px">
              <Overview campaign={campaign} campaignEarnings={earnings} />
            </TabPanel>

            <TabPanel px="0px">
              <Earnings
                campaignName={campaign.campaign_name}
                id={campaign.id}
                currentAff={currentAffiliate}
              />
            </TabPanel>

            <TabPanel px="0px">
              <Assets campaignId={id} />
            </TabPanel>

            <TabPanel px="0px">
              <Leads campaignName={campaign.campaign_name} campaignId={id} />
            </TabPanel>

            <TabPanel px="0px">
              <UnavailableInfo tab="funnels" />
            </TabPanel>

            <TabPanel px="0px">
              <UnavailableInfo tab="pixels" />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Stack>
  );
};

export default ViewCampaign;
