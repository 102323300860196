import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Image,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { BsCheckSquareFill } from "react-icons/bs";
import { IoArrowBackSharp, IoArrowForwardSharp } from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { BaseButton } from "../button";
import Card from "../card";
import { StepWrapper } from "./StepWrapper";
import {
  getCurrentUserInstance,
  setCurrentUserType,
  setLocalUserInstance,
} from "../../state/localstorage";
import { FaStarOfLife } from "react-icons/fa";
import { RiArrowRightFill } from "react-icons/ri";

export const MarketingAsset = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [orgType, setOrgType] = useState(false);
  useEffect(() => {
    if (searchParams.get("type") !== null) {
      setOrgType(searchParams.get("type") ? true : false);
    }
  }, [searchParams, orgType]);
  const [cookies] = useCookies(["user_id", "access_token"]);

  const localUser = getCurrentUserInstance();
  localUser && console.log(localUser);

  const [data, setData] = useState(
    localUser && localUser.assetTypes
      ? localUser.assetTypes
      : [
          {
            defaultIcon:
              "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/1cf27151-86ff-4659-a875-9cd2ff216d76.svg",
            coloredIcon:
              "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/c72cf922-8c5a-4761-bc92-852f7c0387f0.svg",
            title: "Promotional pictures & videos",
            selected: false,
          },
          {
            defaultIcon:
              "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/bbe2ed45-5c45-4612-a2dc-6bb231afb066.svg",
            coloredIcon:
              "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/25a3a426-1fe5-4002-8a3f-6b30990d5d5b.svg",
            title: "Testimonials & reviews",
            selected: false,
          },
          {
            defaultIcon:
              "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/d3426775-561d-4798-83f9-f565efa061d7.svg",
            coloredIcon:
              "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/e77855c3-94d4-4b3d-870c-d3736ad77caf.svg",
            title: "Brand ambassadors",
            selected: false,
          },
          {
            defaultIcon:
              "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/511431b7-955e-4f2f-bde8-8e553e49f429.svg",
            coloredIcon:
              "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/7e4631eb-e0e8-4add-a903-cc231c69ca31.svg",
            title: "Celebrity endorsements",
            selected: false,
          },
          {
            defaultIcon:
              "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/d079624e-1b7a-470c-89a7-e783c514496f.svg",
            coloredIcon:
              "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/6641c769-1f14-440d-ade1-3f5768d390bb.svg",
            title: "Other",
            selected: false,
          },
        ]
  );
  const navigate = useNavigate();

  const [saveAndExit, setSaveAndExit] = useState(false);
  const [loading, setLoading] = useState(false);
  const submitHandler = () => {
    if (data.filter((dt: any) => dt.selected).length < 1) {
      toast.warning("Please complete all required fields or save and exit");
      return;
    }

    setLoading(true);
    const rawData = data;
    const payload = {
      assetType: data
        .filter((dt: any) => dt.selected)
        .map((data: any) => data.title),
    };

    fetch(process.env.REACT_APP_API_ENDPOINT + "auth/signup/step3", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.access_token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);

        if (data.success) {
          setCurrentUserType("advertiser");
          setLocalUserInstance({
            ...localUser,
            assetTypes: rawData,
          });
          const user = getCurrentUserInstance();
          user.organisation.signup_step = 3;
          setLocalUserInstance(user);
          if (orgType) {
            saveAndExit
              ? window.open("https://www.metricks.io", "_self")
              : navigate("/signup/product-details?type=newOrganization");
          } else {
            saveAndExit
              ? window.open("https://www.metricks.io", "_self")
              : navigate("/signup/product-details");
          }
        }
        console.log(data);
        toast.error(data.message);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
        console.log(err);
      });
  };
  const step = [
    {
      title: "User Profile",
      status: "passed",
      key: 1,
    },
    {
      title: "Company Profile",
      status: "passed",
      key: 2,
    },

    {
      title: "Marketing Assets",
      status: "current",
      key: 3,
    },
    {
      title: "Product Details",
      status: "default",
      key: 4,
    },
    {
      title: "Niche",
      status: "default",
      key: 5,
    },
    {
      title: "Billing",
      status: "default",
      key: 6,
    },
  ];
  const orgSteps = [
    {
      title: "Company Profile",
      status: "passed",
      key: 1,
    },

    {
      title: "Marketing Assets",
      status: "current",
      key: 2,
    },
    {
      title: "Product Details",
      status: "default",
      key: 3,
    },
    {
      title: "Niche",
      status: "default",
      key: 4,
    },
    {
      title: "Billing",
      status: "default",
      key: 5,
    },
  ];
  const steps = orgType ? orgSteps : step;
  return (
    <StepWrapper
      step={steps.map((item: any) => {
        if (item.status === "current") {
          let step = `STEP ${item.key} OF ${steps.length}`;
          return step;
        }
      })}
      header="Setting up your advertiser account"
      baseText="Marketing assets"
      stepsMap={steps}
    >
      <Flex
        position={"relative"}
        direction={"column"}
        alignItems={"center"}
        w={["90%", "60%"]}
        // backgroundColor={"red"}
      >
        <Flex w={"100%"} direction={"column"}>
          <ToastContainer />
          <Heading fontSize="1.5em" mb="1em" display={"flex"} gap={1}>
            What marketing assets do you have?
            <FaStarOfLife fontSize={"6px"} color="red" />
          </Heading>

          <Flex
            flexWrap={["wrap", "wrap", "wrap"]}
            rowGap="1em"
            columnGap="1em"
          >
            {data.map((dt: any, i: any) => (
              <Box
                key={i}
                _hover={{ bgColor: "#F5F8FF" }}
                border="1.5px solid"
                borderColor={dt.selected ? "#7211D4" : "#D1D1D1"}
                bgColor={dt.selected ? "#F5F8FF" : "#ffffff"}
                padding="16px 16px 10px 16px"
                borderRadius="0.4em"
                w={["100%", "45%", "48%"]}
                minHeight={["100px", "148px", "150px"]}
                display="flex"
                alignItems="flex-start"
                flexDirection={["row-reverse", "column"]}
                justifyContent="space-between"
                cursor="pointer"
                onClick={() => {
                  const newState = data.map((dt: any) => {
                    if (data[i] == dt) {
                      return {
                        ...dt,
                        selected: !dt.selected,
                      };
                    } else {
                      return dt;
                    }
                  });

                  setData(newState);
                }}
              >
                {!dt.selected ? (
                  <Box
                    width="1em"
                    height="1em"
                    border="1.5px solid #A4B3C3"
                    borderRadius="4px"
                    cursor="pointer"
                    onClick={() => {
                      const newState = data.map((dt: any) => {
                        if (data[i] == dt) {
                          return { ...dt, selected: !dt.selected };
                        } else {
                          return dt;
                        }
                      });

                      setData(newState);
                    }}
                  ></Box>
                ) : (
                  <BsCheckSquareFill
                    color="#5C15CE"
                    cursor="pointer"
                    onClick={() => {
                      const newState = data.map((dt: any) => {
                        if (data[i] == dt) {
                          return { ...dt, selected: !dt.selected };
                        } else {
                          return dt;
                        }
                      });

                      setData(newState);
                    }}
                  />
                )}

                <Box my=".3em">
                  <Image src={dt.selected ? dt.coloredIcon : dt.defaultIcon} />
                  <Text
                    fontWeight="400"
                    fontSize="13px"
                    lineHeight="26px"
                    color={dt.selected ? "#7211D4" : "#000000"}
                    mt=".2em"
                  >
                    {dt.title}
                  </Text>
                </Box>
              </Box>
            ))}
          </Flex>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            mb="1em"
            mt="10em"
            flexDirection={{ base: "column-reverse", lg: "row" }}
            rowGap=".6em"
          >
            <Button
              px="0"
              color="#5C14CE"
              bgColor="#fff"
              fontWeight="500"
              fontSize="14px"
              width={{ md: "40%" }}
              _hover={{ bgColor: "#fff" }}
              _focus={{ bgColor: "#fff" }}
              onClick={() =>
                navigate("/signup/company-profile?default-country=Nigeria")
              }
              leftIcon={<IoArrowBackSharp />}
            >
              Back to the previous step
            </Button>
            <Box
              width={{ base: "100%", lg: "40%" }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection={["column-reverse", "row"]}
              rowGap=".6em"
              justifySelf="flex-end"
            >
              <Box mr={["0em", "1em"]} width={["100%", "60%", "50%"]}>
                {/* <BaseButton
                text="Skip"
                textColor="#5C14CE"
                bgColor="#F0F5FF"
                clickEventHandler={() => {
                  //   setSaveAndExit(true);
                  //   submitHandler();
                  //   window.open("https://metricks.io", "_self");
                  navigate("/signup/product-details");
                }}
              /> */}
              </Box>

              <Box width={["100%", "60%", "60%"]}>
                <BaseButton
                  text="Next step"
                  textColor="#fff"
                  bgColor="#5C14CE"
                  loadingState={loading}
                  rightIcon={<RiArrowRightFill />}
                  clickEventHandler={submitHandler}
                />
              </Box>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </StepWrapper>
  );
};
