import React from "react";
import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { getCurrentUserInstance } from "../../state/localstorage";

const useAuth = () => {
  const user: any = getCurrentUserInstance();
  if (user && user.organisation) {
    return true;
  } else {
    return false;
  }
};

const ProtectedRoutes = (props: any) => {
  let [queryParams, _] = useSearchParams();

  const auth = useAuth();
  if (queryParams.get("managerId"))
    return (
      <Navigate
        to={`/manager/signup?managerId=${queryParams.get(
          "managerId"
        )}&email=${queryParams.get("email")}&organizationName=${queryParams.get(
          "organizationName"
        )}`}
      />
    );
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
