import {
  Box,
  Button,
  Checkbox,
  Flex,
  IconButton,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  css,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { IoPersonOutline } from "react-icons/io5";
import moment from "moment";
import useUserToken from "../../hooks/useUserToken";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

type ReportsTableProps = {
  AffReports: any[];
  sethighlighted: React.Dispatch<React.SetStateAction<any[]>>;
  highlighted: any[];
  sethighlighted2: React.Dispatch<React.SetStateAction<any[]>>;
  highlighted2: any[];
  currentPage: number;
  searchVal: string;
  handleViewAff: (selectedAff: any) => void;
  nextPage: () => void;
  prevPage: () => void;
};

type CheckBoxTrackType = {
  [key: string | number]: boolean;
};

const ReportsTable = ({
  AffReports,
  sethighlighted,
  highlighted,
  sethighlighted2,
  highlighted2,
  handleViewAff,
  currentPage,
  nextPage,
  prevPage,
  searchVal,
}: ReportsTableProps) => {
  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  const [checkBoxTracker, setcheckBoxTracker] = useState<CheckBoxTrackType>({});

  const token = useUserToken();

  const getStatusColor = (status: string) => {
    if (status.toLowerCase() === "approved") return "#239667";
    if (status.toLowerCase() === "paid") return "#239654";

    if (status.toLowerCase() === "pending") return "#F3994A";

    return "#eb5757cd";
  };

  const titles = [
    "",
    "Organization",
    "All Campaigns",
    "All Sales",
    "All Leads",
    "All Earnings",
    "Action",
  ];
  const navigate = useNavigate();
  const handleSelect = (commissionData: any) => {
    console.log("comms", commissionData);
    const foundSale = highlighted.find(
      (item: any) =>
        item.aff_organisation_id === commissionData?.aff_organisation_id
    );

    if (foundSale) {
      sethighlighted(
        highlighted.filter(
          (item: any) =>
            item.aff_organisation_id !== commissionData?.aff_organisation_id
        )
      );
      sethighlighted2(
        highlighted.filter(
          (item: any) =>
            item.aff_organisation_id !== commissionData?.aff_organisation_id
        )
      );
      setcheckBoxTracker({
        ...checkBoxTracker,
        [commissionData.aff_organisation_id]: false,
      });
    } else {
      sethighlighted([...highlighted, commissionData]);
      sethighlighted2([...highlighted, commissionData]);
      // console.log([...highlighted, commissionData]);
      setcheckBoxTracker({
        ...checkBoxTracker,
        [commissionData.aff_organisation_id]: true,
      });
    }
  };

  const handleSelectAll = () => {
    let obj = {};

    if (highlighted.length === AffReports.length && AffReports.length !== 0) {
      sethighlighted([]);
      setcheckBoxTracker({});
    } else {
      sethighlighted(AffReports);

      AffReports.forEach((aff: { [key: string]: any }) => {
        obj = { ...obj, [aff.aff_organisation_id]: true };
      });
      setcheckBoxTracker(obj);
    }
  };

  return (
    <Box
      css={
        isDesktop
          ? {}
          : css({
              "::-webkit-scrollbar": { display: "none" },
              "-webkit-overflow-scrolling": "touch",
            })
      }
      overflowX={{ base: "scroll", md: "visible" }}
      maxWidth="100%"
    >
      <Table>
        <Thead>
          <Tr height="53px" bgColor="#F1F4FA">
            {titles.map((title) => {
              return (
                <Th
                  color="rgba(50, 50, 93, 0.8)"
                  textTransform="uppercase"
                  lineHeight="18px"
                  fontSize="12px"
                  fontWeight="500"
                  key={title}
                  whiteSpace={{ base: "nowrap", md: "normal" }}
                >
                  {title ? (
                    title
                  ) : (
                    <Checkbox
                      colorScheme="purple"
                      onChange={() => handleSelectAll()}
                      isChecked={
                        highlighted.length === AffReports.length &&
                        AffReports.length !== 0
                      }
                    />
                  )}
                </Th>
              );
            })}
          </Tr>
        </Thead>

        <Tbody>
          {AffReports.filter((item: any, i: number) =>
            item?.aff_organisation_name
              ?.toLowerCase()
              .includes(searchVal.toLowerCase())
          ).map((item: any, i: number) => {
            return (
              <Tr
                key={item.aff_organisation_id}
                height="80px"
                width="100%"
                bgColor={
                  checkBoxTracker[item.aff_organisation_id] ? "purple.100" : ""
                }
              >
                <Td pl="20px" pr="10px">
                  <Checkbox
                    colorScheme="purple"
                    onChange={() => handleSelect(item)}
                    isChecked={
                      checkBoxTracker[item.aff_organisation_id] || false
                    }
                  />
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    {item?.aff_organisation_name}
                  </Text>
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    {item?.totalCampaigns || "0"}
                  </Text>
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    $ {item?.totalSales || 0}
                  </Text>
                </Td>

                <Td>
                  <Flex alignItems="center">
                    <Box>
                      <Text
                        color="#32325D"
                        lineHeight="21px"
                        fontSize="14px"
                        fontWeight="500"
                        ml="6px"
                        textTransform="capitalize"
                      >
                        {item?.totalLeads}
                      </Text>
                    </Box>
                  </Flex>
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    $ {item.totalCommissions || 0}
                  </Text>
                </Td>

                <Td>
                  <Flex alignItems="center">
                    <Button
                      borderRadius="8px"
                      bgColor="#F0F3F9"
                      color="#7211D4"
                    >
                      {/* <IoPersonOutline fontSize={14} /> */}
                      <Text
                        fontWeight="500"
                        fontSize={{ base: "12px", md: "14px" }}
                        lineHeight={{ base: "18px", md: "21px" }}
                        ml="7px"
                        onClick={() =>
                          navigate(
                            `/explore/affiliate?id=${item.aff_organisation_id}`
                          )
                        }
                      >
                        View Profile
                      </Text>
                    </Button>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {AffReports.length < 1 && (
        <Flex
          w={"100%"}
          // bgColor={"red"}
          //  h={"50px"}
          mb="29px"
          justify={"center"}
          align={"center"}
          flexDirection={"column"}
        >
          <Image
            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
            width="150px"
            height="150px"
          />
          <Text fontWeight={"medium"}>
            No data available yet, when you have reports you'll see them here.
          </Text>
        </Flex>
      )}
      <Flex
        w={"100%"}
        // bgColor={"red"}
        h={"50px"}
        mb="29px"
        justify={"flex-end"}
        align={"center"}
      >
        <Flex align={"center"} gap={3}>
          <IconButton
            aria-label="Search database"
            icon={<ChevronLeftIcon fontSize={16} />}
            onClick={prevPage}
          />
          <Flex
            borderWidth={0.5}
            borderColor={"#c4c4c4"}
            w={35}
            h={35}
            justify={"center"}
            align={"center"}
            borderRadius={10}
          >
            <Text>{currentPage}</Text>
          </Flex>
          <IconButton
            aria-label="Search database"
            icon={<ChevronRightIcon fontSize={16} />}
            onClick={nextPage}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default ReportsTable;
