import { Box, Heading, Image, Link, Text } from "@chakra-ui/react";
import Type from "../select-type";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

export const SignUpUI = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [type, setType] = useState(false);
  const [country, setCountry] = useState("");
  const [userId, setUserId] = useState("");
  useEffect(() => {
    if (searchParams.get("type") !== null) {
      setType(searchParams.get("type") === "newOrganization" ? true : false);
    }
    if (
      searchParams.get("country") !== null &&
      searchParams.get("country") !== "null"
    ) {
      setCountry(searchParams.get("country")!);
    }
    if (searchParams.get("userId") !== null) {
      setUserId(searchParams.get("userId")!);
    }
  }, [searchParams, type, country, userId]);

  return (
    <>
      <Box
        display="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        py="2em"
        overflowY="hidden"
        height="100vh"
        bgColor="#F0F5FF"
        position="relative"
      >
        <Box
          position="absolute"
          left={["2em", "8em"]}
          top={["3em", "3em", "4em"]}
          width="2em"
          height="2em"
          bgGradient="linear-gradient(151.25deg, #7211D4 5.51%, rgba(255, 255, 255, 0) 69.14%)"
          transform="rotate(-16.74deg)"
          borderRadius="1em"
        ></Box>

        <Box
          position="absolute"
          left={["3em", "10em"]}
          top={["5em", "6em", "8em"]}
          width="3em"
          height="3em"
          bgGradient="linear-gradient(151.25deg, #4665A8 5.51%, rgba(255, 255, 255, 0) 69.14%)"
          transform="rotate(-16.74deg)"
          borderRadius="1.5em"
        ></Box>

        <Box
          position="absolute"
          right={["2em", "8em"]}
          top={["3em", "3em", "4em"]}
          width="3em"
          height="3em"
          bgGradient="linear-gradient(151.25deg, #FB9B54 5.51%, rgba(255, 255, 255, 0) 69.14%)"
          transform="rotate(47.45deg)"
          borderRadius="1.5em"
        ></Box>

        <Box
          position="absolute"
          right={["3em", "10em", "14em"]}
          top={["5em", "6em", "8em"]}
          width="2em"
          height="2em"
          bgGradient="linear-gradient(151.25deg, #C44CC0 5.51%, rgba(255, 255, 255, 0) 69.14%)"
          transform="rotate(47.45deg)"
          borderRadius="1em"
        ></Box>

        <Image src="/assets/logo.png" mb=".5em" height="50px" />
        <Heading mb="1em">Sign me up as an...</Heading>

        {[
          {
            title: "Advertiser",
            desc: "I have products or services to sell",
            hander: () => {
              // type
              //   ? navigate(
              //       `/signup/company-profile?userId=${userId}&default-country=${
              //         country ? country : "Nigeria"
              //       }`
              //     )
              //   : navigate("/signup/user-profile");
            },
          },

          {
            title: "Affiliate",
            desc: "I want to promote products and services",
            hander: () => {
              // type
              //   ? navigate(
              //       `/signup/affiliate-company-profile?userId=${userId}&default-country=${
              //         country ? country : "Nigeria"
              //       }`
              //     )
              //   : navigate("/signup/affiliate-profile");
            },
          },
        ].map((detail, i) => {
          return (
            <Box key={i} width={["95%", "75%", "55%"]} my="1em">
              <Type
                title={detail.title}
                desc={detail.desc}
                selectHandler={detail.hander}
              />
            </Box>
          );
        })}

        {!type && (
          <Text mt="2em">
            Already have an account?{" "}
            <Link href="/login" color="#5C14CE">
              Log In
            </Link>
          </Text>
        )}
      </Box>
    </>
  );
};
