export const integrations = [
  {
    name: "stripe",
    readMore:
      "https://metricks.freshdesk.com/support/solutions/articles/73000553562-how-to-integrate-metricks-with-stripe",
    component: null,
  },

  {
    name: "Woocomerce",
    readMore: "https://wordpress.org/plugins/metricks-for-woocommerce/",
    component: null,
  },
  {
    name: "Magento",
    readMore:
      "https://metricks.freshdesk.com/support/solutions/articles/73000541668-how-to-integrate-metricks-in-your-magento-website",
    component: null,
  },
  {
    name: "Shopify",
    readMore:
      "https://metricks.freshdesk.com/support/solutions/articles/73000541580-how-to-integrate-metricks-with-shopify",
    component: null,
  },
  {
    name: "Wix",
    readMore:
      "https://metricks.freshdesk.com/support/solutions/articles/73000577966-how-to-integrate-metricks-with-your-wix-store-directly",
    component: null,
  },
  {
    name: "Paddle",
    readMore:
      "https://metricks.freshdesk.com/support/solutions/articles/73000567810-how-to-integrate-metricks-with-paddle",
    component: null,
  },
  {
    name: "Javascript",
    readMore:
      "https://metricks.freshdesk.com/support/solutions/articles/73000541672-how-to-integrate-metricks-in-your-javascript-website",
    component: null,
  },
];
