import {
  Box,
  Flex,
  Image,
  Text,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Circle,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { BaseButton } from "../button";
import CampaignTable from "./table";
import { getCookie } from "cookies-next";
import { useEffect, useState } from "react";
import { IoArrowBackSharp, IoArrowForwardSharp } from "react-icons/io5";
import { apiReqHandler } from "../../helper/apiReqHandler";
import { endpoints } from "../../utils/endpoints";
import { useRecoilState } from "recoil";
import { campaignState } from "../../state/campaign";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { BsCheck2Circle } from "react-icons/bs";
import { getCurrentUserInstance } from "../../state/localstorage";
import useUserToken from "../../hooks/useUserToken";

const Campaign = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenPauseModal,
    onOpen: onOpenPauseModal,
    onClose: onClosePauseModal,
  } = useDisclosure();
  const {
    isOpen: isOpenCreateModal,
    onOpen: onOpenCreateModall,
    onClose: onCloseCreateModal,
  } = useDisclosure();
  const [campaignStateObj, setCampaignStateObj] = useRecoilState(campaignState);

  const toast = useToast();
  const [selectedCampaign, setselectedCampaign]: any = useState(null);

  const user = getCurrentUserInstance();
  const token = useUserToken();
  const [loader, setLoader] = useState(false);

  const updateStatusHandler = async (status: "active" | "paused") => {
    setLoader(true);

    const { res, error } = await apiReqHandler({
      //   @ts-ignore
      endPoint: endpoints.campaign.UPDATE_ONE(selectedCampaign?.id),
      method: "PUT",
      payload: { status },
      //   @ts-ignore
      bearerAuth: getCookie("access_token"),
    });
    setLoader(false);

    if (error) {
      console.log("ERR", error);
      return toast({
        title: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }

    if (res) {
      onClose();
      window.location.href = `/campaigns?campaign=${selectedCampaign?.id}`;
      // navigate(`/campaigns?campaign=${selectedCampaignId}`);
    }
  };

  const checkToken = async () => {
    if (!token) return;

    await fetch(process.env.REACT_APP_API_ENDPOINT + `/auth/valid-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data?.valid) {
          return;
        } else {
          window.localStorage.clear();
          sessionStorage.removeItem("currentUserType");
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          navigate("/login");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (token) {
      checkToken();
    }
  }, [token]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent
          borderRadius="1em"
          p=".8em"
          height="235px"
          mx={{ base: ".6em", md: "auto" }}
          marginTop={{ base: "15em", md: "18em" }}
        >
          <Box
            width=".8em"
            height="235px"
            position="absolute"
            left="0"
            bgGradient="linear(360deg, #7211D4 -1.38%, #FA9445 98.36%)"
            top="0"
            borderLeftRadius="1em"
          ></Box>

          <ModalCloseButton
            bgColor="gray.100"
            borderRadius="1.5em"
            p="1.5em"
            top="1.5em"
            right="1.8em"
            fontSize="1em"
          />
          <ModalBody mt="2.5em" mr="1em">
            <Text
              fontSize={{ base: "14px", md: "1.3em" }}
              fontWeight="medium"
              mb="2em"
            >
              Are you sure you want to make{" "}
              <span style={{ color: "#7112D4" }}>{selectedCampaign?.name}</span>{" "}
              a live campaign?
            </Text>
            <Box
              width="90%"
              height="1px"
              mx="auto"
              bgColor="gray.200"
              mb=".6em"
            ></Box>
          </ModalBody>

          <ModalFooter>
            <Button
              bgColor="#F0F5FF"
              color="#7211D4"
              mr={3}
              onClick={() => onClose()}
              leftIcon={<IoArrowBackSharp />}
            >
              No
            </Button>
            <Button
              color="#fff"
              bgColor="#7211D4"
              rightIcon={<IoArrowForwardSharp />}
              onClick={() => updateStatusHandler("active")}
              isLoading={loader}
            >
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* PAUSE MODAL */}
      <Modal isOpen={isOpenPauseModal} onClose={onClosePauseModal} size="xl">
        <ModalOverlay />
        <ModalContent
          borderRadius="1em"
          p=".8em"
          height={{ base: "235px", md: "250px" }}
          mx={{ base: ".6em", md: "auto" }}
          marginTop={{ base: "16em", lg: "18em" }}
        >
          <Box
            width=".8em"
            height={{ base: "235px", md: "250px" }}
            position="absolute"
            left="0"
            bgGradient="linear(360deg, #7211D4 -1.38%, #FA9445 98.36%)"
            top="0"
            borderLeftRadius="1em"
          ></Box>

          <ModalCloseButton
            bgColor="gray.100"
            borderRadius="1.5em"
            p="1.5em"
            top="1.5em"
            right="1.8em"
            fontSize="1em"
          />
          <ModalBody mt="2.5em" mr="1em">
            <Text
              fontSize={{ base: "14px", md: "1.3em" }}
              fontWeight="medium"
              mb={{ base: "1.5em", md: "2em" }}
            >
              If you pause{" "}
              <span style={{ color: "#7112D4" }}>
                {selectedCampaign?.name.length > 7
                  ? selectedCampaign?.name
                  : selectedCampaign?.name}
              </span>{" "}
              , all your affiliates links in this campaign will be deactivated
              and all affiliates will be notified
            </Text>
            <Box
              width="90%"
              height="1px"
              mx="auto"
              bgColor="gray.200"
              mb={{ base: ".2em", md: ".6em" }}
            ></Box>
          </ModalBody>

          <ModalFooter>
            <Flex>
              <Button
                bgColor="#F0F5FF"
                color="#7211D4"
                mr={3}
                onClick={() => onClosePauseModal()}
                leftIcon={<IoArrowBackSharp />}
              >
                Cancel
              </Button>
              <Button
                color="#fff"
                bgColor="#7211D4"
                rightIcon={<IoArrowForwardSharp />}
                onClick={() => updateStatusHandler("paused")}
                isLoading={loader}
              >
                Pause
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpenCreateModal}
        onClose={onCloseCreateModal}
        size={"md"}
      >
        <ModalOverlay />
        <ModalContent
          // mx={{ base: ".6em", md: "auto" }}
          marginTop={{ base: "15em", md: "18em" }}
          borderRadius={10}
          py={5}
        >
          {/* <ModalCloseButton /> */}
          <ModalBody>
            <Flex direction={"column"} gap={5}>
              <Circle size="60px" bg="#ecfdf3">
                <Circle size="40px" bg="#d1fadf" color="#43b780">
                  <BsCheck2Circle size={25} />
                </Circle>
              </Circle>
              <Box>
                <Text fontWeight={600} fontSize={"1.5em"}>
                  Do you want to create a new organization
                </Text>
                <Text mt={5} color={"#737c89"}>
                  If you want to run a campaign for another store that is not
                  connected to your Shopify account, you can create a new
                  organization
                </Text>
              </Box>
            </Flex>
          </ModalBody>

          <ModalFooter justifyContent="space-between" px={10}>
            <Button
              borderRadius="8px"
              bgColor="#fff"
              color="#7211D4"
              borderColor={"#c4c4c4"}
              borderWidth={0.5}
              w={"45%"}
              py={7}
            >
              {/* <IoPersonOutline fontSize={14} /> */}
              <Text
                fontWeight="500"
                fontSize={{ base: "12px", md: "15px" }}
                lineHeight={{ base: "18px", md: "21px" }}
                ml="7px"
                onClick={onCloseCreateModal}
              >
                No, Cancel
              </Text>
            </Button>

            <Button
              bgColor="#7f56d9"
              color="white"
              borderRadius="8px"
              fontWeight="500"
              fontSize={{ base: "12px", md: "15px" }}
              lineHeight={{ base: "18px", md: "21px" }}
              w={"45%"}
              py={7}
              // ml="13px"
              onClick={() => {
                onCloseCreateModal();
                setCampaignStateObj({});
                navigate("/campaigns/create");
              }}
            >
              {/* <Image mr="7px" src="/assets/png/invoice-icon.png" /> */}
              Yes, Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box m="22px">
        <Flex
          gap={{ base: ".2em", md: ".8em" }}
          width={{ lg: "70%", xl: "50%" }}
          py="20px"
        >
          <Button
            leftIcon={
              <Image
                mr={{ base: "0px", md: "7px" }}
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/df57e3b5-fda6-4bb5-88aa-2420eb4a55ae.svg"
              />
            }
            color="#fff"
            bgColor="#5C14CE"
            onClick={() => {
              if (user.organisation.shopify_access_token) {
                onOpenCreateModall();
              } else {
                setCampaignStateObj({});
                navigate("/campaigns/create");
              }
            }}
            fontSize={{ base: "sm", md: "md" }}
          >
            Create Campaign
          </Button>

          <Button
            color="#32325D"
            bgColor="#E8E5F7"
            isDisabled={
              !selectedCampaign ||
              selectedCampaign?.id?.length === 0 ||
              selectedCampaign.status == "active"
            }
            onClick={() => onOpen()}
            fontSize={{ base: "sm", md: "md" }}
          >
            Activate
          </Button>

          <Button
            color="#32325D"
            bgColor="#E8E5F7"
            isDisabled={
              !selectedCampaign ||
              selectedCampaign?.id?.length === 0 ||
              selectedCampaign.status == "draft" ||
              selectedCampaign.status == "paused"
            }
            onClick={() => onOpenPauseModal()}
            fontSize={{ base: "sm", md: "md" }}
          >
            Pause
          </Button>
        </Flex>
        <CampaignTable getSelectedCampaign={setselectedCampaign} />
      </Box>
    </>
  );
};

export default Campaign;
