import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Text,
  Image,
  Input,
  Checkbox,
  Button,
} from "@chakra-ui/react";
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import { useRef, useState } from "react";
import { FormInput } from "../../formInput";
import { StepWrapper } from "../StepWrapper";
// @ts-ignore
import { getList } from "country-list-with-dial-code-and-flag";
import { InputHints } from "../../InputHints";
import { BaseButton } from "../../button";

import { IoArrowForwardSharp, IoArrowBackSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast, ToastContainer } from "react-toastify";
import {
  setLocalUserInstance,
  getCurrentUserInstance,
  setCurrentUserType,
} from "../../../state/localstorage";
import { Phone } from "../../formInput/Phone";
import React from "react";

export const AffiliateProfile = () => {
  const [, setCookie] = useCookies([
    "user",
    "user_id",
    "access_token",
    "aff_user",
    "aff_access_token",
  ]);

  const localUser = getCurrentUserInstance();

  const [firstname, setFirstname] = useState(
    localUser && localUser.firstname ? localUser.firstname : ""
  );
  const [lastname, setLastname] = useState(
    localUser && localUser.lastname ? localUser.lastname : ""
  );
  const [email, setEmail] = useState(
    localUser && localUser.email ? localUser.email : ""
  );
  const [phone, setPhone] = useState(
    localUser && localUser.phoneNumber ? localUser.phoneNumber : ""
  );
  const [countryName, setCountryName] = useState(
    localUser && localUser.countryName ? localUser.countryName : "NG"
  );
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [bot, setBot] = useState(true);
  const [isAgreed, setisAgreed] = useState(false);

  const [validKeys, setValidKeys] = useState({
    password: true,
    confirmPassword: true,
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formViewRef = useRef(null);
  const [captchaHint, setCaptchaHint] = useState(false);

  const submitHandler = async (e: React.ChangeEvent<any>) => {
    e.preventDefault();

    if (!phone || phone.length <= 6) {
      return toast.warning("Invalid phone number format");
    }

    if (
      (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)^[^ ]+$/.test(password) &&
        !/[^\w\s\_]/.test(password) &&
        !/\d/.test(password)) ||
      password !== confirmPassword
    ) {
      return;
    }

    if (!isAgreed) {
      return toast.warning("You must agree to metricks terms and conditions");
    }

    setLoading(true);

    fetch(process.env.REACT_APP_API_ENDPOINT + "auth/signup/step1", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        firstname,
        lastname,
        email,
        password,
        phoneNumber: phone,
        userType: "affiliate",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.success) {
          let expires = new Date();
          expires.setTime(expires.getTime() + 720 * 60 * 60 * 1000);
          setCurrentUserType("affiliate");
          setLocalUserInstance(
            {
              ...data.data.user,
              phoneNumber: phone,
              countryName,
            },
            "affiliate"
          );
          setCookie("aff_user", data.data.user, {
            path: "/",
            expires,
          });
          setCookie("user_id", data.data.user.user_id, {
            path: "/",
            expires,
          });

          setCookie("aff_access_token", data.data.token, {
            path: "/",
            expires,
          });

          navigate(
            `/signup/affiliate-company-profile?default-country=${
              getList().filter((info: any) => info.code === countryName)[0].name
            }`
          );
        } else {
          // @ts-ignore
          toast.warning(data.message || "An error occured");
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.warning(err.message || "An error occured");
      });
  };
  return (
    <StepWrapper
      step="STEP 1 0F 5"
      header="Create your affiliate account "
      baseText="Let's supercharge your affiliate marketing campaigns!"
      stepsMap={[
        {
          title: "User Profile",
          status: "current",
          key: 1,
        },
        {
          title: "Company Profile",
          status: "default",
          key: 2,
        },
        {
          title: "Affiliate Information",
          status: "default",
          key: 3,
        },
        {
          title: "Niche",
          status: "default",
          key: 4,
        },
        {
          title: "Affiliate Background",
          status: "default",
          key: 5,
        },
      ]}
    >
      <Flex
        position={"relative"}
        direction={"column"}
        alignItems={"center"}
        w={["90%", "60%"]}
        // backgroundColor={"red"}
        ref={formViewRef}
      >
        <Flex w={"100%"} direction={"column"}>
          <Box>
            <ToastContainer />
            <form onSubmit={submitHandler}>
              <FormInput
                label="First Name"
                type="text"
                holder="James"
                value={firstname}
                stateHandler={setFirstname}
                required={true}
              />

              <FormInput
                label="Last Name"
                type="text"
                holder="Conell"
                value={lastname}
                stateHandler={setLastname}
                required={true}
              />

              <FormInput
                label="Email Address"
                type="text"
                holder="jamesconell@domain.com "
                value={email}
                stateHandler={setEmail}
                required={true}
              />

              <Box>
                <Phone
                  holder=""
                  label="Phone Number"
                  value={phone}
                  stateHandler={setPhone}
                  required={true}
                />
                <FormInput
                  label="Password"
                  type="password"
                  value={password}
                  preventSpaces={true}
                  stateHandler={setPassword}
                  required={true}
                  holder="Enter password"
                  inputNotValid={validKeys.password == false}
                />
                {password !== "" &&
                  (password.length < 8 ||
                    !/[A-Z]/.test(password) ||
                    !/[a-z]/.test(password) ||
                    !/\d/.test(password) ||
                    !/[^\w\s\_]/.test(password)) && (
                    <Box mt="-1em">
                      <InputHints
                        showWhen={password !== ""}
                        title="Your password must be"
                        hints={[
                          {
                            passed: password.length > 7 ? true : false,
                            text: "at least 8 characters long",
                          },
                          {
                            passed: /[A-Z]/.test(password) ? true : false,
                            text: "at least one uppercase letter",
                          },
                          {
                            passed: /[a-z]/.test(password) ? true : false,
                            text: "at least one lowercase letter",
                          },
                          {
                            passed: /\d/.test(password) ? true : false,
                            text: "at least one number",
                          },
                          {
                            passed: /[^\w\s\_]/.test(password) ? true : false,
                            text: "at least one special character e.g. @#$%^&*()",
                          },
                        ]}
                      />
                    </Box>
                  )}
                <FormInput
                  label="Confirm Password"
                  type="password"
                  value={confirmPassword}
                  stateHandler={setConfirmPassword}
                  required={true}
                  preventSpaces={true}
                  inputNotValid={
                    (confirmPassword !== "" && password !== confirmPassword) ||
                    !validKeys.confirmPassword
                  }
                  holder="******************"
                />
                {confirmPassword !== "" && password !== confirmPassword && (
                  <Box mt="-1em">
                    <InputHints
                      showWhen={confirmPassword !== ""}
                      title="Passwords do not match"
                      hints={[
                        {
                          passed: password === confirmPassword ? true : false,
                          text: "Your passwords must match",
                        },
                      ]}
                    />
                  </Box>
                )}

                <Flex className="flex" mt="20px">
                  <Checkbox
                    colorScheme="purple"
                    alignItems="flex-start"
                    marginRight="7px"
                    mt="9px"
                    onChange={() => setisAgreed(!isAgreed)}
                    isChecked={isAgreed}
                    // isRequired={true}
                  />
                  <Text
                    fontSize="1rem"
                    color="#737373"
                    lineHeight="30px"
                    fontWeight="normal"
                  >
                    I have read and accepted Metricks{" "}
                    <a
                      href="https://www.metricks.io/termsandconditions"
                      style={{ color: "#8B21D6", fontWeight: "500" }}
                      target="_blank"
                    >
                      Terms of Service
                    </a>{" "}
                    and
                    <a
                      href="https://www.metricks.io/PrivacyPolicy"
                      target="_blank"
                      style={{ color: "#8B21D6", fontWeight: "500" }}
                    >
                      {" "}
                      Privacy Policy{" "}
                    </a>{" "}
                    and hereby agree to receive occasional communication with
                    product news and relevant educational content.
                  </Text>
                </Flex>

                {/* <Box my="1em">
                <ReCAPTCHA
                  // @ts-ignore
                  sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                  onChange={() => setBot(false)}
                  required={true}
                />
                {captchaHint && (
                  <small style={{ color: "red" }}>
                    Please complete reCAPTCHA to proceed.
                  </small>
                )}
              </Box> */}

                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  mb={{ base: "1em", md: "1.75em" }}
                  mt="89px"
                  flexDirection={["column-reverse", "row"]}
                >
                  <Button
                    px="0"
                    color="#5C14CE"
                    bgColor="#fff"
                    fontWeight="500"
                    fontSize="14px"
                    _hover={{ bgColor: "#fff" }}
                    _focus={{ bgColor: "#fff" }}
                    onClick={() => window.open("https://metricks.io", "_self")}
                    leftIcon={<IoArrowBackSharp />}
                  >
                    Back to the homepage
                  </Button>

                  <Box
                    width={["100%", "50%", "28%"]}
                    mb={{ base: "13px", md: "0px" }}
                  >
                    <BaseButton
                      text="Next step"
                      textColor="#fff"
                      bgColor="#5C14CE"
                      rightIcon={<IoArrowForwardSharp />}
                      type="submit"
                      loadingState={loading}
                    />
                  </Box>
                </Flex>
              </Box>
            </form>
          </Box>
        </Flex>
      </Flex>
    </StepWrapper>
  );
};
