// @ts-ignore
import { getList } from "country-list-with-dial-code-and-flag";
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Text,
} from "@chakra-ui/react";
import { StepWrapper } from "../StepWrapper";
import { BsCheckSquareFill, BsFillCaretUpFill, BsSearch } from "react-icons/bs";
import React, { useEffect, useState, useRef } from "react";
import { BaseButton } from "../../button";
import {
  IoArrowBackSharp,
  IoArrowForwardSharp,
  IoChevronDown,
  IoChevronUp,
} from "react-icons/io5";
import {
  AiFillInstagram,
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillLinkedin,
  AiOutlineClose,
} from "react-icons/ai";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast, ToastContainer } from "react-toastify";
import { MdOutlineSocialDistance, MdCancel } from "react-icons/md";

import {
  getCurrentUserInstance,
  setCurrentUserType,
  setLocalUserInstance,
} from "../../../state/localstorage";
import { FormInput } from "../../formInput";

const useOutsideAlerter = (ref: any, closeHandler: Function) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        closeHandler();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

export const AffiliateInformation = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [orgType, setOrgType] = useState(false);
  const [localUser, setLocalUser] = useState({});
  useEffect(() => {
    if (searchParams.get("type") !== null) {
      setOrgType(searchParams.get("type") ? true : false);
    }
  }, [searchParams, orgType]);

  useEffect(() => {
    const localUser = getCurrentUserInstance();
    setLocalUser(localUser);
    // setSelectedNiche([]);
    setSelectedAffiliate(
      localUser?.affiliate_type == "social_influencer"
        ? "Social Influencer"
        : localUser?.affiliate_type == "content_publisher"
        ? "Content Publisher/Email Marketer"
        : localUser?.affiliate_type === "media_buyer"
        ? "PPC/Media Buyer"
        : ""
    );
    setWebURL(localUser?.content_publisher?.website);
    setSelectedSize(localUser?.content_publisher?.avgVisitors);
    setSocials(
      localUser?.social_influencer?.socialHandles?.map((item: string) => ({
        title: item,
        url: localUser?.social_influencer?.avgFollowers[item] || "",
      })) || [{ title: "", url: "" }]
    );
    setSelectedNiche(localUser?.media_buyer ? localUser.media_buyer : []);
  }, []);
  const [cookies] = useCookies([
    "user_id",
    "access_token",
    "aff_user",
    "aff_access_token",
  ]);

  const [companyName, setCompanyName] = useState(
    // @ts-ignore
    localUser && localUser.companyname ? localUser.companyname : ""
  );
  const [afftypeSelect, setafftypeSelect] = useState(
    // @ts-ignore
    localUser && localUser.affiliate_type ? localUser.affiliate_type : ""
  );
  const [allCountries, setAllCountries] = useState(
    // @ts-ignore
    localUser && localUser.rawShipToCountries
      ? // @ts-ignore
        localUser.rawShipToCountries
      : getList().map((country: any) => {
          return { country, selected: false };
        })
  );
  const type = [
    "Content Publisher/Email Marketer",
    "PPC/Media Buyer",
    "Social Influencer",
  ];
  const size = ["", "0-100k", "100-250k", "250-500k", "500k-1M"];
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [showIndustryFilter, setShowIndustryFilter] = useState(false);
  const [showTypeFilter, setShowTypeFilter] = useState(false);
  const [showSizeFilter, setShowSizeFilter] = useState(false);
  const [allAffiliateType, setAllAffiliateType] = useState(
    type.map((country: any) => {
      return { country, selected: false };
    })
  );

  const media2 = [
    "Facebook",
    "Instagram",
    "Twitter",
    "Threads",
    "Native",
    "Organic SEO",
    "Paid Search",
    "Google Display Network",
    "Email",
    "Publisher",
    "Mobile DSP",
    "Others",
  ];
  const media = [
    {
      key: "Facebook",
      selected: false,
    },
    {
      key: "Instagram",
      selected: false,
    },
    {
      key: "Twitter",
      selected: false,
    },
    {
      key: "Threads",
      selected: false,
    },
    {
      key: "Native",
      selected: false,
    },
    {
      key: "Organic SEO",
      selected: false,
    },
    {
      key: "Google Display Network",
      selected: false,
    },
    {
      key: "Paid Search",
      selected: false,
    },
    {
      key: "Email",
      selected: false,
    },
    {
      key: "Publisher",
      selected: false,
    },
    {
      key: "Mobile DSP",
      selected: false,
    },
    {
      key: "Others",
      selected: false,
    },
  ];
  //  state redo Adam
  const [completeAffiliate, setCompleteAffiliate] = useState(type);
  const [selectedAffiliate, setSelectedAffiliate] = useState("");
  // section for content publisher
  const [webURL, setWebURL] = useState("");
  const [allSize, setAllSize] = useState(size);
  const [socialsTracker, setsocialsTracker] = useState<{ [key: string]: any }>(
    {}
  );

  const [selectedSize, setSelectedSize] = useState("");
  // section for ppc

  const [nicheData, setNicheData] = useState(media2);
  const [selectedNiche, setSelectedNiche] = useState<any[]>([]);

  // section for social influencers
  const [influencerData, setInfluencerData] = useState(media);
  const [socialData, setSocialData] = useState({ title: "", url: "" });
  const [socials, setSocials]: [
    socials: { title: string; url: string }[],
    setSocials: React.Dispatch<React.SetStateAction<any>>
  ] = useState([{ title: "", url: "" }]);
  const [socialsErrorIndex, setsocialsErrorIndex] = useState("");

  const submitHandler = () => {
    setLoading(true);
    let payload;
    let handles: any = [];
    let socialHandles: any = {};

    if (selectedAffiliate === "Content Publisher/Email Marketer") {
      payload = {
        affiliate_type: "content_publisher",
        content_publisher: {
          website: webURL,
          avgVisitors: selectedSize,
        },
      };

      if (!webURL || !selectedSize) {
        setLoading(false);
        toast.warning("Please complete all required fields");
        return;
      }

      if (!urlPattern.test(webURL)) {
        setLoading(false);
        toast.warning("Invalid website url");
        return;
      }
    } else if (selectedAffiliate === "PPC/Media Buyer") {
      payload = {
        affiliate_type: "media_buyer",
        media_buyer: selectedNiche,
      };
    } else if (selectedAffiliate === "Social Influencer") {
      let socialErr = "";
      for (let i = 0; i < socials.length; i++) {
        const hiddenSelects = [
          "Native",
          "Organic SEO",
          "Paid Search",
          "Google Display Network",
        ];

        if (!socials[i]?.url && !hiddenSelects.includes(socials[i]?.title)) {
          socialErr = `${i}`;
        }
      }

      if (socialErr) {
        setLoading(false);
        toast.warning("Please complete all required fields");
        return setsocialsErrorIndex(socialErr);
      }

      socials.map((item, index) => {
        handles.push(item.title);
        socialHandles[item.title] = item.url;
      });

      payload = {
        affiliate_type: "social_influencer",
        social_influencer: {
          socialHandles: handles,
          avgFollowers: socialHandles,
        },
      };
    }

    fetch(process.env.REACT_APP_API_ENDPOINT + "auth/affiliate-signup/step3", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.aff_access_token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);

        if (data.success) {
          setCurrentUserType("affiliate");
          setLocalUserInstance({
            ...localUser,
            affiliate_type:
              selectedAffiliate == "Content Publisher/Email Marketer"
                ? "content_publisher"
                : selectedAffiliate == "PPC/Media Buyer"
                ? "media_buyer"
                : "social_influencer",
            social_influencer: {
              socialHandles: handles,
              avgFollowers: socialHandles,
            },
            media_buyer: selectedNiche,
            content_publisher: {
              website: webURL,
              avgVisitors: selectedSize,
            },
            rawShipToCountries: allCountries,
            rawProductTypes: cardData,
          });

          const user = getCurrentUserInstance();
          user.organisation.signup_step = 4;
          // console.log("na me b dis", { ...localUser });
          // setLocalUserInstance({ ...localUser, ...user });
          if (orgType) {
            saveAndExit
              ? window.open("https://www.metricks.io", "_self")
              : navigate("/signup/affiliate-niche?type=newOrganization");
          } else {
            saveAndExit
              ? window.open("https://www.metricks.io", "_self")
              : navigate("/signup/affiliate-niche");
          }
        } else {
          toast.warning(data.message || "An error occured");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const [showIndustries, setShowIndustries] = useState(false);
  const [optionFocus, setoptionFocus] = useState("");
  const toggleHandlerMedia = () => {
    return setShowIndustries(!showIndustries);
  };

  const toggleHandler = () => {
    if (showFilter) {
      setoptionFocus("");
    } else {
      setoptionFocus("PCC");
    }
    return setShowFilter(!showFilter);
  };

  const wrapperIndustryRef = useRef(null);
  useOutsideAlerter(wrapperIndustryRef, () => setShowFilter(false));

  const toggleTypeHandler = () => {
    if (showTypeFilter) {
      setoptionFocus("");
    } else {
      setoptionFocus("affType");
    }
    return setShowTypeFilter(!showTypeFilter);
  };

  const toggleIndustryHandler = () => {
    return setShowIndustryFilter(!showIndustryFilter);
  };
  const toggleSizeHandler = () => {
    return setShowSizeFilter(!showSizeFilter);
  };
  const wrapperSizeRef = useRef(null);
  useOutsideAlerter(wrapperSizeRef, () => setShowSizeFilter(false));
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setShowIndustries(false));

  const wrapperTypeRef = useRef(null);
  useOutsideAlerter(wrapperTypeRef, () => setShowTypeFilter(false));

  const addSocialHandler = (data: object) => {
    // console.log(data);
    socialData.url = instagramSize;
    // @ts-ignore
    socialData.title = data.title;
    // if (socialData.title === "") {
    //   return;
    // }

    setSocials((currentSocials: { title: string; url: string }[]) => [
      // @ts-ignore
      ...socials.filter((socialData) => socialData.title !== ""),
      socialData,
    ]);

    setSocialData({ title: "", url: "" });
  };

  const removeSocialHandler = (data: object) => {
    // console.log(data);
    socialData.url = instagramSize;
    // @ts-ignore
    socialData.title = data.title;
    // if (socialData.title === "") {
    //   return;
    // }

    // @ts-ignore

    // console.log("yesman", influencerData);
    setSocials((currentSocials: any) =>
      currentSocials.filter(
        (currentData: any) =>
          // @ts-ignore
          currentData.title !== data.title
      )
    );

    setSocialData({ title: "", url: "" });
  };

  const [cardData, setCardData] = useState(
    // @ts-ignore
    localUser && localUser?.rawProductTypes
      ? // @ts-ignore
        localUser?.rawProductTypes
      : [
          {
            defaultIcon:
              "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/dee212f9-5a5d-4fd6-869d-b4e0fa080ed3.svg",
            coloredIcon: "/assets/svg/colored-ecommerce.svg",
            title: "E-commerce",
            selected: false,
          },
          {
            defaultIcon:
              "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/a7631722-e9dd-4f15-94de-f9c97efe7cba.svg",
            coloredIcon:
              "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/dfee292c-71af-43d6-87c9-5307de051e34.svg",
            title: "SaaS",
            selected: false,
          },
        ]
  );

  const [saveAndExit, setSaveAndExit] = useState(false);
  const [invalidURL, setInValidURL] = useState(false);

  const [loading, setLoading] = useState(false);
  const urlPattern = new RegExp(
    // "^(https?:\\/\\/)?" + // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$", // validate fragment locator
    "i"
  );

  const mediaBuyerData = [
    "Facebook and Instagram",
    "Google Display Network",
    "Influencer",
    "Paid Search",
    "Organic SEO",
    "Native",
    "Email",
    "Publisher",
    "Mobile DSP",
    "Others",
  ];

  const [affiliateType, setAffiliateType] = useState("");

  const [teamSize, setTeamSize] = useState(size[0]);
  const [mediaBuyer, setMediaBuyer] = useState(mediaBuyerData[2]);
  const [socialMedia, setSocialMedia] = useState(mediaBuyerData[1]);
  const [instagramSize, setInstagramSize] = useState(size[0]);
  const [facebookSize, setFacebookSize] = useState(size[0]);

  const [socialLinks, setSocialLinks] = useState([]);

  const showSocialsSelect = (type: string) => {
    const hiddenSelects = [
      "Native",
      "Organic SEO",
      "Paid Search",
      "Google Display Network",
    ];

    if (hiddenSelects.includes(type)) {
      return false;
    }
    return true;
  };

  const affType = () => {
    return allAffiliateType
      .filter((countryData: any) => countryData.selected)
      .map((data: any) => data.country)[0];
  };

  const step = [
    {
      title: "User Profile",
      status: "passed",
      key: 1,
    },
    {
      title: "Company Profile",
      status: "passed",
      key: 2,
    },
    {
      title: "Affiliate Information",
      status: "current",
      key: 3,
    },
    {
      title: "Niche",
      status: "default",
      key: 4,
    },
    {
      title: "Affiliate Background",
      status: "default",
      key: 5,
    },
  ];
  const orgSteps = [
    {
      title: "Company Profile",
      status: "passed",
      key: 1,
    },
    {
      title: "Affiliate Information",
      status: "current",
      key: 2,
    },
    {
      title: "Niche",
      status: "default",
      key: 3,
    },
    {
      title: "Affiliate Background",
      status: "default",
      key: 4,
    },
  ];
  const steps = orgType ? orgSteps : step;
  return (
    <StepWrapper
      step={steps.map((item: any) => {
        if (item.status === "current") {
          let step = `STEP ${item.key} OF ${steps.length}`;
          return step;
        }
      })}
      header="Setting up your affiliate account"
      baseText="Affiliate Information"
      stepsMap={steps}
    >
      <Flex
        position={"relative"}
        direction={"column"}
        alignItems={"center"}
        w={["90%", "60%"]}
      >
        <Flex w={"100%"} direction={"column"}>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            height="80vh"
          >
            <Box ref={wrapperRef}>
              <ToastContainer />
              <Box mt="1.6em">
                <Heading
                  fontSize="15px"
                  mb="1em"
                  lineHeight="40px"
                  fontWeight={showTypeFilter ? "medium" : "normal"}
                  color={showTypeFilter ? " #5C14CE" : "#737373"}
                >
                  What type of affiliate are you?{" "}
                  <span style={{ color: "#dc3545" }}>*</span>
                </Heading>

                <Flex
                  borderBottom={
                    showTypeFilter ? "1px solid #5C14CE" : "1px solid #B6BDC3"
                  }
                  pb=".3em"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  ref={wrapperTypeRef}
                  cursor="pointer"
                  onClick={toggleTypeHandler}
                >
                  <Flex rowGap=".5em" columnGap=".5em" flexWrap="wrap">
                    {selectedAffiliate !== "" ? (
                      <Text
                        mr=".8em"
                        fontSize="15px"
                        fontWeight="500"
                        color="#000"
                      >
                        {selectedAffiliate}
                      </Text>
                    ) : (
                      <Text
                        cursor="pointer"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(0, 0, 0, 0.6)"
                        onClick={toggleTypeHandler}
                      >
                        Select
                      </Text>
                    )}
                  </Flex>
                  <Box>
                    {!showTypeFilter ? (
                      <IoChevronDown
                        fontSize="20px"
                        color="#737373"
                        cursor="pointer"
                        onClick={toggleTypeHandler}
                      />
                    ) : (
                      <IoChevronUp
                        fontSize="20px"
                        color="#737373"
                        cursor="pointer"
                        onClick={toggleTypeHandler}
                      />
                    )}
                  </Box>
                </Flex>

                {showTypeFilter && (
                  <Box
                    p={["1em", "1em", "1.5em"]}
                    my="1em"
                    boxShadow="xl"
                    rounded="xl"
                    maxH="40vh"
                    overflowY="scroll"
                    ref={wrapperTypeRef}
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      my=".5em"
                    >
                      <Text fontSize="14px" color="#737373" fontWeight="500">
                        Affiliate Type
                      </Text>

                      <BsFillCaretUpFill
                        color="#CACBD4"
                        cursor="pointer"
                        onClick={toggleTypeHandler}
                      />
                    </Flex>

                    {completeAffiliate.map((countryData: any, i: any) => {
                      return (
                        <Box
                          justifyContent="space-between"
                          alignItems="center"
                          key={i}
                          cursor="pointer"
                          _hover={{ bgColor: "gray.50" }}
                          p=".5em"
                          mb="4px"
                          borderRadius="3px"
                          onClick={() => {
                            setSelectedAffiliate(countryData);
                            toggleTypeHandler();
                          }}
                        >
                          <Flex alignItems="center" mt="1em">
                            <Text
                              ml="1em"
                              color="#737373"
                              fontWeight="500"
                              fontSize="14px"
                            >
                              {countryData}
                            </Text>
                          </Flex>
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>

              {selectedAffiliate === "Content Publisher/Email Marketer" && (
                <>
                  <FormInput
                    label="Website Link"
                    type="text"
                    holder="https://jamesoconell.com"
                    holderSpecific="Enter URL"
                    value={webURL}
                    stateHandler={setWebURL}
                    required={true}
                  />
                  {webURL !== "" && invalidURL && !urlPattern.test(webURL) && (
                    <small style={{ color: "#fc8383" }}>
                      Please input a valid URL to proceed.
                    </small>
                  )}
                  <Box>
                    <Heading
                      fontSize="15px"
                      mb="1em"
                      fontWeight={showSizeFilter ? "medium" : "normal"}
                      color={showSizeFilter ? " #5C14CE" : "#737373"}
                      lineHeight="40px"
                      mt="2em"
                    >
                      Average Monthly Visitor{" "}
                      <span style={{ color: "#dc3545" }}>*</span>
                    </Heading>

                    <Flex
                      borderBottom={
                        showSizeFilter
                          ? "1px solid #5C14CE"
                          : "1px solid #B6BDC3"
                      }
                      pb=".3em"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      ref={wrapperSizeRef}
                      cursor="pointer"
                      onClick={toggleSizeHandler}
                    >
                      <Flex rowGap=".5em" columnGap=".5em" flexWrap="wrap">
                        {selectedSize ? (
                          <Box
                            alignItems="center"
                            display="flex"
                            borderRadius="4px"
                          >
                            <Text
                              mr=".8em"
                              fontSize="15px"
                              fontWeight="500"
                              color="#000"
                            >
                              {selectedSize}
                            </Text>
                          </Box>
                        ) : (
                          <Text
                            fontSize="14px"
                            fontWeight="500"
                            color="rgba(0, 0, 0, 0.6)"
                            cursor="pointer"
                            onClick={toggleSizeHandler}
                          >
                            Select option
                          </Text>
                        )}
                      </Flex>
                      <Box>
                        {!showSizeFilter ? (
                          <IoChevronDown
                            cursor="pointer"
                            fontSize="20px"
                            color="#737373"
                            onClick={toggleSizeHandler}
                          />
                        ) : (
                          <IoChevronUp
                            cursor="pointer"
                            fontSize="20px"
                            color="#737373"
                            onClick={toggleSizeHandler}
                          />
                        )}
                      </Box>
                    </Flex>

                    {showSizeFilter && (
                      <Box
                        p={["1em", "1em", "1.5em"]}
                        my="1em"
                        boxShadow="xl"
                        rounded="xl"
                        maxH="60vh"
                        overflowY="scroll"
                        ref={wrapperSizeRef}
                      >
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          my=".5em"
                        >
                          <Text
                            color="#737373"
                            fontWeight="500"
                            fontSize="14px"
                          >
                            Number of followers
                          </Text>

                          <BsFillCaretUpFill
                            color="#CACBD4"
                            cursor="pointer"
                            onClick={toggleSizeHandler}
                          />
                        </Flex>

                        {allSize.map((countryData: any, i: any) => {
                          return (
                            <Box
                              display={
                                searchQuery !== "" &&
                                !countryData.country
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase())
                                  ? "none"
                                  : "flex"
                              }
                              justifyContent="space-between"
                              alignItems="center"
                              key={i}
                              cursor="pointer"
                              _hover={{ bgColor: "gray.50" }}
                              p=".5em"
                              my="2px"
                              borderRadius="3px"
                              onClick={() => {
                                setSelectedSize(countryData);
                                toggleSizeHandler();
                              }}
                            >
                              <Flex alignItems="center" mt="1em">
                                <Text
                                  ml="1em"
                                  color="#737373"
                                  fontWeight="500"
                                  fontSize="14px"
                                >
                                  {countryData}
                                </Text>
                              </Flex>
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                  </Box>
                </>
              )}

              {selectedAffiliate === "PPC/Media Buyer" && (
                <>
                  <Box mt="2em">
                    <Heading
                      fontSize="15px"
                      mb="1em"
                      lineHeight="40px"
                      fontWeight={showFilter ? "medium" : "normal"}
                      color={showFilter ? " #5C14CE" : "#737373"}
                    >
                      Social Channels{" "}
                      <span style={{ color: "#dc3545" }}>*</span>
                    </Heading>

                    <Box ref={wrapperIndustryRef}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        borderBottom={
                          showFilter ? "1px solid #5C14CE" : "1px solid #B6BDC3"
                        }
                        pb=".3em"
                        mb="1em"
                        cursor="pointer"
                        onClick={toggleHandler}
                      >
                        <Flex columnGap=".5em" rowGap=".5em" flexWrap="wrap">
                          {selectedNiche && selectedNiche.length ? (
                            // @ts-ignore
                            selectedNiche.map((oneNiche, i) => (
                              <Box
                                key={i}
                                bgColor="#F0F5FF"
                                py={["3px", "3px", "6px"]}
                                px=".4em"
                                alignItems="center"
                                display="flex"
                                borderRadius="4px"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <Text
                                  mr=".8em"
                                  fontSize="14px"
                                  fontWeight="500"
                                  color="#000"
                                >
                                  {oneNiche}
                                </Text>
                                <MdCancel
                                  color="#000"
                                  cursor="pointer"
                                  onClick={() => {
                                    setSelectedNiche(
                                      // @ts-ignore
                                      selectedNiche.filter((item, i) => {
                                        return item !== oneNiche;
                                      })
                                    );
                                  }}
                                />
                              </Box>
                            ))
                          ) : (
                            <Text
                              fontSize="14px"
                              fontWeight="500"
                              color="rgba(0, 0, 0, 0.6)"
                            >
                              Select option
                            </Text>
                          )}
                        </Flex>

                        <Box>
                          {!showFilter ? (
                            <IoChevronDown
                              fontSize="20px"
                              color="#737373"
                              cursor="pointer"
                              onClick={toggleHandler}
                            />
                          ) : (
                            <IoChevronUp
                              fontSize="20px"
                              color="#737373"
                              cursor="pointer"
                              onClick={toggleHandler}
                            />
                          )}
                        </Box>
                      </Box>

                      {showFilter && (
                        <Box
                          p={["1em", "1em", "1.5em"]}
                          my="1em"
                          boxShadow="xl"
                          rounded="xl"
                          maxH="60vh"
                          overflowY="scroll"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            my=".2em"
                          >
                            <Text
                              fontSize="14px"
                              color="#737373"
                              fontWeight="500"
                            >
                              Search Media Buyer
                            </Text>

                            <BsFillCaretUpFill
                              color="#CACBD4"
                              cursor="pointer"
                              onClick={toggleHandler}
                            />
                          </Flex>

                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={<BsSearch color="gray.300" />}
                            />
                            <Input
                              type="text"
                              _focus={{ ring: "0" }}
                              border="0"
                              bgColor="#F4F6F8"
                              value={searchQuery}
                              onChange={(e: React.ChangeEvent<any>) =>
                                setSearchQuery(e.target.value)
                              }
                            />
                          </InputGroup>

                          {nicheData.map((singleNiche, i) => {
                            return (
                              <Box
                                key={i}
                                display={
                                  searchQuery !== "" &&
                                  !singleNiche
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                                    ? "none"
                                    : "flex"
                                }
                                justifyContent="space-between"
                                alignItems="center"
                                my="1em"
                                cursor="pointer"
                                _hover={{ bgColor: "gray.50" }}
                                onClick={() => {
                                  // @ts-ignore

                                  if (
                                    // @ts-ignore
                                    selectedNiche?.includes(singleNiche)
                                  ) {
                                    setSelectedNiche(
                                      // @ts-ignore
                                      selectedNiche.filter((item, i) => {
                                        console.log(item);
                                        return item !== singleNiche;
                                      })
                                    );
                                  } else {
                                    // @ts-ignore
                                    setSelectedNiche([
                                      ...selectedNiche,
                                      singleNiche,
                                    ]);
                                  }
                                }}
                                p=".5em"
                                borderRadius="3px"
                              >
                                <Text
                                  fontSize="14px"
                                  color="#737373"
                                  fontWeight="500"
                                >
                                  {singleNiche}
                                </Text>
                                {/* @ts-ignore */}
                                {selectedNiche?.includes(singleNiche) ? (
                                  <BsCheckSquareFill color="#5C15CE" />
                                ) : (
                                  <Box
                                    width="1em"
                                    height="1em"
                                    border="1.5px solid gray"
                                    borderRadius="4px"
                                  ></Box>
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </>
              )}
              {selectedAffiliate === "Social Influencer" && (
                <>
                  <Box mt="2em">
                    <Heading
                      fontSize="15px"
                      mb="1em"
                      lineHeight="40px"
                      fontWeight={showIndustries ? "medium" : "normal"}
                      color={showIndustries ? " #5C14CE" : "#737373"}
                    >
                      Social Media Platform{" "}
                      <span style={{ color: "#dc3545" }}>*</span>
                    </Heading>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      borderBottom={
                        showIndustries
                          ? "1px solid #5C14CE"
                          : "1px solid #B6BDC3"
                      }
                      pb=".3em"
                      mb="1em"
                      cursor="pointer"
                      onClick={toggleHandlerMedia}
                      ref={wrapperRef}
                    >
                      <Flex columnGap=".5em" rowGap=".5em" flexWrap="wrap">
                        {socials &&
                        socials?.length &&
                        socials[0]?.title !== "" ? (
                          socials.map((social, i) => (
                            <Box
                              key={i}
                              bgColor="#F0F5FF"
                              py={["3px", "3px", "6px"]}
                              px=".4em"
                              alignItems="center"
                              display="flex"
                              borderRadius="4px"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Text
                                mr=".8em"
                                fontSize="14px"
                                fontWeight="500"
                                color="#000"
                              >
                                {social.title}
                              </Text>
                              <AiOutlineClose
                                color="#000"
                                cursor="pointer"
                                onClick={() => {
                                  const newState = influencerData.map(
                                    (data: any, x: any) => {
                                      if (social.title == data.key) {
                                        return {
                                          ...data,
                                          selected: !data.selected,
                                        };
                                      } else {
                                        return data;
                                      }
                                    }
                                  );

                                  removeSocialHandler({
                                    title: social.title,
                                    url: "socialData.url",
                                  });

                                  setInfluencerData(newState);
                                }}
                              />
                            </Box>
                          ))
                        ) : (
                          <Text
                            fontSize="14px"
                            fontWeight="500"
                            color="rgba(0, 0, 0, 0.6)"
                          >
                            {" "}
                            Select options
                          </Text>
                        )}
                      </Flex>

                      <Box>
                        {!showIndustries ? (
                          <IoChevronDown
                            cursor="pointer"
                            fontSize="20px"
                            color="#737373"
                            onClick={toggleHandlerMedia}
                          />
                        ) : (
                          <IoChevronUp
                            cursor="pointer"
                            fontSize="20px"
                            color="#737373"
                            onClick={toggleHandlerMedia}
                          />
                        )}
                      </Box>
                    </Box>

                    {showIndustries && (
                      <Box
                        p={["1em", "1em", "1.5em"]}
                        my="1em"
                        boxShadow="xl"
                        rounded="xl"
                        maxH="50vh"
                        overflowY="scroll"
                        ref={wrapperRef}
                      >
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          my=".2em"
                        >
                          <Text
                            fontSize="14px"
                            color="#737373"
                            fontWeight="500"
                          >
                            Search Social Media Platform
                          </Text>

                          <BsFillCaretUpFill
                            color="#CACBD4"
                            cursor="pointer"
                            onClick={toggleHandlerMedia}
                          />
                        </Flex>

                        <InputGroup>
                          <InputLeftElement
                            pointerEvents="none"
                            children={<BsSearch color="gray.300" />}
                          />
                          <Input
                            type="text"
                            _focus={{ ring: "0" }}
                            border="0"
                            bgColor="#F4F6F8"
                            value={searchQuery}
                            onChange={(e: React.ChangeEvent<any>) =>
                              setSearchQuery(e.target.value)
                            }
                          />
                        </InputGroup>

                        {influencerData.map((singleNiche: any, i: any) => {
                          return (
                            <Box
                              key={i}
                              display={
                                searchQuery !== "" &&
                                !singleNiche.key
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase())
                                  ? "none"
                                  : "flex"
                              }
                              justifyContent="space-between"
                              alignItems="center"
                              my="1em"
                              cursor="pointer"
                              _hover={{ bgColor: "gray.50" }}
                              onClick={() => {
                                const newState = influencerData.map(
                                  (data: any, x: any) => {
                                    if (singleNiche == data) {
                                      return {
                                        ...data,
                                        selected: !data.selected,
                                      };
                                    } else {
                                      return data;
                                    }
                                  }
                                );
                                if (!singleNiche.selected) {
                                  // console.log("here");
                                  setSocialData({
                                    title: singleNiche.key,
                                    url: "socialData.url",
                                  });

                                  addSocialHandler({
                                    title: singleNiche.key,
                                    url: "socialData.url",
                                  });
                                } else {
                                  removeSocialHandler({
                                    title: singleNiche.key,
                                    url: "socialData.url",
                                  });
                                }
                                setInfluencerData(newState);
                              }}
                              p=".5em"
                              borderRadius="3px"
                            >
                              <Text
                                fontSize="14px"
                                color="#737373"
                                fontWeight="500"
                              >
                                {singleNiche.key}
                              </Text>

                              {singleNiche.selected ? (
                                <BsCheckSquareFill color="#5C15CE" />
                              ) : (
                                <Box
                                  width="1em"
                                  height="1em"
                                  border="1.5px solid gray"
                                  borderRadius="4px"
                                ></Box>
                              )}
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                  </Box>
                  <Box my="1.6em">
                    {socials.length >= 1 &&
                      socials[0].title !== "" &&
                      socials.map(
                        (
                          socialInfo: { title: string; url: string },
                          i: any
                        ) => {
                          return (
                            <Flex
                              alignItems={{ md: "center" }}
                              justifyContent="space-between"
                              flexDir={{ base: "column", md: "row" }}
                              borderBottom={{ md: "1.5px solid #B6BDC3" }}
                              pb="4px"
                              px={{ base: "6px", md: "" }}
                              pt={{ base: "4px", md: "" }}
                              bgColor={{ md: "", base: "#F0F5FF" }}
                              rounded={{ base: "4px", md: "" }}
                              my={{ base: "10px", md: "8px" }}
                              key={i}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                bgColor="#F0F5FF"
                                borderRadius=".4em"
                                py={["3px", "3px", "6px"]}
                                px=".4em"
                                width={{ base: "38%", md: "38%", lg: "45%" }}
                                mr=".5em"
                              >
                                {socialInfo.title.toLowerCase() ===
                                "instagram" ? (
                                  <AiFillInstagram color="#999999" />
                                ) : socialInfo.title.toLowerCase() ===
                                  "facebook" ? (
                                  <AiFillFacebook color="#999999" />
                                ) : socialInfo.title.toLowerCase() ===
                                  "twitter" ? (
                                  <AiFillTwitterSquare color="#999999" />
                                ) : socialInfo.title.toLowerCase() ===
                                  "linkedin" ? (
                                  <AiFillLinkedin color="#999999" />
                                ) : (
                                  <MdOutlineSocialDistance color="#999999" />
                                )}
                                <Text
                                  fontSize={{ base: "12px", md: "14px" }}
                                  fontWeight="500"
                                  color="#000"
                                  ml=".5em"
                                >
                                  {socialInfo.title}
                                </Text>
                              </Box>

                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                bgColor="#F0F5FF"
                                borderRadius={{ md: ".4em" }}
                                width={{ base: "100%", md: "58%", lg: "53%" }}
                                ml={{ md: ".5em", base: "0px" }}
                              >
                                {showSocialsSelect(socialInfo.title) ? (
                                  <Box width="100%">
                                    <Select
                                      placeholder="Select option"
                                      fontSize={{ base: "12px", md: "14px" }}
                                      fontWeight="500"
                                      border={
                                        socialsErrorIndex === `${i}`
                                          ? "1px solid #cb1717"
                                          : ""
                                      }
                                      color="#000"
                                      // value={socials[i].url}
                                      onChange={(e: React.ChangeEvent<any>) => {
                                        socials[i].url = e.target.value;
                                        setsocialsErrorIndex("");
                                      }}
                                    >
                                      {size.map((item) => {
                                        return (
                                          <option
                                            hidden={item === "" ? true : false}
                                            disabled={
                                              item === "" ? true : false
                                            }
                                            key={item}
                                            value={item}
                                          >
                                            <Text
                                              fontSize={{
                                                base: "12px",
                                                md: "14px",
                                              }}
                                              color="#737373"
                                              fontWeight="500"
                                            >
                                              {item}
                                            </Text>
                                          </option>
                                        );
                                      })}
                                    </Select>
                                  </Box>
                                ) : (
                                  ""
                                )}
                                <MdCancel
                                  color="#000"
                                  style={{ marginLeft: "auto" }}
                                  cursor="pointer"
                                  onClick={() => {
                                    setSocials((currentSocials: any) =>
                                      currentSocials.filter(
                                        (currentData: any) =>
                                          currentData !== currentSocials[i]
                                      )
                                    );
                                    const newState = influencerData.map(
                                      (data: any, x: any) => {
                                        if (socialInfo.title === data.key) {
                                          return {
                                            ...data,
                                            selected: false,
                                          };
                                        } else {
                                          // console.log("there");
                                          return data;
                                        }
                                      }
                                    );
                                    setInfluencerData(newState);
                                  }}
                                />
                              </Box>
                            </Flex>
                          );
                        }
                      )}
                  </Box>
                </>
              )}
            </Box>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              mb={{ base: "1em", md: "1.75em" }}
              // mt="2.5em"
              flexDirection={{ base: "column-reverse", md: "row" }}
              rowGap=".6em"
              mt={{ base: "3em", lg: showFilter ? "3em" : "25vh" }}
            >
              <Button
                px="0"
                color="#5C14CE"
                bgColor="#fff"
                fontWeight="500"
                fontSize="14px"
                _hover={{ bgColor: "#fff" }}
                _focus={{ bgColor: "#fff" }}
                onClick={() =>
                  navigate(
                    "/signup/affiliate-company-profile?default-country=Nigeria"
                  )
                }
                leftIcon={<IoArrowBackSharp />}
              >
                Back to the previous step
              </Button>
              <Box
                width={["100%", "50%", "28%"]}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                flexDirection={["column-reverse", "row"]}
                rowGap=".6em"
                mb={{ base: "13px", md: "0px" }}
                justifySelf="flex-end"
              >
                <Box ml={["0em", "1em"]} w="100%">
                  <BaseButton
                    text="Next step"
                    // eer
                    textColor="#fff"
                    bgColor="#5C14CE"
                    loadingState={loading}
                    rightIcon={<IoArrowForwardSharp />}
                    clickEventHandler={submitHandler}
                  />
                </Box>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </StepWrapper>
  );
};
