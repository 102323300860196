import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FormInput } from "../../formInput";
import { TextEditor } from "../../TextEditor";
import SettingsInput from "../components/SettingsInput";
import {
  AiFillInstagram,
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillLinkedin,
} from "react-icons/ai";
import { MdCancel, MdOutlineSocialDistance } from "react-icons/md";
import OrganizationSettingsFooter from "../components/OrganizationSettingsFooter";
import draftToHtml from "draftjs-to-html";
import {
  convertToRaw,
  ContentState,
  EditorState,
  convertFromHTML,
} from "draft-js";

const Organization = ({
  orgCompanyName,
  orgDisplayName,
  orgDescription,
  orgWebsite,
  orgSocials,
  currentStep,
  setCurrentStep,
  getOrgDetails,
  setrefreshData,
}: {
  orgCompanyName: string;
  orgDisplayName: string;
  orgDescription: string;
  orgWebsite: string;
  orgSocials: any;
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>;
  currentStep: string;
  getOrgDetails: any;
  setrefreshData: React.Dispatch<React.SetStateAction<number>>;
}) => {
  // console.log("im inside ord", {
  //   orgCompanyName,
  //   orgDisplayName,
  //   orgDescription,
  //   orgWebsite,
  //   orgSocials,
  //   currentStep,
  //   setCurrentStep,
  // });
  const [isEmpty, setisEmpty] = useState(null);
  const [companyName, setCompanyName] = useState(orgCompanyName);
  const [displayName, setDisplayName] = useState(orgDisplayName);
  const [description, setDescription] = useState(() => {
    if (orgDescription)
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(orgDescription).contentBlocks,
          convertFromHTML(orgDescription).entityMap
        )
      );
    else EditorState.createEmpty();
  });
  const [webURL, setWebURL] = useState(orgWebsite);
  const [socialData, setSocialData] = useState({ title: "", url: "" });
  const [newUpdate, setnewUpdate] = useState(false);
  const [count, setcount] = useState(1);
  const [socials, setSocials]: [
    socials: { title: string; url: string }[],
    setSocials: React.Dispatch<React.SetStateAction<any>>
  ] = useState(
    orgSocials && Object.keys(orgSocials).length
      ? Object.keys(orgSocials).map((social) => {
          return { title: social, url: orgSocials[social] };
        })
      : [{ title: "", url: "" }]
  );

  const urlPattern = new RegExp(
    // "^(https?:\\/\\/)?" + // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$", // validate fragment locator
    "i"
  );

  useEffect(() => {
    if (count > 1) {
      setnewUpdate(true);
      sessionStorage.setItem("advertizerSettingsChange", "new update");
    }
    setcount(count + 1);
  }, [companyName, displayName, description, webURL, socials]);

  const addSocialHandler = () => {
    if (socialData.title === "" || socialData.url === "") {
      return;
    }

    setSocials((currentSocials: { title: string; url: string }[]) => [
      ...currentSocials.filter((socialData) => socialData.title !== ""),
      socialData,
    ]);

    setSocialData({ title: "", url: "" });
  };

  const updateObj = {
    companyname: companyName,
    displayname: displayName,
    description: description
      ? draftToHtml(convertToRaw(description?.getCurrentContent()))
      : "",
    website: webURL,
    socialHandles:
      socials.length >= 1
        ? socials.reduce(
            (acc, cur) => ({ ...acc, [cur.title.toLowerCase()]: cur.url }),
            {}
          )
        : {},
  };

  const validator = () => {
    if (!companyName)
      return { isValid: false, error: "Company Name is Required" };
    if (!webURL)
      return { isValid: false, error: "Organization Website is Required" };
    if (webURL) {
      if (!urlPattern.test(webURL)) {
        return { isValid: false, error: "Organization Website is Not Valid" };
      }
    }
    return { isValid: true, error: "" };
  };
  useEffect(() => {
    setCompanyName(orgCompanyName);
    setDisplayName(orgDisplayName);
    // @ts-config
    setDescription(() => {
      if (orgDescription)
        return EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(orgDescription).contentBlocks,
            convertFromHTML(orgDescription).entityMap
          )
        );
      else EditorState.createEmpty();
    });
    setWebURL(orgWebsite);
  }, [setCompanyName, setDisplayName]);

  return (
    <Box className="w-full">
      {/* <Heading fontSize="1rem" color="#32325D" fontWeight={"600"} pb="34px">
        {currentStep}
      </Heading> */}
      <SettingsInput
        label="Organization's Name"
        type="text"
        value={companyName}
        changeHandler={setCompanyName}
        isRequired={true}
      />
      <SettingsInput
        label="Display Name"
        type="text"
        value={displayName}
        changeHandler={setDisplayName}
        isRequired={true}
      />

      <Flex
        alignItems="flex-start"
        // justifyContent="space-between"
        columnGap="1em"
        my="1em"
        flexDirection={["column", "row"]}
        borderTop={["0px", "0.5px solid"]}
        borderTopColor={{ md: "rgba(50, 50, 93, 0.30)" }}
        py={[".5em", "1em"]}
      >
        <Flex width={["100%", "30%"]}>
          <Text
            fontSize="14px"
            color="#32325D"
            fontWeight="500"
            flexGrow={1}
            width={{ base: "100%", md: "40%" }}
            textAlign="left"
            mb={[".3em", "0em"]}
          >
            Description
          </Text>
        </Flex>

        <Flex width={["100%", "60%"]}>
          <Box
            width={{ base: "100%" }}
            border="0.6px solid rgba(50, 50, 93, 0.30)"
            borderRadius="8px"
            padding="12px"
          >
            <TextEditor
              contentState={description}
              stateHandler={setDescription}
            />
            {isEmpty === "description" ? (
              <Text
                fontSize="14px"
                color="red.300"
                fontWeight="medium"
                className="descError"
              >
                This field is required
              </Text>
            ) : null}
          </Box>
        </Flex>
      </Flex>

      <SettingsInput
        label="Website Link"
        type="text"
        value={webURL}
        changeHandler={setWebURL}
        isRequired={false}
      />

      {webURL !== "" ||
        isEmpty === "organization website" ||
        (!urlPattern.test(webURL) && (
          <small style={{ color: "#fc8383" }}>Please input a valid URL.</small>
        ))}

      <Box
        display="flex"
        // justifyContent="space-between"
        columnGap="1em"
        my="1em"
        flexDirection={["column", "row"]}
        borderTop={["0px", "0.5px solid"]}
        borderTopColor={{ md: "rgba(50, 50, 93, 0.30)" }}
        py={[".5em", "1em"]}
      >
        <Flex width={["100%", "30%"]}>
          <Text
            fontSize="14px"
            color="#32325D"
            fontWeight="500"
            flexGrow={1}
            width={{ base: "100%", md: "40%" }}
            textAlign="left"
            mb={[".3em", "0em"]}
          >
            Social Media Handle
          </Text>
        </Flex>

        <Flex width={["100%", "60%"]} direction={"column"}>
          <Flex alignItems="center" justifyContent="space-between" w={"100%"}>
            <Box mr={[".5em", "1em"]} flexGrow={1} mb="7px">
              <FormInput
                label=""
                type="text"
                placeholder="Link Title"
                holder="Link Title"
                value={socialData.title}
                changeEventHandler={(value: string) =>
                  setSocialData({
                    title: value,
                    url: socialData.url,
                  })
                }
              />
            </Box>
            <Box
              ml={[".5em", "1em"]}
              display="flex"
              alignItems="center"
              flexGrow={1}
            >
              <FormInput
                label=""
                type="text"
                placeholder="Enter username"
                holder="Enter URL"
                value={socialData.url}
                changeEventHandler={(value: string) =>
                  setSocialData({
                    title: socialData.title,
                    url: value,
                  })
                }
              />

              <Button
                ml={[".5em", "1em"]}
                bgColor="#5C14CE"
                color="#fff"
                borderRadius=".8em"
                fontWeight="500"
                fontSize="13px"
                px="2em"
                onClick={addSocialHandler}
              >
                Add
              </Button>
            </Box>
          </Flex>

          {socials.length >= 1 &&
            socials[0].title !== "" &&
            socials.map(
              (socialInfo: { title: string; url: string }, i: any) => {
                return (
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    borderBottom="1.5px solid #B6BDC3"
                    pb="4px"
                    my="8px"
                    key={i}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      bgColor="#F0F5FF"
                      borderRadius=".4em"
                      py={["3px", "3px", "6px"]}
                      px=".4em"
                      width={{ base: "38%", md: "38%", lg: "45%" }}
                      mr=".5em"
                    >
                      {socialInfo.title.toLowerCase() === "instagram" ? (
                        <AiFillInstagram color="#999999" />
                      ) : socialInfo.title.toLowerCase() === "facebook" ? (
                        <AiFillFacebook color="#999999" />
                      ) : socialInfo.title.toLowerCase() === "twitter" ? (
                        <AiFillTwitterSquare color="#999999" />
                      ) : socialInfo.title.toLowerCase() === "linkedin" ? (
                        <AiFillLinkedin color="#999999" />
                      ) : (
                        <MdOutlineSocialDistance color="#999999" />
                      )}
                      <Text
                        fontSize="14px"
                        fontWeight="400"
                        color="gray.800"
                        ml=".5em"
                      >
                        {socialInfo.title}
                      </Text>
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      bgColor="#F0F5FF"
                      borderRadius=".4em"
                      py={["3px", "3px", "6px"]}
                      px=".4em"
                      width={{ base: "56%", md: "58%", lg: "53%" }}
                      ml=".5em"
                    >
                      <Text
                        fontSize="14px"
                        fontWeight="400"
                        color="gray.800"
                        mr=".5em"
                        overflowWrap="break-word"
                      >
                        {socialInfo.url.length > 15
                          ? socialInfo.url.slice(0, 15) + "..."
                          : socialInfo.url}
                      </Text>
                      <MdCancel
                        color="#000"
                        cursor="pointer"
                        onClick={() =>
                          setSocials((currentSocials: any) =>
                            currentSocials.filter(
                              (currentData: any) =>
                                currentData !== currentSocials[i]
                            )
                          )
                        }
                      />
                    </Box>
                  </Flex>
                );
              }
            )}
        </Flex>
      </Box>

      {/* <OrganizationSettingsFooter
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        validator={validator}
        dataToUpdate={updateObj}
        getOrgDetails={getOrgDetails}
      /> */}
      <OrganizationSettingsFooter
        setRefreshData={setrefreshData}
        hasUpdate={newUpdate}
        sethasUpdate={setnewUpdate}
        validator={validator}
        dataToUpdate={updateObj}
        getOrgDetails={getOrgDetails}
      />
    </Box>
  );
};

export default Organization;
