import {
  Avatar,
  Box,
  Button,
  Flex,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { IoIosArrowBack } from "react-icons/io";
import { PiTelegramLogoDuotone } from "react-icons/pi";
import React, { useState } from "react";
import moment from "moment";
import Message from "./Message";

const chatData = {
  name: "Angelina Martin",
  id: 3,
  image:
    "https://tse4.explicit.bing.net/th?id=OIP.y-R6b74yIBCpAOfYIBVQpAHaLH&pid=Api&P=0&w=300&h=300",
  isOnline: true,
};

const AffiliateChatView = ({
  updateCurrentChat,
  currentChat,
}: // socket,
{
  updateCurrentChat: React.Dispatch<React.SetStateAction<any>>;
  currentChat: any;
  // socket: any;
}) => {
  const [messages, setmessages] = useState<any[]>([
    {
      user: "Angel Martin",
      userImage:
        "https://tse4.explicit.bing.net/th?id=OIP.y-R6b74yIBCpAOfYIBVQpAHaLH&pid=Api&P=0&w=300&h=300",
      message:
        "Hello, we are impressed by your work and would like you to join us",
      isSender: false,
      timeSent: moment("2024-02-28").add("minute", 3640).format("hh:mm a"),
    },
    {
      user: "Daniel Martin",
      isSender: true,
      userImage:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/5257be03-c9e3-407d-a7c5-f96c4bbf15d2.png",
      message: "No thank you.",
      timeSent: moment("2024-03-02").add("minute", 3640).format("hh:mm a"),
    },
  ]);
  const [newMessage, setnewMessage] = useState("");

  const sendMessage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setmessages([
      ...messages,
      {
        user: "Daniel Martin",
        isSender: true,
        userImage:
          "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/5257be03-c9e3-407d-a7c5-f96c4bbf15d2.png",
        message: newMessage,
        timeSent: moment("2024-03-02").add("minute", 3640).format("hh:mm a"),
      },
    ]);
  };

  const sendMessageClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setmessages([
      ...messages,
      {
        user: "Daniel Martin",
        isSender: true,
        userImage:
          "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/5257be03-c9e3-407d-a7c5-f96c4bbf15d2.png",
        message: newMessage,
        timeSent: moment("2024-03-02").add("minute", 3640).format("hh:mm a"),
      },
    ]);

    setnewMessage("");
  };

  return (
    <Box height="100%" position="relative" display="flex" flexDir="column">
      <Flex
        alignItems="center"
        height="15%"
        padding="8px"
        boxShadow="0px 4px 32px rgba(50, 50, 93, 0.1)"
      >
        <IoIosArrowBack
          width="50px"
          fontSize="20px"
          style={{ cursor: "pointer" }}
          onClick={() => updateCurrentChat(null)}
        />

        <Avatar
          size="md"
          src={chatData.image}
          name={chatData.name}
          marginLeft="12px"
          marginRight="18px"
        />

        <Box>
          <Text
            fontSize="1rem"
            fontWeight="600"
            lineHeight="24px"
            color="rgba(50, 50, 93, 1)"
          >
            {chatData.name}
          </Text>

          {chatData.isOnline ? (
            <Flex alignItems="center">
              <Box
                height="9px"
                width="9px"
                bgColor="rgba(31, 191, 126, 1)"
                rounded="full"
                mr="8px"
              />

              <Text
                fontSize="0.75rem"
                fontWeight="500"
                lineHeight="18px"
                color="rgba(50, 50, 93, 0.7)"
              >
                Online
              </Text>
            </Flex>
          ) : (
            ""
          )}
        </Box>
      </Flex>

      <Box py="22px" height="85%" maxH="85%" position="relative">
        <VStack spacing="24px" maxH="100%" overflowY="scroll" pb="150px">
          {messages.map((mess) => (
            <Message messageData={mess} />
          ))}
        </VStack>

        <Flex
          position="absolute"
          borderTop="1px solid rgba(50, 50, 93, 0.25)"
          bottom="0"
          width="100%"
          bg="white"
          padding="20px 16px"
        >
          <Textarea
            bgColor="rgba(248, 248, 249, 1)"
            flexGrow="1"
            py="12px"
            px="20px"
            height="40px"
            maxHeight="50px"
            _focus={{ ring: "0", border: "0.5px solid #7211D4" }}
            fontSize="12px"
            lineHeight="18px"
            value={newMessage}
            onChange={(e) => setnewMessage(e.target.value)}
            resize="none"
            placeholder="Compose your message"
          />

          <Button
            color="white"
            bg="rgba(114, 17, 212, 1)"
            marginLeft="12px"
            fontSize="12px"
            lineHeight="18px"
            rounded="10px"
            onClick={sendMessageClick}
          >
            <Text>Send</Text>
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default AffiliateChatView;
