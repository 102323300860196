import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Image,
  Text,
  Stack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  css,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Avatar,
  useMediaQuery,
  Drawer,
  DrawerOverlay,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
} from "@chakra-ui/react";
import OffersCarousel from "./explore-details/top-affiliate";
import { BaseButton } from "../button";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useUserToken from "../../hooks/useUserToken";
import { MdArrowForward, MdChevronRight } from "react-icons/md";
import { useCurrentOrg } from "../../hooks/useCurrentOrg";
import { Flex } from "@chakra-ui/react";
import { motion } from "framer-motion";

const ExploreAdvertisers = () => {
  const navigate = useNavigate();
  const token = useUserToken();
  const [_, isVerified] = useCurrentOrg();

  const [approvedOffers, setApprovedOffers] = useState([]);
  const [declinedOffers, setDeclinedOffers] = useState([]);
  const [pendingOffers, setPendingOffers] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const [loading, setLoading] = useState(false);

  const tabs = [
    {
      title: "listed campaigns ",
      content: <OffersCarousel offers={approvedOffers} />,
    },
    {
      title: "PENDING CAMPAIGNS",
      content: <OffersCarousel offers={pendingOffers} />,
    },
    {
      title: "DECLINED CAMPAIGNS",
      content: <OffersCarousel offers={declinedOffers} />,
    },
  ];

  const [queryStrings, fn] = useSearchParams();

  const checkToken = async () => {
    if (!token) return;

    await fetch(process.env.REACT_APP_API_ENDPOINT + `/auth/valid-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data?.valid) {
          return;
        } else {
          window.localStorage.clear();
          sessionStorage.removeItem("currentUserType");
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          navigate("/login");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (token) {
      checkToken();
    }
  }, [token]);

  useEffect(() => {
    getApprovedOffers("approved");
    getDeclinedOffers("rejected");
    getPendingOffers("pending");
    findAffiliates();
    setLoading(false);
  }, []);

  const {
    isOpen: onModalIsOpen,
    onOpen: onModalOnOpen,
    onClose,
  } = useDisclosure();

  const [isDesktop] = useMediaQuery("(min-width: 768px)");

  const getApprovedOffers = (id: string) => {
    setLoading(true);
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + `explore/offers?status=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setApprovedOffers(data.data.offers);
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
        }
      })
      .catch((err) => {
        console.log("approved offers: => " + err);
      });
  };

  const getDeclinedOffers = (id: string) => {
    setLoading(true);
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + `/explore/offers?status=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setDeclinedOffers(data.data.offers);
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
        }
      })
      .catch((err) => {
        console.log("here");
      });
  };

  const getPendingOffers = (id: string) => {
    setLoading(true);
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + `/explore/offers?status=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("pending", data);
        if (data.success) {
          setPendingOffers(data.data.offers);
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
        }
      })
      .catch((err) => {
        console.log("pending-offers: =>" + err);
      });
  };

  const findAffiliates = () => {
    setLoading(true);
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + `/explore/find-affiliates`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setAffiliates(data.data.affiliates);
          setLoading(false);
        } else {
          setLoading(false);
          // @ts-ignore
          formViewRef.current.scrollIntoView();
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("find affiliates: =>" + err);
      });
  };

  const space_years = useCallback((timeframe: any) => {
    let result = "";

    if (!timeframe) return result;

    let splited = timeframe.split("-");

    result = splited[0] + " - " + splited[1];

    return result;
  }, []);

  const fmtContentType = useCallback((text: any) => {
    if (!text) return "";

    let splitted = text.split("_");

    let firstWord = splitted[0][0].toUpperCase() + splitted[0].substr(1);

    if (splitted.length < 2) return firstWord;
    let secondWord = splitted[1][0].toUpperCase() + splitted[1].substr(1);

    let result = firstWord + " " + secondWord;

    return result;
  }, []);
  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        h="70vh"
        alignItems="center"
        pt="20px"
      >
        <Image className="loading" src="/assets/logo.png" height="60px" />

        <Text
          fontWeight="400"
          fontSize="16px"
          lineHeight="70px"
          color="#000000"
          mt="-15px"
        >
          Fetching Affiliate details...
        </Text>
      </Box>
    );

  return (
    <Stack padding={{ base: "0px", md: "19px" }} bgColor="#F1F4FA">
      <Box
        bgColor="white"
        alignItems="center"
        display={{ base: "flex", md: "none" }}
        p="13px 20px"
        mb="26px"
      >
        <Text
          fontSize="14px"
          lineHeight="21px"
          fontWeight="700"
          color="#32325D"
          mr="6px"
        >
          Explore
        </Text>

        <MdChevronRight size={16} color="#32325D" />
      </Box>

      <Box
        background="#FFFFFF"
        width="100%"
        padding="16px 27px"
        borderRadius="8px"
        mb="0 !important"
      >
        <Box width="max-content" mb="20px" display="flex" alignItems="center">
          {/* <Box mr="20px">
            <BaseButton
              text="Find new affiliates"
              textColor="#fff"
              bgColor="#5C14CE"
              type="submit"
              clickEventHandler={() => navigate("/campaigns/create")}
            />
          </Box> */}
          <BaseButton
            text="Add new offer"
            textColor=" #7211D4"
            bgColor="#ffffff"
            type="submit"
            border="1px solid #7211D4"
            clickEventHandler={() => navigate("/explore/campaigns")}
          />
        </Box>

        <Box mt="18px">
          <Text
            fontWeight="500"
            fontSize={{ base: "14px", md: "16px" }}
            color="#32325D"
            lineHeight={{ base: "21px", md: "24px" }}
            opacity="0.8"
          >
            Campaigns
          </Text>
        </Box>

        <Tabs
          mt="33px"
          defaultIndex={queryStrings.get("tab") === "pending" ? 1 : 0}
          isFitted={isDesktop ? false : true}
        >
          <TabList
            overflowX="auto"
            css={css({
              scrollbarWidth: "none",
              "::-webkit-scrollbar": { display: "none" },
              "-webkit-overflow-scrolling": "touch",
              boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
              border: "0 none",
            })}
            padding="0px !important"
            margin="0px !important"
          >
            {tabs.map((tab) => (
              <Tab
                _selected={{
                  color: "#7211D4",
                  borderBottom: "2px solid",
                  borderColor: "#7211D4",
                }}
                padding={{ base: "0px", md: "0px 0px 13px 0px !important" }}
                margin={{ base: "0px", md: "0px 60px 0px 0px !important" }}
                display="flex"
                alignItems="center"
                key={tab.title}
              >
                <Text
                  fontWeight="500"
                  fontSize="12px"
                  color="#32325D"
                  lineHeight="18px"
                  opacity="0.8"
                  textTransform="uppercase"
                >
                  {isDesktop ? tab.title : tab.title.split(" ")[0]}
                </Text>
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabs.map((tab) => (
              <TabPanel paddingLeft="0px" paddingRight="0px" key={tab.title}>
                {tab.content}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>

      <Box
        bgColor={{ base: "transparent", md: "#ffffff" }}
        padding={["50px 20px", "50px 40px"]}
        mt="20px !important"
        borderRadius="8px"
      >
        <Box pb="17px" borderBottom=" 1px solid rgba(50, 50, 93, 0.3)">
          <Text
            fontWeight="500"
            fontSize="12px"
            color="#32325D"
            lineHeight="18px"
            textTransform="uppercase"
          >
            Find New Affiliates
          </Text>
        </Box>

        <Box
          width="100%"
          display="flex"
          mt="36px"
          alignItems="center"
          justifyContent={affiliates.length >= 3 ? "space-between" : ""}
          flexDirection={["column", "row"]}
        >
          {affiliates.length < 1 ? (
            <Flex
              w={"100%"}
              justify={"center"}
              align={"center"}
              direction={"column"}
              gap={5}
            >
              <Image
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
                width="150px"
                height="150px"
              />
              <Text fontWeight={"bold"}>No Top Ranked Affiliate Yet</Text>
            </Flex>
          ) : (
            affiliates.slice(0, 3).map((item: any) => {
              return (
                <Box
                  width={["100%", "32%"]}
                  borderRadius="8px"
                  bgColor="white"
                  shadow="md"
                  padding="25px"
                  mr={affiliates.length >= 3 ? "0px" : "20px"}
                  border="1px solid rgba(50, 50, 93, 0.1)"
                  marginBottom={["20px", "0px"]}
                >
                  <Box
                    mb="26px"
                    filter="auto"
                    blur={isVerified ? "0px" : "4px"}
                    display="flex"
                    alignItems="center"
                  >
                    {item?.user.profile_image ? (
                      <Image
                        rounded="full"
                        src={item.user.profile_image}
                        height="50px"
                        width="50px"
                      />
                    ) : (
                      <Avatar
                        name={item.user.firstname + " " + item.user.lastname}
                      />
                    )}

                    <Text
                      fontSize="14px"
                      fontWeight="500"
                      lineHeight="21px"
                      color="#32325D"
                      ml="8px"
                    >
                      {item.user.firstname + " " + item.user.lastname}
                    </Text>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="18px"
                      color="rgba(50, 50, 93, 0.8)"
                      opacity="0.8"
                    >
                      Affiliate Types
                    </Text>

                    <Text
                      fontSize="14px"
                      fontWeight="500"
                      lineHeight="21px"
                      color="#32325D"
                    >
                      {fmtContentType(item.affiliate_type)}
                    </Text>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt="8px"
                    borderBottom=" 1px solid rgba(50, 50, 93, 0.1)"
                    pb="15px"
                    mb="9px"
                  >
                    <Text
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="18px"
                      color="rgba(50, 50, 93, 0.8)"
                      opacity="0.8"
                    >
                      Experience
                    </Text>

                    <Text
                      fontSize="14px"
                      fontWeight="500"
                      lineHeight="21px"
                      color="#32325D"
                    >
                      {item.affiliate_years}
                    </Text>
                  </Box>

                  <Button
                    bgColor="#F0F3F9"
                    border=" 0.5px solid #E5E4E4"
                    borderRadius="8px"
                    width="100%"
                    onClick={
                      isVerified
                        ? () => navigate(`/explore/affiliate?id=${item.id}`)
                        : onModalOnOpen
                    }
                  >
                    <Text
                      fontWeight="500"
                      fontSize="16px"
                      lineHeight="21px"
                      color=" #7211D4"
                      mr="7px"
                    >
                      View
                    </Text>

                    <MdArrowForward color="#7211D4" size={16} />
                  </Button>
                </Box>
              );
            })
          )}
        </Box>

        {!isDesktop ? (
          <Drawer placement="bottom" onClose={onClose} isOpen={onModalIsOpen}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader textAlign="center">
                Incomplete Organization Profile
              </DrawerHeader>
              <DrawerBody p="0px 32px" mt="20px" mb="60px">
                <Text
                  fontWeight="500"
                  fontSize="18px"
                  lineHeight="30px"
                  color=" rgba(50, 50, 93, 0.8)"
                  textAlign="center"
                >
                  Complete your organisation information to view this affiliate
                </Text>
              </DrawerBody>

              <DrawerFooter
                display="flex"
                justifyContent="space-between"
                borderTopWidth="1px"
                p="27px 32px"
                alignItems="center"
              >
                <Button colorScheme="gray" onClick={onClose} variant="ghost">
                  Cancel
                </Button>
                {/* ----------- */}
                <Button
                  bgColor=" #F0F5FF"
                  color="#5C14CE"
                  borderRadius="8px"
                  onClick={onClose}
                >
                  Proceed
                  <Image
                    cursor="pointer"
                    ml="10px"
                    mt="1px"
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/37d9e26a-0ec4-462d-98ac-8fca88493c0b.svg"
                  />
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        ) : (
          <Modal onClose={onClose} size="lg" isCentered isOpen={onModalIsOpen}>
            <ModalOverlay />
            <ModalContent borderRadius="10px">
              <ModalHeader display="flex" justifyContent="right">
                <Image
                  onClick={onClose}
                  cursor="pointer"
                  ml="7px"
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/3b803641-20b6-4e47-b554-8336ebd3ec5b.svg"
                />
              </ModalHeader>
              <ModalBody
                paddingBottom="26px"
                borderBottom="1px solid rgba(50, 50, 93, 0.3)"
              >
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="30px"
                  color=" rgba(50, 50, 93, 0.8)"
                  textAlign="center"
                >
                  Complete your organisation information to view this affiliate
                </Text>
              </ModalBody>
              <ModalFooter display="flex" justifyContent="left">
                <Button
                  ml="15px"
                  bgColor=" #F0F5FF"
                  color="#5C14CE"
                  borderRadius="8px"
                  onClick={onClose}
                >
                  Proceed
                  <Image
                    cursor="pointer"
                    ml="10px"
                    mt="1px"
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/37d9e26a-0ec4-462d-98ac-8fca88493c0b.svg"
                  />
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}

        <Box textAlign="center" mt="39px">
          <Button
            variant="link"
            onClick={() => navigate(`/explore/affiliates`)}
          >
            <Text
              fontWeight="500"
              fontSize="16px"
              lineHeight="24px"
              textDecoration="underline"
              color="#7211D4"
            >
              View all affiliates
            </Text>
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};

export default ExploreAdvertisers;
