import React, { useEffect, useRef, useState } from "react";
import Container from "../container";
import { Box, Image, Text, Button, Heading } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { FormInput } from "../../formInput";
import { InputHints } from "../../InputHints";
import PinInput from "react-pin-input";
import { usePutRequest } from "../../../hooks/usePutRequest";
import { ToastContainer, toast } from "react-toastify";
import { BaseButton } from "../../button";
import { IoArrowForwardSharp } from "react-icons/io5";
import "./pin.css";
const CreatePassword = () => {
  const [putHandler, serverResponse, serverError] = usePutRequest();
  const [loading, setLoading] = useState(false);
  const emailAddress = localStorage.getItem("remember_email");
  let tokenWrong = serverError === "Incorrect token, please try again";
  let navigate = useNavigate();
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const formViewRef = useRef(null);
  const [validKeys, setValidKeys] = useState({
    password: true,
    confirmPassword: true,
  });
  useEffect(() => {
    if (serverError === "Incorrect token, please try again") {
      // @ts-ignore
      formViewRef.current.scrollIntoView();
    }
    toast.error(serverError);
  }, [serverError]);

  const sumbitHandler = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    console.log(token, "token");
    if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)^[^ ]+$/.test(password)) {
      setValidKeys({ ...validKeys, password: false });
    }

    if (password !== confirmPassword) {
      setValidKeys({ ...validKeys, confirmPassword: false });
    }
    if (
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)^[^ ]+$/.test(password) &&
      token &&
      password &&
      password === confirmPassword
    ) {
      setLoading(true);
      putHandler({
        endPoint: process.env.REACT_APP_API_ENDPOINT + "auth/reset-password",

        dataObject: {
          token: token,
          password: password,
        },
      });
    } else {
      toast.warn("Please complete all required fields");
      setLoading(false);
    }
  };
  if (serverResponse) {
    if (serverResponse.data.success) {
      navigate("/reset-password/success");
    }
  }
  return (
    <>
      <ToastContainer />
      <Container>
        <Box
          ref={formViewRef}
          display="flex"
          width="100%"
          height="auto"
          overflow="hidden"
          left="0"
          bottom="0"
          zIndex="10"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          pt="70px"
          pb="2.5em"
          bgColor="#f0f5ff"
        >
          <Image src="/assets/logo.png" mb="1.5em" height="50px" />
          <Box width={["90%", "50%"]}>
            <Text
              fontSize="20px"
              fontWeight="500"
              marginBottom="40px"
              marginTop="40px"
              textAlign="center"
              textDecorationLine="underline"
              color="#000000"
            >
              {emailAddress}
            </Text>
            <Heading
              as="h1"
              fontWeight="600"
              color="#000000"
              fontSize="20px"
              lineHeight="50px"
              textAlign="center"
              mt="-1.2em"
            >
              Create your new password
            </Heading>
            <Text
              fontSize="16px"
              fontWeight="500"
              marginBottom="40px"
              marginTop="40px"
              textAlign="center"
              opacity="0.5"
              color="#000000"
            >
              Enter the token sent your email
            </Text>
            <form onSubmit={sumbitHandler}>
              <Box textAlign="center">
                <PinInput
                  length={5}
                  initialValue=""
                  type="numeric"
                  onChange={(value, index) => {
                    tokenWrong = false;
                    setToken(value);
                  }}
                  onComplete={(value, index) => {
                    tokenWrong = false;
                    setToken(value);
                  }}
                  inputMode="number"
                  style={{
                    padding: "4px",
                    marginTop: "-20px",
                    fontWeight: "500",
                    fontSize: "32px",
                  }}
                  inputStyle={{
                    borderColor: tokenWrong ? "red" : "#A5A5A5",
                    borderRadius: "10px",
                    width: "73px",
                    height: "70px",
                    marginRight: "10px",
                    color: tokenWrong ? "red" : "black",
                  }}
                  inputFocusStyle={{
                    borderColor: "#A5A5A5",
                    borderRadius: "10px",
                  }}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              </Box>
              <FormInput
                label="New Password"
                type="password"
                value={password}
                stateHandler={setPassword}
                required={true}
                preventSpaces={true}
                inputNotValid={!validKeys.password}
                holder="******************"
              />
              {password !== "" &&
                !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)^[^ ]+$/.test(
                  password
                ) && (
                  <Box mt="-1em">
                    <InputHints
                      showWhen={password !== ""}
                      title="Your password must be"
                      hints={[
                        {
                          passed: password.length > 7 ? true : false,
                          text: "at least 8 characters long",
                        },
                        {
                          passed: /[A-Z]/.test(password) ? true : false,
                          text: "at least one uppercase letter",
                        },
                        {
                          passed: /[a-z]/.test(password) ? true : false,
                          text: "at least one lowercase letter",
                        },
                        {
                          passed: /\d/.test(password) ? true : false,
                          text: "at least one digit",
                        },
                        {
                          passed: /[^\w\s\_]/.test(password) ? true : false,
                          text: "at least one special character e.g. @#$%^&*()",
                        },
                      ]}
                    />
                  </Box>
                )}
              <FormInput
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                stateHandler={setConfirmPassword}
                required={true}
                preventSpaces={true}
                inputNotValid={
                  (confirmPassword !== "" && password !== confirmPassword) ||
                  !validKeys.confirmPassword
                }
                holder="******************"
              />
              {confirmPassword !== "" && password !== confirmPassword && (
                <Box mt="-1em">
                  <InputHints
                    showWhen={confirmPassword !== ""}
                    title="Passwords do not match"
                    hints={[
                      {
                        passed: password === confirmPassword ? true : false,
                        text:
                          password === confirmPassword ? (
                            "Passwords match"
                          ) : (
                            <Text fontWeight="bold">
                              Your passwords must match
                            </Text>
                          ),
                      },
                    ]}
                  />
                </Box>
              )}
              <Box my="30px">
                <BaseButton
                  text="Reset Password"
                  padded={true}
                  textColor="#fff"
                  bgColor="#5C14CE"
                  type="submit"
                  loadingState={loading && !serverError}
                  loaderText="Loading..."
                  clickEventHandler={() => console.log("working")}
                  rightIcon={<IoArrowForwardSharp />}
                />
              </Box>
              <Text
                color="#918A8A"
                mb="2em"
                fontSize="14px"
                fontWeight="400"
                textAlign="center"
              >
                If you require further assistance, contact &nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="mailto:support@metricks.com"
                >
                  <Text as="span" color="#5C14CE !important">
                    support@metricks.io
                  </Text>
                </a>
              </Text>
            </form>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default CreatePassword;
