import {
  Box,
  Grid,
  GridItem,
  Input,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useUserToken from "../../../../../../hooks/useUserToken";

const AffCustomQuestions = ({
  campaignId,
  affOrgId,
}: {
  campaignId: string | undefined;
  affOrgId: number | null;
}) => {
  const questsAndAns = [
    {
      question:
        "How Many Years How many years exprience do you have in affiliate marketing",
      answer: "12",
      type: "number",
    },
    {
      question: "What is your name",
      answer: "Emily Jones Maliya",
      type: "text",
    },
    {
      question: "Your favourite cars",
      answer: ["BMW", "Ferrari"],
      type: "multichoice",
    },
  ];

  const token = useUserToken();
  const [isLoading, setisLoading] = useState(false);
  const [customQuestionsandReply, setcustomQuestionsandReply] = useState<any[]>(
    []
  );

  const refineData = (
    custom_answer: any[],
    default_answer: { [key: string]: any }
  ) => {
    let final_ans = [
      {
        id: default_answer?.id,
        question: "How do you intend on promoting this campaign",
        answer: default_answer?.answer || "",
        type: "textQuestion",
        multipleChoice: false,
        options: null,
        optsLookup: {},
      },
    ];

    custom_answer.forEach((ans) => {
      let opts: { [key: string]: string }[] = ans.customQuestion?.options || [];
      let optsLookup: { [key: string]: any } = {};

      opts.forEach((opt) => {
        optsLookup[opt.id] = opt?.label;
      });

      final_ans.push({
        id: ans?.id,
        question: ans?.customQuestion?.label || "",
        answer: ans?.answer || "",
        type: ans.type,
        multipleChoice: ans.customQuestion.multipleChoice,
        optsLookup: optsLookup || {},
        options: ans.customQuestion?.options || [],
      });
    });

    return final_ans;
  };

  const getAffiliateReply = () => {
    setisLoading(true);

    let bearerAuth = token;

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `/campaign/${campaignId}/affiliate-answers/${affOrgId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setcustomQuestionsandReply(
            refineData(
              data.data.answers.customAnswers,
              data.data.answers.defaultAnswer
            )
          );
          setisLoading(false);
        }
      })
      .catch((err) => {
        setisLoading(false);
        console.log("get top 5 affiliates => " + err);
      });
  };

  useEffect(() => {
    getAffiliateReply();
  }, []);

  return (
    <Box mt="34px">
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
        gap="30px"
      >
        {customQuestionsandReply.map((item: any, idx: number) => (
          <GridItem>
            <Text
              fontSize="14px"
              color="#32325D"
              fontWeight="400"
              mb="8px"
              lineHeight="21px"
            >
              {`${idx + 1}. ` + item.question}
            </Text>

            {item.type === "multipleChoiceQuestion" && item.multipleChoice ? (
              <UnorderedList>
                {item.answer?.map((ans: string) => (
                  <ListItem fontSize="14px" color="#32325D" fontWeight="400">
                    {item.optsLookup[ans]}
                  </ListItem>
                ))}
              </UnorderedList>
            ) : (
              <Input
                borderRadius="8px"
                fontSize="14px"
                color="#32325D"
                fontWeight="400"
                value={
                  item.type === "multipleChoiceQuestion"
                    ? item.optsLookup[item.answer]
                    : item.answer
                }
                lineHeight="21px"
                border="0.53px solid #E1E1E1"
                bgColor="#F7F7F7"
              />
            )}
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

export default AffCustomQuestions;
