import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  css,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { CiEdit } from "react-icons/ci";
import ManagersForm from "./ManagersForm";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const ManagersTable = ({
  managers,
  campaigns,
  setRefreshField,
}: {
  managers: any[];
  campaigns: any[];
  setRefreshField: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const titles = [
    "FIRST NAME",
    "LAST NAME",
    "EMAIL",
    "SIGNUP DATE",
    "LAST ACTIVE",
    "CAMPAIGNS",
    "STATUS",
    "ACTION",
  ];

  const {
    isOpen: isOpenManagerModal,
    onOpen: onOpenManagerModal,
    onClose: onCloseManagerModal,
  } = useDisclosure();

  const [editData, seteditData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    campaigns: [],
    permissionId: 0,
    invitedBy: 0,
  });

  const openEditModal = (item: { [key: string]: any }) => {
    seteditData({
      firstName: item?.firstName || "",
      lastName: item?.lastName || "",
      email: item?.email || "",
      campaigns: item?.campaignIds || [],
      permissionId: item.permissionId || 0,
      invitedBy: item.invitedBy || 0,
    });
    onOpenManagerModal();
  };

  const onCloseModal = () => {
    seteditData({
      firstName: "",
      lastName: "",
      email: "",
      campaigns: [],
      permissionId: 0,
      invitedBy: 0,
    });

    onCloseManagerModal();
  };

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  let currentData = managers?.slice(startIndex, endIndex);

  const nextPage = () => {
    if (currentPage < Math.ceil(managers?.length / pageSize)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  return (
    <Box
      css={
        isDesktop
          ? {}
          : css({
              "::-webkit-scrollbar": { display: "none" },
              "-webkit-overflow-scrolling": "touch",
            })
      }
      overflowX={{ base: "scroll", md: "visible" }}
      maxWidth="100%"
    >
      <Table mb="29px">
        <Thead>
          <Tr height="53px" bgColor="#F1F4FA">
            {titles.map((title) => {
              return (
                <Th
                  color="rgba(50, 50, 93, 0.8)"
                  textTransform="uppercase"
                  lineHeight="18px"
                  fontSize="12px"
                  fontWeight="500"
                  key={title}
                >
                  {title}
                </Th>
              );
            })}
          </Tr>
        </Thead>

        <Tbody>
          {currentData.map((item: any, index: number) => {
            return (
              <Tr key={index} height="80px" width="100%">
                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                    textTransform="capitalize"
                  >
                    {item?.firstName || "-"}
                  </Text>
                </Td>
                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                    textTransform="capitalize"
                  >
                    {item?.lastName || "-"}
                  </Text>
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    {item?.email || "-"}
                  </Text>
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    {item.signupDate?.split("T")[0] || ""}
                  </Text>
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    {item.lastActiveDate?.split("T")[0] || ""}
                  </Text>
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                    textTransform="capitalize"
                  >
                    {item?.campaignName || "-"}
                  </Text>
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                    textTransform="capitalize"
                  >
                    {item?.status === "admin" ? "Owner" : item?.status}
                  </Text>
                </Td>

                {item && item.status && item.status !== "admin" ? (
                  <Td>
                    <Button
                      borderRadius="8px"
                      fontWeight="500"
                      fontSize={{ base: "12px", md: "14px" }}
                      lineHeight={{ base: "18px", md: "21px" }}
                      color="#7211D4"
                      p="7px 16px"
                      bgColor="#E5E4E4"
                      border="0.5px solid rgba(229, 228, 228, 1)"
                      onClick={() => openEditModal(item)}
                    >
                      <CiEdit />
                      Edit
                    </Button>
                  </Td>
                ) : (
                  ""
                )}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Flex
        w={"100%"}
        // bgColor={"red"}
        h={"50px"}
        mb="29px"
        justify={"flex-end"}
        align={"center"}
      >
        <Flex align={"center"} gap={3}>
          <IconButton
            aria-label="Search database"
            icon={<ChevronLeftIcon fontSize={16} />}
            onClick={prevPage}
          />
          <Flex
            borderWidth={0.5}
            borderColor={"#c4c4c4"}
            w={35}
            h={35}
            justify={"center"}
            align={"center"}
            borderRadius={10}
          >
            <Text>{currentPage}</Text>
          </Flex>
          <IconButton
            aria-label="Search database"
            icon={<ChevronRightIcon fontSize={16} />}
            onClick={nextPage}
          />
        </Flex>
      </Flex>

      {!managers.length && (
        <Box
          w="100%"
          height="30vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={{ base: "0px 16px", md: "0px" }}
          mb="21px"
        >
          <Image
            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
            height="180px"
            objectFit="contain"
            mb="16px"
          />

          <Text
            fontWeight="600"
            fontSize="12px"
            lineHeight="22px"
            color="#32325D"
            textAlign="center"
          >
            No existing managers
          </Text>
        </Box>
      )}

      {editData.email && (
        <ManagersForm
          type="Edit"
          isOpen={isOpenManagerModal}
          onClose={onCloseModal}
          campaigns={campaigns || []}
          formData={{
            firstName: editData.firstName || "",
            lastName: editData.lastName || "",
            permissionId: editData.permissionId,
            invitedBy: editData.invitedBy,
            email: editData.email || "",
            campaigns: editData.campaigns || [],
          }}
          setRefresh={setRefreshField}
        />
      )}

      {/* <PaginationComponent
        paginationData={paginationData}
        setPaginationData={setpaginationData}
        dataLength={campaignLeads.length}
      /> */}
    </Box>
  );
};

export default ManagersTable;
