import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Image,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Flex,
  Avatar,
} from "@chakra-ui/react";
import { TabsIcon } from "../../layout/sidebar/icons/tabs";
import Overview from "./tabs/overview";
import useUserToken from "../../../hooks/useUserToken";
import { useNavigate, useSearchParams } from "react-router-dom";
import Type from "../../select-type";
import { useMediaQuery } from "react-responsive";
import {
  getCurrentUserInstance,
  setLocalUserInstance,
} from "../../../state/localstorage";
import { useCookies } from "react-cookie";

const ViewExplore = () => {
  const token = useUserToken();
  const navigate = useNavigate();
  const user = getCurrentUserInstance();

  let [searchParams, setSearchParams] = useSearchParams();
  const [campaign, setCampaign] = useState({
    campaign_mode: "",
    campaign_name: "",
    status: "",
    createdAt: "",
    countriesToShip: "",
    id: "",
    background_image: "",
    banner_image: "",
  });
  const [loading, setLoading] = useState(false);
  const [, setCookie] = useCookies(["user", "access_token"]);

  useEffect(() => {
    if (searchParams.get("id") !== null) {
      getCampaign(searchParams.get("id"));
    }
  }, []);

  const getCampaign = (id: string | null) => {
    setLoading(true);
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + `explore/offers/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setCampaign(data.data.offer.campaign);
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("here");
      });
  };
  const getStatusColor = (status: string) => {
    if (status === "active") {
      return "#239654";
    }
    if (status === "paused") {
      return "#F3994A";
    }
    if (status === "inactive") {
      return "#EB5757";
    }
  };
  const {
    isOpen: onModalIsOpen,
    onOpen: onModalOnOpen,
    onClose,
  } = useDisclosure();
  const [size, setSize] = React.useState("xl");
  const [affiliates, setAffiliate] = useState([]);
  const getAffiliates = () => {
    setLoading(true);
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + `organisation/user/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          let affiliateList = data.data.organisations.filter((item: any) => {
            return item.userType === "affiliate";
          });
          console.log(affiliateList, " affiliateList");
          setAffiliate(affiliateList);
          handleSizeClick();
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("here");
      });
  };
  const handleSizeClick = () => {
    onModalOnOpen();
  };
  const handleRedirect = () => {
    navigate(
      `/signup?type=newOrganization&country=${user.organisation.country}&userId=${user.id}`
    );
  };
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const switchProfile = (id: string) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "organisation/switch", {
      method: "POST",
      body: JSON.stringify({
        orgId: id,
        userId: user.id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          let expires = new Date();
          expires.setTime(expires.getTime() + 720 * 60 * 60 * 1000);
          let userData = data.data.user;
          userData.onboarded = true;
          setLocalUserInstance(userData);
          setCookie("user", userData, {
            path: "/",
            expires,
          });
          setCookie("access_token", data.data.token, {
            path: "/",
            expires,
          });

          setTimeout(function () {
            userData.organisation.userType === "affiliate"
              ? navigate("/affiliate-dashboard")
              : navigate("/dashboard");
          }, 1000); //wait 1 second
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
        }
      })
      .catch((err) => {
        console.log("here");
      });
  };
  return (
    <Stack padding="0px 19px" bgColor="white">
      <Box
        width="100%"
        height={["89px", "269px"]}
        bgGradient="linear(90deg, #1BD75F -164%, #1A1414 143.66%)"
        borderRadius="8px"
        position="relative"
        backgroundImage={
          campaign.background_image ? campaign.background_image : "none"
        }
      >
        <Image
          position="absolute"
          bottom="-40%"
          left="47px"
          width="179px"
          height="179px"
          borderRadius="50%"
          src={
            campaign.banner_image
              ? campaign.banner_image
              : "/assets/svg/Spotify-large.svg"
          }
        />
      </Box>
      <Box paddingBottom="34px" pt={["30px", "100px"]}>
        <Text
          color="#32325D"
          lineHeight="36px"
          fontSize="24px"
          fontWeight="600"
        >
          {campaign.campaign_name}
        </Text>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Text
              color="rgba(50, 50, 93, 0.8)"
              lineHeight="21px"
              fontSize="14px"
              fontWeight="400"
              textTransform="capitalize"
            >
              {campaign.campaign_mode} Campaign
            </Text>
            <Image
              padding="0px 15px"
              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/b4254b20-01f9-4451-9ede-2e8b98258404.svg"
            />
            <Box display="flex" alignItems="center">
              <Box
                background={getStatusColor("active")}
                height="12px"
                width="12px"
                borderRadius="50%"
              ></Box>
              <Box>
                <Text
                  color="#32325D"
                  lineHeight="21px"
                  fontSize="14px"
                  fontWeight="400"
                  paddingLeft="13px"
                  textTransform="capitalize"
                >
                  {campaign.status}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box display={["none", "flex"]} alignItems="center">
            <Box>
              <Button
                bgColor=" #F0F3F9"
                border=" 0.5px solid #E5E4E4"
                borderRadius="8px"
              >
                <Image mr="7px" src="/assets/svg/chat-shape.svg" />
                <Text
                  fontWeight=" 500"
                  fontSize="14px"
                  lineHeight="21px"
                  color=" #7211D4"
                  display={["none", "block"]}
                >
                  Chat
                </Text>
              </Button>
            </Box>
            <Box ml="18px">
              <Button
                bgColor=" #5C14CE"
                border=" 0.5px solid #E5E4E4"
                borderRadius="8px"
                onClick={() => getAffiliates()}
              >
                <Text
                  fontWeight=" 500"
                  fontSize="14px"
                  lineHeight="21px"
                  color=" #ffffff"
                  display={["none", "block"]}
                >
                  Join Campaign
                </Text>
                <Image ml="7px" src="/assets/svg/join-arrow.svg" />
              </Button>
            </Box>
            <Modal
              onClose={onClose}
              size={size}
              isOpen={onModalIsOpen}
              scrollBehavior="inside"
            >
              <ModalOverlay />
              <ModalContent maxWidth="55% !important" borderRadius=" 10px">
                <ModalHeader display="flex" justifyContent="right">
                  <Image
                    onClick={onClose}
                    cursor="pointer"
                    ml="7px"
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/3b803641-20b6-4e47-b554-8336ebd3ec5b.svg"
                  />
                </ModalHeader>
                <ModalBody
                  paddingBottom="26px"
                  borderBottom="1px solid rgba(50, 50, 93, 0.3)"
                >
                  <Flex height="100%">
                    <Box padding="0px 20px" width="100%" height="100%">
                      <Text
                        fontWeight="500"
                        fontSize="18px"
                        lineHeight="30px"
                        color="#32325D"
                        pb="20px"
                      >
                        You need an affiliate's organization to promote this
                        campaign.
                      </Text>
                      <Box paddingRight={["0px", "14px"]}>
                        {affiliates &&
                          affiliates.map((item: any) => {
                            return (
                              <Box marginBottom="29px">
                                <Type
                                  title={item.companyname}
                                  desc={item.userType}
                                  image={
                                    <Avatar
                                      size={"md"}
                                      name={item.companyname}
                                    />
                                  }
                                  selectHandler={() => {
                                    switchProfile(item.id);
                                  }}
                                />
                              </Box>
                            );
                          })}
                        {!affiliates && (
                          <Text
                            fontWeight="500"
                            fontSize="18px"
                            lineHeight="30px"
                            color="#32325D"
                          >
                            You need an affiliate's organization to promote this
                            campaign.
                          </Text>
                        )}
                      </Box>
                    </Box>
                  </Flex>
                </ModalBody>
                <ModalFooter display="flex" justifyContent="left">
                  <Button
                    ml="15px"
                    bgColor=" #F0F5FF"
                    color="#5C14CE"
                    borderRadius="8px"
                    onClick={handleRedirect}
                  >
                    Create affiliate organization
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Box>
          <Box display={["flex", "none"]} alignItems="center">
            <Box>
              <Image
                cursor="pointer"
                mr="2px"
                src="/assets/svg/view-mini.svg"
              />
            </Box>
            <Box ml="10px">
              <Image
                cursor="pointer"
                mr="7px"
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/16d4ad64-7df3-4360-b6eb-64a312641020.svg"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Overview campaign={campaign} />
    </Stack>
  );
};

export default ViewExplore;
