import {
  Box,
  Button,
  css,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useUserToken from "../../../../../../hooks/useUserToken";
import PaginationComponent from "../../../../../explore/affiliates/paginationWrapper";
import LeadFormField from "./LeadFormField";

const Leads = ({
  campaignId,
  campaignName,
}: {
  campaignId: string | undefined;
  campaignName?: string | undefined;
}) => {
  const titles = ["Date Created", "Customer ID", "Action"];

  const [paginationData, setpaginationData] = useState({
    currentPage: 1,
    pageSize: 8,
  });
  const [campaignLeads, setcampaignLeads] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentLead, setcurrentLead] = useState<any>(null);

  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const token = useUserToken("affiliate");

  const getLeads = () => {
    if (!campaignId) return;

    let bearerAuth = token;
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `/events/lead/affiliate/${campaignId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setcampaignLeads(data.data.leads);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("get campaign => " + err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getLeads();
  }, [campaignId]);

  return (
    <Box>
      <Box
        css={
          isDesktop
            ? {}
            : css({
                "::-webkit-scrollbar": { display: "none" },
                "-webkit-overflow-scrolling": "touch",
              })
        }
        overflowX={{ base: "scroll", md: "visible" }}
        maxWidth="100%"
      >
        <Table mb="29px">
          <Thead>
            <Tr height="53px" bgColor="#F1F4FA">
              {titles.map((title) => {
                return (
                  <Th
                    color="rgba(50, 50, 93, 0.8)"
                    textTransform="uppercase"
                    lineHeight="18px"
                    fontSize="12px"
                    fontWeight="500"
                    key={title}
                  >
                    {title}
                  </Th>
                );
              })}
            </Tr>
          </Thead>

          <Tbody>
            {campaignLeads.map((item: any, index: number) => {
              return (
                <Tr key={index} height="80px" width="100%">
                  <Td>
                    <Text
                      color="#32325D"
                      lineHeight={{ base: "18px", md: "21px" }}
                      fontSize={{ base: "12px", md: "14px" }}
                      fontWeight="500"
                    >
                      {new Date(item?.createdAt).toISOString()?.split("T")[0] ||
                        ""}
                    </Text>
                  </Td>

                  <Td>
                    <Text
                      color="#32325D"
                      lineHeight={{ base: "18px", md: "21px" }}
                      fontSize={{ base: "12px", md: "14px" }}
                      fontWeight="500"
                    >
                      {item?.customer_id || "-"}
                    </Text>
                  </Td>

                  <Td>
                    <Button
                      borderRadius="8px"
                      onClick={() => {
                        setcurrentLead(item);
                        onOpen();
                      }}
                      fontWeight="500"
                      fontSize={{ base: "12px", md: "14px" }}
                      lineHeight={{ base: "18px", md: "21px" }}
                      color="#7211D4"
                      p="7px 16px"
                      bgColor="#E5E4E4"
                      border="0.5px solid rgba(229, 228, 228, 1)"
                    >
                      View Profile
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>

      {!campaignLeads.length && (
        <Box
          w="100%"
          height="50vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={{ base: "0px 16px", md: "0px" }}
          mb="21px"
        >
          <Image
            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
            height="250px"
            objectFit="contain"
            mb="16px"
          />

          <Text
            fontWeight="600"
            fontSize="14px"
            lineHeight="21px"
            color="#32325D"
            textAlign="center"
          >
            No data available yet, when you have leads you'll see them here.
          </Text>
        </Box>
      )}

      <PaginationComponent
        paginationData={paginationData}
        setPaginationData={setpaginationData}
        dataLength={campaignLeads.length}
      />

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#32325D" fontWeight="600" fontSize="1.15rem">
            Leads Profile
          </ModalHeader>

          <ModalBody>
            <Box borderY="0.5px solid rgba(50, 50, 93, 0.30)" paddingY="23px">
              <LeadFormField label="Campaign" value={campaignName || ""} />
            </Box>

            <Box
              borderBottom="0.5px solid rgba(50, 50, 93, 0.30)"
              paddingY="23px"
            >
              <LeadFormField
                label="Full name"
                value={currentLead?.name || ""}
              />
            </Box>

            <Box
              borderBottom="0.5px solid rgba(50, 50, 93, 0.30)"
              paddingY="23px"
            >
              <LeadFormField label="Email" value={currentLead?.email || ""} />
            </Box>

            <Box
              borderBottom="0.5px solid rgba(50, 50, 93, 0.30)"
              paddingY="23px"
            >
              <LeadFormField
                label="Phone number"
                value={currentLead?.metadata?.phone || ""}
              />
            </Box>

            <Box
              borderBottom="0.5px solid rgba(50, 50, 93, 0.30)"
              paddingY="23px"
            >
              <LeadFormField
                label="Country"
                value={currentLead?.country || ""}
              />
            </Box>

            <Box
              borderBottom="0.5px solid rgba(50, 50, 93, 0.30)"
              paddingY="23px"
            >
              <LeadFormField
                label="Home address"
                value={currentLead?.meatadata?.address || ""}
              />
            </Box>

            <Box
              borderBottom="0.5px solid rgba(50, 50, 93, 0.30)"
              paddingY="23px"
            >
              <LeadFormField label="State" value={currentLead?.state || ""} />
            </Box>

            <Box
              borderBottom="0.5px solid rgba(50, 50, 93, 0.30)"
              paddingY="23px"
            >
              <LeadFormField
                label="Postal code"
                value={currentLead?.metadata?.postal_code || ""}
              />
            </Box>

            <Box
              borderBottom="0.5px solid rgba(50, 50, 93, 0.30)"
              paddingY="23px"
            >
              <LeadFormField label="IP address" value={currentLead?.ip || ""} />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              borderRadius="8px"
              fontWeight="500"
              fontSize={{ base: "16px", md: "16px" }}
              lineHeight={{ base: "24px", md: "36px" }}
              color="#5C14CE"
              p="7px 2px"
              w="170px"
              bgColor="#F0F5FF"
              onClick={() => {
                onClose();
                setcurrentLead(null);
              }}
              border="0.5px solid rgba(229, 228, 228, 1)"
            >
              Exit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Leads;
