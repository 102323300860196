import { Flex, Heading, Input, Select, Text } from "@chakra-ui/react";
import React from "react";

const SettingsInput = ({
  changeHandler,
  value,
  type,
  label,
  isRequired,
  options,
}: {
  changeHandler: React.Dispatch<any>;
  value: string;
  type: string;
  label: string;
  isRequired: boolean;
  options?: any[];
}) => {
  return (
    <Flex
      alignItems="center"
      // justifyContent="space-between"
      columnGap="1em"
      my="1em"
      flexDirection={["column", "row"]}
      borderTop={["0px", "0.5px solid"]}
      borderTopColor={{ md: "rgba(50, 50, 93, 0.30)" }}
      py={[".5em", "1em"]}
    >
      <Flex width={["100%", "30%"]}>
        <Text
          fontSize="14px"
          color="#32325D"
          fontWeight="500"
          flexGrow={1}
          width={{ base: "100%", md: "40%" }}
          textAlign="left"
          mb={[".3em", "0em"]}
        >
          {label}
          <span style={{ color: "red", marginLeft: 5 }}>*</span>
        </Text>
      </Flex>

      <Flex width={["100%", "80%"]}>
        {type === "select" ? (
          <Select
            _focus={{ ring: "0", borderColor: "#5C14CE" }}
            border="0.6px solid rgba(50, 50, 93, 0.30)"
            borderRadius="8px"
            color="#32325D"
            fontWeight="400"
            fontSize="14px"
            isRequired={true}
            value={value}
            style={{
              padding: "10px",
              height: "50px",
              width: "100%",
            }}
            onChange={(e: React.ChangeEvent<any>) =>
              changeHandler(e.target.value)
            }
            width={{ base: "100%" }}
          >
            {options?.map((opt: any, index: number) => {
              return (
                <option key={index} value={opt || ""}>
                  {opt || ""}
                </option>
              );
            })}
          </Select>
        ) : (
          <Input
            border="0.6px solid rgba(50, 50, 93, 0.30)"
            borderRadius="8px"
            color="#32325D"
            fontWeight="400"
            fontSize="14px"
            type={type}
            value={value}
            onChange={(e: React.ChangeEvent<any>) =>
              changeHandler(e.target.value)
            }
            _focus={{ ring: "0", borderColor: "#5C14CE" }}
            isRequired={isRequired}
            style={{
              height: "50px",
              backgroundColor: "#fefdff",
              borderColor: "#c4c4c4",
              borderRadius: "10px",
              width: "100%",
            }}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default SettingsInput;
