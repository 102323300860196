export const OfferIcon = (props: any) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3848 19.6255L19.6348 11.3755C19.9648 11.0455 20.1665 10.5872 20.1665 10.083V3.66634C20.1665 2.65801 19.3415 1.83301 18.3332 1.83301L11.9165 1.83301C11.4123 1.83301 10.954 2.03467 10.6148 2.37384L2.36484 10.6238C2.03484 10.9538 1.83317 11.4122 1.83317 11.9163C1.83317 12.4205 2.03484 12.8788 2.374 13.2088L8.79067 19.6255C9.12067 19.9647 9.579 20.1663 10.0832 20.1663C10.5873 20.1663 11.0548 19.9555 11.3848 19.6255ZM3.65734 11.9163L11.9165 3.66634L18.3332 3.66634V10.083H18.3423L10.0923 18.333L3.65734 11.9163Z"
        fill="none"
        {...props}
      />
      <path
        d="M14.6665 5.95801C14.6665 6.7174 15.2821 7.33301 16.0415 7.33301C16.8009 7.33301 17.4165 6.7174 17.4165 5.95801C17.4165 5.19862 16.8009 4.58301 16.0415 4.58301C15.2821 4.58301 14.6665 5.19862 14.6665 5.95801Z"
        fill="none"
        {...props}
      />
    </svg>
  );
};
