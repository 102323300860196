import { useCookies } from "react-cookie";
const useUserToken = (type?: string) => {
  const [cookies] = useCookies([
    "user",
    "access_token",
    "aff_user",
    "aff_access_token",
  ]);
  if (type === "affiliate") return cookies.aff_access_token;
  return cookies.access_token;
};
export default useUserToken;
