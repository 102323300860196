import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Image,
  Text,
  Stack,
  Select,
  useColorModeValue,
  Link,
  Button,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
// import Swiper styles
import "swiper/css";
import useUserToken from "../../../../hooks/useUserToken";
import { useCurrentAffiliateOrg } from "../../../../hooks/useCurrentOrg";
import { useNavigate } from "react-router-dom";

const TopAffiliate = ({ bgColor }) => {
  SwiperCore.use([Autoplay]);
  const swiperRef = useRef();

  const navigate = useNavigate();
  const token = useUserToken("affiliate");
  const [topCampaigns, settopCampaigns] = useState([]);
  const [loading, setloading] = useState(true);

  const [_, profileIsComplete] = useCurrentAffiliateOrg();

  const getTop10Campaigns = () => {
    let bearerAuth = token;

    setloading(true);
    fetch(
      process.env.REACT_APP_API_ENDPOINT + "explore/offers/top10-campaigns",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          settopCampaigns(data.data.top10Campaigns);
          setloading(false);
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
          setloading(false);
        }
      })
      .catch((err) => {
        console.log("top 10 campaigns => " + err);
        setloading(false);
      });
  };

  useEffect(() => {
    getTop10Campaigns();
  }, []);

  const handleView = (id, status) => {
    if (!profileIsComplete) return onModalOpen();

    if (status === "not_joined")
      return navigate(`/affiliate-explore/view/${id}`);

    return navigate(`/affiliate-campaigns?id=${id}&status=${status}`);
  };

  return (
    <Stack
      mt="20px"
      position="relative"
      padding={{ md: "20px" }}
      display={!topCampaigns || topCampaigns.length < 10 ? "none" : ""}
      bgColor={{ base: "transparent", md: "rgba(255, 255, 255, 0.6)" }}
    >
      <Box
        mb="14px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text
          fontSize={{ base: "14px", md: "12px" }}
          textTransform="uppercase"
          lineHeight="18px"
          fontWeight="500"
          color="#32325D"
        >
          Top ranked Campaigns
        </Text>

        <Link
          href="/affiliate-explore"
          isExternal
          color="#7211D4"
          fontWeight="medium"
          fontSize="12px"
          lineHeight="18px"
          textDecoration="underline"
          display={{ base: "none", md: "block" }}
        >
          Find New Campaigns
        </Link>
      </Box>

      {topCampaigns && topCampaigns.length ? (
        <>
          <Box position="relative">
            <Swiper
              spaceBetween={25}
              breakpoints={{
                // when window width is >= 370px
                370: {
                  slidesPerView: 1.5,
                },
                // when window width is >= 400px
                460: {
                  slidesPerView: 1.8,
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 2.3,
                },
                // when window width is >= 768px
                768: {
                  slidesPerView: 2.8,
                },
                // when window width is >= 900px
                900: {
                  slidesPerView: 3.6,
                },
                // when window width is >= 1024px
                1024: {
                  slidesPerView: 4.2,
                },
                // when window width is >= 1200px
                1200: {
                  slidesPerView: 4.3,
                },
                // when window width is >= 1500px
                1500: {
                  slidesPerView: 5.3,
                },
              }}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              autoplay={{ delay: 1000 }}
              rewind={true}
            >
              {topCampaigns.map((item, index) => {
                return (
                  <SwiperSlide
                    key={`${index + 1}`}
                    onClick={() =>
                      handleView(item.id, item.campaignPromotionStatus)
                    }
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      background="#FFFFFF"
                      border=" 0.5px solid rgba(50, 50, 93, 0.2)"
                      borderRadius=" 8px"
                      width="100%"
                      height="120px"
                      cursor="pointer"
                      flexDirection="column"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                        p="10px 14px"
                      >
                        <Box
                          filter="auto"
                          blur={profileIsComplete ? "0px" : "4px"}
                          display="flex"
                          alignItems="center"
                        >
                          <Image
                            width="31px"
                            height="30px"
                            objectFit="cover"
                            src={item?.banner_image}
                          />

                          <Text
                            fontWeight="500"
                            fontSize="14px"
                            color=" #32325D"
                            lineHeight="21px"
                            pl="14px"
                            pr="20px"
                          >
                            {item?.campaign_name}
                          </Text>
                        </Box>
                        <Box>
                          <Text
                            fontWeight="500"
                            fontSize="14px"
                            color=" #32325D"
                            lineHeight="22px"
                            opacity="0.5"
                          >
                            {`${index + 1}`}
                          </Text>
                        </Box>
                      </Box>
                      <Box width="100%" p="0px 14px">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Text
                            fontWeight="300"
                            fontSize="12px"
                            color=" #32325D"
                            lineHeight="18px"
                            opacity="0.5"
                          >
                            Commission
                          </Text>
                          <Text
                            width="50%"
                            fontWeight="400"
                            fontSize="12px"
                            color=" #32325D"
                            lineHeight="18px"
                          >
                            $
                            {`${
                              item.commission_amount
                                ? item.commission_amount
                                : 0
                            }`}
                          </Text>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Text
                            fontWeight="300"
                            fontSize="12px"
                            color=" #32325D"
                            lineHeight="18px"
                            opacity="0.5"
                          >
                            Category
                          </Text>
                          <Text
                            width="50%"
                            fontWeight="400"
                            fontSize="12px"
                            color=" #32325D"
                            lineHeight="18px"
                          >
                            ECOMMERCE
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>

          <Button
            background="#FFFFFF"
            boxShadow="0px 0px 7.42829px rgba(114, 17, 212, 0.25)"
            borderRadius=" 4.64268px"
            width="32px"
            height="28px"
            position="absolute"
            top="100px"
            zIndex="10"
            right="-20px"
            onClick={() => swiperRef.current?.slideNext()}
          >
            <Image
              src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/67853fcc-a95e-4476-be48-14434b51b27d.svg`}
            />
          </Button>
        </>
      ) : (
        <Box display={"flex"} justifyContent="center" pb="20px">
          {loading ? (
            ""
          ) : (
            <Text fontSize="0.9rem" fontWeight="600" color="#32325D">
              No campaigns available yet
            </Text>
          )}
        </Box>
      )}

      <Link
        href="/affiliate-explore"
        isExternal
        color="#7211D4"
        fontWeight="medium"
        fontSize="12px"
        lineHeight="18px"
        mt="16px"
        ml="auto"
        textDecoration="underline"
        display={{ base: "block", md: "none" }}
      >
        Find New Campaigns
      </Link>
    </Stack>
  );
};

export default TopAffiliate;
