import { Flex, Grid, GridItem, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

const SettingsOverView = () => {
  const navigate = useNavigate();

  let features = [
    {
      title: "Organization",
      route: "/settings/organization?tab=organizationprofile",
      icon: "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/cf5dda7b-060e-4739-a619-28a050515974.svg",
    },
    {
      title: "Managers",
      route: "/settings/managers",
      icon: "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/c8e88313-5437-4d32-b1c1-b3c4608595e7.svg",
    },
  ];

  return (
    <Stack
      m="28px 0px"
      bgColor="white"
      p={{ base: "25px 20px", md: "50px" }}
      minH={{ base: "75vh", md: "70vh" }}
    >
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
        gap={{ base: "40px", md: "100px" }}
      >
        {features.map((feature) => (
          <GridItem
            h="148px"
            border="2px solid #D1D1D1"
            borderRadius="5px"
            cursor="pointer"
            onClick={() => navigate(feature.route)}
          >
            <Flex
              p="10px 20px 25px 30px"
              h="full"
              w="full"
              flexDirection="column"
              justifyContent="end"
              alignItems="start"
            >
              <Image
                src={feature.icon}
                h="38px"
                mb="10px"
                objectFit="contain"
              />

              <Text
                color="rgba(50, 50, 93, 1)"
                fontSize="16px"
                lineHeight="21px"
                fontWeight="400"
              >
                {feature.title}
              </Text>
            </Flex>
          </GridItem>
        ))}
      </Grid>
    </Stack>
  );
};

export default SettingsOverView;
