import {
  Heading,
  ModalBody,
  Text,
  UnorderedList,
  ListItem,
  Box,
  extendTheme,
} from "@chakra-ui/react";
// import ReactHighlightSyntax from "react-highlight-syntax";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { getCurrentUserInstance } from "../../../../state/localstorage";
const WooDetails = () => {
  const user = getCurrentUserInstance();
  const api = `${user.organisation.apikey}`;
  return (
    <ModalBody py="1em" h="600px">
      <Box
        height={{ base: "500", lg: "700px" }}
        overflowY="auto"
        px={{ base: "1px", lg: "4em" }}
      >
        <Heading size="sm" color="#32325D" mb="20px">
          Adding the Metricks Script to your website
        </Heading>
        <hr />
        <Box width="100%" mt="20px">
          <Heading size="sm" color="#32325D" mb="20px">
            Metricks API Key
          </Heading>
          {/* <ReactHighlightSyntax
            language={"JavaScript"}
            theme={"Base16AtelierCaveLight"}
            copy={true}
            copyBtnTheme={"Dark"}
          >
        </ReactHighlightSyntax> */}
          <SyntaxHighlighter
            language="javascript"
            style={docco}
            customStyle={{
              padding: "15px",
            }}
          >
            {api}
          </SyntaxHighlighter>
        </Box>
        <Text mt="20px">
          To connect Metricks to your woo-commerce store, when setting up your
          campaign on the Metricks dashboard, on the integration step, click on
          “Integrate with Woocommerce,” and you’ll be redirected to the Metricks
          for Woocommerce page.
        </Text>
        <Text mt="20px">
          NB: Be sure you are logged into your WordPress account before moving
          on. If you are logged in, you will find your username at the top right
          corner of the page.
        </Text>
        <Text mt="20px">
          NB: Hitting the 'Download' button will download Metricks plugin as a
          zip file that you can upload to WordPress. We recommend skipping this
          step and heading to your WooCommerce admin dashboard.
        </Text>
        <Box
          backgroundColor="#F1F4FA"
          borderRadius={10}
          p={{ base: 5, lg: 10 }}
          mt="20px"
        >
          <Text>Steps</Text>
          <UnorderedList>
            <ListItem listStyleType="none" mt="20px">
              <Box
                as="span"
                display="inline-block"
                w="8px"
                h="8px"
                bg="#7211D4"
                borderRadius="50%"
                mr="6px"
                position="relative"
              />
              Subscription Created
            </ListItem>
            <ListItem listStyleType="none" mt="20px">
              <Box
                as="span"
                display="inline-block"
                w="8px"
                h="8px"
                bg="#7211D4"
                borderRadius="50%"
                mr="6px"
                position="relative"
              />
              Login to your WooCommerce website admin
            </ListItem>
            <ListItem listStyleType="none" mt="20px">
              <Box
                as="span"
                display="inline-block"
                w="8px"
                h="8px"
                bg="#7211D4"
                borderRadius="50%"
                mr="6px"
                position="relative"
              />
              Select Plugins and click on "Add New"
            </ListItem>
            <ListItem listStyleType="none" mt="20px">
              <Box
                as="span"
                display="inline-block"
                w="8px"
                h="8px"
                bg="#7211D4"
                borderRadius="50%"
                mr="6px"
                position="relative"
              />
              In the search box, type in "Metricks For WooCommerce" and enter.
            </ListItem>
            <ListItem listStyleType="none" mt="20px">
              <Box
                as="span"
                display="inline-block"
                w="8px"
                h="8px"
                bg="#7211D4"
                borderRadius="50%"
                mr="6px"
                position="relative"
              />
              Click Install Now
            </ListItem>
            <ListItem listStyleType="none" mt="20px">
              <Box
                as="span"
                display="inline-block"
                w="8px"
                h="8px"
                bg="#7211D4"
                borderRadius="50%"
                mr="6px"
                position="relative"
              />
              Click Activate
            </ListItem>
            <ListItem listStyleType="none" mt="20px">
              <Box
                as="span"
                display="inline-block"
                w="8px"
                h="8px"
                bg="#7211D4"
                borderRadius="50%"
                mr="6px"
                position="relative"
              />
              Enter your Metricks API key and save.
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>
    </ModalBody>
  );
};

export default WooDetails;
