import React, { useRef } from "react";
import { Box, Image, Text, Stack, Button } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import { Link, useNavigate } from "react-router-dom";

const OffersCarousel = ({ offers }) => {
  const navigate = useNavigate();
  SwiperCore.use([Autoplay]);
  const swiperRef = useRef();

  const getStatusColor = (status) => {
    if (status === "active") {
      return "#239654";
    }
    if (status === "paused") {
      return "#F3994A";
    } else {
      return "#EB5757";
    }
  };

  return (
    <Stack
      mt="0px !important"
      mb="18px"
      background="#FFFFFF"
      width="100%"
      padding={offers.length ? "16px 27px" : { base: "0px", md: "16px 27px" }}
    >
      {offers.length === 0 && (
        <Box
          margin={{ base: "0px", md: "0px 80px" }}
          padding="21px 39px"
          width={{ base: "100%", md: "auto" }}
          background="#F0F5FF"
        >
          <Text
            fontWeight="600"
            fontSize="12px"
            lineHeight="22px"
            color="#32325D"
            textAlign="center"
          >
            No Listed campaigns yet.
            <br /> Add your campaigns to offers to attract more affiliates.
          </Text>
        </Box>
      )}
      {offers.length > 0 && (
        <>
          {offers.length > 4 && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="end"
              marginBottom="15px"
            >
              <Button
                background="#FFFFFF"
                boxShadow="0px 0px 7.42829px rgba(114, 17, 212, 0.25)"
                borderRadius=" 4.64268px"
                width="32px"
                height="28px"
                onClick={() => swiperRef.current?.slidePrev()}
              >
                <Image
                  transform="rotate(180deg)"
                  src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/67853fcc-a95e-4476-be48-14434b51b27d.svg`}
                />
              </Button>
              <Button
                background="#FFFFFF"
                boxShadow="0px 0px 7.42829px rgba(114, 17, 212, 0.25)"
                borderRadius=" 4.64268px"
                width="32px"
                height="28px"
                ml="18px"
                onClick={() => swiperRef.current?.slideNext()}
              >
                <Image
                  src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/67853fcc-a95e-4476-be48-14434b51b27d.svg`}
                />
              </Button>
            </Box>
          )}
          <Box position="relative">
            <Swiper
              spaceBetween={20}
              breakpoints={{
                // when window width is >= 370px
                370: {
                  slidesPerView: 1.6,
                },
                // when window width is >= 400px
                460: {
                  slidesPerView: 1.6,
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 2.4,
                },
                // when window width is >= 768px
                768: {
                  slidesPerView: 2.8,
                },
                // when window width is >= 900px
                900: {
                  slidesPerView: 3.3,
                },
                // when window width is >= 1024px
                1024: {
                  slidesPerView: 3.7,
                },
                // when window width is >= 1200px
                1200: {
                  slidesPerView: 4.2,
                },
                // when window width is >= 1500px
                1500: {
                  slidesPerView: 4.8,
                },
              }}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              autoplay={{ delay: 3000 }}
              // onSlideChange={() => console.log("slide change")}
              // onSwiper={(swiper) => console.log(swiper)}
            >
              {offers.map((item, i) => {
                return (
                  <SwiperSlide position="relative" key={item.id}>
                    <Box
                      display="flex"
                      alignItems="center"
                      width="100%"
                      cursor={"pointer"}
                      onClick={() => {
                        navigate(
                          // `/explore/campaign?id=${activeCampaign}`
                          `/campaigns/view/${item.campaign.id}`
                        );
                      }}
                    >
                      <Text
                        fontWeight="500"
                        fontSize="14px"
                        color=" #32325D"
                        lineHeight="22px"
                        opacity="0.8"
                      >
                        {i + 1}
                      </Text>

                      <Box
                        ml="14px"
                        display="flex"
                        alignItems="center"
                        background="#FFFFFF"
                        border=" 1px solid rgba(50, 50, 93, 0.1)"
                        borderRadius=" 19px"
                        height="72px"
                        p="0px 10px"
                        flex="1"
                      >
                        <Image
                          width="31px"
                          height="30px"
                          borderRadius="50%"
                          src={item.campaign.banner_image || ""}
                        />

                        <Box pl="14px">
                          <Text
                            fontWeight="500"
                            fontSize="14px"
                            color=" #32325D"
                            lineHeight="21px"
                            textTransform="capitalize"
                          >
                            {item.campaign.campaign_name.slice(0, 14)}
                          </Text>
                          <Box display="flex" alignItems="center" mt="4px">
                            <Box
                              background={getStatusColor(item.campaign.status)}
                              height="12px"
                              width="12px"
                              borderRadius="50%"
                            ></Box>
                            <Box>
                              <Text
                                color="#32325D"
                                lineHeight="21px"
                                fontSize="12px"
                                fontWeight="500"
                                paddingLeft="6px"
                                textTransform="capitalize"
                              >
                                {item.campaign.status}
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>
        </>
      )}
    </Stack>
  );
};

export default OffersCarousel;
