import { Box, Image, Text, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Container from "../container";
import Countdown from "react-countdown";
import Time from "./time";
import "../style.css";
import { usePutRequest } from "../../../hooks/usePutRequest";
import { ToastContainer, toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { BaseButton } from "../../button";
import { IoArrowForwardSharp } from "react-icons/io5";
import { RiArrowRightFill } from "react-icons/ri";
const SendToken = () => {
  const [putHandler, serverResponse, serverError] = usePutRequest();
  let navigate = useNavigate();
  useEffect(() => {
    toast.error(serverError);
  }, [serverError]);
  const emailAddress = localStorage.getItem("remember_email");
  const [showToken, setShowToken] = useState(false);
  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a complete state
      setShowToken(true);
      return <Time minutes="0" seconds="0" milliseconds="0" />;
    } else {
      // Render a countdown
      let secondsArr = seconds.toString().split("");
      if (secondsArr.length === 1) {
        secondsArr.unshift(0);
      }
      return (
        <Time
          minutes={minutes}
          seconds={secondsArr[0]}
          milliseconds={secondsArr[1]}
        />
      );
    }
  };

  const sumbitHandler = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    navigate("/reset-password/create-password");
  };
  const requestToken = (e: any) => {
    e.preventDefault();
    putHandler({
      endPoint: process.env.REACT_APP_API_ENDPOINT + "auth/forgot-password",

      dataObject: {
        email: emailAddress,
      },
    });
  };
  if (serverResponse) {
    if (serverResponse.data.success) {
      window.location.reload();
    }
  }
  return (
    <>
      <ToastContainer />
      <Container>
        <Box
          display="flex"
          width="100%"
          height="100vh"
          overflow="hidden"
          left="0"
          bottom="0"
          //
          zIndex="10"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          bgColor="#f0f5ff"
        >
          <Image src="/assets/logo.png" mb="1.5em" height="60px" />
          <Box width={["90%", "50%"]}>
            <Text
              fontSize="18px"
              fontWeight="400"
              marginBottom="40px"
              marginTop="40px"
              textAlign="center"
            >
              A password reset token has been sent to your email address.
              <br />
              Copy the token in your email and click on continue to reset your
              password
            </Text>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
              flexDirection="column"
              width="100%"
              height={["55vh", "auto"]}
              className="bigger"
            >
              <Box
                width="100%"
                display="flex"
                align-items="center"
                justifyContent="center"
              >
                <Countdown
                  date={Date.now() + 180000}
                  renderer={renderer}
                  autoStart={true}
                />
                <Text
                  fontSize="14px"
                  fontWeight="400"
                  display="flex"
                  color="#7211D4"
                  pl="20px"
                  alignItems="center"
                >
                  Minutes
                </Text>
              </Box>

              <form onSubmit={sumbitHandler} style={{ width: "100%" }}>
                <Box my="30px">
                  <BaseButton
                    text="Continue"
                    padded={true}
                    textColor="#fff"
                    bgColor="#5C14CE"
                    type="submit"
                    clickEventHandler={() => console.log("working")}
                    rightIcon={<RiArrowRightFill />}
                  />
                </Box>
                <Text
                  color="#918A8A"
                  mb="2em"
                  fontSize="14px"
                  fontWeight="400"
                  textAlign="center"
                >
                  Check your spam folder if you don't receive an email from us
                  in the next 3 minutes,
                  <br />
                  {showToken && (
                    <Text>
                      or &nbsp;
                      <Text
                        cursor="pointer"
                        as="span"
                        color="#5C14CE !important"
                        onClick={requestToken}
                      >
                        Request a new token.
                      </Text>
                    </Text>
                  )}
                </Text>
              </form>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default SendToken;
