import React from "react";
import { Route, Routes } from "react-router-dom";
import Campaign from "../components/campaigns";
import { Customization } from "../components/campaigns/create/Customization";
import { CampaignDetails } from "../components/campaigns/create/Details";
import ViewCampaign from "../components/campaigns/view-campaigns";
import { MarketingAssets } from "../components/campaigns/create/MarketingAssets";
import { Questions } from "../components/campaigns/create/Questions";
import { ProductDetails } from "../components/campaigns/create/ProductDetails";
import { Asset } from "../components/campaigns/create/Asset";
import { Box } from "@chakra-ui/layout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// @ts-ignore
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const Campaigns = () => {
  return (
    <Box pb=".6em">
      <Routes>
        <Route path="/" element={<Campaign />} />
        <Route path="/view/:id" element={<ViewCampaign />} />
        <Route path="/create" element={<CampaignDetails />} />
        <Route path="/edit-campaign" element={<CampaignDetails />} />
        <Route path="/customization" element={<Customization />} />
        <Route
          path="/edit-campaign/customization"
          element={<Customization />}
        />
        <Route path="/marketing-assets" element={<MarketingAssets />} />
        <Route
          path="/edit-campaign/marketing-assets"
          element={<MarketingAssets />}
        />
        <Route path="/marketing-assets/asset" element={<Asset />} />
        <Route
          path="/edit-campaign/marketing-assets/asset"
          element={<Asset />}
        />
        <Route path="/questions" element={<Questions />} />
        <Route path="/edit-campaign/questions" element={<Questions />} />
        <Route
          path="/product-details"
          element={
            <Elements stripe={stripePromise}>
              <ProductDetails />
            </Elements>
          }
        />
        <Route
          path="/edit-campaign/product-details"
          element={
            <Elements stripe={stripePromise}>
              <ProductDetails />
            </Elements>
          }
        />
      </Routes>
    </Box>
  );
};

export default Campaigns;
