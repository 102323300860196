import React, { ReactNode, useEffect, useState } from "react";
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  useColorModeValue,
  Image,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Spacer,
  DrawerBody,
  DrawerOverlay,
  Radio,
  RadioGroup,
  Slide,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import ChatComponent from "../../chat";
import ChatAffiliateComponent from "../../chat/affiliate";
import FistStep from "../onboarding/firstStep";
import { useNavigate, useSearchParams } from "react-router-dom";
import Joyride from "react-joyride";
import StepsContent from "../onboarding/steps";
import { ColorModeSwitcher } from "../../../ColorModeSwitcher";
import { DashboardIcon } from "./icons/dashboard";
import { CampaignIcon } from "./icons/campaign";
import { OfferIcon } from "./icons/offer";
import { SettingsIcon } from "./icons/settings";
import { ReportIcon } from "./icons/report";
import Notification from "../../notifications";
import { ReactText } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useCookies } from "react-cookie";
import {
  getCurrentUserInstance,
  setLocalUserInstance,
} from "../../../state/localstorage";
import OrganizationSwitch from "../organization-bar";
import { MoreIcon } from "./icons/more";
import { PromptModal } from "./PromptModal";
import ChatComponentCompose from "../../chat/compose";
import { ChatContext } from "../../../contexts/chat";
import { AiOutlineClose } from "react-icons/ai";
import { BsArrowLeft } from "react-icons/bs";
import "intro.js/introjs.css";
import { Steps, Hints } from "intro.js-react";
import { IoArrowForwardSharp } from "react-icons/io5";

interface SidebarProps extends BoxProps {
  onIsClose: () => void;
  onOpenChats: () => void;
  open: boolean;
  onIsOpen: any;
  organization: any;
  onboardingHandler: Function;
  profileHandler: Function;
  openProfile?: boolean;
  setOpenProfile: Function;
}
interface NavItemProps extends FlexProps {
  icon: any;
  route: string;
  routeType?: string;
  setOpenProfile?: Function;
  children: ReactText;
  onClose2: any;
}
interface LinkItemProps {
  name: string;
  icon: any;
  route: string;
  className: string;
}
const LinkItems: Array<LinkItemProps> = [
  {
    name: "Dashboard",
    icon: <DashboardIcon stroke="currentColor" />,
    route: "/dashboard",
    className: "dashboard",
  },
  {
    name: "campaigns",
    icon: <CampaignIcon stroke="currentColor" />,
    route: "/campaigns",
    className: "campaigns",
  },
  // {
  //   name: "Reports",
  //   icon: <ReportIcon stroke="currentColor" />,
  //   route: "/reports",
  //   className: "reports",
  // },
  {
    name: "Explore",
    icon: <OfferIcon stroke="currentColor" />,
    route: "/explore",
    className: "explore",
  },
  {
    name: "Settings",
    icon: <SettingsIcon stroke="currentColor" />,
    route: "/settings",
    className: "settings",
  },
  // {
  //   name: "Refer Metricks",
  //   icon: (
  //     <Image
  //       src="/assets/svg/referral 1.svg"
  //       w="24px"
  //       h="24px"
  //       objectFit="contain"
  //     />
  //   ),
  //   route: "/metricks",
  //   className: "referMetricks",
  // },
];
const LinkItemsM: Array<LinkItemProps> = [
  {
    name: "Dashboard",
    icon: <DashboardIcon stroke="currentColor" />,
    route: "/dashboard",
    className: "dashboardM",
  },
  {
    name: "campaigns",
    icon: <CampaignIcon stroke="currentColor" />,
    route: "/campaigns",
    className: "campaignsM",
  },
  {
    name: "Reports",
    icon: <ReportIcon stroke="currentColor" />,
    route: "/reports",
    className: "reports",
  },
  {
    name: "Explore",
    icon: <OfferIcon stroke="currentColor" />,
    route: "/explore",
    className: "exploreM",
  },
  {
    name: "Settings",
    icon: <SettingsIcon stroke="currentColor" />,
    route: "/settings",
    className: "settingsM",
  },
  // {
  //   name: "Refer Metricks",
  //   icon: (
  //     <Image
  //       src="/assets/svg/referral 1.svg"
  //       w="24px"
  //       h="24px"
  //       objectFit="contain"
  //     />
  //   ),
  //   route: "/metricks",
  //   className: "referMetricksM",
  // },
];

const AffiliateLinkItems: Array<LinkItemProps> = [
  {
    name: "Dashboard",
    icon: <DashboardIcon stroke="currentColor" />,
    route: "/affiliate-dashboard",
    className: "dashboard",
  },
  {
    name: "campaigns",
    icon: <CampaignIcon stroke="currentColor" />,
    route: "/affiliate-campaigns",
    className: "campaigns",
  },
  // {
  //   name: "Reports",
  //   icon: <ReportIcon stroke="currentColor" />,
  //   route: "/affiliate-reports",
  //   className: "reports",
  // },
  {
    name: "Explore",
    icon: <OfferIcon stroke="currentColor" />,
    route: "/affiliate-explore",
    className: "explore",
  },
  {
    name: "Settings",
    icon: <SettingsIcon stroke="currentColor" />,
    route: "/settings",
    className: "settings",
  },
  // {
  //   name: "Refer Metricks",
  //   icon: (
  //     <Image
  //       src="/assets/svg/referral 1.svg"
  //       w="24px"
  //       h="24px"
  //       objectFit="contain"
  //     />
  //   ),
  //   route: "/metricks",
  //   className: "referMetricks",
  // },
];
const AffiliateLinkItemsM: Array<LinkItemProps> = [
  {
    name: "Dashboard",
    icon: <DashboardIcon stroke="currentColor" />,
    route: "/affiliate-dashboard",
    className: "dashboardM",
  },
  {
    name: "campaigns",
    icon: <CampaignIcon stroke="currentColor" />,
    route: "/affiliate-campaigns",
    className: "campaignsM",
  },
  {
    name: "Reports",
    icon: <ReportIcon stroke="currentColor" />,
    route: "/affiliate-reports",
    className: "reports",
  },
  {
    name: "Explore",
    icon: <OfferIcon stroke="currentColor" />,
    route: "/affiliate-explore",
    className: "exploreM",
  },
  {
    name: "Settings",
    icon: <SettingsIcon stroke="currentColor" />,
    route: "/settings",
    className: "settingsM",
  },
  // {
  //   name: "Refer Metricks",
  //   icon: (
  //     <Image
  //       src="/assets/svg/referral 1.svg"
  //       w="24px"
  //       h="24px"
  //       objectFit="contain"
  //     />
  //   ),
  //   route: "/metricks",
  //   className: "referMetricksM",
  // },
];

export const SidebarContentMobile = ({
  onIsClose,
  onIsOpen,
  onOpenChats,
  open,
  organization,
  onboardingHandler,
  profileHandler,
  openProfile,
  setOpenProfile,
  ...rest
}: SidebarProps) => {
  const [show, setShow] = useState(-100000);
  const [cookies] = useCookies([
    "user",
    "access_token",
    "aff_user",
    "aff_access_token",
  ]);
  const [, setCookie] = useCookies([
    "user",
    "access_token",
    "aff_user",
    "aff_access_token",
  ]);
  let [searchParams, setSearchParams] = useSearchParams();
  // console.log("nsnsn", searchParams.get("campaign"));
  const user = getCurrentUserInstance();
  let validToken =
    user.organisation.userType === "affiliate"
      ? cookies.aff_access_token
      : cookies.access_token;

  const btnRef = React.useRef(null);
  const { isOpen, onOpen, onClose: onButtonClose } = useDisclosure();

  const steps: any = [
    {
      content: <FistStep />,
      locale: {
        next: "Yes, show me around",
        skip: (
          <Text
            fontWeight="500"
            fontSize=" 12px"
            lineHeight="18px"
            color=" #49484D"
            aria-label="skip"
            order="4"
          >
            No, I'll explore later
          </Text>
        ),
      },
      placement: "center",
      target: "body",
    },
    // {
    //   target: ".chat",
    //   placement: "bottom",
    //   content: (
    //     <StepsContent
    //       step="1"
    //       title="Chat"
    //       content="You can use this to start chats with your affiliates"
    //     />
    //   ),
    // },
    // {
    //   target: ".notification",
    //   placement: "bottom",
    //   content: (
    //     <StepsContent
    //       step="2"
    //       title="Notifications Bar"
    //       content="Enable push notifications here so you can recieve updates in real time"
    //     />
    //   ),
    // },
    // {
    //   target: ".mobile-notification",
    //   placement: "bottom",
    //   content: (
    //     <StepsContent
    //       step="2"
    //       title="Notifications Bar"
    //       content="Enable push notifications here so you can recieve updates in real time"
    //     />
    //   ),
    // },
    {
      target: ".dashboard",
      placement: "right",
      content: (
        <StepsContent
          step="1"
          title="Dashboard"
          content="Find all your campaign statistics summarized in this section"
        />
      ),
    },
    {
      target: ".mobile-dashboard",
      placement: "right",
      content: (
        <StepsContent
          step="1"
          title="Dashboard"
          content="Find all your campaign statistics summarized in this section"
        />
      ),
    },
    {
      target: ".campaigns",
      placement: "right",
      content: (
        <StepsContent
          step="2"
          title="Campaigns"
          content="Create new and edit your existing campaigns here"
        />
      ),
    },
    // {
    //   target: ".reports",
    //   placement: "right",
    //   content: (
    //     <StepsContent
    //       step="5"
    //       title="Reports"
    //       content="You can view and download all your campaign reports here"
    //     />
    //   ),
    // },
    {
      target: ".explore",
      placement: "right",
      content: (
        <StepsContent
          step="3"
          title="Explore"
          content="Discover new affiliates and upload your campaigns here."
        />
      ),
    },
    {
      target: ".settings",
      placement: "right",
      content: (
        <StepsContent
          step="4"
          title="Settings"
          content="You can update your organization and campaign settings here"
        />
      ),
    },
    {
      content: (
        <StepsContent
          step="5"
          title="Need More Help?"
          content="If you need more help, send an email to support@metricks.io"
        />
      ),
      locale: {
        last: "Complete",
      },
      placement: "center",
      target: "body",
    },
  ];
  const affiliateSteps: any = [
    {
      content: <FistStep />,
      locale: {
        next: "Yes, show me around",
        skip: (
          <Text
            fontWeight="500"
            fontSize=" 12px"
            lineHeight="18px"
            color=" #49484D"
            aria-label="skip"
            order="4"
          >
            No, I'll explore later
          </Text>
        ),
      },
      placement: "center",
      target: "body",
    },
    // {
    //   target: ".chat",
    //   placement: "bottom",
    //   content: (
    //     <StepsContent
    //       step="1"
    //       title="Chat"
    //       content="You can use this to start chats with your affiliates"
    //     />
    //   ),
    // },
    // {
    //   target: ".notification",
    //   placement: "bottom",
    //   content: (
    //     <StepsContent
    //       step="2"
    //       title="Notifications Bar"
    //       content="Enable push notifications here so you can recieve updates in real time"
    //     />
    //   ),
    // },
    // {
    //   target: ".mobile-notification",
    //   placement: "bottom",
    //   content: (
    //     <StepsContent
    //       step="2"
    //       title="Notifications Bar"
    //       content="Enable push notifications here so you can recieve updates in real time"
    //     />
    //   ),
    // },
    {
      target: ".dashboard",
      placement: "right",
      content: (
        <StepsContent
          step="1"
          title="Dashboard"
          content="Find all your campaign statistics summarized in this section"
        />
      ),
    },
    {
      target: ".campaigns",
      placement: "right",
      content: (
        <StepsContent
          step="2"
          title="Campaigns"
          content="View all your approved campaign information here"
        />
      ),
    },
    // {
    //   target: ".reports",
    //   placement: "right",
    //   content: (
    //     <StepsContent
    //       step="5"
    //       title="Reports"
    //       content="You can view and download all your campaign reports here"
    //     />
    //   ),
    // },
    {
      target: ".explore",
      placement: "right",
      content: (
        <StepsContent
          step="3"
          title="Explore"
          content="Find new campaigns and start chats with advertisers here"
        />
      ),
    },
    {
      target: ".settings",
      placement: "right",
      content: (
        <StepsContent
          step="4"
          title="Settings"
          content="You can update your organization and payout information here"
        />
      ),
    },
    {
      content: (
        <StepsContent
          step="5"
          title="Need More Help?"
          content="If you need more help, send an email to support@metricks.io"
        />
      ),
      locale: {
        last: "Complete",
      },
      placement: "center",
      target: "body",
    },
  ];
  const mobileSteps: any = [
    {
      content: <FistStep />,
      locale: {
        next: "Yes, show me around",
        skip: (
          <Text
            fontWeight="500"
            fontSize=" 12px"
            lineHeight="18px"
            color=" #49484D"
            aria-label="skip"
            order="4"
          >
            No, I'll explore later
          </Text>
        ),
      },
      placement: "center",
      target: "body",
    },

    {
      target: ".dashboardM",
      placement: "bottom",
      content: (
        <StepsContent
          step="1"
          title="Dashboard"
          content="Find all your campaign statistics summarized in this section"
        />
      ),
    },
    {
      target: ".campaignsM",
      placement: "bottom",
      content: (
        <StepsContent
          step="2"
          title="Campaigns"
          content="View all your approved campaign information here"
        />
      ),
    },

    {
      target: ".exploreM",
      placement: "bottom",
      content: (
        <StepsContent
          step="3"
          title="Explore"
          content="Find new campaigns and start chats with advertisers here"
        />
      ),
    },
    {
      target: ".settingsM",
      placement: "top",
      content: (
        <StepsContent
          step="4"
          title="Settings"
          content="You can update your organization and payout information here"
        />
      ),
    },
    {
      content: (
        <StepsContent
          step="5"
          title="Need More Help?"
          content={
            <p>
              If you need more help, send an email to{" "}
              <a href="mailto:support@metricks.com">support@metricks.com</a>
            </p>
          }
        />
      ),
      locale: {
        last: "Complete",
      },
      placement: "center",
      target: "body",
    },
  ];

  const affiliateMobileSteps: any = [
    {
      content: <FistStep />,
      locale: {
        next: "Yes, show me around",
        skip: (
          <Text
            fontWeight="500"
            fontSize=" 12px"
            lineHeight="18px"
            color=" #49484D"
            aria-label="skip"
            order="4"
          >
            No, I'll explore later
          </Text>
        ),
      },
      placement: "center",
      target: "body",
    },

    {
      target: ".dashboardM",
      placement: "bottom",
      content: (
        <StepsContent
          step="1"
          title="Dashboard"
          content="Find all your campaign statistics summarized in this section"
        />
      ),
    },
    {
      target: ".campaignsM",
      placement: "bottom",
      content: (
        <StepsContent
          step="2"
          title="Campaigns"
          content="View all your approved campaign information here"
        />
      ),
    },
    {
      target: ".reports",
      placement: "right",
      content: (
        <StepsContent
          step="3"
          title="Reports"
          content="You can view and download all your campaign reports here"
        />
      ),
    },
    {
      target: ".exploreM",
      placement: "bottom",
      content: (
        <StepsContent
          step="4"
          title="Explore"
          content="Find new campaigns and start chats with advertisers here"
        />
      ),
    },
    {
      target: ".settingsM",
      placement: "top",
      content: (
        <StepsContent
          step="5"
          title="Settings"
          content="You can update your organization and payout information here"
        />
      ),
    },
    {
      content: <StepsContent step="6" title="Need More Help?" content={""} />,
      locale: {
        last: "Complete",
      },
      placement: "center",
      target: "body",
    },
  ];

  const [newSteps, setNewSteps] = useState({
    stepsEnabled: true,
    initialStep: 0,
    steps: [
      {
        element: ".dashboardM",
        intro: "Hello step",
      },
      {
        element: ".campaignsM",
        intro: "World step",
      },
    ],
    hintsEnabled: true,
    hints: [
      {
        element: ".dashboardM",
        hint: "Hello hint",
        hintPosition: "middle-right",
      },
      {
        element: ".campaignsM",
        hint: "Hello hint",
        hintPosition: "middle-right",
      },
    ],
  });

  const NavItem = ({
    icon,
    route,
    children,
    routeType,
    setOpenProfile,
    onClose2,
    ...rest
  }: NavItemProps) => {
    const location = useLocation();
    const currentRoute = location.pathname.toLowerCase();
    const {
      isOpen: isOpenCampaignPrompt,
      onOpen: onOpenCampaignPrompt,
      onClose: onCloseCampaignPrompt,
    } = useDisclosure();
    //  const [showCampaignPrompt, setShowCampaignPrompt] = useState(false);

    const navigate = useNavigate();
    const { pathname } = window.location;

    const [proceedToRoute, setProceedToRoute] = useState("");
    const navHandler = (route: string) => {
      if (pathname.includes("/settings/organization")) {
        onOpenCampaignPrompt();
        return setProceedToRoute(route);
      }
      if (pathname.includes("/metricks"))
        return navigate(route, { state: { userType: routeType } });
      if (pathname.includes("/campaigns") === false || !pathname.split("/")[2])
        return navigate(route);

      if (pathname.includes("/campaigns/view")) {
        navigate(route);
      } else {
        // console.log("it has no view", route);
        onOpenCampaignPrompt();
        setProceedToRoute(route);
      }
    };
    return (
      <>
        <PromptModal
          isOpen={isOpenCampaignPrompt}
          onClose={onCloseCampaignPrompt}
          title="Leave page?"
          onClose2={onClose2}
          message="Changes you made may not be saved."
          route={proceedToRoute}
        />
        <Button
          onClick={() => navHandler(route)}
          variant="ghost"
          color={
            // @ts-ignore
            currentRoute.includes(route) ? "#7211D4" : "#32325D"
          }
          _focus={{ bgColor: "transparent" }}
          py="2em"
          borderRadius="0"
          backgroundColor={
            currentRoute.includes(route)
              ? "rgba(114, 17, 212, 0.04)"
              : "transparent"
          }
          _hover={{
            background: "rgba(114, 17, 212, 0.04)",
            color: " #7211D4 !important",
          }}
          w="100%"
        >
          {/* <Box
            backgroundColor={
              currentRoute.includes(route) ? "rgba(114, 17, 212, 0.04)" : "#fffffr"
            }
            _hover={{
              background: "rgba(114, 17, 212, 0.04)",
              color: " #7211D4 !important",
            }}
          > */}
          {/* <NavLink
              style={({ isActive }) =>
                isActive
                  ? {
                      textDecoration: "none",
                      color: " #7211D4 !important",
                    }
                  : { color: "#32325D !important" }
              }
              to={route}
            > */}

          <Flex
            align="center"
            p="4"
            // mx="4"
            borderRadius="lg"
            role="group"
            cursor="pointer"
            {...rest}
            justifyContent="flex-start"
            w="full"
          >
            {icon && <Box>{icon}</Box>}
            <Text
              fontSize="16px"
              fontWeight="400"
              lineHeight="24px"
              textTransform="capitalize"
              marginLeft="20px"
            >
              {children}
            </Text>
          </Flex>

          {/* </NavLink> */}
          {/* </Box> */}
        </Button>
      </>
    );
  };

  const renderStep = () => {
    if (isMobile) {
      if (user.organisation.userType === "affiliate") {
        return affiliateMobileSteps;
      } else {
        return mobileSteps;
      }
    } else {
      if (user.organisation.userType === "affiliate") {
        return affiliateSteps;
      } else {
        return steps;
      }
    }
  };
  const renderStepM = () => {
    if (user.organisation.userType === "affiliate") {
      return affiliateMobileSteps;
    } else {
      return mobileSteps;
    }
  };

  const navigate = useNavigate();
  const handleJoyrideCallback = (data: any) => {
    const { action, index, status, type } = data;
    console.log(type);
    if (status === "finished") {
      onboardingCompleteProfile();
      if (!isMobile) {
        window.$crisp.push(["do", "chat:open"]);
        window.$crisp.push([
          "set",
          "message:text",
          ["Hi! I'd like to get help."],
        ]);
      }
    }
    if (status === "finished" || action === "skip") {
      onboardingHandler(false);
      onboardingCompleteProfile();
    }
  };
  const handleJoyrideCallbackMobile = async (data: any) => {
    const { action, index, status, type } = data;
    if (status === "finished") {
      await onboardingCompleteProfile();
      window.$crisp.push(["do", "chat:open"]);
      window.$crisp.push([
        "set",
        "message:text",
        ["Hi! I'd like to get help."],
      ]);
    }
    if (status === "finished" || action === "skip") {
      onboardingHandler(false);
      onboardingCompleteProfile();
    }
    if (isMobile) {
      if (index === 0) {
        console.log("iamhere");
        // onIsOpen();
      }
    }
  };
  const showProfile = () => {
    profileHandler();
  };
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const onboardingCompleteProfile = async () => {
    await fetch(process.env.REACT_APP_API_ENDPOINT + "auth/update-profile", {
      method: "PUT",
      body: JSON.stringify({
        onboarded: true,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${validToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          let userData = user;
          userData.onboarded = true;
          if (userData.organisation.userType === "affiliate") {
            setCookie("aff_user", userData, {
              path: "/",
            });
          } else {
            setCookie("user", userData, {
              path: "/",
            });
          }
          setLocalUserInstance(userData);
          // setCookie("access_token", data.data.token, {
          //   path: "/",
          //   expires,
          // });
          // window.location.reload();
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    onboardingCompleteProfile();
  }, []);

  const switchProfile = (id: string) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "organisation/switch", {
      method: "POST",
      body: JSON.stringify({
        orgId: id,
        userId: user.id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          let expires = new Date();
          expires.setTime(expires.getTime() + 720 * 60 * 60 * 1000);
          let userData = data.data.user;
          userData.onboarded = true;
          setCookie("user", userData, {
            path: "/",
            expires,
          });
          setCookie("access_token", data.data.token, {
            path: "/",
            expires,
          });
          window.location.reload();
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
        }
      })
      .catch((err) => {
        console.log("here");
      });
  };
  const handleRedirect = () => {
    navigate(
      `/signup?type=newOrganization&country=${user.organisation.country}&userId=${user.id}`
    );
  };
  const logout = () => {
    window.localStorage.clear();
    sessionStorage.removeItem("currentUserType");
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    navigate("/login");
  };
  const {
    isOpen: isOpenCampaignPrompt,
    onOpen: onOpenCampaignPrompt,
    onClose: onCloseCampaignPrompt,
  } = useDisclosure();

  const { pathname } = window.location;
  const [proceedToRoute, setProceedToRoute] = useState("");
  const [isCancelopen, setCancelopen] = useState(false);

  const navHandler = (route: string) => {
    // console.log(pathname);
    if (
      pathname.includes("/settings/organization") &&
      user.organisation.userType === "affiliate"
    ) {
      setProceedToRoute(route);

      onOpenCampaignPrompt();
    }

    if (pathname.includes("/campaigns") === false || !pathname.split("/")[2]) {
      if (route.includes("/logout") === false) return navigate(route);
      else logout();
    }

    if (pathname.includes("/campaigns") && pathname.split("/")[1]) {
      setProceedToRoute(route);

      onOpenCampaignPrompt();
    }
  };

  return (
    <>
      <PromptModal
        isOpen={isOpenCampaignPrompt}
        onClose={onCloseCampaignPrompt}
        title="Leave page?"
        message="Changes you made may not be saved."
        onClose2={onIsClose}
        route={proceedToRoute.includes("logout") ? "" : proceedToRoute}
        handler={proceedToRoute.includes("logout") ? logout : null}
      />
      <Modal isOpen={isCancelopen} onClose={onIsClose} size="md" isCentered>
        <ModalOverlay />
        <ModalContent
          borderRadius="1em"
          p=".8em"
          height="300px"
          mx={{ base: ".6em", md: "auto" }}
          marginTop={{ base: "15em", md: "18em" }}
        >
          <Box
            width=".8em"
            height="300px"
            position="absolute"
            left="0"
            bgGradient="linear(360deg, #7211D4 -1.38%, #FA9445 98.36%)"
            top="0"
            borderLeftRadius="1em"
          ></Box>
          {/* <ModalHeader mt="1em">Modal Title</ModalHeader> */}
          <ModalCloseButton
            bgColor="gray.100"
            borderRadius="1.5em"
            p="1.5em"
            top="1.5em"
            right="1.8em"
            fontSize="1em"
            // my={10}
            onClick={() => setCancelopen(false)}
          />

          <ModalBody mt="5em" mr="1em">
            <Text
              fontSize={{ base: "1em", md: "1.3em" }}
              fontWeight="500"
              mb={{ base: "6em", md: "4em" }}
            >
              Are you sure you want to logout
            </Text>

            <Box
              width="100%"
              height="1px"
              mx="auto"
              bgColor="gray.200"
              mt="1em"
            ></Box>
          </ModalBody>

          <ModalFooter>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              mb="1em"
              // mt="2.5em"
              flexDirection={{
                md: "row",
              }}
              rowGap=".6em"
            >
              <Button
                border="1px"
                borderColor="#5C14CE"
                color="#5C14CE"
                bgColor="#fff"
                fontWeight="500"
                fontSize="14px"
                px="2em"
                _hover={{ bgColor: "#fff" }}
                _focus={{ bgColor: "#fff" }}
                width={["48%"]}
                onClick={() => setCancelopen(false)}
              >
                Cancel
              </Button>
              <Button
                bgColor="#7211D4"
                color="#F0F5FF"
                rightIcon={<IoArrowForwardSharp />}
                onClick={() => navHandler("/logout")}
                width={["48%"]}
                // isLoading={loading}
              >
                Yes, Logout
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box
        transition="3s ease"
        bg={useColorModeValue("#F1F4FA", "gray.900")}
        w={{ base: "full", md: 64 }}
        pos={{ base: "relative", md: "fixed" }}
        h={{ base: "auto", md: "100vh" }}
        overflowY={{ base: "auto", md: "hidden" }}
        flexDirection="column"
        bgColor="#ffffff"
        className="metricks-container"
        {...rest}
      >
        <Box>
          <Flex
            h="20"
            alignItems="center"
            mx="8"
            borderBottom="0.5px solid rgba(50, 50, 93, 0.13)"
            width=" 100%"
            margin="0"
            bg={{ base: "rgba(3, 70, 38, 0.02)", md: "transparent" }}
            justifyContent={{ base: "flex-start", md: "center" }}
          >
            <Box
              h="100%"
              w="85px"
              bg="#F3F3F9"
              onClick={openProfile ? showProfile : onIsClose}
              display={{ base: "flex", md: "none" }}
              alignItems="center"
              justifyContent="center"
            >
              {openProfile ? (
                <BsArrowLeft color="#19073B" size="18px" />
              ) : (
                <AiOutlineClose color="#19073B" size="18px" />
              )}
            </Box>

            <Box
              width="100%"
              display={{ base: "block", md: "flex" }}
              ml={{ base: "24px", md: "" }}
              mt={{ md: "30px" }}
            >
              <Image
                src="/assets/logo.png"
                h={{ base: "34px", md: "auto" }}
                mb={{ md: "1.5em" }}
              />
            </Box>
          </Flex>

          <Box display={{ base: "block", md: "none" }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt="20px"
            >
              <Box width=" 100%" padding="0px 30px">
                <Box
                  width="100%"
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Avatar
                    size={"sm"}
                    src={user.profile_image}
                    name={user.firstname + " " + user.lastname}
                  />
                </Box>

                <Box>
                  <Text
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="20px"
                    color="#32325D"
                    pt="12px"
                    textTransform="capitalize"
                    className="mobile-chat"
                  >
                    {user.firstname} {user.lastname}
                  </Text>
                </Box>
              </Box>
            </Box>

            <Box
              background="rgba(50, 50, 93, 0.25)"
              height="0.3px !important"
              my={{ base: "11px", md: "23px" }}
            ></Box>

            <Box
              paddingLeft="30px"
              paddingRight="21px"
              display={{ base: `${openProfile ? "none" : ""}`, md: "" }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                borderRadius="2px"
                height={{ md: "27.999998092651367px" }}
                mt="15px"
                cursor="pointer"
                onClick={() => {
                  onIsClose();
                  navHandler(
                    user.organisation.userType === "advertiser"
                      ? "/dashboard/account-settings"
                      : "/affiliate-settings"
                  );
                }}
              >
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/71404719-1f55-484f-a4a8-6af7fa114e3f.svg"
                  h={{ base: "16px", md: "" }}
                />
                {/* MOBILE */}
                <Text paddingLeft="20px">Account settings</Text>
              </Box>

              {/* 
              <Box

                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                borderRadius="2px"
                height={{ md: "27.999998092651367px" }}
                mt="15px"
                cursor="pointer"
                onClick={onOpenChats}
              >
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/7a074a02-6aaa-46d4-8fe3-2a3c262defb4.png"
                  h={{ base: "16px", md: "" }}
                />
             
                <Text paddingLeft="20px">Chats</Text>
              </Box> */}
            </Box>

            <Box
              background="rgba(50, 50, 93, 0.25)"
              height="0.3px"
              my={{ base: "15px", md: "23px" }}
              display={{ base: `${openProfile ? "none" : ""}`, md: "" }}
            ></Box>
          </Box>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            padding="0px 17px"
            margin="10px 0px"
            paddingLeft="30px"
          >
            <Flex alignItems="center" justifyContent="center">
              <Avatar
                size={"xs"}
                src={user.profile_image}
                name={
                  user.organisation.displayname || user.organisation.companyname
                }
              />
              <Box ml="17px" pt="4px">
                <Text
                  color="#32325D"
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="21px"
                  textTransform="capitalize"
                >
                  {user.organisation.displayname ||
                    user.organisation.companyname}
                </Text>
                <Text
                  color="#32325D"
                  fontWeight="400"
                  fontSize="10px"
                  lineHeight="15px"
                  textTransform="capitalize"
                  opacity="0.5"
                >
                  {user.organisation.userType}
                </Text>
              </Box>
            </Flex>
            <Flex align={"center"}>
              <Image
                cursor="pointer"
                onClick={showProfile}
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/ab09fff7-94ae-4686-866a-8c88564adfa1.svg"
              />
            </Flex>
          </Flex>
          {/* <Steps
            enabled={true}
            steps={newSteps.steps}
            initialStep={newSteps.initialStep}
            onExit={onExit}
          /> */}

          {isMobile && (
            <Box display={{ base: `${openProfile ? "none" : ""}`, md: "" }}>
              {user.organisation.userType === "advertiser" &&
                LinkItemsM.map((link, i) => (
                  <NavItem
                    className={`${link.className}`}
                    route={link.route}
                    key={link.name}
                    routeType="advertiser"
                    icon={link.icon}
                    setOpenProfile={setOpenProfile}
                    onClose2={onIsClose}
                    //close the sidemenu
                    onClick={() => {
                      searchParams.get("campaign") == null
                        ? onIsClose()
                        : console.log("nothing");
                    }}
                  >
                    {link.name}
                  </NavItem>
                ))}
            </Box>
          )}
          {isMobile && (
            <Box display={{ base: `${openProfile ? "none" : ""}`, md: "" }}>
              {user.organisation.userType === "affiliate" &&
                AffiliateLinkItemsM.map((link) => {
                  if (user.roleId === 6 && link.name === "Settings") {
                    return <></>;
                  } else {
                    return (
                      <NavItem
                        className={link.className}
                        route={link.route}
                        key={link.name}
                        icon={link.icon}
                        routeType="affiliate"
                        onClose2={onIsClose}
                        //close the sidemenu
                        onClick={() => {
                          searchParams.get("campaign") == null
                            ? onIsClose()
                            : console.log("nothing");
                        }}
                      >
                        {link.name}
                      </NavItem>
                    );
                  }
                })}
            </Box>
          )}

          {openProfile ? (
            <Box
              display={{ base: "block", md: "none" }}
              paddingLeft="30px"
              paddingRight="17px"
              minH="40vh"
              mt="20px"
            >
              <Text
                fontSize="10px"
                fontWeight="300"
                color="#32325D"
                opacity="0.9"
                textTransform="capitalize"
                pb="10px"
                borderBottom="1px solid rgba(50, 50, 93, 0.1)"
              >
                {user.organisation.userType === "affiliate"
                  ? "Organizations"
                  : "Organizations"}
              </Text>
              <OrganizationSwitch
                organization={organization}
                selectCallback={onIsClose}
              />
            </Box>
          ) : (
            ""
          )}
        </Box>

        {isMobile && (
          <Joyride
            hideCloseButton
            scrollToFirstStep
            run={open}
            showSkipButton
            steps={isMobile ? renderStepM() : renderStep()}
            callback={handleJoyrideCallback}
            continuous
            styles={{
              buttonNext: {
                background: "#5C14CE",
                padding: "10px 26px",
                order: "1",
                marginBottom: "26px",
                border: "2px solid #5C14CE",
                outline: "none",
              },
              buttonSkip: {
                width: "max-content",
                flex: "0",
                order: "3",
                marginLeft: "15px",
                marginBottom: "26px",
                outline: "none",
              },
              buttonBack: {
                display: "none",
              },
              options: {
                zIndex: 1000000,
              },
            }}
          />
        )}
        {/* {isMobile && (
            <Steps
              enabled={newSteps.stepsEnabled}
              steps={newSteps.steps}
              initialStep={newSteps.initialStep}
              onExit={onExit}
            />
          )} */}

        <Flex
          mt="60px"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          text-align="center"
        >
          <Box
            display={{ base: "flex", md: "none" }}
            alignItems="center"
            justifyContent="flex-start"
            borderRadius="2px"
            px="8"
            py="14px"
            borderTop="0.3px solid rgba(50, 50, 93, 0.25)"
            mt="15px"
            width="100%"
            onClick={() => {
              setOpenProfile(false);
              onboardingHandler(true);
            }}
          >
            <Flex
              px={1}
              py={1}
              borderRadius={50}
              bgColor={"#fff"}
              border="0.3px solid rgba(50, 50, 93, 0.25)"
            >
              <Flex px={3} py={2} borderRadius={50} bgColor={"#7039d4"}>
                <Text fontSize={12} color={"#fff"}>
                  Relaunch onboarding
                </Text>
              </Flex>
            </Flex>
          </Box>

          <Box
            display={{ base: "flex", md: "none" }}
            alignItems="center"
            justifyContent="flex-start"
            borderRadius="2px"
            px="8"
            py="14px"
            borderBottom="0.3px solid rgba(50, 50, 93, 0.25)"
            borderTop="0.3px solid rgba(50, 50, 93, 0.25)"
            // mt="15px"
            width="100%"
            onClick={() => {
              // onIsClose();
              setCancelopen(true);
            }}
            className="explored"
          >
            <Image src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/0a855899-5b32-4906-8301-9586a9ab3eee.svg" />
            <Text paddingLeft="20px">Logout</Text>
          </Box>
        </Flex>
      </Box>
    </>
  );
};
