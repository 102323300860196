import { useEffect, useState } from "react";

const useJsonToCSV = (headers: string[], jsonData: any[]) => {
  const [csvFileLink, setcsvFileLink] = useState("");

  const convertToCSV = () => {
    let tempArr = [headers];

    jsonData.forEach((row: any) => {
      tempArr.push(Object.values(row));
    });

    let csvDataString = "";

    tempArr.map((row: any[]) => {
      csvDataString += row.join(",") + "\n";
    });

    const blob = new Blob([csvDataString], { type: "text/csv;charset=utf-8," });
    const objUrl = URL.createObjectURL(blob);

    return objUrl;
  };

  useEffect(() => {
    setcsvFileLink(convertToCSV());
  }, [jsonData]);

  return [csvFileLink];
};

export default useJsonToCSV;
