import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  css,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import useUserToken from "../../../../../../hooks/useUserToken";
import { MdArrowDropDown } from "react-icons/md";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const AffiliateReports = ({
  id,
  affOrgId,
}: {
  id: string | undefined;
  affOrgId: any;
}) => {
  const [isLoading, setisLoading] = useState(true);

  const token = useUserToken();
  const [filterData, setfilterData] = useState({
    date: "",
    status: "",
  });
  const [filteredReports, setfilteredReports] = useState<any[]>([]);
  const [reports, setreports] = useState<any[]>([]);
  const [invoiceData, setinvoiceData] = useState<any>(null);

  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  const titles = [
    "DATE",
    "PRODUCT ID",
    "SALES",
    "EARNINGS",
    "STATUS",
    "ACTION",
  ];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const invoiceRef = useRef<HTMLDivElement>(null);

  const getStatusColor = (status: string) => {
    if (status.toLowerCase() === "paid")
      return { bg: "rgba(0, 186, 108, 0.1)", text: "rgba(0, 186, 108, 1)" };

    if (status.toLowerCase() === "pending")
      return { bg: "rgba(246, 173, 0, 0.1)", text: "rgba(246, 173, 0, 1)" };

    return { bg: "rgba(236, 99, 92, 0.1)", text: "rgba(236, 99, 92, 1)" };
  };

  const getReports = (
    endpoint: string,
    setPlaceholder: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    if (!id) return;

    setisLoading(true);
    let bearerAuth = token;

    fetch(process.env.REACT_APP_API_ENDPOINT + endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setPlaceholder(
            data.data.conversions.map((report: any) => ({
              id: report.id,
              createdAt: report.createdAt,
              sales: report.amount,
              commission: report.commission.amount,
              status: report.status,
              aff_orgId: report.aff_orgId,
              aff_orgName: report.aff_org.companyname,
              campaignName: report.campaign.campaign_name,
              invoiceDueDate: report.payout.dateScheduledFor,
              invoiceNumber: report.payout.invoiceId,
              productId: report?.metadata.product_id,
            }))
          );
          setisLoading(false);
        }
      })
      .catch((err) => {
        setisLoading(false);
        console.log("get commissions => " + err);
      });
  };

  useEffect(() => {
    setfilterData({
      date: "",
      status: "",
    });
    getReports(
      `/events/conversion/get-conversions/${id}/affiliate/${affOrgId}`,
      setreports
    );
  }, []);

  useEffect(() => {
    if (filterData.date && !filterData.status) {
      getReports(
        `/events/conversion/get-conversions/${id}/affiliate/${affOrgId}?date=${filterData.date}`,
        setfilteredReports
      );
    } else if (!filterData.date && filterData.status) {
      getReports(
        `/events/conversion/get-conversions/${id}/affiliate/${affOrgId}?status=${filterData.status}`,
        setfilteredReports
      );
    } else if (filterData.date && filterData.status) {
      getReports(
        `/events/conversion/get-conversions/${id}/affiliate/${affOrgId}?date=${filterData.date}&status=${filterData.status}`,
        setfilteredReports
      );
    } else {
      // do nothing
    }
  }, [filterData]);

  const renderedList = () => {
    if (isLoading) return reports;
    if (filterData.date || filterData.status) return filteredReports;
    return reports;
  };

  const noResult = () => {
    if (!isLoading && !reports.length)
      return {
        result: true,
        reason: "No reports are available for this affiliate",
      };

    if (!isLoading && !filteredReports.length && filterData.date)
      return { result: true, reason: "No Results Found" };

    return { result: false, reason: "" };
  };

  const openInvoice = (invoiceData: any) => {
    setinvoiceData({
      dueDate: invoiceData?.invoiceDueDate?.split("T")[0],
      invoiceNumber: invoiceData.invoiceNumber || "--",
      invoiceFields: [
        {
          label: "Campaign",
          value: invoiceData?.campaignName,
        },
        {
          label: "Organization",
          value: invoiceData?.aff_orgName,
        },
        {
          label: "Sales",
          value: `$${invoiceData?.sales}`,
        },
        {
          label: "Earnings",
          value: `$${invoiceData?.commission}`,
        },
        {
          label: "Status",
          value: invoiceData?.status,
        },
      ],
    });

    onOpen();
  };

  const downloadInvoice = async () => {
    const pdf = new jsPDF("portrait", "px", "a5");

    if (invoiceRef.current) {
      const data = await html2canvas(invoiceRef.current);
      const img = data.toDataURL("image/png");
      // const imgProperties = pdf.getImageProperties(img);
      // const pdfWidth = pdf.internal.pageSize.getWidth();
      // const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(img, "JPEG", 10, 50, 270, 280);
      pdf.save(invoiceData?.invoiceFields[0].value + "_invoice.pdf");
    }
  };

  if (isLoading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        h="40vh"
        alignItems="center"
        pt="20px"
      >
        <Image className="loading" src="/assets/logo.png" height="80px" />
        <Text
          fontWeight="400"
          fontSize="16px"
          lineHeight="70px"
          color="#000000"
          mt="-15px"
        >
          Loading Reports...
        </Text>
      </Box>
    );

  return (
    <Box
      css={
        isDesktop
          ? {}
          : css({
              "::-webkit-scrollbar": { display: "none" },
              "-webkit-overflow-scrolling": "touch",
            })
      }
      overflowX={{ base: "scroll", md: "visible" }}
      maxWidth="100%"
      mt="13px"
    >
      <HStack>
        <Select
          w="200px"
          bg="white"
          placeholder="Date"
          value={filterData.date}
          onChange={(e) =>
            setfilterData({ ...filterData, date: e.target.value })
          }
          icon={<MdArrowDropDown />}
        >
          <option value="last_day">Last Day</option>
          <option value="last_30_days">Last 30 Days</option>
          <option value="last_quater">Last Quarter</option>
          <option value="last_year">Last Year</option>
        </Select>
      </HStack>

      <Table mb="29px" mt="18px">
        <Thead>
          <Tr height="53px" bgColor="#F1F4FA">
            {titles.map((title) => {
              return (
                <Th
                  color="rgba(50, 50, 93, 0.8)"
                  textTransform="uppercase"
                  lineHeight="18px"
                  fontSize="12px"
                  fontWeight="500"
                  key={title}
                  whiteSpace={{ base: "nowrap", md: "normal" }}
                >
                  {title}
                </Th>
              );
            })}
          </Tr>
        </Thead>

        <Tbody>
          {renderedList().map((item: any) => {
            return (
              <Tr
                key={item.id}
                height="80px"
                width="100%"
                // bgColor={highlighted === item?.id ? "purple.100" : ""}
              >
                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    {item?.createdAt?.split("T")[0] || ""}
                  </Text>
                </Td>

                <Td maxWidth="120px">
                  {/* <Tooltip label={item?.aff_org.companyname} fontSize="md"> */}
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    {item?.productId || "-"}
                  </Text>
                  {/* </Tooltip> */}
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    $ {item?.sales || "-"}
                  </Text>
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    $ {item?.commission || "-"}
                  </Text>
                </Td>

                <Td>
                  <Box
                    backgroundColor={getStatusColor(item?.status).bg}
                    p="4px 5px"
                    w="max-content"
                    borderRadius="8px"
                  >
                    <Text
                      color={getStatusColor(item?.status).text}
                      lineHeight="21px"
                      fontSize="14px"
                      fontWeight="500"
                      textTransform="capitalize"
                    >
                      {item?.status}
                    </Text>
                  </Box>
                </Td>

                <Td>
                  <Button
                    bgColor="#F0F3F9"
                    borderRadius="8px"
                    color="#7211D4"
                    onClick={() => openInvoice(item)}
                  >
                    <Text
                      fontWeight="500"
                      fontSize={{ base: "12px", md: "14px" }}
                      lineHeight={{ base: "18px", md: "21px" }}
                    >
                      View Invoice
                    </Text>
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      {noResult().result && (
        <Box
          w="100%"
          height="30vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={{ base: "0px 16px", md: "0px" }}
          mb="21px"
        >
          <Image
            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
            height="180px"
            objectFit="contain"
            mb="16px"
          />

          <Text
            fontWeight="600"
            fontSize="12px"
            lineHeight="22px"
            color="#32325D"
            textAlign="center"
          >
            {noResult().reason}
          </Text>
        </Box>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize="24px"
            fontWeight="500"
            lineHeight="32px"
            color="rgba(50, 50, 93, 1)"
          >
            Invoice
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody ref={invoiceRef}>
            <Flex p="19px" bgColor="#F0F5FF" borderRadius="8px" mb="40px">
              <Box w="50%">
                <Text
                  lineHeight="21px"
                  fontSize="14px"
                  fontWeight="400"
                  color="#32325D"
                >
                  Due Date
                </Text>
                <Text
                  lineHeight="25px"
                  fontSize="16px"
                  fontWeight="500"
                  color="#32325D"
                >
                  {invoiceData?.dueDate}
                </Text>
              </Box>

              <Box w="50%">
                <Text
                  lineHeight="21px"
                  fontSize="14px"
                  fontWeight="400"
                  color="#32325D"
                >
                  Invoice Number
                </Text>
                <Text
                  lineHeight="25px"
                  fontSize="16px"
                  fontWeight="500"
                  color="#32325D"
                >
                  {invoiceData?.invoiceNumber}
                </Text>
              </Box>
            </Flex>

            <Stack spacing="20px">
              {invoiceData?.invoiceFields.map(
                (field: { label: string; value: string }) => (
                  <Box>
                    <Text
                      lineHeight="21px"
                      mb="8px"
                      fontSize="14px"
                      fontWeight="400"
                      color="#32325D"
                    >
                      {field.label}
                    </Text>
                    <Box
                      borderRadius="8px"
                      p="8px 14px"
                      border="1px solid rgba(50, 50, 93, 0.3)"
                      bg="transparent"
                    >
                      <Text
                        lineHeight="21px"
                        fontSize="14px"
                        fontWeight="600"
                        color="#32325D"
                        textTransform="capitalize"
                      >
                        {field.value}
                      </Text>
                    </Box>
                  </Box>
                )
              )}
            </Stack>
          </ModalBody>

          <ModalFooter borderTop="1px solid rgba(50, 50, 93, 0.3)">
            <Button
              colorScheme="purple"
              variant="ghost"
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>

            <Button
              bgColor="#F0F3F9"
              borderRadius="8px"
              onClick={downloadInvoice}
              color="#7211D4"
            >
              <Text
                fontWeight="500"
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight={{ base: "18px", md: "21px" }}
              >
                Download
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AffiliateReports;
