import React, { useEffect, useState } from "react";
import {
  Stack,
  Box,
  Text,
  Image,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import useUserToken from "../../hooks/useUserToken";
const Notifications = () => {
  const token = useUserToken();
  const [enabled, setEnabled] = useState(false);
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    if (localStorage.getItem("pushNotification") === "enabled") {
      setEnabled(true);
    }
    fetchData();
  }, []);
  const fetchData = () => {
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + "/notification/all", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setNotifications(data.data);
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
        }
      })
      .catch((err) => {
        console.log("here");
      });
  };
  const readNotification = (id: string) => {
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + `/notification/update/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          fetchData();
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
        }
      })
      .catch((err) => {
        console.log("here");
      });
  };

  // createdAt: "2022-11-01T04:49:57.845Z";
  // id: 2;
  // message: "All the heart I broke in lekki, only God fit to protect me";
  // orgId: 16;
  // read: false;
  // subject: "Story of my life";
  // updatedAt: "2022-11-01T04:49:57.846Z";
  // userId: 10;
  // const showNotification=()=> {
  //    if(document.visibilityState === "visible) {
  //        return;
  //    }
  //    var title = "JavaScript Jeep";
  //    icon = "image-url"
  //    var body = "Message to be displayed";
  //    var notification = new Notification('Title', { body, icon });
  //    notification.onclick = () => {
  //           notification.close();
  //           window.parent.focus();
  //    }
  // }
  // const requestAndShowPermission=()=> {
  //    Notification.requestPermission(function (permission) {
  //       if (permission === "granted") {
  //             showNotification();
  //       }
  //    });
  // }
  //   let permission = Notification.permission;
  // if(permission === "granted") {
  //    showNotification();
  // } else if(permission === "default"){
  //    requestAndShowPermission();
  // } else {
  //   alert("Use normal alert");
  // }
  return (
    <Stack>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        background="white"
        height="57px"
        p={["0px 15px", "0px 40px"]}
      >
        <Text
          fontWeight="500"
          fontSize="16px"
          color="#32325D"
          lineHeight="24px"
          textTransform="capitalize"
          width="auto"
          whiteSpace="nowrap"
        >
          Push Notifications
        </Text>
        <FormControl
          width="auto"
          display="flex"
          marginRight="20px"
          alignItems="center"
        >
          <FormLabel htmlFor="email-alerts" mb="0">
            On
          </FormLabel>
          <Switch isChecked={enabled} id="email-alerts" colorScheme="purple" />
        </FormControl>
      </Box>
      <Box m="0!important">
        {notifications.map((item: any) => {
          return (
            <Box
              key={item.id}
              display="flex"
              alignItems="center"
              background={item.read ? "#f6f2f9" : "#ffffff"}
              justifyContent="space-between"
              borderTop="0.836397px solid #C1C0FF"
              borderBottom="0.836397px solid #C1C0FF"
              padding="12px"
              onClick={() => readNotification(item.id)}
            >
              <Box width="8%" display="flex" alignItems="center">
                {item.read && (
                  <Image src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/94eacdc2-158a-420d-8912-46e7fe500ee0.svg" />
                )}
              </Box>
              <Box
                width="92%"
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
              >
                <Text
                  fontSize="12px"
                  color="rgba(50, 50, 93, 0.7)"
                  fontWeight="300"
                  lineHeight="17px"
                >
                  {item.subject}
                </Text>
                <Text
                  fontSize="10px"
                  color="rgba(50, 50, 93, 0.6)"
                  fontWeight="500"
                  lineHeight="15px"
                  pt="10px"
                >
                  {item.message}
                </Text>
                <Text
                  fontSize="10px"
                  color="rgba(50, 50, 93, 0.6)"
                  fontWeight="500"
                  lineHeight="15px"
                  pt="10px"
                >
                  {item.createdAt.split("T")[0]}
                </Text>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Stack>
  );
};
export default Notifications;
