import {
  Avatar,
  Box,
  Button,
  Flex,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { IoIosArrowBack } from "react-icons/io";
import { PiTelegramLogoDuotone } from "react-icons/pi";
import React, { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import Message from "./Message";
// import { io } from "socket.io-client";
import useUserToken from "../../../hooks/useUserToken";
import { getCurrentUserInstance } from "../../../state/localstorage";
import { TemporaryNavData } from "../../context/TemporaryNavData";

const chatData = {
  name: "Angelina Martin",
  id: 3,
  image:
    "https://tse4.explicit.bing.net/th?id=OIP.y-R6b74yIBCpAOfYIBVQpAHaLH&pid=Api&P=0&w=300&h=300",
  isOnline: true,
};

const AdvertiserChatView = ({
  updateCurrentChat,
  currentChat,
}: {
  updateCurrentChat: React.Dispatch<React.SetStateAction<any>>;
  currentChat: any;
}) => {
  const token = useUserToken();
  const user = getCurrentUserInstance();
  const { socket } = useContext(TemporaryNavData);
  console.log("currc", currentChat);
  // const socket = io("https://metricks-backend-service.herokuapp.com", {
  //   extraHeaders: {
  //     access_token: token,
  //   },
  // });
  const getPrivateChat = () => {
    socket.on("connect", () => {
      socket.emit(
        "GET_PRIVATE",
        {
          userId: currentChat.chatId,
          parentId: user.id,
        },
        (event: any) => {
          console.log("GETPrivate ", event);
          setmessages(event);
        }
      );
    });
  };
  useEffect(() => {
    getPrivateChat();
    console.log("im user", user);
  }, [socket]);

  const [messages, setmessages] = useState<any[]>([]);
  const [newMessage, setnewMessage] = useState("");
  const messagesRef = useRef(null);

  const sendMessage = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    socket.on("connect", () => {
      socket.emit("CHAT_PRIVATE", {
        userId: currentChat.chatId,
        orgId: user.organisation.id,
        message: newMessage,
        parentId: user.id,
      });
    });
    getPrivateChat();
    setnewMessage("");
  };

  useEffect(() => {
    if (messagesRef?.current) {
      // @ts-ignore
      messagesRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <Box height="100%" position="relative" display="flex" flexDir="column">
      <Flex
        alignItems="center"
        height="15%"
        padding="8px"
        boxShadow="0px 4px 32px rgba(50, 50, 93, 0.1)"
      >
        <IoIosArrowBack
          width="50px"
          fontSize="20px"
          style={{ cursor: "pointer" }}
          onClick={() => updateCurrentChat(null)}
        />

        <Avatar
          size="md"
          src={currentChat.userImage}
          name={currentChat.username}
          marginLeft="12px"
          marginRight="18px"
        />

        <Box>
          <Text
            fontSize="1rem"
            fontWeight="600"
            lineHeight="24px"
            color="rgba(50, 50, 93, 1)"
          >
            {currentChat.username}
          </Text>

          {chatData.isOnline ? (
            <Flex alignItems="center">
              <Box
                height="9px"
                width="9px"
                bgColor="rgba(31, 191, 126, 1)"
                rounded="full"
                mr="8px"
              />

              <Text
                fontSize="0.75rem"
                fontWeight="500"
                lineHeight="18px"
                color="rgba(50, 50, 93, 0.7)"
              >
                Online
              </Text>
            </Flex>
          ) : (
            ""
          )}
        </Box>
      </Flex>

      <Box py="22px" height="85%" maxH="85%" position="relative">
        <VStack
          ref={messagesRef}
          spacing="24px"
          maxH="100%"
          overflowY="scroll"
          pb="150px"
        >
          {messages.map((mess) => (
            <Message messageData={mess} key={mess.id} />
          ))}
        </VStack>

        <Flex
          position="absolute"
          borderTop="1px solid rgba(50, 50, 93, 0.25)"
          bottom="0"
          width="100%"
          bg="white"
          padding="20px 16px"
        >
          <Textarea
            bgColor="rgba(248, 248, 249, 1)"
            flexGrow="1"
            py="12px"
            px="20px"
            height="40px"
            maxHeight="50px"
            _focus={{ ring: "0", border: "0.5px solid #7211D4" }}
            fontSize="12px"
            lineHeight="18px"
            resize="none"
            value={newMessage}
            onChange={(e) => setnewMessage(e.target.value)}
            placeholder="Compose your message"
          />

          <Button
            color="white"
            bg="rgba(114, 17, 212, 1)"
            marginLeft="12px"
            fontSize="12px"
            lineHeight="18px"
            rounded="10px"
            onClick={(e) => sendMessage(e)}
          >
            <Text>Send</Text>
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default AdvertiserChatView;
