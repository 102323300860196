import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Image,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
// @ts-ignore
import useUserToken from "../../../hooks/useUserToken";
import { useNavigate, useSearchParams } from "react-router-dom";
import Overview from "./tabs/overview";
import { MdChevronRight, MdKeyboardBackspace } from "react-icons/md";
import { motion } from "framer-motion";
import { BsChatDots } from "react-icons/bs";

const ViewCampaign = () => {
  const token = useUserToken();
  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();

  const [campaign, setCampaign] = useState({
    campaign_mode: "",
    campaign_name: "",
    status: "",
    createdAt: "",
    countriesToShip: "",
    id: "",
    banner_image: "",
    background_image: "",
  });

  const [loading, setLoading] = useState(false);
  const [allOffers, setAllOffers] = useState([]);

  useEffect(() => {
    if (searchParams.get("id") !== null) {
      getCampaign(searchParams.get("id"));
      getAdminOffers();
    }
  }, []);

  const getCampaign = (id: string | null) => {
    setLoading(true);
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + `campaign/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("selected campaign", data);
        if (data.success) {
          setCampaign(data.data.campaign);
        } else {
          // formViewRef.current.scrollIntoView();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("get campaign => " + err);
        setLoading(false);
      });
  };

  const getAdminOffers = () => {
    setLoading(true);
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + `explore/offers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setAllOffers(data.data.offers);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const getStatusColor = (status: string) => {
    if (status === "active") {
      return "#239654";
    }
    if (status === "paused") {
      return "#F3994A";
    }
    if (status === "inactive") {
      return "#EB5757";
    }
  };

  const AlreadyInOffer = (name: string) => {
    if (!allOffers.length) return false;

    let offerForCampaign = allOffers.find(
      (item: any) => item?.campaign.campaign_name === name
    );

    if (!offerForCampaign) return false;
    return true;
  };

  const {
    isOpen: onModalIsOpen,
    onOpen: onModalOnOpen,
    onClose,
  } = useDisclosure();

  const toast = useToast();
  const [size, setSize] = React.useState("xl");
  const [creatingOffers, setCreatingOffers] = useState(false);

  const createOffer = () => {
    setCreatingOffers(true);

    if (AlreadyInOffer(campaign.campaign_name)) {
      toast({
        title: "Error creating Offer",
        description: "This campaign has already been added to offers",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setCreatingOffers(false);
    }

    if (campaign.status !== "active") {
      setCreatingOffers(false);
      return toast({
        title: "Error creating Offer",
        description:
          "A Campaign that is not active cannot be added to offers. Make the campaign active and try again",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }

    let bearerAuth = token;
    const payload = { campaignId: campaign.id };

    fetch(process.env.REACT_APP_API_ENDPOINT + `explore/offers/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          handleSizeClick();
        }
      })
      .catch((err) => {
        toast({
          title: "Server Error",
          description: "Unable to create offer for campaign",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        console.log("submit offer: =>" + err);
      });

    setCreatingOffers(false);
  };

  const handleSizeClick = () => {
    onModalOnOpen();
  };

  return loading ? (
    <Box display="flex" h="80vh" alignItems="center" justifyContent="center">
      <motion.div
        animate={{ opacity: [0.5, 1], scale: [0.8, 1.5] }}
        transition={{
          repeat: Infinity,
          repeatType: "reverse",
          duration: 3,
        }}
      >
        <Text fontSize="20px" fontWeight="500" color=" #7211D4">
          Loading...
        </Text>
      </motion.div>
    </Box>
  ) : (
    <Stack padding={{ base: "0px", md: "19px" }} bgColor="#F1F4FA">
      <Box
        display="flex"
        mb={{ base: "30px", md: "12px" }}
        padding={{ base: "12px 20px", md: "0px" }}
        bgColor={{ base: "white", md: "transparent" }}
        alignItems="center"
      >
        <Text
          fontSize="12px"
          fontWeight="500"
          lineHeight="18px"
          color="#32325D"
          mr="5px"
          cursor="pointer"
          _hover={{ textDecoration: "underline" }}
          onClick={() => navigate(-1)}
        >
          Explore
        </Text>

        <MdChevronRight size={20} color="#32325D" />

        <Text
          fontSize="12px"
          lineHeight="18px"
          color="#32325D"
          fontWeight="400"
          ml="5px"
        >
          Add New Offers
        </Text>
      </Box>

      <Stack
        bgColor="white"
        pl={{ base: "19px", md: "0px" }}
        pr={{ base: "19px", md: "0px" }}
      >
        <Box
          as="image"
          width="100%"
          height={["89px", "269px"]}
          bgGradient="linear(90deg, #1BD75F -164%, #1A1414 143.66%)"
          borderRadius="8px"
          position="relative"
          objectFit="cover"
          backgroundImage={
            campaign.background_image ? campaign.background_image : ""
          }
        >
          <Image
            position="absolute"
            bottom="-40%"
            left={{ base: "19px", md: "47px" }}
            width={{ base: "82px", md: "179px" }}
            height={{ base: "82px", md: "179px" }}
            borderRadius="50%"
            objectFit="cover"
            src={campaign?.banner_image ? campaign?.banner_image : ""}
          />
        </Box>

        <Box
          display={{ base: "none", md: "flex" }}
          alignItems="center"
          cursor="pointer"
          justifyContent="end"
          pr="47px"
          mt="34px"
          _hover={{ color: "#7211D4" }}
          onClick={() => navigate(`/explore/campaigns`)}
        >
          <MdKeyboardBackspace color="#32325D" />
          <Text
            color="#32325D"
            lineHeight="40px"
            fontSize="14px"
            fontWeight="400"
            pl="8px"
          >
            Back to the previous page
          </Text>
        </Box>

        <Box
          paddingBottom="34px"
          pt={["60px", "100px"]}
          pl={{ base: "19px", md: "47px" }}
          pr={{ base: "19px", md: "47px" }}
        >
          <Text
            color="#32325D"
            lineHeight={{ base: "24px", md: "36px" }}
            fontSize={{ base: "16px", md: "24px" }}
            fontWeight={{ base: "500", md: "600" }}
            mb={{ base: "3px", md: "0px" }}
          >
            {campaign?.campaign_name}
          </Text>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Text
                color="rgba(50, 50, 93, 0.8)"
                lineHeight="21px"
                fontSize="14px"
                fontWeight="400"
                textTransform="capitalize"
              >
                {campaign?.campaign_mode} Campaign
              </Text>

              <Image
                margin="0px 13px"
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/b4254b20-01f9-4451-9ede-2e8b98258404.svg"
              />

              <Box display="flex" alignItems="center">
                <Box
                  background={getStatusColor(campaign?.status || "active")}
                  height="12px"
                  width="12px"
                  borderRadius="50%"
                ></Box>
                <Text
                  color="#32325D"
                  lineHeight="21px"
                  fontSize="14px"
                  ml="6px"
                  fontWeight="400"
                  textTransform="capitalize"
                >
                  {campaign?.status}
                </Text>
              </Box>
            </Box>

            <Box display={["none", "flex"]} alignItems="center">
              <Box>
                <Button
                  bgColor="#F0F3F9"
                  border=" 0.5px solid #E5E4E4"
                  borderRadius="8px"
                >
                  <Text
                    fontWeight=" 500"
                    fontSize="14px"
                    lineHeight="21px"
                    color=" #7211D4"
                    display={["none", "block"]}
                  >
                    Cancel
                  </Text>
                </Button>
              </Box>
              <Box ml="18px">
                <Button
                  bgColor=" #7211D4"
                  border=" 0.5px solid #E5E4E4"
                  borderRadius="8px"
                  onClick={createOffer}
                  isLoading={creatingOffers}
                >
                  <Text
                    fontWeight=" 500"
                    fontSize="14px"
                    lineHeight="21px"
                    color=" #F0F3F9"
                    display={["none", "block"]}
                  >
                    Submit for review
                  </Text>
                  <Image
                    ml="7px"
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/f986e464-5df1-47da-a06a-cea4503eb0e9.svg"
                  />
                </Button>
              </Box>
              <Modal onClose={onClose} size={size} isOpen={onModalIsOpen}>
                <ModalOverlay />
                <ModalContent maxWidth="65% !important" borderRadius=" 10px">
                  <ModalHeader display="flex" justifyContent="right">
                    <Image
                      onClick={onClose}
                      cursor="pointer"
                      ml="7px"
                      src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/3b803641-20b6-4e47-b554-8336ebd3ec5b.svg"
                    />
                  </ModalHeader>
                  <ModalBody
                    paddingBottom="26px"
                    borderBottom="1px solid rgba(50, 50, 93, 0.3)"
                  >
                    <Text
                      fontWeight="500"
                      fontSize="18px"
                      lineHeight="30px"
                      color=" rgba(50, 50, 93, 0.8)"
                      textAlign="center"
                    >
                      You have submitted{" "}
                      <Text display="inline" color=" #7211D4">
                        {" "}
                        {campaign.campaign_name}{" "}
                      </Text>{" "}
                      for review.
                    </Text>
                  </ModalBody>
                  <ModalFooter display="flex" justifyContent="left">
                    <Button
                      ml="15px"
                      bgColor=" #F0F5FF"
                      color="#5C14CE"
                      borderRadius="8px"
                      onClick={() => navigate(`/explore?tab=pending`)}
                    >
                      Proceed
                      <Image
                        cursor="pointer"
                        ml="10px"
                        mt="1px"
                        src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/37d9e26a-0ec4-462d-98ac-8fca88493c0b.svg"
                      />
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Box>
            {/* <Box display={["flex", "none"]} alignItems="center">
              <Box>
                <Image
                  cursor="pointer"
                  mr="2px"
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/cc7542dc-b911-4eef-ab88-e139713f35e9.svg"
                />
              </Box>
              <Box ml="10px">
                <Image
                  cursor="pointer"
                  mr="7px"
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/16d4ad64-7df3-4360-b6eb-64a312641020.svg"
                />
              </Box>
            </Box> */}
          </Box>
        </Box>

        <Overview campaign={campaign} />

        <Box
          color="white"
          bgColor="#7211D4"
          display={{ base: "flex", md: "none" }}
          borderRadius="8px"
          alignItems="center"
          justifyContent="center"
          width="100%"
          padding="12px 14px"
          mt="56px"
          onClick={createOffer}
        >
          <Text fontSize="16px" lineHeight="24px" fontWeight="500" ml="8px">
            Submit For Review
          </Text>
          <Image
            ml="7px"
            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/f986e464-5df1-47da-a06a-cea4503eb0e9.svg"
          />
        </Box>

        <Box
          display={{ base: "flex", md: "none" }}
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          mt="32px"
          onClick={() => navigate(-1)}
        >
          <MdKeyboardBackspace color="#32325D" />
          <Text
            color="#32325D"
            lineHeight="40px"
            fontSize="14px"
            fontWeight="400"
            pl="8px"
          >
            Back to the previous page
          </Text>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ViewCampaign;
