import { Box, Text, Stack, css } from "@chakra-ui/react";
import Iframe from "react-iframe";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import ProductId from "./ProductId";
import useUserToken from "../../../../../../hooks/useUserToken";
import Countries from "../../../../../explore/explore-campaign-details/tabs/overview/countries";

const Overview = ({ campaign, campaignEarnings }: any) => {
  const [affiliateRef, setaffiliateRef] = useState<string>("");

  const token = useUserToken("affiliate");

  const getproductLinks = () => {
    if (!campaign.id) return;

    let bearerAuth = token;
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `/promote-campaign/affiliate/campaigns??campaignId=${campaign.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setaffiliateRef(data.data.campaigns[0]?.affiliateLink || "");
        }
      })
      .catch((err) => {
        console.log("get affiliate ref => " + err);
      });
  };

  useEffect(() => {
    getproductLinks();
  }, [campaign]);

  const renderCommission = () => {
    if (campaign.commission_type === "fixed") {
      if (campaign.commission_amount) {
        return `${campaign.commission_amount} ${campaign.currency}`;
      } else {
        return "0 usd";
      }
    } else {
      if (campaign.commission_percentage) {
        return `${campaign.commission_percentage} %`;
      } else {
        ("0%");
      }
    }
  };

  return (
    <Stack pb="100px">
      <Box
        background="#F1F4FA"
        borderRadius=" 8px"
        h="95px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        overflowX="auto"
        css={css({
          scrollbarWidth: "none",
          "::-webkit-scrollbar": { display: "none" },
          "-webkit-overflow-scrolling": "touch",
          // boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
          border: "0 none",
        })}
        mb="34px"
      >
        <Box
          display="flex"
          pl="48px"
          pr={{ base: "30px", md: "0px" }}
          w={{ base: "max-content", md: "auto" }}
          flex="1"
          flexDirection="column"
          borderRight="1px solid rgba(50, 50, 93, 0.5)"
        >
          <Text
            color="#32325D"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            opacity="0.8"
            letterSpacing="0.02em"
            textTransform="capitalize"
          >
            {campaign.product_type === "saas"
              ? campaign.product_type.slice(0, 3) +
                campaign.product_type.slice(3).toUpperCase()
              : campaign.product_type}
          </Text>
          <Text
            color="rgba(50, 50, 93, 0.8)"
            lineHeight="18px"
            fontSize="12px"
            fontWeight="400"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            Product
          </Text>
        </Box>
        <Box
          display="flex"
          pl="48px"
          pr={{ base: "30px", md: "0px" }}
          flex="1"
          justifyContent="flex-start"
          flexDirection="column"
          borderRight="1px solid rgba(50, 50, 93, 0.5)"
        >
          <Text
            color="#32325D"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            opacity="0.8"
            letterSpacing="0.02em"
            textTransform="uppercase"
            w={{ base: "max-content", md: "auto" }}
          >
            {renderCommission()}
          </Text>
          <Text
            color="rgba(50, 50, 93, 0.8)"
            lineHeight="18px"
            fontSize="12px"
            fontWeight="400"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            Commission
          </Text>
        </Box>
        <Box
          display="flex"
          pl="48px"
          pr={{ base: "30px", md: "0px" }}
          flex="1"
          flexDirection="column"
          borderRight="1px solid rgba(50, 50, 93, 0.5)"
        >
          <Text
            color="#32325D"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            ${campaignEarnings}
          </Text>
          <Text
            color="rgba(50, 50, 93, 0.8)"
            lineHeight="18px"
            fontSize="12px"
            fontWeight="400"
            w={{ base: "max-content", md: "auto" }}
            opacity="0.8"
            letterSpacing="0.02em"
          >
            All Earnings
          </Text>
        </Box>
        {campaign.product_type === "ecommerce" && (
          <Box
            display="flex"
            pl={{ base: "30px", md: "0px" }}
            pr={{ base: "30px", md: "0px" }}
            flex="1"
            alignItems="center"
            flexDirection="column"
            borderRight="1px solid rgba(50, 50, 93, 0.5)"
          >
            <Text
              color="#32325D"
              lineHeight="21px"
              fontSize="14px"
              fontWeight="500"
              opacity="0.8"
              textTransform="capitalize"
              letterSpacing="0.02em"
            >
              {campaign.stock?.toLowerCase() === "unlimited"
                ? campaign.stock
                : typeof campaign.stock === "number"
                ? new Intl.NumberFormat().format(campaign.stock) + " Units"
                : "0 Units"}
            </Text>
            <Text
              color="rgba(50, 50, 93, 0.8)"
              lineHeight="18px"
              fontSize="12px"
              fontWeight="400"
              w={{ base: "max-content", md: "auto" }}
              opacity="0.8"
              letterSpacing="0.02em"
            >
              Stock Cap
            </Text>
          </Box>
        )}
        <Box
          flex="1"
          display="flex"
          pl="48px"
          pr={{ base: "30px", md: "0px" }}
          flexDirection="column"
        >
          <Text
            color="#32325D"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            opacity="0.8"
            w={{ base: "max-content", md: "auto" }}
            letterSpacing="0.02em"
          >
            {campaign?.createdAt?.split("T")[0]}
          </Text>
          <Text
            color="rgba(50, 50, 93, 0.8)"
            lineHeight="18px"
            fontSize="12px"
            fontWeight="400"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            Launched
          </Text>
        </Box>
      </Box>

      <Box pb="12px" borderBottom="0.8px solid rgba(50, 50, 93, 0.30)">
        <Countries
          countries={campaign.countriesToShip}
          outerPadding="0px 20px"
        />
      </Box>

      <Box
        padding={["24px 12px", "24px 28px"]}
        mt="24px"
        background="#F1F4FA"
        borderRadius=" 8px"
      >
        <Text
          color="#32325D"
          lineHeight="18px"
          fontSize="12px"
          fontWeight="500"
          opacity="0.8"
          letterSpacing="0.02em"
          textTransform="uppercase"
        >
          Description
        </Text>
        <Text
          color="rgba(50, 50, 93, 0.9)"
          lineHeight="22px"
          fontSize="14px"
          fontWeight="300"
          opacity="0.8"
          pt={["13px", "26px"]}
        >
          {parse(campaign.description || "")}
        </Text>
      </Box>

      {campaign?.product_id?.length >= 1 && (
        <ProductId
          productId={campaign.product_id}
          affiliateRef={affiliateRef}
        />
      )}
    </Stack>
  );
};

export default Overview;
