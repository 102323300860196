import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Stack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useCountries } from "../../../hooks/useCountries";
import { getUrlQueryString } from "../../../services/urlQuery";
import { getList } from "country-list-with-dial-code-and-flag";
import SettingsInput from "../components/SettingsInput";
import OrganizationSettingsFooter from "../components/OrganizationSettingsFooter";

const Address = ({
  orgAddress,
  orgCity,
  orgCountry,
  orgState,
  orgZipCode,
  setrefreshData,
  getOrgDetails,
  currentStep,
  setCurrentStep,
}: {
  orgCountry: string;
  orgAddress: string;
  orgState: string;
  orgCity: string;
  orgZipCode: string;
  setrefreshData: React.Dispatch<React.SetStateAction<number>>;
  getOrgDetails: any;
  currentStep: any;
  setCurrentStep: any;
}) => {
  const [allCountries] = useCountries();
  const [country, setCountry] = useState(
    orgCountry
      ? orgCountry
      : getUrlQueryString("default-country")
      ? getUrlQueryString("default-country")
      : ""
  );
  const [address, setAddress] = useState(orgAddress);
  const [localState, setLocalState] = useState(orgState);
  const [city, setCity] = useState(orgCity);
  const [zipCode, setZipCode] = useState(orgZipCode);
  const [newUpdate, setnewUpdate] = useState(false);
  const [count, setcount] = useState(1);
  const updateData = {
    country,
    address,
    state:
      allCountries && allCountries[0].country !== "" && localState === ""
        ? allCountries.find(
            (singleCountry: { country: string; state: string[] }) =>
              singleCountry.country === country
          )?.states[0]
        : localState,
    city,
    zip_code: zipCode,
  };

  console.log(allCountries);
  useEffect(() => {
    if (count > 1) {
      setnewUpdate(true);
      sessionStorage.setItem("advertizerSettingsChange", "new update");
    }
    setcount(count + 1);
  }, [country, address, localState, city, zipCode]);

  const validator = () => {
    if (!country)
      return { isValid: false, error: "Organization's Country is Required" };
    if (!address)
      return { isValid: false, error: "Organization's Address is Required" };
    if (!localState) {
      return { isValid: false, error: "Organization's State is Required" };
    }
    if (!city) {
      return { isValid: false, error: "Organization's City is Required" };
    }
    if (!zipCode) {
      return { isValid: false, error: "Organization's Zip Code is Required" };
    }
    return { isValid: true, error: "" };
  };

  return (
    <Box>
      {/* <Heading fontSize="1rem" color="#32325D" fontWeight={"600"} pb="34px">
        {currentStep}
      </Heading> */}
      <Stack w="full">
        <FormControl
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          columnGap="1em"
          my="1em"
          flexDirection={["column", "row"]}
          borderTop={["0px", "0.5px solid"]}
          borderTopColor={{ md: "rgba(50, 50, 93, 0.30)" }}
          py={[".5em", "1em"]}
          isRequired={true}
        >
          <Flex width={["100%", "30%"]}>
            <FormLabel
              htmlFor="country"
              fontSize="14px"
              color="#32325D"
              flexGrow={1}
              width={{ base: "100%", md: "40%" }}
              textAlign="left"
              mb={[".3em", "0em"]}
            >
              Country
            </FormLabel>
          </Flex>

          <Flex
            alignItems="center"
            flexGrow={1}
            width={["100%", "60%"]}
            borderColor={"#c4c4c4"}
          >
            {/* <Box>
              {getList().find((info: any) => info.name === country)?.flag || ""}
            </Box> */}
            <Flex width={["100%", "85%"]}>
              <Select
                _focus={{ ring: "0", borderColor: "#5C14CE" }}
                border="0.6px solid rgba(50, 50, 93, 0.30)"
                borderRadius="8px"
                color="#32325D"
                fontWeight="400"
                fontSize="14px"
                style={{
                  padding: "10px",
                  height: "50px",
                }}
                // @ts-ignore
                value={country}
                onChange={(e: React.ChangeEvent<any>) =>
                  setCountry(e.target.value)
                }
              >
                {getList()
                  .map((data: any) => data.name)
                  .map((name: string, i: any) => {
                    return (
                      <option key={i} value={name}>
                        {name}
                      </option>
                    );
                  })}
              </Select>
            </Flex>
          </Flex>
        </FormControl>

        <SettingsInput
          label="Address"
          type="text"
          value={address}
          changeHandler={setAddress}
          isRequired={true}
        />

        {allCountries && allCountries[0].country !== "" && (
          <SettingsInput
            label="State"
            type="select"
            value={localState}
            changeHandler={setLocalState}
            isRequired={true}
            options={
              allCountries && allCountries[0].country !== ""
                ? allCountries.find(
                    (singleCountry: { country: string; state: string[] }) =>
                      singleCountry.country === country
                  )?.states
                : [""]
            }
          />
        )}

        <SettingsInput
          label="City"
          type="text"
          value={city}
          changeHandler={setCity}
          isRequired={true}
        />

        <SettingsInput
          label="Zip Code"
          type="text"
          value={zipCode}
          changeHandler={setZipCode}
          isRequired={true}
        />
      </Stack>

      <OrganizationSettingsFooter
        hasUpdate={newUpdate}
        sethasUpdate={setnewUpdate}
        dataToUpdate={updateData}
        setRefreshData={setrefreshData}
        validator={validator}
        getOrgDetails={getOrgDetails}
      />
    </Box>
  );
};

export default Address;
