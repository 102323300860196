import React from "react";
import { Text, Flex, Box, Image, Avatar } from "@chakra-ui/react";
import Type from "../../select-type";
import { useMediaQuery } from "react-responsive";

const OrganizationContent = ({ data, modalHandler }: any) => {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  return (
    <Flex height="100%">
      <Box display={isMobile ? "none" : "block"}>
        <Image
          height="100%"
          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/81dc1778-387e-47d6-8994-59dd23f4f71e.svg"
          alt="data"
        />
      </Box>
      <Box padding="34px" width="100%" height="100%">
        <Text
          fontWeight="600"
          fontSize="24px"
          lineHeight="40px"
          color="#000000"
          pb="20px"
        >
          Select an organization to login
        </Text>
        <Box
          paddingRight={["0px", "14px"]}
          height={["100%", "340px"]}
          overflowY="scroll"
        >
          {data &&
            data.map((item: any, i: any) => {
              return (
                <Box marginBottom="29px" key={i}>
                  <Type
                    title={item.companyName}
                    desc={item.orgType}
                    image={<Avatar size={"md"} name={item.companyName} />}
                    selectHandler={() => {
                      modalHandler(item);
                    }}
                    isDraft={item.isDraft}
                  />
                </Box>
              );
            })}
        </Box>
      </Box>
    </Flex>
  );
};

export default OrganizationContent;
