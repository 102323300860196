import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  css,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IoPersonOutline } from "react-icons/io5";
import { BsChatDots } from "react-icons/bs";

type AffiliateTableProps = {
  affiliates: any[];
  highlighted: any[];
  sethighlighted: React.Dispatch<React.SetStateAction<any[]>>;
  setcurrentSelectedAffiliate: React.Dispatch<any>;
};

type CheckBoxTrackType = {
  [key: string | number]: boolean;
};

const titles = [
  "",
  "ORGANIZATION",
  "SIGNUP DATE",
  "CAMPAIGN",
  "LAST ACTIVE",
  "STATUS",
  "ACTION",
];

const AffiliatesTable = ({
  affiliates,
  highlighted,
  sethighlighted,
  setcurrentSelectedAffiliate,
}: AffiliateTableProps) => {
  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  const [checkBoxTracker, setcheckBoxTracker] = useState<CheckBoxTrackType>({});

  // const navigate = useNavigate();
  const handleSelect = (affiliate: any) => {
    const foundAff = highlighted.find((item: any) => item.id === affiliate?.id);

    if (foundAff) {
      sethighlighted(
        highlighted.filter((item: any) => item.id !== affiliate?.id)
      );
      setcheckBoxTracker({ ...checkBoxTracker, [affiliate.id]: false });
    } else {
      sethighlighted([...highlighted, affiliate]);
      setcheckBoxTracker({ ...checkBoxTracker, [affiliate.id]: true });
    }
  };

  const handleSelectAll = () => {
    let obj = {};

    if (highlighted.length === affiliates.length && affiliates.length !== 0) {
      sethighlighted([]);
      setcheckBoxTracker({});
    } else {
      sethighlighted(affiliates);

      affiliates.forEach((aff: { [key: string]: any }) => {
        obj = { ...obj, [aff.id]: true };
      });
      setcheckBoxTracker(obj);
    }
  };

  const getStatusColor = (status: string) => {
    if (status.toLowerCase() === "approved") return "#239654";

    if (status.toLowerCase() === "pending") return "#F3994A";

    return "#eb5757cd";
  };

  return (
    <Stack>
      <Box
        css={
          isDesktop
            ? {}
            : css({
                "::-webkit-scrollbar": { display: "none" },
                "-webkit-overflow-scrolling": "touch",
              })
        }
        overflowX={{ base: "scroll", md: "visible" }}
        maxWidth="100%"
        mt="13px"
      >
        <Table mb="29px">
          <Thead>
            <Tr height="53px" bgColor="#F1F4FA">
              {titles.map((title) => {
                return (
                  <Th
                    color="rgba(50, 50, 93, 0.8)"
                    textTransform="uppercase"
                    lineHeight="18px"
                    fontSize="12px"
                    fontWeight="500"
                    key={title}
                    whiteSpace={{ base: "nowrap", md: "normal" }}
                  >
                    {title === "" ? (
                      <Checkbox
                        colorScheme="purple"
                        onChange={() => handleSelectAll()}
                        isChecked={
                          highlighted.length === affiliates.length &&
                          affiliates.length !== 0
                        }
                      />
                    ) : (
                      title
                    )}
                  </Th>
                );
              })}
            </Tr>
          </Thead>

          <Tbody>
            {affiliates.map((item: any) => {
              return (
                <Tr
                  key={item.id}
                  height="80px"
                  width="100%"
                  bgColor={checkBoxTracker[item.id] ? "purple.100" : ""}
                >
                  <Td pl="20px" pr="10px">
                    <Checkbox
                      colorScheme="purple"
                      onChange={() => handleSelect(item)}
                      isChecked={checkBoxTracker[item.id] || false}
                    />
                  </Td>

                  <Td maxWidth="120px">
                    <Tooltip label={item?.aff_org.companyname} fontSize="md">
                      <Text
                        color="#32325D"
                        lineHeight={{ base: "18px", md: "21px" }}
                        fontSize={{ base: "12px", md: "14px" }}
                        fontWeight="500"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {item?.aff_org.companyname || "-"}
                      </Text>
                    </Tooltip>
                  </Td>

                  <Td>
                    <Text
                      color="#32325D"
                      lineHeight={{ base: "18px", md: "21px" }}
                      fontSize={{ base: "12px", md: "14px" }}
                      fontWeight="500"
                    >
                      {item?.createdAt?.split("T")[0] || ""}
                    </Text>
                  </Td>

                  <Td maxWidth="120px">
                    <Tooltip label={item?.campaign.campaign_name} fontSize="md">
                      <Text
                        color="#32325D"
                        lineHeight={{ base: "18px", md: "21px" }}
                        fontSize={{ base: "12px", md: "14px" }}
                        fontWeight="500"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {item?.campaign.campaign_name || "-"}
                      </Text>
                    </Tooltip>
                  </Td>

                  <Td>
                    <Text
                      color="#32325D"
                      lineHeight={{ base: "18px", md: "21px" }}
                      fontSize={{ base: "12px", md: "14px" }}
                      fontWeight="500"
                    >
                      {item?.aff_org?.last_login?.split("T")[0] || "-"}
                    </Text>
                  </Td>

                  <Td>
                    <Flex alignItems="center">
                      <Box
                        backgroundColor={getStatusColor(item?.status)}
                        height="12px"
                        width="12px"
                        borderRadius="50%"
                      ></Box>
                      <Box>
                        <Text
                          color="#32325D"
                          lineHeight="18px"
                          fontSize="12px"
                          fontWeight="500"
                          ml="6px"
                          textTransform="capitalize"
                        >
                          {item?.status}
                        </Text>
                      </Box>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex alignItems="center">
                      <Button
                        bgColor="#F0F3F9"
                        borderRadius="8px"
                        color="#7211D4"
                        mr="18px"
                        onClick={() => setcurrentSelectedAffiliate(item)}
                        cursor={"pointer"}
                      >
                        <IoPersonOutline fontSize={14} />
                        <Text
                          fontWeight="500"
                          fontSize={{ base: "12px", md: "14px" }}
                          lineHeight={{ base: "18px", md: "21px" }}
                          ml="7px"
                        >
                          View
                        </Text>
                      </Button>

                      {/* <Box>
                        <BsChatDots size={14} color="#7211D4" />
                      </Box> */}
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Stack>
  );
};

export default AffiliatesTable;
