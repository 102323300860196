import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import CustomSelect from "../customSelect";
import { getList } from "country-list-with-dial-code-and-flag";
import { MdOutlineCancel } from "react-icons/md";
import { CloseIcon } from "@chakra-ui/icons";

type FilterPropTypes = {
  filterData: any;
  setfilterData: React.Dispatch<any>;
  setSearchVal: React.Dispatch<React.SetStateAction<string>>;
  searchVal: string;
  advancedFilterChange: boolean;
  setAdvancedFilterChange: React.Dispatch<React.SetStateAction<boolean>>;
  setFilterInfo: React.Dispatch<any>;
  setsearchResult: React.Dispatch<any>;
};

const Filter = ({
  filterData,
  setfilterData,
  setSearchVal,
  searchVal,
  advancedFilterChange,
  setAdvancedFilterChange,
  setFilterInfo,
  setsearchResult,
}: FilterPropTypes) => {
  const [filterIsOpen, setfilterIsOpen] = useState<boolean>(false);
  const [currentSelectViwed, setcurrentSelectViwed] = useState("");
  const [allCountries, setAllCountries] = useState<any[]>(
    getList().map((country: any) => {
      return { ...country, selected: false };
    })
  );

  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  const countryRef = useRef(null);
  const experienceRef = useRef(null);
  const productRef = useRef(null);

  const {
    isOpen: isModalFilterOpen,
    onOpen: onModalFilterOpen,
    onClose: onModalFilterClose,
  } = useDisclosure();

  const toggleAdvanced = () => {
    if (isDesktop) {
      setfilterIsOpen(!filterIsOpen);
    } else {
      onModalFilterOpen();
    }

    setSearchVal("");
    setfilterData({
      country: [],
      product: [],
      experience: [],
      search: [],
    });
    setFilterInfo(null);
    setsearchResult([]);
  };

  useEffect(() => {
    if (isDesktop) {
      onModalFilterClose();
    } else {
      setfilterIsOpen(false);
    }
  }, [isDesktop]);

  const addDataToFilter = (data: string, title: string) => {
    // console.log("selected", data);
    // console.log("selected", {
    //   ...filterData,
    //   [title]: filterData[title].filter((item: string) => item !== data),
    // });
    if (filterData[title].includes(data)) {
      setfilterData({
        ...filterData,
        [title]: filterData[title].filter((item: string) => item !== data),
      });
    } else {
      setfilterData({
        ...filterData,
        [title]: [...filterData[title], data],
      });
    }
  };

  return (
    <Box>
      <Box
        py={"10px"}
        css={{
          borderTop: "1px solid rgba(50, 50, 93, 0.15)",
          borderBottom: "1px solid rgba(50, 50, 93, 0.15)",
        }}
        display="flex"
        alignItems="center"
      >
        <Flex
          p={{ base: "19px 0px", md: "10px" }}
          align={"center"}
          bgColor={"#F9F7FB"}
          width={"100%"}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width={{ base: "100px", md: "auto" }}
            css={{
              border: "0.5px solid rgba(50, 50, 93, 0.4)",
            }}
            borderRadius="8px"
            py="7px"
            px="14px"
            mr="10px"
            cursor="pointer"
            onClick={toggleAdvanced}
          >
            <Image
              w="16px"
              h="9px"
              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/2b827a68-23d5-45ce-acdc-2dcaf9387d96.svg"
            />
            <Text
              fontSize="14px"
              lineHeight="21px"
              fontWeight="500"
              ml="8px"
              color="rgba(50, 50, 93, 0.8)"
            >
              Filter
            </Text>
          </Box>

          {filterIsOpen ? (
            <HStack spacing="10px">
              <CustomSelect
                title="Country"
                fieldVals={allCountries}
                showVals={currentSelectViwed === "Country"}
                filterData={filterData}
                setfilterData={setfilterData}
                toggleValsVisibility={setcurrentSelectViwed}
                viewedSelect={currentSelectViwed}
                refData={countryRef}
                setAdvancedFilterChange={setAdvancedFilterChange}
                advancedFilterChange={advancedFilterChange}
              />

              <CustomSelect
                title="Years of Experience"
                filterData={filterData}
                setfilterData={setfilterData}
                showVals={currentSelectViwed === "Years of Experience"}
                fieldVals={["0-1 year", "2-4 years", "5-9 years", "10 years >"]}
                viewedSelect={currentSelectViwed}
                refData={experienceRef}
                toggleValsVisibility={setcurrentSelectViwed}
                setAdvancedFilterChange={setAdvancedFilterChange}
                advancedFilterChange={advancedFilterChange}
              />

              <CustomSelect
                title="Product"
                filterData={filterData}
                setfilterData={setfilterData}
                fieldVals={["SAAS", "ECOMMERCE"]}
                viewedSelect={currentSelectViwed}
                showVals={currentSelectViwed === "Product"}
                refData={productRef}
                toggleValsVisibility={setcurrentSelectViwed}
                setAdvancedFilterChange={setAdvancedFilterChange}
                advancedFilterChange={advancedFilterChange}
              />
            </HStack>
          ) : (
            <InputGroup outlineColor="#7211D4">
              <InputLeftElement
                pointerEvents="none"
                children={<RiSearchLine color="rgba(50, 50, 93, 0.8)" />}
              />
              <Input
                css={{
                  border: "0.5px solid rgba(50, 50, 93, 0.4)",
                }}
                value={searchVal}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchVal(e.target.value)
                }
                type="text"
                flex={{ base: "1", md: "auto" }}
                maxWidth={{ md: "280px" }}
                width={{ base: "auto", md: "280px" }}
                placeholder="Search affiliate names"
              />
            </InputGroup>
          )}

          {filterIsOpen && (
            <Button
              bgColor="#EBE1F7"
              borderRadius="8px"
              padding="5px 20px"
              ml="auto"
              onClick={toggleAdvanced}
            >
              <Flex gap={3} align={"center"}>
                <Text
                  fontSize="14px"
                  lineHeight="24px"
                  fontWeight="500"
                  color="#7211D4"
                >
                  Close
                </Text>
                <CloseIcon
                  fontSize={"12px"}
                  color="#7211D4"
                  fontWeight={"700"}
                />
              </Flex>
            </Button>
          )}

          <Modal
            onClose={onModalFilterClose}
            size="lg"
            isCentered
            isOpen={isModalFilterOpen}
          >
            <ModalOverlay />
            <ModalContent borderRadius="10px">
              <ModalHeader
                display="flex"
                fontSize="18px"
                fontWeight="500"
                lineHeight="27px"
              >
                Affiliate Filter
              </ModalHeader>
              <ModalCloseButton />

              <ModalBody
                paddingBottom="26px"
                borderBottom="1px solid rgba(50, 50, 93, 0.3)"
              >
                <Stack spacing="19px">
                  <CustomSelect
                    title="Country"
                    fieldVals={allCountries}
                    showVals={currentSelectViwed === "Country"}
                    filterData={filterData}
                    setfilterData={setfilterData}
                    isFull={true}
                    toggleValsVisibility={setcurrentSelectViwed}
                    viewedSelect={currentSelectViwed}
                  />

                  <CustomSelect
                    title="Years of Experience"
                    filterData={filterData}
                    setfilterData={setfilterData}
                    showVals={currentSelectViwed === "Years of Experience"}
                    fieldVals={["0-1", "2-4", "5-9", "10-49"]}
                    viewedSelect={currentSelectViwed}
                    toggleValsVisibility={setcurrentSelectViwed}
                  />

                  <CustomSelect
                    title="Product"
                    filterData={filterData}
                    setfilterData={setfilterData}
                    isFull={true}
                    fieldVals={["SAAS", "ECOMMERCE"]}
                    viewedSelect={currentSelectViwed}
                    showVals={currentSelectViwed === "Product"}
                    toggleValsVisibility={setcurrentSelectViwed}
                  />
                </Stack>
              </ModalBody>

              <ModalFooter display="flex" justifyContent="right">
                <Button
                  colorScheme="purple"
                  fontSize="14px"
                  lineHeight="21px"
                  variant="ghost"
                  onClick={onModalFilterClose}
                >
                  Cancel
                </Button>

                <Button
                  ml="20px"
                  color="white"
                  bgColor="#5C14CE"
                  borderRadius="8px"
                  fontSize="14px"
                  lineHeight="21px"
                  onClick={() => {
                    setAdvancedFilterChange(!advancedFilterChange);
                    onModalFilterClose();
                  }}
                >
                  Apply
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>
      </Box>
      {(filterData.country.length >= 1 ||
        filterData.experience.length >= 1 ||
        filterData.product.length >= 1) && (
        <Flex
          p={{ base: "19px 5px", md: "10px" }}
          align={"center"}
          bgColor={"#F3F3F5"}
          borderRadius={10}
          my={3}
          justify={"space-between"}
        >
          <Flex wrap={"wrap"} gap={3} w={["100%", "85%"]}>
            {filterData.country.map((country: any, i: any) => (
              <Flex
                align={"center"}
                bgColor={"#F0F5FF"}
                p={1.5}
                borderRadius={10}
                gap={2}
              >
                <MdOutlineCancel
                  size={14}
                  cursor={"pointer"}
                  onClick={() => {
                    addDataToFilter(country, "country");
                  }}
                />
                <Text>{country}</Text>
              </Flex>
            ))}
            {filterData.experience.map((experience: any, i: any) => (
              <Flex
                align={"center"}
                bgColor={"#F0F5FF"}
                p={1.5}
                borderRadius={10}
                gap={2}
              >
                <MdOutlineCancel
                  size={14}
                  cursor={"pointer"}
                  onClick={() => {
                    addDataToFilter(experience, "experience");
                  }}
                />
                <Text>{experience}</Text>
              </Flex>
            ))}
            {filterData.product.map((product: any, i: any) => (
              <Flex
                align={"center"}
                bgColor={"#F0F5FF"}
                p={1.5}
                borderRadius={10}
                gap={2}
              >
                <MdOutlineCancel
                  size={14}
                  cursor={"pointer"}
                  onClick={() => {
                    addDataToFilter(product, "product");
                  }}
                />
                <Text>{product} </Text>
              </Flex>
            ))}
          </Flex>

          <Box w={"14%"} display={["none", "flex"]} justifyContent={"flex-end"}>
            <Flex
              gap={2}
              bgColor={"#F7F7F7"}
              p={1.5}
              cursor={"pointer"}
              onClick={() => {
                toggleAdvanced();
              }}
            >
              <Text fontSize={12} color={"red"}>
                Cancel Filters
              </Text>
              <MdOutlineCancel size={14} color="red" />
            </Flex>
          </Box>
        </Flex>
      )}
      {filterIsOpen && (
        <Flex display={["flex", "none"]}>
          <Flex gap={2} bgColor={"#F7F7F7"} p={1.5} cursor={"pointer"}>
            <Text fontSize={11} color={"red"}>
              Cancel Filters
            </Text>
            <MdOutlineCancel size={14} cursor={"pointer"} color="red" />
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default Filter;
