import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// @ts-ignore
import { Editor, EditorToolbar } from "react-draft-wysiwyg";
import "draft-js/dist/Draft.css";

// initial contentState should be  EditorState.createEmpty()

export const TextEditor = ({
  contentState,
  stateHandler,
}: {
  contentState: any;
  stateHandler: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const changeHandler = (state: any) => stateHandler(state);

  return (
    <>
      <Editor
        editorStyle={{ border: "1px solid #CBD5E0", borderRadius: ".2em" }}
        editorState={contentState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={(editorState: any) => changeHandler(editorState)}
        toolbar={{
          options: ["inline", "fontSize", "list", "link", "history"],
          inline: {
            inDropdown: true,
            options: ["bold", "italic", "underline"],
          },
          textAlign: { inDropdown: false },
          link: { inDropdown: true },
          history: { inDropdown: false },
          colorPicker: { inDropdown: false },
          image: { inDropdown: false },
          list: { inDropdown: false, options: ["unordered", "ordered"] },
        }}
      />
    </>
  );
};
