import { Box, Flex, Heading, Text, SlideFade } from "@chakra-ui/react";
import { useEffect } from "react";
import { BsCheckLg } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";

interface PropSchema {
  title: string;
  hints: { passed: boolean; text: any }[];
  showWhen: boolean;
}

export const InputHints = ({ title, hints, showWhen }: PropSchema) => {
  return (
    <SlideFade offsetY="100px" in={showWhen}>
      <Box border="1px" borderRadius="0.6em" p="1.5em" mb="1em">
        <Heading
          fontSize="14px"
          fontWeight="semibold"
          color="formGray"
        >{`${title}:`}</Heading>

        <Box mt="1em">
          {hints.map((hint, i) => {
            return (
              <Flex alignItems="center" key={i} my="1em">
                {hint.passed ? (
                  <BsCheckLg color="#11D686" />
                ) : (
                  <IoMdClose color="#C70C0C" />
                )}
                <Text ml="1em" mt="-0.4em" fontSize="15px">
                  {hint.text}
                </Text>
              </Flex>
            );
          })}
        </Box>
      </Box>
    </SlideFade>
  );
};
