import Container from "../container";
import { Box, Image, Text, Button, Heading } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { BaseButton } from "../../button";
import { IoArrowForwardSharp } from "react-icons/io5";
const PasswordSuccess = () => {
  const navigate = useNavigate();
  const sumbitHandler = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    navigate("/login");
  };
  return (
    <Container>
      <Box
        display="flex"
        width="100%"
        height="100vh"
        overflow="hidden"
        left="0"
        bottom="0"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        zIndex="10"
        pt="70px"
        pb="2.5em"
        bgColor="#f0f5ff"
      >
        <Image src="/assets/logo.png" mb="1.5em" height="60px" />
        <Box width={["90%", "50%"]}>
          <Heading
            as="h1"
            fontWeight="600"
            color="#000000"
            fontSize="20px"
            lineHeight="50px"
            textAlign="center"
            mt="50px"
          >
            Password reset successful
          </Heading>
          <form onSubmit={sumbitHandler}>
            <Box my="30px">
              <BaseButton
                text="Login"
                padded={true}
                textColor="#fff"
                bgColor="#5C14CE"
                type="submit"
                clickEventHandler={() => console.log("working")}
                rightIcon={<IoArrowForwardSharp />}
              />
            </Box>
            <Text
              color="#918A8A"
              mb="2em"
              fontSize="14px"
              fontWeight="400"
              textAlign="center"
            >
              If you require further assistance, contact &nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:support@metricks.io"
              >
                <Text as="span" color="#5C14CE !important">
                  support@metricks.io
                </Text>
              </a>
            </Text>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default PasswordSuccess;
