import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import CampaignTabs from "./campaign-tabs/Index";
import CampaignTable from "./table";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  MdArrowDropDown,
  MdChevronRight,
  MdOutlineFilterList,
} from "react-icons/md";
import { RiSearchLine } from "react-icons/ri";
import FilterDrawer from "./mobile-elements/FilterDrawer";
import useUserToken from "../../../hooks/useUserToken";

const Campaign = () => {
  const [currentTab, setcurrentTab] = useState<string>("approved");
  const [filterData, setfilterData] = useState({
    date: "",
    status: "",
  });
  const [searchVal, setsearchVal] = useState<string>("");

  const [filterIsOpen, setfilterIsOpen] = useState<boolean>(false);
  let [searchParams, _] = useSearchParams();
  const navigate = useNavigate();
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();

  const token = useUserToken("affiliate");

  const checkToken = async () => {
    if (!token) return;

    await fetch(process.env.REACT_APP_API_ENDPOINT + `/auth/valid-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data?.valid) {
          return;
        } else {
          window.localStorage.clear();
          sessionStorage.removeItem("currentUserType");
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          navigate("/login");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (token) {
      checkToken();
    }
  }, [token]);

  useEffect(() => {
    if (searchParams.get("id")) {
      let c_status = searchParams.get("status") || "approved";
      setcurrentTab(c_status);
    }
  }, []);

  const handleFilterChange = (prop: string, value: string) => {
    setfilterData({ date: "", status: "" });
    setsearchVal("");
    setfilterData({ ...filterData, [prop]: value });
  };

  return (
    <Stack padding={{ base: "0px", md: "27px" }} bgColor="#F1F4FA" minH="90vh">
      <Box
        bgColor="white"
        alignItems="center"
        display={{ base: "flex", md: "none" }}
        p="13px 20px"
      >
        <Text
          fontSize="14px"
          lineHeight="21px"
          fontWeight="700"
          color="#32325D"
          mr="6px"
        >
          Campaigns
        </Text>

        <MdChevronRight size={16} color="#32325D" />

        <Text
          ml="6px"
          fontSize="14px"
          lineHeight="21px"
          fontWeight="400"
          color="#32325D"
        >
          Overview
        </Text>
      </Box>

      <Stack
        bgColor={{ base: "transparent", md: "white" }}
        padding={{ base: "27px 20px", md: "27px 12px" }}
      >
        <Box
          display={{ base: "none", md: "flex" }}
          mb="15px"
          alignItems="center"
          justifyContent={{ base: "center", md: "start" }}
        >
          <Center
            mr="16px"
            border="0.5px solid rgba(50, 50, 93, 0.4)"
            borderRadius="8px"
            width="96px"
            height="36px"
            cursor="pointer"
            bgColor="white"
            onClick={() => {
              setfilterIsOpen(!filterIsOpen);
              navigate("/affiliate-campaigns");
            }}
          >
            <Text fontSize="0.9rem">Filter</Text>
          </Center>

          <CampaignTabs
            setFilterData={setfilterData}
            setSearchVal={setsearchVal}
            currentTab={currentTab}
            setCurrentTab={setcurrentTab}
          />
        </Box>

        <Box
          display={{ base: "flex", md: "none" }}
          mb="24px"
          alignItems="center"
        >
          <Button
            bgColor="white"
            border="1px solid rgba(229, 228, 228, 1)"
            onClick={onDrawerOpen}
            mr="10px"
          >
            <MdOutlineFilterList size={10} color="rgba(50, 50, 93, 1)" />
            <Text
              ml="9px"
              color="rgba(50, 50, 93, 0.8)"
              fontSize="14px"
              lineHeight="21px"
            >
              Filter
            </Text>
          </Button>

          <InputGroup flex="1">
            <InputLeftElement
              pointerEvents="none"
              children={<RiSearchLine color="rgba(50, 50, 93, 0.8)" />}
            />
            <Input
              css={{
                border: "0.5px solid rgba(50, 50, 93, 0.3)",
              }}
              type="text"
              value={searchVal}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setsearchVal(e.target.value)
              }
              fontSize="0.9rem"
              bg={{ base: "white", md: "transparent" }}
              focusBorderColor="#7211D4"
              placeholder="Search campaigns"
            />
          </InputGroup>
        </Box>

        {filterIsOpen && (
          <Box
            display={{ base: "none", md: "flex" }}
            flexDirection={{ base: "column", md: "row" }}
            alignItems={{ base: "start", md: "center" }}
            p="13px 0px"
            borderTop="0.5px solid rgba(50, 50, 93, 0.3)"
            borderBottom="0.5px solid rgba(50, 50, 93, 0.3)"
          >
            <InputGroup
              w={{ base: "100%", md: "280px" }}
              mb={{ base: "16px", md: "0px" }}
            >
              <InputLeftElement
                pointerEvents="none"
                children={<RiSearchLine color="rgba(50, 50, 93, 0.8)" />}
              />
              <Input
                css={{
                  border: "0.5px solid rgba(50, 50, 93, 0.4)",
                }}
                type="text"
                value={searchVal}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setsearchVal(e.target.value)
                }
                fontSize="0.9rem"
                mr="20px"
                fontWeight="500"
                bg={{ base: "white", md: "transparent" }}
                focusBorderColor="#7211D4"
                placeholder="Search campaigns"
              />
            </InputGroup>

            <HStack spacing="14px">
              <Select
                w="120px"
                bg="white"
                placeholder="Date"
                value={filterData.date}
                border="0.5px solid rgba(50, 50, 93, 0.4)"
                onChange={(e) => handleFilterChange("date", e.target.value)}
                focusBorderColor="#7211D4"
                icon={<MdArrowDropDown />}
              >
                <option value="last_day">Last Day</option>
                <option value="last_30_days">Last 30 Days</option>
                <option value="last_quater">Last Quarter</option>
                <option value="last_year">Last Year</option>
              </Select>

              {currentTab === "approved" && (
                <Select
                  w="120px"
                  bg="white"
                  placeholder="Status"
                  value={filterData.status}
                  border="0.5px solid rgba(50, 50, 93, 0.4)"
                  onChange={(e) => handleFilterChange("status", e.target.value)}
                  focusBorderColor="#7211D4"
                  icon={<MdArrowDropDown />}
                >
                  <option value="active">Active</option>
                  <option value="paused">Paused</option>
                </Select>
              )}
            </HStack>
          </Box>
        )}

        <FilterDrawer
          onDrawerClose={onDrawerClose}
          isDrawerOpen={isDrawerOpen}
          setFilterData={setfilterData}
          setSearchVal={setsearchVal}
          filterData={filterData}
          setCurrentTab={setcurrentTab}
        />

        <CampaignTable
          tabStatus={currentTab}
          filterData={filterData}
          searchVal={searchVal}
        />
      </Stack>
    </Stack>
  );
};

export default Campaign;
