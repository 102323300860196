import React, { useState } from "react";
import { Box, Checkbox, Flex, Image, Text } from "@chakra-ui/react";

const Type = ({
  title,
  desc,
  selectHandler,
  image,
  isDraft,
}: {
  title: string;
  desc: string;
  selectHandler: Function;
  image?: any;
  isDraft?: boolean;
}) => {
  const [hover, setHover] = useState(false);
  const [clicked, setClicked] = useState(false);
  const onMouseEnter = () => {
    setHover(true);
  };
  const cardClicked = () => {
    setClicked(!clicked);
    selectHandler();
  };

  const onMouseLeave = () => {
    setHover(false);
  };
  return (
    <Box
      background={hover || clicked ? "#F5F8FF" : "#ffffff"}
      border={hover || clicked ? "2px solid #7211D4" : "2px solid #D1D1D1"}
      borderRadius="13px"
      height={["158px", "125px"]}
      width="100%"
      display="flex"
      alignItems={["flex-start", "center"]}
      justifyContent="space-between"
      padding={["22px 22px", "35px 29px"]}
      fontFamily="Montserrat"
      fontStyle="normal"
      cursor="pointer"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={cardClicked}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={["column", "row"]}
        width={["95%", "auto"]}
      >
        <Box display={["flex", "block"]} justifyContent="center">
          {!image ? (
            title == "Advertiser" ? (
              <Image src="https://metricksassets.s3.us-east-2.amazonaws.com/development/default/59c0585e-bd8d-4a87-9e44-9e015a81268b.png" />
            ) : (
              <Image src="https://metricksassets.s3.us-east-2.amazonaws.com/development/default/6095f367-4385-4cae-95c1-3aab58c8f50d.png" />
            )
          ) : (
            image
          )}
        </Box>

        <Flex>
          <Box
            paddingLeft={["0px", "17px"]}
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
          >
            <Text
              fontWeight="600"
              fontSize="20px"
              lineHeight="25px"
              color="#333333"
            >
              {title}
            </Text>
            <Text
              fontWeight="400"
              fontSize={["12px", "16px"]}
              lineHeight="40px"
              color="#333333"
              marginTop={["-15px", "0px"]}
              textTransform="capitalize"
            >
              {desc}
            </Text>
          </Box>

          {isDraft ? (
            <Text
              w="52px"
              color="#5C14CE"
              bgColor="#DAC7F6"
              py="3px"
              ml="11px"
              textAlign="center"
              rounded="4px"
              h="max-content"
            >
              Draft
            </Text>
          ) : (
            ""
          )}
        </Flex>
      </Box>
      <Box width={["5%", "auto"]}>
        <Checkbox
          colorScheme="purple"
          color="#A5A5A5"
          alignItems="center"
          isChecked={hover || clicked}
          borderRadius="200px !important"
          marginTop={["0px", "-20px"]}
        ></Checkbox>
      </Box>
    </Box>
  );
};

export default Type;
