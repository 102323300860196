import {
  Box,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import SettingsInput from "./SettingsInput";
import { useOutsideAlerter } from "../../../../hooks/useOutsideAlerter";
import { getList } from "country-list-with-dial-code-and-flag";
import { MdCancel } from "react-icons/md";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { BsCheckSquareFill, BsFillCaretUpFill, BsSearch } from "react-icons/bs";
import OrganizationSettingsFooter from "./OrganizationSettingsFooter";

const AffiliateOrgBackground = ({
  affYears,
  affTeamSize,
  countriesToShip,
  productType,
  setRefreshData,
}: {
  affYears: string;
  affTeamSize: string;
  countriesToShip: string[];
  productType: string[];
  setRefreshData: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [affiliateYears, setaffiliateYears] = useState(affYears);
  const [teamSize, setteamSize] = useState(affTeamSize);
  const [searchQuery, setSearchQuery] = useState("");
  const [allCountries, setAllCountries] = useState(
    countriesToShip && countriesToShip.length
      ? getList().map((country: any) => {
          if (countriesToShip.includes(country?.name)) {
            return { country, selected: true };
          } else {
            return { country, selected: false };
          }
        })
      : getList().map((country: any) => {
          return { country, selected: false };
        })
  );
  let products = [
    {
      defaultIcon:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/dee212f9-5a5d-4fd6-869d-b4e0fa080ed3.svg",
      coloredIcon: "/assets/svg/colored-ecommerce.svg",
      title: "E-commerce",
      selected: false,
    },
    {
      defaultIcon:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/a7631722-e9dd-4f15-94de-f9c97efe7cba.svg",
      coloredIcon:
        "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/dfee292c-71af-43d6-87c9-5307de051e34.svg",
      title: "SaaS",
      selected: false,
    },
  ];
  const [affProductType, setAffProductType] = useState(
    productType && productType.length
      ? products.map((prod) => {
          if (
            productType.includes(prod?.title?.split("-").join("").toLowerCase())
          ) {
            return { ...prod, selected: true };
          } else {
            return prod;
          }
        })
      : products
  );

  const wrapperCountryRef = useRef(null);
  const productsWrapperRef = useRef(null);

  const [showCountryFilter, setShowCountryFilter] = useState(false);
  const [showProducts, setShowProducts] = useState(false);

  const [newUpdate, setnewUpdate] = useState(false);
  const [count, setcount] = useState(1);

  useOutsideAlerter(wrapperCountryRef, () => setShowCountryFilter(false));
  useOutsideAlerter(productsWrapperRef, () => setShowProducts(false));

  useEffect(() => {
    if (count > 1) {
      setnewUpdate(true);
      sessionStorage.setItem("affiliateSettingsChange", "new update");
    }
    setcount(count + 1);
  }, [affiliateYears, teamSize, productType, countriesToShip]);

  const productsToggleHandler = () => {
    return setShowProducts(!showProducts);
  };

  const toggleCountryHandler = () => {
    return setShowCountryFilter(!showCountryFilter);
  };

  const validator = () => {
    if (!affiliateYears) {
      return {
        isValid: false,
        error: "Number of years as an affiliate is required",
      };
    }
    if (!teamSize) {
      return { isValid: false, error: "Team size is required" };
    }
    if (!affProductType.filter((dt: any) => dt.selected).length) {
      return {
        isValid: false,
        error: "Please choose at least one type of product",
      };
    }
    if (
      !allCountries.filter((countryData: any) => countryData.selected).length
    ) {
      return {
        isValid: false,
        error: "Please choose at least one country to ship",
      };
    }

    return { isValid: true, error: "" };
  };

  const updateData = {
    affiliate_years: affiliateYears,
    team_size: teamSize,
    productType: affProductType
      .filter((dt: any) => dt.selected)
      .map((data: any) => data.title.split("-").join("").toLowerCase()),
    countriesToShip: allCountries
      .filter((countryData: any) => countryData.selected)
      .map((data: any) => data.country.name),
  };

  return (
    <Box>
      <SettingsInput
        label="How long have you been an affiliate?"
        type="select"
        value={affiliateYears}
        changeHandler={setaffiliateYears}
        isRequired={true}
        options={["0-1", "2-4", "5-9", "10-49"]}
      />

      <SettingsInput
        label="What's the size of your team?"
        type="select"
        value={teamSize}
        changeHandler={setteamSize}
        isRequired={true}
        options={["0-1", "2-4", "5-9", "10-49"]}
      />

      <Box
        display="flex"
        columnGap="1em"
        my="1em"
        flexDirection={["column", "row"]}
        borderTop={["0px", "0.5px solid"]}
        borderTopColor={{ md: "rgba(50, 50, 93, 0.30)" }}
        py={[".5em", "1em"]}
      >
        <Text
          fontSize="0.9rem"
          color="#32325D"
          fontWeight="500"
          width={{ base: "100%", md: "30%" }}
          textAlign="left"
          mb={[".3em", "0em"]}
        >
          What Countries do you advertise in?
        </Text>

        <Box
          width={{ base: "100%", md: "70%" }}
          maxW="572px"
          ref={wrapperCountryRef}
        >
          <Flex
            border="0.6px solid rgba(50, 50, 93, 0.30)"
            minHeight="48px"
            padding="0px 12px 0px 12px"
            borderRadius="8px"
            justifyContent="space-between"
            alignItems="center"
            w="100%"
            columnGap=".5em"
            cursor="pointer"
            onClick={() => {
              toggleCountryHandler();
            }}
          >
            <Flex rowGap=".5em" columnGap=".5em" flexWrap="wrap" py=".5rem">
              {allCountries.filter((countryData: any) => countryData.selected)
                .length >= 1 ? (
                allCountries
                  .filter((countryData: any) => countryData.selected)
                  .map((data: any, i: any) => {
                    return (
                      <Box
                        key={i}
                        bgColor="#F0F5FF"
                        py={["3px", "3px", "6px"]}
                        px=".4em"
                        alignItems="center"
                        display="flex"
                        borderRadius="4px"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Box>{data.country.flag}</Box>
                        <Text
                          mr=".8em"
                          ml=".5em"
                          fontSize="14px"
                          fontWeight="400"
                          color="gray.800"
                        >
                          {data.country.name}
                        </Text>

                        <MdCancel
                          color="#000"
                          cursor="pointer"
                          onClick={(e: React.ChangeEvent<any>) => {
                            const newState = allCountries.map(
                              (ctry: any, x: any) => {
                                if (data.country?.name === ctry.country?.name) {
                                  return { ...ctry, selected: !ctry.selected };
                                } else {
                                  return ctry;
                                }
                              }
                            );
                            setAllCountries(newState);
                          }}
                        />
                      </Box>
                    );
                  })
              ) : (
                <Text cursor="pointer" onClick={toggleCountryHandler}>
                  Select countries
                </Text>
              )}
            </Flex>
            <Box>
              {!showCountryFilter ? (
                <IoChevronDown
                  cursor="pointer"
                  onClick={toggleCountryHandler}
                />
              ) : (
                <IoChevronUp cursor="pointer" onClick={toggleCountryHandler} />
              )}
            </Box>
          </Flex>

          {showCountryFilter && (
            <Box
              p={["1em", "1em", "1.5em"]}
              my="1em"
              boxShadow="xl"
              rounded="xl"
              height="60vh"
              overflowY="scroll"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                my=".5em"
              >
                <Text fontSize="14px" color="gray.400" fontWeight="400">
                  Search Country
                </Text>

                <BsFillCaretUpFill
                  color="#CACBD4"
                  cursor="pointer"
                  onClick={toggleCountryHandler}
                />
              </Flex>

              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<BsSearch color="gray.300" />}
                />
                <Input
                  type="text"
                  _focus={{ ring: "0" }}
                  border="0"
                  bgColor="#F4F6F8"
                  value={searchQuery}
                  onChange={(e: React.ChangeEvent<any>) =>
                    setSearchQuery(e.target.value)
                  }
                />
              </InputGroup>

              {allCountries.map((countryData: any, i: any) => {
                return (
                  <Box
                    display={
                      searchQuery !== "" &&
                      !countryData.country.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                        ? "none"
                        : "flex"
                    }
                    justifyContent="space-between"
                    alignItems="center"
                    key={i}
                    cursor="pointer"
                    _hover={{ bgColor: "gray.50" }}
                    p=".5em"
                    my="2px"
                    borderRadius="3px"
                    onClick={() => {
                      const newState = allCountries.map((data: any, x: any) => {
                        if (allCountries[i] == data) {
                          return { ...data, selected: !data.selected };
                        } else {
                          return data;
                        }
                      });
                      setAllCountries(newState);
                    }}
                  >
                    <Flex alignItems="center" mt="1em">
                      <div> {countryData.country.flag}</div>
                      <Text ml="1em">{countryData.country.name}</Text>
                    </Flex>

                    {countryData.selected ? (
                      <BsCheckSquareFill color="#5C15CE" />
                    ) : (
                      <Box
                        width="1em"
                        height="1em"
                        border="1.5px solid gray"
                        borderRadius="4px"
                      ></Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </Box>

      <Flex
        columnGap="1em"
        my="1em"
        flexDirection={["column", "row"]}
        borderTop={["0px", "0.5px solid"]}
        borderTopColor={{ md: "rgba(50, 50, 93, 0.30)" }}
        py={[".5em", "1em"]}
      >
        <Text
          fontSize="0.9rem"
          color="#32325D"
          fontWeight="500"
          width={{ base: "100%", md: "30%" }}
          textAlign="left"
          mb={[".3em", "0em"]}
        >
          What type of products do you promote?
        </Text>

        <Box
          width={{ base: "100%", md: "70%" }}
          maxW="572px"
          ref={productsWrapperRef}
        >
          <Flex w="100%" columnGap=".5em" alignItems="center">
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              border="1px solid"
              borderColor="gray.300"
              borderRadius="8px"
              minHeight="48px"
              padding="0px 12px 0px 12px"
              mb="1em"
              cursor={"pointer"}
              onClick={productsToggleHandler}
            >
              <Flex columnGap=".5em" rowGap=".5em" flexWrap="wrap">
                {affProductType.filter((product: any) => product.selected)
                  .length >= 1 ? (
                  affProductType
                    .filter((product: any) => product.selected)
                    .map((product: any, i: any) => {
                      return (
                        <Box
                          key={i}
                          bgColor="#F0F5FF"
                          py={["3px", "3px", "6px"]}
                          px=".4em"
                          alignItems="flex-start"
                          display="flex"
                          borderRadius="4px"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Text
                            mr=".8em"
                            fontSize="14px"
                            fontWeight="400"
                            color="gray.800"
                          >
                            {product.title}
                          </Text>
                          <MdCancel
                            color="#000"
                            cursor="pointer"
                            onClick={() => {
                              const newState = affProductType.map(
                                (data: any, x: any) => {
                                  if (product.title === data.title) {
                                    return { ...data, selected: false };
                                  } else {
                                    return data;
                                  }
                                }
                              );
                              setAffProductType(newState);
                            }}
                          />
                        </Box>
                      );
                    })
                ) : (
                  <Text>Select Product Type</Text>
                )}
              </Flex>

              <Box>
                {!showProducts ? (
                  <IoChevronDown
                    cursor={"pointer"}
                    onClick={productsToggleHandler}
                  />
                ) : (
                  <IoChevronUp
                    cursor={"pointer"}
                    onClick={productsToggleHandler}
                  />
                )}
              </Box>
            </Box>
          </Flex>

          {showProducts && (
            <Box
              p={["1em", "1em", "1.5em"]}
              my="1em"
              boxShadow="xl"
              rounded="xl"
              height="25vh"
            >
              {affProductType.map((product: any, i: any) => {
                return (
                  <Box
                    key={i}
                    display={
                      searchQuery !== "" &&
                      !product.title
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                        ? "none"
                        : "flex"
                    }
                    justifyContent="space-between"
                    alignItems="flex-start"
                    my="1em"
                    cursor="pointer"
                    _hover={{ bgColor: "gray.50" }}
                    onClick={() => {
                      const newState = affProductType.map(
                        (data: any, x: any) => {
                          if (affProductType[i] == data) {
                            return { ...data, selected: !data.selected };
                          } else {
                            return data;
                          }
                        }
                      );
                      setAffProductType(newState);
                    }}
                    p=".5em"
                    borderRadius="3px"
                  >
                    <Text>{product?.title}</Text>

                    {product?.selected ? (
                      <BsCheckSquareFill color="#5C15CE" />
                    ) : (
                      <Box
                        width="1em"
                        height="1em"
                        border="1.5px solid gray"
                        borderRadius="4px"
                      ></Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          )}

          {affProductType.filter((niche: any) => niche.selected)?.length ===
          0 ? (
            <Text
              fontSize="14px"
              color="red.300"
              mt={showProducts ? "0em" : "-0.6em"}
              fontWeight="medium"
              className="nicheError"
            >
              This field is required
            </Text>
          ) : null}
        </Box>
      </Flex>

      <OrganizationSettingsFooter
        hasUpdate={newUpdate}
        sethasUpdate={setnewUpdate}
        setRefreshData={setRefreshData}
        dataToUpdate={updateData}
        validator={validator}
      />
    </Box>
  );
};

export default AffiliateOrgBackground;
