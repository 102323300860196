import {
  Box,
  Button,
  Checkbox,
  Heading,
  Image,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import FormField from "./FormField";
import { CiLock, CiUser } from "react-icons/ci";
import useUserToken from "../../hooks/useUserToken";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InputHints } from "../InputHints";
import ReCAPTCHA from "react-google-recaptcha";

const ManagerSignup = () => {
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [password, setpassword] = useState("");
  const [agreedToTerms, setagreedToTerms] = useState<boolean>(false);
  const [confirmPassword, setconfirmPassword] = useState("");
  const [creatingNewManager, setcreatingNewManager] = useState<boolean>(false);
  const [errorField, setErrorField] = useState("");

  const token = useUserToken();
  const toast = useToast();
  let [queryParams, _] = useSearchParams();
  const navigate = useNavigate();

  const createNewManager = () => {
    setcreatingNewManager(true);
    if (!firstName) {
      setcreatingNewManager(false);
      setErrorField("firstName");

      return toast({
        title: "Failed Invite New Manager",
        description: "First name is required",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    } else if (!lastName) {
      setcreatingNewManager(false);
      setErrorField("lastName");

      return toast({
        title: "Failed Invite New Manager",
        description: "Last name is required",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    } else if (!password) {
      setcreatingNewManager(false);
      setErrorField("password");

      return toast({
        title: "Failed Invite New Manager",
        description: "Password is required",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    } else if (confirmPassword !== password) {
      setcreatingNewManager(false);

      return toast({
        title: "Failed Invite New Manager",
        description: "Passwords do not match",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    } else if (!agreedToTerms) {
      setcreatingNewManager(false);
      setErrorField("terms");
      return toast({
        title: "Failed Invite New Manager",
        description: "You must agree to metricks terms and",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    } else if (!queryParams.get("managerId") || !queryParams.get("email")) {
      setcreatingNewManager(false);
      return toast({
        title: "Failed Invite New Manager",
        description: "Missing required parameters",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }

    let bearerAuth = token;

    const payload = {
      firstname: firstName,
      lastname: lastName,
      password: password,
      managerId: Number(queryParams.get("managerId")) || "",
      email: queryParams.get("email") || "",
    };

    fetch(process.env.REACT_APP_API_ENDPOINT + `create/manager`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setcreatingNewManager(false);
          navigate("/login");

          return toast({
            title: "Signup successful",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          setcreatingNewManager(false);
          return toast({
            title: "Failed signup as manager",
            description: data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      });
  };

  const clearValidation = () => {
    setErrorField("");
  };

  return (
    <Box
      bgColor={{ base: "white", md: "#F0F5FF" }}
      width="100vw"
      minH="100vh"
      py={{ base: "25px", md: "40px" }}
      display="flex"
    >
      <Stack
        bgColor="white"
        maxW={{ base: "95%", md: "664px" }}
        margin="0 auto"
        p={{ base: "25px 15px", md: "40px 86px" }}
        spacing="28px"
      >
        <Image src="/assets/logo.png" height="50px" width="78px" />

        <Heading
          fontSize={{ base: "1.5rem", md: "1.75rem" }}
          fontWeight="700"
          color="#333"
        >
          {queryParams.get("organizationName") || ""} has invited you to join
          their team.
        </Heading>

        <Stack spacing="16px">
          <FormField
            icon={<CiUser color="#737373" size="24px" />}
            label="First Name"
            placeholder=""
            invalid={errorField === "firstName"}
            setChangeHandler={setfirstName}
            value={firstName}
            type="text"
            onChangeAction={clearValidation}
          />

          <FormField
            icon={<CiUser color="#737373" size="24px" />}
            label="Last Name"
            placeholder=""
            invalid={errorField === "lastName"}
            setChangeHandler={setlastName}
            value={lastName}
            type="text"
            onChangeAction={clearValidation}
          />

          <Box>
            <FormField
              icon={<CiLock color="#737373" size="24px" />}
              label="Password"
              placeholder=""
              setChangeHandler={setpassword}
              value={password}
              type="password"
              invalid={errorField === "password"}
              preventSpaces={true}
              onChangeAction={clearValidation}
            />
            {password !== "" &&
              (password.length < 8 ||
                !/[A-Z]/.test(password) ||
                !/[a-z]/.test(password) ||
                !/\d/.test(password) ||
                !/[^\w\s\_]/.test(password)) && (
                <Box mt="0.75em" bgColor="white">
                  <InputHints
                    showWhen={password !== ""}
                    title="Your password must be"
                    hints={[
                      {
                        passed: password.length > 7 ? true : false,
                        text: "at least 8 characters long",
                      },
                      {
                        passed: /[A-Z]/.test(password) ? true : false,
                        text: "at least one uppercase letter",
                      },
                      {
                        passed: /[a-z]/.test(password) ? true : false,
                        text: "at least one lowercase letter",
                      },
                      {
                        passed: /\d/.test(password) ? true : false,
                        text: "at least one number",
                      },
                      {
                        passed: /[^\w\s\_]/.test(password) ? true : false,
                        text: "at least one special character e.g. @#$%^&*()",
                      },
                    ]}
                  />
                </Box>
              )}
          </Box>

          <Box>
            <FormField
              icon={<CiLock color="#737373" size="24px" />}
              label="Confirm Password"
              placeholder=""
              setChangeHandler={setconfirmPassword}
              value={confirmPassword}
              invalid={confirmPassword !== "" && password !== confirmPassword}
              type="password"
              preventSpaces={true}
              onChangeAction={clearValidation}
            />
            {confirmPassword !== "" && password !== confirmPassword && (
              <Box mt="0.75em" bgColor="white">
                <InputHints
                  showWhen={confirmPassword !== ""}
                  title="Passwords do not match"
                  hints={[
                    {
                      passed: password === confirmPassword ? true : false,
                      text: "Your passwords must match",
                    },
                  ]}
                />
              </Box>
            )}
          </Box>
        </Stack>

        <Box>
          <Checkbox
            display="flex"
            alignItems="start"
            colorScheme="purple"
            isChecked={agreedToTerms}
            isInvalid={errorField === "terms"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setagreedToTerms(!agreedToTerms)
            }
          >
            <Text
              fontSize="14px"
              lineHeight="24px"
              color="#737373"
              mt="-5x"
              fontWeight="400"
            >
              I have read and accepted meetricks’s{" "}
              <a
                style={{ color: "#7211D4" }}
                href="https://www.metricks.io/termsandconditions"
                target="_blank"
              >
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a
                href="https://www.metricks.io/PrivacyPolicy"
                target="_blank"
                style={{ color: "#7211D4" }}
              >
                Privacy Policy
              </a>{" "}
              and hereby agree to receive occasional communication with product
              news and relevant educational content.
            </Text>
          </Checkbox>

          <Button
            color="#FFF"
            bgColor="#7211D4"
            w="100%"
            mt="60px"
            isLoading={creatingNewManager}
            onClick={createNewManager}
          >
            Submit
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default ManagerSignup;
