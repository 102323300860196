import { Box, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import React from "react";

type TabInfoType = {
  title: string;
  val: any;
  type: string;
};

const TabInfo = ({ title, val, type }: TabInfoType) => {
  return (
    <Grid
      pt={{ base: "14px", md: "17px" }}
      mb={{ base: "17px", md: "23px" }}
      templateColumns={{ base: "1fr", md: "40% 60%" }}
      css={{ borderTop: "0.5px solid rgba(50, 50, 93, 0.3)" }}
    >
      <GridItem>
        <Text
          fontSize="14px"
          color="rgba(50, 50, 93, 0.6)"
          fontWeight="500"
          lineHeight="21px"
        >
          {title}
        </Text>
      </GridItem>

      <GridItem>
        <Box
          display="flex"
          alignItems="center"
          borderRadius="8px"
          w="100%"
          padding={{ base: "0px", md: "12px 34px" }}
          mt={{ base: "8px", md: "0px" }}
          border={{ base: "0px solid #7211D4", md: "0.6px solid #7211D4" }}
          flexWrap={"wrap"}
          gap={3}
        >
          {type === "string" ? (
            <Text
              fontSize="14px"
              fontWeight="500"
              lineHeight="21px"
              color="#32325D"
            >
              {val}
            </Text>
          ) : type === "socials" ? (
            Object.entries(val).map(
              ([item, val]) =>
                item !== "" && (
                  <Box mr="14px" p="2px 10px" bgColor="#F0F5FF">
                    <Text
                      fontSize="12px"
                      lineHeight="21px"
                      fontWeight="500"
                      color="#32325D"
                    >
                      {`${item} @${val}`}
                    </Text>
                  </Box>
                )
            )
          ) : (
            val?.map((item: string) => (
              <Flex mr="14px" p="2px 10px" bgColor="#F0F5FF">
                <Text
                  fontSize="12px"
                  lineHeight="21px"
                  fontWeight="500"
                  color="#32325D"
                >
                  {item == "saas" ? "SaaS" : item}
                </Text>
              </Flex>
            ))
          )}
        </Box>
      </GridItem>
    </Grid>
  );
};

export default TabInfo;
