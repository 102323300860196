import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import "firebase/compat/auth";
import "firebase/compat/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCW4zOIRbMwHgF8-RiL_JjFxGikd0SGUqo",
  authDomain: "notification-app-4ff0a.firebaseapp.com",
  projectId: "notification-app-4ff0a",
  storageBucket: "notification-app-4ff0a.appspot.com",
  messagingSenderId: "489616921292",
  appId: "1:489616921292:web:eb2cfd6f459f3972ea2869",
  measurementId: "G-F4RTDN5L4E",
};

firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
