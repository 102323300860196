import { Box, Flex, Grid, GridItem, Link, Text } from "@chakra-ui/react";
import React from "react";

type AffiliatePropertyProp = {
  label: string;
  fieldData: any;
  type: string;
};

const AffiliateProperty = ({
  label,
  fieldData,
  type,
}: AffiliatePropertyProp) => {
  return (
    <Grid
      templateColumns={{ base: "1fr", md: "40% 60%" }}
      maxWidth="5xl"
      py="23px"
    >
      <GridItem>
        <Text
          fontSize="14px"
          fontWeight="400"
          lineHeight="21px"
          color="rgba(50, 50, 93, 1)"
        >
          {label}
        </Text>
      </GridItem>

      <GridItem>
        <Box p="14px 30px" borderRadius="8px" border="0.6px solid #7211D4">
          {type === "string" ? (
            <Text
              fontSize="14px"
              fontWeight="400"
              lineHeight="21px"
              color="rgba(50, 50, 93, 1)"
            >
              {fieldData}
            </Text>
          ) : type === "object" ? (
            <Flex flexWrap="wrap" gap="10px">
              {Object.keys(fieldData).map((social: string) => (
                <Flex>
                  <Link
                    href={fieldData[social]}
                    target="_blank"
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="21px"
                    p="7px 8px"
                    bgColor="#F0F5FF"
                    color="rgba(50, 50, 93, 1)"
                    isExternal={true}
                    _hover={{ textDecoration: "none" }}
                  >
                    {social}
                  </Link>
                </Flex>
              ))}
            </Flex>
          ) : (
            <Flex flexWrap="wrap" gap="10px">
              {fieldData.map((item: string) => (
                <Text
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="21px"
                  borderRadius="8px"
                  p="7px 8px"
                  bgColor="#F0F5FF"
                  color="rgba(50, 50, 93, 1)"
                >
                  {item}
                </Text>
              ))}
            </Flex>
          )}
        </Box>
      </GridItem>
    </Grid>
  );
};

export default AffiliateProperty;
