import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { StepWrapper } from "./StepWrapper";
import { MdCancel } from "react-icons/md";
import { useContext, useEffect, useRef, useState } from "react";
import {
  IoArrowBackSharp,
  IoArrowForwardSharp,
  IoChevronDown,
  IoChevronUp,
} from "react-icons/io5";
import { BsCheckSquareFill, BsFillCaretUpFill, BsSearch } from "react-icons/bs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseButton } from "../button";
import { useCookies } from "react-cookie";
import { toast, ToastContainer } from "react-toastify";
import {
  getCurrentUserInstance,
  setCurrentUserType,
  setLocalUserInstance,
} from "../../state/localstorage";
import { industries } from "../../utils/niche";
import { useOutsideAlerter } from "../../hooks/useOutsideAlerter";
import { TemporaryNavData } from "../context/TemporaryNavData";
import { FaStarOfLife } from "react-icons/fa";
import { RiArrowRightFill } from "react-icons/ri";

export const Niche = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { shopify, setShopify } = useContext(TemporaryNavData);
  console.log("testing shopify", shopify);
  const [orgType, setOrgType] = useState(false);
  useEffect(() => {
    if (searchParams.get("type") !== null) {
      setOrgType(searchParams.get("type") ? true : false);
    }
  }, [searchParams, orgType]);
  const [cookies] = useCookies(["user_id", "access_token"]);

  const localUser = getCurrentUserInstance();
  console.log("im from niche", localUser);
  // localUser && console.log(localUser);

  const [searchQuery, setSearchQuery] = useState("");
  const [showIndustries, setShowIndustries] = useState(false);
  const [nicheData, setNicheData] = useState(industries);

  const toggleHandler = () => {
    return setShowIndustries(!showIndustries);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setShowIndustries(false));

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [saveAndExit, setSaveAndExit] = useState(false);
  const submitHandler = () => {
    if (selectedItem.length < 1) {
      toast.warning("Please complete all required fields or save and exit");
      return;
    }

    const payload = {
      niche: selectedItem.map((data: any) => data.niche),
    };

    setLoading(true);
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `${shopify ? "auth/signup/step5?shopify=true" : "auth/signup/step5"}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.access_token}`,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // navigate("/dashboard");
        console.log("niche data", data);
        setLoading(false);
        // console.log("step5", data);
        if (data.success) {
          setLocalUserInstance({ ...localUser, nicheData: selectedItem });
          setCurrentUserType("advertiser");
          const user = getCurrentUserInstance();
          user.organisation.signup_step = shopify ? 6 : 5;
          setLocalUserInstance(user);
          if (orgType) {
            saveAndExit
              ? window.open("https://www.metricks.io", "_self")
              : navigate(
                  `${
                    shopify
                      ? "/dashboard"
                      : "/signup/billing?type=newOrganization"
                  }`
                );
          } else {
            saveAndExit
              ? window.open("https://www.metricks.io", "_self")
              : navigate(`${shopify ? "/dashboard" : "/signup/billing"}`);
          }
        }
        console.log(data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const step = [
    {
      title: "User Profile",
      status: "passed",
      key: 1,
    },
    {
      title: "Company Profile",
      status: "passed",
      key: 2,
    },

    {
      title: "Marketing Assets",
      status: "passed",
      key: 3,
    },
    {
      title: "Product Details",
      status: "passed",
      key: 4,
    },
    {
      title: "Niche",
      status: "current",
      key: 5,
    },
    {
      title: "Billing",
      status: "default",
      key: 6,
    },
  ];
  const orgSteps = [
    {
      title: "Company Profile",
      status: "passed",
      key: 1,
    },

    {
      title: "Marketing Assets",
      status: "passed",
      key: 2,
    },
    {
      title: "Product Details",
      status: "passed",
      key: 3,
    },
    {
      title: "Niche",
      status: "current",
      key: 4,
    },
    {
      title: "Billing",
      status: "default",
      key: 5,
    },
  ];
  const steps = orgType ? orgSteps : step;
  const [selectedItem, selectedItems]: any = useState(
    localUser.nicheData || []
  );
  const handleSelect = (item: any) => {
    console.log("selectedCountry", selectedItem), item;
    // const isSelected = selectedItem.includes(item);
    const isSelected = selectedItem.some(
      (selectedItemData: any) => selectedItemData.niche === item.niche
    );
    // setSelectAll(false);
    // console.log(isSelected);
    if (isSelected) {
      // If item is already selected, remove it from the selectedItems array
      selectedItems((prevSelectedItems: any) =>
        prevSelectedItems.filter(
          (selectedItem: any) => selectedItem.niche !== item.niche
        )
      );
    } else {
      // If item is not selected, add it to the selectedItems array
      selectedItems((prevSelectedItems: any) => [...prevSelectedItems, item]);
    }
  };
  return (
    <StepWrapper
      step={steps.map((item: any) => {
        if (item.status === "current") {
          let step = `STEP ${item.key} OF ${steps.length}`;
          return step;
        }
      })}
      header="Setting up your advertiser account"
      baseText="Niche"
      stepsMap={steps}
    >
      <Flex
        position={"relative"}
        direction={"column"}
        alignItems={"center"}
        w={["90%", "60%"]}
        // backgroundColor={"red"}
      >
        <Flex w={"100%"} direction={"column"}>
          <ToastContainer />
          <Box>
            <Heading fontSize="1.2em" mb="1em" display={"flex"} gap={1}>
              What industy are you targeting?
              <FaStarOfLife fontSize={"6px"} color="red" />
            </Heading>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid #B6BDC3"
              pb=".3em"
              mb="1em"
              cursor="pointer"
              onClick={() => toggleHandler()}
              ref={wrapperRef}
            >
              <Flex columnGap=".5em" rowGap=".5em" flexWrap="wrap">
                {selectedItem.length >= 1 ? (
                  selectedItem.map((singleNiche: any, i: any) => {
                    return (
                      <Box
                        key={i}
                        bgColor="#F0F5FF"
                        py={["3px", "3px", "6px"]}
                        px=".4em"
                        alignItems="center"
                        display="flex"
                        borderRadius="4px"
                      >
                        <Text
                          mr=".8em"
                          fontSize="14px"
                          fontWeight="400"
                          color="gray.800"
                        >
                          {singleNiche.niche}
                        </Text>
                        <MdCancel
                          color="#000"
                          cursor="pointer"
                          onClick={() => {
                            // const newState = nicheData.map(
                            //   (data: any, x: any) => {
                            //     if (nicheData[i] == data) {
                            //       return { ...data, selected: false };
                            //     } else {
                            //       return data;
                            //     }
                            //   }
                            // );
                            // setNicheData(newState);
                            handleSelect(singleNiche);
                          }}
                        />
                      </Box>
                    );
                  })
                ) : (
                  <Text>Select niche</Text>
                )}
              </Flex>

              <Box>
                {!showIndustries ? (
                  <IoChevronDown
                    cursor="pointer"
                    fontSize="20px"
                    // onClick={toggleHandler}
                  />
                ) : (
                  <IoChevronUp
                    cursor="pointer"
                    fontSize="20px"
                    // onClick={toggleHandler}
                  />
                )}
              </Box>
            </Box>

            {showIndustries && (
              <Box
                p={["1em", "1em", "1.5em"]}
                my="1em"
                boxShadow="xl"
                rounded="xl"
                height="60vh"
                overflowY="scroll"
                ref={wrapperRef}
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  my=".2em"
                >
                  <Text fontSize="14px" color="gray.400" fontWeight="400">
                    Search Niche
                  </Text>

                  <BsFillCaretUpFill
                    color="#CACBD4"
                    cursor="pointer"
                    onClick={toggleHandler}
                  />
                </Flex>

                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<BsSearch color="gray.300" />}
                  />
                  <Input
                    type="text"
                    _focus={{ ring: "0" }}
                    border="0"
                    bgColor="#F4F6F8"
                    value={searchQuery}
                    onChange={(e: React.ChangeEvent<any>) =>
                      setSearchQuery(e.target.value)
                    }
                  />
                </InputGroup>
                {/* {nicheData.length <= 0 && <Text>No Data found</Text>} */}
                {nicheData
                  .filter((singleNiche: any) =>
                    singleNiche.niche
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  )
                  .map((singleNiche: any, i: any) => {
                    return (
                      <Box
                        key={i}
                        display={"flex"}
                        justifyContent="space-between"
                        alignItems="center"
                        my="1em"
                        cursor="pointer"
                        _hover={{ bgColor: "gray.50" }}
                        onClick={() => {
                          // const newState = nicheData.map((data: any, x: any) => {
                          //   if (nicheData[i] == data) {
                          //     return { ...data, selected: !data.selected };
                          //   } else {
                          //     return data;
                          //   }
                          // });
                          // setNicheData(newState);
                          handleSelect(singleNiche);
                        }}
                        p=".5em"
                        borderRadius="3px"
                      >
                        <Text>{singleNiche.niche}</Text>

                        {selectedItem.some(
                          (selectedItemData: any) =>
                            selectedItemData.niche === singleNiche.niche
                        ) ? (
                          <BsCheckSquareFill color="#5C15CE" />
                        ) : (
                          <Box
                            width="1em"
                            height="1em"
                            border="1.5px solid gray"
                            borderRadius="4px"
                          ></Box>
                        )}

                        {/* <Checkbox
                      onChange={(e) => {
                        if (e.target.checked) {
                          const newState = nicheData.map(
                            (data: any, x: any) => {
                              if (nicheData[i] == data) {
                                return { ...data, selected: true };
                              } else {
                                return data;
                              }
                            }
                          );
                          setNicheData(newState);
                        } else {
                          const newState = nicheData.map(
                            (data: any, x: any) => {
                              if (nicheData[i] == data) {
                                return { ...data, selected: false };
                              } else {
                                return data;
                              }
                            }
                          );
                          setNicheData(newState);
                        }
                      }}
                      mr=".2em"
                      defaultChecked={singleNiche.selected}
                      colorScheme="purple"
                    >
                      {""}
                    </Checkbox> */}
                      </Box>
                    );
                  })}
                {nicheData.length > 0 &&
                  nicheData.filter(
                    (countryData: any) =>
                      !countryData.niche
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                  ).length === nicheData.length && (
                    <Text fontSize="14px" color="gray.500" mt="1em">
                      No data found
                    </Text>
                  )}
              </Box>
            )}
          </Box>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            mb="1em"
            mt={{ base: "30em", lg: showIndustries ? "3em" : "55vh" }}
            flexDirection={{ base: "column-reverse", lg: "row" }}
            rowGap=".6em"
          >
            <Button
              px="0"
              color="#5C14CE"
              bgColor="#fff"
              fontWeight="500"
              fontSize="14px"
              width={{ md: "40%" }}
              _hover={{ bgColor: "#fff" }}
              _focus={{ bgColor: "#fff" }}
              onClick={() => navigate("/signup/product-details")}
              leftIcon={<IoArrowBackSharp />}
            >
              Back to the previous step
            </Button>
            <Box
              width={{ base: "100%", lg: "40%" }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection={["column-reverse", "row"]}
              rowGap=".6em"
              justifySelf="flex-end"
            >
              <Box mr={["0em", "1em"]} width={["100%", "60%", "50%"]}>
                {/* <BaseButton
                text="Skip"
                textColor="#5C14CE"
                bgColor="#F0F5FF"
                clickEventHandler={() => {
                  //   setSaveAndExit(true);
                  //   submitHandler();
                  //   window.open("https://metricks.io", "_self");
                  navigate("/signup/billing");
                }}
              /> */}
              </Box>

              <Box width={["100%", "60%", "60%"]}>
                <BaseButton
                  text="Next step"
                  textColor="#fff"
                  bgColor="#5C14CE"
                  loadingState={loading}
                  rightIcon={<RiArrowRightFill />}
                  clickEventHandler={submitHandler}
                />
              </Box>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </StepWrapper>
  );
};
