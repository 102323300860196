import { Box, Flex, Button, Text } from "@chakra-ui/react";

export const CampaignHeader = ({ currentStep }: { currentStep: number }) => {
  return (
    <Box pb="20px" top="70px" bgColor="#F1F4F9" zIndex={10}>
      <Box
        position={"sticky"}
        mb={"20px"}
        w={"100%"}
        bgColor={"#fff"}
        // h={"30px"}

        py={"7px"}
        pl={"30px"}
      >
        <Text fontWeight={"500"} fontSize={13}>
          Create New Campaign
        </Text>
      </Box>
      <Flex
        bgColor="#8247e232"
        borderRadius="5px"
        justifyContent="space-between"
        display={{ base: "none", md: "flex" }}
        mx={"22px"}
      >
        {[1, 2, 3, 4].map((step) => (
          <Button
            bgColor={currentStep === step ? "white" : "transparent"}
            color={currentStep === step ? "#5C14CE" : "gray.600"}
            fontSize="14px"
            fontWeight="500"
            px={["3em", "2em"]}
            py=".2em"
          >
            {`STEP ${step}`}
          </Button>
        ))}
      </Flex>
      <Flex
        justifyContent="space-between"
        display={{ base: "flex", md: "none" }}
        mx={"22px"}
      >
        {[1, 2, 3, 4].map((step: number) => (
          <>
            <Text
              border="1px solid"
              borderColor="gray.300"
              color="#33325D"
              fontWeight="medium"
              py=".5em"
              px="1em"
              borderRadius="full"
              bgColor={currentStep === step ? "#DDD2F6" : "transparent"}
            >
              {step}
            </Text>
            {step !== 4 && (
              <Box
                width="1em"
                height="2px"
                bgColor="gray.300"
                mx=".4em"
                marginTop="1.2em"
              ></Box>
            )}
          </>
        ))}
      </Flex>
    </Box>
  );
};
