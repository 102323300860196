import React from "react";
import { Route, Routes } from "react-router-dom";
import Reset from "../components/reset-password";
import SendToken from "../components/reset-password/sendToken";
import CreatePassword from "../components/reset-password/create-password";
import PasswordSuccess from "../components/reset-password/success";
const Forgot = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Reset />} />
        <Route path="/token" element={<SendToken />} />
        <Route path="/create-password" element={<CreatePassword />} />
        <Route path="/success" element={<PasswordSuccess />} />
      </Routes>
    </>
  );
};

export default Forgot;
