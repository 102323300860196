import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { endpoints } from "../../../utils/endpoints";
import { getCookie } from "cookies-next";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Stack,
  Spinner,
  Image,
  Circle,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { MdDelete, MdKeyboardArrowRight } from "react-icons/md";
import { SlOptions } from "react-icons/sl";
import Iframe from "react-iframe";
import { BsFiletypeDoc, BsPlusCircle } from "react-icons/bs";
import { apiReqHandler } from "../../../helper/apiReqHandler";
import { AiFillFile, AiFillFileWord } from "react-icons/ai";
import { FaFilePdf } from "react-icons/fa";
import { FiUploadCloud } from "react-icons/fi";
import { HiOutlineCloudArrowUp } from "react-icons/hi2";
import { IoArrowForwardSharp } from "react-icons/io5";

export const Asset = () => {
  const [searchParams] = useSearchParams();
  const productType = searchParams.get("product");
  const campaignId = searchParams.get("campaign");
  const [assets, setAssets] = useState([]);
  const [editPage] = useState(
    window.location.pathname.includes("/edit-campaign")
  );
  const folderData = {
    folderName: searchParams.get("folderName"),
    folderId: searchParams.get("folderId"),
    slug: searchParams.get("folderSlug"),
  };
  const navigate = useNavigate();

  const [uploading, setUploading] = useState(false);
  const [selected, setSeleceted] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const submitHandler = async (files: any) => {
    const formData = new FormData();
    console.log("files", files);
    // @ts-ignore
    formData.append("folder_id", folderData.folderId);

    // @ts-ignore
    for (var i = 0; i < files.length; i++) {
      // @ts-ignore
      var file = files[i];
      // @ts-ignore
      formData.append("assets", file, file.name); // 'file' + i is just an
    }

    // @ts-ignore
    formData.append("name", "fileasst");

    setUploading(true);
    try {
      const response = await fetch(
        endpoints.drive.NEWFOLDER.UPLOAD(campaignId),
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${getCookie("access_token")}`,
          },
          body: formData,
        }
      );
      console.log(response.body);
      setUploading(false);
    } catch (e) {
      console.log(e);
      setUploading(false);
    }

    setUploading(false);
  };
  useEffect(() => {
    fetchAssets();
  }, [uploading]);

  const fetchAssets = async () => {
    const { error, res } = await apiReqHandler({
      endPoint: endpoints.drive.NEWFOLDER.FOLDERASSETS(
        campaignId,
        folderData.folderId
      ),
      method: "GET",
      //   @ts-ignore
      bearerAuth: getCookie("access_token"),
    });
    setAssets(res?.data.data.folder.folderAssets || []);
    console.log(res?.data.data.folder.folderAssets);
  };

  const deleteAsset = async (id: any) => {
    setUploading(true);
    const payload = {
      asset_id: id,
    };
    const { error, res } = await apiReqHandler({
      endPoint: endpoints.drive.NEWFOLDER.DELETE(campaignId),
      method: "DELETE",
      //   @ts-ignore
      bearerAuth: getCookie("access_token"),
      payload,
    });
    console.log(res, error, id);
    await fetchAssets();
    setUploading(false);
  };

  //   @ts-ignore
  // const folderSplit = folderData?.folderUrl.split("/");
  //   @ts-ignore
  // const folderId = folderSplit[folderSplit.length - 1];

  const closeHandler = () => {
    navigate(
      editPage
        ? `/campaigns/edit-campaign/marketing-assets?product=${productType}&campaign=${campaignId}`
        : `/campaigns/marketing-assets?product=${productType}&campaign=${campaignId}`
    );
  };

  function endsWithExtension(url: any, extension: any) {
    return url.toLowerCase().endsWith(extension);
  }
  return (
    <>
      <Box m="22px">
        <Flex
          alignItems="center"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
          mb="1em"
        >
          <Box
            display="flex"
            columnGap=".3em"
            alignItems="center"
            justifyContent={{ base: "flex-start" }}
            w={{ base: "full" }}
          >
            <Heading
              fontSize=".9em"
              fontWeight="500"
              color="#32325D"
              onClick={closeHandler}
              cursor="pointer"
            >
              All folders
            </Heading>

            <MdKeyboardArrowRight color="#32325D" />
            <Heading fontSize=".9em" color="gray.400" fontWeight="500">
              {folderData?.folderName && folderData?.folderName?.length > 11
                ? folderData?.folderName?.slice(0, 11).trim() + "..."
                : folderData?.folderName}
            </Heading>
          </Box>
          <Box
            width="100%"
            height="1px"
            mt=".5em"
            mb=".4em"
            bgColor="gray.300"
            display={{ base: "block", md: "none" }}
          ></Box>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            flexDir={{ base: "row-reverse", md: "row" }}
            columnGap=".8em"
            w={{ base: "full", lg: "50%", xl: "40%", xxl: "30%" }}
            mt={{ base: ".5em", md: "0em" }}
          >
            <Button
              bgColor="#fff"
              color="#000"
              fontWeight="medium"
              fontSize="14px"
              px="1em"
              height="42px"
              visibility={"hidden"}
              disabled={true}
              //   @ts-ignore
              onClick={() => window.open(folderData?.folderUrl)}
            >
              View in Google Drive
            </Button>

            <form
              encType="multipart/form-data"
              onSubmit={submitHandler}
              style={{ width: "40%" }}
            >
              <label htmlFor="asset">
                <input
                  type="file"
                  //   accept="image/png, image/jpeg"
                  id="asset"
                  multiple
                  style={{ display: "none" }}
                  onChange={(e: React.ChangeEvent<any>) => {
                    console.log("check me", e.target.files);
                    submitHandler(e.target.files);
                  }}
                />

                <Flex
                  cursor="pointer"
                  bgColor="#F2E5FF"
                  color="gray.700"
                  fontWeight="medium"
                  fontSize="14px"
                  px="1em"
                  display="flex"
                  alignItems="center"
                  py=".7em"
                  borderRadius=".4em"
                  w="100%"
                  justifyContent="center"
                >
                  <BsPlusCircle
                    color="#5C14CE"
                    fontSize="1em"
                    fontWeight="medium"
                  />

                  <Text ml=".5em" fontWeight="medium">
                    {uploading ? "Uploading..." : "Upload"}
                  </Text>
                </Flex>
              </label>
            </form>
          </Flex>
        </Flex>

        <Stack
          bgColor="#fff"
          padding="1.3em"
          borderRadius=".4em"
          minHeight="75vh"
        >
          {uploading ? (
            <Spinner
              size="xl"
              color="purple.500"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
            />
          ) : assets.length == 0 ? (
            <Flex
              w={"100%"}
              justify={"center"}
              h={["", "600px"]}
              align={"center"}
            >
              <Flex
                w={["100%", "40%"]}
                // borderColor={"#c4c4c4"}
                p={10}
                // borderWidth={1}
                direction={"column"}
                align={"center"}
                gap={5}
              >
                <Flex
                  w={"100%"}
                  justify={"center"}
                  align={"center"}
                  direction={"column"}
                >
                  <HiOutlineCloudArrowUp size={"120px"} color="#7211d4" />
                  <Text fontWeight={"500"} fontSize={15} textAlign={"center"}>
                    Start by uploading a file
                  </Text>
                  <Text
                    color={"#c4c4c4"}
                    fontWeight={"400"}
                    textAlign={"center"}
                  >
                    All assets uploaded here will be accessible by your approved
                    affiliates
                  </Text>
                </Flex>

                <form
                  encType="multipart/form-data"
                  onSubmit={submitHandler}
                  style={{ width: "40%" }}
                >
                  <label htmlFor="asset">
                    <input
                      type="file"
                      //   accept="image/png, image/jpeg"
                      multiple={true}
                      id="asset"
                      style={{ display: "none" }}
                      onChange={(e: React.ChangeEvent<any>) => {
                        console.log("check me", e.target.files);
                        submitHandler(e.target.files);
                      }}
                    />
                    {/* <input type="file" multiple onChange={

                      ()=>{
                        console.log("check me2", e.target.files);
                      }
                     } /> */}

                    <Flex
                      cursor="pointer"
                      bgColor="#F2E5FF"
                      color="gray.700"
                      fontWeight="medium"
                      fontSize="14px"
                      px="1em"
                      display="flex"
                      alignItems="center"
                      py=".7em"
                      borderRadius=".4em"
                      w="100%"
                      justifyContent="center"
                    >
                      <FiUploadCloud size={"20px"} color="#5C14CE" />

                      <Text ml=".5em" fontWeight="medium">
                        {uploading ? "Uploading..." : "Upload"}
                      </Text>
                    </Flex>
                  </label>
                </form>
              </Flex>
            </Flex>
          ) : (
            <Flex direction={"row"} w={"100%"} gap={5} flexWrap={"wrap"}>
              {/* @ts-ignore */}
              {assets.map((item, i) => (
                <Flex
                  key={i}
                  width={300}
                  borderWidth={1}
                  borderColor={"#c4c4c4c4"}
                  borderRadius={10}
                  align={"center"}
                  justify={"center"}
                  py={5}
                  direction={"column"}
                  gap={5}
                >
                  <Flex
                    width={260}
                    // borderWidth={1}
                    // borderColor={"#c4c4c4c4"}
                    borderRadius={10}
                    align={"center"}
                    justify={"center"}
                    h={120}
                    overflow={"hidden"}
                    cursor={"pointer"}
                  >
                    {/* @ts-ignore */}
                    {endsWithExtension(item.url, "png") ? (
                      <Image
                        // @ts-ignore
                        src={item.url}
                        w={"auto"}
                        objectFit={"cover"}
                        // @ts-ignore
                        onClick={() => window.open(item.url, "_blank")}
                      />
                    ) : //  @ts-ignore
                    endsWithExtension(item.url, "document") ? (
                      <Image
                        // size={100}
                        src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/22d49350-acc9-431b-a292-f5e8244d3958.svg"
                        width="120px"
                        height="120px"
                        // color="#5C14CE"
                        // @ts-ignore
                        onClick={() => window.open(item.url, "_blank")}
                        cursor={"pointer"}
                      />
                    ) : //  @ts-ignore
                    endsWithExtension(item.url, "pdf") ? (
                      <Image
                        // size={100}
                        src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/c8214bd5-91c3-41ce-a568-facfb582443a.svg"
                        width="120px"
                        height="120px"
                        // color="#5C14CE"
                        // @ts-ignore
                        onClick={() => window.open(item.url, "_blank")}
                        cursor={"pointer"}
                      />
                    ) : // @ts-ignore
                    endsWithExtension(item.url, "txt") ? (
                      <Image
                        // size={100}
                        src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/4deb27b1-f5dc-4994-ab89-ae52221934fe.svg"
                        width="120px"
                        height="120px"
                        // color="#5C14CE"
                        // @ts-ignore
                        onClick={() => window.open(item.url, "_blank")}
                        cursor={"pointer"}
                      />
                    ) : // @ts-ignore
                    endsWithExtension(item.url, "ppt") ? (
                      <Image
                        // size={100}
                        src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/33036f58-f7eb-4a4d-a086-281e96d2ebe4.svg"
                        width="120px"
                        height="120px"
                        // color="#5C14CE"
                        // @ts-ignore
                        onClick={() => window.open(item.url, "_blank")}
                        cursor={"pointer"}
                      />
                    ) : // @ts-ignore
                    endsWithExtension(item.url, "zip") ? (
                      <Image
                        // size={100}
                        src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/31facd62-cabb-49ea-9bcd-4d80d6576e3b.svg"
                        width="120px"
                        height="120px"
                        // color="#5C14CE"
                        // @ts-ignore
                        onClick={() => window.open(item.url, "_blank")}
                        cursor={"pointer"}
                      />
                    ) : (
                      <AiFillFile
                        size={100}
                        color="#5C14CE"
                        // @ts-ignore
                        onClick={() => window.open(item.url, "_blank")}
                        cursor={"pointer"}
                      />
                    )}
                  </Flex>
                  <Flex width={260} direction={"column"}>
                    <Flex gap={2} align={"baseline"} mb={3}>
                      {/* @ts-ignore */}
                      {endsWithExtension(item.url, "png") ? (
                        <BsFiletypeDoc
                          size={14}
                          color="#5C14CE"
                          fontWeight={"bold"}
                        />
                      ) : //  @ts-ignore
                      endsWithExtension(item.url, "document") ? (
                        <Image
                          // size={100}
                          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/22d49350-acc9-431b-a292-f5e8244d3958.svg"
                          width="17px"
                          height="17px"
                          // color="#5C14CE"
                          // @ts-ignore
                          onClick={() => window.open(item.url, "_blank")}
                          cursor={"pointer"}
                        />
                      ) : //  @ts-ignore
                      endsWithExtension(item.url, "pdf") ? (
                        <Image
                          // size={100}
                          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/c8214bd5-91c3-41ce-a568-facfb582443a.svg"
                          width="17px"
                          height="17px"
                          // color="#5C14CE"
                          // @ts-ignore
                          onClick={() => window.open(item.url, "_blank")}
                          cursor={"pointer"}
                        />
                      ) : // @ts-ignore
                      endsWithExtension(item.url, "txt") ? (
                        <Image
                          // size={100}
                          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/4deb27b1-f5dc-4994-ab89-ae52221934fe.svg"
                          width="17px"
                          height="17px"
                          // color="#5C14CE"
                          // @ts-ignore
                          onClick={() => window.open(item.url, "_blank")}
                          cursor={"pointer"}
                        />
                      ) : // @ts-ignore
                      endsWithExtension(item.url, "ppt") ? (
                        <Image
                          // size={100}
                          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/33036f58-f7eb-4a4d-a086-281e96d2ebe4.svg"
                          width="17px"
                          height="17px"
                          // color="#5C14CE"
                          // @ts-ignore
                          onClick={() => window.open(item.url, "_blank")}
                          cursor={"pointer"}
                        />
                      ) : // @ts-ignore
                      endsWithExtension(item.url, "zip") ? (
                        <Image
                          // size={100}
                          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/31facd62-cabb-49ea-9bcd-4d80d6576e3b.svg"
                          width="17px"
                          height="17px"
                          // color="#5C14CE"
                          // @ts-ignore
                          onClick={() => window.open(item.url, "_blank")}
                          cursor={"pointer"}
                        />
                      ) : (
                        <AiFillFile
                          size={14}
                          color="#5C14CE"
                          // @ts-ignore
                          onClick={() => window.open(item.url, "_blank")}
                          cursor={"pointer"}
                        />
                      )}

                      <Text fontWeight={"bold"} fontSize={17}>
                        {/* @ts-ignore */}
                        {item.name.length > 17
                          ? // @ts-ignore
                            `${item.name.substring(0, 17)}...`
                          : // @ts-ignore
                            item.name}
                      </Text>
                    </Flex>
                    <Flex justify={"space-between"} align={"center"}>
                      <Flex gap={1} direction={"column"}>
                        <Text fontWeight={"bold"}>File size</Text>
                        {/* @ts-ignore */}
                        <Text>{item.filesize} mb</Text>
                      </Flex>
                      <Circle
                        borderWidth={1}
                        borderColor={"#c4c4c4c4"}
                        size={10}
                        position={"relative"}
                        onClick={() => {
                          onOpen();
                          // @ts-ignore
                          setSeleceted(item.url);
                        }}
                      >
                        <MdDelete
                          size={16}
                          color="#5C14CE"
                          onClick={() => {
                            onOpen();
                            // @ts-ignore
                            setSeleceted(item.url);
                          }}
                        />
                        <Modal isOpen={isOpen} onClose={onClose} size="xl">
                          <ModalOverlay />
                          <ModalContent
                            borderRadius="1em"
                            p=".8em"
                            height="235px"
                            mx={{ base: ".6em", md: "auto" }}
                            marginTop={{ base: "15em", md: "18em" }}
                          >
                            <Box
                              width=".8em"
                              height="235px"
                              position="absolute"
                              left="0"
                              bgGradient="linear(360deg, #7211D4 -1.38%, #FA9445 98.36%)"
                              top="0"
                              borderLeftRadius="1em"
                            ></Box>
                            {/* <ModalHeader mt="1em">Modal Title</ModalHeader> */}
                            <ModalCloseButton
                              bgColor="gray.100"
                              borderRadius="1.5em"
                              p="1.5em"
                              top="1.5em"
                              right="1.8em"
                              fontSize="1em"
                              onClick={onClose}
                            />

                            <ModalBody mt="2.5em" mr="1em">
                              <Text
                                fontSize={{ base: "14px", md: "1.3em" }}
                                fontWeight="medium"
                                mb={{ base: "1.6em", md: "2em" }}
                              >
                                Are you sure you want to delete this file?
                              </Text>
                              <Box
                                width="90%"
                                height="1px"
                                mx="auto"
                                bgColor="gray.200"
                                mb=".6em"
                              ></Box>
                            </ModalBody>

                            <ModalFooter>
                              {
                                <Button
                                  variant="ghost"
                                  mr={3}
                                  onClick={() => {
                                    onClose();
                                  }}
                                >
                                  Cancel
                                </Button>
                              }
                              <Button
                                bgColor="#F0F5FF"
                                color="#7211D4"
                                rightIcon={<IoArrowForwardSharp />}
                                // @ts-ignore
                                onClick={() => deleteAsset(item.id)}
                                // isLoading={activateLoader}
                              >
                                Delete
                              </Button>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>

                        {/* @ts-ignore */}
                      </Circle>
                    </Flex>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          )}
        </Stack>
      </Box>
    </>
  );
};
