import ExploreAffiliate from "../../components/affiliate/affiliate-explore";
import { Route, Routes } from "react-router-dom";
import ExploreCampagins from "../../components/affiliate/affiliate-explore/explore-campaigns";
import ViewCampaign from "../../components/affiliate/affiliate-explore/view-campaigns";

const AffiliateExplore = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ExploreAffiliate />} />
        <Route path="/view/:id" element={<ViewCampaign />} />
        <Route path="/explore" element={<ExploreCampagins />} />
      </Routes>
    </>
  );
};

export default AffiliateExplore;
