// @ts-ignore
import { getList } from "country-list-with-dial-code-and-flag";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import React, { ChangeEvent, useEffect, useState } from "react";
import { BaseButton } from "../../button";
import { StepWrapper } from "../StepWrapper";
import { IoArrowForwardSharp, IoArrowBackSharp } from "react-icons/io5";
import { FormInput } from "../../formInput";
import { getUrlQueryString } from "../../../services/urlQuery";
import { useCountries } from "../../../hooks/useCountries";
import { toast, ToastContainer } from "react-toastify";
import {
  AiFillInstagram,
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillLinkedin,
} from "react-icons/ai";

import { MdOutlineSocialDistance, MdCancel } from "react-icons/md";
import {
  getCurrentUserInstance,
  setCurrentUserType,
  setLocalUserInstance,
} from "../../../state/localstorage";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
export const AffiliateCompanyProfile = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [cookies] = useCookies([
    "user_id",
    "access_token",
    "aff_user",
    "aff_access_token",
  ]);
  const [, setCookie] = useCookies([
    "user",
    "user_id",
    "access_token",
    "aff_user",
    "aff_access_token",
  ]);
  const [orgType, setOrgType] = useState(false);
  const [userId, setUserId] = useState("");
  const [invalidPaypal, setinvalidPaypal] = useState(false);

  let localUser: { [key: string]: any } = {};

  if (searchParams.get("userId") !== null) {
  } else {
    localUser = getCurrentUserInstance();
  }

  useEffect(() => {
    //rechecking this bug
    // if (localUser?.organisation.signup_step == 6) {
    //   navigate("/dashboard");
    // }
    if (searchParams.get("userId") !== null) {
      setOrgType(searchParams.get("userId") ? true : false);
    }
    if (searchParams.get("userId") !== null) {
      setUserId(searchParams.get("userId")!);
    }
  }, [searchParams, orgType, userId]);
  localUser && console.log(localUser);

  const [allCountries] = useCountries();
  const [companyName, setCompanyName] = useState(
    localUser && localUser.organisation?.companyname
      ? localUser.organisation?.companyname
      : ""
  );
  const [country, setCountry] = useState(
    localUser && localUser.organisation?.country
      ? localUser.organisation?.country
      : getUrlQueryString("default-country")
      ? getUrlQueryString("default-country")
      : ""
  );
  const [address, setAddress] = useState(
    localUser && localUser.organisation?.address
      ? localUser.organisation?.address
      : ""
  );
  const [localState, setLocalState] = useState(
    localUser && localUser.organisation?.state
      ? localUser.organisation?.state
      : ""
  );
  const [city, setCity] = useState(
    localUser && localUser.organisation?.city
      ? localUser.organisation?.city
      : ""
  );
  const [zipCode, setZipCode] = useState(
    localUser && localUser.organisation?.zip_code
      ? localUser.organisation?.zip_code
      : ""
  );
  const [webURL, setWebURL] = useState(
    localUser && localUser.organisation?.website
      ? localUser.organisation?.website
      : ""
  );
  const [paypalAddress, setpaypalAddress] = useState(
    localUser && localUser.organisation?.paypal_address
      ? localUser.organisation?.paypal_address
      : ""
  );
  const [socialData, setSocialData] = useState({ title: "", url: "" });
  const [socials, setSocials]: [
    socials: { title: string; url: string }[],
    setSocials: React.Dispatch<React.SetStateAction<any>>
  ] = useState(
    localUser && localUser.socials
      ? localUser.socials
      : [{ title: "", url: "" }]
  );

  const [loading, setLoading] = useState(false);
  const [isCountryFocus, setisCountryFocus] = useState(false);
  const navigate = useNavigate();

  const addSocialHandler = () => {
    if (socialData.title === "" || socialData.url === "") {
      return;
    }

    setSocials((currentSocials: { title: string; url: string }[]) => [
      ...currentSocials.filter((socialData) => socialData.title !== ""),
      socialData,
    ]);

    setSocialData({ title: "", url: "" });
  };

  const urlPattern = new RegExp(
    // "^(https?:\\/\\/)?" + // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$", // validate fragment locator
    "i"
  );
  const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  const [saveAndExit, setSaveAndExit] = useState(false);
  const [invalidURL, setInValidURL] = useState(false);

  let affJoinCampaign = sessionStorage.getItem("campaignId");

  const submitHandler = (e: React.ChangeEvent<any>) => {
    e.preventDefault();

    if (webURL) {
      if (!urlPattern.test(webURL)) {
        setInValidURL(true);
        return;
      }

      if (urlPattern.test(webURL) && invalidURL) {
        setInValidURL(false);
      }
    }

    if (paypalAddress) {
      if (!emailPattern.test(paypalAddress)) {
        setinvalidPaypal(true);
        return;
      } else {
        setinvalidPaypal(false);
      }
    }

    setLoading(true);
    let optional: { [key: string]: any } = {};

    if (paypalAddress) {
      optional["paypal_address"] = paypalAddress;
    }

    const payload = {
      companyname: companyName,
      country,
      address,
      state:
        allCountries && allCountries[0].country !== "" && localState === ""
          ? allCountries.find(
              (singleCountry: { country: string; state: string[] }) =>
                singleCountry.country === country
            ).states[0]
          : localState,
      city,
      website: webURL,
      zip_code: zipCode,
      ...optional,
      socialHandles:
        socials.length >= 1
          ? socials.reduce(
              (acc, cur) => ({ ...acc, [cur.title.toLowerCase()]: cur.url }),
              {}
            )
          : {},
    };
    if (orgType) {
      const orgPayload = {
        companyname: companyName,
        country,
        address,
        state:
          allCountries && allCountries[0].country !== "" && localState === ""
            ? allCountries.find(
                (singleCountry: { country: string; state: string[] }) =>
                  singleCountry.country === country
              ).states[0]
            : localState,
        city,
        website: webURL,
        zip_code: zipCode,
        ...optional,
        userType: "affiliate",
        socialHandles:
          socials.length >= 1
            ? socials.reduce(
                (acc, cur) => ({
                  ...acc,
                  [cur.title.toLowerCase()]: cur.url,
                }),
                {}
              )
            : {},
      };
      fetch(
        process.env.REACT_APP_API_ENDPOINT + `organisation/create/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.aff_access_token}`,
          },
          body: JSON.stringify(orgPayload),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setCurrentUserType("affiliate");
            setLocalUserInstance({ ...data.data.user, socials });
            window.localStorage.setItem("organization", "yes");
            let expires = new Date();
            expires.setTime(expires.getTime() + 720 * 60 * 60 * 1000);
            window.localStorage.setItem("organization", "no");
            let userData = data.data.user;
            userData.onboarded = true;
            setCookie("aff_user", userData, {
              path: "/",
              expires,
            });
            setCookie("user_id", data.data.user.user_id, {
              path: "/",
              expires,
            });

            setCookie("aff_access_token", data.data.token, {
              path: "/",
              expires,
            });
            saveAndExit
              ? window.open("https://www.metricks.io", "_self")
              : navigate("/signup/affiliate-information?type=newOrganization");
          } else {
            toast.warning(data.message || "An error occured");
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      fetch(process.env.REACT_APP_API_ENDPOINT + "auth/signup/step2", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.aff_access_token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data.success) {
            // console.log(data.data);
            setLocalUserInstance(
              Object.keys(data.data)
                ? { ...localUser, ...data.data.user, socials }
                : { ...data.data.user, socials }
            );
            saveAndExit
              ? window.open("https://www.metricks.io", "_self")
              : navigate("/signup/affiliate-information");
          } else {
            toast.warning(data.message || "An error occured");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const step = [
    {
      title: "User Profile",
      status: "passed",
      key: 1,
    },
    {
      title: "Company Profile",
      status: "current",
      key: 2,
    },
    {
      title: "Affiliate Information",
      status: "default",
      key: 3,
    },
    {
      title: "Niche",
      status: "default",
      key: 4,
    },
    {
      title: "Affiliate Background",
      status: "default",
      key: 5,
    },
  ];
  const orgSteps = [
    {
      title: "Company Profile",
      status: "current",
      key: 1,
    },
    {
      title: "Affiliate Information",
      status: "default",
      key: 2,
    },
    {
      title: "Niche",
      status: "default",
      key: 3,
    },
    {
      title: "Affiliate Background",
      status: "default",
      key: 4,
    },
  ];
  const steps = orgType ? orgSteps : step;
  return (
    <StepWrapper
      step={steps.map((item: any) => {
        if (item.status === "current") {
          let step = `STEP ${item.key} OF ${steps.length}`;
          return step;
        }
      })}
      header="Setting up your affiliate account "
      baseText="Company profile"
      stepsMap={steps}
    >
      <Flex
        position={"relative"}
        direction={"column"}
        alignItems={"center"}
        w={["90%", "60%"]}
      >
        <ToastContainer />
        <Flex w={"100%"} direction={"column"}>
          <Box>
            <form onSubmit={submitHandler}>
              <FormInput
                label="Company's Name"
                type="text"
                value={companyName}
                stateHandler={setCompanyName}
                required={true}
              />
              <FormInput
                label="Website URL"
                type="text"
                value={webURL}
                stateHandler={setWebURL}
                required={true}
              />
              {webURL !== "" && invalidURL && !urlPattern.test(webURL) && (
                <small style={{ color: "#fc8383" }}>
                  Please input a valid URL to proceed.
                </small>
              )}

              <Box my="1.6em">
                <Text fontSize="15px" fontWeight="400" color="#737373">
                  Social Media Links
                </Text>

                <Flex
                  flexDir={{ base: "column", md: "row" }}
                  alignItems={{ md: "center" }}
                  justifyContent={{ md: "space-between" }}
                  mb={{ base: "14px", md: "0px" }}
                >
                  <Box
                    mr={{ base: "0em", md: "1em" }}
                    flexGrow={1}
                    mb={{ base: "12px", md: "7px" }}
                  >
                    <FormInput
                      label=""
                      type="text"
                      holderSpecific="Link Title"
                      holder="Link Title"
                      _placeholder={{ color: "rgba(0, 0, 0, 0.6)" }}
                      value={socialData.title}
                      changeEventHandler={(value: string) =>
                        setSocialData({
                          title: value,
                          url: socialData.url,
                        })
                      }
                    />
                  </Box>

                  <Box
                    ml={{ md: "1em" }}
                    display="flex"
                    flexDir={{ base: "column", md: "row" }}
                    alignItems={{ md: "center" }}
                    flexGrow={1}
                  >
                    <FormInput
                      label=""
                      type="text"
                      placeholder="Enter username"
                      holderSpecific="Username"
                      _placeholder={{ color: "rgba(0, 0, 0, 0.6)" }}
                      value={socialData.url}
                      changeEventHandler={(value: string) =>
                        setSocialData({
                          title: socialData.title,
                          url: value,
                        })
                      }
                    />

                    <Button
                      ml={{ base: "auto", md: "1em" }}
                      bgColor="#5C14CE"
                      color="#fff"
                      borderRadius=".8em"
                      fontWeight="500"
                      mt={{ base: "12px", md: "0px" }}
                      mr={{ base: "0px", md: "8px" }}
                      fontSize="13px"
                      w={{ base: "max-content", md: "auto" }}
                      px={{ base: "13px", md: "2em" }}
                      onClick={addSocialHandler}
                    >
                      Add
                    </Button>
                  </Box>
                </Flex>

                {socials.length >= 1 &&
                  socials[0].title !== "" &&
                  socials.map(
                    (socialInfo: { title: string; url: string }, i: any) => {
                      return (
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          borderBottom="1.5px solid #B6BDC3"
                          pb="4px"
                          my={{ base: "8px", md: "8px" }}
                          key={i}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            bgColor="#F0F5FF"
                            borderRadius=".4em"
                            py={["3px", "3px", "6px"]}
                            px=".4em"
                            width={{ base: "38%", md: "38%", lg: "45%" }}
                            mr=".5em"
                          >
                            {socialInfo.title.toLowerCase() === "instagram" ? (
                              <AiFillInstagram color="#999999" />
                            ) : socialInfo.title.toLowerCase() ===
                              "facebook" ? (
                              <AiFillFacebook color="#999999" />
                            ) : socialInfo.title.toLowerCase() === "twitter" ? (
                              <AiFillTwitterSquare color="#999999" />
                            ) : socialInfo.title.toLowerCase() ===
                              "linkedin" ? (
                              <AiFillLinkedin color="#999999" />
                            ) : (
                              <MdOutlineSocialDistance color="#999999" />
                            )}
                            <Text
                              fontSize="1rem"
                              fontWeight="500"
                              color="#000"
                              ml=".5em"
                            >
                              {socialInfo.title}
                            </Text>
                          </Box>

                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            bgColor="#F0F5FF"
                            borderRadius=".4em"
                            py={["3px", "3px", "6px"]}
                            px=".4em"
                            width={{ base: "56%", md: "58%", lg: "53%" }}
                            ml=".5em"
                          >
                            <Text
                              fontSize="1rem"
                              fontWeight="500"
                              color="#000"
                              mr=".5em"
                              overflowWrap="break-word"
                            >
                              {socialInfo.url.length > 15
                                ? socialInfo.url.slice(0, 15) + "..."
                                : socialInfo.url}
                            </Text>
                            <MdCancel
                              color="#000"
                              cursor="pointer"
                              onClick={() =>
                                setSocials((currentSocials: any) =>
                                  currentSocials.filter(
                                    (currentData: any) =>
                                      currentData !== currentSocials[i]
                                  )
                                )
                              }
                            />
                          </Box>
                        </Flex>
                      );
                    }
                  )}
              </Box>

              <FormInput
                label="Paypal Email Address"
                type="text"
                value={paypalAddress}
                stateHandler={setpaypalAddress}
                required={affJoinCampaign ? true : false}
              />

              {paypalAddress !== "" && !emailPattern.test(paypalAddress) && (
                <Box mt="-0.2rem" mb="0.6rem">
                  <small style={{ color: "#fc8383" }}>
                    Please input a valid paypal email to proceed.
                  </small>
                </Box>
              )}

              <FormControl isRequired={true}>
                <FormLabel
                  htmlFor="country"
                  fontSize="15px"
                  fontWeight={isCountryFocus ? "medium" : "normal"}
                  color={isCountryFocus ? " #5C14CE" : "#737373"}
                >
                  Country
                </FormLabel>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1.5px solid #B6BDC3"
                  my="1.6em"
                >
                  <Box>
                    {
                      getList().filter((info: any) => info.name === country)[0]
                        .flag
                    }
                  </Box>
                  <Select
                    border="0"
                    flexGrow={1}
                    // @ts-ignore
                    value={country}
                    onChange={(e: React.ChangeEvent<any>) =>
                      setCountry(e.target.value)
                    }
                    onFocus={(e) => setisCountryFocus(true)}
                    onBlur={(e) => setisCountryFocus(false)}
                    borderRadius="1px"
                    fontWeight="medium"
                    variant="flushed"
                    color="#000"
                    pl="0.5rem"
                    focusBorderColor={"#5C14CE"}
                  >
                    {getList()
                      .map((data: any) => data.name)
                      .map((name: string, i: any) => {
                        return (
                          <option key={i} value={name}>
                            {name}
                          </option>
                        );
                      })}
                  </Select>
                </Flex>
              </FormControl>
              <FormInput
                label="Address"
                type="text"
                value={address}
                stateHandler={setAddress}
                required={true}
              />

              {allCountries && allCountries[0].country !== "" && (
                // localState !== "" &&
                <FormInput
                  label="State"
                  type="select"
                  value={
                    allCountries &&
                    allCountries[0].country !== "" &&
                    localState === ""
                      ? allCountries.find(
                          (singleCountry: {
                            country: string;
                            state: string[];
                          }) => singleCountry.country === country
                        ).states[0]
                      : localState
                  }
                  stateHandler={setLocalState}
                  required={true}
                  holder="Select your state"
                  options={
                    allCountries && allCountries[0].country !== ""
                      ? allCountries.find(
                          (singleCountry: {
                            country: string;
                            state: string[];
                          }) => singleCountry.country === country
                        ).states
                      : [""]
                  }
                />
              )}

              <FormInput
                label="City"
                type="text"
                value={city}
                stateHandler={setCity}
                required={true}
              />
              <FormInput
                label="Zip Code"
                type="number"
                value={zipCode}
                stateHandler={setZipCode}
                required={true}
              />
              <Flex
                alignItems="center"
                justifyContent="space-between"
                mb={{ base: "1em", md: "1.75em" }}
                mt="89px"
                flexDirection={["column-reverse", "row"]}
                rowGap=".6em"
              >
                <Box
                  width={["100%", "50%", "28%"]}
                  mt={{ base: "13px", md: "0px" }}
                >
                  {/* <Button
                    px="0"
                    color="#5C14CE"
                    bgColor="#fff"
                    fontWeight="500"
                    fontSize="14px"
                    _hover={{ bgColor: "#fff" }}
                    _focus={{ bgColor: "#fff" }}
                    onClick={() => window.open("https://metricks.io", "_self")}
                    leftIcon={<IoArrowBackSharp />}
                  >
                    Back to the homepage
                  </Button> */}
                </Box>

                <Box width={["100%", "50%", "28%"]}>
                  <BaseButton
                    text="Next step"
                    textColor="#fff"
                    bgColor="#5C14CE"
                    rightIcon={<IoArrowForwardSharp />}
                    type="submit"
                    loadingState={loading}
                  />
                </Box>
              </Flex>
            </form>
          </Box>
        </Flex>
      </Flex>
    </StepWrapper>
  );
};
