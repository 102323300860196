import { Button } from "@chakra-ui/react";
import { MouseEventHandler, ReactElement } from "react";

interface ButtonSchema {
  text: string;
  textColor: string;
  bgColor: string;
  clickEventHandler?: MouseEventHandler;
  type?: "button" | "reset" | "submit" | undefined;
  loadingState?: boolean;
  loaderText?: string;
  leftIcon?: ReactElement<any, string | React.JSXElementConstructor<any>>;
  rightIcon?: ReactElement<any, string | React.JSXElementConstructor<any>>;
  isLoading?: boolean;
  loadingText?: string;
  padded?: boolean;
  border?: string;
  borderRadius?: string;
  fontSize?: any;
  disabled?: boolean;
}

export const BaseButton = ({
  text,
  textColor,
  bgColor,
  clickEventHandler,
  type,
  leftIcon,
  rightIcon,
  loadingState,
  loaderText,
  padded,
  border,
  borderRadius,
  fontSize,
  disabled,
}: ButtonSchema) => {
  return (
    <Button
      px={["3em", "2em"]}
      color={textColor}
      bgColor={bgColor}
      fontWeight="500"
      fontSize={fontSize ? fontSize : "14px"}
      onClick={clickEventHandler && clickEventHandler}
      leftIcon={leftIcon && leftIcon}
      rightIcon={rightIcon && rightIcon}
      type={type ? type : "button"}
      width="100%"
      border={border && border}
      borderRadius={borderRadius && borderRadius}
      py={padded ? "2em" : "1.5em"}
      isLoading={loadingState}
      loadingText={loaderText ? loaderText : ""}
      isDisabled={disabled ? disabled : false}
    >
      {text}
    </Button>
  );
};
