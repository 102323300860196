import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Select,
  Image,
  ModalFooter,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import { getCookie } from "cookies-next";
import { useEffect, useState } from "react";
import { AiFillFile } from "react-icons/ai";
import { BsPlusCircle, BsSearch } from "react-icons/bs";

import { IoArrowBackSharp, IoArrowForwardSharp } from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { apiReqHandler } from "../../../helper/apiReqHandler";
import { campaignState } from "../../../state/campaign";
import { endpoints } from "../../../utils/endpoints";
import { BaseButton } from "../../button";
import { CampaignHeader } from "./Header";
import { AddIcon } from "@chakra-ui/icons";
import { FiUploadCloud } from "react-icons/fi";
import { HiOutlineCloudArrowUp } from "react-icons/hi2";

export const MarketingAssets = () => {
  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get("campaign");
  const productType = searchParams.get("product");
  const activeAsset = searchParams.get("active-asset");
  const [campaignStateObj, setCampaignStateObj] = useRecoilState(campaignState);

  const [editPage] = useState(
    window.location.pathname.includes("/edit-campaign")
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [newFolderName, setNewFolderName] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [allFolders, setAllFolders]: any = useState(null);

  const [createBusy, setCreateBusy] = useState(false);
  const createHandler = async (campId: string) => {
    const payload = {
      folder_name: newFolderName,
      step: 3,
      // campaignId: Number(campaignId),
    };

    setCreateBusy(true);
    const { error, res } = await apiReqHandler({
      endPoint: endpoints.drive.NEWFOLDER.CREATE(campId),
      method: "POST",
      //   @ts-ignore
      bearerAuth: getCookie("access_token"),
      payload,
    });
    console.log(res);
    setCreateBusy(false);

    if (error)
      return toast({
        title: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });

    if (res) {
      setAllFolders([...allFolders, res.data.data]);
      onClose();
    }
    setNewFolderName("");

    //   @ts-ignore
    // console.log("response", res.data.data.campaignDriveFolder);
  };

  const fetchFolders = async (campId: string) => {
    setLoading(true);
    const { error, res } = await apiReqHandler({
      endPoint: endpoints.drive.NEWFOLDER.ALL(campId),
      method: "GET",
      //   @ts-ignore
      bearerAuth: getCookie("access_token"),
    });

    if (error) {
      console.log("ERR", error);
      setLoading(false);
      return toast({
        title: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
    console.log("all folders", res?.data.data);
    if (res) {
      setAllFolders(res?.data?.data);
      setLoading(false);
    }
  };

  const searchHandler = (searchTerm: any) => {
    // console.log(searchTerm);
    setSearchQuery(searchTerm);
    if (searchTerm !== "") {
      const results = allFolders.filter((item: any) => {
        return item.folderName.toLowerCase().includes(searchTerm.toLowerCase());
      });
      setSearchResults(results);
      // console.log(results);
    } else {
      setSearchResults(allFolders);
    }
  };

  useEffect(() => {
    if (campaignId) {
      fetchFolders(campaignId);
    }

    return;
  }, []);

  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    setCampaignStateObj({ ...campaignStateObj, isActive: false });
    function handleBeforeUnload(event: any) {
      if (!formSubmitted) {
        event.preventDefault();
        event.returnValue = "";
      }
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formSubmitted]);

  const navigate = useNavigate();
  const submitHandler = (e: any) => {
    e.preventDefault();
    setFormSubmitted(true);

    navigate(
      editPage
        ? `/campaigns/edit-campaign/questions?product=${productType}&campaign=${campaignId}`
        : `/campaigns/questions?product=${productType}&campaign=${campaignId}`
    );
  };

  const saveAndExitHandler = () => {
    setCampaignStateObj({});
    navigate("/campaigns");
  };

  return (
    <Box m="0px 0px 22px 0px">
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          mx={{ base: ".6em", md: "auto" }}
          marginTop={{ base: "15em", md: "18em" }}
        >
          <ModalCloseButton />
          <ModalBody>
            <Box py="1.5em">
              <Heading fontSize="xl" mb="1.5em">
                Create New Folder
              </Heading>

              <Input
                type="text"
                mt=".8em"
                value={newFolderName}
                onChange={(e) => setNewFolderName(e.target.value)}
                _focus={{ ring: "0", borderColor: "#5C14CE" }}
                placeholder="Enter folder name"
              />
            </Box>
          </ModalBody>

          <ModalFooter justifyContent="flex-end">
            <Box width={{ base: "40%", md: "50%" }}></Box>
            <Button
              textColor="#000"
              bgColor="#fff"
              onClick={onClose}
              fontWeight="medium"
            >
              Cancel
            </Button>
            <Spacer />
            <Button
              textColor="#5C14CE"
              bgColor="#fff"
              // @ts-ignore
              onClick={() => createHandler(`${campaignId}`)}
              fontWeight="medium"
              isLoading={createBusy}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {loading && editPage ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          h="50vh"
          alignItems="center"
          pt="20px"
        >
          <Image className="loading" src="/assets/logo.png" height="60px" />
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="70px"
            color="#000000"
            mt="-15px"
          >
            Fetching Campaign Details ...
          </Text>
        </Box>
      ) : (
        <Box>
          <CampaignHeader currentStep={3} />

          <Stack bgColor="#fff" padding="1.3em" borderRadius=".4em" mx={"22px"}>
            <Heading
              fontSize="1em"
              color="#32325D"
              borderBottom="1px solid"
              borderColor="gray.300"
              pb="1.3em"
            >
              Upload your marketing assets
            </Heading>

            <Flex
              alignItems="center"
              justifyContent="space-between"
              borderBottom="1px solid"
              borderColor="gray.300"
              pb=".6em"
            >
              <InputGroup
                borderRadius="1em"
                width={{ base: "48%", md: "40%", lg: "30%" }}
              >
                <InputLeftElement children={<BsSearch color="gray" />} />
                <Input
                  type="text"
                  placeholder="Search folders..."
                  value={searchQuery}
                  onChange={(e) => searchHandler(e.target.value)}
                  _focus={{ ring: "0", borderColor: "#5C14CE" }}
                  borderRadius="1.5em"
                  fontSize={{ base: "12px", md: "14px" }}
                />
              </InputGroup>

              <Button
                leftIcon={<BsPlusCircle color="#5C14CE" />}
                bgColor="#F2E5FF"
                color="gray.700"
                fontWeight="medium"
                fontSize={{ base: "12px", md: "14px" }}
                px="1em"
                onClick={onOpen}
              >
                New Folder
              </Button>
            </Flex>

            {!allFolders || allFolders?.length < 1 ? (
              <Flex
                w={"100%"}
                justify={"center"}
                h={["", "600px"]}
                align={"center"}
              >
                <Flex
                  w={["100%", "40%"]}
                  // borderColor={"#c4c4c4"}
                  p={10}
                  // borderWidth={1}
                  direction={"column"}
                  align={"center"}
                  gap={5}
                >
                  <Flex
                    w={"100%"}
                    justify={"center"}
                    align={"center"}
                    direction={"column"}
                  >
                    <HiOutlineCloudArrowUp size={"120px"} color="#7211d4" />
                    <Text fontWeight={"500"} fontSize={15} textAlign={"center"}>
                      Start by uploading a file
                    </Text>
                    <Text
                      color={"#c4c4c4"}
                      fontWeight={"400"}
                      textAlign={"center"}
                    >
                      All assets uploaded here will be accessible by your
                      approved affiliates
                    </Text>
                  </Flex>
                  <Button
                    leftIcon={<FiUploadCloud />}
                    // colorScheme="teal"
                    variant="solid"
                    w={"250px"}
                    p={"20px"}
                    _hover={{
                      bg: "#c",
                      borderColor: "PurplishBlue",
                      color: "white",
                    }}
                    transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
                    _active={{
                      bg: "#7211d4",
                      transform: "scale(0.98)",
                      borderColor: "#7211d4",
                    }}
                    bgColor={"#7211d4"}
                    color={"white"}
                    onClick={onOpen}
                  >
                    Upload
                  </Button>
                </Flex>
              </Flex>
            ) : (
              <Box py="1em">
                <Flex
                  alignItems="flex-start"
                  rowGap="1em"
                  columnGap="1em"
                  flexWrap="wrap"
                >
                  {allFolders.length > 0 &&
                    (searchQuery.length < 1 ? allFolders : searchResults).map(
                      (folder: any, idx: number) => {
                        return (
                          <Box
                            key={idx}
                            width={{
                              base: "100%",
                              md: "48%",
                              lg: "30",
                              xl: "23%",
                            }}
                            onClick={() =>
                              navigate(
                                editPage
                                  ? `/campaigns/edit-campaign/marketing-assets/asset?product=${productType}&campaign=${campaignId}&folderName=${folder.folderName}&folderSlug=${folder.slug}&folderId=${folder.id}`
                                  : `/campaigns/marketing-assets/asset?product=${productType}&campaign=${campaignId}&folderUrl=${folder.drive_folder}&folderName=${folder.folderName}&folderSlug=${folder.slug}&folderId=${folder.id}`
                              )
                            }
                          >
                            <Stack
                              border="1px solid"
                              borderColor="gray.100"
                              borderRadius=".4em"
                              p="1.5em"
                              px={{ lg: "1em" }}
                              spacing={4}
                              minHeight="130px"
                              justifyContent="center"
                              _hover={{ borderColor: "gray.300" }}
                              cursor="pointer"
                            >
                              <AiFillFile
                                color={idx % 2 === 0 ? "#F6AD00" : "#FA4E4E"}
                                fontSize="2em"
                              />
                              <Heading fontSize="16px">
                                {folder.folderName}
                              </Heading>
                              <Text fontSize="14px">
                                {folder?.folderAssets?.length}{" "}
                                {folder?.folderAssets?.length <= 1
                                  ? "file"
                                  : "files"}
                              </Text>
                              {/* <Text fontSize="sm">{folder.numbOfFiles}</Text> */}
                            </Stack>
                            {/* </Link> */}
                          </Box>
                        );
                      }
                    )}
                </Flex>
              </Box>
            )}

            <Flex
              alignItems="center"
              justifyContent="space-between"
              mb="1em"
              flexDirection={{ base: "column-reverse", lg: "row" }}
              rowGap=".6em"
              // pt={allFolders?.length < 5 ? "10em" : "4em"}
              pt={"4em"}
              mt={[50, 200]}
              borderTopColor={"#c4c4c4"}
              borderTopWidth={1}
            >
              <Button
                px="0"
                color="#5C14CE"
                bgColor="#fff"
                fontWeight="500"
                fontSize="14px"
                // width={{ md: "40%" }}
                _hover={{ bgColor: "#fff" }}
                _focus={{ bgColor: "#fff" }}
                onClick={() =>
                  navigate(
                    editPage
                      ? `/campaigns/edit-campaign/customization?product=${productType}&campaign=${campaignId}`
                      : `/campaigns/customization?product=${productType}&campaign=${campaignId}`
                  )
                }
                leftIcon={<IoArrowBackSharp />}
              >
                Back to the previous page
              </Button>

              <Box
                width={{ base: "100%", lg: "40%" }}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={["column-reverse", "row"]}
                rowGap=".6em"
                justifySelf="flex-end"
              >
                <Box mr={["0em", "1em"]} width={["100%", "60%", "50%"]}>
                  <BaseButton
                    text="Save and Exit"
                    textColor="#5C14CE"
                    bgColor="#F0F5FF"
                    clickEventHandler={saveAndExitHandler}
                  />
                </Box>

                <Box width={["100%", "60%", "50%"]}>
                  <BaseButton
                    text="Continue"
                    textColor="#fff"
                    bgColor="#5C14CE"
                    //   loadingState={loading}
                    rightIcon={<IoArrowForwardSharp />}
                    clickEventHandler={submitHandler}
                  />
                </Box>
              </Box>
            </Flex>
          </Stack>
        </Box>
      )}
    </Box>
  );
};
