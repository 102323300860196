import React from "react";
import { Box, Text, Image } from "@chakra-ui/react";
import { getCurrentUserInstance } from "../../../state/localstorage";
const FistStep = () => {
  const user = getCurrentUserInstance();
  return (
    <Box margin="-20px -10px">
      <Box
        display="flex !important"
        alignItems="center"
        justifyContent="space-between"
        background="#F0F5FF"
        borderRadius="9.45324px 9.45324px 0px 0px"
        padding="8px 46px"
        style={{
          flexDirection: "row",
        }}
      >
        <Text
          fontWeight="500"
          fontSize="20px"
          lineHeight="30px"
          color="#000000"
          textTransform="capitalize"
          textAlign="left"
          paddingLeft={["0px", "0px"]}
          paddingRight={["25px", "60px"]}
        >
          Welcome
          <br />
          {user.firstname}
        </Text>
        <Image
          pl={["0px", "0px"]}
          width="180px"
          height="180px"
          src="https://metricksassets.s3.us-east-2.amazonaws.com/development/default/7bafbf43-a40e-4530-988e-b79a02c81b7a.png"
        />
      </Box>
      <Box paddingTop="25px" paddingBottom="15px" textAlign="center">
        <Text
          fontWeight="500"
          fontSize=" 18px"
          lineHeight="18px"
          color="#000000"
        >
          Thank you for choosing Metricks
        </Text>
        <Text
          fontWeight="400"
          fontSize=" 12px"
          lineHeight="18px"
          color="#6F6F6F"
          pt="14px"
        >
          Let's start with a quick overview of what you have in your <br />
          customized control panel.
        </Text>
      </Box>
    </Box>
  );
};

export default FistStep;
