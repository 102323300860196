export const industries = [
  {
    niche: "Accessories",
    selected: false,
  },
  {
    niche: "Acne",
    selected: false,
  },
  {
    niche: "Alcohol",
    selected: false,
  },
  {
    niche: "Air-Purification",
    selected: false,
  },
  {
    niche: "Anti-Aging",
    selected: false,
  },
  {
    niche: "Anxiety-Management",
    selected: false,
  },
  {
    niche: "Apparel",
    selected: false,
  },
  {
    niche: "Bags and purses",
    selected: false,
  },
  {
    niche: "Bed and bath",
    selected: false,
  },
  {
    niche: "Beddings",
    selected: false,
  },
  {
    niche: "Blood-Pressure",
    selected: false,
  },

  {
    niche: "Diabetes",
    selected: false,
  },
  {
    niche: "Cash-on-Delivery",
    selected: false,
  },
  {
    niche: "CBD & Marijuana",
    selected: false,
  },
  {
    niche: "Coffee",
    selected: false,
  },
  {
    niche: "Beddings",
    selected: false,
  },

  {
    niche: "Cognitive",
    selected: false,
  },
  {
    niche: "Cognitive-Enhancements",
    selected: false,
  },
  {
    niche: "Cosmetics",
    selected: false,
  },
  {
    niche: "Credit card",
    selected: false,
  },
  {
    niche: "Credit reporting and management",
    selected: false,
  },
  {
    niche: "Crochet and Knitting",
    selected: false,
  },
  {
    niche: "Cryptocurrency trading",
    selected: false,
  },
  {
    niche: "Debt",
    selected: false,
  },
  {
    niche: "Debt reduction and Settlement",
    selected: false,
  },
  {
    niche: "Decor",
    selected: false,
  },
  {
    niche: "Diabetes",
    selected: false,
  },
  {
    niche: "Diet",
    selected: false,
  },
  {
    niche: "Diet and recipes",
    selected: false,
  },
  {
    niche: "Digital marketing",
    selected: false,
  },
  {
    niche: "Drones",
    selected: false,
  },

  {
    niche: "E-Cig",
    selected: false,
  },
  {
    niche: "Education",
    selected: false,
  },
  {
    niche: "Embroidery",
    selected: false,
  },
  {
    niche: "Energy",
    selected: false,
  },
  {
    niche: "Energy drinks",
    selected: false,
  },
  {
    niche: "Erectile-Dysfunction",
    selected: false,
  },
  {
    niche: "Essential oils",
    selected: false,
  },

  {
    niche: "E-Sports",
    selected: false,
  },
  {
    niche: "Exercise",
    selected: false,
  },
  {
    niche: "Exercise-equipment",
    selected: false,
  },
  {
    niche: "Eye-Care",
    selected: false,
  },
  {
    niche: "Fat-Burners",
    selected: false,
  },
  {
    niche: "Feminine-Care",
    selected: false,
  },
  {
    niche: "Fertility",
    selected: false,
  },
  {
    niche: "Fitness",
    selected: false,
  },
  {
    niche: "Fitness tech and apps",
    selected: false,
  },
  {
    niche: "Fragrances",
    selected: false,
  },
  {
    niche: "Furniture",
    selected: false,
  },
  {
    niche: "Gadget",
    selected: false,
  },
  {
    niche: "Gaming",
    selected: false,
  },
  {
    niche: "Gardening",
    selected: false,
  },
  {
    niche: "GPS",
    selected: false,
  },
  {
    niche: "Grooming and Hygiene products",
    selected: false,
  },
  {
    niche: "Gut-Health",
    selected: false,
  },
  {
    niche: "Hair and hair products",
    selected: false,
  },
  {
    niche: "Health and wellness",
    selected: false,
  },
  {
    niche: "Herbal",
    selected: false,
  },
  {
    niche: "Home",
    selected: false,
  },
  {
    niche: "Home security",
    selected: false,
  },
  {
    niche: "HVAC",
    selected: false,
  },
  {
    niche: "Hydration",
    selected: false,
  },
  {
    niche: "Immunity",
    selected: false,
  },
  {
    niche: "Incontinence",
    selected: false,
  },
  {
    niche: "Investment portfolio management and trading",
    selected: false,
  },
  {
    niche: "Insurance",
    selected: false,
  },
  {
    niche: "Jewelry",
    selected: false,
  },
  {
    niche: "Joint-Pain",
    selected: false,
  },
  {
    niche: "Kids",
    selected: false,
  },
  {
    niche: "Kitchen and Dining",
    selected: false,
  },
  {
    niche: "Lashes",
    selected: false,
  },
  {
    niche: "Lead-Gen",
    selected: false,
  },
  {
    niche: "Loan application and management",
    selected: false,
  },
  {
    niche: "Loan application and management",
    selected: false,
  },
  {
    niche: "Luggage",
    selected: false,
  },
  {
    niche: "Male-Skin",
    selected: false,
  },
  {
    niche: "Meal kit and subscription boxes",
    selected: false,
  },
  {
    niche: "Mood-Enhancers",
    selected: false,
  },
  {
    niche: "Mortgage",
    selected: false,
  },

  {
    niche: "Muscle",
    selected: false,
  },
  {
    niche: "Nootropics",
    selected: false,
  },
  {
    niche: "Nutrition and supplements",
    selected: false,
  },
  {
    niche: "Online banking and savings",
    selected: false,
  },
  {
    niche: "Oral-Care",
    selected: false,
  },
  {
    niche: "Organic",
    selected: false,
  },
  {
    niche: "Others",
    selected: false,
  },
  {
    niche: "Outdoors",
    selected: false,
  },
  {
    niche: "Pain-Management",
    selected: false,
  },
  {
    niche: "Pain-Relief",
    selected: false,
  },
  {
    niche: "Parenting",
    selected: false,
  },
  {
    niche: "Personal development",
    selected: false,
  },
  {
    niche: "Personal workout programs",
    selected: false,
  },
  {
    niche: "Pets",
    selected: false,
  },
  {
    niche: "Phone-Accessories",
    selected: false,
  },
  {
    niche: "Photography",
    selected: false,
  },
  {
    niche: "Plant and flower",
    selected: false,
  },
  {
    niche: "Pottery and ceramics",
    selected: false,
  },
  {
    niche: "Pregnancy",
    selected: false,
  },
  {
    niche: "Security devices",
    selected: false,
  },
  {
    niche: "Sexual-Wellness",
    selected: false,
  },
  {
    niche: "Skin and skincare",
    selected: false,
  },
  {
    niche: "Software and apps",
    selected: false,
  },
  {
    niche: "Speakers and headphones",
    selected: false,
  },
  {
    niche: "Sports",
    selected: false,
  },
  {
    niche: "Stationary",
    selected: false,
  },
  {
    niche: "Stress-Management",
    selected: false,
  },
  {
    niche: "Sweepstakes",
    selected: false,
  },
  {
    niche: "Tea",
    selected: false,
  },
  {
    niche: "Toys",
    selected: false,
  },
  {
    niche: "Travel",
    selected: false,
  },
  {
    niche: "Telemedicine",
    selected: false,
  },
  {
    niche: "Testing-Kits",
    selected: false,
  },
  {
    niche: "Tracking devices",
    selected: false,
  },
  {
    niche: "TV sets and remote controls",
    selected: false,
  },
  {
    niche: "Vegan",
    selected: false,
  },
  {
    niche: "Vitamins and supplements",
    selected: false,
  },

  {
    niche: "VPN",
    selected: false,
  },

  {
    niche: "Watches",
    selected: false,
  },

  {
    niche: "Wearable",
    selected: false,
  },
  {
    niche: "Weight-Loss",
    selected: false,
  },
  {
    niche: "Wills and estate planning",
    selected: false,
  },
  {
    niche: "Women's-Health",
    selected: false,
  },
  {
    niche: "Yoga",
    selected: false,
  },
];
