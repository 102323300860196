import React, { useEffect, useRef, useState } from "react";
import {
  Text,
  Image,
  Stack,
  Box,
  Button,
  DrawerBody,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Flex,
  Heading,
  Spinner,
  Avatar,
} from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useUserToken from "../../../hooks/useUserToken";
import NoData from "../../noData";
import { ppid } from "process";
import { campaignNavItems, campaignState } from "../../../state/campaign";
import { useRecoilState, useRecoilValue } from "recoil";
import { fetchCampaign } from "../../../helper/fetchCampaign";

const CampaignTable = ({ getSelectedCampaign }: any) => {
  const [campaignStateObj, setCampaignStateObj] = useRecoilState(campaignState);
  const { isOpen, onOpen, onClose: onButtonClose } = useDisclosure();
  const btnRef = useRef();
  const [loading, setLoading] = useState(false);
  const [campaignNav, setcampaignNav] = useRecoilState(campaignNavItems);
  const [campaigns, setCampaigns] = useState([]);
  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get("campaign");
  const navigate = useNavigate();

  const data = [
    {
      key: 1,
      name: "Sportify",
      status: "Active",
      image: "/assets/svg/spotify-cam.svg",
    },
    {
      key: 2,
      name: "Amazon",
      status: "Paused",
      image: "/assets/svg/amazon-cam.svg",
    },
    {
      key: 3,
      name: "Netflix",
      status: "Inactive",
      image: "/assets/svg/netflix.svg",
    },
  ];
  const getStatusColor = (status: string) => {
    if (status.toLowerCase() === "active") return "#239654";

    if (status.toLowerCase() === "paused") return "#F3994A";

    if (status.toLowerCase() === "draft") return "#eb5757cd";

    if (status.toLowerCase() === "inactive") return "#EB5757";
  };
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const token = useUserToken();

  const getCampaigns = () => {
    setLoading(true);
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + "campaign/fetch-campaigns", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setCampaigns(data.data.campaigns);
          setLoading(false);
          // console.log("fetched value", data.data.campaigns);
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
          setLoading(false);
        }
      })
      .catch((err) => {
        setCampaigns([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      @keyframes blink {
        0% {
          background-color: #d1b8f7;
        }
        50% {
          background-color: #ffdcaa;
        }
        100% {
          background-color: #d1b8f7;
        }
      }
    `;

    document.head.appendChild(style);
  }, []);

  const [selectedCampaignId, setselectedCampaignId] = useState("");
  const [selectedCampaignName, setSelectedCampaignName] = useState("");
  const [selectedCampaignStatus, setSelectedCampaignStatus] = useState("");

  const selectionHandler = (id: any, name: string, status: string) => {
    const campId = Number(id);

    if (Number(selectedCampaignId) !== campId) {
      setselectedCampaignId(id);
      setSelectedCampaignName(name);
      setSelectedCampaignStatus(status);
    } else {
      setselectedCampaignId("");
      setSelectedCampaignName("");
      setSelectedCampaignStatus("");
    }
  };

  useEffect(() => {
    console.log("checkings", campaignNav);
    getSelectedCampaign({
      id: selectedCampaignId,
      name: selectedCampaignName,
      status: selectedCampaignStatus,
    });
  }, [selectedCampaignId]);

  const [currentItemForMobile, setCurrentItemForMobile]: any = useState(null);
  const openHandler = (item: any) => {
    setCurrentItemForMobile(item);
    if (isOpen) return;
    else onOpen();
  };
  const closeHandler = () => {
    if (currentItemForMobile) setCurrentItemForMobile(null);
    onButtonClose();
  };

  const getCampaignData = async (campaignId: any) => {
    setLoading(true);

    const { campaignData } = await fetchCampaign({ campaignId });

    setLoading(false);

    console.log("step data", campaignData);
    if (campaignData.step == 1) {
      navigate(`/campaigns/edit-campaign?campaign=${campaignId}`);
    } else if (campaignData.step == 2) {
      navigate(
        `/campaigns/edit-campaign/customization?product=${
          campaignData.product_type == "ecommerce" ? "ecommerce" : "saas"
        }&campaign=${campaignId}`
      );
    } else if (campaignData.step == 3) {
      navigate(
        `/campaigns/edit-campaign/marketing-assets?product=${
          campaignData.product_type == "ecommerce" ? "ecommerce" : "saas"
        }&campaign=${campaignId}`
      );
    } else {
      navigate(
        `/campaigns/edit-campaign/questions?product=${
          campaignData.product_type == "ecommerce" ? "ecommerce" : "saas"
        }&campaign=${campaignId}`
      );
    }
  };

  return (
    <Stack
      py="1em"
      px="3em"
      borderRadius=".6em"
      fontStyle="normal"
      bgColor="#fff"
      h={campaigns.length <= 8 ? "80vh" : ""}
    >
      {!isMobile && (
        <>
          {campaigns.length > 0 ? (
            <Box>
              <Flex
                alignItems="center"
                borderBottom="1px"
                py="1.3em"
                borderColor="gray.100"
                justify="space-between"
              >
                <Heading
                  color="rgba(50, 50, 93, 0.8)"
                  textTransform="uppercase"
                  fontSize="12px"
                  fontWeight="600"
                  width={"5%"}
                >
                  S/N
                </Heading>

                <Heading
                  color="rgba(50, 50, 93, 0.8)"
                  textTransform="uppercase"
                  fontSize="12px"
                  fontWeight="600"
                  width={"25%"}
                >
                  Campaign Name
                </Heading>

                <Heading
                  color="rgba(50, 50, 93, 0.8)"
                  textTransform="uppercase"
                  fontSize="12px"
                  fontWeight="600"
                  width={"30%"}
                >
                  Status
                </Heading>

                <Heading
                  color="rgba(50, 50, 93, 0.8)"
                  textTransform="uppercase"
                  fontSize="12px"
                  fontWeight="600"
                  width={"40%"}
                >
                  Action
                </Heading>
              </Flex>
              {campaigns.map((item: any, idx: number) => {
                // console.log(item);
                return (
                  <Flex
                    alignItems="center"
                    justify="space-between"
                    key={item.campaignId}
                    py="1.3em"
                    px=".5em"
                    borderRadius="2px"
                    borderBottom="1px"
                    borderColor="gray.100"
                    bgColor={
                      Number(selectedCampaignId) === Number(item.campaignId)
                        ? "purple.50"
                        : "white"
                    }
                    style={{
                      animation:
                        Number(campaignId) === Number(item.campaignId)
                          ? "blink 1s linear 2s"
                          : "",
                    }}
                  >
                    <Box width={"5%"}>
                      <Flex gap={3}>
                        <Text
                          color="#32325D"
                          lineHeight="21px"
                          fontSize="15px"
                          fontWeight="500"
                          marginRight=".5em"
                        >
                          {idx + 1}.
                        </Text>

                        <Box
                          width="1.5em"
                          height="1.5em"
                          border="2px"
                          borderColor="gray.200"
                          borderRadius="4px"
                          cursor="pointer"
                          bgColor={
                            Number(selectedCampaignId) ===
                            Number(item.campaignId)
                              ? "purple.500"
                              : "white"
                          }
                          onClick={() =>
                            selectionHandler(
                              item.campaignId,
                              item.campaignName,
                              item.campaignStatus
                            )
                          }
                        ></Box>
                      </Flex>
                    </Box>

                    <Box width={"25%"}>
                      <Flex alignItems="center">
                        {/* <Checkbox marginRight=".5em" /> */}
                        <Box>
                          <Avatar
                            name={item.campaignName}
                            width="40px"
                            height="40px"
                            src={item.bannerImage}
                          />
                        </Box>
                        <Box>
                          <Text
                            color="#32325D"
                            lineHeight="21px"
                            fontSize="15px"
                            fontWeight="500"
                            paddingLeft="20px"
                            // borderBottom="1px"
                          >
                            {item.campaignName.length > 18
                              ? `${item.campaignName.substring(0, 18)}...`
                              : item.campaignName}
                            {/* {item.campaignName} */}
                          </Text>
                        </Box>
                      </Flex>
                    </Box>
                    <Box width={"30%"}>
                      <Flex alignItems="center">
                        <Box
                          backgroundColor={getStatusColor(item.campaignStatus)}
                          height="12px"
                          width="12px"
                          borderRadius="50%"
                        ></Box>
                        <Box>
                          <Text
                            color="#32325D"
                            lineHeight="21px"
                            fontSize="15px"
                            fontWeight="500"
                            paddingLeft="13px"
                          >
                            {item.campaignStatus.slice(0, 1).toUpperCase() +
                              item.campaignStatus.slice(1)}
                          </Text>
                        </Box>
                      </Flex>
                    </Box>

                    <Box width={"40%"}>
                      <Flex alignItems="center" gap="1em">
                        <Button
                          bgColor="transparent"
                          border=" 1px solid rgba(50, 50, 93, 0.4)"
                          borderRadius="8px"
                          fontWeight=" 500"
                          fontSize="14px"
                          lineHeight="21px"
                          color="#32325D"
                          p={5}
                          onClick={() => {
                            // setCampaignStateObj({});
                            setcampaignNav({ id: item.campaignId });
                            getCampaignData(item.campaignId);
                            // navigate(
                            //   `/campaigns/edit-campaign?campaign=${item.campaignId}`
                            // );
                          }}
                        >
                          <Image
                            mr="7px"
                            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/1d0eb369-16e4-4b31-be0b-13a34ea94538.svg"
                          />
                          Edit
                        </Button>
                        <Button
                          bgColor="transparent"
                          border=" 1px solid rgba(50, 50, 93, 0.4)"
                          borderRadius="8px"
                          fontWeight=" 500"
                          fontSize="14px"
                          lineHeight="21px"
                          color="#32325D"
                          p={5}
                          isDisabled={true}
                        >
                          <Flex gap={3} align={"center"}>
                            <Image
                              mr="7px"
                              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/7c0491c8-88dc-4ed0-b604-50804239f2a9.svg"
                            />
                            <Text>Report</Text>
                            <Text
                              bgColor={"#f9f5fd"}
                              color={"#7211D4"}
                              px={1}
                              py={0.5}
                            >
                              Coming Soon
                            </Text>
                          </Flex>
                        </Button>
                        <Link to={`/campaigns/view/${item.campaignId}`}>
                          <Button
                            bgColor="transparent"
                            border=" 1px solid #c4c4c4"
                            borderRadius="8px"
                            fontWeight="600"
                            fontSize="14px"
                            lineHeight="21px"
                            color=" #7211D4"
                            p={5}
                            backgroundColor={"#f0f3f9"}
                            // isDisabled={
                            //   item.campaignStatus == "draft" ? true : false
                            // }
                          >
                            <Image
                              mr="7px"
                              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/f5d72c76-51c1-41ff-ae9c-56dcafc8ec72.svg"
                            />
                            View
                          </Button>
                        </Link>
                      </Flex>
                    </Box>
                  </Flex>
                );
              })}
            </Box>
          ) : (
            !loading && (
              <Box>
                <Flex
                  alignItems="center"
                  borderBottom="1px"
                  py="1.3em"
                  borderColor="gray.100"
                  justify="space-between"
                >
                  <Heading
                    color="rgba(50, 50, 93, 0.8)"
                    textTransform="uppercase"
                    fontSize="12px"
                    fontWeight="500"
                    width={"15%"}
                  >
                    S/N
                  </Heading>

                  <Heading
                    color="rgba(50, 50, 93, 0.8)"
                    textTransform="uppercase"
                    fontSize="12px"
                    fontWeight="500"
                    width={"25%"}
                  >
                    Campaign Name
                  </Heading>

                  <Heading
                    color="rgba(50, 50, 93, 0.8)"
                    textTransform="uppercase"
                    fontSize="12px"
                    fontWeight="500"
                    width={"25%"}
                  >
                    Status
                  </Heading>

                  <Heading
                    color="rgba(50, 50, 93, 0.8)"
                    textTransform="uppercase"
                    fontSize="12px"
                    fontWeight="500"
                    width={"35%"}
                  >
                    Action
                  </Heading>
                </Flex>
                <Flex
                  alignItems="center"
                  borderBottom="1px"
                  py="1.3em"
                  borderColor="gray.100"
                  justify="center"
                  direction="column"
                >
                  <Image
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
                    // width="40px"
                    // height="40px"
                  />
                  <Text>Create a campaign to see your data</Text>
                </Flex>
              </Box>
            )
          )}
        </>
      )}
      {isMobile && (
        <>
          {campaigns.length > 0 ? (
            <Box bgColor="#ffffff" borderRadius="8px">
              <Box
                display="flex"
                height="53px"
                alignItems="center"
                justifyContent="space-between"
                padding="20px 4px"
                borderBottom="0.41199px solid rgba(50, 50, 93, 0.3)"
              >
                <Box
                  width="15%"
                  //  display={{ base: "none", md: "block" }}
                >
                  <Text
                    color="rgba(50, 50, 93, 0.8)"
                    textTransform="uppercase"
                    lineHeight="18px"
                    fontSize="12px"
                    fontWeight="700"
                  >
                    S/N
                  </Text>
                </Box>
                <Box width="38%">
                  <Text
                    color="rgba(50, 50, 93, 0.8)"
                    textTransform="uppercase"
                    lineHeight="18px"
                    fontSize="12px"
                    fontWeight="700"
                  >
                    Campaign
                  </Text>
                </Box>
                <Box width="33%">
                  <Text
                    color="rgba(50, 50, 93, 0.8)"
                    textTransform="uppercase"
                    lineHeight="18px"
                    fontSize="12px"
                    fontWeight="700"
                  >
                    STATUS
                  </Text>
                </Box>
                <Box width={{ base: "22%", md: "20%" }}>
                  <Text
                    color="rgba(50, 50, 93, 0.8)"
                    textTransform="uppercase"
                    lineHeight="18px"
                    fontSize="12px"
                    fontWeight="700"
                  >
                    Action
                  </Text>
                </Box>
              </Box>
              {campaigns.map((item: any, idx: number) => {
                return (
                  <Box
                    display="flex"
                    height="80px"
                    alignItems="center"
                    justifyContent="space-between"
                    padding="20px 4px"
                    borderBottom="0.41199px solid rgba(50, 50, 93, 0.3)"
                    key={idx}
                    bgColor={
                      Number(selectedCampaignId) === Number(item.campaignId)
                        ? "purple.50"
                        : "white"
                    }
                    style={{
                      animation:
                        Number(campaignId) === Number(item.campaignId)
                          ? "blink 1s linear 2s"
                          : "",
                    }}
                  >
                    <Box width="15%">
                      <Flex alignItems="center">
                        <Text
                          color="#32325D"
                          lineHeight="21px"
                          fontSize="15px"
                          fontWeight="500"
                          marginRight=".2em"
                        >
                          {idx + 1}.
                        </Text>
                        <Box
                          width="1em"
                          height="1em"
                          border="2px"
                          borderColor="gray.200"
                          borderRadius="4px"
                          cursor="pointer"
                          bgColor={
                            Number(selectedCampaignId) ===
                            Number(item.campaignId)
                              ? "purple.500"
                              : "white"
                          }
                          onClick={() =>
                            selectionHandler(
                              item.campaignId,
                              item.campaignName,
                              item.campaignStatus
                            )
                          }
                        ></Box>
                      </Flex>
                    </Box>
                    <Box
                      width="32%"
                      display="flex"
                      alignItems="center"
                      height="100%"
                      // border="1x solid red"
                    >
                      {/* <Box>
                    <Image
                      width="23px"
                      height="20px"
                      borderRadius="50%"
                      src={item.bannerImage}
                    />
                  </Box> */}

                      <Box>
                        <Text
                          color="#32325D"
                          lineHeight="21px"
                          fontSize="12px"
                          fontWeight="500"
                          // paddingLeft="10px"
                        >
                          {item.campaignName.length > 7
                            ? item.campaignName.slice(0, 5).trim() + "..."
                            : item.campaignName}

                          {/* {item.campaignName} */}
                        </Text>
                      </Box>
                    </Box>
                    <Box width="35%">
                      <Box display="flex" alignItems="center">
                        <Box
                          background={getStatusColor(item.campaignStatus)}
                          height="8px"
                          width="8px"
                          borderRadius="50%"
                        ></Box>
                        <Box>
                          <Text
                            color="#32325D"
                            lineHeight="21px"
                            fontSize="12px"
                            fontWeight="500"
                            paddingLeft="10px"
                          >
                            {item.campaignStatus.slice(0, 1).toUpperCase() +
                              item.campaignStatus.slice(1)}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      width="18%"
                      // border="1px solid red"
                      display="flex"
                      justifyContent="flex-start"
                    >
                      <Button
                        variant="ghost"
                        //  @ts-ignore
                        ref={btnRef}
                        onClick={() => openHandler(item)}
                        p="0em"
                        _hover={{ bgColor: "transparent" }}
                        ml={{ base: "0em", md: ".5em" }}
                      >
                        <Image
                          cursor="pointer"
                          mr="7px"
                          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/9dc6b5da-de59-4250-8729-a0f0b57be3ca.svg"
                        />
                      </Button>
                    </Box>
                  </Box>
                );
              })}

              <Drawer
                placement="bottom"
                onClose={closeHandler}
                isOpen={isOpen}
                // @ts-ignore
                finalFocusRef={btnRef}
              >
                <DrawerOverlay />
                <DrawerContent height="auto" borderRadius="16px">
                  <Image
                    textAlign="center"
                    marginTop="10px"
                    height="5px"
                    marginBottom="15px"
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/32d539ff-6833-4bb2-9c07-91b8b11ac21b.svg"
                    cursor="pointer"
                    onClick={onButtonClose}
                  />
                  {currentItemForMobile ? (
                    <DrawerBody>
                      <Link
                        to={`/campaigns/view/${currentItemForMobile?.campaignId}`}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          height="60px"
                          marginBottom="15px"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            cursor="pointer"
                          >
                            <Box>
                              <Image
                                width="18px"
                                height="18px"
                                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/f5d72c76-51c1-41ff-ae9c-56dcafc8ec72.svg"
                              />
                            </Box>
                            <Box ml="20px">
                              <Text
                                fontSize="16px"
                                fontWeight="700"
                                lineHeight="24px"
                                color="#32325D"
                                textTransform="capitalize"
                              >
                                View
                              </Text>
                            </Box>
                          </Box>

                          <Box display="flex" alignItems="center"></Box>
                        </Box>
                      </Link>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        height="60px"
                        marginBottom="15px"
                      >
                        <Flex gap={"20px"} align={"center"}>
                          <Image
                            width="18px"
                            height="18px"
                            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/7c0491c8-88dc-4ed0-b604-50804239f2a9.svg"
                          />
                          <Text
                            fontSize="16px"
                            fontWeight="700"
                            lineHeight="24px"
                            color="#c4c4c4"
                            textTransform="capitalize"
                          >
                            Report
                          </Text>
                          <Text
                            bgColor={"#f9f5fd"}
                            color={"#7211D4"}
                            px={1}
                            py={0.5}
                          >
                            Coming Soon
                          </Text>
                        </Flex>
                        <Box display="flex" alignItems="center"></Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        height="60px"
                        marginBottom="15px"
                        onClick={() =>
                          navigate(
                            `/campaigns/edit-campaign?campaign=${currentItemForMobile.campaignId}`
                          )
                        }
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          cursor="pointer"
                        >
                          <Box>
                            <Image
                              width="18px"
                              height="18px"
                              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/1d0eb369-16e4-4b31-be0b-13a34ea94538.svg"
                            />
                          </Box>
                          <Box ml="20px">
                            <Text
                              fontSize="16px"
                              fontWeight="700"
                              lineHeight="24px"
                              color="#32325D"
                              textTransform="capitalize"
                            >
                              Edit Campaign
                            </Text>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="center"></Box>
                      </Box>
                    </DrawerBody>
                  ) : (
                    <Flex justifyContent="center" alignItems="center" py="1em">
                      <Spinner color="purple.800" size="lg" />
                    </Flex>
                  )}
                </DrawerContent>
              </Drawer>
            </Box>
          ) : (
            !loading && (
              <Box>
                <Box
                  display="flex"
                  height="53px"
                  alignItems="center"
                  justifyContent="space-between"
                  padding="20px 4px"
                  borderBottom="0.41199px solid rgba(50, 50, 93, 0.3)"
                >
                  <Box
                    width="15%"
                    //  display={{ base: "none", md: "block" }}
                  >
                    <Text
                      color="rgba(50, 50, 93, 0.8)"
                      textTransform="uppercase"
                      lineHeight="18px"
                      fontSize="12px"
                      fontWeight="700"
                    >
                      S/N
                    </Text>
                  </Box>
                  <Box width="38%">
                    <Text
                      color="rgba(50, 50, 93, 0.8)"
                      textTransform="uppercase"
                      lineHeight="18px"
                      fontSize="12px"
                      fontWeight="700"
                    >
                      Campaign
                    </Text>
                  </Box>
                  <Box width="33%">
                    <Text
                      color="rgba(50, 50, 93, 0.8)"
                      textTransform="uppercase"
                      lineHeight="18px"
                      fontSize="12px"
                      fontWeight="700"
                    >
                      STATUS
                    </Text>
                  </Box>
                  <Box width={{ base: "22%", md: "20%" }}>
                    <Text
                      color="rgba(50, 50, 93, 0.8)"
                      textTransform="uppercase"
                      lineHeight="18px"
                      fontSize="12px"
                      fontWeight="700"
                    >
                      Action
                    </Text>
                  </Box>
                </Box>
                <Flex
                  alignItems="center"
                  borderBottom="1px"
                  py="1.3em"
                  borderColor="gray.100"
                  justify="center"
                  direction="column"
                >
                  <Image
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
                    // width="40px"
                    // height="40px"
                  />
                  <Text>Create a campaign to see your data</Text>
                </Flex>
              </Box>
            )
          )}
        </>
      )}
    </Stack>
  );
};

export default CampaignTable;
