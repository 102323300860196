import { Stack, Text } from "@chakra-ui/react";
import CurrencyInput from "react-currency-input-field";

export const CurrencyInputField = ({
  valueSetter,
  value,
  placeholder,
  globalSetter,
  globalState,
  errorTest,
  minValue,
  isDisabled,
  lockCampaign,
  border,
}: any) => {
  console.log("valuee", value);

  return (
    <Stack w="full">
      <CurrencyInput
        placeholder={placeholder ? placeholder : "$0.00"}
        prefix={!placeholder ? "$" : ""}
        value={value ? value : 0}
        decimalsLimit={2}
        onValueChange={(value) => {
          // console.log(globalState);
          // errorTest(false);
          globalSetter({ ...globalState, commission_amount: value });
          valueSetter(value);
        }}
        className="currencyInput"
        style={{
          width: "100%",
          border: isDisabled ? "1px solid #F7FAFC" : `1px solid ${border}`,
          color: isDisabled ? "#9f9f9f" : "#000",

          cursor: isDisabled ? "not-allowed" : "auto",
          height: "50px",
          backgroundColor: "#fefdff",
          // borderColor: "#c4c4c4",
          borderRadius: "10px",
        }}
        // min={minValue ? minValue : 1}
        disabled={lockCampaign}
      />
      {/* {minValue && value !== 0 && value < minValue && (
        <Text fontSize="14px" color="red.300" fontWeight="medium">
          Field is required
        </Text>
      )} */}

      {/* {minValue && value !== 0 && value < minValue && (
        <Text fontSize="14px" color="red.300" fontWeight="medium">
          {`Value must be ${Number(minValue)} or over`}
        </Text>
      )} */}
    </Stack>
  );
};
