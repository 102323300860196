import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Text,
  Tr,
  Th,
  Td,
  TableContainer,
  Image,
  Stack,
  Box,
  Button,
  useDisclosure,
  useToast,
  Checkbox,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import useUserToken from "../../../hooks/useUserToken";
import NoData from "../../noData";
import { motion } from "framer-motion";
import { MdChevronRight, MdKeyboardBackspace } from "react-icons/md";

const SelectCampaign = () => {
  const { isOpen, onOpen, onClose: onButtonClose } = useDisclosure();
  const navigate = useNavigate();

  const data = [
    {
      campaignId: 1,
      name: "Sportify",
      status: "Active",
      image: "/assets/svg/spotify-cam.svg",
    },
    {
      campaignId: 2,
      name: "Amazon",
      status: "Paused",
      image: "/assets/svg/amazon-cam.svg",
    },
    {
      campaignId: 3,
      name: "Netflix",
      status: "Inactive",
      image: "/assets/svg/netflix.svg",
    },
  ];

  const token = useUserToken();

  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [activeCampaign, setActiveCampaign] = useState(0);
  const [loading, setloading] = useState<boolean>(true);
  const toast = useToast();

  useEffect(() => {
    getCampaigns();
  }, []);

  const changeCampaignStatus = (id: number) => {
    setActiveCampaign(id);
  };

  const getStatusColor = (status: string) => {
    if (status.toLowerCase() === "active") {
      return "#239654";
    }
    if (status.toLowerCase() === "paused") {
      return "#F3994A";
    } else {
      return "#EB5757";
    }
  };
  const getStatusCase = (status: string) => {
    if (status.toLowerCase() === "active") {
      return "Active";
    }
    if (status.toLowerCase() === "paused") {
      return "Paused";
    } else {
      return "Draft";
    }
  };

  const getCampaigns = () => {
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + "campaign/fetch-campaigns", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("camps", data.data.campaigns);
        if (data.success) {
          const filteredCamp = data.data.campaigns.filter(
            (value: any) => value.campaignStatus !== "draft"
          );
          setCampaigns(filteredCamp);
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
        }
        setloading(false);
      })
      .catch((err) => {
        console.log("here");
        setloading(false);
      });
  };

  const [isDesktop] = useMediaQuery("(min-width: 768px)");

  return (
    <Stack
      mt="8px"
      fontStyle="normal"
      padding={{ base: "0px", md: "36px 19px" }}
    >
      <Box
        display={{ base: "flex", md: "none" }}
        mb="30px"
        padding="12px 20px"
        alignItems="center"
        bgColor="white"
      >
        <Text
          fontSize="12px"
          fontWeight="500"
          lineHeight="18px"
          color="#32325D"
          mr="5px"
          cursor="pointer"
          _hover={{ textDecoration: "underline" }}
          onClick={() => navigate(`/explore`)}
        >
          Explore
        </Text>

        <MdChevronRight size={20} color="#32325D" />

        <Text
          fontSize="12px"
          lineHeight="18px"
          color="#32325D"
          fontWeight="400"
          ml="5px"
        >
          Add New Offer
        </Text>
      </Box>

      <TableContainer bgColor="#ffffff" borderRadius="8px">
        <Box padding="20px 20px">
          <Box
            display="flex"
            alignItems="center"
            cursor="pointer"
            onClick={() => navigate(`/explore`)}
          >
            <MdKeyboardBackspace color="#32325D" />
            <Text
              color="#32325D"
              lineHeight="40px"
              fontSize="14px"
              fontWeight="400"
              pl="8px"
              display={{ base: "none", md: "block" }}
            >
              Back to the previous page
            </Text>
            <Text
              color="#32325D"
              lineHeight="40px"
              fontSize="14px"
              fontWeight="400"
              pl="8px"
              display={{ base: "block", md: "none" }}
            >
              Back
            </Text>
          </Box>

          <Box mt="24px" maxWidth={{ base: "280px", md: "auto" }}>
            <Text
              color="#32325D"
              lineHeight="24px"
              fontSize="16px"
              fontWeight="500"
            >
              Select the campaign you want to add to offers
            </Text>
          </Box>
        </Box>

        {loading ? (
          <Box display="flex" justifyContent="center">
            <motion.div
              animate={{ opacity: [0.5, 1], scale: [0.7, 1.1] }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 2,
              }}
            >
              <Text fontSize="20px" fontWeight="500" color=" #7211D4">
                Loading...
              </Text>
            </motion.div>
          </Box>
        ) : (
          <Table size={isDesktop ? "md" : undefined}>
            <Thead>
              <Tr height="53px">
                <Th
                  color="rgba(50, 50, 93, 0.8)"
                  textTransform="uppercase"
                  lineHeight="18px"
                  fontSize="12px"
                  fontWeight="500"
                ></Th>
                <Th
                  color="rgba(50, 50, 93, 0.8)"
                  textTransform="uppercase"
                  lineHeight="18px"
                  fontSize="12px"
                  fontWeight="500"
                >
                  Campaign NAME
                </Th>
                <Th
                  color="rgba(50, 50, 93, 0.8)"
                  textTransform="uppercase"
                  lineHeight="18px"
                  fontSize="12px"
                  fontWeight="500"
                >
                  STATUS
                </Th>
                <Th
                  color="rgba(50, 50, 93, 0.8)"
                  textTransform="uppercase"
                  lineHeight="18px"
                  fontSize="12px"
                  fontWeight="500"
                >
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {campaigns.length === 0 && (
                <Tr>
                  <NoData />
                </Tr>
              )}
              {campaigns.length > 0 &&
                campaigns.map((item: any) => {
                  return (
                    <Tr
                      key={item.campaignId}
                      height="80px"
                      bgColor={
                        activeCampaign === item.campaignId
                          ? "#F9F5FD"
                          : "FFFFFF"
                      }
                      onClick={() => changeCampaignStatus(item.campaignId)}
                      cursor="pointer"
                    >
                      <Td pl={{ base: "10px", md: "20px" }} pr="0px">
                        <Checkbox
                          colorScheme="purple"
                          color="#A5A5A5"
                          // ml={{}}
                          alignItems="center"
                          isChecked={activeCampaign === item.campaignId}
                          borderRadius="200px !important"
                        ></Checkbox>
                      </Td>
                      <Td>
                        <Box display="flex" alignItems="center">
                          <Image
                            width={{ base: "24px", md: "40px" }}
                            height={{ base: "24px", md: "40px" }}
                            borderRadius="50%"
                            src={item.bannerImage || item.image}
                          />{" "}
                          <Text
                            color="#32325D"
                            lineHeight="21px"
                            fontSize="14px"
                            fontWeight="500"
                            paddingLeft={{ base: "8px", md: "20px" }}
                          >
                            {item.campaignName || item.name}
                          </Text>
                        </Box>
                      </Td>
                      <Td>
                        <Box display="flex" alignItems="center">
                          <Box
                            background={getStatusColor(
                              item.campaignStatus || item.status
                            )}
                            height="12px"
                            width="12px"
                            borderRadius="50%"
                          ></Box>
                          <Text
                            color="#32325D"
                            lineHeight="21px"
                            fontSize="15px"
                            fontWeight="500"
                            paddingLeft={{ base: "6px", md: "13px" }}
                          >
                            {/* {item.campaignStatus || item.status} */}
                            {getStatusCase(item.campaignStatus || item.status)}
                          </Text>
                        </Box>
                      </Td>
                      <Td>
                        {/* <Link to="/campaigns/view/123456"> */}
                        <Button
                          bgColor="transparent"
                          border=" 1px solid rgba(50, 50, 93, 0.4)"
                          borderRadius="8px"
                          fontWeight=" 500"
                          fontSize="14px"
                          lineHeight="21px"
                          color=" #7211D4"
                          onClick={() => {
                            if (activeCampaign === item.campaignId) {
                              if (item.campaignStatus === "active") {
                                navigate(
                                  // `/explore/campaign?id=${activeCampaign}`
                                  `/campaigns/view/${item.campaignId}?offers=true`
                                );
                              } else {
                                toast({
                                  title: "Error creating Offer",
                                  description:
                                    "A Campaign that is not active cannot be added to offers. Make the campaign active and try again",
                                  status: "error",
                                  duration: 6000,
                                  isClosable: true,
                                  position: "top-right",
                                });
                              }
                            }
                          }}
                        >
                          {activeCampaign !== item.campaignId && (
                            <Image
                              mr="7px"
                              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/f5d72c76-51c1-41ff-ae9c-56dcafc8ec72.svg"
                            />
                          )}
                          {activeCampaign === item.campaignId
                            ? "Continue"
                            : "View"}
                        </Button>
                        {/* </Link> */}
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        )}
      </TableContainer>
    </Stack>
  );
};

export default SelectCampaign;
