import React, { createContext, useState } from "react";
// import { io } from "socket.io-client";

export const TemporaryNavData = createContext<any>(null);

export function NavProvider({ children }: any) {
  const [shopify, setShopify] = useState(false);
  const [orgDetails, setorgDetails] = useState([]);

  // const socket = io("https://metricks-backend-service.herokuapp.com", {
  //   transports: ["polling", "websocket"],
  // });

  return (
    <TemporaryNavData.Provider
      value={{ shopify, setShopify, orgDetails, setorgDetails }}
    >
      {children}
    </TemporaryNavData.Provider>
  );
}
