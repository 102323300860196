import {
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  HStack,
  Input,
  Link,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { HiDownload } from "react-icons/hi";
import { MdAddCircle, MdKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import useJsonToCSV from "../../../../../../hooks/useJsonToCSV";
import { UploadFileInputUI } from "../../../../../UploadFileInputUI";
// import { read, utils, writeFile } from "xlsx";
import useUserToken from "../../../../../../hooks/useUserToken";
import Papa from "papaparse";

type newAffDataProps = {
  [key: string]: any;
};

const SampleAffiliatesList = [
  {
    campaignname: "ClarkCampaign",
    firstname: "Clark",
    email: "clark@Metricks.io",
    status: "approved",
  },
  {
    campaignname: "ClarkCampaign",
    firstname: "Molly",
    email: "mollyP@Metricks.io",
    status: "pending",
  },
  // {
  //   campaignname: "ClarkCampaign",
  //   firstname: "Jane",
  //   email: "Jane@metricks.com",
  //   status: "declined",
  // },
  // {
  //   campaignname: "ClarkCampaign",
  //   firstname: "Mayor",
  //   email: "mayor@metricks.com",
  //   status: "paid",
  // },
];

let statusOptions = [
  { id: "approved", value: "Approved" },
  { id: "pending", value: "Pending" },
  // { id: "declined", value: "Declined" },
  // { id: "paid", value: "Paid" },
];

const NewAffForm = ({
  onClose,
  id,
  campaignName,
}: {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  id: string | undefined;
  campaignName: string;
}) => {
  const [newAffData, setnewAffData] = useState<newAffDataProps[]>([
    {
      firstname: "",
      email: "",
      status: "approved",
    },
  ]);
  const [affFormDataTracker, setaffFormDataTracker] = useState<newAffDataProps>(
    {
      "0": {
        firstname: "",
        email: "",
        status: "approved",
      },
    }
  );
  const [csvImported, setcsvImported] = useState<any[]>([]);
  const [csvImportedHeaders, setcsvImportedHeaders] = useState<any[]>([]);
  const [csvImportError, setcsvImportError] = useState<string>("");
  const [csvFileName, setcsvFileName] = useState<string>("");
  const [isAddingAffiliates, setisAddingAffiliates] = useState(false);

  const [inputErrorTrackers, setinputErrorTrackers] = useState<string>("");
  const [csvFileDownloadLink] = useJsonToCSV(
    ["CAMPAIGN NAME", "FIRST NAME", "EMAIL", "STATUS"],
    SampleAffiliatesList
  );
  const toast = useToast();
  const token = useUserToken();

  const handleFormDataChange = (val: string, index: number, field: string) => {
    if (inputErrorTrackers) {
      setinputErrorTrackers("");
    }

    setaffFormDataTracker({
      ...affFormDataTracker,
      [`${index}`]: {
        ...affFormDataTracker[`${index}`],
        [field]: val,
      },
    });
  };
  const [papaData, setPapaData] = useState([]);
  const [fileName, setFileName] = useState("");
  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    // console.log("file", file);
    setFileName(file.name);
    Papa.parse(file, {
      header: true,
      complete: function (results: any) {
        const transformedData = results.data.map((item: any) => {
          return {
            email: item.EMAIL,
            firstname: item["FIRST NAME"],
            status: item.STATUS.toLowerCase(),
          };
        });
        setPapaData(transformedData);
        console.log("uploaed", transformedData);
      },
    });
  };

  const csvFileToArray = (string: any) => {
    if (!string) return;
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i: string) => {
      const values = i.split(",");
      const obj = csvHeader.reduce(
        (object: { [key: string]: any }, header: string, index: number) => {
          object[header] = values[index];
          return object;
        },
        {}
      );
      return obj;
    });

    let headers = Object.keys(array[0]);
    let validHeaders = ["FIRST NAME", "EMAIL", "STATUS"];
    let err = "";

    headers.forEach((header: string) => {
      if (!validHeaders.includes(header)) {
        err =
          "Invalid file format, only CSV files allowed, download a template for guidance";
      }
    });

    if (err) {
      setcsvImportError(err);
    } else {
      setcsvImported(array);
      setcsvImportedHeaders(headers);
    }
  };

  const handleImportCSV = (e: React.ChangeEvent<HTMLInputElement>) => {
    setcsvImported([]);
    setcsvImportError("");
    setcsvFileName("");

    let files = e.target.files || [];
    let file = files[0];

    if (file) {
      setcsvFileName(file.name);
      const fileReader = new FileReader();

      fileReader.onload = (event) => {
        const text = event.target?.result || "";

        if (text) {
          if (!file.type.includes("csv")) {
            toast({
              title: "Please select a valid CSV file",
              status: "error",
              duration: 6000,
              isClosable: true,
              position: "top-right",
            });
            setcsvImportError(
              "Invalid file format, only CSV files allowed, download a template for guidance"
            );
          } else {
            csvFileToArray(text);
          }
        }
      };

      fileReader.readAsText(file);
    }
  };

  const ValidateForm = () => {
    let values = Object.values(affFormDataTracker);
    let validCount = 1;
    let error = "";

    for (let k in values) {
      let elem = values[k];

      if (!elem.firstname) {
        error = 'Please fill the "first name" field for Form ' + validCount;
        setinputErrorTrackers(`${validCount - 1}_firstname`);
        break;
      } else if (!elem.email) {
        error = 'Please fill the "email" field for Form ' + validCount;
        setinputErrorTrackers(`${validCount - 1}_email`);
        break;
      } else {
      }

      if (/\S+@\S+\.\S+/.test(elem.email)) {
        validCount += 1;
      } else {
        error = "Invalid Email";
        break;
      }
    }

    if (error) {
      return { isValid: false, error };
    } else {
      return { isValid: true, error: "" };
    }
  };

  const AddNewForm = () => {
    setaffFormDataTracker({
      ...affFormDataTracker,
      [`${newAffData.length}`]: {
        firstname: "",
        email: "",
        status: "approved",
      },
    });

    setnewAffData([
      ...newAffData,
      {
        firstname: "",
        email: "",
        status: "approved",
      },
    ]);
  };

  const removeForm = (index: number) => {
    let tempArr = affFormDataTracker;
    delete tempArr[`${index}`];

    setaffFormDataTracker(tempArr);

    setnewAffData(newAffData.filter((fm: any, idx: number) => index !== idx));
  };

  const getAffiliatePayload = () => {
    // if (!affFormDataTracker) return [];
    let initialNewAffiliates = Object.values(affFormDataTracker);

    if (initialNewAffiliates.length >= 1) {
      if (initialNewAffiliates[0]?.email == "") {
        console.log("values", papaData);
        return papaData;
      } else {
        console.log("values exist", [...initialNewAffiliates, ...papaData]);
        return [...initialNewAffiliates, ...papaData];
      }
    }
  };

  const handleAffiliateSubmit = () => {
    let bearerAuth = token;
    setisAddingAffiliates(true);

    if (!(papaData.length >= 1)) {
      let checkValidation = ValidateForm();
      if (!checkValidation.isValid) {
        setisAddingAffiliates(false);
        return toast({
          description: checkValidation.error,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    }

    let affiliatePayload = getAffiliatePayload();

    const payload = {
      campaignId: Number(id),
      affiliateData: affiliatePayload,
    };

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `/promote-campaign/invite-affiliates`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          onClose(false);
          setisAddingAffiliates(false);

          return toast({
            title: "Invite has been sent to the affiliate(s) Successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          setisAddingAffiliates(false);
          return toast({
            title: "Failed to send invite(s)",
            description: data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      });
    // more ...
  };

  return (
    <Box pb="100px">
      <Box
        display="flex"
        alignItems="center"
        cursor="pointer"
        onClick={() => onClose(false)}
        mt="26px"
      >
        <MdKeyboardBackspace color="#32325D" />
        <Text
          color="#32325D"
          lineHeight="40px"
          fontSize="14px"
          fontWeight="400"
          pl="8px"
          display={{ base: "none", md: "block" }}
        >
          Back to the previous page
        </Text>
        <Text
          color="#32325D"
          lineHeight="40px"
          fontSize="14px"
          fontWeight="400"
          pl="8px"
          display={{ base: "block", md: "none" }}
        >
          Back
        </Text>
      </Box>

      {/* <input type="file" accept=".csv" onChange={handleFileUpload} /> */}

      <Text mt="41px">
        Upload your CSV file here if you have a database of existing affiliates.
      </Text>

      <Box mb="50px" mt="30px">
        <Link
          href={csvFileDownloadLink}
          download={`sampleAffiliatesList.csv`}
          fontSize={{ base: "12px", md: "14px" }}
          lineHeight={{ base: "18px", md: "21px" }}
          fontWeight="500"
          display="flex"
          alignItems="center"
          color="rgba(114, 17, 212, 1)"
        >
          Download sample template
          <HiDownload size={14} />
        </Link>

        <Text
          color="rgba(50, 50, 93, 0.8)"
          opacity="0.5"
          fontSize="14px"
          lineHeight="21px"
          fontWeight="500"
          mt="8px"
        >
          Download the guide to uploading your affiliate's details in CSV.
        </Text>
      </Box>

      <Box
        pt="23px"
        pb="33px"
        borderBottom="0.5px solid rgba(50, 50, 93, 0.3)"
        borderTop="0.5px solid rgba(50, 50, 93, 0.3)"
      >
        <Text
          color="rgba(50, 50, 93, 1)"
          fontSize="14px"
          fontWeight="500"
          lineHeight="21px"
          mb="18px"
        >
          CSV Upload
        </Text>

        <label htmlFor="csv-upload">
          <UploadFileInputUI filename={fileName || ""} />
          <input
            type="file"
            id="csv-upload"
            accept=".csv"
            style={{ display: "none" }}
            onChange={(e) => handleFileUpload(e)}
          />
        </label>

        {csvImportError !== "" && (
          <Text mt="20px" color="red.300">
            {csvImportError}
          </Text>
        )}

        {csvImported.length ? (
          <Table variant="simple" mt="20px" size="sm">
            <Thead>
              <Tr>
                {csvImportedHeaders.map((hd: string) => (
                  <Th>{hd}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {csvImported.map((row: { [key: string]: string }) => (
                <Tr>
                  {Object.values(row).map((col: string) => (
                    <Td>{col}</Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          ""
        )}
      </Box>

      <Text
        mt="21px"
        fontSize="14px"
        fontWeight="400"
        lineHeight="18px"
        color="rgba(50, 50, 93, 1)"
      >
        Add new affiliates to {campaignName}
      </Text>

      <HStack mt="21px" mb="40px">
        <Divider orientation="horizontal" borderColor="rgba(50, 50, 93, 0.3)" />
        {/* <Text fontSize="14px" lineHeight="21px" fontWeight="500">
          Or
        </Text> */}
        <Divider orientation="horizontal" borderColor="rgba(50, 50, 93, 0.3)" />
      </HStack>

      <Stack spacing="50px">
        {newAffData.map((affForm: any, index: number) => (
          <Box>
            <Grid templateColumns="1fr 1fr" gap="30px">
              <GridItem>
                <Box>
                  <Text
                    color="rgba(0, 0, 0, 1)"
                    fontSize="14px"
                    lineHeight="21px"
                    textTransform="capitalize"
                  >
                    First Name
                  </Text>
                  <Input
                    type="text"
                    bgColor="#F1F4FA"
                    errorBorderColor="red.300"
                    isInvalid={inputErrorTrackers === `${index}_firstname`}
                    border={"0.5px solid #E1E1E1"}
                    borderRadius="10px"
                    value={affFormDataTracker[`${index}`]["firstname"]}
                    onChange={(e) =>
                      handleFormDataChange(e.target.value, index, "firstname")
                    }
                  />
                </Box>
              </GridItem>
              <GridItem>
                <Box>
                  <Text
                    color="rgba(0, 0, 0, 1)"
                    fontSize="14px"
                    lineHeight="21px"
                    textTransform="capitalize"
                  >
                    Email
                  </Text>
                  <Input
                    type="text"
                    bgColor="#F1F4FA"
                    errorBorderColor="red.300"
                    isInvalid={inputErrorTrackers === `${index}_email`}
                    border={"0.5px solid #E1E1E1"}
                    borderRadius="10px"
                    value={affFormDataTracker[`${index}`]["email"]}
                    onChange={(e) =>
                      handleFormDataChange(e.target.value, index, "email")
                    }
                  />
                </Box>
              </GridItem>

              <GridItem>
                <Box>
                  <Text
                    color="rgba(0, 0, 0, 1)"
                    fontSize="14px"
                    lineHeight="21px"
                    textTransform="capitalize"
                  >
                    Status
                  </Text>

                  <Select
                    bgColor="#F1F4FA"
                    border={"0.5px solid #E1E1E1"}
                    borderRadius="10px"
                    value={affFormDataTracker[`${index}`]["status"]}
                    onChange={(e) =>
                      handleFormDataChange(e.target.value, index, "status")
                    }
                  >
                    {statusOptions?.map((opt: any) => (
                      <option value={opt.id}>{opt.value}</option>
                    ))}
                  </Select>
                </Box>
              </GridItem>
            </Grid>

            {index > 0 && (
              <Button
                colorScheme="purple"
                fontWeight="400"
                fontSize="12px"
                lineHeight="18px"
                mt="12px"
                variant="link"
                onClick={() => removeForm(index)}
                width="max-content"
              >
                Remove
              </Button>
            )}
          </Box>
        ))}
      </Stack>

      <Box display="flex" flexDir="column">
        <Button
          leftIcon={<MdAddCircle />}
          colorScheme="purple"
          fontWeight="400"
          fontSize="12px"
          lineHeight="18px"
          mt="28px"
          variant="link"
          width="max-content"
          onClick={AddNewForm}
        >
          Add Another Affiliate
        </Button>

        <Button
          colorScheme="purple"
          fontWeight="600"
          mt="52px"
          fontSize="12px"
          lineHeight="18px"
          variant="outline"
          width="max-content"
          isLoading={isAddingAffiliates}
          onClick={
            // papaData.length !== 0 ? handleFileUpload :
            handleAffiliateSubmit
          }
        >
          Invite Affiliate
        </Button>
      </Box>
    </Box>
  );
};

export default NewAffForm;
