import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import {
  IoArrowBackSharp,
  IoArrowForward,
  IoArrowForwardSharp,
} from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getCurrentUserInstance,
  setLocalUserInstance,
} from "../../../../state/localstorage";
import useUserToken from "../../../../hooks/useUserToken";

const OrganizationSettingsFooter = ({
  dataToUpdate,
  validator,
  hasUpdate,
  sethasUpdate,
  setRefreshData,
}: {
  dataToUpdate: any;
  hasUpdate: boolean;
  sethasUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshData: React.Dispatch<React.SetStateAction<number>>;
  validator: () => any;
}) => {
  let tabs = [
    "organizationprofile",
    "address",
    "affiliateinformation",
    "niche",
    "affiliatebackground",
  ];
  const toast = useToast();

  const [updatingProfile, setupdatingProfile] = useState<string | null>(null);
  const accessToken = useUserToken("affiliate");

  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  let [searchParams, _] = useSearchParams();
  const navigate = useNavigate();

  const handleBack = () => {
    let currentIndex = tabs.indexOf(
      searchParams.get("tab") || "organizationprofile"
    );

    if (currentIndex > 0) {
      navigate(`/settings/organization?tab=${tabs[currentIndex - 1]}`);
    }
  };

  const handleNext = () => {
    let currentIndex = tabs.indexOf(
      searchParams.get("tab") || "organizationprofile"
    );

    if (currentIndex + 1 < tabs.length) {
      navigate(`/settings/organization?tab=${tabs[currentIndex + 1]}`);
    } else {
      navigate("/settings");
      setRefreshData(Math.random() * 3000);
    }
  };

  const handleUpdate = async (direction: string) => {
    setupdatingProfile(direction);

    let validate = validator();

    if (!validate) {
      setupdatingProfile(null);

      return toast({
        title: "Failed to update Organization Details",
        status: "error",
        duration: 4000,
        position: "top-right",
      });
    }

    if (!validate?.isValid) {
      setupdatingProfile(null);

      return toast({
        title: "Failed to update organization information",
        description: validate.error,
        status: "error",
        duration: 3000,
        position: "top-right",
      });
    }

    await fetch(process.env.REACT_APP_API_ENDPOINT + `organisation/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(dataToUpdate),
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.success) {
          toast({
            title: "Failed to update organization information",
            description: data.message,
            status: "error",
            duration: 3000,
            position: "top-right",
          });
        }

        if (data.success) {
          let currentUserInstance = getCurrentUserInstance() || {};

          setLocalUserInstance({
            ...currentUserInstance,
            organisation: {
              ...currentUserInstance.organisation,
              ...dataToUpdate,
            },
          });
          toast({
            title: "Organization details has been updated",
            status: "success",
            duration: 3000,
            position: "top-right",
          });

          if (direction === "next") {
            handleNext();
          } else {
            handleBack();
          }
          sethasUpdate(false);
          setRefreshData(Math.random() * 3000);

          if (searchParams.get("tab") === "affiliatebackground") {
            navigate("/settings");
          }
        }
        setupdatingProfile(null);
      })
      .catch((err) => {
        toast({
          title: "Failed to update organization information",
          description: err.message,
          status: "error",
          duration: 3000,
          position: "top-right",
        });
        setupdatingProfile(null);
      });
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      borderTop={["0px", "0.5px solid"]}
      borderTopColor={{ md: "rgba(50, 50, 93, 0.30)" }}
      my="1em"
      pt="2.5em"
      pr={{ md: "40px" }}
      flexDirection={["column-reverse", "row"]}
      rowGap=".6em"
    >
      <Box width={["100%", "50%", "28%"]}>
        {!searchParams.get("tab") ||
        searchParams.get("tab") !== "organizationprofile" ? (
          <Button
            px="0"
            color="#5C14CE"
            bgColor="#fff"
            fontWeight="500"
            fontSize="14px"
            onClick={hasUpdate ? () => handleUpdate("back") : handleBack}
            disabled={updatingProfile ? true : false}
            _hover={{ background: "purple.50" }}
            isLoading={updatingProfile === "back"}
            w={{ base: "100%", md: "auto" }}
            leftIcon={<IoArrowBackSharp />}
          >
            {isDesktop ? "Back to previous page" : "Back"}
          </Button>
        ) : (
          ""
        )}
      </Box>

      <Button
        bgColor=" #7211D4"
        border=" 0.5px solid #E5E4E4"
        borderRadius="8px"
        w={{ base: "100%", md: "auto" }}
        onClick={hasUpdate ? () => handleUpdate("next") : handleNext}
        isLoading={updatingProfile === "next"}
        disabled={updatingProfile ? true : false}
      >
        <Text
          fontWeight=" 500"
          fontSize="14px"
          lineHeight="21px"
          color=" #F0F3F9"
        >
          {searchParams.get("tab") !== "affiliatebackground" && !hasUpdate
            ? "Next"
            : "Update"}
        </Text>
        <Image
          ml="7px"
          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/f986e464-5df1-47da-a06a-cea4503eb0e9.svg"
        />
      </Button>
    </Flex>
  );
};

export default OrganizationSettingsFooter;
