import React, { useState, useEffect } from "react";
import { Box, Text, Stack, css } from "@chakra-ui/react";
import parse from "html-react-parser";
import useUserToken from "../../../../../hooks/useUserToken";
import ProductId from "../../../../affiliate/campaigns/view-campaigns/tabs/overview/ProductId";
import Countries from "../../../../explore/explore-campaign-details/tabs/overview/countries";
import TopAffiliate from "./top-affiliate";

const Overview = ({ campaign, id }: any) => {
  const token = useUserToken();
  const [top5Affiliates, settop5Affiliates] = useState<any[]>([]);

  useEffect(() => {
    console.log("product_id", campaign.product_id);
    getTop5Affiliates();
  }, [campaign]);

  const getTop5Affiliates = () => {
    let bearerAuth = token;

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `explore/offers/top5-affiliates/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          console.log("affname", data.data.top5Affiliates);
          settop5Affiliates(data.data.top5Affiliates);
        }
      })
      .catch((err) => {
        console.log("get campaign => " + err);
      });
  };

  const renderCommission = () => {
    console.log(campaign);
    if (campaign.commission_amount === null) return `${0} ${campaign.currency}`;
    if (campaign.commission_type === "fixed")
      return `${campaign.commission_amount} ${campaign.currency}`;

    if (campaign.commission_type === "percentage")
      return `${campaign.commission_percentage} %`;

    return `0 usd`;
  };

  const beautifyNumber = (num: number) => {
    const result = new Intl.NumberFormat().format(num);
    return result;
  };

  return (
    <Stack pb="100px">
      <Box
        background="#F1F4FA"
        borderRadius=" 8px"
        h="95px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        overflowX="auto"
        css={css({
          scrollbarWidth: "none",
          "::-webkit-scrollbar": { display: "none" },
          "-webkit-overflow-scrolling": "touch",
          // boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
          border: "0 none",
        })}
        mb="22px"
      >
        <Box
          display="flex"
          pl={{ base: "30px", md: "0px" }}
          pr={{ base: "30px", md: "0px" }}
          w={{ base: "max-content", md: "auto" }}
          flex="1"
          alignItems="center"
          flexDirection="column"
          borderRight="1px solid rgba(50, 50, 93, 0.5)"
        >
          <Text
            color="#32325D"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            opacity="0.8"
            letterSpacing="0.02em"
            textTransform="capitalize"
          >
            {campaign.product_type === "saas"
              ? campaign.product_type.slice(0, 3) +
                campaign.product_type.slice(3).toUpperCase()
              : campaign.product_type}
          </Text>
          <Text
            color="rgba(50, 50, 93, 0.8)"
            lineHeight="18px"
            fontSize="12px"
            fontWeight="400"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            Product
          </Text>
        </Box>
        <Box
          display="flex"
          pl={{ base: "30px", md: "0px" }}
          pr={{ base: "30px", md: "0px" }}
          flex="1"
          alignItems="center"
          justifyContent="flex-start"
          flexDirection="column"
          borderRight="1px solid rgba(50, 50, 93, 0.5)"
        >
          <Text
            color="#32325D"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            opacity="0.8"
            letterSpacing="0.02em"
            textTransform="uppercase"
            w={{ base: "max-content", md: "auto" }}
          >
            {renderCommission()}
          </Text>
          <Text
            color="rgba(50, 50, 93, 0.8)"
            lineHeight="18px"
            fontSize="12px"
            fontWeight="400"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            Commission
          </Text>
        </Box>
        <Box
          display="flex"
          pl={{ base: "30px", md: "0px" }}
          pr={{ base: "30px", md: "0px" }}
          flex="1"
          alignItems="center"
          flexDirection="column"
          borderRight="1px solid rgba(50, 50, 93, 0.5)"
        >
          <Text
            color="#32325D"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            {beautifyNumber(campaign?.noOfAffiliate)}
          </Text>
          <Text
            color="rgba(50, 50, 93, 0.8)"
            lineHeight="18px"
            fontSize="12px"
            fontWeight="400"
            w={{ base: "max-content", md: "auto" }}
            opacity="0.8"
            letterSpacing="0.02em"
          >
            Affiliates
          </Text>
        </Box>

        {campaign.product_type === "ecommerce" && (
          <Box
            display="flex"
            pl={{ base: "30px", md: "0px" }}
            pr={{ base: "30px", md: "0px" }}
            flex="1"
            alignItems="center"
            flexDirection="column"
            borderRight="1px solid rgba(50, 50, 93, 0.5)"
          >
            <Text
              color="#32325D"
              lineHeight="21px"
              fontSize="14px"
              fontWeight="500"
              opacity="0.8"
              textTransform="capitalize"
              letterSpacing="0.02em"
            >
              {campaign.stock?.toLowerCase() === "unlimited"
                ? campaign.stock
                : typeof campaign.stock === "number"
                ? beautifyNumber(campaign.stock) + " Units"
                : "0 Units"}
            </Text>
            <Text
              color="rgba(50, 50, 93, 0.8)"
              lineHeight="18px"
              fontSize="12px"
              fontWeight="400"
              w={{ base: "max-content", md: "auto" }}
              opacity="0.8"
              letterSpacing="0.02em"
            >
              Stock Cap
            </Text>
          </Box>
        )}

        <Box
          flex="1"
          display="flex"
          pl={{ base: "30px", md: "0px" }}
          pr={{ base: "30px", md: "0px" }}
          alignItems={"center"}
          flexDirection="column"
        >
          <Text
            color="#32325D"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            opacity="0.8"
            w={{ base: "max-content", md: "auto" }}
            letterSpacing="0.02em"
          >
            {campaign?.createdAt?.split("T")[0]}
          </Text>
          <Text
            color="rgba(50, 50, 93, 0.8)"
            lineHeight="18px"
            fontSize="12px"
            fontWeight="400"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            Launched
          </Text>
        </Box>
      </Box>

      {top5Affiliates.length && (
        <TopAffiliate top5Affiliates={top5Affiliates} />
      )}

      <Countries countries={campaign.countriesToShip} />

      <Box
        padding={["24px 12px", "24px 28px"]}
        background="#F1F4FA"
        borderRadius=" 8px"
      >
        <Text
          color="#32325D"
          lineHeight="18px"
          fontSize="12px"
          fontWeight="500"
          opacity="0.8"
          letterSpacing="0.02em"
          textTransform="uppercase"
        >
          Description
        </Text>
        <Box
          color="rgba(50, 50, 93, 0.9)"
          lineHeight="22px"
          fontSize="14px"
          fontWeight="300"
          opacity="0.8"
          pt={["13px", "26px"]}
        >
          {parse(campaign.description || "")}
        </Box>
      </Box>
      {campaign?.product_id && (
        <ProductId productId={campaign.product_id} affiliateRef={null} />
      )}
    </Stack>
  );
};

export default Overview;
