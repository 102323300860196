import { Routes, Route } from "react-router-dom";
import { SignUpUI } from "../components/signup";
import { Billing } from "../components/signup/Billing";
import { CompanyProfile } from "../components/signup/CompanyProfile";
import { MarketingAsset } from "../components/signup/MarketingAsset";
import { Niche } from "../components/signup/niche";
import { ProductDetails } from "../components/signup/ProductDetails";
import { UserProfile } from "../components/signup/UserProfile";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AffiliateProfile } from "../components/signup/signup-affiliate/affiliateProfile";
import { AffiliateCompanyProfile } from "../components/signup/signup-affiliate/affiliateCompanyProfile";
import { AffiliateInformation } from "../components/signup/signup-affiliate/affiliateInformation";
import { AffiliateNiche } from "../components/signup/signup-affiliate/affiliateNiche";
import { AffiliateBackground } from "../components/signup/signup-affiliate/affiliateBackground";
import React from "react";
//! Make sure to call `loadStripe` outside of a component's render to avoid
//! recreating the `Stripe` object on every render.
// @ts-ignore
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const SignUp = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<SignUpUI />} />
        <Route path="/user-profile" element={<UserProfile />} />
        <Route path="/affiliate-profile" element={<AffiliateProfile />} />
        <Route path="/company-profile" element={<CompanyProfile />} />
        <Route
          path="/affiliate-company-profile"
          element={<AffiliateCompanyProfile />}
        />
        <Route
          path="/affiliate-information"
          element={<AffiliateInformation />}
        />
        <Route path="/affiliate-background" element={<AffiliateBackground />} />
        <Route path="/affiliate-niche" element={<AffiliateNiche />} />
        <Route path="/marketing-assets" element={<MarketingAsset />} />
        <Route
          path="/product-details"
          element={
            // <Elements stripe={stripePromise}>
            <ProductDetails />
            // </Elements>
          }
        />
        <Route path="/niche" element={<Niche />} />
        <Route
          path="/billing"
          element={
            <Elements stripe={stripePromise}>
              <Billing />
            </Elements>
          }
        />
      </Routes>
    </>
  );
};

export default SignUp;
