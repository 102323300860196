export const CampaignIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 12.9999C18.7167 12.9999 18.4793 12.9039 18.288 12.7119C18.096 12.5206 18 12.2833 18 11.9999C18 11.7166 18.096 11.4789 18.288 11.2869C18.4793 11.0956 18.7167 10.9999 19 10.9999H21C21.2833 10.9999 21.5207 11.0956 21.712 11.2869C21.904 11.4789 22 11.7166 22 11.9999C22 12.2833 21.904 12.5206 21.712 12.7119C21.5207 12.9039 21.2833 12.9999 21 12.9999H19ZM18.4 19.3999L16.8 18.1999C16.5667 18.0333 16.4333 17.8166 16.4 17.5499C16.3667 17.2833 16.4333 17.0333 16.6 16.7999C16.7667 16.5666 16.9833 16.4333 17.25 16.3999C17.5167 16.3666 17.7667 16.4333 18 16.5999L19.6 17.7999C19.8333 17.9666 19.9667 18.1833 20 18.4499C20.0333 18.7166 19.9667 18.9666 19.8 19.1999C19.6333 19.4333 19.4167 19.5666 19.15 19.5999C18.8833 19.6333 18.6333 19.5666 18.4 19.3999ZM18 7.39994C17.7667 7.5666 17.5167 7.63327 17.25 7.59993C16.9833 7.5666 16.7667 7.43327 16.6 7.19993C16.4333 6.9666 16.3667 6.7166 16.4 6.44993C16.4333 6.18327 16.5667 5.9666 16.8 5.79993L18.4 4.59993C18.6333 4.43327 18.8833 4.3666 19.15 4.39993C19.4167 4.43327 19.6333 4.5666 19.8 4.79994C19.9667 5.03327 20.0333 5.28327 20 5.54993C19.9667 5.8166 19.8333 6.03327 19.6 6.19993L18 7.39994ZM5 18.9999V14.9999H4C3.45 14.9999 2.97933 14.8039 2.588 14.4119C2.196 14.0206 2 13.5499 2 12.9999V10.9999C2 10.4499 2.196 9.97893 2.588 9.58693C2.97933 9.1956 3.45 8.99993 4 8.99993H8L11.475 6.89994C11.8083 6.69993 12.146 6.69993 12.488 6.89994C12.8293 7.09994 13 7.3916 13 7.77494V16.2249C13 16.6083 12.8293 16.8999 12.488 17.0999C12.146 17.2999 11.8083 17.2999 11.475 17.0999L8 14.9999H7V18.9999H5ZM14 15.3499V8.64993C14.45 9.04993 14.8127 9.5376 15.088 10.1129C15.3627 10.6876 15.5 11.3166 15.5 11.9999C15.5 12.6833 15.3627 13.3123 15.088 13.8869C14.8127 14.4623 14.45 14.9499 14 15.3499ZM11 14.4499V9.54993L8.55 10.9999H4V12.9999H8.55L11 14.4499Z"
        fill="none"
        {...props}
      />
    </svg>
  );
};
