import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  MdCalendarToday,
  MdChevronRight,
  MdFiberManualRecord,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";

type FilterDrawerProps = {
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
  setFilterData: React.Dispatch<
    React.SetStateAction<{
      date: string;
      status: string;
    }>
  >;
  setSearchVal: React.Dispatch<React.SetStateAction<string>>;
  onDrawerClose: () => void;
  isDrawerOpen: boolean;
  filterData: any;
};

const FilterDrawer = ({
  onDrawerClose,
  isDrawerOpen,
  setCurrentTab,
  setFilterData,
  setSearchVal,
  filterData,
}: FilterDrawerProps) => {
  const navigate = useNavigate();
  const [openedFilter, setopenedFilter] = useState<string | null>(null);

  const HandleTabChange = (tab: string) => {
    setCurrentTab(tab);
    navigate("/affiliate-campaigns");
    setFilterData({
      date: "",
      status: "",
    });
    setSearchVal("");
    onDrawerClose();
  };

  const handleDateSelect = (value: string) => {
    setFilterData({ ...filterData, date: value });
    onDrawerClose();
    setopenedFilter(null);
  };

  return (
    <Box>
      <Drawer
        placement={"bottom"}
        onClose={onDrawerClose}
        isOpen={isDrawerOpen}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            {openedFilter === "date" ? (
              <Stack divider={<StackDivider borderColor="gray.200" />}>
                <Box
                  p="14px 40px"
                  display="flex"
                  alignItems="center"
                  onClick={() => handleDateSelect("")}
                >
                  <Text
                    fontSize="14px"
                    lineHeight="20px"
                    color="rgba(50, 50, 93, 1)"
                  >
                    All
                  </Text>
                </Box>

                <Box
                  p="14px 40px"
                  display="flex"
                  alignItems="center"
                  onClick={() => handleDateSelect("last_day")}
                >
                  <Text
                    fontSize="14px"
                    lineHeight="20px"
                    color="rgba(50, 50, 93, 1)"
                  >
                    Last Day
                  </Text>
                </Box>

                <Box
                  p="14px 40px"
                  display="flex"
                  alignItems="center"
                  onClick={() => handleDateSelect("last_30_days")}
                >
                  <Text
                    fontSize="14px"
                    lineHeight="20px"
                    color="rgba(50, 50, 93, 1)"
                  >
                    Last 30 days
                  </Text>
                </Box>

                <Box
                  p="14px 40px"
                  display="flex"
                  alignItems="center"
                  onClick={() => handleDateSelect("last_quater")}
                >
                  <Text
                    fontSize="14px"
                    lineHeight="20px"
                    color="rgba(50, 50, 93, 1)"
                  >
                    Last Quater
                  </Text>
                </Box>

                <Box
                  p="14px 40px"
                  display="flex"
                  alignItems="center"
                  onClick={() => handleDateSelect("last_year")}
                >
                  <Text
                    fontSize="14px"
                    lineHeight="20px"
                    color="rgba(50, 50, 93, 1)"
                  >
                    Last Year
                  </Text>
                </Box>
              </Stack>
            ) : (
              <Stack divider={<StackDivider borderColor="gray.200" />}>
                <Box
                  color="rgba(50, 50, 93, 1)"
                  p="14px 40px"
                  display="flex"
                  alignItems="center"
                  onClick={() => setopenedFilter("date")}
                >
                  <MdCalendarToday size={17} />
                  <Text
                    fontSize="14px"
                    lineHeight="20px"
                    flex="1"
                    ml="25px"
                    color="rgba(50, 50, 93, 1)"
                  >
                    Date
                  </Text>
                  <MdChevronRight size={10} />
                </Box>

                <Box
                  p="14px 40px"
                  display="flex"
                  alignItems="center"
                  onClick={() => HandleTabChange("approved")}
                >
                  <MdFiberManualRecord color="rgba(35, 150, 84, 1)" size={20} />
                  <Text
                    fontSize="14px"
                    lineHeight="20px"
                    color="rgba(50, 50, 93, 1)"
                    ml="25px"
                  >
                    Approve
                  </Text>
                </Box>

                <Box
                  p="14px 40px"
                  display="flex"
                  alignItems="center"
                  onClick={() => HandleTabChange("pending")}
                >
                  <MdFiberManualRecord
                    color="rgba(243, 153, 74, 1)"
                    size={20}
                  />
                  <Text
                    fontSize="14px"
                    lineHeight="20px"
                    color="rgba(50, 50, 93, 1)"
                    ml="25px"
                  >
                    Pending
                  </Text>
                </Box>

                <Box
                  p="14px 40px"
                  display="flex"
                  alignItems="center"
                  onClick={() => HandleTabChange("declined")}
                >
                  <MdFiberManualRecord color="rgba(235, 87, 87, 1)" size={20} />
                  <Text
                    fontSize="14px"
                    lineHeight="20px"
                    color="rgba(50, 50, 93, 1)"
                    ml="25px"
                  >
                    Declined
                  </Text>
                </Box>
              </Stack>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default FilterDrawer;
