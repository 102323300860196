import React, { useEffect, useState } from "react";
import useJsonToCSV from "../../../../../hooks/useJsonToCSV";
import {
  Avatar,
  Box,
  Button,
  Circle,
  Flex,
  HStack,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdArrowDropDown, MdFilterList } from "react-icons/md";
import { RiSearchLine } from "react-icons/ri";
import CommissionsTable from "./CommissionsTable";
import useUserToken from "../../../../../hooks/useUserToken";
import Fuse from "fuse.js";
import { FaFileExport } from "react-icons/fa";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import moment from "moment";

const Commissions = ({
  campaignName,
  id,
  refreshData,
  setExternalAffInfo,
  setTabIndex,
}: {
  campaignName: string;
  id: string | undefined;
  refreshData: boolean;
  setExternalAffInfo: React.Dispatch<any>;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [searchVal, setsearchVal] = useState<string>("");
  const [filterData, setfilterData] = useState({
    date: "",
    status: "",
  });
  const [declineReason, setdeclineReason] = useState("This is a duplicate");

  const token = useUserToken();
  const toast = useToast();

  const [isLoading, setisLoading] = useState(true);
  const [filterIsOpen, setfilterIsOpen] = useState(false);

  const [commissions, setcommissions] = useState<any[]>([]);
  const [filteredCommissons, setfilteredCommissons] = useState<any[]>([]);
  const [existingAffiliates, setexistingAffiliates] = useState<any[]>([]);
  const [updatingCommisions, setupdatingCommisions] = useState(false);
  const [refreshCommissionTable, setrefreshCommissionTable] = useState(false);
  const [updateAction, setupdateAction] = useState("");

  const [highlighted, sethighlighted] = useState<any[]>([]);
  const [highlighted2, sethighlighted2] = useState<any[]>([]);
  const [dataToDownLoad, setdataToDownLoad] = useState<any[]>([]);
  const [dataToDownLoad2, setdataToDownLoad2] = useState<any[]>([]);

  const options = {
    keys: ["aff_orgName", "productId"],
  };

  const fuse = new Fuse(commissions, options);

  const [compName, setCompName] = useState("");
  const [valueText, setvalueText] = useState("");
  const [invalidDesc, setinvalidDesc] = useState(false);

  const getCommissions = (
    endpoint: string,
    setPlaceholder: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    if (!id) return;

    setisLoading(true);
    let bearerAuth = token;

    fetch(process.env.REACT_APP_API_ENDPOINT + endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          console.log("commisions", data.data.commissions[0]);
          setCompName(data.data.commissions[0].adv_org?.companyname);
          setPlaceholder(
            data.data.commissions.map((commission: any) => ({
              id: commission.id,
              createdAt: commission.createdAt,
              sales: commission.sales,
              commission: commission.amount,
              status: commission.status,
              aff_orgId: commission.aff_org.id,
              aff_orgName: commission.aff_org.companyname,
              aff_fullname:
                commission.aff_user.firstname +
                " " +
                commission.aff_user.lastname,
              campaignName,
              aff_email: commission.aff_org.paypal_address,
              productId: commission.conversion.product_id,
              affCountry: commission.aff_org.country,
              affState: commission.aff_org.state,
              affAddress: commission.aff_org.address,
              advertiserCountry: commission.adv_org.country,
              advertiserState: commission.adv_org.state,
              advertiserAddress: commission.adv_org.address,
              affOrg: commission.aff_org,
              affOrgName: commission.aff_org.companyname,
              affUser: commission.aff_user,
              payment_due_date: commission.payouts[0].dateScheduledFor,
            }))
          );
          console.log(data);
        }
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
        console.log("get commissions => " + err);
      });
  };

  const getCampaignAffiliates = () => {
    if (!id) return;

    // setisLoading(true);
    let bearerAuth = token;

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `/promote-campaign/get-affiliates/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          console.log("afff", data.data.affiliates);
          setexistingAffiliates(data.data.affiliates);
          // setisLoading(false);
        }
      })
      .catch((err) => {
        // setisLoading(false);
        console.log("get sales => " + err);
      });
  };

  const handleViewAffiliate = (selectedAff: any) => {
    let affInfo = existingAffiliates.find(
      (aff) => aff.aff_orgId === selectedAff
    );
    console.log(affInfo);

    if (affInfo) {
      setExternalAffInfo(affInfo);
      setTabIndex(3);
    }
  };

  const changeCommissionStatus = (status: string) => {
    setupdatingCommisions(true);
    console.log("status", status);
    setupdateAction(status);

    let bearerAuth = token;
    let commissionIds: any[] = [];

    highlighted.forEach((x: any) => {
      commissionIds.push(x?.id);
    });

    const payload = {
      status,
      commissionIds: commissionIds,
      reason: declineReason == "Other" ? valueText : declineReason,
    };

    fetch(process.env.REACT_APP_API_ENDPOINT + `/commissions/change-status`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          console.log(data);
          setupdatingCommisions(false);
          setrefreshCommissionTable(!refreshCommissionTable);
          sethighlighted([]);
          setupdateAction("");

          return toast({
            title: `${
              status === "approved"
                ? "Approved"
                : status === "paid"
                ? "Paid"
                : "Declined"
            } Commisson(s) Successfully`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          setupdatingCommisions(false);
          return toast({
            title: `Failed to ${
              status === "approved"
                ? "Approved"
                : status === "paid"
                ? "Paid"
                : "Declined"
            } commissions`,
            description: data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      });
  };

  function isJSON(str: string) {
    try {
      return JSON.parse(str) && !!str;
    } catch (e) {
      return false;
    }
  }

  const payAffiliate = () => {
    setupdatingCommisions(true);
    setupdateAction("paying");
    let bearerAuth = token;

    fetch(process.env.REACT_APP_API_ENDPOINT + `/multiple/payout`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setupdatingCommisions(false);
          setrefreshCommissionTable(!refreshCommissionTable);
          sethighlighted([]);
          setupdateAction("");

          return toast({
            title: `Affiliate(s) have been paid successfully`,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          setupdatingCommisions(false);
          setupdateAction("");
          return toast({
            title: `Failed to pay affiliate(s)`,
            description: isJSON(data.message)
              ? JSON.parse(data.message)?.name
              : data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        setupdatingCommisions(false);
        return toast({
          title: `Failed to pay affiliates`,
          description: err.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      });
  };

  const noResult = () => {
    if (!isLoading && !commissions.length)
      return { result: true, reason: "You currently do not have any sale" };

    if (!isLoading && !filteredCommissons.length && searchVal)
      return { result: true, reason: "No Results Found" };

    if (!isLoading && !filteredCommissons.length && filterData.date)
      return { result: true, reason: "No Results Found" };

    return { result: false, reason: "" };
  };

  const ifFilterExists = () => {
    if (isLoading) return false;
    if (searchVal || filterData.date || filterData.status) return true;
    return false;
  };

  useEffect(() => {
    setfilterData({
      date: "",
      status: "",
    });
    getCommissions(`commissions/get-commissions/${id}`, setcommissions);
    getCampaignAffiliates();
  }, [refreshData, refreshCommissionTable]);

  useEffect(() => {
    if (filterData.date && !filterData.status) {
      setsearchVal("");
      getCommissions(
        `commissions/get-commissions/${id}?date=${filterData.date}`,
        setfilteredCommissons
      );
    } else if (!filterData.date && filterData.status) {
      setsearchVal("");
      getCommissions(
        `commissions/get-commissions/${id}?status=${filterData.status}`,
        setfilteredCommissons
      );
    } else if (filterData.date && filterData.status) {
      setsearchVal("");
      getCommissions(
        `commissions/get-commissions/${id}?date=${filterData.date}&status=${filterData.status}`,
        setfilteredCommissons
      );
    } else {
      // do nothing
    }
  }, [filterData]);

  useEffect(() => {
    setfilteredCommissons(
      fuse.search(searchVal).map((commission: any) => ({ ...commission.item }))
    );

    if (filterData.date || filterData.status) {
      setfilterData({ date: "", status: "" });
    }
  }, [searchVal]);

  useEffect(() => {
    let clonedHighlighted: any[] = [];
    let clonedHighlighted2: any[] = [];
    console.log("highlighted", highlighted);
    highlighted.forEach((item: any) => {
      clonedHighlighted.push({
        dateCreated: item?.createdAt.slice(0, 10) || "",
        organisation: item?.campaignName || "",
        sales: item?.sales || 0,
        commission: item?.commission || 0,
        status: item?.status || "",
        productId: item?.productId || "-",
        payPalEmail: item?.aff_email || "",
        paymentDueDate:
          moment(item?.payment_due_date).format("MMM Do YYYY") || "",
      });
    });
    highlighted2.forEach((item: any) => {
      clonedHighlighted2.push({
        campaign: campaignName,
        firstName: item?.affUser.firstname || "",
        lastName: item?.affUser.lastname || 0,
        payPalEmail: item?.aff_email || 0,
        companyName: item?.affOrg.companyname || "",
      });
    });

    console.log("highlighted", highlighted);
    setdataToDownLoad(clonedHighlighted);
    setdataToDownLoad2(clonedHighlighted2);
  }, [highlighted, highlighted2]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenR,
    onOpen: onOpenR,
    onClose: onCloseR,
  } = useDisclosure();

  const [csvFileDownloadLink] = useJsonToCSV(
    [
      "DATE CREATED",
      "ORGANIZATION",
      "SALES",
      "COMMISSON",
      "STATUS",
      "PRODUCT ID",
      "PAYPAL EMAIL",
      "PAYMENT DUE DATE",
    ],
    dataToDownLoad
  );

  const [csvFileDownloadLink2] = useJsonToCSV(
    ["CAMPAIGN", "FIRST NAME", "LAST NAME", "PAYPAL EMAIL", "COMPANY NAME"],
    dataToDownLoad2
  );

  const [dateFilterToggle, setDateFilterToggle] = useState(false);
  const [statusFilterToggle, setStatusFilterToggle] = useState(false);
  return (
    <Stack pb="100px">
      <Box
        alignItems="center"
        display={{ base: "flex", md: "none" }}
        mt="36px"
        mb="14px"
        justifyContent="end"
      >
        <Button
          borderRadius="8px"
          variant="outline"
          color="rgba(50, 50, 93, 1)"
          fontSize={{ base: "12px", md: "14px" }}
          lineHeight={{ base: "18px", md: "21px" }}
          isLoading={updatingCommisions && updateAction === "approved"}
          isDisabled={updatingCommisions || highlighted.length === 0}
          fontWeight="500"
          border="0.5px solid rgba(50, 50, 93, 0.4)"
          onClick={() => changeCommissionStatus("approved")}
        >
          Approve
        </Button>

        <Button
          borderRadius="8px"
          variant="outline"
          mx="12px"
          color="rgba(50, 50, 93, 1)"
          isDisabled={updatingCommisions || highlighted.length === 0}
          isLoading={updatingCommisions && updateAction === "declined"}
          fontSize={{ base: "12px", md: "14px" }}
          lineHeight={{ base: "18px", md: "21px" }}
          border="0.5px solid rgba(50, 50, 93, 0.4)"
          fontWeight="500"
          onClick={() => changeCommissionStatus("declined")}
        >
          Decline
        </Button>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
        <ModalOverlay />
        <ModalContent
          // mx={{ base: ".6em", md: "auto" }}
          marginTop={{ base: "15em", md: "18em" }}
          borderRadius={10}
          paddingBottom={5}
        >
          {/* <ModalCloseButton /> */}
          <ModalBody>
            <Flex py="1.5em" direction={"column"} gap={5}>
              <Circle size="60px" bg="#fef3f2">
                <Circle size="40px" bg="#fee4e2" color="#d92d21">
                  <HiOutlineExclamationCircle size={25} />
                </Circle>
              </Circle>
              <Box>
                <Text fontWeight={600} fontSize={"1.5em"}>
                  Decline Commision
                </Text>
                <Text>Are you sure you want to decline this commission?</Text>
              </Box>
            </Flex>
          </ModalBody>

          <ModalFooter justifyContent="flex-end">
            <Button
              borderRadius="8px"
              bgColor="#ffff"
              color="#000"
              p={7}
              borderWidth={0.5}
              borderColor={"#c4c4c4"}
            >
              {/* <IoPersonOutline fontSize={14} /> */}
              <Text
                fontWeight="500"
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight={{ base: "18px", md: "21px" }}
                ml="7px"
                onClick={onClose}
              >
                No, Cancel
              </Text>
            </Button>

            <Button
              p={7}
              bgColor="#7211D4"
              color="white"
              borderRadius="8px"
              fontWeight="500"
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight={{ base: "18px", md: "21px" }}
              w="max-content"
              ml="13px"
              onClick={() => {
                onClose();
                onOpenR();
              }}
            >
              Yes, Decline
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenR} onClose={onCloseR} size={"xl"}>
        <ModalOverlay />
        <ModalContent
          // mx={{ base: ".6em", md: "auto" }}
          marginTop={{ base: "15em", md: "18em" }}
          borderRadius={10}
          py={5}
        >
          <ModalCloseButton />
          <ModalBody>
            <Flex py="1.5em" direction={"column"} gap={5}>
              <Circle size="60px" bg="#fef3f2">
                <Circle size="40px" bg="#fee4e2" color="#d92d21">
                  <HiOutlineExclamationCircle size={25} />
                </Circle>
              </Circle>
              <Flex gap={5} flexDirection={"column"} w={"100%"}>
                {declineReason == "Other" ? (
                  <Text fontWeight={600} fontSize={"1.2em"}>
                    Other
                  </Text>
                ) : (
                  <Text fontWeight={600} fontSize={"1.2em"}>
                    Reason for declining
                  </Text>
                )}
                {declineReason == "Other" ? (
                  <Text>Are you sure you want to decline this commission</Text>
                ) : (
                  <Text>Select why you want to decline this commission</Text>
                )}

                <Select
                  w={{ base: "100%", md: "100%" }}
                  h={"45px"}
                  bg="white"
                  placeholder={"This is a duplicate"}
                  value={declineReason}
                  onChange={(e) => setdeclineReason(e.target.value)}
                  icon={<MdArrowDropDown />}
                >
                  <option value="This is a fraudulent commission">
                    This is a fraudulent commission
                  </option>
                  {/* <option value="This is a duplicate">
                    
                  </option> */}
                  <option value="This was an error">This was an error</option>
                  <option value="Other">Other</option>
                </Select>

                {declineReason === "Other" && (
                  <Flex gap={3}>
                    <Avatar name={compName} size={{ base: "md", md: "sm" }} />

                    <Box width={"100%"}>
                      <Textarea
                        placeholder="Tell us why you want to decline your commission.."
                        size={"sm"}
                        value={valueText}
                        onChange={(e) => {
                          setinvalidDesc(false);
                          let inputValue = e.target.value;
                          setvalueText(inputValue);
                        }}
                        // isInvalid={invalidDesc}
                      />
                      {invalidDesc && (
                        <Text fontSize={11} color={"red"}>
                          Kindly give a reason why you want to decline
                        </Text>
                      )}
                    </Box>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter justifyContent="flex-end">
            <Button borderRadius="8px" bgColor="#7211D4" color="white" w="30%">
              {/* <IoPersonOutline fontSize={14} /> */}
              <Text
                fontWeight="500"
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight={{ base: "18px", md: "21px" }}
                ml="7px"
                onClick={onCloseR}
              >
                Cancel
              </Text>
            </Button>

            <Button
              bgColor="#F0F3F9"
              // size={"md"}
              borderRadius="8px"
              fontWeight="500"
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight={{ base: "18px", md: "21px" }}
              color="#7211D4"
              w="30%"
              ml="13px"
              onClick={() => {
                if (declineReason == "Other") {
                  // console.log(valueText);
                  if (valueText == "") {
                    setinvalidDesc(() => true);
                    return;
                  } else {
                    onCloseR();
                    changeCommissionStatus("declined");
                  }
                } else {
                  console.log(declineReason);
                  onCloseR();
                  changeCommissionStatus("declined");
                }
              }}
            >
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box
        display={{ base: "none", md: "flex" }}
        alignItems="center"
        justifyContent="space-between"
        mt={{ md: "36px" }}
        mb="28px"
      >
        <Button
          borderRadius="8px"
          variant="outline"
          color="rgba(50, 50, 93, 1)"
          onClick={() => setfilterIsOpen(!filterIsOpen)}
        >
          <MdFilterList fontSize={15} />
          <Text
            fontSize="14px"
            color="rgba(50, 50, 93, 0.8)"
            fontWeight="500"
            lineHeight="21px"
            ml="7px"
          >
            Filter
          </Text>
        </Button>

        <Flex alignItems="center">
          {highlighted[0]?.status !== "overdue" &&
            highlighted[0]?.status !== "approved" && (
              <Tooltip
                label={
                  highlighted.length === 0
                    ? "select the commisions you have paid with the tickbox to continue"
                    : ""
                }
                fontSize="md"
              >
                <Button
                  borderRadius="8px"
                  mx="12px"
                  variant="outline"
                  color="rgba(50, 50, 93, 1)"
                  fontSize={{ base: "12px", md: "14px" }}
                  lineHeight={{ base: "18px", md: "21px" }}
                  display={{ base: "none", md: "inline-block" }}
                  isLoading={updatingCommisions && updateAction === "approved"}
                  isDisabled={
                    updatingCommisions ||
                    highlighted.length !== 1 ||
                    updateAction === "approved"
                  }
                  fontWeight="500"
                  border="0.5px solid rgba(50, 50, 93, 0.4)"
                  onClick={() => changeCommissionStatus("approved")}
                >
                  Approve
                </Button>
              </Tooltip>
            )}

          {highlighted[0]?.status !== "declined" && (
            <Tooltip
              label={
                highlighted.length === 0
                  ? "select the commisions you want to decline with the tickbox to continue"
                  : ""
              }
              fontSize="md"
            >
              <Button
                borderRadius="8px"
                variant="outline"
                mx="12px"
                color="rgba(50, 50, 93, 1)"
                disabled={updatingCommisions}
                isLoading={updatingCommisions && updateAction === "declined"}
                isDisabled={
                  updatingCommisions ||
                  highlighted.length !== 1 ||
                  // @ts-ignore
                  updateAction === "declined"
                }
                display={{ base: "none", md: "inline-block" }}
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight={{ base: "18px", md: "21px" }}
                border="0.5px solid rgba(50, 50, 93, 0.4)"
                fontWeight="500"
                onClick={
                  () => onOpen()
                  // changeCommissionStatus("declined")
                }
              >
                Decline
              </Button>
            </Tooltip>
          )}
          {highlighted[0]?.status !== "paid" && (
            <Tooltip
              label={
                highlighted.length == 0
                  ? "select the commisions you want to approve with the tickbox to continue"
                  : ""
              }
              fontSize="md"
            >
              <Button
                borderRadius="8px"
                mx="12px"
                variant="outline"
                color="rgba(50, 50, 93, 1)"
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight={{ base: "18px", md: "21px" }}
                display={{ base: "none", md: "inline-block" }}
                isLoading={updatingCommisions && updateAction === "paid"}
                isDisabled={
                  updatingCommisions ||
                  highlighted.length !== 1 ||
                  highlighted[0]?.status === "pending"
                    ? true
                    : false
                }
                fontWeight="500"
                border="0.5px solid rgba(50, 50, 93, 0.4)"
                onClick={() => changeCommissionStatus("paid")}
              >
                Paid
              </Button>
            </Tooltip>
          )}

          {highlighted.length !== 0 ? (
            <Link
              href={csvFileDownloadLink}
              download={`${campaignName}_Commissions.csv`}
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight={{ base: "18px", md: "21px" }}
              fontWeight="500"
              width={{ base: "90px", md: "130px" }}
              borderRadius="8px"
              height="35px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              color="rgba(50, 50, 93, 0.8)"
              border="0.5px solid rgba(50, 50, 93, 0.4)"
              _hover={{ textDecoration: "none" }}
            >
              <Flex align={"center"} gap={2}>
                <FaFileExport />
                {/* ef */}
                Export CSV
              </Flex>
            </Link>
          ) : (
            <Tooltip
              label={
                "select the commissions you want to export with the tickbox to continue"
              }
              fontSize="md"
            >
              <Button
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight={{ base: "18px", md: "21px" }}
                fontWeight="500"
                width={{ base: "90px", md: "130px" }}
                borderRadius="8px"
                height="35px"
                display="flex"
                isDisabled={true}
                alignItems="center"
                justifyContent="center"
                color="rgba(50, 50, 93, 0.8)"
                border="0.5px solid rgba(50, 50, 93, 0.4)"
                _hover={{ textDecoration: "none" }}
              >
                <Flex align={"center"} gap={2}>
                  <FaFileExport />
                  Export CSV
                </Flex>
              </Button>
            </Tooltip>
          )}
        </Flex>
      </Box>

      {filterIsOpen && (
        <Box
          display={{ base: "flex", md: "flex" }}
          flexDirection={{ base: "column", md: "row" }}
          alignItems={{ base: "start", md: "center" }}
          p="13px 7px"
          bgColor="#F2EFF6"
          mb="8px"
          mt="8px"
        >
          <InputGroup
            w={{ base: "100%", md: "280px" }}
            mb={{ base: "16px", md: "0px" }}
          >
            <InputLeftElement
              pointerEvents="none"
              children={<RiSearchLine color="rgba(50, 50, 93, 0.8)" />}
            />
            <Input
              css={{
                border: "0.5px solid rgba(50, 50, 93, 0.4)",
              }}
              type="text"
              value={searchVal}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setsearchVal(e.target.value)
              }
              fontSize="16px"
              mr={{ md: "20px" }}
              bg="white"
              focusBorderColor="#7211D4"
              placeholder="Search for Affiliates"
            />
          </InputGroup>

          <Flex
            gap="14px"
            flexDirection={["column", "row"]}
            mt={"8px"}
            // w={["100%", ""]}
          >
            <Flex flexDir={"column"} w={"100%"} position={"relative"}>
              <Flex
                justify={"space-between"}
                align={"center"}
                bgColor={"white"}
                p={3}
                w={["100%", "150px"]}
                cursor={"pointer"}
                h={"80%"}
                onClick={() => {
                  setDateFilterToggle((prev) => !prev);
                }}
              >
                <Text>{filterData.date == "" ? "Date" : filterData.date}</Text>
                <MdArrowDropDown fontSize={"24px"} />
              </Flex>

              {dateFilterToggle && (
                <Box
                  position={"absolute"}
                  top={"80%"}
                  w={["100%", "150px"]}
                  zIndex={1}
                >
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, date: "last_day" });
                      setDateFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Last Day</Text>
                  </Flex>
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, date: "last_30_days" });
                      setDateFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Last 30 Days</Text>
                  </Flex>
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, date: "last_quater" });
                      setDateFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Last Quarter</Text>
                  </Flex>
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, date: "last_year" });
                      setDateFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Last Year</Text>
                  </Flex>
                </Box>
              )}
            </Flex>
            <Flex flexDir={"column"} w={"100%"} position={"relative"}>
              <Flex
                justify={"space-between"}
                align={"center"}
                bgColor={"white"}
                p={3}
                w={["100%", "150px"]}
                cursor={"pointer"}
                h={"80%"}
                onClick={() => {
                  setStatusFilterToggle((prev) => !prev);
                }}
              >
                <Text>
                  {filterData.status == "" ? "Status" : filterData.status}
                </Text>
                <MdArrowDropDown fontSize={"24px"} />
              </Flex>

              {statusFilterToggle && (
                <Box
                  position={"absolute"}
                  top={"80%"}
                  w={["100%", "150px"]}
                  zIndex={1}
                >
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, status: "paid" });
                      setStatusFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Paid</Text>
                  </Flex>
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, status: "pending" });
                      setStatusFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Pending</Text>
                  </Flex>
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, status: "declined" });
                      setStatusFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Declined</Text>
                  </Flex>
                  <Flex
                    bgColor={"#fff"}
                    w={["100%", "150px"]}
                    p={3}
                    cursor={"pointer"}
                    onClick={() => {
                      setfilterData({ ...filterData, status: "overdue" });
                      setStatusFilterToggle((prev) => !prev);
                    }}
                  >
                    <Text>Overdue</Text>
                  </Flex>
                </Box>
              )}
            </Flex>
            {/* <Select
              w={{ base: "100%", md: "120px" }}
              bg="white"
              placeholder="Date"
              cursor={"pointer"}
              value={filterData.date}
              onChange={(e) =>
                setfilterData({ ...filterData, date: e.target.value })
              }
              icon={<MdArrowDropDown />}
            >
              <option value="last_day">Last Day</option>
              <option value="last_30_days">Last 30 Days</option>
              <option value="last_quater">Last Quarter</option>
              <option value="last_year">Last Year</option>
            </Select> */}

            {/* <Select
              w={{ base: "100%", md: "120px" }}
              bg="white"
              placeholder="Status"
              cursor={"pointer"}
              value={filterData.status}
              onChange={(e) =>
                setfilterData({ ...filterData, status: e.target.value })
              }
              icon={<MdArrowDropDown />}
            >
              <option value="paid">Paid</option>
              <option value="pending">Pending</option>
              <option value="declined">Declined</option>
              <option value="overdue">Overdue</option>
            </Select> */}
          </Flex>
        </Box>
      )}

      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          h="40vh"
          alignItems="center"
          pt="20px"
        >
          <Image className="loading" src="/assets/logo.svg" />
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="70px"
            color="#000000"
            mt="-15px"
          >
            Loading Commissions Table...
          </Text>
        </Box>
      ) : (
        <CommissionsTable
          commissions={!ifFilterExists() ? commissions : filteredCommissons}
          highlighted={highlighted}
          sethighlighted={sethighlighted}
          highlighted2={highlighted2}
          sethighlighted2={sethighlighted2}
          handleViewAff={handleViewAffiliate}
        />
      )}

      {noResult().result && (
        <Box
          w="100%"
          height="30vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={{ base: "0px 16px", md: "0px" }}
          mb="21px"
        >
          <Image
            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
            height="180px"
            objectFit="contain"
            mb="16px"
          />

          <Text
            fontWeight="600"
            fontSize="12px"
            lineHeight="22px"
            color="#32325D"
            textAlign="center"
          >
            {noResult().reason}
          </Text>
        </Box>
      )}
    </Stack>
  );
};

export default Commissions;
