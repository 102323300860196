import { Flex, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import React from "react";

const ReferSignificance = () => {
  return (
    <Flex
      alignItems="center"
      px={{ base: "14px", md: "40px" }}
      mt="30px"
      flexDirection="column"
    >
      <Text
        fontSize="24px"
        lineHeight="38px"
        mb="50px"
        fontWeight="600"
        w="max-content"
        color="#32325D"
      >
        Why Should you Jump At this?
      </Text>

      <Grid
        w="full"
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
        gap="20px"
      >
        <GridItem
          bgColor="white"
          borderRadius="24px"
          p="40px"
          boxShadow="0px 0px 30px rgba(50, 50, 93, 0.06)"
        >
          <Image h="34px" w="34px" src="/assets/png/refer-assets.png" />

          <Text
            fontSize="18px"
            lineHeight="32px"
            fontWeight="500"
            color="#32325D"
            mt="20px"
            mb="8px"
          >
            Marketing assets
          </Text>

          <Text
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            color="#32325D"
          >
            Easy to download promotional tools such as banners, coupons, links,
            videos and documents to aid marketing.
          </Text>
        </GridItem>

        <GridItem
          bgColor="white"
          borderRadius="24px"
          p="40px"
          boxShadow="0px 0px 30px rgba(50, 50, 93, 0.06)"
        >
          <Image h="34px" w="34px" src="/assets/png/refer-commission.png" />

          <Text
            fontSize="18px"
            lineHeight="32px"
            fontWeight="500"
            color="#32325D"
            mt="20px"
            mb="8px"
          >
            20% recurring commission
          </Text>

          <Text
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            color="#32325D"
          >
            Get a 20% commission for every referral, and get an additional 20%
            commission for every recurring payment.
          </Text>
        </GridItem>

        <GridItem
          bgColor="white"
          borderRadius="24px"
          p="40px"
          boxShadow="0px 0px 30px rgba(50, 50, 93, 0.06)"
        >
          <Image h="34px" w="34px" src="/assets/png/refer-support.png" />

          <Text
            fontSize="18px"
            lineHeight="32px"
            fontWeight="500"
            color="#32325D"
            mt="20px"
            mb="8px"
          >
            Round-the-clock support
          </Text>

          <Text
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            color="#32325D"
          >
            Get direct access to an affiliate manager to answer all your
            questions and provide support and directions when you need them.
          </Text>
        </GridItem>
      </Grid>
    </Flex>
  );
};

export default ReferSignificance;
