import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const initialState: any = {};
const { persistAtom } = recoilPersist();
export const campaignState = atom({
  key: "campaignState",
  default: initialState,
  effects_UNSTABLE: [persistAtom],
});

export const campaignNavItems = atom({
  key: "campaignStateTest",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
