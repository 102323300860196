import React, { useRef } from "react";
import {
  Box,
  Image,
  Text,
  Button,
  useToast,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const ProductId = ({
  productId,
  affiliateRef,
}: {
  productId: any[];
  affiliateRef: string | undefined | null;
}) => {
  const swiperRef = useRef<any>();
  const toast = useToast();

  const CopyText = async (text: string) => {
    if (!text) return;

    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const handleCopyProductLink = (link: string, Id: string) => {
    CopyText(link)
      .then(() => {
        toast({
          title: "Copied",
          description:
            'The link for Product ID "' + Id + '" copied to clipboard',
          status: "info",
          duration: 3000,
          position: "top-right",
        });
      })
      .catch(() => {
        toast({
          title: "Copied",
          description: "Failed to copy",
          status: "info",
          duration: 3000,
          position: "top-right",
        });
      });
  };

  return (
    <>
      {/* @ts-ignore */}

      <Box
        margin="18px 0px"
        background="#FFFFFF"
        width="100%"
        height="152px"
        padding={{ base: "16px 0px", md: "16px 27px" }}
      >
        {productId.length !== 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb="20px"
          >
            <Box display="flex" alignItems="center">
              <Text
                fontWeight="500"
                fontSize="12px"
                color="#32325D"
                lineHeight="18px"
                opacity="0.8"
                letterSpacing="0.02em"
                textTransform="uppercase"
              >
                Product Links
              </Text>
            </Box>

            <Box display={{ base: "none", md: "flex" }} alignItems="center">
              <Button
                background="#FFFFFF"
                boxShadow="0px 0px 7.42829px rgba(114, 17, 212, 0.25)"
                borderRadius=" 4.64268px"
                width="32px"
                height="28px"
                onClick={() => swiperRef.current?.slidePrev()}
              >
                <Image
                  transform="rotate(180deg)"
                  src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/67853fcc-a95e-4476-be48-14434b51b27d.svg`}
                />
              </Button>
              <Button
                background="#FFFFFF"
                boxShadow="0px 0px 7.42829px rgba(114, 17, 212, 0.25)"
                borderRadius=" 4.64268px"
                width="32px"
                height="28px"
                ml="18px"
                onClick={() => swiperRef.current?.slideNext()}
              >
                <Image
                  src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/67853fcc-a95e-4476-be48-14434b51b27d.svg`}
                />
              </Button>
            </Box>
          </Box>
        )}

        <Box display={{ base: "block", md: "none" }}>
          <Grid templateColumns="repeat(1, 1fr)" gap="10px">
            {productId?.map((item: any, index: number) => {
              return (
                <GridItem key={index}>
                  <Box
                    display="flex"
                    alignItems="center"
                    background="#FFFFFF"
                    padding="3px 8px"
                    width="100%"
                  >
                    <Text
                      fontSize="14px"
                      fontWeight="500"
                      lineHeight="21px"
                      color="#32325D"
                    >
                      {index < 10 && "0"}
                      {index + 1}
                    </Text>

                    <Box
                      border="0.6px solid #7211D4"
                      p="8px 12px"
                      borderRadius="6px"
                      ml="4px"
                      mr="10px"
                      flex="1"
                    >
                      <Text
                        fontWeight="400"
                        fontSize="12px"
                        color=" #32325D"
                        bgColor="#F0F5FF"
                        lineHeight="18px"
                        p="4px 14px"
                      >
                        {item?.id}
                      </Text>
                    </Box>

                    <Image
                      cursor="pointer"
                      src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/e107144c-810b-4130-b98e-15c142b0dff3.svg"
                      alt="copy"
                      h="24px"
                      w="24px"
                      onClick={() =>
                        handleCopyProductLink(
                          item.link +
                            `${affiliateRef ? `?ref=${affiliateRef}` : ""}`,
                          item.id
                        )
                      }
                    />
                  </Box>
                </GridItem>
              );
            })}
          </Grid>
        </Box>

        <Box display={{ base: "none", md: "block" }} position="relative">
          <Swiper
            spaceBetween={25}
            breakpoints={{
              // when window width is >= 370px
              370: {
                slidesPerView: 1.5,
              },
              // when window width is >= 400px
              460: {
                slidesPerView: 2.4,
              },
              // when window width is >= 640px
              640: {
                slidesPerView: 2.8,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 3.4,
              },
              // when window width is >= 900px
              900: {
                slidesPerView: 4,
              },
              // when window width is >= 1024px
              1024: {
                slidesPerView: 4,
              },
              // when window width is >= 1200px
              1200: {
                slidesPerView: 4,
              },
              // when window width is >= 1500px
              1500: {
                slidesPerView: 5.5,
              },
            }}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {productId &&
              productId?.map((item: any, index: number) => {
                return (
                  <SwiperSlide key={index}>
                    <Box
                      display="flex"
                      alignItems="center"
                      background="#FFFFFF"
                      padding="3px 8px"
                      width="100%"
                    >
                      <Text
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="21px"
                        color="#32325D"
                      >
                        {index < 10 && "0"}
                        {index + 1}
                      </Text>

                      <Box
                        border="0.6px solid #7211D4"
                        p="8px 12px"
                        borderRadius="6px"
                        ml="4px"
                        mr="10px"
                        flex="1"
                      >
                        <Text
                          fontWeight="400"
                          fontSize="12px"
                          color=" #32325D"
                          bgColor="#F0F5FF"
                          lineHeight="18px"
                          p="4px 14px"
                        >
                          {item?.id}
                        </Text>
                      </Box>

                      <Image
                        cursor="pointer"
                        src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/e107144c-810b-4130-b98e-15c142b0dff3.svg"
                        alt="copy"
                        h="24px"
                        w="24px"
                        onClick={() =>
                          handleCopyProductLink(
                            item.link +
                              `${affiliateRef ? `?ref=${affiliateRef}` : ""}`,
                            item.id
                          )
                        }
                      />
                    </Box>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </Box>
      </Box>
    </>
  );
};

export default ProductId;
