import {
  Box,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";

interface PropSchema {
  label?: string;
  type: string;
  value: any;
  preventSpaces?: boolean;
  stateHandler?: React.Dispatch<React.SetStateAction<any>>;
  changeEventHandler?: Function;
  holder?: string;
  holderSpecific?: string;
  helperText?: string;
  inputNotValid?: boolean;
  leftElement?: { content: any; color: string; bgColor: string };
  rightElement?: {
    content: string;
    color: string;
    bgColor: string;
    width?: string;
  };
  rightAddon?: string;
  required?: boolean;
  options?: any[];
  paddingLeft?: string;
  maxChar?: number;
  minNumValue?: string;
  bgColor?: string;
  [x: string]: any;
}

export const FormInput = ({
  label,
  holder,
  holderSpecific,
  type,
  value,
  leftElement,
  rightElement,
  required,
  options,
  helperText,
  preventSpaces,
  inputNotValid,
  stateHandler,
  changeEventHandler,
  paddingLeft,
  maxChar,
  minNumValue,
  bgColor,
  ...rest
}: PropSchema) => {
  const [showPassword, setShowPassword] = useState(false);
  const [focused, setFocused] = React.useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const showHandler = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box w="100%" my={label ? "1.9em" : "0.5em"}>
      {type !== "select" ? (
        <FormControl isRequired={required ? required : false}>
          <Flex alignItems="center" justifyContent="space-between">
            {label && (
              <FormLabel
                htmlFor={label ? label.split(" ").join("").toLowerCase() : ""}
                fontSize="15px"
                fontWeight={focused ? "medium" : "normal"}
                color={focused ? " #5C14CE" : "#737373"}
              >
                {label}
              </FormLabel>
            )}
            {maxChar && (
              <Text fontSize="0.8em">
                <span style={{ color: "#777777" }}>{value.length}</span>/
                {maxChar}
              </Text>
            )}
          </Flex>
          <InputGroup alignItems="center">
            {leftElement && (
              <InputLeftElement
                bgColor={leftElement.bgColor}
                color={leftElement.color}
                children={leftElement.content}
                // borderRadius="0.5em"

                width={`${leftElement.content.length / 2}em`}
                fontSize={["0.6em", "0.6em", "0.9em"]}
                fontWeight="medium"
                px={leftElement.content.length > 10 ? "4em" : "3em"}
                mt="5px"
                ml="0.5em"
              />
            )}
            <Input
              {...rest}
              style={{ borderRadius: "0" }}
              min={minNumValue ? minNumValue : "0"}
              maxLength={maxChar ? maxChar : 524288}
              type={showPassword ? "text" : type}
              id={label ? label.split(" ").join("").toLowerCase() : ""}
              value={value}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder={holderSpecific && holderSpecific}
              bgColor={bgColor}
              variant="flushed"
              onChange={
                changeEventHandler && !stateHandler
                  ? (e: React.ChangeEvent<any>) => {
                      preventSpaces
                        ? changeEventHandler(e.target.value.replace(/\s/g, ""))
                        : changeEventHandler(e.target.value);
                    }
                  : stateHandler && !changeEventHandler
                  ? (e: React.ChangeEvent<any>) => {
                      preventSpaces
                        ? stateHandler(e.target.value.replace(/\s/g, ""))
                        : stateHandler(e.target.value);
                    }
                  : () => {
                      // do nothing
                    }
              }
              onKeyDown={(e: React.KeyboardEvent<any>) => {
                // @ts-ignore
                if (e.keyCode === 38 || e.keyCode === 40) {
                  e.preventDefault();
                }
              }}
              py="1.5em"
              pr={rightElement && `${rightElement.content.length - 3.5}em`}
              // borderRadius="0.6em"
              focusBorderColor={inputNotValid ? "red.300" : "#5C14CE"}
              border="none"
              borderBottom="1.5px solid #B6BDC3"
              errorBorderColor="red.300"
              paddingLeft="0"
              paddingRight="0"
              color="#000"
              fontSize="15px"
              fontWeight="500"
              isInvalid={inputNotValid ? inputNotValid : false}
            />
            {type === "password" && !rightElement && (
              <InputRightElement
                cursor="pointer"
                onClick={showHandler}
                height="100%"
                children={showPassword ? <IoEyeSharp /> : <IoEyeOffSharp />}
              />
            )}

            {rightElement && type !== "password" && (
              <InputRightElement
                bgColor={rightElement.bgColor}
                color={rightElement.color}
                children={rightElement.content}
                // borderRadius="0.5em"
                width={
                  rightElement.width
                    ? rightElement.width
                    : `${rightElement.content.length / 2}em`
                }
                fontSize={["0.6em", "0.6em", "0.9em"]}
                fontWeight="medium"
                px={rightElement.content.length > 10 ? "4em" : "0em"}
                mt="6px"
                mr="0.5em"
                display="flex"
                alignItems="center"
                flexDirection="column"
                height="80%"
              />
            )}
          </InputGroup>

          <FormHelperText fontStyle="italic">{helperText}</FormHelperText>
        </FormControl>
      ) : (
        <FormControl isRequired={required ? required : false}>
          <FormLabel
            htmlFor={label ? label.split(" ").join("").toLowerCase() : ""}
            fontSize="15px"
            fontWeight={focused ? "medium" : "normal"}
            color={focused ? " #5C14CE" : "#737373"}
          >
            {label}
          </FormLabel>
          <Select
            placeholder={holder ? holder : "Select option"}
            value={value}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(e: React.ChangeEvent<any>) =>
              changeEventHandler && !stateHandler
                ? changeEventHandler(e.target.value)
                : stateHandler && stateHandler(() => e.target.value)
            }
            border="0"
            // borderColor="#010947"
            style={{ paddingLeft: "0em" }}
            borderBottom="1.5px solid #B6BDC3"
            isInvalid={inputNotValid ? inputNotValid : false}
            errorBorderColor="red.300"
            borderRadius="1px"
            fontWeight="medium"
            variant="flushed"
            color="#000"
            focusBorderColor={inputNotValid ? "red.300" : "#5C14CE"}
          >
            {options ? (
              options.map((option, i) => {
                return (
                  <option key={i} value={option}>
                    {option}
                  </option>
                );
              })
            ) : (
              <option>No option available</option>
            )}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};
