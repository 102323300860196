import { useState } from "react";

export const useConvertToBs64 = () => {
  const [bs64Version, setBs64Version]: any = useState(null);
  const [error, setError]: any = useState(null);
  const convertFile = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (err) => reject(err);
    });
  };

  return { convertFile };
};
