import {
  Box,
  Grid,
  GridItem,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Stack,
  Text,
  Spinner,
  Flex,
  Circle,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import useUserToken from "../../../../../../hooks/useUserToken";
import PaginationComponent from "../../../../../explore/affiliates/paginationWrapper";
import AssetFolder from "./AssetFolder";
import Fuse from "fuse.js";
import { MdChevronRight, MdKeyboardBackspace } from "react-icons/md";
import Iframe from "react-iframe";
import { useNavigate } from "react-router-dom";
import { endpoints } from "../../../../../../utils/endpoints";
import { FaFilePdf } from "react-icons/fa";
import { AiFillFile, AiFillFileWord } from "react-icons/ai";
import { BsFiletypeDoc } from "react-icons/bs";
import { SlOptions } from "react-icons/sl";
import { apiReqHandler } from "../../../../../../helper/apiReqHandler";
import { getCookie } from "cookies-next";

const Assets = ({ campaignId }: { campaignId: string | undefined }) => {
  const token = useUserToken("affiliate");
  // console.log(campaignId);
  const [searchVal, setSearchVal] = useState<string>("");
  const [paginationData, setpaginationData] = useState({
    currentPage: 1,
    pageSize: 8,
  });
  const [fetchedFolders, setfetchedFolders] = useState<any[]>([]);
  const [paginatedFolders, setpaginatedFolders] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedFolder, setselectedFolder] = useState<any>(null);
  const [folderAssets, setfolderAssets] = useState<any>([]);
  const [isUploadingFile, setisUploadingFile] = useState<boolean>(false);
  const navigate = useNavigate();

  const options = {
    keys: ["folderName"],
  };

  const fuse = new Fuse(fetchedFolders, options);

  const genColor = () => {
    const value: number = Math.floor(Math.random() * 4);

    if (value === 0) {
      return "rgba(246, 173, 0, 1)";
    } else if (value === 1) {
      return "rgba(72, 118, 249, 1)";
    } else if (value === 2) {
      return "rgba(50, 189, 122, 1)";
    } else {
      return "rgba(250, 78, 78, 1)";
    }
  };
  function endsWithExtension(url: any, extension: any) {
    return url.toLowerCase().endsWith(extension);
  }

  const FetchAllCampaignFolders = () => {
    let bearerAuth = token;

    setLoading(true);

    // @ts-ignore
    fetch(endpoints.drive.NEWFOLDER.ALL(campaignId), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setfetchedFolders(data.data);
          setLoading(false);
        } else {
          // formViewRef.current.scrollIntoView();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("get campaign => " + err);
        setLoading(false);
      });
  };

  useEffect(() => {
    FetchAllCampaignFolders();
  }, []);

  useEffect(() => {
    if (campaignId) {
      let startIndex =
        (paginationData.currentPage - 1) * paginationData.pageSize;
      let endIndex = startIndex + paginationData.pageSize;

      if (searchVal) {
        setpaginatedFolders(
          fuse
            .search(searchVal)
            .map((item) => {
              return item.item;
            })
            .filter((item, index) => index >= startIndex && index < endIndex)
        );
      } else {
        setpaginatedFolders(
          fetchedFolders.filter(
            (item, index) => index >= startIndex && index < endIndex
          )
        );
      }
      setLoading(false);
    }
  }, [fetchedFolders, searchVal, paginationData]);

  const checkForNoResult = () => {
    if (!fetchedFolders.length && !loading) return true;
    if (searchVal && !paginatedFolders.length) return true;
    return false;
  };

  const fetchAssets = async (folder: any) => {
    setisUploadingFile(true);
    const { error, res } = await apiReqHandler({
      endPoint: endpoints.drive.NEWFOLDER.FOLDERASSETS(
        folder.campaignId,
        folder.id
      ),
      method: "GET",
      bearerAuth: token,
    });
    setfolderAssets(res?.data.data.folder.folderAssets || []);
    setisUploadingFile(false);
  };

  return (
    <Stack pb="100px">
      {selectedFolder && (
        <Box
          display={{ base: "flex", md: "none" }}
          alignItems="center"
          cursor="pointer"
          mb="18px"
          onClick={() => setselectedFolder(null)}
        >
          <MdKeyboardBackspace color="#32325D" />
          <Text
            color="#7211D4"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            pl="8px"
          >
            Back
          </Text>
        </Box>
      )}

      <Box
        mt={{ base: "0px", md: "32px" }}
        borderBottom="0.5px solid rgba(50, 50, 93, 0.3)"
        mb={{ base: "16px", md: "34px" }}
        pb="21px"
        display="flex"
        alignItems={{ base: "start", md: "center" }}
        flexDirection={{ base: "column", md: "row" }}
        justifyContent={selectedFolder ? "space-between" : "start"}
      >
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          alignItems={{ base: "start", md: "center" }}
        >
          <HStack
            spacing="8px"
            mr={{ base: "0px", md: "8px" }}
            mb={{ base: "21px", md: "0px" }}
          >
            <Text
              color={selectedFolder ? "rgba(50, 50, 93, 0.5)" : "#32325D"}
              fontSize="14px"
              fontWeight={selectedFolder ? "500" : "500"}
              lineHeight="21px"
              cursor={selectedFolder ? "pointer" : "default"}
              _hover={{
                textDecoration: `${selectedFolder ? "underline" : "none"}`,
              }}
              onClick={() => setselectedFolder(null)}
            >
              All Folders
            </Text>

            {selectedFolder && (
              <MdChevronRight size={20} color="rgba(50, 50, 93, 0.5)" />
            )}

            {selectedFolder && (
              <Text
                fontSize="14px"
                fontWeight="600"
                lineHeight="21px"
                color="#32325D"
              >
                {selectedFolder.folderName}
              </Text>
            )}
          </HStack>

          {!selectedFolder && (
            <InputGroup
              w={{ base: "100%", md: "280px" }}
              ml={{ base: "0px", md: "26px" }}
            >
              <InputLeftElement
                pointerEvents="none"
                children={<RiSearchLine color="rgba(50, 50, 93, 0.8)" />}
              />
              <Input
                css={{
                  border: "0.5px solid rgba(50, 50, 93, 0.4)",
                }}
                type="text"
                value={searchVal}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchVal(e.target.value)
                }
                fontSize="16px"
                borderRadius={{ base: "100px", md: "8px" }}
                bg={{ base: "white", md: "transparent" }}
                focusBorderColor="#7211D4"
                placeholder="Search folders"
              />
            </InputGroup>
          )}
        </Box>

        {/* {selectedFolder && (
          <Link
            bgColor="transparent"
            p="8px 15px"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight={{ base: "18px", md: "21px" }}
            fontWeight="500"
            color="rgba(50, 50, 93, 1)"
            borderRadius="8px"
            border="0.5px solid rgba(50, 50, 93, 0.4)"
            textAlign="center"
            target="_blank"
            href={selectedFolder.drive_folder}
            _hover={{ textDecoration: "none", color: "rgba(50, 50, 93, 1)" }}
          >
            View in Google Drive
          </Link>
        )} */}
      </Box>

      {selectedFolder ? (
        <Box>
          {/* @ts-ignore */}
          {isUploadingFile ? (
            <Spinner
              size="xl"
              color="purple.500"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
            />
          ) : (
            <Flex direction={"row"} w={"100%"} gap={5} flexWrap={"wrap"}>
              {/* @ts-ignore */}
              {folderAssets.map((item: any, i: any) => (
                <Flex
                  key={i}
                  width={300}
                  borderWidth={1}
                  borderColor={"#c4c4c4c4"}
                  borderRadius={10}
                  align={"center"}
                  justify={"center"}
                  py={5}
                  direction={"column"}
                  gap={5}
                >
                  <Flex
                    width={260}
                    borderWidth={1}
                    borderColor={"#c4c4c4c4"}
                    borderRadius={10}
                    align={"center"}
                    justify={"center"}
                    h={120}
                    overflow={"hidden"}
                    cursor={"pointer"}
                  >
                    {/* @ts-ignore */}
                    {endsWithExtension(item.url, "png") ? (
                      <Image
                        // @ts-ignore
                        src={item.url}
                        w={"auto"}
                        objectFit={"cover"}
                        // @ts-ignore
                        onClick={() => window.open(item.url, "_blank")}
                      />
                    ) : //  @ts-ignore
                    endsWithExtension(item.url, "document") ? (
                      <AiFillFileWord
                        size={100}
                        color="#5C14CE"
                        // @ts-ignore
                        onClick={() => window.open(item.url, "_blank")}
                        cursor={"pointer"}
                      />
                    ) : //  @ts-ignore
                    endsWithExtension(item.url, "pdf") ? (
                      <FaFilePdf
                        size={100}
                        color="#5C14CE"
                        // @ts-ignore
                        onClick={() => window.open(item.url, "_blank")}
                        cursor={"pointer"}
                      />
                    ) : (
                      <AiFillFile
                        size={100}
                        color="#5C14CE"
                        // @ts-ignore
                        onClick={() => window.open(item.url, "_blank")}
                        cursor={"pointer"}
                      />
                    )}
                  </Flex>
                  <Flex width={260} direction={"column"}>
                    <Flex gap={2} align={"baseline"}>
                      <BsFiletypeDoc
                        size={14}
                        color="#5C14CE"
                        fontWeight={"bold"}
                      />
                      {/* @ts-ignore */}
                      <Text fontWeight={"bold"}>{item.id}</Text>
                    </Flex>
                    <Flex justify={"space-between"} align={"center"}>
                      <Flex gap={2} direction={"column"}>
                        <Text fontWeight={"bold"}>File size</Text>
                        {/* @ts-ignore */}
                        <Text>3.4mb</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          )}
        </Box>
      ) : (
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(4, 1fr)" }}
          gap={15}
          pb="21px"
        >
          {paginatedFolders.map((folder: any, i: any) => {
            return (
              <GridItem
                p="18px"
                cursor="pointer"
                borderRadius="8px"
                border="0.5px solid rgba(179, 179, 179, 1)"
                onClick={() => {
                  setselectedFolder(folder);
                  fetchAssets(folder);
                }}
                key={i}
              >
                <AssetFolder
                  color={genColor()}
                  title={folder?.folderName}
                  numberOfFiles={folder?.folderAssets.length}
                />
              </GridItem>
            );
          })}
        </Grid>
      )}

      {checkForNoResult() && (
        <Box
          w="100%"
          height="40vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={{ base: "0px 16px", md: "0px" }}
          mb="21px"
        >
          <Image
            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
            height="250px"
            objectFit="contain"
            mb="16px"
          />

          <Text
            fontWeight="600"
            fontSize="14px"
            lineHeight="21px"
            color="#32325D"
            textAlign="center"
          >
            {searchVal
              ? "No Results Found For Folder Named: " + searchVal
              : "No data available yet, when you have assets you will see them here."}
          </Text>
        </Box>
      )}

      {/* {!selectedFolder && (
        <PaginationComponent
          paginationData={paginationData}
          setPaginationData={setpaginationData}
          dataLength={
            searchVal ? paginatedFolders.length : fetchedFolders.length
          }
        />
      )} */}
    </Stack>
  );
};

export default Assets;
