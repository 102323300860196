import {
  Box,
  Container,
  Image,
  Stack,
  Heading,
  Text,
  Flex,
  useMediaQuery,
  Progress,
  extendTheme,
  Divider,
} from "@chakra-ui/react";

import { StepsNav } from "../steps-nav";
import ProgressBar from "../ProgressBar/ProgressBar";

export const StepWrapper = ({
  step,
  header,
  baseText,
  children,
  stepsMap,
}: {
  step: any;
  header: string;
  baseText: string;
  children: JSX.Element[] | JSX.Element;
  stepsMap: { title: string; status: string; key: number }[];
}) => {
  const [isDesktop] = useMediaQuery("(min-width: 768px)");

  return (
    <Stack
      height="100vh"
      direction={["column", "row"]}
      justifyContent="space-between"
      // maxW={"100%"}
    >
      <Box
        display={["none", "flex"]}
        width="30%"
        position="fixed"
        minHeight="100vh"
        overflow="hidden"
        // justifyContent="center"
        flexDirection="column"
        alignItems="center"
        textAlign="left"
        bgColor="#F0F5FF"
      >
        <Container maxW="container.xl" pl={["2em", "3em", "55px"]}>
          <Image
            src="/assets/logo.png"
            mt="49px"
            mb="20vh"
            height="45px"
            alignSelf="flex-start"
          />

          <StepsNav steps={stepsMap} />
        </Container>
      </Box>

      <Flex justify={"center"}>
        <Box
          width={["100%", "60%"]}
          position={["static", "absolute"]}
          right="0"
        >
          <Box
            bgColor="#F0F5FF"
            py=".5em"
            mt="-1em"
            display={["block", "none"]}
            px={[".8em", ""]}
          >
            <Image
              src="/assets/logo.png"
              mt="2em"
              height="40px"
              mb=".5em"
              alignSelf="flex-start"
            />
            {isDesktop && <StepsNav steps={stepsMap} />}
          </Box>

          <Flex
            pt={["0em", "4em"]}
            direction={"column"}
            justify={"center"}
            align={["center", "flex-start"]}
            w={["100%", "65%"]}
            // backgroundColor={"red"}
          >
            <Flex mb={["1em", "4em"]} direction={"column"} w={"90%"}>
              {isDesktop && (
                <Heading
                  fontSize="1.em"
                  as="h5"
                  fontWeight="medium"
                  color="gray.400"
                  mt="49px"
                  mb="4em"
                >
                  {step}
                </Heading>
              )}

              {!isDesktop && (
                <Flex my={5} w={"100%"}>
                  <ProgressBar steps={stepsMap} />
                </Flex>
              )}
              <Heading
                fontSize={{ base: "1.15em", md: "2em" }}
                lineHeight={{ base: "35px", md: "40px" }}
                color="#000"
                fontWeight="bold"
                mb={{ base: "19px", md: "10px" }}
              >
                {header}
              </Heading>
              <Text
                color="#737373"
                fontSize={{ base: "0.9em", md: "1em" }}
                lineHeight="26px"
                fontWeight="normal"
              >
                {baseText}
              </Text>
            </Flex>

            {!isDesktop && (
              <Divider
                orientation="horizontal"
                mb={"2em"}
                variant={"dashed"}
                color={"#c4c4c4"}
              />
            )}
          </Flex>
          <Flex justify={["center", "flex-start"]}>{children}</Flex>
        </Box>
      </Flex>
    </Stack>
  );
};
