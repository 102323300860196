import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { BsCheckSquareFill, BsFillCaretUpFill, BsSearch } from "react-icons/bs";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { MdCancel, MdKeyboardBackspace, MdOutlineAddBox } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ManagersTable from "./components/ManagersTable";
import ManagersForm from "./components/ManagersForm";
import { useOutsideAlerter } from "../../hooks/useOutsideAlerter";
import useUserToken from "../../hooks/useUserToken";

type ManagerType = {
  id: number;
  campaignName: string;
  firstName: string;
  lastName: string;
  email: string;
  signupDate: string;
  lastActiveDate: string;
  status: string;
  campaignIds: any[];
};

const Managers = () => {
  const navigate = useNavigate();
  const [showIndustries, setShowIndustries] = useState(false);
  useOutsideAlerter;
  const toggleHandler = () => {
    return setShowIndustries(!showIndustries);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [refreshField, setrefreshField] = useState(0);
  const [campaigns, setcampaigns] = useState<any[]>([]);
  const [managers, setmanagers] = useState<any[]>([]);
  const [filteredManagers, setfilteredManagers] = useState<any[]>([]);
  const [loading, setloading] = useState(false);

  const {
    isOpen: isOpenManagerModal,
    onOpen: onOpenManagerModal,
    onClose: onCloseManagerModal,
  } = useDisclosure();

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setShowIndustries(false));
  const token = useUserToken();

  const getCampaigns = () => {
    let bearerAuth = token;
    fetch(process.env.REACT_APP_API_ENDPOINT + "campaign/fetch-campaigns", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("all camps", data);
        if (data.success) {
          setcampaigns(
            data.data.campaigns.map((item: any) => ({
              name: item?.campaignName || "",
              id: item?.campaignId || "",
            }))
          );
        }
      })
      .catch((err) => {
        console.log("get campaign => " + err);
      });
  };

  const getManagers = () => {
    let bearerAuth = token;
    setloading(true);

    fetch(process.env.REACT_APP_API_ENDPOINT + `/all/users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("managers", data);
        if (data.success) {
          setmanagers(
            data.data.users.map((user: any) => {
              return {
                id: user.id,
                campaignName:
                  user.campaigns && user.campaigns?.length
                    ? user.campaigns.join(", ")
                    : "",
                firstName: user.firstname,
                lastName: user.lastname,
                email: user.email,
                signupDate: user.createdAt,
                lastActiveDate: user.last_login,
                status: user.role
                  ? user.role.role_name.split("_").join(" ")
                  : "",
                permissionId: user.permissionId || 0,
                invitedBy: user.invitedBy || 0,
                campaignIds: user.campaignIds || [],
              };
            })
          );
        }
        setloading(false);
      })
      .catch((err) => {
        console.log("get campaign => " + err);
        setloading(false);
      });
  };

  const filterManagers = () => {
    let filtered: any[] = [];
    let exists: { [key: string]: boolean } = {};
    selectedItem.forEach((item: any) => {
      managers.forEach((manager: ManagerType) => {
        if (manager && manager.status === "admin") {
          filtered.push(manager);
          exists[`${manager.id}`] = true;
        }
        if (
          manager &&
          manager.campaignIds.includes(item.id) &&
          !exists[`${manager.id}`]
        ) {
          filtered.push(manager);
          exists[`${manager.id}`] = true;
        }
      });
    });
    return filtered;
  };

  const filterExist = () => {
    let selectedCampaigns = campaigns.filter((item) => item.selected);

    if (selectedItem?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const toast = useToast();

  const openAddNewModal = () => {
    if (loading) return;
    if (!loading && campaigns?.length === 0)
      return toast({
        title:
          "You have to be approved to at least one campaigns to add managers",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });

    onOpenManagerModal();
  };
  const [selectedItem, selectedItems]: any = useState([]);
  useEffect(() => {
    setfilteredManagers(filterManagers());
  }, [selectedItem]);

  useEffect(() => {
    getManagers();
  }, [refreshField]);

  useEffect(() => {
    getCampaigns();
  }, []);

  const handleSelect = (item: any) => {
    console.log("selectedCamp", selectedItem), item;
    // const isSelected = selectedItem.includes(item);
    const isSelected = selectedItem.some(
      (selectedItemData: any) => selectedItemData.id === item.id
    );
    // setSelectAll(false);
    // console.log(isSelected);
    if (isSelected) {
      // If item is already selected, remove it from the selectedItems array
      selectedItems((prevSelectedItems: any) =>
        prevSelectedItems.filter(
          (selectedItem: any) => selectedItem.id !== item.id
        )
      );
    } else {
      // If item is not selected, add it to the selectedItems array
      selectedItems((prevSelectedItems: any) => [...prevSelectedItems, item]);
    }
  };

  return (
    <Box mt="30px" bgColor="white" p="25px 30px" minH="80vh">
      <Box
        display="flex"
        alignItems="center"
        pb="16px"
        borderBottom="1px solid rgba(50, 50, 93, 0.30)"
      >
        <Flex
          onClick={() => navigate(`/settings`)}
          align={"center"}
          cursor="pointer"
        >
          <MdKeyboardBackspace color="#32325D" />
          <Text
            color="#32325D"
            lineHeight="40px"
            fontSize="16px"
            fontWeight="500"
            ml="8px"
          >
            Back to the previous page
          </Text>
        </Flex>
      </Box>

      <Box display="flex" alignItems="center" py="25px">
        <Button
          bgColor="rgba(114, 17, 212, 0.06)"
          border="0.5px solid rgba(50, 50, 93, 0.20)"
          color="rgba(50, 50, 93, 0.80)"
          borderRadius="8px"
          mr="17px"
          onClick={openAddNewModal}
          w={"250px"}
        >
          <MdOutlineAddBox fontSize={15} />
          <Text
            fontSize={{ base: "12px", md: "14px" }}
            fontWeight="500"
            lineHeight={{ base: "18px", md: "21px" }}
            ml="7px"
          >
            Add New Manager
          </Text>
        </Button>

        <Box position="relative">
          <Flex w="100%" columnGap=".5em" alignItems="center">
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              border="0.6px solid"
              borderColor="rgba(50, 50, 93, 0.30)"
              borderRadius="8px"
              p=".6em"
              cursor={"pointer"}
              onClick={toggleHandler}
              ref={wrapperRef}
            >
              <Flex columnGap=".5em" mr="10px" rowGap=".5em" flexWrap="wrap">
                {selectedItem.length >= 1 ? (
                  selectedItem.map((campaign: any, i: any) => {
                    return (
                      <Box
                        key={i}
                        bgColor="#F0F5FF"
                        py={["3px", "3px", "6px"]}
                        px=".4em"
                        alignItems="flex-start"
                        display="flex"
                        borderRadius="4px"
                      >
                        <Text
                          mr=".8em"
                          fontSize="14px"
                          fontWeight="400"
                          color="gray.800"
                        >
                          {campaign.name}
                        </Text>
                        <MdCancel
                          color="#000"
                          cursor="pointer"
                          onClick={() => {
                            // const newState = campaigns.map(
                            //   (data: any, x: any) => {
                            //     if (campaigns[i] == data) {
                            //       return { ...data, selected: false };
                            //     } else {
                            //       return data;
                            //     }
                            //   }
                            // );
                            // setcampaigns(newState);
                            handleSelect(campaign);
                          }}
                        />
                      </Box>
                    );
                  })
                ) : (
                  <Text>Campaigns</Text>
                )}
              </Flex>

              <Box>
                {!showIndustries ? (
                  <IoChevronDown cursor={"pointer"} onClick={toggleHandler} />
                ) : (
                  <IoChevronUp cursor={"pointer"} onClick={toggleHandler} />
                )}
              </Box>
            </Box>
          </Flex>

          {showIndustries && (
            <Box
              p={["1em", "1em", "1.5em"]}
              my="1em"
              boxShadow="xl"
              rounded="xl"
              zIndex="3"
              background="white"
              position="absolute"
              height="60vh"
              w="max-content"
              overflowY="scroll"
              ref={wrapperRef}
            >
              <Flex
                alignItems="flex-start"
                justifyContent="space-between"
                my=".2em"
              >
                <Text fontSize="14px" color="gray.400" fontWeight="400">
                  Search Campaign
                </Text>

                <BsFillCaretUpFill
                  color="#CACBD4"
                  cursor="pointer"
                  onClick={toggleHandler}
                />
              </Flex>

              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<BsSearch color="gray.300" />}
                />
                <Input
                  type="text"
                  _focus={{ ring: "0" }}
                  border="0"
                  bgColor="#F4F6F8"
                  value={searchQuery}
                  onChange={(e: React.ChangeEvent<any>) =>
                    setSearchQuery(e.target.value)
                  }
                />
              </InputGroup>

              {campaigns.map((campaign: any, i: any) => {
                return (
                  <Box
                    key={i}
                    display={
                      searchQuery !== "" &&
                      !campaign.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                        ? "none"
                        : "flex"
                    }
                    justifyContent="space-between"
                    alignItems="flex-start"
                    my="1em"
                    cursor="pointer"
                    _hover={{ bgColor: "gray.50" }}
                    onClick={() => {
                      // const newState = campaigns.map((data: any, x: any) => {
                      //   if (campaigns[i] == data) {
                      //     return { ...data, selected: !data.selected };
                      //   } else {
                      //     return data;
                      //   }
                      // });
                      // setcampaigns(newState);
                      handleSelect(campaign);
                    }}
                    p=".5em"
                    borderRadius="3px"
                  >
                    <Text>{campaign.name}</Text>

                    {selectedItem.some(
                      (selectedItemData: any) =>
                        selectedItemData.id === campaign.id
                    ) ? (
                      <BsCheckSquareFill color="#5C15CE" />
                    ) : (
                      <Box
                        width="1em"
                        height="1em"
                        border="1.5px solid gray"
                        borderRadius="4px"
                      ></Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </Box>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          h="40vh"
          alignItems="center"
          pt="20px"
        >
          <Image className="loading" src="/assets/logo.png" height="60px" />
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="70px"
            color="#000000"
            mt="-15px"
          >
            Loading Managers Table...
          </Text>
        </Box>
      ) : (
        <ManagersTable
          managers={filterExist() ? filteredManagers : managers}
          campaigns={campaigns}
          setRefreshField={setrefreshField}
        />
      )}

      {campaigns?.length >= 1 ? (
        <ManagersForm
          isOpen={isOpenManagerModal}
          onClose={onCloseManagerModal}
          setRefresh={setrefreshField}
          type="New"
          campaigns={campaigns && campaigns?.length ? campaigns : []}
        />
      ) : (
        ""
      )}
    </Box>
  );
};

export default Managers;
