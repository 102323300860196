import React, { useRef } from "react";
import {
  Box,
  Image,
  Text,
  Stack,
  useColorModeValue,
  Button,
  Avatar,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper styles
import "swiper/css";

const TopAffiliate = ({ top5Affiliates }) => {
  const swiperRef = useRef();

  return (
    <Stack
      marginBottom="10px"
      background="#FFFFFF"
      width="100%"
      height="130px"
      padding={["10px 0px", "10px 0px"]}
      // padding={0}
      borderBottom=" 0.8px solid rgba(50, 50, 93, 0.3)"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb="15px"
      >
        <Box display="flex" alignItems="center">
          <Text
            fontWeight="500"
            fontSize="12px"
            color=" #32325D"
            lineHeight="18px"
            opacity="0.8"
            letterSpacing="0.02em"
            textTransform="uppercase"
          >
            Top 5 Affiliates
          </Text>
          <Image
            ml="10px"
            src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/39116ff5-c69d-4845-b009-28991cd8efa9.svg`}
          />
        </Box>
      </Box>
      <Box position="relative">
        <Swiper
          spaceBetween={16}
          breakpoints={{
            // when window width is >= 370p
            370: {
              slidesPerView: 1.6,
            },
            // when window width is >= 400px
            460: {
              slidesPerView: 1.6,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 3.4,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 2.4,
            },
            // when window width is >= 900px
            900: {
              slidesPerView: 3.4,
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 4.3,
            },
            // when window width is >= 1200px
            1200: {
              slidesPerView: 5,
            },
            // when window width is >= 1500px
            1500: {
              slidesPerView: 5,
            },
          }}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {top5Affiliates.map((item, index) => {
            return (
              <SwiperSlide position="relative" key={index}>
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  maxW="100%"
                >
                  <Text
                    fontWeight="500"
                    fontSize="14px"
                    color=" #32325D"
                    lineHeight="22px"
                    opacity="0.8"
                  >
                    {index + 1}
                  </Text>

                  <Box
                    ml="10px"
                    display="flex"
                    alignItems="center"
                    background="#FFFFFF"
                    border=" 0.5px solid rgba(50, 50, 93, 0.5)"
                    borderRadius=" 80px"
                    padding="9px"
                  >
                    {item.profile_image ? (
                      <Image
                        width="32px"
                        height="32px"
                        borderRadius="full"
                        objectFit="cover"
                        src={item?.profile_image}
                      />
                    ) : (
                      <Avatar
                        height="32px"
                        width="32px"
                        size="sm"
                        name={item?.companyname}
                      />
                    )}

                    <Text
                      fontWeight="600"
                      fontSize="14px"
                      color=" #32325D"
                      lineHeight="21px"
                      ml="8px"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      textTransform="capitalize"
                    >
                      {item?.companyname}
                    </Text>
                  </Box>
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
      <Button
        background="#FFFFFF"
        boxShadow="0px 0px 7.42829px rgba(114, 17, 212, 0.25)"
        borderRadius=" 4.64268px"
        width="32px"
        height="28px"
        position="absolute"
        marginTop="60px !important"
        zIndex="10"
        right="15px"
        onClick={() => swiperRef.current?.slideNext()}
        display={["block", "none"]}
      >
        <Image
          src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/67853fcc-a95e-4476-be48-14434b51b27d.svg`}
        />
      </Button>
    </Stack>
  );
};

export default TopAffiliate;
