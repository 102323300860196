import {
  Box,
  Button,
  css,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import useUserToken from "../../../../../../hooks/useUserToken";
import CommissionInvoice from "../../../../../campaigns/view-campaigns/tabs/commissions/CommissionInvoice";
// import PaginationComponent from "../../../../../explore/affiliates/paginationWrapper";

const titles = [
  "DATE CREATED",
  "PRODUCT ID",
  "SALES",
  "STATUS",
  "EARNINGS",
  " ",
];

const EarningsTable = ({
  earnings,
}: {
  earnings: any[];
  highlighted?: any[];
  sethighlighted?: React.Dispatch<React.SetStateAction<any[]>>;
}) => {
  const [paginationData, setpaginationData] = useState({
    currentPage: 1,
    pageSize: 6,
  });
  const [selectedInvoice, setselectedInvoice] = useState<any>({});
  const [invoiceData, setinvoiceData] = useState<any>({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  const token = useUserToken("affiliate");

  const getStatusColor = (status: string) => {
    if (status === "paid" || status === "approved") {
      return "#239654";
    } else if (status === "pending") {
      return "#F3994A";
    } else {
      return "#EB5757";
    }
  };

  const getInvoiceData = () => {
    if (!selectedInvoice?.id) return;

    let bearerAuth = token;

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `payout/affiliate/invoice/${selectedInvoice.id}/${selectedInvoice.adv_orgId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setinvoiceData(data.data.invoice);
        }
      })
      .catch((err) => {
        console.log("get commissions Invoice => " + err);
      });
  };

  const handleInvoiceOpen = (commissionInfo: any) => {
    setselectedInvoice(commissionInfo);
    onOpen();
  };

  useEffect(() => {
    getInvoiceData();
  }, [selectedInvoice]);

  const handleInvoiceClose = () => {
    setselectedInvoice({});
    onClose();
  };

  return (
    <Box
      css={
        isDesktop
          ? {}
          : css({
              "::-webkit-scrollbar": { display: "none" },
              "-webkit-overflow-scrolling": "touch",
            })
      }
      overflowX={{ base: "scroll", md: "visible" }}
      maxWidth="100%"
    >
      <Table mb="29px">
        <Thead>
          <Tr height="53px" bgColor="#F1F4FA">
            {titles.map((title) => {
              return (
                <Th
                  color="rgba(50, 50, 93, 0.8)"
                  textTransform="uppercase"
                  lineHeight="18px"
                  fontSize="12px"
                  fontWeight="500"
                  key={title}
                >
                  {title}
                </Th>
              );
            })}
          </Tr>
        </Thead>

        <Tbody>
          {earnings.map((item: any, index: number) => {
            return (
              <Tr key={item.id} height="80px" width="100%">
                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    {item?.createdAt?.split("T")[0] || ""}
                  </Text>
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    {item.productId || "-"}
                  </Text>
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    ${item.sales || 0}
                  </Text>
                </Td>

                <Td>
                  <Box display="flex" alignItems="center">
                    <Box
                      background={getStatusColor(item.status)}
                      height="12px"
                      width="12px"
                      borderRadius="50%"
                    ></Box>
                    <Text
                      color="#32325D"
                      lineHeight={{ base: "18px", md: "21px" }}
                      fontSize={{ base: "12px", md: "14px" }}
                      fontWeight="500"
                      marginLeft="8px"
                      textTransform="capitalize"
                    >
                      {item.status}
                    </Text>
                  </Box>
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    ${item.earnings || 0}
                  </Text>
                </Td>

                <Td display="flex" pr="0px">
                  <Button
                    bgColor="#F0F3F9"
                    borderRadius="8px"
                    fontWeight="500"
                    fontSize={{ base: "12px", md: "14px" }}
                    lineHeight={{ base: "18px", md: "21px" }}
                    color="#7211D4"
                    w="max-content"
                    ml="auto"
                    onClick={() => handleInvoiceOpen(item)}
                  >
                    <Image mr="7px" src="/assets/png/invoice-icon.png" />
                    Invoice
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <CommissionInvoice
        isOpen={isOpen}
        onClose={handleInvoiceClose}
        commissionTotal={selectedInvoice.earnings || 0}
        advertiserAddress={selectedInvoice.advertiserAddress || "---"}
        advertiserCountry={selectedInvoice.advertiserCountry || "---"}
        advertiserState={selectedInvoice.advertiserState || "---"}
        affiliateAddress={selectedInvoice.affAddress || "---"}
        affiliateCountry={selectedInvoice.affCountry || "---"}
        affiliateState={selectedInvoice.affState || "---"}
        issuedDate={invoiceData?.createdAt?.split("T")[0] || "--"}
        invoiceNumber={invoiceData?.invoiceId || "--"}
        paymentInfo={[
          {
            label: "Payment Due Date",
            value: `${invoiceData?.dateScheduledFor?.split("T")[0] || "--"}`,
          },
          {
            label: "Payment Amount",
            value: `$ ${selectedInvoice.earnings}`,
          },
          {
            label: "Product ID",
            value: selectedInvoice.productId,
          },
          {
            label: "Status",
            value: selectedInvoice.status,
          },
        ]}
        accountInfo={[
          {
            label: "Affiliate Organization",
            value: selectedInvoice.aff_orgName,
          },
          {
            label: "Full Name",
            value: selectedInvoice.aff_fullname,
          },
          {
            label: "Email Address",
            value: selectedInvoice.aff_email,
          },
          {
            label: "Campaign",
            value: selectedInvoice.campaignName,
          },
        ]}
      />
    </Box>
  );
};

export default EarningsTable;
