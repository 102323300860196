export const setLocalUserInstance = (userObj: any, userType?: string) => {
  if (
    (userObj.organisation && userObj.organisation.userType === "affiliate") ||
    userType === "affiliate"
  ) {
    localStorage.setItem("localAffiliateUser", JSON.stringify(userObj || {}));
  } else {
    localStorage.setItem("localUser", JSON.stringify(userObj));
  }
};

//   @ts-ignore
export const getCurrentUserInstance = () => {
  //   @ts-ignore
  const localUser = JSON.parse(localStorage.getItem("localUser"));
  //   @ts-ignore
  const localAffUser = JSON.parse(localStorage.getItem("localAffiliateUser"));
  let userType = sessionStorage.getItem("currentUserType");

  if (userType === "affiliate" || !localUser) {
    return localAffUser;
  }

  return localUser;
};

export const setCurrentUserType = (type: string) => {
  sessionStorage.setItem("currentUserType", type);
};
