import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import TabInfo from "./TabInfo";

const TabForm = ({ affiliateDetails }: { affiliateDetails: any }) => {
  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  console.log("affdet", affiliateDetails);

  return (
    <Tabs
      isFitted={isDesktop ? false : true}
      variant="line"
      colorScheme="purple"
    >
      <TabList>
        <Tab
          padding={{ base: "0px", md: "0px 0px 13px 0px !important" }}
          margin={{ base: "0px", md: "0px 60px 0px 0px !important" }}
          fontSize={{ base: "14px", md: "16px" }}
        >
          Organization Profile
        </Tab>
        <Tab
          fontSize={{ base: "14px", md: "16px" }}
          padding={{ base: "0px", md: "0px 0px 13px 0px !important" }}
          margin={{ base: "0px", md: "0px 60px 0px 0px !important" }}
        >
          Affiliate Information
        </Tab>
      </TabList>

      <Box padding={{ base: "0px", md: "0px 20px" }}>
        <TabPanels>
          <TabPanel>
            <TabInfo
              title="Display Name"
              val={affiliateDetails?.companyname}
              type="string"
            />
            <TabInfo
              title="Website"
              val={affiliateDetails?.website}
              type="string"
            />
            <TabInfo
              title="Country"
              val={affiliateDetails?.country}
              type="string"
            />
            <TabInfo
              title="Active Since"
              val={affiliateDetails?.createdAt?.split("T")[0] || ""}
              type="string"
            />
            <TabInfo
              title="Social Media Handles"
              val={affiliateDetails?.socialHandles || {}}
              type="socials"
            />

            {affiliateDetails?.description && (
              <Box
                pt={{ base: "12px", md: "34px" }}
                pb={{ base: "12px", md: "0px" }}
                css={{ borderTop: "0.5px solid rgba(50, 50, 93, 0.3)" }}
                borderBottom={{
                  base: "0.5px solid rgba(50, 50, 93, 0.3)",
                  md: "0px solid #fffff",
                }}
              >
                <Text
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="21px"
                  color="rgba(50, 50, 93, 0.6)"
                >
                  Description
                </Text>

                <Text
                  fontWeight="300"
                  fontSize="14px"
                  lineHeight="21px"
                  color="#32325D"
                  mt="17px"
                >
                  {affiliateDetails?.description}
                </Text>
              </Box>
            )}
          </TabPanel>

          {/* --------- */}

          <TabPanel>
            <TabInfo
              title="Affiliate Type"
              val={affiliateDetails?.affiliate_type}
              type="string"
            />
            <TabInfo
              title="Experience"
              val={affiliateDetails?.affiliate_years}
              type="string"
            />
            <TabInfo
              title="Size of Team"
              val={affiliateDetails?.team_size}
              type="string"
            />
            <TabInfo
              title="Countries I run Ads in"
              val={affiliateDetails?.countriesToShip || []}
              type="array"
            />
            <TabInfo
              title="Products I promote"
              val={affiliateDetails?.productType || []}
              type="array"
            />
            <TabInfo
              title="Niche"
              val={affiliateDetails?.niche || []}
              type="array"
            />
          </TabPanel>
        </TabPanels>
      </Box>
    </Tabs>
  );
};

export default TabForm;
