import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  css,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { IoPersonOutline } from "react-icons/io5";
import CommissionInvoice from "./CommissionInvoice";
import useUserToken from "../../../../../hooks/useUserToken";
import moment from "moment";

type CommissionTableProps = {
  commissions: any[];
  sethighlighted: React.Dispatch<React.SetStateAction<any[]>>;
  highlighted: any[];
  sethighlighted2: React.Dispatch<React.SetStateAction<any[]>>;
  highlighted2: any[];
  handleViewAff: (selectedAff: any) => void;
};

type CheckBoxTrackType = {
  [key: string | number]: boolean;
};

const CommissionsTable = ({
  commissions,
  sethighlighted,
  highlighted,
  sethighlighted2,
  highlighted2,
  handleViewAff,
}: CommissionTableProps) => {
  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  const [checkBoxTracker, setcheckBoxTracker] = useState<CheckBoxTrackType>({});
  const [selectedInvoice, setselectedInvoice] = useState<any>({});
  const [invoiceData, setinvoiceData] = useState<any>({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  const token = useUserToken();

  const getStatusColor = (status: string) => {
    if (status.toLowerCase() === "approved") return "#239667";
    if (status.toLowerCase() === "paid") return "#239654";

    if (status.toLowerCase() === "pending") return "#F3994A";

    return "#eb5757cd";
  };

  const titles = [
    "",
    "DATE CREATED",
    "ORGANISATION NAME",
    "SALES",
    "COMMISSION",
    "STATUS",
    "PRODUCT ID",
    "ACTION",
  ];

  const getInvoiceData = () => {
    if (!selectedInvoice?.id) return;

    let bearerAuth = token;

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `payout/advertiser-invoice/${selectedInvoice.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("invoice", data.data.invoice);
        if (data.success) {
          setinvoiceData(data.data.invoice);
        }
      })
      .catch((err) => {
        console.log("get commissions Invoice => " + err);
      });
  };

  const handleSelect = (commissionData: any) => {
    console.log("comms", commissionData);
    const foundSale = highlighted.find(
      (item: any) => item.id === commissionData?.id
    );

    if (foundSale) {
      sethighlighted(
        highlighted.filter((item: any) => item.id !== commissionData?.id)
      );
      sethighlighted2(
        highlighted.filter((item: any) => item.id !== commissionData?.id)
      );
      setcheckBoxTracker({ ...checkBoxTracker, [commissionData.id]: false });
    } else {
      sethighlighted([...highlighted, commissionData]);
      sethighlighted2([...highlighted, commissionData]);
      // console.log([...highlighted, commissionData]);
      setcheckBoxTracker({ ...checkBoxTracker, [commissionData.id]: true });
    }
  };

  const handleSelectAll = () => {
    let obj = {};

    if (highlighted.length === commissions.length && commissions.length !== 0) {
      sethighlighted([]);
      setcheckBoxTracker({});
    } else {
      sethighlighted(commissions);

      commissions.forEach((aff: { [key: string]: any }) => {
        obj = { ...obj, [aff.id]: true };
      });
      setcheckBoxTracker(obj);
    }
  };

  const handleInvoiceOpen = (commissionInfo: any) => {
    setselectedInvoice(commissionInfo);
    onOpen();
  };

  useEffect(() => {
    getInvoiceData();
  }, [selectedInvoice]);

  const handleInvoiceClose = () => {
    setselectedInvoice({});
    onClose();
  };

  return (
    <Box
      css={
        isDesktop
          ? {}
          : css({
              "::-webkit-scrollbar": { display: "none" },
              "-webkit-overflow-scrolling": "touch",
            })
      }
      overflowX={{ base: "scroll", md: "visible" }}
      maxWidth="100%"
    >
      <Table mb="29px">
        <Thead>
          <Tr height="53px" bgColor="#F1F4FA">
            {titles.map((title) => {
              return (
                <Th
                  color="rgba(50, 50, 93, 0.8)"
                  textTransform="uppercase"
                  lineHeight="18px"
                  fontSize="12px"
                  fontWeight="500"
                  key={title}
                  whiteSpace={{ base: "nowrap", md: "normal" }}
                >
                  {title ? (
                    title
                  ) : (
                    <Checkbox
                      colorScheme="purple"
                      onChange={() => handleSelectAll()}
                      isChecked={
                        highlighted.length === commissions.length &&
                        commissions.length !== 0
                      }
                    />
                  )}
                </Th>
              );
            })}
          </Tr>
        </Thead>

        <Tbody>
          {commissions.map((item: any) => {
            return (
              <Tr
                key={item.id}
                height="80px"
                width="100%"
                bgColor={checkBoxTracker[item.id] ? "purple.100" : ""}
              >
                <Td pl="20px" pr="10px">
                  <Checkbox
                    colorScheme="purple"
                    onChange={() => handleSelect(item)}
                    isChecked={checkBoxTracker[item.id] || false}
                  />
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    {item?.createdAt?.split("T")[0] || ""}
                  </Text>
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    {item?.affOrgName || ""}
                  </Text>
                </Td>
                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    {item?.sales || "0"}
                  </Text>
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    $ {item?.commission || 0}
                  </Text>
                </Td>

                <Td>
                  <Flex alignItems="center">
                    <Box
                      backgroundColor={getStatusColor(item?.status)}
                      height="12px"
                      width="12px"
                      borderRadius="50%"
                    ></Box>
                    <Box>
                      <Text
                        color="#32325D"
                        lineHeight="21px"
                        fontSize="14px"
                        fontWeight="500"
                        ml="6px"
                        textTransform="capitalize"
                      >
                        {item?.status}
                      </Text>
                    </Box>
                  </Flex>
                </Td>

                <Td>
                  <Text
                    color="#32325D"
                    lineHeight={{ base: "18px", md: "21px" }}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="500"
                  >
                    {item.productId || "-"}
                  </Text>
                </Td>

                <Td>
                  <Flex alignItems="center">
                    <Button borderRadius="8px" bgColor="#7211D4" color="white">
                      <IoPersonOutline fontSize={14} />
                      <Text
                        fontWeight="500"
                        fontSize={{ base: "12px", md: "14px" }}
                        lineHeight={{ base: "18px", md: "21px" }}
                        ml="7px"
                        onClick={() => handleViewAff(item?.aff_orgId)}
                      >
                        View
                      </Text>
                    </Button>

                    <Button
                      bgColor="#F0F3F9"
                      borderRadius="8px"
                      fontWeight="500"
                      fontSize={{ base: "12px", md: "14px" }}
                      lineHeight={{ base: "18px", md: "21px" }}
                      color="#7211D4"
                      w="max-content"
                      ml="13px"
                      onClick={() => handleInvoiceOpen(item)}
                    >
                      <Image mr="7px" src="/assets/png/invoice-icon.png" />
                      Invoice
                    </Button>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <CommissionInvoice
        isOpen={isOpen}
        onClose={handleInvoiceClose}
        commissionTotal={selectedInvoice.commission || 0}
        advertiserAddress={selectedInvoice.advertiserAddress || "---"}
        advertiserCountry={selectedInvoice.advertiserCountry || "---"}
        advertiserState={selectedInvoice.advertiserState || "---"}
        affiliateAddress={selectedInvoice.affAddress || "---"}
        affiliateCountry={selectedInvoice.affCountry || "---"}
        affiliateState={selectedInvoice.affState || "---"}
        issuedDate={moment(invoiceData?.createdAt).format("MMM Do YYYY")}
        invoiceNumber={invoiceData?.invoiceId || "--"}
        paymentInfo={[
          {
            label: "Payment Due Date",
            value: `${moment(invoiceData?.dateScheduledFor).format(
              "MMM Do YYYY"
            )}`,
          },
          {
            label: "Payment Amount",
            value: `$ ${selectedInvoice.commission}`,
          },
          {
            label: "Product ID",
            value: selectedInvoice.productId,
          },
          {
            label: "Status",
            value: selectedInvoice.status,
          },
        ]}
        accountInfo={[
          {
            label: "Affiliate Organization",
            value: selectedInvoice.aff_orgName,
          },
          {
            label: "Full Name",
            value: selectedInvoice.aff_fullname,
          },
          {
            label: "Email Address",
            value: selectedInvoice.aff_email,
          },
          {
            label: "Campaign",
            value: selectedInvoice.campaignName,
          },
        ]}
      />
    </Box>
  );
};

export default CommissionsTable;
