import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Input,
  InputGroup,
  // InputLeftElement,
  InputRightAddon,
  Link,
  Select,
  Stack,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Image,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { getCookie } from "cookies-next";
import { useEffect, useState } from "react";
import {
  IoArrowBackSharp,
  IoArrowForwardSharp,
  IoCopyOutline,
} from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import { apiReqHandler } from "../../../helper/apiReqHandler";
// import { userState } from "../../../state/user";
import { endpoints } from "../../../utils/endpoints";
import { integrations } from "../../../utils/integrations";
import { BaseButton } from "../../button";

import { CurrencyInputField } from "../../formInput/CurrencyInput";
import { BsExclamationTriangleFill } from "react-icons/bs";
import { campaignState } from "../../../state/campaign";
import { useRecoilState } from "recoil";
import { AiFillCloseCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import { fetchCampaign } from "../../../helper/fetchCampaign";
import { CampaignHeader } from "./Header";
import { Integration } from "./Integration";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

import { getCurrentUserInstance } from "../../../state/localstorage";

export const Customization = () => {
  const [campaignStateObj, setCampaignStateObj] = useRecoilState(campaignState);
  console.log("where data is", campaignStateObj);

  const [searchParams] = useSearchParams();
  const productType = searchParams.get("product");
  const campaignId = searchParams.get("campaign");

  const [editPage] = useState(
    window.location.pathname.includes("/edit-campaign")
  );

  const [integrateWith, setIntegrateWith]: any = useState(
    campaignStateObj?.integration ? campaignStateObj?.integration : null
  );
  const [commissionAmount, setCommissionAmount] = useState(
    campaignStateObj?.commission_amount
      ? campaignStateObj?.commission_amount
      : 0.0
  );
  const [commissionType, setCommissionType] = useState(
    campaignStateObj?.commission_type
      ? campaignStateObj?.commission_type === "life_time"
        ? "Lifetime"
        : campaignStateObj?.commission_type[0]?.toUpperCase() +
          campaignStateObj?.commission_type.slice(1)
      : "Fixed"
  );
  const [landingPage, setLandingPage] = useState(
    campaignStateObj?.landing_page_url
      ? campaignStateObj?.landing_page_url
      : campaignStateObj?.landingPage
      ? campaignStateObj?.landingPage
      : ""
  );
  const [landingPageEdit, setLandingPageEdit] = useState(
    campaignStateObj?.landingPage ? campaignStateObj?.landingPage : ""
  );
  const [commissionPercentage, setCommisionPercentage] = useState(
    campaignStateObj?.commission_percentage
      ? campaignStateObj?.commission_percentage
      : ""
  );
  const [recurringLimit, setRecurringLimit] = useState(
    campaignStateObj?.recurring_limit ? campaignStateObj?.recurring_limit : ""
  );
  const [recurringLimitValue, setRecurringLimitValue] = useState("");
  const [commissionTypes, setCommissionTypes]: any = useState([]);
  const [RecurringLimitArr, setRecurringLimitArr]: any = useState([]);

  useEffect(() => {
    if (productType === "ecommerce") {
      setCommissionTypes(["Fixed", "Percentage"]);
    } else {
      setCommissionTypes(["Fixed", "Percentage"]);
    }

    if (productType === "saas") {
      setRecurringLimitArr(["Lifetime", "Limit Number"]);
    } else {
      setRecurringLimitArr([]);
    }

    if (integrations) {
      setIntegrateWith(integrations[0]?.name);
    }
    return;
  }, [productType]);
  const toast = useToast();

  const [stockCapOption, setStockCapOption] = useState(
    campaignStateObj?.stock &&
      campaignStateObj?.stock?.toLowerCase() !== "unlimited"
      ? "Customize"
      : "Unlimited"
  );

  const [stockCapUnit, setStockCapUnit] = useState(
    // campaignStateObj?.stock ? campaignStateObj?.stock : 0.0
    campaignStateObj?.stock &&
      typeof Number(campaignStateObj?.stock) === "number"
      ? Number(campaignStateObj?.stock)
      : 0
  );

  const [saveAndExit, setSaveAndExit] = useState(false);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [amountEmpty, setAmountEmpty] = useState(false);
  // console.log("console.log(commissionAmount)", commissionAmount);
  const requestHandler: any = async () => {
    if (
      (commissionType.toLowerCase() === "fixed" ||
        commissionType.toLowerCase() === "lifetime") &&
      commissionAmount < 1
    ) {
      setAmountEmpty(true);
      return;
    }
    // console.log({ commissionType });

    let data = {
      integration: integrateWith,
      commission_type:
        commissionType.toLowerCase() === "lifetime"
          ? "life_time"
          : commissionType.toLowerCase(),
      landing_page_url: landingPage,
      products: [...productIds, ...productIdsActive],
      trial_period: Number(trialPeriod),
      interval: subInterval.toLowerCase(),
      step: 2,
      payment_interval: paypalDetails.payoutFrequency,
      payment_repeat: Number(paypalDetails.payoutRepeat),
      // ref_comm_support: refCommissionSupport === "ON" ? true : false,
    };

    if (
      productType?.toLowerCase() === "ecommerce" &&
      stockCapOption?.toLowerCase() === "unlimited"
    ) {
      // @ts-ignore
      data.stock = "Unlimited";
    }

    if (
      productType?.toLowerCase() === "ecommerce" &&
      stockCapOption?.toLowerCase() === "customize"
    ) {
      // @ts-ignore
      data.stock = stockCapUnit.toString();
    }

    if (
      commissionType.toLowerCase() === "fixed" ||
      commissionType.toLowerCase() === "lifetime"
    ) {
      // @ts-ignore
      data.commission_amount = Number(commissionAmount);
    }

    if (commissionType.toLowerCase() === "percentage") {
      // @ts-ignore
      data.commission_percentage = Number(commissionPercentage);
    }
    if (commissionType.toLowerCase() === "recurring") {
      // @ts-ignore
      data.recurring_limit = Number(recurringLimit);
    }
    if (landingPage?.length >= 1) {
      setIsValidUrl(false);
      // @ts-ignore
      data.landing_page_url = landingPage;
    }

    setCampaignStateObj({ ...campaignStateObj, ...data });

    console.log("na me be dissss oooo", data);
    const { res, error } = await apiReqHandler({
      //   @ts-ignore
      endPoint: endpoints.campaign.CREATE.STEP_TWO(campaignId),
      method: "PUT",
      payload: data,
      //   @ts-ignore
      bearerAuth: getCookie("access_token"),
    });

    if (error)
      return toast({
        title: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });

    console.log(res);
    return { res, error };

    // return { res: null, error: null };
  };
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    setCampaignStateObj({ ...campaignStateObj, isActive: false });
    function handleBeforeUnload(event: any) {
      if (!formSubmitted) {
        event.preventDefault();
        event.returnValue = "";
      }
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formSubmitted]);

  const submitHandler = async (e: any) => {
    e.preventDefault();

    if (!verifyStatus) {
      setClosedVerified(true);
    } else {
      setFormSubmitted(true);
      if (
        (commissionType.toLowerCase() === "fixed" ||
          commissionType.toLowerCase() === "lifetime") &&
        commissionAmount < 1
      ) {
        setAmountEmpty(true);
        return;
      }

      setLoader(true);
      const { res, error } = await requestHandler();
      setLoader(false);
      if (!error && res) {
        return navigate(
          editPage
            ? `/campaigns/edit-campaign/marketing-assets?product=${productType}&campaign=${campaignId}`
            : `/campaigns/marketing-assets?product=${productType}&campaign=${campaignId}`
        );
      }
    }
  };

  const saveAndExitHandler = async () => {
    // if (
    //   (commissionType.toLowerCase() === "fixed" ||
    //     commissionType.toLowerCase() === "lifetime") &&
    //   commissionAmount < 1
    // ) {
    //   setAmountEmpty(true);
    //   return;
    // }
    // console.log({ commissionType });

    let data = {
      integration: integrateWith,
      commission_type:
        commissionType.toLowerCase() === "lifetime"
          ? "life_time"
          : commissionType.toLowerCase(),
      landing_page_url: landingPage,
      products: [...productIds, ...productIdsActive],
      trial_period: Number(trialPeriod),
      interval: subInterval.toLowerCase(),
      step: 2,
      payment_interval: paypalDetails.payoutFrequency,
      payment_repeat: Number(paypalDetails.payoutRepeat),
      // ref_comm_support: refCommissionSupport === "ON" ? true : false,
    };

    if (
      productType?.toLowerCase() === "ecommerce" &&
      stockCapOption?.toLowerCase() === "unlimited"
    ) {
      // @ts-ignore
      data.stock = "Unlimited";
    }

    if (
      productType?.toLowerCase() === "ecommerce" &&
      stockCapOption?.toLowerCase() === "customize"
    ) {
      // @ts-ignore
      data.stock = stockCapUnit.toString();
    }

    if (
      commissionType.toLowerCase() === "fixed" ||
      commissionType.toLowerCase() === "lifetime"
    ) {
      // @ts-ignore
      data.commission_amount = Number(commissionAmount);
    }

    if (commissionType.toLowerCase() === "percentage") {
      // @ts-ignore
      data.commission_percentage = Number(commissionPercentage);
    }
    if (commissionType.toLowerCase() === "recurring") {
      // @ts-ignore
      data.recurring_limit = Number(recurringLimit);
    }
    if (landingPage?.length >= 1) {
      setIsValidUrl(false);
      // @ts-ignore
      data.landing_page_url = landingPage;
    }

    setCampaignStateObj({ ...campaignStateObj, ...data });

    console.log("na me be dissss oooo", data);
    const { res, error } = await apiReqHandler({
      //   @ts-ignore
      endPoint: endpoints.campaign.CREATE.STEP_TWO(campaignId),
      method: "PUT",
      payload: data,
      //   @ts-ignore
      bearerAuth: getCookie("access_token"),
    });

    if (error)
      return toast({
        title: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });

    return navigate("/campaigns");
  };

  const backToPrevious = () => {
    let data = {
      integration: integrateWith,
      commission_type: commissionType.toLowerCase(),
    };

    if (
      productType?.toLowerCase() === "ecommerce" &&
      stockCapOption?.toLowerCase() === "unlimited"
    ) {
      // @ts-ignore
      data.stock = "unlimited";
    }

    if (
      productType?.toLowerCase() === "ecommerce" &&
      stockCapOption?.toLowerCase() === "customize"
    ) {
      // @ts-ignore
      data.stock = stockCapUnit.toString();
    }

    if (
      commissionType.toLowerCase() === "fixed" ||
      commissionType.toLowerCase() === "lifetime"
    ) {
      // @ts-ignore
      data.commission_amount = Number(commissionAmount);
    }

    if (commissionType.toLowerCase() === "percentage") {
      // @ts-ignore
      data.commission_percentage = Number(commissionPercentage);
    }
    if (commissionType.toLowerCase() === "recurring") {
      // @ts-ignore
      data.recurring_limit = Number(recurringLimit);
    }
    const urlPattern = new RegExp(
      "^([a-zA-Z]+:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$", // fragment locator
      "i"
    );

    if (landingPage?.length >= 1 && !urlPattern.test(landingPage)) {
      setIsValidUrl(false);
      // @ts-ignore
      data.landing_page_url = landingPage;
    }
    setCampaignStateObj({ ...campaignStateObj, ...data });

    navigate(
      editPage
        ? `/campaigns/edit-campaign?campaign=${campaignId}`
        : `/campaigns/create?campaign=${campaignId}`
    );
  };

  const [lockCampaign, setLockCampaign] = useState(false);
  const [campaignDataCheck, setcampaignDataCheck] = useState("");
  const [verifyStatus, setverifyStatus] = useState(false);
  const [integrationType, setIntegrationType] = useState("none");
  const [campaignData, setcampaignData]: any = useState({});
  const user = getCurrentUserInstance();
  const [isOpenVerified, setClosedVerified] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [status, setStatus] = useState("active");
  const [campstatus, setcampStatus] = useState("active");
  const [shopifyStatus, setShopifyStatus] = useState("");
  const [productIds, setProductIds] = useState([]);
  const [productId, setProductId] = useState("");
  const [productIdLink, setProductIdLink] = useState("");
  const [productIdsActive, setProductIdsActive] = useState([]);
  const [trialPeriod, setTrialPeriod] = useState(
    campaignStateObj.trialPeriod ? campaignStateObj.trialPeriod : 0
  );
  const [subInterval, setSubInterval] = useState(
    campaignStateObj.subInterval ? campaignStateObj.subInterval : "Daily"
  );
  const [copySuccess, setCopySuccess] = useState(false);
  const [paypalDetails, setpaypalDetails] = useState({
    paypalId: "",
    paypalKey: "",
    payoutFrequency: "monthly",
    payoutRepeat: "1",
  });

  const copyToClipboard = () => {
    const textToCopy = api; // Replace with your desired text

    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);

    setCopySuccess(true);

    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  };
  const [refCommissionSupport, setRefCommissionSupport] = useState(
    campaignStateObj.refCommissionSupport
      ? campaignStateObj.refCommissionSupport
      : "ON"
  );
  const api = `${user.organisation.apikey}`;
  console.log("token test", user.organisation);
  useEffect(() => {
    //   @ts-ignore
    fetchVerifivationStatus({ campaignId });
    if (campaignId && editPage) {
      const getCampaignData = async () => {
        const { campaignData } = await fetchCampaign({ campaignId });
        // setcampaignDataCheck(campaignData?.landing_page_url);
        setcampaignData(campaignData);
        setStatus(campaignData.status);
        console.log("needed", campaignData);

        if (campaignData?.status?.toLowerCase() === "active") {
          setLockCampaign(true);
        }
        // console.log(integrations[2]);
        setIntegrateWith(
          campaignData.integration
            ? campaignData?.integration
            : integrations[0]?.name
        );
        setCommissionAmount(
          campaignData.commission_amount ? campaignData.commission_amount : 0.0
        );

        setCommissionType(
          campaignData?.commission_type === "life_time"
            ? "Lifetime"
            : campaignData?.commission_type[0].toUpperCase() +
                campaignData?.commission_type.slice(1)
        );
        setCommisionPercentage(
          campaignData.commission_percentage
            ? campaignData.commission_percentage
            : ""
        );

        setRecurringLimit(
          campaignData.recurring_limit ? campaignData.recurring_limit : ""
        );

        if (
          campaignData?.stock?.toLowerCase() !== "customize" &&
          typeof Number(campaignData?.stock) === "number"
        ) {
          setStockCapOption("Customize");
          setStockCapUnit(Number(campaignData?.stock));
        }
        if (campaignData?.stock?.toLowerCase() === "unlimited") {
          setStockCapOption("Unlimited");
        }

        setStockCapUnit(
          campaignData.stock && campaignData.stock !== "unlimited"
            ? Number(campaignData.stock)
            : 0
        );

        getCampaignData();
      };
    }
  }, []);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (campaignId) {
      const getCampaignData = async () => {
        setLoading(true);
        const { campaignData } = await fetchCampaign({ campaignId });
        setLoading(false);
        console.log("data check", campaignData);
        if (campaignData?.status?.toLowerCase() === "active") {
          setLockCampaign(true);
        }
        console.log("shopify-", campaignData);
        setShopifyStatus(campaignData?.shopify_shop_name);
        setCommissionAmount(campaignData?.commission_amount);
        setcampStatus(campaignData?.status);
        setpaypalDetails({
          ...paypalDetails,
          payoutFrequency: campaignData?.payment_interval,
        });
        // console.log("year", campaignData?.payment_interval);
        setLandingPage(
          campaignData?.landing_page_url == ""
            ? campaignStateObj?.landingPage
            : campaignData?.landing_page_url
        );

        //   @ts-ignore
        // setCampaignName(campaignData?.campaign_name);

        if (editPage) {
          setProductIdsActive(
            campaignData?.product_id === null ? [] : campaignData?.product_id
          );
          setTrialPeriod(
            campaignData?.trial_period ? campaignData?.trial_period : 0
          );
          setSubInterval(
            campaignData?.interval
              ? campaignData?.interval[0].toUpperCase() +
                  campaignData?.interval?.slice(1)
              : "Daily"
          );

          setRefCommissionSupport(
            campaignData?.ref_comm_support ? "ON" : "OFF"
          );
        }
      };
      getCampaignData();
    }
  }, []);
  const fetchVerifivationStatus = async ({
    campaignId,
  }: {
    campaignId: string;
  }) => {
    // console.log(endpoints.campaign.VERIFY(campaignId));
    const { res } = await apiReqHandler({
      endPoint: endpoints.campaign.VERIFY(campaignId),
      method: "GET",

      //   @ts-ignore
      bearerAuth: getCookie("access_token"),
    });

    const campaignStatus: any = res?.data?.data?.verified;

    //   @ts-ignore
    setverifyStatus(campaignStatus);
    setIntegrationType(res?.data?.data?.integration_type);
    // console.log("fetvh", res);
    console.log("status", res);
    console.log("status", campaignStatus);
    return { campaignStatus: Boolean };
  };

  const addProdIdHandler = () => {
    //   @ts-ignore
    if (productId == "" || productIdLink == "") {
      return;
    }
    //   @ts-ignore
    setProductIds([...productIds, { id: productId, link: productIdLink }]);
    setProductId("");
    setProductIdLink("");
  };
  const removeProdIdHandler = (id: string) => {
    setProductIds(() => productIds.filter((pid: any) => pid.id !== id));
  };
  const removeProdIdHandlerActive = (id: string) => {
    setProductIdsActive(() =>
      productIdsActive.filter((pid: any) => pid.id !== id)
    );
  };
  const navHandler = () => {
    setCampaignStateObj({});
    navigate(`/campaigns?campaign=${campaignId}`);
  };

  // console.log({ stockCapOption });
  return (
    <Box m="0px 0px 22px 0px">
      <Modal isOpen={isOpenVerified} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent
          borderRadius="1em"
          p=".8em"
          height="235px"
          mx={{ base: ".6em", md: "auto" }}
          marginTop={{ base: "15em", md: "18em" }}
        >
          <Box
            width=".8em"
            height="235px"
            position="absolute"
            left="0"
            bgGradient="linear(360deg, #7211D4 -1.38%, #FA9445 98.36%)"
            top="0"
            borderLeftRadius="1em"
          ></Box>
          {/* <ModalHeader mt="1em">Modal Title</ModalHeader> */}
          <ModalCloseButton
            bgColor="gray.100"
            borderRadius="1.5em"
            p="1.5em"
            top="1.5em"
            right="1.8em"
            fontSize="1em"
            onClick={() => setClosedVerified(false)}
          />

          <ModalBody mt="2.5em" mr="1em">
            <Text
              fontSize={{ base: "14px", md: "1.3em" }}
              fontWeight="medium"
              mb={{ base: "1.6em", md: "2em" }}
            >
              {landingPage === ""
                ? "Integrate Metricks with your website to continue"
                : "Integrate Metricks with your website to continue"}
            </Text>
            <Box
              width="90%"
              height="1px"
              mx="auto"
              bgColor="gray.200"
              mb=".6em"
            ></Box>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => setClosedVerified(false)}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box>
        <CampaignHeader currentStep={2} />
      </Box>
      {loading && editPage ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          h="50vh"
          alignItems="center"
          pt="20px"
        >
          <Image className="loading" src="/assets/logo.png" height="60px" />
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="70px"
            color="#000000"
            mt="-15px"
          >
            Fetching Campaign Details ...
          </Text>
        </Box>
      ) : (
        <Box mx={"22px"}>
          <Stack bgColor="#fff" padding="1.3em" borderRadius=".4em">
            <Heading
              fontSize="1em"
              color="#32325D"
              borderBottom="1px solid"
              borderColor="gray.300"
              pb="1.3em"
            >
              Customize your campaign
            </Heading>

            <form onSubmit={submitHandler}>
              {/* <Container maxW="container.lg" mx="auto"> */}

              <Flex
                borderBottom={["0", "1px solid #CBD5E0"]}
                py={[".5em", "1em"]}
                alignItems="center"
                my="1em"
                flexDirection={["column", "row"]}
                borderTopColor={{ md: "gray.300" }}
                gap={3}
              >
                <Tooltip
                  label={"Input your website's URL link here."}
                  fontSize="md"
                  placement="bottom-start"
                >
                  <Flex width={["100%", "30%"]}>
                    <Text
                      fontSize={["14px", "1em"]}
                      color="#32325D"
                      flexGrow={1}
                      width={{ base: "100%", md: "49%" }}
                      textAlign="left"
                      mb={[".3em", "0em"]}
                    >
                      Landing Page Url
                      <span style={{ color: "red", marginLeft: 5 }}>*</span>
                    </Text>
                  </Flex>
                </Tooltip>
                <Flex width={["100%", "50%"]} direction={"column"}>
                  {shopifyStatus !== null ? (
                    <Input
                      type="text"
                      value={landingPage}
                      disabled={true}
                      onChange={(e: React.ChangeEvent<any>) => {
                        setLandingPage(e.target.value);
                        setCampaignStateObj({
                          ...campaignStateObj,
                          landing_page_url: e.target.value,
                        });
                      }}
                      _focus={{ ring: "0", borderColor: "#5C14CE" }}
                      isRequired={true}
                      borderColor="gray.300"
                      style={{
                        height: "50px",
                        backgroundColor: "#fefdff",
                        // borderColor: "#c4c4c4",
                        borderRadius: "10px",
                      }}
                    />
                  ) : (
                    <Input
                      type="text"
                      value={landingPage}
                      disabled={
                        verifyStatus && lockCampaign ? lockCampaign : false
                      }
                      onChange={(e: React.ChangeEvent<any>) => {
                        setverifyStatus(false);
                        setLandingPage(e.target.value);
                        setCampaignStateObj({
                          ...campaignStateObj,
                          landing_page_url: e.target.value,
                        });
                      }}
                      _focus={{ ring: "0", borderColor: "#5C14CE" }}
                      isRequired={true}
                      borderColor="gray.300"
                      style={{
                        height: "50px",
                        backgroundColor: "#fefdff",
                        // borderColor: "#c4c4c4",
                        borderRadius: "10px",
                      }}
                      // w="full"
                    />
                  )}

                  {isValidUrl && (
                    <Text
                      fontSize="14px"
                      color="red.300"
                      fontWeight="medium"
                      className="urlError"
                    >
                      Invalid URL
                    </Text>
                  )}
                </Flex>
              </Flex>
              <Flex
                alignItems={"flex-start"}
                borderBottom={["0", "1px solid #CBD5E0"]}
                py={[".5em", "1em"]}
                my="1em"
                flexDirection={["column", "row"]}
                borderTopColor={{ md: "gray.300" }}
                gap={3}
                columnGap="1em"
              >
                <Tooltip
                  label={
                    "Find the guideline to integrate your product's website to Metricks here"
                  }
                  fontSize="md"
                  placement="bottom-start"
                >
                  <Flex width={["100%", "30%"]}>
                    <Text
                      fontSize={["14px", "1em"]}
                      color="#32325D"
                      flexGrow={1}
                      width={{ base: "100%", md: "49%" }}
                      textAlign="left"
                      mb={[".3em", "0em"]}
                    >
                      Integrate your website with
                      <span style={{ color: "red", marginLeft: 5 }}>*</span>
                    </Text>
                  </Flex>
                </Tooltip>
                <Box width={["100%", "50%"]}>
                  <Flex
                    width={"100%"}
                    alignItems="center"
                    justifyContent="space-between"
                    columnGap=".5em"
                    // bgColor={"red"}
                    style={{
                      backgroundColor: "#fefdff",
                      borderRadius: "10px",
                    }}
                  >
                    {verifyStatus ? (
                      <Box w="full">
                        <Box
                          border="1px solid"
                          borderColor="gray.200"
                          borderRadius=".4em"
                          py="1em"
                          px="1em"
                          w="full"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          // onClick={toggleHandler}
                          cursor="pointer"
                          // role="button"
                          // isDisabled={true}
                          style={{
                            pointerEvents: verifyStatus ? "auto" : "none",
                          }}
                        >
                          {integrationType !== "none" ? (
                            <Text>{integrationType}</Text>
                          ) : (
                            <Text></Text>
                          )}
                          <MdOutlineKeyboardArrowDown />
                        </Box>
                      </Box>
                    ) : (
                      <Box w="full">
                        {/* @ts-ignore */}
                        <Integration verifyStatus={verifyStatus} />
                      </Box>
                    )}

                    {editPage && lockCampaign ? (
                      verifyStatus ? (
                        <img
                          alt=""
                          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/b50a18cd-5809-45f9-a35b-ba79eab96c4b.svg"
                        />
                      ) : (
                        <Button
                          color="#5C14CE"
                          bgColor="gray.100"
                          borderRadius=".4em"
                          fontWeight="medium"
                          isDisabled={verifyStatus ? true : false}
                          onClick={() => {
                            // console.log(true);
                            const urlPattern = new RegExp(
                              "^(https?:\\/\\/)?(www\\.)?" + // protocol and optional "www"
                                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
                                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                                "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                                "(\\#[-a-z\\d_]*)?$", // fragment locator
                              "i"
                            );
                            const originalString = landingPage;
                            const modifiedString = originalString.replace(
                              /^https:\/\//,
                              ""
                            );
                            if (landingPage.includes("https://")) {
                              if (landingPage.match(urlPattern)) {
                                window.location.href = `${landingPage}?metricksVerify=true&campaignId=${campaignStateObj.campaignId}&campaignURL=${modifiedString}`;
                              } else {
                                setIsValidUrl(true);
                              }
                            } else {
                              if (landingPage.match(urlPattern)) {
                                window.location.href = `https://${landingPage}?metricksVerify=true&campaignId=${campaignStateObj.campaignId}&campaignURL=${modifiedString}`;
                              } else {
                                setIsValidUrl(true);
                              }
                            }
                          }}
                        >
                          {verifyStatus ? "Verified" : "Verify"}
                        </Button>
                      )
                    ) : (
                      <Button
                        color="#5C14CE"
                        bgColor="gray.100"
                        borderRadius=".4em"
                        fontWeight="medium"
                        isDisabled={verifyStatus ? true : false}
                        onClick={() => {
                          // console.log(true);
                          const urlPattern = new RegExp(
                            "^(https?:\\/\\/)?(www\\.)?" + // protocol and optional "www"
                              "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                              "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
                              "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                              "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                              "(\\#[-a-z\\d_]*)?$", // fragment locator
                            "i"
                          );
                          const originalString = landingPage;
                          const modifiedString = originalString.replace(
                            /^https:\/\//,
                            ""
                          );

                          if (landingPage.includes("https://")) {
                            if (landingPage.match(urlPattern)) {
                              window.location.href = `${landingPage}?metricksVerify=true&campaignId=${campaignStateObj.campaignId}&campaignURL=${modifiedString}`;
                            } else {
                              setIsValidUrl(true);
                            }
                          } else {
                            if (landingPage.match(urlPattern)) {
                              window.location.href = `https://${landingPage}?metricksVerify=true&campaignId=${campaignStateObj.campaignId}&campaignURL=${modifiedString}`;
                            } else {
                              setIsValidUrl(true);
                            }
                          }
                        }}
                      >
                        {verifyStatus ? "Verified" : "Verify"}
                      </Button>
                    )}
                  </Flex>
                  <Box mt={"10px"}>
                    <Flex
                      w={"100%"}
                      justify={"space-between"}
                      gap={["", 5]}
                      align={"center"}
                    >
                      <Text w={"90%"} bgColor="#F9F5FD" p={"10px"}>
                        {api}
                      </Text>

                      <IconButton
                        isRound={true}
                        variant="solid"
                        bgColor={"#F0F5FF"}
                        color={"#5C14CE"}
                        aria-label="Done"
                        fontSize="20px"
                        onClick={copyToClipboard}
                        icon={<IoCopyOutline />}
                      />
                      {copySuccess && <div>Copied to clipboard!</div>}
                    </Flex>

                    <Text fontSize={"12px"} mt={"5px"}>
                      Before selecting the "verify" button, you can copy the
                      Metricks API Key right here.
                    </Text>
                  </Box>
                </Box>
              </Flex>

              <Flex
                alignItems={"flex-start"}
                borderBottom={["0", "1px solid #CBD5E0"]}
                py={[".5em", "1em"]}
                my="1em"
                flexDirection={["column", "row"]}
                borderTopColor={{ md: "gray.300" }}
                gap={3}
                columnGap="1em"
              >
                <Flex width={["100%", "30%"]}>
                  <Text
                    fontSize={["14px", "1em"]}
                    color="#32325D"
                    flexGrow={1}
                    width={{ base: "100%", md: "49%" }}
                    textAlign="left"
                    mb={[".3em", "0em"]}
                  >
                    Commission Type
                    <span style={{ color: "red", marginLeft: 5 }}>*</span>
                  </Text>
                </Flex>
                <Box width={["100%", "50%"]}>
                  {commissionTypes?.length >= 1 &&
                    (lockCampaign ? (
                      <Flex w="full">
                        <Box
                          border="1px solid"
                          borderColor="gray.200"
                          borderRadius=".4em"
                          py="1em"
                          px="1em"
                          w="full"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          // onClick={toggleHandler}
                          cursor="pointer"
                          // role="button"
                          // isDisabled={true}
                          style={{
                            pointerEvents: verifyStatus ? "auto" : "none",
                          }}
                        >
                          {commissionType !== "none" ? (
                            <Text>{commissionType}</Text>
                          ) : (
                            <Text></Text>
                          )}
                          <MdOutlineKeyboardArrowDown />
                        </Box>
                        <img
                          alt=""
                          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/b50a18cd-5809-45f9-a35b-ba79eab96c4b.svg"
                        />
                      </Flex>
                    ) : (
                      <Select
                        _focus={{ ring: "0", borderColor: "#5C14CE" }}
                        isRequired={true}
                        value={commissionType}
                        onChange={(e: React.ChangeEvent<any>) =>
                          setCommissionType(e.target.value)
                        }
                        style={{
                          height: "50px",
                          backgroundColor: "#fefdff",
                          // borderColor: "#c4c4c4",
                          borderRadius: "10px",
                        }}
                        width={{ base: "100%" }}
                      >
                        {commissionTypes?.map((comType: any) => {
                          return (
                            <option key={comType} value={comType}>
                              {comType}
                            </option>
                          );
                        })}
                      </Select>
                    ))}
                </Box>
              </Flex>

              {(commissionType.toLowerCase() === "fixed" ||
                commissionType.toLowerCase() === "lifetime") && (
                <Flex
                  borderBottom={["0", "1px solid #CBD5E0"]}
                  py={[".5em", "1em"]}
                  alignItems="center"
                  my="1em"
                  flexDirection={["column", "row"]}
                  borderTopColor={{ md: "gray.300" }}
                  gap={3}
                >
                  <Flex width={["100%", "30%"]}>
                    <Text
                      fontSize={["14px", "1em"]}
                      color="#32325D"
                      flexGrow={1}
                      width={{ base: "100%", md: "49%" }}
                      textAlign="left"
                      mb={[".3em", "0em"]}
                    >
                      Commission Amount
                      <span style={{ color: "red", marginLeft: 5 }}>*</span>
                    </Text>
                  </Flex>

                  <Box width={["100%", "50%"]}>
                    <CurrencyInputField
                      valueSetter={setCommissionAmount}
                      globalSetter={setCampaignStateObj}
                      globalState={campaignStateObj}
                      value={commissionAmount}
                      errorTest={setAmountEmpty}
                      lockCampaign={lockCampaign}
                      minValue={1}
                      border={"#CBD5E0"}
                    />

                    {amountEmpty && (
                      <Text fontSize="14px" color="red.300" fontWeight="medium">
                        Field is required
                      </Text>
                    )}
                  </Box>
                </Flex>
              )}

              {commissionType.toLowerCase() === "percentage" && (
                <Flex
                  borderBottom={["0", "1px solid #CBD5E0"]}
                  py={[".5em", "1em"]}
                  alignItems="center"
                  my="1em"
                  flexDirection={["column", "row"]}
                  borderTopColor={{ md: "gray.300" }}
                  gap={3}
                >
                  <Flex width={["100%", "30%"]}>
                    <Text
                      fontSize={["14px", "1em"]}
                      color="#32325D"
                      flexGrow={1}
                      width={{ base: "100%", md: "49%" }}
                      textAlign="left"
                      mb={[".3em", "0em"]}
                    >
                      Commission Percentage
                      <span style={{ color: "red", marginLeft: 5 }}>*</span>
                    </Text>
                  </Flex>
                  {/* <Flex > */}
                  <InputGroup
                    width={["100%", "50%"]}
                    alignItems={"center"}
                    borderColor="gray.300"
                    borderWidth={1}
                    p={"3px"}
                    justifyContent={"space-between"}
                    borderRadius={"10px"}
                    bgColor={"#fefdff"}
                  >
                    <Input
                      type="number"
                      value={commissionPercentage}
                      disabled={lockCampaign}
                      onChange={(e: React.ChangeEvent<any>) =>
                        setCommisionPercentage(e.target.value.toString())
                      }
                      _focus={{
                        ring: "0",
                        borderTopColor: "#5C14CE",
                        borderLeftColor: "#5C14CE",
                        borderBottomColor: "#5C14CE",
                      }}
                      isRequired={
                        commissionType.toLowerCase() === "percentage"
                          ? true
                          : false
                      }
                      style={{
                        height: "47px",
                        backgroundColor: "#fefdff",
                        // borderColor: "#c4c4c4",
                        // borderRadius: "10px",
                      }}
                      borderColor="#fefdff"
                      min="1"
                      maxLength={524288}
                      onKeyDown={(e: React.KeyboardEvent<any>) => {
                        // @ts-ignore
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <InputRightAddon
                      h={"47px"}
                      w={"57px"}
                      children="%"
                      bgColor="#5C14CE"
                      alignItems={"center"}
                      justifyContent={"center"}
                      fontWeight={"600"}
                      fontSize={20}
                      borderRadius={"10px"}
                      color="#fff"
                    />
                  </InputGroup>
                </Flex>
                // </Flex>
              )}
              {RecurringLimitArr?.length >= 1 && (
                <Flex
                  borderBottom={["0", "1px solid #CBD5E0"]}
                  py={[".5em", "1em"]}
                  alignItems="center"
                  my="1em"
                  flexDirection={["column", "row"]}
                  borderTopColor={{ md: "gray.300" }}
                  gap={3}
                >
                  <Flex width={["100%", "30%"]}>
                    <Text
                      fontSize={["14px", "1em"]}
                      color="#32325D"
                      flexGrow={1}
                      width={{ base: "100%", md: "49%" }}
                      textAlign="left"
                      mb={[".3em", "0em"]}
                    >
                      Recurring limit
                      <span style={{ color: "red", marginLeft: 5 }}>*</span>
                    </Text>
                  </Flex>

                  {RecurringLimitArr?.length >= 1 && (
                    <Flex width={["100%", "50%"]}>
                      <Select
                        _focus={{ ring: "0", borderColor: "#5C14CE" }}
                        isRequired={true}
                        value={recurringLimit}
                        onChange={(e: React.ChangeEvent<any>) =>
                          setRecurringLimit(e.target.value)
                        }
                        width={{ base: "100%" }}
                        style={{
                          height: "50px",
                          backgroundColor: "#fefdff",
                          // borderColor: "#c4c4c4",
                          borderRadius: "10px",
                        }}
                      >
                        {RecurringLimitArr?.map((comType: any) => {
                          return (
                            <option key={comType} value={comType}>
                              {comType}
                            </option>
                          );
                        })}
                      </Select>
                    </Flex>
                  )}
                </Flex>
              )}
              {recurringLimit === "Limit Number" && (
                <Flex
                  borderBottom={["0", "1px solid #CBD5E0"]}
                  py={[".5em", "1em"]}
                  alignItems="center"
                  my="1em"
                  flexDirection={["column", "row"]}
                  borderTopColor={{ md: "gray.300" }}
                  gap={3}
                >
                  <Flex width={["100%", "30%"]}>
                    <Text
                      fontSize={["14px", "1em"]}
                      color="#32325D"
                      flexGrow={1}
                      width={{ base: "100%", md: "49%" }}
                      textAlign="left"
                      mb={[".3em", "0em"]}
                    >
                      Recurring Limit Number
                      <span style={{ color: "red", marginLeft: 5 }}>*</span>
                    </Text>
                  </Flex>

                  <Flex width={["100%", "50%"]} direction={"column"}>
                    <Input
                      type="number"
                      value={recurringLimitValue}
                      onChange={(e: React.ChangeEvent<any>) =>
                        setRecurringLimitValue(e.target.value.toString())
                      }
                      _focus={{ ring: "0", borderColor: "#5C14CE" }}
                      isRequired={
                        recurringLimit.toLowerCase() !== "Lifetime"
                          ? true
                          : false
                      }
                      borderColor="gray.300"
                      min="1"
                      style={{
                        height: "50px",
                        backgroundColor: "#fefdff",
                        // borderColor: "#c4c4c4",
                        borderRadius: "10px",
                      }}
                      maxLength={524288}
                      onKeyDown={(e: React.KeyboardEvent<any>) => {
                        // @ts-ignore
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                    />

                    {/* {recurringLimitValue.length <= 1 && (
                    <Text fontSize="14px" color="red.300" fontWeight="medium">
                      Field is required
                    </Text>
                  )} */}
                    <Box
                      bgColor="gray.100"
                      px="1em"
                      py="1.3em"
                      borderRadius=".4em"
                      mt=".4em"
                      display="flex"
                    >
                      <BsExclamationTriangleFill
                        fontSize="1.5em"
                        fontWeight="bold"
                      />
                      <Text
                        marginLeft="1em"
                        color="gray.600"
                        fontSize="14px"
                        fontWeight="medium"
                      >
                        This is a setting for Saas campaigns. You can select the
                        number of times you would like a commission to reoccur.
                        Once it reaches that limit, your affiliates will no
                        longer recieve a commission on that conversion.
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              )}

              {commissionType.toLowerCase() === "recurring" && (
                <Flex
                  alignItems="flex-start"
                  justifyContent="space-between"
                  columnGap="1em"
                  my="1em"
                  flexDirection={["column", "row"]}
                  borderBottom={["0", "1px solid #CBD5E0"]}
                  py={[".5em", "1em"]}
                >
                  <Text
                    fontSize={["14px", "1em"]}
                    color="#32325D"
                    flexGrow={1}
                    width={{ base: "100%", md: "49%" }}
                    textAlign="left"
                    mb={[".3em", "0em"]}
                  >
                    Recurring Limit
                    <span style={{ color: "red", marginLeft: 5 }}>*</span>
                  </Text>

                  <Box w="full">
                    <Input
                      type="number"
                      value={recurringLimit}
                      onChange={(e: React.ChangeEvent<any>) =>
                        setRecurringLimit(e.target.value.toString())
                      }
                      _focus={{ ring: "0", borderColor: "#5C14CE" }}
                      isRequired={
                        commissionType.toLowerCase() === "recurring"
                          ? true
                          : false
                      }
                      borderColor="gray.300"
                      min="1"
                      maxLength={524288}
                      onKeyDown={(e: React.KeyboardEvent<any>) => {
                        // @ts-ignore
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                    />

                    <Box
                      bgColor="gray.100"
                      px="1em"
                      py="1.3em"
                      borderRadius=".4em"
                      mt=".4em"
                      display="flex"
                    >
                      <AiOutlineExclamationCircle
                        fontSize="2em"
                        fontWeight="bold"
                      />
                      <Text
                        marginLeft=".5em"
                        fontSize="13px"
                        fontWeight="medium"
                        color="gray.600"
                      >
                        You can select the number times you would like a
                        commission to reoccur. Once it reaches that limit, your
                        affiliates will no longer recieve commission on that
                        conversion
                      </Text>
                    </Box>
                  </Box>
                </Flex>
              )}

              {productType?.toLowerCase() === "ecommerce" ? (
                <>
                  <Flex
                    alignItems={"flex-start"}
                    borderBottom={["0", "1px solid #CBD5E0"]}
                    py={[".5em", "1em"]}
                    my="1em"
                    flexDirection={["column", "row"]}
                    borderTopColor={{ md: "gray.300" }}
                    gap={3}
                    columnGap="1em"
                  >
                    <Flex width={["100%", "30%"]}>
                      <Text
                        fontSize={["14px", "1em"]}
                        color="#32325D"
                        flexGrow={1}
                        width={{ base: "100%", md: "49%" }}
                        textAlign="left"
                        mb={[".3em", "0em"]}
                        onClick={() =>
                          console.log("what is strd", { campaignStateObj })
                        }
                      >
                        Stock cap
                        <span style={{ color: "red", marginLeft: 5 }}>*</span>
                      </Text>
                    </Flex>
                    <Box width={["100%", "50%"]}>
                      <Box width="full">
                        <Select
                          _focus={{ ring: "0", borderColor: "#5C14CE" }}
                          isRequired={true}
                          value={stockCapOption}
                          onChange={(e: React.ChangeEvent<any>) =>
                            setStockCapOption(e.target.value)
                          }
                          style={{
                            height: "50px",
                            backgroundColor: "#fefdff",
                            // borderColor: "#c4c4c4",
                            borderRadius: "10px",
                          }}
                          width={{ base: "100%" }}
                        >
                          {["Unlimited", "Customize"]?.map((stockOpt: any) => {
                            return (
                              <option key={stockOpt} value={stockOpt}>
                                {stockOpt}
                              </option>
                            );
                          })}
                        </Select>
                      </Box>
                    </Box>
                  </Flex>

                  {stockCapOption?.toLowerCase() === "customize" && (
                    <Flex
                      borderBottom={["0", "1px solid #CBD5E0"]}
                      py={[".5em", "1em"]}
                      alignItems="center"
                      my="1em"
                      flexDirection={["column", "row"]}
                      borderTopColor={{ md: "gray.300" }}
                      gap={3}
                    >
                      <Flex width={["100%", "30%"]}></Flex>
                      <Box width={["100%", "50%"]}>
                        <InputGroup
                          width={"100%"}
                          alignItems={"center"}
                          borderColor="gray.300"
                          borderWidth={1}
                          p={"3px"}
                          justifyContent={"space-between"}
                          borderRadius={"10px"}
                          bgColor={"#fefdff"}
                        >
                          <CurrencyInputField
                            valueSetter={setStockCapUnit}
                            globalSetter={setCampaignStateObj}
                            globalState={campaignStateObj}
                            // errorTest={setAmountEmpty}
                            placeholder="0.00"
                            value={stockCapUnit}
                            border={"#F7FAFC"}
                          />
                          <InputRightAddon
                            h={"47px"}
                            w={"57px"}
                            children="Units"
                            bgColor="#5C14CE"
                            alignItems={"center"}
                            justifyContent={"center"}
                            fontWeight={"600"}
                            fontSize={16}
                            borderRadius={"10px"}
                            color="#fff"
                          />
                        </InputGroup>

                        <Box
                          bgColor="gray.100"
                          px="1em"
                          py="1.3em"
                          borderRadius=".4em"
                          mt=".4em"
                          display="flex"
                        >
                          <BsExclamationTriangleFill
                            fontSize="1.5em"
                            fontWeight="bold"
                          />
                          <Text
                            marginLeft="1em"
                            color="gray.600"
                            fontSize="14px"
                            fontWeight="medium"
                          >
                            If you customize this, your campaign will be
                            automatically paused once the inputed stock is
                            reached. You can always reactivate when you restock.
                          </Text>
                        </Box>
                      </Box>
                    </Flex>
                  )}
                </>
              ) : null}

              {productType !== "saas" && (
                <Flex
                  borderBottom={["0", "1px solid #CBD5E0"]}
                  py={[".5em", "1em"]}
                  alignItems="center"
                  my="1em"
                  flexDirection={["column", "row"]}
                  borderTopColor={{ md: "gray.300" }}
                  gap={3}
                >
                  <Tooltip
                    label={"Sales recorded are ONLY unique to this product"}
                    fontSize="md"
                    placement="bottom-start"
                  >
                    <Flex width={["100%", "30%"]}>
                      <Text
                        fontSize={["14px", "1em"]}
                        color="#32325D"
                        flexGrow={1}
                        width={{ base: "100%", md: "49%" }}
                        textAlign="left"
                        mb={[".3em", "0em"]}
                      >
                        Product ID
                        {/* <span style={{ color: "red", marginLeft: 5 }}>*</span> */}
                      </Text>
                    </Flex>
                  </Tooltip>
                  <Box width={["100%", "50%"]}>
                    {/* <form onSubmit={addProdIdHandler}> */}
                    <Flex
                      align="center"
                      justifyContent="space-between"
                      flexDir={{ base: "column", md: "row" }}
                      rowGap=".6em"
                    >
                      <Flex
                        align="center"
                        gap=".5em"
                        width={{ base: "100%", md: "85%" }}
                      >
                        <Input
                          type="text"
                          value={productId}
                          placeholder="Product ID"
                          onChange={(e: React.ChangeEvent<any>) =>
                            setProductId(e.target.value)
                          }
                          _focus={{ ring: "0", borderColor: "#5C14CE" }}
                          // isRequired={true}
                          borderColor="gray.300"
                          style={{
                            height: "50px",
                            backgroundColor: "#fefdff",
                            // borderColor: "#c4c4c4",
                            borderRadius: "10px",
                          }}
                        />
                        <Input
                          type="text"
                          value={productIdLink}
                          placeholder="Product ID Link"
                          onChange={(e: React.ChangeEvent<any>) =>
                            setProductIdLink(e.target.value)
                          }
                          _focus={{ ring: "0", borderColor: "#5C14CE" }}
                          // isRequired={true}
                          borderColor="gray.300"
                          style={{
                            height: "50px",
                            backgroundColor: "#fefdff",
                            // borderColor: "#c4c4c4",
                            borderRadius: "10px",
                          }}
                        />
                      </Flex>
                      <Button
                        onClick={addProdIdHandler}
                        color="#fff"
                        bgColor="#5C14CE"
                        marginLeft={{ base: "0em", md: "0em" }}
                        width={{ base: "100%", md: "13%" }}
                      >
                        Add
                      </Button>
                    </Flex>
                    {/* </form> */}

                    {productIds && productIds?.length >= 1 ? (
                      <Box mt=".5em">
                        {productIds?.map(
                          (
                            { id, link }: { id: string; link: string },
                            idx: any
                          ) => {
                            return (
                              <Flex
                                key={idx}
                                align="center"
                                columnGap=".2em"
                                p=".2em"
                                borderRadius=".2em"
                                direction={["column-reverse", "row"]}
                              >
                                <Box
                                  border="1px"
                                  borderRadius=".2em"
                                  borderColor="#5C14CE"
                                  width={["100%", "42.5%"]}
                                  p={2}
                                >
                                  <Text
                                    m=".3em"
                                    bgColor="gray.100"
                                    p="5px"
                                    px={"15px"}
                                  >
                                    {id}
                                  </Text>
                                </Box>

                                <Box
                                  border="1px"
                                  borderRadius=".2em"
                                  borderColor="#5C14CE"
                                  width={["100%", "42.5%"]}
                                  p={2}
                                >
                                  <Text
                                    m=".3em"
                                    bgColor="gray.100"
                                    p="5px"
                                    px={"15px"}
                                  >
                                    {link}
                                  </Text>
                                </Box>

                                <Box ml={{ base: ".5em" }}>
                                  <AiFillCloseCircle
                                    color="black"
                                    cursor="pointer"
                                    fontSize="1.2em"
                                    onClick={() => removeProdIdHandler(id)}
                                  />
                                </Box>
                              </Flex>
                            );
                          }
                        )}
                      </Box>
                    ) : null}
                    {productIdsActive && productIdsActive?.length >= 1 ? (
                      <Box mt=".5em">
                        {productIdsActive?.map(
                          (
                            { id, link }: { id: string; link: string },
                            idx: any
                          ) => {
                            return (
                              <Flex
                                key={idx}
                                align="center"
                                columnGap=".2em"
                                p=".2em"
                                borderRadius=".2em"
                                direction={["column-reverse", "row"]}
                              >
                                <Box
                                  border="1px"
                                  borderRadius=".2em"
                                  borderColor="#5C14CE"
                                  width={["100%", "42.5%"]}
                                  p={2}
                                >
                                  <Text
                                    m=".3em"
                                    bgColor="gray.100"
                                    p="5px"
                                    px={"15px"}
                                  >
                                    {id}
                                  </Text>
                                </Box>

                                <Box
                                  border="1px"
                                  borderRadius=".2em"
                                  borderColor="#5C14CE"
                                  width={["100%", "42.5%"]}
                                  p={2}
                                >
                                  <Text
                                    m=".3em"
                                    bgColor="gray.100"
                                    p="5px"
                                    px={"15px"}
                                  >
                                    {link}
                                  </Text>
                                </Box>

                                {campstatus === "draft" ? (
                                  <Box ml={{ base: ".5em" }}>
                                    <AiFillCloseCircle
                                      color="black"
                                      cursor="pointer"
                                      fontSize="1.2em"
                                      onClick={() =>
                                        removeProdIdHandlerActive(id)
                                      }
                                    />
                                  </Box>
                                ) : (
                                  <Image
                                    alt=""
                                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/b50a18cd-5809-45f9-a35b-ba79eab96c4b.svg"
                                    display={["none", "flex"]}
                                  />
                                )}
                              </Flex>
                            );
                          }
                        )}
                      </Box>
                    ) : null}
                  </Box>
                </Flex>
              )}

              {productType?.toLowerCase() === "saas" && (
                <>
                  <Flex
                    borderBottom={["0", "1px solid #CBD5E0"]}
                    py={[".5em", "1em"]}
                    alignItems="center"
                    my="1em"
                    flexDirection={["column", "row"]}
                    borderTopColor={{ md: "gray.300" }}
                    gap={3}
                  >
                    <Flex width={["100%", "30%"]}>
                      <Text
                        fontSize={["14px", "1em"]}
                        color="#32325D"
                        flexGrow={1}
                        width={{ base: "100%", md: "49%" }}
                        textAlign="left"
                        mb={[".3em", "0em"]}
                      >
                        Trial Period
                      </Text>
                    </Flex>

                    <Flex
                      width={["100%", "50%"]}
                      alignItems="center"
                      justifyContent="space-between"
                      columnGap=".5em"
                    >
                      <InputGroup alignItems={"center"} gap={5}>
                        <Input
                          type="text"
                          value={trialPeriod}
                          // placeholder="Enter the trail period for your SaaS product"
                          onChange={(e: React.ChangeEvent<any>) =>
                            setTrialPeriod(e.target.value)
                          }
                          _focus={{ ring: "0", borderColor: "#5C14CE" }}
                          isRequired={true}
                          borderColor="gray.300"
                          style={{
                            height: "50px",
                            backgroundColor: "#fefdff",
                            // borderColor: "#c4c4c4",
                            borderRadius: "10px",
                            width: "85%",
                          }}
                          isDisabled={editPage && lockCampaign ? true : false}
                        />

                        <InputRightAddon
                          children="Days"
                          bgColor="#5C14CE"
                          color="#fff"
                          fontWeight={600}
                          px={7}
                          py={5}
                          borderRadius={5}
                        />
                      </InputGroup>

                      {editPage && lockCampaign ? (
                        <img
                          alt=""
                          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/b50a18cd-5809-45f9-a35b-ba79eab96c4b.svg"
                        />
                      ) : null}
                    </Flex>
                  </Flex>

                  <Flex
                    borderBottom={["0", "1px solid #CBD5E0"]}
                    py={[".5em", "1em"]}
                    alignItems="center"
                    my="1em"
                    flexDirection={["column", "row"]}
                    borderTopColor={{ md: "gray.300" }}
                    gap={3}
                  >
                    <Flex width={["100%", "30%"]}>
                      <Text
                        fontSize={["14px", "1em"]}
                        color="#32325D"
                        flexGrow={1}
                        width={{ base: "100%", md: "49%" }}
                        textAlign="left"
                        mb={[".3em", "0em"]}
                      >
                        Subscription Interval
                      </Text>
                    </Flex>

                    <Flex
                      width={["100%", "50%"]}
                      alignItems="center"
                      justifyContent="space-between"
                      columnGap=".5em"
                    >
                      <Select
                        _focus={{ ring: "0", borderColor: "#5C14CE" }}
                        isRequired={true}
                        value={subInterval}
                        onChange={(e: React.ChangeEvent<any>) =>
                          setSubInterval(e.target.value)
                        }
                        width={{ base: "100%" }}
                        isDisabled={editPage && lockCampaign ? true : false}
                        style={{
                          height: "50px",
                          backgroundColor: "#fefdff",
                          // borderColor: "#c4c4c4",
                          borderRadius: "10px",
                        }}
                      >
                        {["Daily", "Weekly", "Monthly", "Yearly"].map(
                          (interval) => (
                            <option key={interval} value={interval}>
                              {interval}
                            </option>
                          )
                        )}
                      </Select>

                      {editPage && lockCampaign ? (
                        <img
                          alt=""
                          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/b50a18cd-5809-45f9-a35b-ba79eab96c4b.svg"
                        />
                      ) : null}
                    </Flex>
                  </Flex>
                </>
              )}

              <Flex
                alignItems={"center"}
                borderBottom={["0", "1px solid #CBD5E0"]}
                py={[".5em", "1em"]}
                my="1em"
                flexDirection={["column", "row"]}
                borderTopColor={{ md: "gray.300" }}
                gap={3}
                columnGap="1em"
              >
                <Flex width={["100%", "30%"]}>
                  <Text
                    fontSize={["14px", "1em"]}
                    color="#32325D"
                    flexGrow={1}
                    width={{ base: "100%", md: "49%" }}
                    textAlign="left"
                    mb={[".3em", "0em"]}
                  >
                    Payout frequency
                  </Text>
                </Flex>

                <Flex width={["100%", "50%"]} flexDirection={"column"}>
                  <Select
                    p="12px 0px"
                    // mb="7px"
                    color="rgba(50, 50, 93, 1)"
                    borderRadius="8px"
                    width={{ base: "full", md: "full" }}
                    border="0.6px solid rgba(50, 50, 93, 0.3)"
                    // disabled={!editMode ? true : false}
                    _disabled={{ opacity: "0.8" }}
                    value={paypalDetails.payoutFrequency}
                    onChange={(e) =>
                      setpaypalDetails({
                        ...paypalDetails,
                        payoutFrequency: e.target.value,
                      })
                    }
                    style={{
                      padding: "10px",
                      height: "50px",
                    }}
                  >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="annually">Annually</option>
                  </Select>
                  <Text fontSize={"12px"} mt={"5px"}>
                    Changes made here will be specific to this campaign and
                    override the default payout frequency settings.{" "}
                  </Text>
                </Flex>
              </Flex>

              {/* //Bottom */}
              <Flex
                alignItems="center"
                justifyContent="space-between"
                mb="1em"
                mt={productType?.toLowerCase() !== "saas" ? "5em" : "7em"}
                flexDirection={{ base: "column-reverse", lg: "row" }}
                rowGap=".6em"
                w={["100%", "81%"]}
              >
                <Button
                  px="0"
                  color="#5C14CE"
                  bgColor="#fff"
                  fontWeight="500"
                  fontSize="14px"
                  // width={{ md: "40%" }}
                  _hover={{ bgColor: "#fff" }}
                  _focus={{ bgColor: "#fff" }}
                  onClick={backToPrevious}
                  leftIcon={<IoArrowBackSharp />}
                >
                  Back to the previous page
                </Button>
                <Box
                  width={{ base: "100%", lg: "40%" }}
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  flexDirection={["column-reverse", "row"]}
                  rowGap=".6em"
                >
                  <Box mr={["0em", "1em"]} width={["100%", "60%", "50%"]}>
                    <BaseButton
                      text="Save and Exit"
                      textColor="#5C14CE"
                      bgColor="#F0F5FF"
                      clickEventHandler={saveAndExitHandler}
                      loadingState={saveAndExit}
                    />
                  </Box>

                  <Box width={["100%", "60%", "50%"]}>
                    <BaseButton
                      text="Continue"
                      textColor="#fff"
                      bgColor="#5C14CE"
                      type="submit"
                      loadingState={loader}
                      rightIcon={<IoArrowForwardSharp />}
                    />
                  </Box>
                </Box>
              </Flex>
              {/* </Container> */}
            </form>
          </Stack>
        </Box>
      )}
    </Box>
  );
};
