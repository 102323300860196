// @ts-ignore
import { getList } from "country-list-with-dial-code-and-flag";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { StepWrapper } from "./StepWrapper";
import { BsCheckSquareFill, BsFillCaretUpFill, BsSearch } from "react-icons/bs";
import React, { useEffect, useState, useRef } from "react";
import { BaseButton } from "../button";
import {
  IoArrowBackSharp,
  IoArrowForwardSharp,
  IoChevronDown,
  IoChevronUp,
} from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdCancel } from "react-icons/md";
import { useCookies } from "react-cookie";
import { toast, ToastContainer } from "react-toastify";
import { useOutsideAlerter } from "../../hooks/useOutsideAlerter";
import {
  getCurrentUserInstance,
  setCurrentUserType,
  setLocalUserInstance,
} from "../../state/localstorage";
import { FaStarOfLife } from "react-icons/fa";
import { RiArrowRightFill } from "react-icons/ri";

export const ProductDetails = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [orgType, setOrgType] = useState(false);
  useEffect(() => {
    if (searchParams.get("type") !== null) {
      setOrgType(searchParams.get("type") ? true : false);
    }
  }, [searchParams, orgType]);
  const [cookies] = useCookies(["user_id", "access_token"]);

  const localUser = getCurrentUserInstance();

  const [allCountries, setAllCountries] = useState(
    getList().map((country: any) => {
      return { country };
    })
  );
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const toggleHandler = () => {
    return setShowFilter((prev) => !prev);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setShowFilter(false));

  const [cardData, setCardData] = useState(
    localUser && localUser.rawProductTypes
      ? localUser.rawProductTypes
      : [
          {
            defaultIcon:
              "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/dee212f9-5a5d-4fd6-869d-b4e0fa080ed3.svg",
            coloredIcon: "/assets/svg/colored-ecommerce.svg",
            title: "E-commerce",
            selected: false,
          },
          {
            defaultIcon:
              "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/a7631722-e9dd-4f15-94de-f9c97efe7cba.svg",
            coloredIcon:
              "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/dfee292c-71af-43d6-87c9-5307de051e34.svg",
            title: "SaaS",
            selected: false,
          },
        ]
  );

  const [saveAndExit, setSaveAndExit] = useState(false);
  const [loading, setLoading] = useState(false);
  const submitHandler = () => {
    if (
      cardData.filter((dt: any) => dt.selected).length < 1 ||
      selectedItem.length < 1
    ) {
      toast.warning("Please complete all required fields or save and exit");
      return;
    }

    setLoading(true);
    const payload = {
      productType: cardData
        .filter((dt: any) => dt.selected)
        .map((data: any) => data.title.split("-").join("").toLowerCase()),
      countriesToShip: selectedItem.map((data: any) => data.country.name),
    };
    console.log(payload);

    fetch(process.env.REACT_APP_API_ENDPOINT + "auth/signup/step4", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.access_token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);

        if (data.success) {
          setCurrentUserType("advertiser");
          setLocalUserInstance({
            ...localUser,
            rawShipToCountries: selectedItem,
            rawProductTypes: cardData,
          });
          const user = getCurrentUserInstance();
          user.organisation.signup_step = 4;
          setLocalUserInstance(user);
          if (orgType) {
            saveAndExit
              ? window.open("https://www.metricks.io", "_self")
              : navigate("/signup/niche?type=newOrganization");
          } else {
            saveAndExit
              ? window.open("https://www.metricks.io", "_self")
              : navigate("/signup/niche");
          }
        }
        console.log(data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const step = [
    {
      title: "User Profile",
      status: "passed",
      key: 1,
    },
    {
      title: "Company Profile",
      status: "passed",
      key: 2,
    },

    {
      title: "Marketing Assets",
      status: "passed",
      key: 3,
    },
    {
      title: "Product Details",
      status: "current",
      key: 4,
    },
    {
      title: "Niche",
      status: "default",
      key: 5,
    },
    {
      title: "Billing",
      status: "default",
      key: 6,
    },
  ];
  const orgSteps = [
    {
      title: "Company Profile",
      status: "passed",
      key: 1,
    },

    {
      title: "Marketing Assets",
      status: "passed",
      key: 2,
    },
    {
      title: "Product Details",
      status: "current",
      key: 3,
    },
    {
      title: "Niche",
      status: "default",
      key: 4,
    },
    {
      title: "Billing",
      status: "default",
      key: 5,
    },
  ];
  const steps = orgType ? orgSteps : step;

  const [selectedItem, selectedItems]: any = useState(
    localUser.rawShipToCountries || []
  );
  const handleSelect = (item: any) => {
    // console.log("selectedCountry", selectedItem);
    // const isSelected = selectedItem.includes(item);
    const isSelected = selectedItem.some(
      (selectedItemData: any) => selectedItemData === item
    );
    // setSelectAll(false);
    // console.log(isSelected);
    if (isSelected) {
      // If item is already selected, remove it from the selectedItems array
      selectedItems((prevSelectedItems: any) =>
        prevSelectedItems.filter((selectedItem: any) => selectedItem !== item)
      );
    } else {
      // If item is not selected, add it to the selectedItems array
      selectedItems((prevSelectedItems: any) => [...prevSelectedItems, item]);
    }
  };
  return (
    <StepWrapper
      step={steps.map((item: any) => {
        if (item.status === "current") {
          let step = `STEP ${item.key} OF ${steps.length}`;
          return step;
        }
      })}
      header="Setting up your advertiser account"
      baseText="Product details"
      stepsMap={steps}
    >
      <Flex
        position={"relative"}
        direction={"column"}
        alignItems={"center"}
        w={["90%", "60%"]}
        // backgroundColor={"red"}
      >
        <Flex w={"100%"} direction={"column"}>
          <Box ref={wrapperRef}>
            <ToastContainer />
            <Heading fontSize="1.2em" mb="1em" display={"flex"} gap={1}>
              What countries can you ship your products to?
              <FaStarOfLife fontSize={"6px"} color="red" />
            </Heading>

            <Flex
              borderBottom="1px solid #B6BDC3"
              pb=".3em"
              justifyContent="space-between"
              alignItems="center"
              ref={wrapperRef}
              cursor="pointer"
              onClick={() => toggleHandler()}
            >
              <Flex rowGap=".5em" columnGap=".5em" flexWrap="wrap">
                {selectedItem.length >= 1 ? (
                  selectedItem.map((data: any, i: any) => {
                    return (
                      <Box
                        key={i}
                        bgColor="#F0F5FF"
                        py={["3px", "3px", "6px"]}
                        px=".4em"
                        alignItems="center"
                        display="flex"
                        borderRadius="4px"
                      >
                        <Box>{data.country.flag}</Box>
                        <Text
                          mr=".8em"
                          ml=".5em"
                          fontSize="14px"
                          fontWeight="400"
                          color="gray.800"
                        >
                          {data.country.name}
                        </Text>

                        <MdCancel
                          color="#000"
                          cursor="pointer"
                          onClick={(e: React.ChangeEvent<any>) => {
                            e.preventDefault();
                            handleSelect(data);
                          }}
                        />
                      </Box>
                    );
                  })
                ) : (
                  <Text cursor="pointer">Select countries</Text>
                )}
              </Flex>
              <Box>
                {!showFilter ? (
                  <IoChevronDown
                    cursor="pointer"
                    fontSize="20px"
                    // onClick={() => toggleHandler}
                  />
                ) : (
                  <IoChevronUp
                    cursor="pointer"
                    fontSize="20px"
                    // onClick={() => toggleHandler}
                  />
                )}
              </Box>
            </Flex>

            {showFilter && (
              <Box
                p={["1em", "1em", "1.5em"]}
                my="1em"
                boxShadow="xl"
                rounded="xl"
                height="60vh"
                overflowY="scroll"
                ref={wrapperRef}
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  my=".5em"
                >
                  <Text fontSize="14px" color="gray.400" fontWeight="400">
                    Search Country
                  </Text>

                  <BsFillCaretUpFill
                    color="#CACBD4"
                    cursor="pointer"
                    // onClick={() => toggleHandler}
                  />
                </Flex>

                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<BsSearch color="gray.300" />}
                  />
                  <Input
                    type="text"
                    _focus={{ ring: "0" }}
                    border="0"
                    bgColor="#F4F6F8"
                    value={searchQuery}
                    onChange={(e: React.ChangeEvent<any>) =>
                      setSearchQuery(e.target.value)
                    }
                  />
                </InputGroup>

                {allCountries
                  .filter((countryData: any) =>
                    countryData.country.name
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  )
                  .map((countryData: any, i: any) => (
                    <Box
                      display={"flex"}
                      justifyContent="space-between"
                      alignItems="center"
                      key={i}
                      cursor="pointer"
                      _hover={{ bgColor: "gray.50" }}
                      p=".5em"
                      my="2px"
                      borderRadius="3px"
                      onClick={() => {
                        handleSelect(countryData);
                      }}
                    >
                      <Flex alignItems="center" mt="1em">
                        <div> {countryData.country.flag}</div>
                        <Text ml="1em">{countryData.country.name}</Text>
                      </Flex>

                      {selectedItem.some(
                        (selectedItemData: any) =>
                          selectedItemData.country.name ===
                          countryData.country.name
                      ) ? (
                        <BsCheckSquareFill color="#5C15CE" />
                      ) : (
                        <Box
                          width="1em"
                          height="1em"
                          border="1.5px solid gray"
                          borderRadius="4px"
                        ></Box>
                      )}
                    </Box>
                  ))}

                {allCountries.length > 0 &&
                  allCountries.filter(
                    (countryData: any) =>
                      !countryData.country.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                  ).length === allCountries.length && (
                    <Text fontSize="14px" color="gray.500" mt="1em">
                      No data found
                    </Text>
                  )}
              </Box>
            )}
          </Box>

          <Box mt="40px">
            <Heading fontSize="1.2em" mb="1em" mt="2em" display={"flex"}>
              What type of products do you have?
              <FaStarOfLife fontSize={"6px"} color="red" />
            </Heading>

            <Flex flexWrap="wrap" rowGap="1em" columnGap="1em">
              {cardData.map((dt: any, i: any) => (
                <Box
                  key={i}
                  _hover={{ bgColor: "#F5F8FF" }}
                  border="1.5px solid"
                  borderColor={dt.selected ? "#7211D4" : "#D1D1D1"}
                  bgColor={dt.selected ? "#F5F8FF" : "#ffffff"}
                  padding="16px 16px 10px 16px"
                  borderRadius="0.4em"
                  w={["100%", "45%", "48%"]}
                  minHeight={["100px", "148px", "150px"]}
                  display="flex"
                  alignItems="flex-start"
                  flexDirection={["row-reverse", "column"]}
                  justifyContent="space-between"
                  cursor="pointer"
                  onClick={() => {
                    const newState = cardData.map((dt: any) => {
                      if (cardData[i] == dt) {
                        return {
                          ...dt,
                          selected: !dt.selected,
                        };
                      } else {
                        return dt;
                      }
                    });

                    setCardData(newState);
                  }}
                >
                  {!dt.selected ? (
                    <Box
                      width="1em"
                      height="1em"
                      border="1.5px solid #A4B3C3"
                      borderRadius="4px"
                      cursor="pointer"
                      onClick={() => {
                        const newState = cardData.map((dt: any) => {
                          if (cardData[i] == dt) {
                            return { ...dt, selected: !dt.selected };
                          } else {
                            return dt;
                          }
                        });
                        setCardData(newState);
                      }}
                    ></Box>
                  ) : (
                    <BsCheckSquareFill
                      color="#5C15CE"
                      cursor="pointer"
                      onClick={() => {
                        const newState = cardData.map((dt: any) => {
                          if (cardData[i] == dt) {
                            return { ...dt, selected: !dt.selected };
                          } else {
                            return dt;
                          }
                        });
                        setCardData(newState);
                      }}
                    />
                  )}
                  {/* <Checkbox
                colorScheme="purple"
                color="#A5A5A5"
                alignItems="center"
                isChecked={dt.selected}
                onChange={(e) => {
                  if (e.target.checked) {
                    const newState = cardData.map((dt: any) => {
                      if (cardData[i] == dt) {
                        return { ...dt, selected: true };
                      } else {
                        return dt;
                      }
                    });
                    setCardData(newState);
                  } else {
                    const newState = cardData.map((dt: any) => {
                      if (cardData[i] == dt) {
                        return { ...dt, selected: false };
                      } else {
                        return dt;
                      }
                    });
                    setCardData(newState);
                  }
                }}
              ></Checkbox> */}

                  <Box my=".3em">
                    <Image
                      src={dt.selected ? dt.coloredIcon : dt.defaultIcon}
                    />
                    <Text
                      fontWeight="400"
                      fontSize="14px"
                      lineHeight="26px"
                      color={dt.selected ? "#7211D4" : "#000000"}
                      mt=".2em"
                    >
                      {dt.title}
                    </Text>
                  </Box>
                </Box>
              ))}
            </Flex>
          </Box>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            mb="1em"
            // mt="2.5em"
            flexDirection={{ base: "column-reverse", lg: "row" }}
            rowGap=".6em"
            mt={{ base: "10em", lg: showFilter ? "3em" : "35vh" }}
          >
            <Button
              px="0"
              color="#5C14CE"
              bgColor="#fff"
              fontWeight="500"
              fontSize="14px"
              width={{ md: "40%" }}
              _hover={{ bgColor: "#fff" }}
              _focus={{ bgColor: "#fff" }}
              onClick={() => navigate("/signup/marketing-assets")}
              leftIcon={<IoArrowBackSharp />}
            >
              Back to the previous step
            </Button>
            <Box
              width={{ base: "100%", lg: "40%" }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection={["column-reverse", "row"]}
              rowGap=".6em"
              justifySelf="flex-end"
            >
              <Box mr={["0em", "1em"]} width={["100%", "60%", "50%"]}>
                {/* <BaseButton
              text="Skip"
              textColor="#5C14CE"
              bgColor="#F0F5FF"
              clickEventHandler={() => {
                // setSaveAndExit(true);
                // submitHandler();
                // window.open("https://metricks.io", "_self");
                navigate("/signup/niche");
              }}
            /> */}
              </Box>

              <Box width={["100%", "60%", "60%"]}>
                <BaseButton
                  text="Next step"
                  textColor="#fff"
                  bgColor="#5C14CE"
                  loadingState={loading}
                  rightIcon={<RiArrowRightFill />}
                  clickEventHandler={submitHandler}
                />
              </Box>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </StepWrapper>
  );
};
