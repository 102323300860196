import { Box, Heading, Image, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import OrganizationHeader from "./components/OrganizationHeader";
import AffilaiteOrganizationProfile from "./components/AffilaiteOrganizationProfile";
import AffiliateOrgAddress from "./components/AffiliateOrgAddress";
import AffiliateOrgNiche from "./components/AffiliateOrgNiche";
import AffiliateOrgBackground from "./components/AffiliateOrgBackground";
import useUserToken from "../../../hooks/useUserToken";
import { gsap, Power2 } from "gsap";
import AffiliateOrgInfo from "./components/AffiliateOrgInfo";
import { useSearchParams } from "react-router-dom";

const OrganizationSettings = () => {
  const [loading, setloading] = useState(true);
  let [searchParams, _] = useSearchParams();
  const [refreshField, setrefreshField] = useState(0);
  const [orgDetails, setorgDetails] = useState<{ [key: string]: any }>({});

  const token: string = useUserToken("affiliate");

  const getOrgDetails = () => {
    setloading(true);
    let bearerAuth = token;

    fetch(process.env.REACT_APP_API_ENDPOINT + "auth/me", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setorgDetails(data.data.user.organisation || {});
        }
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        console.log("org campaign => " + err);
      });
  };

  useEffect(() => {
    getOrgDetails();
    gsap.fromTo(
      ".loading",
      {
        opacity: 0,
        ease: Power2.easeInOut,
        scale: 0,
        y: 80,
      },
      {
        opacity: 1,
        duration: 1.5,
        scale: 1,
        ease: Power2.easeInOut,
        repeat: -1,
        y: 0,
        yoyo: true,
      }
    );
  }, [refreshField]);

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        pt="20px"
        minH="70vh"
      >
        <Image className="loading" src="/assets/logo.png" height="80px" />
        <Text
          fontWeight="400"
          fontSize="16px"
          lineHeight="70px"
          color="#000000"
          mt="-15px"
        >
          Loading....
        </Text>
      </Box>
    );

  const getPageTitle = (step: string) => {
    if (step === "address") {
      return "Address";
    } else if (step === "affiliateinformation") {
      return "Affiliate Information";
    } else if (step === "niche") {
      return "Niche";
    } else if (step === "affiliatebackground") {
      return "Affiliate Background";
    } else {
      return "Organization Profile";
    }
  };

  return (
    <Stack m="28px 0px" minH="80vh">
      <OrganizationHeader />

      <Box
        m="20px 0px"
        bgColor="white"
        p="25px 30px"
        minH="50vh"
        borderRadius="8px"
      >
        <Text fontSize="1rem" color="#32325D" fontWeight={"600"} pb="24px">
          {getPageTitle(searchParams.get("tab") || "")}
        </Text>

        {getPageTitle(searchParams.get("tab") || "") ===
        "Organization Profile" ? (
          <AffilaiteOrganizationProfile
            setrefreshData={setrefreshField}
            orgCompanyName={orgDetails.companyname || ""}
            orgDescription={orgDetails.description || ""}
            orgDisplayName={orgDetails.displayname || ""}
            orgSocials={orgDetails.socialHandles || {}}
            orgWebsite={orgDetails.website || ""}
          />
        ) : getPageTitle(searchParams.get("tab") || "") === "Address" ? (
          <AffiliateOrgAddress
            orgAddress={orgDetails.address || ""}
            orgCity={orgDetails.city || ""}
            orgCountry={orgDetails.country || ""}
            orgState={orgDetails.state || ""}
            orgZipCode={orgDetails.zip_code || ""}
            setRefreshData={setrefreshField}
          />
        ) : getPageTitle(searchParams.get("tab") || "") ===
          "Affiliate Information" ? (
          <AffiliateOrgInfo
            affiliate_type={orgDetails.affiliate_type || ""}
            media_buyer={orgDetails?.media_buyer ? orgDetails?.media_buyer : []}
            social_influencer={orgDetails.social_influencer || {}}
            contentPublisher={orgDetails.content_publisher || {}}
            setRefreshData={setrefreshField}
          />
        ) : getPageTitle(searchParams.get("tab") || "") === "Niche" ? (
          <AffiliateOrgNiche
            orgNiche={orgDetails?.niche ? orgDetails?.niche : []}
            setRefreshData={setrefreshField}
          />
        ) : (
          <AffiliateOrgBackground
            affTeamSize={orgDetails.team_size || ""}
            affYears={orgDetails.affiliate_years || ""}
            countriesToShip={orgDetails.countriesToShip || []}
            setRefreshData={setrefreshField}
            productType={orgDetails.productType || []}
          />
        )}
      </Box>
    </Stack>
  );
};

export default OrganizationSettings;
