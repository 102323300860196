export const ReportIcon = (props: any) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0517 12.3184H4.98633"
        stroke="none"
        strokeWidth="1.26013"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
      <path
        d="M11.0517 8.80176H4.98633"
        stroke="none"
        strokeWidth="1.26013"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
      <path
        d="M7.30076 5.29199H4.98633"
        stroke="none"
        strokeWidth="1.26013"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2137 1C11.2137 1 4.76441 1.00336 4.75433 1.00336C2.4357 1.01764 1 2.54323 1 4.87026V12.5957C1 14.9345 2.44662 16.466 4.78542 16.466C4.78542 16.466 11.2339 16.4634 11.2448 16.4634C13.5635 16.4492 15 14.9227 15 12.5957V4.87026C15 2.53147 13.5525 1 11.2137 1Z"
        stroke="none"
        strokeWidth="1.26013"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
    </svg>
  );
};
