import {
  Box,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import { RiSearchLine } from "react-icons/ri";
import { industries } from "../../../../utils/niche";
import CustomSelect from "./CustomSelect";

const modifiedIndustries = industries.map((item: any) => item.niche);

type FilterPropTypes = {
  filterData: any;
  setfilterData: React.Dispatch<any>;
  setSearchVal: React.Dispatch<React.SetStateAction<string>>;
  searchVal: string;
  advancedFilterChange?: boolean;
  setAdvancedFilterChange?: React.Dispatch<React.SetStateAction<boolean>>;
  setFilterInfo?: React.Dispatch<any>;
  setsearchResult?: React.Dispatch<any>;
};

const Filter = ({
  filterData,
  setfilterData,
  searchVal,
  setSearchVal,
}: FilterPropTypes) => {
  const [currentSelectViwed, setcurrentSelectViwed] = useState<string>("");
  // const prodcutsRef = useRef(null);
  // const nicheRef = useRef(null);
  // const [modifiedIndustries, setmodifiedIndustries] = useState<string[]>([]);

  useEffect(() => {
    setSearchVal("");
  }, [currentSelectViwed]);

  return (
    <Box
      p="12px 0px"
      css={{
        borderTop: "1px solid rgba(50, 50, 93, 0.15)",
        borderBottom: "1px solid rgba(50, 50, 93, 0.15)",
      }}
      mb={
        (filterData.product && filterData.product.length) ||
        (filterData.niche && filterData.niche.length)
          ? "0px"
          : "32px"
      }
      position={{ base: "relative", md: "initial" }}
    >
      <Box
        bgColor="#F2EFF6"
        flexDirection={{ base: "column", md: "row" }}
        alignItems={{ base: "start", md: "center" }}
        display="flex"
        padding="6px 5px"
      >
        <InputGroup
          w={{ base: "100%", md: "280px" }}
          mb={{ base: "16px", md: "0px" }}
        >
          <InputLeftElement
            pointerEvents="none"
            children={<RiSearchLine color="rgba(50, 50, 93, 0.8)" />}
          />
          <Input
            css={{
              border: "0.3px solid rgba(0, 0, 0, 0.3)",
            }}
            type="text"
            value={searchVal}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchVal(e.target.value)
            }
            fontSize="14px"
            mr="20px"
            rounded="8px"
            bg={{ base: "white", md: "white" }}
            focusBorderColor="#7211D4"
            placeholder="Search campaigns"
          />
        </InputGroup>

        <HStack spacing="14px">
          <CustomSelect
            title="Product"
            filterData={filterData}
            setfilterData={setfilterData}
            fieldVals={["SAAS", "ECOMMERCE"]}
            viewedSelect={currentSelectViwed}
            showVals={currentSelectViwed === "Product"}
            toggleValsVisibility={setcurrentSelectViwed}
          />

          <CustomSelect
            title="Niche"
            filterData={filterData}
            setfilterData={setfilterData}
            fieldVals={modifiedIndustries}
            viewedSelect={currentSelectViwed}
            showVals={currentSelectViwed === "Niche"}
            toggleValsVisibility={setcurrentSelectViwed}
          />
        </HStack>
      </Box>
    </Box>
  );
};

export default Filter;
