import { Input, Flex, Box, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const FormField = ({
  value,
  setChangeHandler,
  type,
  icon,
  placeholder,
  label,
  invalid,
  preventSpaces,
  onChangeAction,
}: {
  value: string;
  setChangeHandler: React.Dispatch<React.SetStateAction<any>>;
  type: string;
  label: string;
  placeholder: string;
  invalid?: boolean;
  preventSpaces?: boolean;
  icon: JSX.Element;
  onChangeAction?: () => any;
}) => {
  const [showPassword, setshowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setshowPassword(!showPassword);
  };

  return (
    <Box>
      <Text fontSize="0.9rem" fontWeight="600" color="#4D4D4D">
        {label}
      </Text>

      <Flex
        border={invalid ? "1px solid #cb1717" : "0.5px solid #B3B3B3"}
        borderRadius="8px"
        mt="10px"
        alignItems="center"
        p="8px 24px"
      >
        {icon}

        <input
          type={type === "password" && showPassword ? "text" : type}
          placeholder={placeholder}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (preventSpaces) {
              setChangeHandler(e.target.value.replace(/\s/g, ""));
            } else {
              setChangeHandler(e.target.value);
            }
            if (onChangeAction) {
              onChangeAction();
            }
          }}
          style={{
            outline: "0px solid transparent !important",
            background: "transparent",
            margin: "0px 20px",
            borderWidth: 0,
            padding: "0px",
            flexGrow: "1",
            color: "#4D4D4D",
          }}
        />

        {type === "password" ? (
          <Box cursor="pointer">
            {showPassword ? (
              <AiOutlineEye
                onClick={togglePasswordVisibility}
                color="#737373"
                size="24px"
              />
            ) : (
              <AiOutlineEyeInvisible
                color="#737373"
                size="24px"
                onClick={togglePasswordVisibility}
              />
            )}
          </Box>
        ) : (
          ""
        )}
      </Flex>
    </Box>
  );
};

export default FormField;
