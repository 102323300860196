import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { PromptModal } from "./PromptModal";
import { useNavigate, useSearchParams } from "react-router-dom";

const OrganizationHeader = () => {
  let tabs = [
    "Organization Profile",
    "Address",
    "Affiliate Information",
    "Niche",
    "Affiliate Background",
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [nextStep, setnextStep] = useState("");
  let [searchParams, _] = useSearchParams();
  const navigate = useNavigate();

  const proceed = () => {
    sessionStorage.removeItem("affiliateSettingsChange");
    onClose();
    navigate(`/settings/organization?tab=${nextStep}`);
  };

  const openModal = (step: string) => {
    onOpen();
    if (step === "Address") {
      setnextStep("address");
    } else if (step === "Affiliate Information") {
      setnextStep("affiliateinformation");
    } else if (step === "Niche") {
      setnextStep("niche");
    } else if (step === "Affiliate Background") {
      setnextStep("affiliatebackground");
    } else {
      setnextStep("organizationprofile");
    }
  };

  return (
    <Box position="sticky" top="70px" bgColor="#F1F4F9" zIndex={3}>
      <Flex
        bgColor="#8247e232"
        borderRadius="5px"
        justifyContent="space-between"
        display={{ base: "none", md: "flex" }}
      >
        {tabs.map((step: string) => (
          <Button
            bgColor={
              searchParams.get("tab") === step.replace(" ", "").toLowerCase()
                ? "white"
                : "transparent"
            }
            color={
              searchParams.get("tab") === step.replace(" ", "").toLowerCase()
                ? "#5C14CE"
                : "gray.600"
            }
            fontSize="14px"
            fontWeight="500"
            px={["3em", "0.75em"]}
            py="0.5rem"
            textTransform="uppercase"
            onClick={() => openModal(step)}
          >
            {step}
          </Button>
        ))}
      </Flex>

      <Flex
        justifyContent="space-between"
        display={{ base: "flex", md: "none" }}
      >
        {[1, 2, 3, 4, 5].map((step: number) => (
          <>
            <Text
              border="1px solid"
              borderColor="gray.300"
              color="#33325D"
              fontWeight="medium"
              py=".5em"
              px="1em"
              borderRadius="full"
              bgColor={
                searchParams.get("tab") ===
                tabs[step - 1].replace(" ", "").toLowerCase()
                  ? "#DDD2F6"
                  : "transparent"
              }
              onClick={() => openModal(tabs[step - 1])}
            >
              {step}
            </Text>
            {tabs[step - 1] !== "Affiliate Background" && (
              <Box
                width="1em"
                height="2px"
                bgColor="gray.300"
                mx=".4em"
                marginTop="1.2em"
              ></Box>
            )}
          </>
        ))}
      </Flex>

      <PromptModal
        isOpen={isOpen}
        title="Leave Page"
        onClose={onClose}
        handler={proceed}
        message={"Changes you made may not be saved."}
      />
    </Box>
  );
};

export default OrganizationHeader;
