import { Box, Center, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

type CampaignTabsProps = {
  currentTab: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
  setFilterData: React.Dispatch<
    React.SetStateAction<{
      date: string;
      status: string;
    }>
  >;
  setSearchVal: React.Dispatch<React.SetStateAction<string>>;
};

const CampaignTabs = ({
  currentTab,
  setCurrentTab,
  setFilterData,
  setSearchVal,
}: CampaignTabsProps) => {
  const navigate = useNavigate();

  const HandleTabChange = (tab: string) => {
    setCurrentTab(tab);
    navigate("/affiliate-campaigns");
    setFilterData({
      date: "",
      status: "",
    });
    setSearchVal("");
  };

  return (
    <HStack
      // p="3px"
      h="40px"
      border="0.5px solid rgba(50, 50, 93, 0.4)"
      borderRadius="8px"
      bgColor="white"
    >
      <Center
        h="100%"
        p="0px 5px"
        onClick={() => HandleTabChange("approved")}
        borderRadius="6px"
        cursor="pointer"
        bgColor={currentTab === "approved" ? "rgba(50, 50, 93, 0.17)" : ""}
      >
        <Text
          fontSize="0.9rem"
          fontWeight={currentTab === "approved" ? "600" : "500"}
          lineHeight="21px"
          color={
            currentTab === "approved" ? "#18181B" : "rgba(50, 50, 93, 0.8)"
          }
        >
          Approved
        </Text>
      </Center>

      <Center
        h="100%"
        p="0px 5px"
        bgColor={currentTab === "pending" ? "rgba(50, 50, 93, 0.17)" : ""}
        onClick={() => HandleTabChange("pending")}
        borderRadius="6px"
        cursor="pointer"
      >
        <Text
          fontSize="0.9rem"
          fontWeight={currentTab === "pending" ? "600" : "500"}
          lineHeight="21px"
          color={currentTab === "pending" ? "#18181B" : "rgba(50, 50, 93, 0.8)"}
        >
          Pending
        </Text>
      </Center>

      <Center
        h="100%"
        p="0px 5px"
        bgColor={currentTab === "suspended" ? "rgba(50, 50, 93, 0.17)" : ""}
        onClick={() => HandleTabChange("suspended")}
        borderRadius="6px"
        cursor="pointer"
      >
        <Text
          fontSize="0.9rem"
          fontWeight={currentTab === "suspended" ? "600" : "500"}
          lineHeight="21px"
          color={
            currentTab === "suspended" ? "#18181B" : "rgba(50, 50, 93, 0.8)"
          }
        >
          Suspended
        </Text>
      </Center>

      <Center
        h="100%"
        p="0px 5px"
        bgColor={currentTab === "declined" ? "rgba(50, 50, 93, 0.17)" : ""}
        onClick={() => HandleTabChange("declined")}
        borderRadius="6px"
        cursor="pointer"
      >
        <Text
          fontSize="0.9rem"
          fontWeight={currentTab === "declined" ? "600" : "500"}
          lineHeight="21px"
          color={
            currentTab === "declined" ? "#18181B" : "rgba(50, 50, 93, 0.8)"
          }
        >
          Declined
        </Text>
      </Center>
    </HStack>
  );
};

export default CampaignTabs;
