import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { CiCirclePlus } from "react-icons/ci";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
  IoArrowForwardSharp,
  IoChevronDown,
  IoChevronUp,
} from "react-icons/io5";
// import SyntaxHighlighter from "react-syntax-highlighter";
// import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
// import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { LuTrash2 } from "react-icons/lu";
import useUserToken from "../../../../../hooks/useUserToken";
import { useOutsideAlerter } from "../../../../../hooks/useOutsideAlerter";

const socialMediaOptions = [
  {
    label: "Twitter",
    value: "twitter",
  },
  {
    label: "Facebook",
    value: "facebook",
  },
  {
    label: "Reddit",
    value: "reddit",
  },
  {
    label: "Instagram",
    value: "instagram",
  },
  {
    label: "LinkedIn",
    value: "linkedIn",
  },
  {
    label: "TikTok",
    value: "tiktok",
  },
];

const descriptions: { [key: string]: any } = {
  lead: "Please include the tracking script set to track sales lead event only.",
  purchase: `Please include the tracking script set to track purchase event only`,
  click:
    "Please include the tracking script set to track specific engagement event only.",
};

const PostbackTracking = ({
  campaignId,
}: {
  campaignId: string | undefined;
}) => {
  const [options, setoptions] = useState<{ [key: string]: any }[]>([]);

  const [isselected, setisSelected] = useState(false);
  const [selectedOption, setselectedOption] = useState<any>(null);
  const [socialFilter, setsocialFilter] = useState("");
  const [newTrackingOptionName, setnewTrackingOptionName] = useState("");
  const [isProcessing, setisProcessing] = useState(false);
  const [loadingOptions, setloadingOptions] = useState(false);
  const [isDeleting, setisDeleting] = useState(false);
  const [refreshTracker, setrefreshTracker] = useState(0);

  const token = useUserToken();
  const toast = useToast();

  const socialMediaRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useOutsideAlerter(socialMediaRef, () => {
    return setsocialFilter("");
  });

  const toggleSizeHandler = (option: string) => {
    if (socialFilter === option) {
      return setsocialFilter("");
    }
    return setsocialFilter(option);
  };

  const handleAddNewOption = (option: string) => {
    let id = Math.random();

    setoptions([
      ...options.filter((opt) => !opt.unsaved),
      {
        name: option,
        id,
        description: descriptions[option.toLowerCase()] || "",
        socialMedia: "",
        unsaved: true,
        title: "",
        code: "",
      },
    ]);

    setselectedOption({
      name: option,
      id,
      description: descriptions[option.toLowerCase()] || "",
      socialMedia: "",
      unsaved: true,
      title: "",
      code: "",
    });
  };

  const displayOption = (opt: string) => {
    // let existingOpt = options.find(
    //   (option: any) => option.name?.toLowerCase() === opt.toLowerCase()
    // );
    // if (existingOpt && existingOpt.name) return false;
    return true;
  };

  const removeOption = async (opt: any) => {
    if (opt.unsaved) {
      setoptions(options.filter((option) => option.id !== opt.id));
      setselectedOption(null);
    } else {
      let bearerAuth = token;
      setisDeleting(true);

      await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          `post-back/delete/${selectedOption.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setselectedOption(null);
            setrefreshTracker(Math.random() * 300);
            onClose();
          }
          setisDeleting(false);
        })
        .catch((err) => {
          console.log("get campaign => " + err);
          setisDeleting(false);
        });
    }
  };

  const isValidJavaScript = (code: string) => {
    try {
      new Function(code);
      return true;
    } catch (e) {
      return false;
    }
  };

  const validateOption = (operation?: string) => {
    let valid = true,
      error = "";

    if (operation === "update") {
      if (!selectedOption.title) {
        valid = false;
        error = "Please provide a name for the tracking option";
      } else if (!selectedOption.code) {
        valid = false;
        error = "Please enter the script for this tracking option";
      } else if (!isValidJavaScript(selectedOption.code)) {
        valid = false;
        error = "Please enter a valid script for this tracking option";
      } else if (!selectedOption.socialMedia) {
        valid = false;
        error = "Please select a social media platform for the tracking option";
      }
    } else {
      if (!newTrackingOptionName) {
        valid = false;
        error = "Please provide a name for the tracking option";
      } else if (!selectedOption.code) {
        valid = false;
        error = "Please enter the script for this tracking option";
      } else if (!isValidJavaScript(selectedOption.code)) {
        valid = false;
        error = "Please enter a valid script for this tracking option";
      } else if (!selectedOption.socialMedia) {
        valid = false;
        error = "Please select a social media platform for the tracking option";
      } else if (!selectedOption.name) {
        valid = false;
        error = "Tracking event is missing.";
      }
    }

    return { valid, error };
  };

  const getTrackingOptions = async () => {
    let bearerAuth = token;
    setloadingOptions(true);
    setoptions([]);

    await fetch(
      process.env.REACT_APP_API_ENDPOINT + `post-back/get-all/${campaignId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data.postBack) {
          let reformedOptions = data.data.postBack.map((opt: any) => {
            return {
              id: opt.id || "",
              name: opt.event ? opt.event : "",
              title: opt.name || "",
              code: opt.script || "",
              description: opt.event ? descriptions[opt.event] : "",
              socialMedia: opt.social_media ? opt.social_media : "",
            };
          });

          setoptions(reformedOptions);
        }
        setloadingOptions(false);
      })
      .catch((err) => {
        console.log("get campaign => " + err);
        setloadingOptions(false);
      });
  };

  const saveTrackingOption = (operation: string) => {
    let validation = validateOption(operation);

    if (!validation.valid) {
      return toast({
        title: validation.error || "Failed to add tracking option",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }

    setisProcessing(true);
    let bearerAuth = token;

    const payload = {
      name:
        operation === "update" ? selectedOption.title : newTrackingOptionName,
      event: selectedOption.name.toLowerCase() || "",
      script: selectedOption.code || "",
      social_media: selectedOption.socialMedia.toLowerCase() || "",
    };

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `${
          operation === "update"
            ? `post-back/update/${selectedOption.id}`
            : `post-back/create/${campaignId}`
        }`,
      {
        method: operation === "update" ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setisProcessing(false);
          setselectedOption(null);
          setnewTrackingOptionName("");

          setrefreshTracker(Math.random() * 300);

          return toast({
            title: `Postback tracking option has been ${
              operation === "update" ? "updated" : "added"
            } successfully`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          setisProcessing(false);
          return toast({
            title: `Failed to ${
              operation === "update" ? "update" : "add"
            } postback tracking option`,
            description: data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      });
  };

  useEffect(() => {
    if (campaignId) {
      getTrackingOptions();
    }
  }, [refreshTracker, campaignId]);

  return (
    <Flex w={"100%"} flexDir={"column"} pb="40px">
      <Flex
        gap={3}
        flexDir={"column"}
        mb={"3px"}
        pb="17px"
        borderBottom="1px solid #E5E7EB"
      >
        <Text fontSize="1rem" fontWeight="600" color={"#32325D"}>
          Postback Tracking Setup
        </Text>
        <Text fontSize={"0.75rem"} color={"#32325DCC"}>
          This setup provides accurate advanced tracking and reporting of
          campaign conversions.
        </Text>
      </Flex>

      <Flex
        borderBottomColor={"#E5E7EB"}
        borderBottomWidth={1}
        paddingTop="50px"
        paddingBottom="24px"
        w={{ base: "100%", md: "85%" }}
        flexDir={["column", "row"]}
        gap={5}
        justifyContent="space-between"
      >
        <Text fontWeight={"600"} fontSize="0.875rem" color={"#32325D"}>
          Name
        </Text>

        <Flex maxW="500px" w="70%">
          <Input
            w={{ base: "100%", md: "90%" }}
            bgColor={"#F1F4FA"}
            px="32px"
            py="20px"
            color={"#32325D"}
            _focus={{
              borderColor: "#5C14CE",
            }}
            fontWeight="600"
            onChange={(e) => setnewTrackingOptionName(e.target.value)}
            value={newTrackingOptionName}
            rounded="8px"
            placeholder="Enter tracking name"
            _placeholder={{ color: "rgba(50, 50, 93, 0.4)" }}
          />
        </Flex>
      </Flex>

      <Flex
        borderBottomColor={"#E5E7EB"}
        borderBottomWidth={1}
        paddingTop="22px"
        paddingBottom="24px"
        w={{ base: "100%", md: "85%" }}
        flexDir={["column", "row"]}
        gap={5}
        justifyContent="space-between"
      >
        <Text fontWeight={"600"} fontSize="0.875rem" color={"#32325D"}>
          Tracking Options
        </Text>

        <Flex flexDir={"column"} gap={3} maxW="500px" w="70%">
          <Flex
            bgColor={"#F1F4FA"}
            justify={"center"}
            align={"center"}
            px={5}
            py="16px"
            gap={5}
            borderRadius={10}
            w={["100%", "90%"]}
            cursor={"pointer"}
            onClick={() => setisSelected((prev) => !prev)}
          >
            <CiCirclePlus fontWeight={"500"} />

            <Text fontWeight={"600"} color={"#32325D"}>
              Add tracking options
            </Text>
          </Flex>

          {isselected && (
            <Flex
              w={["100%", "90%"]}
              borderRadius={10}
              borderWidth={1}
              borderColor={"#c4c4c4"}
              flexDir={"column"}
            >
              <Box
                display={displayOption("Lead") ? "flex" : "none"}
                w={"100%"}
                cursor={"pointer"}
                py="4px"
                px="16px"
                color="#111827"
                _hover={{
                  color: "#5D13CF",
                  backgroundColor: "#F2F4F7",
                  fontWeight: "600",
                }}
                onClick={() => {
                  setisSelected((prev) => !prev);
                  handleAddNewOption("Lead");
                }}
              >
                <Text fontSize="0.9rem" lineHeight="1.5rem">
                  Lead
                </Text>
              </Box>

              <Box
                display={displayOption("Purchase") ? "flex" : "none"}
                w={"100%"}
                cursor={"pointer"}
                py="4px"
                px="16px"
                color="#111827"
                _hover={{
                  color: "#5D13CF",
                  backgroundColor: "#F2F4F7",
                  fontWeight: "600",
                }}
                onClick={() => {
                  setisSelected((prev) => !prev);
                  handleAddNewOption("Purchase");
                }}
              >
                <Text fontSize="0.9rem" lineHeight="1.5rem">
                  Purchase
                </Text>
              </Box>

              <Box
                display={displayOption("Click") ? "flex" : "none"}
                w={"100%"}
                cursor={"pointer"}
                py="4px"
                px="16px"
                color="#111827"
                _hover={{
                  color: "#5D13CF",
                  backgroundColor: "#F2F4F7",
                  fontWeight: "600",
                }}
                onClick={() => {
                  setisSelected((prev) => !prev);
                  handleAddNewOption("Click");
                }}
              >
                <Text fontSize="0.9rem" lineHeight="1.5rem">
                  Click
                </Text>
              </Box>
            </Flex>
          )}
          <Text fontSize={"12px"} color={"#32325D"}>
            Include other available tracking options by clicking add
          </Text>
        </Flex>
      </Flex>

      {loadingOptions ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          h="40vh"
          alignItems="center"
          pt="20px"
        >
          <Image className="loading" src="/assets/logo.png" height="40px" />
          <Text
            fontWeight="400"
            fontSize="1rem"
            lineHeight="70px"
            color="#000"
            mt="-15px"
          >
            Loading Tracking Options....
          </Text>
        </Box>
      ) : (
        <Box
          display={options.length ? "block" : "none"}
          w={{ base: "100%", md: "70%" }}
          mt="24px"
          py="15px"
          rounded="20px"
          bgColor="white"
          shadow="lg"
        >
          {options.map((opt: any, index) => (
            <Box key={opt.id}>
              <Flex
                justify={"space-between"}
                alignItems={"center"}
                cursor={"pointer"}
                py="16px"
                px="40px"
                borderBottom={
                  index + 1 === options.length && selectedOption?.id !== opt.id
                    ? ""
                    : "1px solid #E5E7EB"
                }
                onClick={() =>
                  selectedOption?.id === opt.id
                    ? setselectedOption("")
                    : setselectedOption(opt)
                }
              >
                <Text
                  fontWeight={"600"}
                  fontSize="0.875rem"
                  textTransform="capitalize"
                >
                  {opt.name}
                </Text>
                <Flex
                  color={"#6B7280"}
                  fontSize="1.25rem"
                  gap="20px"
                  alignItems="center"
                >
                  {selectedOption?.id === opt.id ? (
                    <>
                      {!selectedOption.unsaved ? (
                        <Button
                          px="12px"
                          color="white"
                          bg="#7F56D9"
                          fontSize="0.75rem"
                          rounded="8px"
                          fontWeight="500"
                          isLoading={isProcessing}
                          isDisabled={isProcessing}
                          onClick={(e) => {
                            e.stopPropagation();
                            saveTrackingOption("update");
                          }}
                        >
                          Update
                        </Button>
                      ) : (
                        ""
                      )}
                      <LuTrash2
                        className="delete-hover"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (opt.unsaved) {
                            removeOption(opt);
                          } else {
                            onOpen();
                          }
                        }}
                      />
                      <IoIosArrowUp />
                    </>
                  ) : (
                    <IoIosArrowDown />
                  )}
                </Flex>
              </Flex>

              {selectedOption?.id === opt.id ? (
                <Flex
                  flexDir={"column"}
                  px="40px"
                  pt="16px"
                  pb="14px"
                  borderBottom={
                    index + 1 === options.length ? "" : "1px solid #E5E7EB"
                  }
                >
                  {!opt.unsaved ? (
                    <Box mb="21px">
                      <Text
                        fontWeight={"600"}
                        fontSize="0.875rem"
                        color={"rgba(50, 50, 93, 0.4)"}
                        mb="16px"
                      >
                        Name
                      </Text>

                      <Input
                        value={selectedOption.title}
                        onChange={(e) =>
                          setselectedOption({
                            ...selectedOption,
                            title: e.target.value,
                          })
                        }
                        border="none"
                        borderBottom="1px solid #B6BDC3"
                        _focus={{
                          borderBottomColor: "#5C14CE",
                        }}
                        rounded="none"
                        variant="unstyled"
                        pb=".3em"
                        fontSize="0.875rem"
                        fontWeight="500"
                        color="4B5563"
                      />
                    </Box>
                  ) : (
                    ""
                  )}

                  <Box mb="24px">
                    <Text
                      fontWeight={"600"}
                      fontSize="0.875rem"
                      color={"rgba(50, 50, 93, 0.4)"}
                      mb="16px"
                    >
                      Social Media
                    </Text>

                    <Box ref={socialMediaRef}>
                      <Flex
                        borderBottom={
                          socialFilter === opt.id
                            ? "1px solid #5C14CE"
                            : "1px solid #B6BDC3"
                        }
                        pb=".3em"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        cursor="pointer"
                        onClick={() => toggleSizeHandler(opt.id || "")}
                      >
                        <Flex rowGap=".5em" columnGap=".5em" flexWrap="wrap">
                          {selectedOption.socialMedia ? (
                            <Text
                              mr=".5em"
                              fontSize="0.875rem"
                              fontWeight="500"
                              color="4B5563"
                              textTransform="capitalize"
                            >
                              {selectedOption.socialMedia}
                            </Text>
                          ) : (
                            <Text
                              fontSize="0.875rem"
                              fontWeight="500"
                              color={"#32325D"}
                              cursor="pointer"
                              onClick={() => toggleSizeHandler(opt.id || "")}
                            >
                              Select option
                            </Text>
                          )}
                        </Flex>
                        <Box>
                          {socialFilter !== opt.id ? (
                            <IoChevronDown
                              cursor="pointer"
                              fontSize="20px"
                              color="#737373"
                              onClick={() => toggleSizeHandler(opt.id || "")}
                            />
                          ) : (
                            <IoChevronUp
                              cursor="pointer"
                              fontSize="20px"
                              color="#737373"
                              onClick={() => toggleSizeHandler(opt.id || "")}
                            />
                          )}
                        </Box>
                      </Flex>

                      {socialFilter === opt.id && (
                        <Box
                          p={["1em", "1em", "1.5em"]}
                          my="1em"
                          boxShadow="xl"
                          rounded="xl"
                          maxH="60vh"
                          // overflowY="scroll"
                        >
                          {socialMediaOptions.map((social: any, i: any) => {
                            return (
                              <Box
                                key={i}
                                cursor="pointer"
                                _hover={{ bgColor: "gray.50" }}
                                p=".5em"
                                borderRadius="3px"
                                onClick={() => {
                                  setselectedOption({
                                    ...selectedOption,
                                    socialMedia: social.value,
                                  });
                                  toggleSizeHandler(opt.id);
                                }}
                              >
                                <Text
                                  ml="1em"
                                  color={"#32325D"}
                                  fontWeight="500"
                                  fontSize="0.875rem"
                                  textTransform="capitalize"
                                >
                                  {social.label}
                                </Text>
                              </Box>
                            );
                          })}
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Text
                    fontSize={"0.875rem"}
                    fontWeight="medium"
                    color={"#32325D"}
                    mb="10px"
                  >
                    {opt.description}
                  </Text>

                  <Textarea
                    resize="vertical"
                    height="190px"
                    bg="#F9FAFB"
                    px="20px"
                    py="17px"
                    fontWeight={"600"}
                    value={selectedOption.code}
                    onChange={(e) =>
                      setselectedOption({
                        ...selectedOption,
                        code: e.target.value,
                      })
                    }
                    color={"#32325D"}
                  />

                  {selectedOption.unsaved ? (
                    <Button
                      w="122px"
                      py="2px"
                      color="white"
                      bg="#7F56D9"
                      fontSize="0.875rem"
                      rounded="8px"
                      mt="20px"
                      fontWeight="500"
                      disabled={isProcessing}
                      isLoading={isProcessing}
                      onClick={() => saveTrackingOption("new")}
                    >
                      Save
                    </Button>
                  ) : (
                    ""
                  )}
                </Flex>
              ) : (
                ""
              )}

              <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
                <ModalOverlay />
                <ModalContent
                  borderRadius="1em"
                  p=".8em"
                  height="300px"
                  mx={{ base: ".6em", md: "auto" }}
                >
                  <Box
                    width=".8em"
                    height="300px"
                    position="absolute"
                    left="0"
                    bgGradient="linear(360deg, #7211D4 -1.38%, #FA9445 98.36%)"
                    top="0"
                    borderLeftRadius="1em"
                  ></Box>

                  <ModalCloseButton
                    bgColor="gray.100"
                    borderRadius="1.5em"
                    p="1.5em"
                    top="1.5em"
                    right="1.8em"
                    fontSize="1em"
                  />

                  <ModalBody mt="5em" mr="1em">
                    <Text
                      fontSize={{ base: "1em", md: "1.3em" }}
                      fontWeight="500"
                      mb={{ base: "6em", md: "4em" }}
                    >
                      Are you sure you want to delete this postback tracking
                      option?
                    </Text>

                    <Box
                      width="100%"
                      height="1px"
                      mx="auto"
                      bgColor="gray.200"
                      mt="1em"
                    ></Box>
                  </ModalBody>

                  <ModalFooter>
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      mb="1em"
                      flexDirection={{
                        md: "row",
                      }}
                      rowGap=".6em"
                    >
                      <Button
                        border="1px"
                        borderColor="#5C14CE"
                        color="#5C14CE"
                        bgColor="#fff"
                        fontWeight="500"
                        mr="20px"
                        px="2em"
                        _hover={{ bgColor: "#fff" }}
                        _focus={{ bgColor: "#fff" }}
                        fontSize={"0.875rem"}
                        onClick={onClose}
                      >
                        Cancel
                      </Button>

                      <Button
                        bgColor="#7211D4"
                        color="#F0F5FF"
                        fontSize={"0.875rem"}
                        rightIcon={<IoArrowForwardSharp />}
                        onClick={() => removeOption(opt)}
                        isLoading={isDeleting}
                      >
                        Yes, Delete
                      </Button>
                    </Flex>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Box>
          ))}
        </Box>
      )}
    </Flex>
  );
};

export default PostbackTracking;
