import { Box, Image, Text } from "@chakra-ui/react";

export const UploadFileInputUI = ({ filename }: { filename: string }) => {
  return (
    <Box
      display="flex"
      columnGap="1em"
      alignItems="center"
      border="1px solid"
      borderColor="gray.300"
      borderRadius=".4em"
      p="1em"
      cursor="pointer"
    >
      <Image
        src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/4d6f6275-3ebe-49fd-b353-21a6d3673757.svg"
        height="60px"
        width="60px"
      />

      <Text>
        {filename !== ""
          ? filename.length < 15
            ? filename
            : filename.slice(0, 14) + "..."
          : "Click to upload (max. 800 400px)"}
      </Text>
    </Box>
  );
};
