import React from "react";
import Dashboard from "../../components/affiliate/affiliate-dashboard";
const AffiliateLanding = () => {
  return (
    <>
      <Dashboard />
    </>
  );
};

export default AffiliateLanding;
