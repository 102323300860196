import { Stack, StackDivider } from "@chakra-ui/react";
import React from "react";
import AffiliateProperty from "./Detail";
import AffiliatePropertyS from "./Details";

type AffiliateOrg = {
  companyName: string;
  website: string;
  socials: any;
  country: string;
  address: string;
  state: string;
  city: string;
};

const AffiliateOrg = ({
  companyName,
  website,
  socials,
  country,
  address,
  state,
  city,
}: AffiliateOrg) => {
  console.log("socialls", socials);
  return (
    <Stack divider={<StackDivider borderColor="rgba(50, 50, 93, 0.3)" />}>
      <AffiliateProperty
        type="string"
        fieldData={companyName}
        label="Company Name"
      />

      <AffiliateProperty type="string" fieldData={website} label="Website" />

      <AffiliatePropertyS fieldData={socials} label="Socials" />
      <AffiliateProperty type="string" fieldData={country} label="Country" />
      <AffiliateProperty type="string" fieldData={address} label="Address" />
      <AffiliateProperty type="string" fieldData={state} label="State" />
      <AffiliateProperty type="string" fieldData={city} label="City" />
    </Stack>
  );
};

export default AffiliateOrg;
