import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Box, Text } from "@chakra-ui/react";
import "react-phone-number-input/style.css";
// import "./styles.css";

interface PropSchema {
  holder: string;
  value: any;
  label: string;
  stateHandler: React.Dispatch<React.SetStateAction<any>>;
  isDisabled: boolean;
}

export const Phone = ({
  holder,
  value,
  stateHandler,
  label,
  isDisabled,
}: PropSchema) => {
  return (
    <Box my=".5em" w={"100%"}>
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        defaultCountry="NG"
        placeholder={holder}
        value={value}
        onChange={stateHandler}
        required={true}
        style={{
          padding: ".5em 1em",
          borderWidth: "1px",
          borderColor: isDisabled ? "#e7e7e7" : "#7211D4",
          borderRadius: "10px",
          backgroundColor: "#fefdff",
          color: isDisabled ? "#b8babd" : "#000",
        }}
        disabled={isDisabled}
        className="accountSettingsPhone2"
      />
    </Box>
  );
};

export {};
