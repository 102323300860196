import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Text,
  Image,
  Input,
  Checkbox,
  useToast,
  Button,
} from "@chakra-ui/react";
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import { useContext, useEffect, useRef, useState } from "react";
import { FormInput } from "../formInput";
import { StepWrapper } from "./StepWrapper";
// @ts-ignore
import { getList } from "country-list-with-dial-code-and-flag";
import { InputHints } from "../InputHints";
import { BaseButton } from "../button";

import { IoArrowForwardSharp, IoArrowBackSharp } from "react-icons/io5";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ToastContainer } from "react-toastify";
import {
  setLocalUserInstance,
  getCurrentUserInstance,
  setCurrentUserType,
} from "../../state/localstorage";
import { Phone } from "../formInput/Phone";
import { TemporaryNavData } from "../context/TemporaryNavData";
import { RiArrowRightFill } from "react-icons/ri";

declare global {
  interface Window {
    Metricks: any;
  }
}

export const UserProfile = () => {
  const { shopify, setShopify } = useContext(TemporaryNavData);
  const navigate = useNavigate();
  const toast = useToast();
  let [searchParams, setSearchParams] = useSearchParams();
  const [integration, setIntegration] = useState<string | null>("");
  const [integrationToken, setIntegrationToken] = useState<string | null>("");
  const [integrationExistShp, setIntegrationExistShp] = useState(false);
  const [integrationExistWx, setIntegrationExistWx] = useState(false);
  const [integrationExist, setIntegrationExist] = useState(false);
  useEffect(() => {
    console.log("integration", searchParams.get("integration"));
    if (searchParams.get("integration") !== null) {
      setIntegrationExist(true);
      setIntegration(searchParams.get("integration"));
      setIntegrationToken(searchParams.get("token"));
      setIntegrationExistShp(
        searchParams.get("integration") === "shopify" ? true : false
      );
      setIntegrationExistWx(
        searchParams.get("integration") === "wix" ? true : false
      );
    }
  }, [searchParams, integration, integrationExistShp, integrationToken]);
  const [, setCookie] = useCookies(["user", "user_id", "access_token"]);

  const localUser = getCurrentUserInstance();

  const [firstname, setFirstname] = useState(
    localUser && localUser.firstname ? localUser.firstname : ""
  );
  const [lastname, setLastname] = useState(
    localUser && localUser.lastname ? localUser.lastname : ""
  );
  const [email, setEmail] = useState(
    localUser && localUser.email ? localUser.email : ""
  );
  const [phone, setPhone] = useState(
    localUser && localUser.phoneNumber ? localUser.phoneNumber : ""
  );
  const [countryName, setCountryName] = useState(
    localUser && localUser.countryName ? localUser.countryName : "NG"
  );
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [bot, setBot] = useState(false);

  const [validKeys, setValidKeys] = useState({
    password: true,
    confirmPassword: true,
  });
  const [loading, setLoading] = useState(false);
  const formViewRef = useRef(null);
  const [captchaHint, setCaptchaHint] = useState(false);

  const submitHandler = async () => {
    // console.log("clicked me");
    if (bot) {
      setCaptchaHint(true);
      return;
    }
    if (!bot && captchaHint) {
      setCaptchaHint(false);
    }

    if (
      (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)^[^ ]+$/.test(password) &&
        !/[^\w\s\_]/.test(password) &&
        !/\d/.test(password)) ||
      password !== confirmPassword
    ) {
      return;
    }

    if (password === "Test@123") {
      setLoading(false);
      toast({
        title: "Kindly choose a different variation of characters for Password",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    setLoading(true);

    // await window.Metricks.lead({
    //   customer_id: email,
    //   email: email,
    //   name: `${firstname} ${lastname}`,
    //   metadata: {},
    // });
    if (phone) {
      if (phone.legnth < 4) {
        setLoading(false);

        toast({
          title: "phone number required",
          status: "error",
          duration: 4000,
          position: "top-right",
        });
        return;
      }
    } else {
      setLoading(false);

      toast({
        title: "phone number required",
        status: "error",
        duration: 4000,
        position: "top-right",
      });

      return;
    }

    fetch(process.env.REACT_APP_API_ENDPOINT + "auth/signup/step1", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        firstname,
        lastname,
        email,
        password,
        phoneNumber: phone,
        userType: "advertiser",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data.success) {
          let expires = new Date();
          expires.setTime(expires.getTime() + 720 * 60 * 60 * 1000);

          setCurrentUserType("advertiser");
          setLocalUserInstance({
            ...data.data.user,
            phoneNumber: phone,
            countryName,
          });
          setCookie("user", data.data.user, {
            path: "/",
            expires,
          });
          setCookie("user_id", data.data.user.user_id, {
            path: "/",
            expires,
          });

          setCookie("access_token", data.data.token, {
            path: "/",
            expires,
          });

          navigate(
            `/signup/company-profile?default-country=${
              getList().filter((info: any) => info.code === countryName)[0].name
            }`
          );
        } else {
          // @ts-ignore

          toast({
            title: data.message,
            status: "error",
            duration: 4000,
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };
  const submitHandlerIntegrationWix = async () => {
    console.log("me wix");
    if (bot) {
      setCaptchaHint(true);
      return;
    }
    if (!bot && captchaHint) {
      setCaptchaHint(false);
    }

    if (
      (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)^[^ ]+$/.test(password) &&
        !/[^\w\s\_]/.test(password) &&
        !/\d/.test(password)) ||
      password !== confirmPassword
    ) {
      return;
    }

    setLoading(true);
    const data = {
      firstname,
      lastname,
      email,
      password,
      phoneNumber:
        getList().filter((info: any) => info.code === countryName)[0]
          .dial_code + phone,
      token: integrationToken,
    };

    fetch(
      process.env.REACT_APP_API_ENDPOINT + "auth/signup/step1?integration=wix",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          firstname,
          lastname,
          email,
          password,
          phoneNumber:
            getList().filter((info: any) => info.code === countryName)[0]
              .dial_code + phone,
          userType: "advertiser",
          token: integrationToken,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.success) {
          let expires = new Date();
          expires.setTime(expires.getTime() + 720 * 60 * 60 * 1000);
          console.log(data.data);
          setLocalUserInstance({
            ...data.data.user,
            phoneNumber: phone,
            countryName,
          });
          setCookie("user", data.data.user, {
            path: "/",
            expires,
          });
          setCookie("user_id", data.data.user.user_id, {
            path: "/",
            expires,
          });

          setCookie("access_token", data.data.token, {
            path: "/",
            expires,
          });

          navigate(
            `/signup/company-profile?default-country=${
              getList().filter((info: any) => info.code === countryName)[0].name
            }`
          );
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
          console.log("error", data);
          toast({
            title: data.message,
            status: "error",
            duration: 4000,
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };
  const submitHandlerIntegration = async () => {
    setShopify(true);
    if (bot) {
      setCaptchaHint(true);
      return;
    }
    if (!bot && captchaHint) {
      setCaptchaHint(false);
    }

    if (
      (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)^[^ ]+$/.test(password) &&
        !/[^\w\s\_]/.test(password) &&
        !/\d/.test(password)) ||
      password !== confirmPassword
    ) {
      return;
    }

    setLoading(true);
    const data = {
      firstname,
      lastname,
      email,
      password,
      phoneNumber:
        getList().filter((info: any) => info.code === countryName)[0]
          .dial_code + phone,
    };

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "auth/signup/step1?integration=shopify",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password,
          token: integrationToken,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.success) {
          let expires = new Date();
          expires.setTime(expires.getTime() + 720 * 60 * 60 * 1000);
          console.log(data.data);
          setLocalUserInstance({
            ...data.data.user,
            phoneNumber: phone,
            countryName,
          });
          setCookie("user", data.data.user, {
            path: "/",
            expires,
          });
          setCookie("user_id", data.data.user.user_id, {
            path: "/",
            expires,
          });

          setCookie("access_token", data.data.token, {
            path: "/",
            expires,
          });

          navigate(
            `/signup/company-profile?default-country=${
              getList().filter((info: any) => info.code === countryName)[0].name
            }`
          );
        } else {
          // @ts-ignore
          formViewRef.current.scrollIntoView();
          data.error.forEach((err: string) =>
            toast({
              title: err,
              status: "error",
              duration: 4000,
              position: "top-right",
            })
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  return (
    <StepWrapper
      step="STEP 1 0F 6"
      header="Create your advertiser account"
      baseText="Let's supercharge your affiliate marketing campaigns!"
      stepsMap={[
        {
          title: "User Profile",
          status: "current",
          key: 1,
        },
        {
          title: "Company Profile",
          status: "default",
          key: 2,
        },

        {
          title: "Marketing Assets",
          status: "default",
          key: 3,
        },
        {
          title: "Product Details",
          status: "default",
          key: 4,
        },
        {
          title: "Niche",
          status: "default",
          key: 5,
        },
        {
          title: "Billing",
          status: "default",
          key: 6,
        },
      ]}
    >
      <Flex
        ref={formViewRef}
        position={"relative"}
        direction={"column"}
        alignItems={"center"}
        w={["90%", "60%"]}
      >
        <Flex w={"100%"}>
          <ToastContainer />
          <form
            style={{ position: "relative" }}
            onSubmit={
              (e) => {
                e.preventDefault();
                if (integrationExist) {
                  if (integrationExistShp) {
                    // @ts-ignore
                    submitHandlerIntegration();
                    // console.log("shopify");
                  } else {
                    // @ts-ignore
                    submitHandlerIntegrationWix();
                    // console.log("wix");
                  }
                } else {
                  // @ts-ignore
                  submitHandler();
                  // console.log("none");
                }
              }
              // integrationExistShp ? submitHandlerIntegration : submitHandler
            }
          >
            {!integrationExistShp && (
              <FormInput
                label="First Name"
                type="text"
                holder="James"
                value={firstname}
                stateHandler={setFirstname}
                required={true}
              />
            )}
            {!integrationExistShp && (
              <FormInput
                label="Last Name"
                type="text"
                holder="Conell"
                value={lastname}
                stateHandler={setLastname}
                required={true}
              />
            )}
            {!integrationExistShp && (
              <FormInput
                label="Email Address"
                type="text"
                holder="jamesconell@domain.com "
                value={email}
                stateHandler={setEmail}
                required={true}
              />
            )}

            <Box>
              {!integrationExistShp && (
                <Phone
                  holder=""
                  label="Phone Number"
                  value={phone}
                  stateHandler={setPhone}
                />
              )}

              <FormInput
                label="Password"
                type="password"
                value={password}
                preventSpaces={true}
                stateHandler={setPassword}
                required={true}
                holder="Enter password"
                inputNotValid={validKeys.password == false}
              />
              {password !== "" &&
                (password.length < 8 ||
                  !/[A-Z]/.test(password) ||
                  !/[a-z]/.test(password) ||
                  !/\d/.test(password) ||
                  !/[^\w\s\_]/.test(password)) && (
                  <Box mt="-1em">
                    <InputHints
                      showWhen={password !== ""}
                      title="Your password must be"
                      hints={[
                        {
                          passed: password.length > 7 ? true : false,
                          text: "at least 8 characters long",
                        },
                        {
                          passed: /[A-Z]/.test(password) ? true : false,
                          text: "at least one uppercase letter",
                        },
                        {
                          passed: /[a-z]/.test(password) ? true : false,
                          text: "at least one lowercase letter",
                        },
                        {
                          passed: /\d/.test(password) ? true : false,
                          text: "at least one number",
                        },
                        {
                          passed: /[^\w\s\_]/.test(password) ? true : false,
                          text: "at least one special character e.g. @#$%^&*()",
                        },
                      ]}
                    />
                  </Box>
                )}

              <FormInput
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                stateHandler={setConfirmPassword}
                required={true}
                preventSpaces={true}
                inputNotValid={
                  (confirmPassword !== "" && password !== confirmPassword) ||
                  !validKeys.confirmPassword
                }
                holder="******************"
              />
              {confirmPassword !== "" && password !== confirmPassword && (
                <Box mt="-1em">
                  <InputHints
                    showWhen={confirmPassword !== ""}
                    title="Passwords do not match"
                    hints={[
                      {
                        passed: password === confirmPassword ? true : false,
                        text: "Your passwords must match",
                      },
                    ]}
                  />
                </Box>
              )}

              <Flex mt={"50px"}>
                <Checkbox
                  colorScheme="blue"
                  alignItems="flex-start"
                  isRequired={true}
                  m={"4px"}
                  mr={5}
                ></Checkbox>
                <Text>
                  I have read and accepted Metricks's{" "}
                  <Link
                    to="https://www.metricks.io/termsandconditions"
                    target="_blank"
                    // color="#6e2ed4"
                  >
                    <Text as="span" color="#6e2ed4">
                      Terms of Service
                    </Text>
                  </Link>{" "}
                  and
                  <Link
                    to="https://www.metricks.io/PrivacyPolicy"
                    target="_blank"
                  >
                    {" "}
                    <Text as="span" color="#6e2ed4">
                      Privacy Policy{" "}
                    </Text>
                  </Link>{" "}
                  and hereby agree to receive occasional communication with
                  product news and relevant educational content.
                </Text>
              </Flex>

              {/* <Box my="1em">

              <ReCAPTCHA
                // @ts-ignore
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                onChange={() => setBot(false)}
                required={true}
              /> 
              {captchaHint && (
                <small style={{ color: "red" }}>
                  Please complete reCAPTCHA to proceed.
                </small>
              )}
            </Box> */}
            </Box>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              mb={"50px"}
              flexDirection={["column-reverse", "row"]}
              // position={"absolute"}
              // bottom={10}
              w={"100%"}
              mt={"50px"}
            >
              <Button
                px="0"
                color="#5C14CE"
                bgColor="#fff"
                fontWeight="500"
                fontSize="14px"
                _hover={{ bgColor: "#fff" }}
                _focus={{ bgColor: "#fff" }}
                onClick={() => window.open("https://metricks.io", "_self")}
                leftIcon={<IoArrowBackSharp />}
              >
                Back to the homepage
              </Button>
              <Box width={["100%", "50%", "28%"]}>
                <BaseButton
                  text="Next step"
                  textColor="#fff"
                  bgColor="#5C14CE"
                  rightIcon={<RiArrowRightFill />}
                  type="submit"
                  loadingState={loading}
                />
              </Box>
            </Flex>
          </form>
        </Flex>
      </Flex>
    </StepWrapper>
  );
};
