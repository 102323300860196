import { atom } from "recoil";

export const loginState = atom({
  key: "loginState",
  default: {},
});

export const forgotState = atom({
  key: "forgotState ",
  default: "",
});
