import { Box, Text, Stack, css } from "@chakra-ui/react";
import useUserToken from "../../../../../hooks/useUserToken";
import Countries from "./countries";
import Iframe from "react-iframe";
import { useEffect, useState } from "react";
import parse from "html-react-parser";

const Overview = ({ campaign }: any) => {
  const token = useUserToken();
  const [campaignFolders, setcampaignFolders] = useState<any[]>([]);

  const getDriveFolders = () => {
    if (!campaign.id) return;

    let bearerAuth = token;
    fetch(
      process.env.REACT_APP_API_ENDPOINT + `/drive/all-folders/${campaign.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setcampaignFolders(data.data.campaignDriveFolders);
        }
      })
      .catch((err) => {
        console.log("get drive-folders => " + err);
      });
  };

  useEffect(() => {
    getDriveFolders();
  }, [campaign]);

  const renderCommission = () => {
    if (campaign.commission_type === "fixed")
      return `${campaign.commission_amount} ${campaign.currency}`;

    if (campaign.commission_type === "percentage")
      return `${campaign.commission_percentage} %`;

    return `0 usd`;
  };

  return (
    <Stack
      pb="100px"
      pl={{ base: "19px", md: "47px" }}
      pr={{ base: "19px", md: "47px" }}
    >
      <Box
        background="#F1F4FA"
        borderRadius=" 8px"
        h="95px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        overflowX="auto"
        css={css({
          scrollbarWidth: "none",
          "::-webkit-scrollbar": { display: "none" },
          "-webkit-overflow-scrolling": "touch",
          // boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
          border: "0 none",
        })}
        mb="22px"
      >
        <Box
          display="flex"
          pl="33px"
          pr={{ base: "30px", md: "0px" }}
          w={{ base: "max-content", md: "auto" }}
          flex="1"
          alignItems="center"
          flexDirection="column"
          borderRight="1px solid rgba(50, 50, 93, 0.5)"
        >
          <Text
            color="#32325D"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            opacity="0.8"
            letterSpacing="0.02em"
            textTransform="capitalize"
          >
            {campaign.product_type === "saas"
              ? campaign.product_type.slice(0, 3) +
                campaign.product_type.slice(3).toUpperCase()
              : campaign.product_type}
          </Text>
          <Text
            color="rgba(50, 50, 93, 0.8)"
            lineHeight="18px"
            fontSize="12px"
            fontWeight="400"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            Product
          </Text>
        </Box>
        <Box
          display="flex"
          pl="33px"
          pr={{ base: "30px", md: "0px" }}
          flex="1"
          alignItems="center"
          justifyContent="flex-start"
          flexDirection="column"
          borderRight="1px solid rgba(50, 50, 93, 0.5)"
        >
          <Text
            color="#32325D"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            opacity="0.8"
            letterSpacing="0.02em"
            textTransform="uppercase"
            w={{ base: "max-content", md: "auto" }}
          >
            {renderCommission()}
          </Text>
          <Text
            color="rgba(50, 50, 93, 0.8)"
            lineHeight="18px"
            fontSize="12px"
            fontWeight="400"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            Commission
          </Text>
        </Box>
        <Box
          display="flex"
          pl="33px"
          pr={{ base: "30px", md: "0px" }}
          flex="1"
          alignItems="center"
          flexDirection="column"
          borderRight="1px solid rgba(50, 50, 93, 0.5)"
        >
          <Text
            color="#32325D"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            Shipping
          </Text>
          <Text
            color="rgba(50, 50, 93, 0.8)"
            lineHeight="18px"
            fontSize="12px"
            fontWeight="400"
            w={{ base: "max-content", md: "auto" }}
            opacity="0.8"
            letterSpacing="0.02em"
          >
            {campaign?.countriesToShip?.length || 0} Countries
          </Text>
        </Box>
        <Box
          flex="1"
          display="flex"
          pl="33px"
          pr={{ base: "30px", md: "0px" }}
          alignItems={"center"}
          flexDirection="column"
        >
          <Text
            color="#32325D"
            lineHeight="21px"
            fontSize="14px"
            fontWeight="500"
            opacity="0.8"
            w={{ base: "max-content", md: "auto" }}
            letterSpacing="0.02em"
          >
            {campaign?.createdAt?.split("T")[0]}
          </Text>
          <Text
            color="rgba(50, 50, 93, 0.8)"
            lineHeight="18px"
            fontSize="12px"
            fontWeight="400"
            opacity="0.8"
            letterSpacing="0.02em"
          >
            Launched
          </Text>
        </Box>
      </Box>

      <Box>
        {campaignFolders.length > 0 && (
          <Iframe
            url={`https://drive.google.com/embeddedfolderview?id=${campaignFolders[0]?.drive_folder_id}#grid`}
            width="100%"
            height="100%"
            display="block"
            position="relative"
          />
        )}
      </Box>

      <Countries countries={campaign.countriesToShip} />

      <Box
        padding={["24px 12px", "24px 28px"]}
        background="#F1F4FA"
        borderRadius=" 8px"
      >
        <Text
          color="#32325D"
          lineHeight="18px"
          fontSize="12px"
          fontWeight="500"
          opacity="0.8"
          letterSpacing="0.02em"
          textTransform="uppercase"
        >
          Description
        </Text>
        {campaign?.description ? (
          <Box
            color="rgba(50, 50, 93, 0.8)"
            lineHeight="22px"
            fontSize="14px"
            fontWeight="300"
            opacity="0.8"
            pt={["13px", "26px"]}
          >
            {parse(campaign?.description || "")}
          </Box>
        ) : (
          ""
        )}
      </Box>
    </Stack>
  );
};

export default Overview;
