import { Box, HStack, Image, Skeleton, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import parse from "html-react-parser";

type CampaignProps = {
  id: number | null;
  campaign_name: string;
  product_type: string | null;
  countriesToShip: string[] | null;
  commission_amount: number | null;
  commission_percentage: number | null;
  commission_type: string | null;
  currency: string | null;
  description: string | null;
  background_image: string;
};

const CampaignInfo = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [campaign, setCampaign] = useState<CampaignProps>({
    id: null,
    campaign_name: "",
    product_type: "",
    countriesToShip: null,
    commission_amount: null,
    commission_percentage: null,
    commission_type: "fixed",
    currency: "",
    description: "",
    background_image: "",
  });
  const [loading, setloading] = useState<boolean>(true);

  useEffect(() => {
    if (!searchParams.get("id")) {
      navigate("/login");
    } else {
      getCampaign(searchParams.get("id"));
    }
  }, []);

  const getCampaign = (id: string | null) => {
    let affiliate = searchParams.get("affiliate_name") || "";
    let campaignId = searchParams.get("id") || "";
    const affStatus = searchParams.get("status");

    fetch(process.env.REACT_APP_API_ENDPOINT + `get-campaign/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setCampaign({
            id: data.data.campaign.id,
            campaign_name: data.data.campaign.campaign_name,
            product_type: data.data.campaign.product_type,
            countriesToShip: data.data.campaign.countriesToShip,
            commission_amount: data.data.campaign.commission_amount,
            commission_percentage: data.data.campaign.commission_percentage,
            commission_type: data.data.campaign.commission_type,
            currency: data.data.campaign.currency,
            description: data.data.campaign.description,
            background_image: data.data.campaign.background_image,
          });

          sessionStorage.setItem("affiliate", affiliate);
          sessionStorage.setItem("campaignId", campaignId);
          if (affStatus) {
            sessionStorage.setItem("affiliateStatus", affStatus);
          }
          setloading(false);
        } else {
          setloading(false);
        }
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const renderCommission = () => {
    if (campaign.commission_type === "fixed")
      return `${campaign.commission_amount} ${campaign.currency}`;

    if (campaign.commission_type === "percentage")
      return `${campaign.commission_percentage} %`;

    return `0 usd`;
  };

  return (
    <Box
      position="relative"
      boxShadow="0px 16px 32px rgba(147, 147, 147, 0.25)"
      maxWidth={{ base: "100%", md: "640px" }}
      pt="38px"
      display="flex"
      flexDirection="column"
      ml={{ base: "0px", md: "60px" }}
      mb={{ base: "60px", md: "0px" }}
      alignItems="center"
    >
      <Image
        display={{ base: "block", md: "none" }}
        src="/assets/logo.png"
        mb="48px"
        height="52px"
        width="98px"
        objectFit="contain"
      />

      {searchParams.get("affiliate_name") ? (
        <Text fontSize="18px" fontWeight="700" lineHeight="40px" mb="33px">
          <span style={{ textTransform: "capitalize" }}>
            {searchParams.get("affiliate_name")}
          </span>{" "}
          has invited you to join their campaign
        </Text>
      ) : (
        <Text fontSize="18px" fontWeight="700" lineHeight="40px" mb="33px">
          You have been invited to join{" "}
          <span style={{ textTransform: "capitalize" }}>
            {campaign.campaign_name}
          </span>
        </Text>
      )}

      <Box
        borderRadius="8px"
        position="relative"
        css={{ zIndex: 3 }}
        bgColor="white"
        mb="-50px"
        ml={{ md: "48px" }}
        mr={{ md: "48px" }}
        border="0.5px solid #9797bd67"
        padding={{ base: "14px", md: "20px 35px" }}
      >
        {loading ? (
          <Stack width="500px">
            <Skeleton height="40px" width="300px" />
            <Skeleton height="80px" />
            <Skeleton height="20px" width="40" />
            <Skeleton height="40px" />
          </Stack>
        ) : (
          <>
            <Text
              fontSize="32px"
              color="#32325D"
              lineHeight="48px"
              fontWeight="600"
              mb="22px"
            >
              {campaign.campaign_name}
            </Text>

            <HStack spacing={{ base: "14px", md: "22px" }} color="#32325D">
              <Box>
                <Text
                  fontSize="16px"
                  lineHeight="24px"
                  textTransform="capitalize"
                  fontWeight="600"
                >
                  {campaign.product_type === "saas"
                    ? campaign.product_type.slice(0, 3) +
                      campaign.product_type.slice(3).toUpperCase()
                    : campaign.product_type}
                </Text>
                <Text fontSize="14px" lineHeight="21px" fontWeight="400">
                  Product Type
                </Text>
              </Box>

              <Box
                borderLeft="0.5px solid #9797BD"
                borderRight="0.5px solid #9797BD"
                padding="0px 25px"
              >
                <Text
                  fontSize="16px"
                  textTransform="uppercase"
                  lineHeight="24px"
                  fontWeight="500"
                >
                  {renderCommission()}
                </Text>
                <Text fontSize="14px" lineHeight="21px" fontWeight="400">
                  Commission
                </Text>
              </Box>

              <Box>
                <Text fontSize="16px" lineHeight="24px" fontWeight="500">
                  {campaign.countriesToShip
                    ? campaign.countriesToShip?.length
                    : 0}{" "}
                  {campaign.countriesToShip &&
                  campaign.countriesToShip?.length > 1
                    ? " Countries"
                    : " Country"}
                </Text>
                <Text fontSize="14px" lineHeight="21px" fontWeight="400">
                  Shipping
                </Text>
              </Box>
            </HStack>

            <Text
              fontSize="16px"
              color="#1F1F43"
              lineHeight="24px"
              fontWeight="500"
              mt="23px"
              mb="14px"
            >
              About Offer
            </Text>

            <Text
              fontSize="14px"
              lineHeight="21px"
              fontWeight="400"
              wordBreak={
                campaign.description?.length === 1 ? "break-all" : "normal"
              }
              color="rgba(50, 50, 93, 0.71)"
              css={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": 3,
                "-webkit-box-orient": "vertical",
                maxWidth: "100%",
              }}
            >
              {campaign &&
                campaign.description &&
                parse(campaign.description || "")}
            </Text>
          </>
        )}
      </Box>

      <Box width="100%">
        {loading ? (
          <Skeleton height="45vh" width="100%" />
        ) : (
          <Image
            objectFit="cover"
            width="100%"
            h="45vh"
            src={
              campaign.background_image ? campaign.background_image : "empty"
            }
          />
        )}
      </Box>
    </Box>
  );
};

export default CampaignInfo;
