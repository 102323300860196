export const supportedIntegrations = [
  {
    name: "Javascript",
    color: "#000",
    bgColor: "#F7DF1E",
    icon: "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/9628733a-f453-4ef5-b16c-b4b107b1d837.svg",
  },
  {
    name: "Wix",
    bgColor: "#116DFF",
    color: "#fff",
    icon: "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/c603ab84-a628-4d22-a6a9-1f1ec0f8149d.svg",
  },
  //   { name: "Magento" },
  {
    name: "WooCommerce",
    bgColor: "#9B5C8F",
    color: "#fff",
    icon: "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/f3d94f31-3ab9-456d-ba36-38dd61a0986e.svg",
  },
  {
    name: "Paddle",
    bgColor: "#45567C",
    color: "#fff",
    icon: "/assets/svg/paddle_icon.svg",
  },
];
