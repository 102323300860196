// @ts-ignore
import { getList } from "country-list-with-dial-code-and-flag";
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Text,
} from "@chakra-ui/react";
import { BsCheckSquareFill, BsFillCaretUpFill, BsSearch } from "react-icons/bs";
import React, { useEffect, useState, useRef } from "react";
import {
  IoArrowBackSharp,
  IoArrowForwardSharp,
  IoChevronDown,
  IoChevronUp,
} from "react-icons/io5";
import {
  AiFillInstagram,
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillLinkedin,
} from "react-icons/ai";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast, ToastContainer } from "react-toastify";
import { MdOutlineSocialDistance, MdCancel } from "react-icons/md";
import SettingsInput from "./SettingsInput";
import { FormInput } from "../../../formInput";
import OrganizationSettingsFooter from "./OrganizationSettingsFooter";

const useOutsideAlerter = (ref: any, closeHandler: Function) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        closeHandler();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

const AffiliateOrgInfo = ({
  affiliate_type,
  contentPublisher,
  media_buyer,
  social_influencer,
  setRefreshData,
}: {
  affiliate_type: string;
  contentPublisher: any;
  media_buyer: any[];
  social_influencer: any;
  setRefreshData: React.Dispatch<React.SetStateAction<number>>;
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [orgType, setOrgType] = useState(false);

  useEffect(() => {
    if (searchParams.get("type") !== null) {
      setOrgType(searchParams.get("type") ? true : false);
    }
  }, [searchParams, orgType]);

  const type = [
    "Content Publisher/Email Marketer",
    "PPC/Media Buyer",
    "Social Influencer",
  ];
  const size = ["0-100k", "100-250k", "250-500k", "500k-1M"];

  const [searchQuery, setSearchQuery] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [showIndustryFilter, setShowIndustryFilter] = useState(false);
  const [showTypeFilter, setShowTypeFilter] = useState(false);
  const [showSizeFilter, setShowSizeFilter] = useState(false);
  const [allAffiliateType, setAllAffiliateType] = useState(
    affiliate_type
      ? type.map((country: any) => {
          if (
            affiliate_type === "content_publisher" &&
            country === "Content Publisher/Email Marketer"
          ) {
            return { country, selected: true };
          } else if (
            affiliate_type === "media_buyer" &&
            country === "PPC/Media Buyer"
          ) {
            return { country, selected: true };
          } else if (
            affiliate_type === "social_influencer" &&
            country === "Social Influencer"
          ) {
            return { country, selected: true };
          } else {
            return { country, selected: false };
          }
        })
      : type.map((country: any) => {
          return { country, selected: false };
        })
  );
  const [showIndustries, setShowIndustries] = useState(false);
  const toggleHandlerMedia = () => {
    return setShowIndustries(!showIndustries);
  };
  const [allSize, setAllSize] = useState(
    contentPublisher && contentPublisher?.avgVisitors
      ? size.map((country: any) => {
          if (contentPublisher?.avgVisitors === country) {
            return { country, selected: true };
          } else {
            return { country, selected: false };
          }
        })
      : size.map((country: any) => {
          return { country, selected: false };
        })
  );
  const toggleHandler = () => {
    return setShowFilter(!showFilter);
  };
  const wrapperIndustryRef = useRef(null);
  useOutsideAlerter(wrapperIndustryRef, () => setShowFilter(false));
  const toggleTypeHandler = () => {
    return setShowTypeFilter(!showTypeFilter);
  };
  const toggleIndustryHandler = () => {
    return setShowIndustryFilter(!showIndustryFilter);
  };
  const toggleSizeHandler = () => {
    return setShowSizeFilter(!showSizeFilter);
  };
  const wrapperSizeRef = useRef(null);
  useOutsideAlerter(wrapperSizeRef, () => setShowSizeFilter(false));
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setShowIndustries(false));

  const wrapperTypeRef = useRef(null);
  useOutsideAlerter(wrapperTypeRef, () => setShowTypeFilter(false));
  const [socialData, setSocialData] = useState({ title: "", url: "" });
  const [socials, setSocials]: [
    socials: { title: string; url: string }[],
    setSocials: React.Dispatch<React.SetStateAction<any>>
  ] = useState(
    social_influencer && social_influencer.avgFollowers
      ? Object.keys(social_influencer?.avgFollowers || {}).map(
          (item: string) => {
            return {
              title: item,
              url: social_influencer?.avgFollowers[item] || "",
            };
          }
        )
      : [{ title: "", url: "" }]
  );
  const addSocialHandler = (data: string) => {
    socialData.url = instagramSize;
    socialData.title = data;
    if (socialData.title === "") {
      return toast.warn("Please enter the name of the social media plaform");
    }

    let socialExists = false;

    for (let social of socials) {
      if (social.title.toLowerCase() === socialData.title.toLowerCase()) {
        socialExists = true;
      }
    }

    if (socialExists) {
      return toast.warn(
        "This social media platform is already included in the list of socials"
      );
    }

    const newState = influencerData.map((data: any, x: any) => {
      if (socialData.title.toLowerCase() === data.key.toLowerCase()) {
        return { ...data, selected: true };
      } else {
        return data;
      }
    });
    setInfluencerData(newState);

    setSocials((currentSocials: { title: string; url: string }[]) => [
      ...currentSocials.filter((socialData) => socialData.title !== ""),
      socialData,
    ]);

    setSocialData({ title: "", url: "" });
  };

  const urlPattern = new RegExp(
    // "^(https?:\\/\\/)?" + // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$", // validate fragment locator
    "i"
  );

  const [webURL, setWebURL] = useState(contentPublisher?.website || "");
  const [instagramSize, setInstagramSize] = useState(size[0]);
  const [newUpdate, setnewUpdate] = useState(false);
  const [count, setcount] = useState(1);

  const affType = () => {
    return allAffiliateType
      .filter((countryData: any) => countryData.selected)
      .map((data: any) => data.country)[0];
  };

  const media = [
    {
      key: "Facebook",
      selected: false,
    },
    {
      key: "Instagram",
      selected: false,
    },
    {
      key: "Google Display Network",
      selected: false,
    },
    {
      key: "Paid Search",
      selected: false,
    },
    {
      key: "Organic SEO",
      selected: false,
    },
    {
      key: "Native",
      selected: false,
    },
    {
      key: "Email",
      selected: false,
    },
    {
      key: "Publisher",
      selected: false,
    },
    {
      key: "Mobile DSP",
      selected: false,
    },
    {
      key: "Others",
      selected: false,
    },
  ];
  const [nicheData, setNicheData] = useState(
    media_buyer && media_buyer.length
      ? media.map((item) => {
          if (media_buyer.includes(item?.key)) {
            return { ...item, selected: true };
          } else {
            return item;
          }
        })
      : media
  );
  const [influencerData, setInfluencerData] = useState(
    media.map((sc) => {
      let existing =
        (social_influencer &&
          social_influencer?.socialHandles &&
          social_influencer?.socialHandles.map((x: string) =>
            x.toLowerCase()
          )) ||
        [];

      if (existing.includes(sc.key.toLowerCase())) {
        return { ...sc, selected: true };
      } else {
        return sc;
      }
    })
  );

  useEffect(() => {
    if (count > 1) {
      setnewUpdate(true);
      sessionStorage.setItem("affiliateSettingsChange", "new update");
    }
    setcount(count + 1);
  }, [allAffiliateType, webURL, allSize, nicheData, influencerData, socials]);

  const updateData = () => {
    let payload;
    let handles: any = [];
    let socialHandles: any = {};
    if (affType() === "Content Publisher/Email Marketer") {
      payload = {
        affiliate_type: "content_publisher",
        content_publisher: {
          website: webURL,
          avgVisitors: allSize
            .filter((countryData: any) => countryData.selected)
            .map((data: any) => data.country)[0],
        },
      };
    } else if (affType() === "PPC/Media Buyer") {
      payload = {
        affiliate_type: "media_buyer",
        media_buyer: nicheData
          .filter((countryData: any) => countryData.selected)
          .map((data: any) => data.key),
      };
    } else if (affType() === "Social Influencer") {
      socials.map((item) => {
        handles.push(item.title);
        socialHandles[item.title] = item.url;
      });
      payload = {
        affiliate_type: "social_influencer",
        social_influencer: {
          socialHandles: handles,
          avgFollowers: socialHandles,
        },
      };
    }

    return payload;
  };

  const validator = () => {
    if (
      affType() === "Content Publisher/Email Marketer" &&
      !urlPattern.test(webURL)
    ) {
      return { isValid: false, error: "Website provided is not valid" };
    }

    return { isValid: true, error: "" };
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      minHeight="70vh"
    >
      <Box>
        <ToastContainer />
        <Box
          display="flex"
          columnGap="1em"
          my="1em"
          flexDirection={["column", "row"]}
          borderTop={["0px", "0.5px solid"]}
          borderTopColor={{ md: "rgba(50, 50, 93, 0.30)" }}
          py={[".5em", "1em"]}
        >
          <Text
            fontSize="0.9rem"
            fontWeight="500"
            color="#32325D"
            width={{ base: "100%", md: "30%" }}
            textAlign="left"
            mb={[".3em", "0em"]}
          >
            What type of affiliate are you?
          </Text>

          <Box
            width={{ base: "100%", md: "70%" }}
            maxW="572px"
            ref={wrapperTypeRef}
          >
            <Flex
              border="0.6px solid rgba(50, 50, 93, 0.30)"
              borderRadius="8px"
              height="48px"
              padding="12px 12px 15px 12px"
              justifyContent="space-between"
              alignItems="flex-start"
              mb="1em"
              cursor="pointer"
              onClick={() => {
                toggleTypeHandler();
              }}
            >
              <Flex rowGap=".5em" columnGap=".5em" flexWrap="wrap">
                {allAffiliateType.filter(
                  (countryData: any) => countryData.selected
                ).length >= 1 ? (
                  allAffiliateType
                    .filter((countryData: any) => countryData.selected)
                    .map((data: any, i: any) => {
                      return (
                        <Box
                          key={i}
                          alignItems="center"
                          display="flex"
                          borderRadius="4px"
                        >
                          <Text
                            mr=".8em"
                            ml=".5em"
                            fontSize="0.9rem"
                            fontWeight="400"
                            color="gray.800"
                          >
                            {data.country}
                          </Text>
                        </Box>
                      );
                    })
                ) : (
                  <Text cursor="pointer" onClick={toggleTypeHandler}>
                    Affiliate Type
                  </Text>
                )}
              </Flex>

              <Box>
                {!showTypeFilter ? (
                  <IoChevronDown cursor="pointer" onClick={toggleTypeHandler} />
                ) : (
                  <IoChevronUp cursor="pointer" onClick={toggleTypeHandler} />
                )}
              </Box>
            </Flex>

            {showTypeFilter && (
              <Box
                p={["1em", "1em", "1.5em"]}
                my="1em"
                boxShadow="xl"
                rounded="xl"
                height="40vh"
                overflowY="scroll"
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  my=".5em"
                >
                  <Text fontSize="0.9rem" color="gray.400" fontWeight="400">
                    Affiliate Type
                  </Text>

                  <BsFillCaretUpFill
                    color="#CACBD4"
                    cursor="pointer"
                    onClick={toggleTypeHandler}
                  />
                </Flex>

                {allAffiliateType.map((countryData: any, i: any) => {
                  return (
                    <Box
                      display={
                        searchQuery !== "" &&
                        !countryData.country
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                          ? "none"
                          : "flex"
                      }
                      justifyContent="space-between"
                      alignItems="center"
                      key={i}
                      cursor="pointer"
                      _hover={{ bgColor: "gray.50" }}
                      p=".5em"
                      my="2px"
                      borderRadius="3px"
                      onClick={() => {
                        const newState = allAffiliateType.map(
                          (data: any, x: any) => {
                            if (allAffiliateType[i] == data) {
                              return { ...data, selected: true };
                            } else {
                              return { ...data, selected: false };
                            }
                          }
                        );
                        setAllAffiliateType(newState);
                        toggleTypeHandler();
                      }}
                    >
                      <Flex alignItems="center" mt="1em">
                        <Text ml="1em">{countryData.country}</Text>
                      </Flex>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        </Box>

        {affType() === "Content Publisher/Email Marketer" && (
          <>
            <SettingsInput
              label="Website Link"
              type="text"
              value={webURL}
              changeHandler={setWebURL}
              isRequired={true}
            />
            {webURL !== "" && !urlPattern.test(webURL) && (
              <small style={{ color: "#fc8383" }}>
                Please input a valid URL to proceed.
              </small>
            )}

            <Box
              display="flex"
              columnGap="1em"
              my="1em"
              flexDirection={["column", "row"]}
              borderTop={["0px", "0.5px solid"]}
              borderTopColor={{ md: "rgba(50, 50, 93, 0.30)" }}
              py={[".5em", "1em"]}
            >
              <Text
                fontSize="0.9rem"
                fontWeight="500"
                color="#32325D"
                width={{ base: "100%", md: "30%" }}
                textAlign="left"
                mb={[".3em", "0em"]}
              >
                Average Monthly Visitor
              </Text>

              <Box
                width={{ base: "100%", md: "70%" }}
                maxW="572px"
                ref={wrapperSizeRef}
              >
                <Flex
                  _focusWithin={{ ring: "0", borderColor: "#5C14CE" }}
                  border="0.6px solid rgba(50, 50, 93, 0.30)"
                  borderRadius="8px"
                  justifyContent="space-between"
                  padding="12px 12px 15px 12px"
                  height="48px"
                  alignItems="flex-start"
                  cursor="pointer"
                  onClick={() => {
                    toggleSizeHandler();
                  }}
                >
                  <Flex rowGap=".5em" columnGap=".5em" flexWrap="wrap">
                    {allSize.filter((countryData: any) => countryData.selected)
                      .length >= 1 ? (
                      allSize
                        .filter((countryData: any) => countryData.selected)
                        .map((data: any, i: any) => {
                          return (
                            <Box
                              key={i}
                              alignItems="center"
                              display="flex"
                              borderRadius="4px"
                            >
                              <Text
                                mr=".8em"
                                ml=".5em"
                                fontSize="0.9rem"
                                fontWeight="400"
                                color="#32325D"
                              >
                                {data.country}
                              </Text>
                            </Box>
                          );
                        })
                    ) : (
                      <Text cursor="pointer" onClick={toggleSizeHandler}>
                        Select option
                      </Text>
                    )}
                  </Flex>
                  <Box>
                    {!showSizeFilter ? (
                      <IoChevronDown
                        cursor="pointer"
                        onClick={toggleSizeHandler}
                      />
                    ) : (
                      <IoChevronUp
                        cursor="pointer"
                        onClick={toggleSizeHandler}
                      />
                    )}
                  </Box>
                </Flex>

                {showSizeFilter && (
                  <Box
                    p={["1em", "1em", "1.5em"]}
                    my="1em"
                    boxShadow="xl"
                    rounded="xl"
                    height="60vh"
                    overflowY="scroll"
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      my=".5em"
                    >
                      <Text fontSize="0.9rem" color="gray.400" fontWeight="400">
                        Number of followers
                      </Text>

                      <BsFillCaretUpFill
                        color="#CACBD4"
                        cursor="pointer"
                        onClick={toggleSizeHandler}
                      />
                    </Flex>

                    {allSize.map((countryData: any, i: any) => {
                      return (
                        <Box
                          display={
                            searchQuery !== "" &&
                            !countryData.country
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                              ? "none"
                              : "flex"
                          }
                          justifyContent="space-between"
                          alignItems="center"
                          key={i}
                          cursor="pointer"
                          _hover={{ bgColor: "gray.50" }}
                          p=".5em"
                          my="2px"
                          borderRadius="3px"
                          onClick={() => {
                            const newState = allSize.map(
                              (data: any, x: any) => {
                                if (allSize[i] == data) {
                                  return { ...data, selected: true };
                                } else {
                                  return { ...data, selected: false };
                                }
                              }
                            );
                            setAllSize(newState);
                            toggleSizeHandler();
                          }}
                        >
                          <Flex alignItems="center" mt="1em">
                            <Text ml="1em">{countryData.country}</Text>
                          </Flex>
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            </Box>
          </>
        )}

        {affType() === "PPC/Media Buyer" && (
          <>
            <Box
              display="flex"
              columnGap="1em"
              my="1em"
              flexDirection={["column", "row"]}
              borderTop={["0px", "0.5px solid"]}
              borderTopColor={{ md: "rgba(50, 50, 93, 0.30)" }}
              py={[".5em", "1em"]}
            >
              <Text
                fontSize="0.9rem"
                color="#32325D"
                fontWeight="500"
                width={{ base: "100%", md: "30%" }}
                textAlign="left"
                mb={[".3em", "0em"]}
              >
                PPC/Media Buyer
              </Text>

              <Box
                width={{ base: "100%", md: "70%" }}
                maxW="572px"
                ref={wrapperIndustryRef}
              >
                <Box
                  _focus={{ ring: "0", borderColor: "#5C14CE" }}
                  border="0.6px solid rgba(50, 50, 93, 0.30)"
                  borderRadius="8px"
                  minHeight="48px"
                  padding="12px 12px 15px 12px"
                  mb="1em"
                  display="flex"
                  alignItems="center"
                  cursor="pointer"
                  justifyContent="space-between"
                  onClick={toggleHandler}
                >
                  <Flex columnGap=".5em" rowGap=".5em" flexWrap="wrap">
                    {nicheData.filter(
                      (singleNiche: any) => singleNiche.selected
                    ).length >= 1 ? (
                      nicheData
                        .filter((singleNiche: any) => singleNiche.selected)
                        .map(
                          (
                            singleNiche: { key: string; selected: boolean },
                            i: any
                          ) => {
                            return (
                              <Box
                                key={i}
                                bgColor="#F0F5FF"
                                py={["3px", "3px", "6px"]}
                                px=".4em"
                                alignItems="center"
                                display="flex"
                                borderRadius="4px"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <Text
                                  mr=".8em"
                                  fontSize="14px"
                                  fontWeight="400"
                                  color="gray.800"
                                >
                                  {singleNiche.key}
                                </Text>
                                <MdCancel
                                  color="#000"
                                  cursor="pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();

                                    const newState = nicheData.map(
                                      (data: any, x: any) => {
                                        if (singleNiche.key === data.key) {
                                          console.log(
                                            nicheData[i],
                                            "hello",
                                            data,
                                            "here"
                                          );
                                          return {
                                            ...data,
                                            selected: false,
                                          };
                                        } else {
                                          console.log("there");
                                          return data;
                                        }
                                      }
                                    );
                                    setNicheData(newState);
                                  }}
                                />
                              </Box>
                            );
                          }
                        )
                    ) : (
                      <Text> Select option</Text>
                    )}
                  </Flex>

                  <Box>
                    {!showFilter ? (
                      <IoChevronDown cursor="pointer" onClick={toggleHandler} />
                    ) : (
                      <IoChevronUp cursor="pointer" onClick={toggleHandler} />
                    )}
                  </Box>
                </Box>

                {showFilter && (
                  <Box
                    p={["1em", "1em", "1.5em"]}
                    my="1em"
                    boxShadow="xl"
                    rounded="xl"
                    height="60vh"
                    overflowY="scroll"
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      my=".2em"
                    >
                      <Text fontSize="14px" color="gray.400" fontWeight="400">
                        Search Media Buyer
                      </Text>

                      <BsFillCaretUpFill
                        color="#CACBD4"
                        cursor="pointer"
                        onClick={toggleHandler}
                      />
                    </Flex>

                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<BsSearch color="gray.300" />}
                      />
                      <Input
                        type="text"
                        _focus={{ ring: "0" }}
                        border="0"
                        bgColor="#F4F6F8"
                        value={searchQuery}
                        onChange={(e: React.ChangeEvent<any>) =>
                          setSearchQuery(e.target.value)
                        }
                      />
                    </InputGroup>

                    {nicheData.map((singleNiche: any, i: any) => {
                      return (
                        <Box
                          key={i}
                          display={
                            searchQuery !== "" &&
                            !singleNiche.key
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                              ? "none"
                              : "flex"
                          }
                          justifyContent="space-between"
                          alignItems="center"
                          my="1em"
                          cursor="pointer"
                          _hover={{ bgColor: "gray.50" }}
                          onClick={() => {
                            const newState = nicheData.map(
                              (data: any, x: any) => {
                                if (nicheData[i] == data) {
                                  return {
                                    ...data,
                                    selected: !data.selected,
                                  };
                                } else {
                                  return data;
                                }
                              }
                            );
                            setNicheData(newState);
                          }}
                          p=".5em"
                          borderRadius="3px"
                        >
                          <Text>{singleNiche.key}</Text>
                          {singleNiche.selected ? (
                            <BsCheckSquareFill color="#5C15CE" />
                          ) : (
                            <Box
                              width="1em"
                              height="1em"
                              border="1.5px solid gray"
                              borderRadius="4px"
                            ></Box>
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            </Box>
          </>
        )}

        {affType() === "Social Influencer" && (
          <>
            <Box
              display="flex"
              alignItems="flex-start"
              columnGap="1em"
              my="1em"
              flexDirection={["column", "row"]}
              borderTop={["0px", "0.5px solid"]}
              borderTopColor={{ md: "rgba(50, 50, 93, 0.30)" }}
              py={[".5em", "1em"]}
            >
              <Text
                fontSize="0.9rem"
                color="#32325D"
                fontWeight="500"
                width={{ base: "100%", md: "30%" }}
                textAlign="left"
                mb={[".3em", "0em"]}
              >
                Social Media Platform
              </Text>

              <Box
                width={{ base: "100%", md: "70%" }}
                maxW="572px"
                ref={wrapperRef}
              >
                <Box
                  _focus={{ ring: "0", borderColor: "#5C14CE" }}
                  border="0.6px solid rgba(50, 50, 93, 0.30)"
                  borderRadius="8px"
                  padding="12px 12px 15px 12px"
                  minH="48px"
                  mb="1em"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  cursor="pointer"
                  onClick={toggleHandlerMedia}
                >
                  <Flex columnGap=".5em" rowGap=".5em" flexWrap="wrap">
                    {influencerData.filter(
                      (singleNiche: any) => singleNiche.selected
                    ).length >= 1 ? (
                      influencerData
                        .filter((singleNiche: any) => singleNiche.selected)
                        .map(
                          (
                            singleNiche: { key: string; selected: boolean },
                            i: any
                          ) => {
                            return (
                              <Box
                                key={i}
                                bgColor="#F0F5FF"
                                py={["3px", "3px", "6px"]}
                                px=".4em"
                                alignItems="center"
                                display="flex"
                                borderRadius="4px"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <Text
                                  mr=".8em"
                                  fontSize="0.9rem"
                                  fontWeight="400"
                                  color="gray.800"
                                >
                                  {singleNiche.key}
                                </Text>
                                <MdCancel
                                  color="#000"
                                  cursor="pointer"
                                  onClick={() => {
                                    const newState = influencerData.map(
                                      (data: any, x: any) => {
                                        if (singleNiche.key === data.key) {
                                          setSocials(
                                            socials.filter(
                                              (sc) =>
                                                sc.title.toLowerCase() !==
                                                data.key.toLowerCase()
                                            )
                                          );
                                          return { ...data, selected: false };
                                        } else {
                                          // console.log("there");
                                          return data;
                                        }
                                      }
                                    );
                                    setInfluencerData(newState);
                                  }}
                                />
                              </Box>
                            );
                          }
                        )
                    ) : (
                      <Text> Select option</Text>
                    )}
                  </Flex>

                  <Box>
                    {!showIndustries ? (
                      <IoChevronDown
                        cursor="pointer"
                        onClick={toggleHandlerMedia}
                      />
                    ) : (
                      <IoChevronUp
                        cursor="pointer"
                        onClick={toggleHandlerMedia}
                      />
                    )}
                  </Box>
                </Box>

                {showIndustries && (
                  <Box
                    p={["1em", "1em", "1.5em"]}
                    my="1em"
                    boxShadow="xl"
                    rounded="xl"
                    height="60vh"
                    overflowY="scroll"
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      my=".2em"
                    >
                      <Text fontSize="0.9rem" color="gray.400" fontWeight="400">
                        Search Social Media Platform
                      </Text>

                      <BsFillCaretUpFill
                        color="#CACBD4"
                        cursor="pointer"
                        onClick={toggleHandlerMedia}
                      />
                    </Flex>

                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<BsSearch color="gray.300" />}
                      />
                      <Input
                        type="text"
                        _focus={{ ring: "0" }}
                        border="0"
                        bgColor="#F4F6F8"
                        value={searchQuery}
                        onChange={(e: React.ChangeEvent<any>) =>
                          setSearchQuery(e.target.value)
                        }
                      />
                    </InputGroup>

                    {influencerData.map((singleNiche: any, i: any) => {
                      return (
                        <Box
                          key={i}
                          display={
                            searchQuery !== "" &&
                            !singleNiche.key
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                              ? "none"
                              : "flex"
                          }
                          justifyContent="space-between"
                          alignItems="center"
                          my="1em"
                          cursor="pointer"
                          _hover={{ bgColor: "gray.50" }}
                          onClick={() => {
                            const newState = influencerData.map(
                              (data: any, x: any) => {
                                if (influencerData[i] == data) {
                                  return { ...data, selected: !data.selected };
                                } else {
                                  return data;
                                }
                              }
                            );
                            if (!singleNiche.selected) {
                              console.log("here");
                              setSocialData({
                                title: singleNiche.key,
                                url: "socialData.url",
                              });
                              addSocialHandler(singleNiche.key);
                            }
                            setInfluencerData(newState);
                          }}
                          p=".5em"
                          borderRadius="3px"
                        >
                          <Text>{singleNiche.key}</Text>

                          {singleNiche.selected ? (
                            <BsCheckSquareFill color="#5C15CE" />
                          ) : (
                            <Box
                              width="1em"
                              height="1em"
                              border="1.5px solid gray"
                              borderRadius="4px"
                            ></Box>
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              columnGap="1em"
              my="1em"
              flexDirection={["column", "row"]}
              borderTop={["0px", "0.5px solid"]}
              borderTopColor={{ md: "rgba(50, 50, 93, 0.30)" }}
              py={[".5em", "1em"]}
            >
              <Text
                fontSize="0.9rem"
                fontWeight="500"
                color="#32325D"
                width={{ base: "100%", md: "30%" }}
                textAlign="left"
                mb={[".3em", "0em"]}
              >
                What is your average following?
              </Text>

              <Box width={{ base: "100%", md: "70%" }} maxW="572px">
                <Flex alignItems="center" justifyContent="space-between">
                  <Box mr={[".5em", "1em"]} flexGrow={1} mb="7px" width="100%">
                    <FormInput
                      label=""
                      type="text"
                      holder="Social Media"
                      value={socialData.title}
                      changeEventHandler={(value: string) =>
                        setSocialData({
                          title: value,
                          url: socialData.url,
                        })
                      }
                    />
                  </Box>
                  <Box
                    ml={[".5em", "1em"]}
                    display="flex"
                    alignItems="center"
                    flexGrow={1}
                  >
                    <Button
                      ml={[".5em", "1em"]}
                      bgColor="#5C14CE"
                      color="#fff"
                      borderRadius=".8em"
                      fontWeight="500"
                      fontSize="13px"
                      px="2em"
                      onClick={() => addSocialHandler(socialData.title)}
                    >
                      Add
                    </Button>
                  </Box>
                </Flex>

                {socials.length >= 1 &&
                  socials[0].title !== "" &&
                  socials.map(
                    (socialInfo: { title: string; url: string }, i: any) => {
                      return (
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          borderBottom="1.5px solid #B6BDC3"
                          pb="4px"
                          my="8px"
                          key={i}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            bgColor="#F0F5FF"
                            borderRadius=".4em"
                            py={["3px", "3px", "6px"]}
                            px=".4em"
                            width={{ base: "38%", md: "38%", lg: "45%" }}
                            mr=".5em"
                          >
                            {socialInfo.title.toLowerCase() === "instagram" ? (
                              <AiFillInstagram color="#999999" />
                            ) : socialInfo.title.toLowerCase() ===
                              "facebook" ? (
                              <AiFillFacebook color="#999999" />
                            ) : socialInfo.title.toLowerCase() === "twitter" ? (
                              <AiFillTwitterSquare color="#999999" />
                            ) : socialInfo.title.toLowerCase() ===
                              "linkedin" ? (
                              <AiFillLinkedin color="#999999" />
                            ) : (
                              <MdOutlineSocialDistance color="#999999" />
                            )}
                            <Text
                              fontSize="0.9rem"
                              fontWeight="400"
                              color="gray.800"
                              ml=".5em"
                            >
                              {socialInfo.title}
                            </Text>
                          </Box>

                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            bgColor="#F0F5FF"
                            borderRadius=".4em"
                            width={{ base: "56%", md: "58%", lg: "53%" }}
                            ml=".5em"
                          >
                            <Box width="100%">
                              <Select
                                placeholder="Select option"
                                value={socialInfo.url}
                                onChange={(e: React.ChangeEvent<any>) => {
                                  setSocials(
                                    socials.map((sc) => {
                                      if (sc.title === socialInfo.title) {
                                        return { ...sc, url: e.target.value };
                                      } else {
                                        return sc;
                                      }
                                    })
                                  );
                                }}
                              >
                                {size.map((item) => {
                                  return (
                                    <option key={item} value={item}>
                                      {item}
                                    </option>
                                  );
                                })}
                              </Select>
                            </Box>
                            <MdCancel
                              color="#000"
                              cursor="pointer"
                              onClick={() => {
                                setSocials(
                                  socials.filter(
                                    (sc) =>
                                      sc.title.toLowerCase() !==
                                      socialInfo.title.toLowerCase()
                                  )
                                );
                                const newState = influencerData.map(
                                  (data: any, x: any) => {
                                    if (socialInfo.title === data.key) {
                                      return {
                                        ...data,
                                        selected: false,
                                      };
                                    } else {
                                      // console.log("there");
                                      return data;
                                    }
                                  }
                                );
                                setInfluencerData(newState);
                              }}
                            />
                          </Box>
                        </Flex>
                      );
                    }
                  )}
              </Box>
            </Box>
          </>
        )}
      </Box>

      <OrganizationSettingsFooter
        hasUpdate={newUpdate}
        sethasUpdate={setnewUpdate}
        setRefreshData={setRefreshData}
        dataToUpdate={updateData()}
        validator={validator}
      />
    </Box>
  );
};

export default AffiliateOrgInfo;
