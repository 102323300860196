import { Box, Button, Image, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { gsap, Power2 } from "gsap";

const EmailVerification = () => {
  let [searchParams, _] = useSearchParams();
  const navigate = useNavigate();

  const [isSuccessful, setisSuccessful] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(true);

  useEffect(() => {
    gsap.fromTo(
      ".loading",
      {
        opacity: 0,
        ease: Power2.easeInOut,
        scale: 0,
        y: 80,
      },
      {
        opacity: 1,
        duration: 1.5,
        scale: 1,
        ease: Power2.easeInOut,
        repeat: -1,
        y: 0,
        yoyo: true,
      }
    );
    verifyEmail();
  }, []);

  const verifyEmail = () => {
    const token = searchParams.get("token");

    if (!token) return navigate("/login");

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `auth/activate-account/${encodeURIComponent(token)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setisSuccessful(true);
          setloading(false);
        } else {
          setloading(false);
          console.log("email verification =>:" + data);
        }
      })
      .catch((err) => {
        setloading(false);
        console.log("email verification =>:" + err);
      });
  };

  if (loading)
    return (
      <Box display="flex" justifyContent="center" h="80vh" alignItems="center">
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Image className="loading" src="/assets/logo.png" height="80px" />
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="70px"
            color="#000000"
            mt="-15px"
          >
            Loading....
          </Text>
        </Box>
      </Box>
    );

  return (
    <Box
      w="100vw"
      h="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Image src="/assets/logo.png" h="80px" width="100px" />
      <Text
        mt="32px"
        fontSize="24px"
        fontWeight="600"
        lineHeight="40px"
        color="rgba(50, 50, 93, 1)"
        mb="28px"
      >
        Welcome To <span style={{ color: "#7211D4" }}>Metricks</span>
      </Text>
      <Text
        fontSize="18px"
        fontWeight="500"
        color="rgba(50, 50, 93, 0.8)"
        lineHeight="28px"
        mb="32px"
      >
        {isSuccessful
          ? "Your email has been successfully verified. Log in to continue"
          : "Your email has already been verified. Log in to continue"}
      </Text>

      <Button
        onClick={() => navigate("/login")}
        color="white"
        bgColor="#7211D4"
        width="200px"
        py="10px"
      >
        Login
      </Button>
    </Box>
  );
};

export default EmailVerification;
