import {
  Box,
  Checkbox,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { RiSearchLine } from "react-icons/ri";
import Fuse from "fuse.js";
import { useOutsideAlerter } from "../../../../hooks/useOutsideAlerter";

type CustomSelectProps = {
  title: string;
  fieldVals: any[];
  showVals: boolean;
  toggleValsVisibility: React.Dispatch<React.SetStateAction<string>>;
  filterData: any;
  setfilterData: React.Dispatch<any>;
  viewedSelect: string;
  isFull?: boolean;
};

const CustomSelect = ({
  title,
  fieldVals,
  showVals,
  toggleValsVisibility,
  filterData,
  setfilterData,
  viewedSelect,
  isFull,
}: CustomSelectProps) => {
  const [searchVal, setsearchVal] = useState<string>("");
  const [searchResult, setsearchResult] = useState<any>(null);
  const [showOptions, setshowOptions] = useState(false);

  const addDataToFilter = (data: string, title: string) => {
    if (filterData[title].includes(data)) {
      setfilterData({
        ...filterData,
        [title]: filterData[title].filter((item: string) => item !== data),
      });
    } else {
      setfilterData({
        ...filterData,
        [title]: [...filterData[title], data],
      });
    }
  };

  const options = {
    keys: ["name"],
  };

  const fuse = new Fuse(fieldVals, options);
  const filterRef = useRef(null);
  useOutsideAlerter(filterRef, () => setshowOptions(false));

  useEffect(() => {
    if (searchVal) {
      setsearchResult(fuse.search(searchVal));
    } else {
      setsearchResult(null);
    }
  }, [searchVal]);

  const space_years = useCallback((timeframe: any) => {
    let result = "";

    if (!timeframe) return result;

    let splited = timeframe.split("-");

    result = splited[0] + " - " + splited[1];

    return result;
  }, []);

  return (
    <Stack position={{ md: "relative" }}>
      <Box ref={filterRef}>
        <Box
          display="flex"
          alignItems="center"
          css={{
            border: "0.5px solid #c4c4c4",
          }}
          borderRadius="8px"
          px="9px"
          py="7px"
          bgColor="white"
          cursor="pointer"
          onClick={() => {
            setshowOptions(!showOptions);
          }}
        >
          <Text
            fontSize="14px"
            fontWeight="400"
            lineHeight="21px"
            flex={isFull || title === "Years of Experience" ? "1" : "0"}
            color="rgba(50, 50, 93, 0.8)"
            mr="24px"
          >
            {title}
          </Text>

          <MdOutlineArrowDropDown
            height={6}
            width={9}
            color="rgba(50, 50, 93, 0.7)"
          />
        </Box>

        {showOptions && (
          <Stack
            padding="8px 21px"
            position="absolute"
            zIndex="3"
            backgroundColor="white"
            borderRadius="8px"
            boxShadow="lg"
            maxH="300px"
            overflowY={title === "Country" ? "scroll" : "auto"}
            w={title === "Country" || title === "Niche" ? "300px" : "200px"}
            top={{ base: "90%", md: "40px" }}
            left={{ base: "0px", md: "" }}
            bgColor="white"
          >
            {title === "Country" && (
              <Box mb="16px">
                <Text
                  mb="5px"
                  fontSize="12px"
                  lineHeight="40px"
                  color="rgba(115, 115, 115, 1)"
                >
                  Select Country
                </Text>

                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<RiSearchLine color="rgba(50, 50, 93, 0.8)" />}
                  />
                  <Input
                    type="input"
                    value={searchVal}
                    focusBorderColor="rgba(114, 17, 212, 1)"
                    variant="filled"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setsearchVal(e.target.value)
                    }
                  />
                </InputGroup>
              </Box>
            )}

            {searchResult ? (
              searchResult?.map((item: any) => {
                return (
                  <Box display="flex" alignItems="center" cursor="pointer">
                    <Text
                      fontSize="13px"
                      fontWeight="500"
                      lineHeight="40px"
                      ml="12px"
                      flex="1"
                    >
                      {item.item.name}
                    </Text>

                    <Checkbox
                      colorScheme="purple"
                      isChecked={
                        filterData[
                          title === "Years of Experience"
                            ? "experience"
                            : title.toLowerCase()
                        ].includes(item.item.name)
                          ? true
                          : false
                      }
                      onChange={() =>
                        addDataToFilter(
                          item.item.name,
                          `${
                            title === "Years of Experience"
                              ? "experience"
                              : title.toLowerCase()
                          }`
                        )
                      }
                    />
                  </Box>
                );
              })
            ) : (
              <Stack spacing="8px">
                {fieldVals?.map((item) => {
                  if (title === "Country")
                    return (
                      <Box display="flex" alignItems="center" cursor="pointer">
                        <Text
                          fontSize="13px"
                          fontWeight="500"
                          lineHeight="40px"
                          ml="12px"
                          flex="1"
                        >
                          {item.name}
                        </Text>

                        <Checkbox
                          colorScheme="purple"
                          isChecked={
                            filterData[title.toLowerCase()].includes(item?.name)
                              ? true
                              : false
                          }
                          onChange={() =>
                            addDataToFilter(item?.name, title.toLowerCase())
                          }
                        />
                      </Box>
                    );

                  return (
                    <Box
                      display="flex"
                      justifyContent={"space-between"}
                      alignItems="center"
                      cursor="pointer"
                    >
                      <Text
                        fontSize="13px"
                        lineHeight="40px"
                        fontWeight="500"
                        flex="1"
                      >
                        {title === "Years of Experience"
                          ? space_years(item)
                          : item}
                      </Text>

                      <Checkbox
                        colorScheme="purple"
                        isChecked={
                          filterData[
                            title === "Years of Experience"
                              ? "experience"
                              : title.toLowerCase()
                          ].includes(item)
                            ? true
                            : false
                        }
                        onChange={() =>
                          addDataToFilter(
                            item,
                            `${
                              title === "Years of Experience"
                                ? "experience"
                                : title.toLowerCase()
                            }`
                          )
                        }
                      />
                    </Box>
                  );
                })}
              </Stack>
            )}
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default CustomSelect;
