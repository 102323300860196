import {
  Avatar,
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import AffiliateProperty from "./Detail";
import useUserToken from "../../../../../../hooks/useUserToken";

type AffiliateProfileProps = {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  profileImage: string;
  status: string;
};

const AffUserProfile = ({
  id,
  firstname,
  lastname,
  email,
  phone,
  profileImage,
  status,
}: AffiliateProfileProps) => {
  const getStatusColor = (status: string) => {
    if (status === "approved") {
      return "#239654";
    } else if (status === "pending") {
      return "#F3994A";
    } else {
      return "#EB5757";
    }
  };

  const getStatusDesc = () => {
    if (statusAction === "approved") return "approve";
    if (statusAction === "suspended") return "suspend";
    if (statusAction === "declined") return "decline";

    return "";
  };

  const [currentStatus, setcurrentStatus] = useState(status);
  const [isUpdatingStatus, setisUpdatingStatus] = useState(false);
  const [statusAction, setstatusAction] = useState("");
  const token = useUserToken();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const changeAffiliateStatus = () => {
    let promotionIds: any[] = [id];

    if (!statusAction) return;

    setisUpdatingStatus(true);
    onClose();

    let bearerAuth = token;

    const payload = {
      status: statusAction,
      promotionIds,
    };

    fetch(
      process.env.REACT_APP_API_ENDPOINT + `promote-campaign/multiple-approve`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setisUpdatingStatus(false);
          setcurrentStatus(statusAction);
          setstatusAction("");

          return toast({
            title: `${firstname} ${lastname} has been ${statusAction}`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          setisUpdatingStatus(false);
          return toast({
            title: `Failed to suspend ${firstname} ${lastname}`,
            description: data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      });
  };

  const triggerChange = (status: string) => {
    setstatusAction(status);
    onOpen();
  };

  return (
    <Stack>
      <Box mb="42px" pt="26px">
        {profileImage ? (
          <Image
            src={profileImage}
            objectFit="cover"
            rounded="full"
            h="52px"
            width="52px"
            mb="18px"
          />
        ) : (
          <Avatar
            name={firstname + " " + lastname}
            size={{ base: "lg", md: "xl" }}
          />
        )}
        <Image />

        <Text
          color="#000000"
          fontWeight="500"
          fontSize="18px"
          lineHeight="28px"
        >
          {`${firstname} ${lastname}`}
        </Text>

        <Flex mt="14px">
          {currentStatus === "approved" && (
            <Button
              bgColor="#EC635C"
              fontSize="12px"
              lineHeight="14px"
              fontWeight="500"
              color="white"
              mr="12px"
              onClick={() => triggerChange("suspended")}
              isLoading={isUpdatingStatus && statusAction === "suspended"}
              isDisabled={isUpdatingStatus}
              borderRadius="8px"
            >
              Suspend
            </Button>
          )}

          {currentStatus === "pending" || currentStatus === "suspended" ? (
            <Button
              bgColor="#239654"
              fontSize="12px"
              lineHeight="14px"
              fontWeight="500"
              color="white"
              mr="12px"
              onClick={() => triggerChange("approved")}
              isLoading={isUpdatingStatus && statusAction === "approved"}
              isDisabled={isUpdatingStatus}
              borderRadius="8px"
            >
              Approve
            </Button>
          ) : (
            ""
          )}

          {currentStatus === "pending" && (
            <Button
              bgColor="#EC635C"
              fontSize="12px"
              lineHeight="14px"
              fontWeight="500"
              color="white"
              mr="12px"
              onClick={() => triggerChange("declined")}
              isLoading={isUpdatingStatus && statusAction === "declined"}
              isDisabled={isUpdatingStatus}
              borderRadius="8px"
            >
              Decline
            </Button>
          )}

          <Box
            fontSize="12px"
            lineHeight="14px"
            fontWeight="500"
            display="flex"
            alignItems="center"
          >
            <Box
              background={getStatusColor(currentStatus.toLowerCase())}
              height="8px"
              width="8px"
              borderRadius="50%"
            ></Box>
            <Text
              color={getStatusColor(currentStatus.toLowerCase())}
              lineHeight="21px"
              fontSize="14px"
              ml="4px"
              fontWeight="400"
              textTransform="capitalize"
            >
              {currentStatus}
            </Text>
          </Box>
        </Flex>
      </Box>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textTransform="capitalize">
            {getStatusDesc()} {firstname} {lastname}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to {getStatusDesc()} {firstname} {lastname}
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              colorScheme="purple"
              mr={3}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button colorScheme="purple" onClick={changeAffiliateStatus}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Stack
        borderTop="0.5px solid rgba(50, 50, 93, 0.3)"
        divider={<StackDivider borderColor="rgba(50, 50, 93, 0.3)" />}
      >
        <AffiliateProperty
          type="string"
          fieldData={firstname}
          label="First Name"
        />
        <AffiliateProperty
          type="string"
          fieldData={lastname}
          label="Last Name"
        />
        <AffiliateProperty
          type="string"
          fieldData={email}
          label="Email Address"
        />
        <AffiliateProperty
          type="string"
          fieldData={phone}
          label="Phone Number"
        />
      </Stack>
    </Stack>
  );
};

export default AffUserProfile;
