import * as React from "react";
import "./styles/globals.css";
import { ChakraProvider, theme } from "@chakra-ui/react";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import { Route, Routes, Navigate } from "react-router-dom";
import { RecoilRoot } from "recoil";
import "react-toastify/dist/ReactToastify.css";
import SignUp from "./pages/signup";
import Forgot from "./pages/forgot";
import ProtectedRoutes from "./components/protectedRoute/ProtectedRoute";
import InnerContent from "./components/protectedRoute/innerContent";
import { getCurrentUserInstance } from "./state/localstorage";
import AffiliateLoginPPage from "./pages/affiliate/login";
import Verification from "./pages/verification";
import Suspended from "./pages/Suspended";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import NewManager from "./pages/affiliate/newManager";
import UploadAsset from "./components/layout/uploadAsset";
// import { io } from "socket.io-client";

// @ts-ignore
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const user = getCurrentUserInstance();

export const App = () => (
  <RecoilRoot>
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<InnerContent />}>
            <Route
              path="/"
              element={
                <Navigate
                  replace
                  to={
                    user?.organisation?.userType === "advertiser"
                      ? "dashboard"
                      : "affiliate-dashboard"
                  }
                />
              }
            />

            <Route path="/*" element={<Dashboard />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/affiliate-login" element={<AffiliateLoginPPage />} />
        <Route path="/signup/*" element={<SignUp />} />
        <Route path="/reset-password/*" element={<Forgot />} />
        <Route path="/email-verification" element={<Verification />} />
        <Route path="/manager/signup" element={<NewManager />} />
        <Route path="/upload/resourcesXadmin" element={<UploadAsset />} />
        <Route
          path="/suspended"
          element={
            <Elements stripe={stripePromise}>
              <Suspended />
            </Elements>
          }
        />
      </Routes>
    </ChakraProvider>
  </RecoilRoot>
);
