import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import { ImAttachment } from "react-icons/im";
import useUserToken from "../../../../../hooks/useUserToken";
import { AiOutlineClose } from "react-icons/ai";

type refundFormType = {
  header: string;
  description: string;
  files: any[];
};

const LogRefund = ({
  isOpen,
  onClose,
  saleToRefund,
}: {
  isOpen: boolean;
  onClose: () => void;
  saleToRefund: number | null;
}) => {
  const filesRef = useRef<HTMLInputElement>(null);
  const [isLoggingRefund, setisLoggingRefund] = useState(false);
  const [refundFormData, setrefundFormData] = useState<refundFormType>({
    header: "",
    description: "",
    files: [],
  });
  const [fileList, setfileList] = useState<FileList | null>(null);

  const token = useUserToken();
  const toast = useToast();

  const openFiles = () => {
    if (filesRef.current) {
      filesRef.current.click();
    }
  };

  const handleClose = () => {
    setrefundFormData({
      header: "",
      description: "",
      files: [],
    });
    onClose();
    setfileList(null);
  };

  const logRefund = () => {
    setisLoggingRefund(true);

    if (!refundFormData.description)
      return toast({
        title: "Log Refund Error",
        description: "Please provide description for the sale refund",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

    let bearerAuth = token;

    const formData = new FormData();
    formData.append("conversionId", `${saleToRefund}`);
    formData.append("description", refundFormData.description);
    formData.append("subject", refundFormData.header);

    if (refundFormData.files.length) {
      refundFormData.files.forEach((file: File) => {
        formData.append("files", file);
      });
    }

    fetch(process.env.REACT_APP_API_ENDPOINT + `refund/create`, {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: bearerAuth ? `Bearer ${bearerAuth}` : "",
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          if (data.data.refund) {
            setisLoggingRefund(false);
            handleClose();

            return toast({
              title: "Sale refund has been logged successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
          } else {
            setisLoggingRefund(false);
            handleClose();

            return toast({
              title: "Refund has already been logged for this sale",
              status: "info",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
          }
        } else {
          setisLoggingRefund(false);
          return toast({
            title: "Log Refund Error",
            description: data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      });
  };

  useEffect(() => {
    if (fileList) {
      let files = fileList;
      let customFiles = [];

      for (var i = 0; i < files.length; i++) {
        customFiles.push(files[i]);
      }

      setrefundFormData({ ...refundFormData, files: customFiles });
    }
  }, [fileList]);

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={handleClose} isCentered={true}>
      <ModalOverlay />

      <ModalContent borderRadius="10px">
        <ModalHeader
          color="white"
          bgColor="#7211D4"
          fontSize="16px"
          lineHeight="24px"
          fontWeight="600"
        >
          Log Refund
        </ModalHeader>
        <ModalCloseButton
          borderRadius="full"
          _hover={{ bgColor: "white" }}
          bgColor="white"
          color="#7211D4"
        />

        <ModalBody>
          <Stack
            divider={<StackDivider borderColor="rgba(50, 50, 93, 0.3)" />}
            spacing="19px"
            mb="34px"
            mt="47px"
          >
            <Input
              variant="unstyled"
              placeholder="Subject for refund"
              fontSize="16px"
              fontWeight="500"
              color="background: rgba(0, 0, 0, 1)"
              value={refundFormData.header}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setrefundFormData({ ...refundFormData, header: e.target.value })
              }
            />

            <Textarea
              placeholder="Type your reason for logging refund here"
              variant="filled"
              height="220px"
              resize="none"
              padding="10px"
              fontSize="14px"
              fontWeight="500"
              focusBorderColor="purple.300"
              color="rgba(50, 50, 93, 1)"
              value={refundFormData.description}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setrefundFormData({
                  ...refundFormData,
                  description: e.target.value,
                })
              }
            />
          </Stack>

          {refundFormData.files.length > 0 && (
            <Box display="flex" flexWrap="wrap" alignItems="center" gap="16px">
              {refundFormData.files.map((file: any, index: number) => (
                <Box
                  display="flex"
                  bgColor="rgba(50, 50, 93, 0.2)"
                  padding="8px"
                  alignItems="center"
                >
                  <Text
                    fontSize="12px"
                    fontWeight="400"
                    mr="7px"
                    color="rgba(50, 50, 93, 1)"
                  >
                    {file?.name}
                  </Text>
                </Box>
              ))}
            </Box>
          )}
        </ModalBody>

        <ModalFooter display="flex" justifyContent="right">
          <input
            type="file"
            id="file"
            ref={filesRef}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setfileList(e.target.files)
            }
            style={{ display: "none" }}
            multiple={true}
          />

          <Button
            onClick={openFiles}
            variant="unstyled"
            color="rgba(50, 50, 93, 0.8)"
            borderRadius="full"
            mr="16px"
          >
            <ImAttachment />
          </Button>

          <Button
            bgColor="rgba(50, 50, 93, 1)"
            borderRadius="8px"
            fontWeight="500"
            fontSize={{ base: "14px", md: "16px" }}
            lineHeight={{ base: "21px", md: "32px" }}
            color="white"
            w="max-content"
            onClick={logRefund}
            isLoading={isLoggingRefund}
          >
            Send Now
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LogRefund;
