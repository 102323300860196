import React from "react";
import { Box, Text, Stack } from "@chakra-ui/react";

const Time = ({ minutes, seconds, milliseconds }: any) => {
  return (
    <Stack direction="row" justifyContent="center">
      <Box
        height="60px"
        width="64px"
        bgColor="white"
        border="2px solid #A5A5A5"
        borderRadius="10px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text color="#000000" fontWeight="500" fontSize="32px">
          {minutes}
        </Text>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Text color="#000000" fontWeight="500" fontSize="32px">
          :
        </Text>
      </Box>
      <Box
        height="60px"
        width="64px"
        bgColor="white"
        border="2px solid #A5A5A5"
        borderRadius="10px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text color="#000000" fontWeight="500" fontSize="32px">
          {seconds}
        </Text>
      </Box>
      <Box
        height="60px"
        width="64px"
        bgColor="white"
        border="2px solid #A5A5A5"
        borderRadius="10px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text color="#000000" fontWeight="500" fontSize="32px">
          {milliseconds}
        </Text>
      </Box>
    </Stack>
  );
};

export default Time;
