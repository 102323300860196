export const SettingsIcon = (props: any) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0451 5.17099L13.5914 4.3844C13.2083 3.71871 12.3583 3.48854 11.6919 3.87092C11.3743 4.05737 10.9956 4.11054 10.6395 4.01804C10.2826 3.92554 9.97812 3.69541 9.79167 3.37712C9.6715 3.17538 9.60739 2.94522 9.60521 2.71069C9.6154 2.33415 9.47339 1.96925 9.21119 1.69977C8.94899 1.42956 8.58846 1.27734 8.21191 1.27734H7.29785C6.92931 1.27734 6.57535 1.42445 6.31533 1.68592C6.05532 1.94739 5.90966 2.3021 5.91184 2.67064C5.90092 3.43248 5.27963 4.04427 4.51853 4.04427C4.28327 4.04135 4.05313 3.97727 3.85138 3.85709C3.18496 3.47545 2.33499 3.70486 1.95189 4.37056L1.46463 5.17099C1.08153 5.83523 1.30805 6.6852 1.97156 7.07049C2.40274 7.31958 2.66858 7.77989 2.66858 8.27734C2.66858 8.77552 2.40274 9.23509 1.97156 9.48418C1.30951 9.86728 1.08226 10.7143 1.46463 11.3771L1.92493 12.171C2.10483 12.4951 2.40638 12.734 2.76253 12.8352C3.11941 12.9372 3.50178 12.8921 3.82516 12.7107C4.14271 12.525 4.52144 12.4747 4.87687 12.5694C5.2323 12.6648 5.53528 12.8979 5.71809 13.2176C5.83827 13.4194 5.90237 13.6495 5.90455 13.884C5.90455 14.6539 6.52873 15.2773 7.29785 15.2773H8.21191C8.97812 15.2773 9.60157 14.6575 9.60521 13.8906C9.60302 13.5206 9.74942 13.1652 10.0109 12.9037C10.2724 12.6415 10.6278 12.4959 10.9985 12.4973C11.2323 12.5039 11.4617 12.5679 11.6649 12.6837C12.3299 13.0669 13.1791 12.8403 13.5652 12.1776L14.0451 11.3771C14.2309 11.0581 14.2818 10.6787 14.1864 10.3218C14.0917 9.96562 13.8579 9.66116 13.5382 9.47762C13.2185 9.29335 12.9847 8.98966 12.89 8.63277C12.7946 8.27589 12.8456 7.89643 13.0313 7.57742C13.1522 7.3662 13.327 7.19139 13.5382 7.07049C14.1973 6.6852 14.4231 5.84033 14.0451 5.17755V5.17099Z"
        stroke="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
      <path
        d="M7.75837 10.1974C8.81875 10.1974 9.67837 9.33782 9.67837 8.27743C9.67837 7.21704 8.81875 6.35742 7.75837 6.35742C6.69798 6.35742 5.83838 7.21704 5.83838 8.27743C5.83838 9.33782 6.69798 10.1974 7.75837 10.1974Z"
        stroke="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
    </svg>
  );
};
