import { useEffect, useRef } from "react";
import { Box, Image, Text, Button } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper styles

import { findFlagUrlByCountryName } from "country-flags-svg";
import "semantic-ui-css/semantic.min.css";
import "swiper/css";

const Countries = ({ countries, outerPadding = "" }) => {
  const swiperRef = useRef();

  useEffect(() => {
    countries &&
      setInterval(() => {
        if (countries.length >= 5) {
          swiperRef.current?.slideNext();
        }
      }, 3000);
  }, []);

  return (
    <Box
      mx="0px"
      mb={10}
      background="#FFFFFF"
      width="100%"
      padding={{
        base: "10px 0px",
        md: outerPadding ? outerPadding : "10x 0px",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb="15px"
      >
        <Box display="flex" alignItems="center">
          <Text
            fontWeight="500"
            fontSize="12px"
            color=" #32325D"
            lineHeight="18px"
            opacity="0.8"
            letterSpacing="0.02em"
            textTransform="uppercase"
          >
            Shipping
          </Text>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            background="#FFFFFF"
            boxShadow="0px 0px 7.42829px rgba(114, 17, 212, 0.25)"
            borderRadius=" 4.64268px"
            width="32px"
            height="28px"
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <Image
              transform="rotate(180deg)"
              src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/67853fcc-a95e-4476-be48-14434b51b27d.svg`}
            />
          </Button>
          <Button
            background="#FFFFFF"
            boxShadow="0px 0px 7.42829px rgba(114, 17, 212, 0.25)"
            borderRadius=" 4.64268px"
            width="32px"
            height="28px"
            ml="18px"
            onClick={() => swiperRef.current?.slideNext()}
          >
            <Image
              src={`https://metricksassets.s3.us-east-2.amazonaws.com/production/default/67853fcc-a95e-4476-be48-14434b51b27d.svg`}
            />
          </Button>
        </Box>
      </Box>
      <Box position="relative">
        <Swiper
          spaceBetween={20}
          slidesPerView="auto"
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          autoplay={{ delay: 3000 }}
        >
          {countries &&
            countries?.map((item, index) => {
              console.log(item);
              return (
                <SwiperSlide
                  position="relative"
                  key={index}
                  style={{ width: "fit-content" }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    background="#FFFFFF"
                    border=" 0.5px solid rgba(50, 50, 93, 0.20)"
                    borderRadius="80px"
                    padding="9px 14px"
                    // mr={20}
                  >
                    {findFlagUrlByCountryName(item) ? (
                      <Image
                        src={findFlagUrlByCountryName(item)}
                        h="30px"
                        width="30px"
                        rounded="full"
                        objectFit="cover"
                      />
                    ) : item === "AmericanSamoa" ? (
                      <Image
                        src={"/assets/png/america-sm.png"}
                        h="30px"
                        width="30px"
                        rounded="full"
                        objectFit="cover"
                      />
                    ) : (
                      <Box h="30px" w="30px" rounded="full" bg="#dddbf0" />
                    )}

                    <Text
                      fontWeight="600"
                      fontSize={{ base: "0.75rem", md: "1rem" }}
                      color=" #32325D"
                      lineHeight="21px"
                      ml="14px"
                    >
                      {/* {item.length > 8 ? `${item.substring(0, 10)}...` : item} */}
                      {item}
                    </Text>
                  </Box>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </Box>
    </Box>
  );
};

export default Countries;
