import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";

const UnavailableInfo = ({ tab }: { tab: string }) => {
  return (
    <Box
      w="100%"
      height="50vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={{ base: "0px 16px", md: "0px" }}
      mt="21px"
    >
      <Image
        src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/8325c5af-bd34-4410-bb2a-4ef7f6654593.svg"
        height="250px"
        objectFit="contain"
        mb="16px"
      />

      <Text
        mb="6px"
        fontWeight="600"
        fontSize="14px"
        lineHeight="21px"
        color="#32325D"
        textAlign="center"
      >
        No data available yet, when you have {tab} you will see them here.
      </Text>

      <Text color="#7211D4" fontWeight="600" fontSize="12px" lineHeight="18px">
        Coming Soon
      </Text>
    </Box>
  );
};

export default UnavailableInfo;
